import React, { Component } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { Treebeard } from 'react-treebeard';

import { connect } from 'react-redux';
import { buildOrgTree } from '../../utils';
import { orgSelectors, orgOperations } from '../orgs/duck';

const modalRoot = document.getElementById('modal-root');

const treeStyle = {
    tree: {
        base: {
            listStyle: 'none',
            backgroundColor: 'white',
            margin: 0,
            padding: 0,
            color: '#22292F',
            fontFamily:
                'system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
            fontSize: '14px'
        },
        node: {
            base: {
                position: 'relative'
            },
            link: {
                cursor: 'pointer',
                position: 'relative',
                padding: '0px 5px',
                display: 'block'
            },
            activeLink: {
                background: '#F1F5F8'
            },
            toggle: {
                base: {
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginLeft: '-5px',
                    height: '20px',
                    width: '20px'
                },
                wrapper: {
                    position: 'absolute',
                    top: '50%',
                    left: '70%',
                    margin: '-7px 0 0 -7px',
                    height: '14px'
                },
                height: 10,
                width: 10,
                arrow: {
                    fill: '#3D4852',
                    strokeWidth: 0
                }
            },
            header: {
                base: {
                    display: 'inline-block',
                    verticalAlign: 'top',
                    color: '#22292F',
                    marginLeft: '0.5em'
                },
                connector: {
                    width: '2px',
                    height: '12px',
                    borderLeft: 'solid 2px black',
                    borderBottom: 'solid 2px black',
                    position: 'absolute',
                    top: '0px',
                    left: '-21px'
                },
                title: {
                    lineHeight: '24px',
                    verticalAlign: 'middle'
                }
            },
            subtree: {
                listStyle: 'none',
                paddingLeft: '19px'
            },
            loading: {
                color: '#22292F'
            }
        }
    }
};

class OrgSelector extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');

        this.state = { data: [] };
    }

    static getDerivedStateFromProps({ isLoading, errors, orgs }, prevState) {
        if (prevState.data.length === 0) {
            const orgTree = buildOrgTree(orgs, orgs[0]);
            orgTree.toggled = true;

            return isLoading || errors
                ? null
                : {
                      ...prevState,
                      data: [orgTree]
                  };
        }

        return null;
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);

        if (this.props.shouldUpdate && !this.props.isLoading) this.props.updateOrgs();
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    onToggle = (node, toggled) => {
        if (_.isEqual(this.state.previousNode, node)) this.props.orgSelected({ Id: node.id, Name: node.name, ContractType_Id: node.ContractType_Id });

        if (this.state.cursor) this.state.cursor.active = false;

        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }

        this.setState({ previousNode: node, cursor: node });
        this.forceUpdate();
    };

    render() {
        return ReactDOM.createPortal(this.renderOrgSelector(), this.el);
    }

    renderOrgSelector() {
        const { isLoading } = this.props;

        return (
            <div className="flex justify-center items-center pin select-none fixed z-50">
                <div className="absolute pin bg-black opacity-75" onClick={this.props.close} />
                <div className="z-20 bg-white rounded shadow-lg px-6 py-4 w-4/5 lg:w-1/3 h-screen-75 overflow-y-auto">
                    <h1 className="font-bold text-black text-lg mb-2">Select an Organization</h1>
                    <h6 className="font-normal text-grey-darker text-sm mb-4">Double-click an organization to select it.</h6>
                    {isLoading ? (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    ) : (
                        <div>
                            <Treebeard data={this.state.data} onToggle={this.onToggle} style={treeStyle} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    shouldUpdate: orgSelectors.shouldUpdateOrgs(state.orgs),
    isLoading: state.orgs._updating._gettingOrgs,
    orgs: state.orgs.orgs
});

const mapDispatchToProps = dispatch => ({
    updateOrgs: () => dispatch(orgOperations.getOrgs())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgSelector);
