import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Items from './Items';
import ItemPage from './ItemPage';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const itemsRouter = () => (
    <Route key="items-router" path="/cases/:caseId/items/:itemId">
        <Switch key="items-router-switch">
            <Guard
                key="item-route"
                exact
                path="/cases/:caseId/items/:itemId"
                notFound={NotFound}
                permissions={[roles.Items.VIEW_ITEMS, roles.Items.EDIT_ITEMS]}
            >
                <AuthRoute exact path="/cases/:caseId/items/:itemId" component={ItemPage} />
            </Guard>
        </Switch>
    </Route>
);

const itemsListRouter = () => (
    <Route key="item-list-router" exact path="/items">
        <Switch key="item-list-router-switch">
            <Guard key="item-list-route" exact path="/items" notFound={NotFound} permissions={[roles.Items.SEARCH_ITEMS]}>
                <AuthRoute exact path="/items" component={Items} />
            </Guard>
        </Switch>
    </Route>
);

export { itemsRouter, itemsListRouter };
