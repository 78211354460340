import React from 'react';
import { roles } from '../../constants';
import { buildDesktopNavigation, MobileNavigationItem } from '../../utils/navigation';

const nav = {
    linkText: 'Invoices',
    icon: 'fa-clipboard-list',
    props: { to: '/invoices' },
    permissions: [roles.Invoices.VIEW_INVOICE_DETAILS]
};

export const invoicesDesktopNavigation = buildDesktopNavigation(nav);
export const invoicesMobileNavigation = <MobileNavigationItem key="invoices-mobile-navigation" navigation={nav} menuLevel={0} />;
