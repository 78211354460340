import types from './types';

const getCaseTemplatesRequest = () => ({
    type: types.GET_CASE_TEMPLATES_REQUEST
});
const getCaseTemplatesSuccess = caseTemplates => ({
    type: types.GET_CASE_TEMPLATES_SUCCESS,
    payload: caseTemplates
});
const getCaseTemplatesFailure = errors => ({
    type: types.GET_CASE_TEMPLATES_FAILURE,
    payload: errors
});

const createCaseTemplateRequest = () => ({
    type: types.CREATE_CASE_TEMPLATE_REQUEST
});
const createCaseTemplateSuccess = caseTemplate => ({
    type: types.CREATE_CASE_TEMPLATE_SUCCESS,
    payload: caseTemplate
});
const createCaseTemplateFailure = errors => ({
    type: types.CREATE_CASE_TEMPLATE_FAILURE,
    payload: errors
});

const editCaseTemplateRequest = caseTemplateId => ({
    type: types.EDIT_CASE_TEMPLATE_REQUEST,
    payload: caseTemplateId
});
const editCaseTemplateSuccess = caseTemplate => ({
    type: types.EDIT_CASE_TEMPLATE_SUCCESS,
    payload: caseTemplate
});
const editCaseTemplateFailure = (caseTemplateId, errors) => ({
    type: types.EDIT_CASE_TEMPLATE_FAILURE,
    payload: { caseTemplateId, errors }
});

const deleteCaseTemplateRequest = caseTemplateId => ({
    type: types.DELETE_CASE_TEMPLATE_REQUEST,
    payload: caseTemplateId
});
const deleteCaseTemplateSuccess = caseTemplateId => ({
    type: types.DELETE_CASE_TEMPLATE_SUCCESS,
    payload: caseTemplateId
});
const deleteCaseTemplateFailure = (caseTemplateId, errors) => ({
    type: types.DELETE_CASE_TEMPLATE_FAILURE,
    payload: { caseTemplateId, errors }
});

const swapCaseTemplateItemsRequest = () => ({
    type: types.SWAP_CASE_TEMPLATE_ITEM_REQUEST
});
const swapCaseTemplateItemsSuccess = (caseTemplateId, swapResult) => ({
    type: types.SWAP_CASE_TEMPLATE_ITEM_SUCCESS,
    payload: { caseTemplateId, swapResult }
});
const swapCaseTemplateItemsFailure = (errors) => ({
    type: types.SWAP_CASE_TEMPLATE_ITEM_FAILURE,
    payload: errors
});

export default {
    getCaseTemplatesRequest,
    getCaseTemplatesSuccess,
    getCaseTemplatesFailure,
    createCaseTemplateRequest,
    createCaseTemplateSuccess,
    createCaseTemplateFailure,
    editCaseTemplateRequest,
    editCaseTemplateSuccess,
    editCaseTemplateFailure,
    deleteCaseTemplateRequest,
    deleteCaseTemplateSuccess,
    deleteCaseTemplateFailure,
    swapCaseTemplateItemsRequest,
    swapCaseTemplateItemsSuccess,
    swapCaseTemplateItemsFailure
};