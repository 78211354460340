
import { Form, FormConsumer } from '../../common/form/Form'; import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ReactTable from 'react-table';
import { roles } from '../../../constants';
import { Header, Divider, Section, Input, TextArea, Label } from '../../common/form';
import Guard from '../../common/Guard';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
//import UpdateAdditionalInformationForm from './UpdateAdditionalInformationForm';
import { baseUrl } from '../../../config';
import axios from 'axios';
import { toast } from 'react-toastify';

class AdditionalInformations extends Component {
    state = {
        hasRenderError: false,
        expanded: {},
        showUpdateAdditionalInformationModal: false
    };


    componentDidMount() {
        const { caseId } = this.props;
        this.props.getAdditionalInformations(caseId);

    }

    componentDidCatch() {
        this.setState({
            hasRenderError: true
        });
    }

    openAdditionalInformation(rowIndex) {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    }

    submitAdditionalInformation = async values => {
        console.log(this.props.caseId);

        console.log(values);

        const formData = new FormData();
        for (var key in values) {
            formData.append(key, values[key]);
        }
        try {
            const res = await axios.post(`${baseUrl}/api/cases/${this.props.caseId}/UpdateCaseCustomParameters`, formData);

            //            const response = await axios.get(`${baseUrl}/api/cases/${mapState.cases.CaseNumber}/CaseCustomParameters/`, {

            //const res = await axios.post(`http://localhost:55910/api/user/GetFileList`, formData);
    //        console.log(this.props.additionalinformations);
       //     console.log(res.data)

            this.props.getAdditionalInformations(this.props.caseId);
         //   console.log(this.props.additionalinformations);
            //               const res1 = await axios.get('https://jsonplaceholder.typicode.com/users')
            //         console.log(res1.data)
            toast.success('Information Updated Successfully.');
        } catch (error) {
            toast.success('ERROR Updating Information.');
            console.log(error);

        }

    };



    render() {
        const {
            additionalinformations } = this.props;
        const { hasRenderError, expanded, showUpdateAdditionalInformationModal } = this.state;

        if (hasRenderError) return <h1 className="text-primary text-base">Unable to show AdditionalInformations</h1>;
        //console.log({ additionalinformations })

        if (additionalinformations.length > 0) {
            return (
                <Form
                    onSubmit={this.submitAdditionalInformation}
                >
                    <Section>

                        <Header>Additional Settings</Header>
                        <Divider />
                        {
                            <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                                {this.props.additionalinformations.map((result, i) => {
                                    if (result.ParameterType == "TextArea") {
                                        return (
                                            <div key={i} className="mb-4 flex-grow px-3">
                                                <TextArea name={result.ParameterName} label={result.ParameterReference + '-' + result.ParameterValue} className="h-32" />
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div key={i} className="mb-4 flex-grow px-3">
                                                <Input name={result.ParameterName} label={result.ParameterReference + '-' + result.ParameterValue} />
                                            </div>
                                        )
                                    }
                                }
                                )}
                                <div className="flex w-full items-center justify-end">
                                    <Button type="submit">
                                        Update Additional Fields
                                    </Button>

                                </div>
                            </div>

                        }
                    </Section>
                </Form>
            );
        }
        else {

            return (
                <></>
            );
        }


/*                <Modal className="max-w-md" show={this.state.showUpdateAdditionalInformationModal} onClose={() => this.setState({ showUpdateAdditionalInformationModal: false })}>
                    {this.state.showUpdateAdditionalInformationModal ? (
                        <UpdateAdditionalInformationForm
                            onSubmit={this.submitAdditionalInformation}
                            defaultValues={{}}
                            onClose={() => this.setState({ showUpdateAdditionalInformationModal: false })}
                        />
                    ) : (
                            <></>
                        )}
                </Modal>
                    */  }


}

const mapState = state => ({
    additionalinformations: state.additionalinformations.additionalinformations

});

const mapDispatchToProps = dispatch => ({
    addAdditionalInformation: dispatch.additionalinformations.addAdditionalInformation,
    getAdditionalInformations: caseId => dispatch.additionalinformations.getAdditionalInformations(caseId),

});
export default connect(
    mapState,
    mapDispatchToProps
)(AdditionalInformations);
