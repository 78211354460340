import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Button from '../../common/Button';
import { Label, ValidationError } from '../../common/form';
import { transformSections, optional } from '../../../utils';
import CustomCheckbox from '../../common/CustomCheckbox';

class NewItemTypeForm extends Component {
    state = {
        Name: '',
        Description: '',
        Sections: [],
        selectedSection: -1,
        errors: {}
    };

    onChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    updateError = event => {
        this.setState({
            ...this.state,
            errors: {
                ...this.state.errors,
                [event.target.name]: !event.target.checkValidity()
            }
        });
    };

    addSection = event => {
        event.preventDefault();

        this.setState({
            ...this.state,
            Sections: [
                ...this.state.Sections,
                {
                    SectionId: +this.state.selectedSection,
                    Required: false
                }
            ],
            selectedSection: -1
        });
    };

    updateRequiredOnSection = (sectionId, sectionRequired) => {
        this.setState({
            ...this.state,
            Sections: this.state.Sections.map(s =>
                s.SectionId !== sectionId
                    ? s
                    : {
                          ...s,
                          Required: sectionRequired
                      }
            )
        });
    };

    removeSection = sectionId => {
        this.setState({
            ...this.state,
            Sections: this.state.Sections.filter(section => section.SectionId !== sectionId)
        });
    };

    createItemType = event => {
        event.preventDefault();
        const { Name, Description, Sections } = this.state;

        this.props
            .createItemType({ Name, Description, Sections })
            .then(() => this.props.closeModal())
            .catch(() => {});
    };

    render() {
        const { closeModal, sections, gettingSections, creatingItemType } = this.props;
        const { Name, Description, selectedSection } = this.state;
        const currentSections = this.state.Sections;
        const errors = { ...this.state.errors, ...this.props.errors } || {};

        const availableSections = transformSections(sections)
            .filter(s => !s.Sections.some(ss => currentSections.some(cs => cs.SectionId === ss.Id)) && s.Sections[0].Enabled)
            .sort((s1, s2) => s1.SectionName > s2.SectionName);

        return (
            <form onSubmit={this.createItemType}>
                <div className="flex flex-col lg:flex-row px-4">
                    <div className="w-full lg:w-1/2 lg:mr-4">
                        <Label>
                            Item Template Name <strong className="text-red">&nbsp;*</strong>
                        </Label>
                        <input
                            type="text"
                            name="Name"
                            required
                            value={Name}
                            onChange={this.onChange}
                            onBlur={this.updateError}
                            className={`input-field ${errors['Name'] ? 'border-red-light' : ''}`}
                        />
                        <ValidationError>{errors['Name']}</ValidationError>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <Label>
                            Item Template Header <strong className="text-red">&nbsp;*</strong>
                        </Label>
                        <input
                            type="text"
                            name="Description"
                            required
                            value={Description}
                            onChange={this.onChange}
                            onBlur={this.updateError}
                            className={`input-field ${errors['Description'] ? 'border-red-light' : ''}`}
                        />
                        <ValidationError>{errors['Description']}</ValidationError>
                    </div>
                </div>
                <div className="w-full mt-2 px-4">
                    <div className="flex justify-between items-center">
                        <div className="relative flex-grow">
                            <select className="input-field" name="selectedSection" placeholder="" value={selectedSection} onChange={this.onChange}>
                                {gettingSections ? (
                                    <option value={selectedSection} disabled>
                                        Loading...
                                    </option>
                                ) : (
                                    [
                                        <option key="disabled-default-section" disabled value={-1}>
                                            Select a section...
                                        </option>,
                                        ...availableSections
                                            .sort((a, b) => {
                                                var x = optional(a, s => s.SectionName.toLowerCase(), '');
                                                var y = optional(b, s => s.SectionName.toLowerCase(), '');
                                                if (x < y) {
                                                    return -1;
                                                }
                                                if (x > y) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map(s => (
                                                <option key={s.Sections[0].Id} value={s.Sections[0].Id}>
                                                    {s.SectionName}
                                                </option>
                                            ))
                                    ]
                                )}
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                        <Button disabled={selectedSection === -1} icon="fas fa-plus" onClick={this.addSection} className="py-3 ml-2">
                            Add Section
                        </Button>
                    </div>
                </div>
                <div className="mt-4 px-4">
                    {currentSections.length > 0 ? (
                        <ReactTable
                            data={sections.filter(s => currentSections.some(cs => cs.SectionId === s.Id))}
                            columns={[
                                {
                                    Header: 'Section Template Name',
                                    accessor: 'SectionName',
                                    filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
                                },
                                {
                                    Header: 'Required',
                                    filterable: false,
                                    sortable: false,
                                    Cell: ({ row }) => {
                                        const currentSection = currentSections.filter(cs => cs.SectionId === row._original.Id)[0];
                                        const required = currentSection ? currentSection.Required : false;
                                        return (
                                            <div className="flex justify-center items-center h-full">
                                                <Guard permissions={[roles.ItemTypes.EDIT_ITEM_TYPES]}>
                                                    <CustomCheckbox
                                                        checked={required}
                                                        onChange={e => this.updateRequiredOnSection(row._original.Id, !!e.target.checked)}
                                                    />
                                                </Guard>
                                            </div>
                                        );
                                    }
                                },
                                {
                                    filterable: false,
                                    sortable: false,
                                    Cell: ({ row }) => (
                                        <div className="flex justify-center items-center h-full">
                                            <span
                                                className="text-red-light cursor-pointer"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    this.removeSection(row._original.Id);
                                                }}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </div>
                                    )
                                }
                            ]}
                            defaultSorted={[{ id: 'SectionName', desc: false }]}
                            pageSize={currentSections.length}
                            showPagination={false}
                        />
                    ) : (
                        <div className="text-primary">This item template has no possible sections yet.</div>
                    )}
                </div>
                <div className="flex justify-end items-end mt-4">
                    <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                        Cancel
                    </Button>
                    {!creatingItemType ? (
                        <Button type="submit">Add Item Template</Button>
                    ) : (
                        <Button disabled>
                            Adding Item Template... <i className="fas fa-spinner fa-pulse" />
                        </Button>
                    )}
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => ({
    gettingSections: state.sections._updating._gettingSections,
    sections: state.sections.sections
});

export default connect(mapStateToProps)(NewItemTypeForm);
