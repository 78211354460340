import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '../common/form/Form';
import { Input } from '../common/form';
import Button from '../common/Button';
import { baseUrl } from '../../config';

class ForgotPassword extends Component {
    state = {
        sendingEmail: false,
        errors: {}
    };
    onSubmit = async values => {
        console.log(values);
        try {
            this.setState({
                sendingEmail: true
            });

            await axios.post(`${baseUrl}/api/user/forgot-username`, values);

            toast.success('Email has been sent.');
        } catch (error) {
            console.log(error);
            this.setState({
                sendingEmail: false,
                errors: { ...error }
            });
        }

        this.setState({
            sendingEmail: false
        });
    };

    render() {
        let { sendingEmail } = this.state;

        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <Form onSubmit={this.onSubmit}>
                            <div className="mb-4">
                                <Input name="Email" type="email" label="Email" placeholder="" required={true} />
                            </div>
                            <div className="flex items-center justify-between">
                                <Button type="submit" loading={sendingEmail}>
                                    Send Username
                                </Button>
                                <Link
                                    to="/login"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </Form>
                        <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
