import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getSections = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getSectionsRequest());

    try {
        const sections = await axios.get(`${baseUrl}/api/sections`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getSectionsSuccess(sections.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.getSectionsFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const createSection = section => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createSectionRequest());

    try {
        const newSection = await axios.post(
            `${baseUrl}/api/sections`,
            {
                ...section,
                Template: JSON.stringify(section.Template)
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        dispatch(Creators.createSectionSuccess(newSection.data));
        toast.success(`${section.SectionName} (v${newSection.data.Version}) was added successfully.`);
        return Promise.resolve(newSection.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.createSectionFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const editSectionROI = section => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createSectionRequest());

    try {
        const newSection = await axios.put(
            `${baseUrl}/api/sections/${section.Id}/roi`,
            {
                ...section,
                Template: JSON.stringify(section.Template)
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        dispatch(Creators.updateXSLTSuccess(newSection.data));
        toast.success(`XSLT was updated.`);
        return Promise.resolve(newSection.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.createSectionFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const deleteSection = section => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteSectionRequest(section.Id));

    try {
        await axios.delete(`${baseUrl}/api/sections/${section.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.deleteSectionSuccess(section.Id));
        toast.success(`${section.SectionName} (v${section.Version}) was ${section.Enabled ? 'disabled' : 'enabled'} successfully.`);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.deleteSectionFailure(
                section.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

export default {
    getSections,
    createSection,
    deleteSection,
    editSectionROI
};
