import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Form } from '../common/form/Form';
import { Input } from '../common/form';

import Button from '../common/Button';
import { baseUrl } from '../../config';

class ForgotPassword extends Component {
    state = {
        sending: false
    };
    onSubmit = async values => {
        try {
            this.setState({
                sending: true
            });

            await axios.post(`${baseUrl}/api/user/forgot-password`, values);

            this.setState({
                sending: false
            });
        } catch (error) {
            this.setState({
                sending: false,
                errors: error
            });
        } finally {
            toast.success(`If an account exists for ${values.Username}, an email will be sent.`);
        }
    };
    render() {
        let { sending } = this.state;

        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <Form onSubmit={this.onSubmit}>
                            <div className="mb-4">
                                <Input name="Username" label="Username" type="text" placeholder="" required={true} />
                            </div>
                            <div className="flex items-center justify-between">
                                <Button type="submit" loading={sending}>
                                    Send Password Reset Link
                                </Button>
                                <Link
                                    to="/login"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </Form>
                    </div>
                    <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
