import React from 'react';
import { roles } from '../../constants';
import { buildDesktopNavigation, MobileNavigationItem } from '../../utils/navigation';

const casesNavigation = {
    linkText: 'Cases',
    icon: 'fa-briefcase',
    props: { to: '/cases' },
    permissions: [roles.Cases.VIEW_CASES],
    children: []
};

export const casesDesktopNavigation = buildDesktopNavigation(casesNavigation);
export const casesMobileNavigation = <MobileNavigationItem key="cases-mobile-navigation" navigation={casesNavigation} menuLevel={0} />;
