import React, { Component } from 'react';
import { FormConsumer } from '../../common/form/Form';
import { Label } from '../../common/form';

export default class OrgManager extends Component {
    state = {
        error: false
    };
    componentDidUpdate(prevProps) {
        if (prevProps.user && prevProps.user.Org && prevProps.user.Org.Id !== this.props.user.Org.Id) this.input.blur();
    }
    render = () => {
        let { name, label, disabled, required = true, user = {}, showOrgSelector, ...rest } = this.props;
        return (
            <div className="mb-4 flex-grow">
                <Label>
                    {label}
                    {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                </Label>
                <div className="relative" onClick={showOrgSelector}>
                    <FormConsumer>
                        {({ setValue }) => {
                            return (
                                <input
                                    ref={input => (this.input = input)}
                                    name={name}
                                    {...rest}
                                    value={user.Org ? user.Org.Name : ''}
                                    placeholder={label}
                                    onBlur={e => {
                                        e.preventDefault();
                                        this.setState({ error: !e.target.checkValidity() });
                                        setValue(name, user.Org ? user.Org.Name : '');
                                    }}
                                    onFocus={showOrgSelector}
                                    disabled={disabled}
                                    className={`input-field ${this.state.error ? 'border-red-light' : ''}`}
                                />
                            );
                        }}
                    </FormConsumer>
                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    };
}
