import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Guard from '../../../common/Guard';
import { roles } from '../../../../constants';
import { baseUrl } from '../../../../config';

import { makeCancelable } from '../../../../utils';
import Button from '../../../common/Button';
import OrgUsersList from './OrgUsers/OrgUsersList';
import UserCreator from '../../../identity/components/UserCreator';

class OrgUsers extends Component {
    state = {
        loadingUsers: false,
        showCreateModal: false,
        users: []
    };

    openModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    componentDidMount() {
        this.loadOrgUsers();
    }

    componentWillUnmount() {
        if (this.orgUsersPromise) this.orgUsersPromise.cancel();
    }

    loadOrgUsers = (refresh = false) => {
        const { access_token, match: { params: { orgId } } } = this.props;
        this.orgUsersPromise = makeCancelable(
            new Promise(() => {
                this.setState({
                    ...this.state,
                    loadingUsers: !refresh
                }, async () => {
                    try {
                        const users = await axios.get(`${baseUrl}/api/orgs/${orgId}/users`, {
                            headers: { Authorization: `Bearer ${access_token}` }
                        });

                        this.setState({
                            ...this.state,
                            loadingUsers: false,
                            users: users.data
                        });
                    } catch (e) {
                        toast.error(`There was an error ${refresh ? 'refreshing' : 'loading'} the users for this org.`);
                        this.setState({
                            ...this.state,
                            users: refresh ? this.state.users : [],
                            loadingUsers: false
                        });
                    }
                });
            })
        );
    };

    userUpdated = (user) => {
        this.setState({
            ...this.state,
            users: this.state.users.map(u => u.Id !== user.Id ? u : user)
        })
    };

    render() {
        const { loadingUsers, users, showCreateModal } = this.state;
        const { match: { params: { orgId } }, orgs } = this.props;
        const org = orgs.find(o => o.Id === +orgId);

        return (<>
            <div className="flex justify-end">
                <Guard permissions={[roles.Users.CREATE_USER]}>
                    <Button icon="fas fa-user-plus" inverse onClick={this.openModal}>New User</Button>
                </Guard>
            </div>
            {!loadingUsers ?
                users.length > 0 ?
                    <OrgUsersList
                        users={users}
                        orgId={orgId}
                        usersChanged={this.loadOrgUsers}
                        userUpdated={this.userUpdated} /> :
                    <div className="text-primary">There are no users for this org.</div>
                :
                <span>
                    Loading... <i className="fas fa-spinner fa-pulse" />
                </span>
            }
            {showCreateModal ?
                <UserCreator Org_Id={+orgId} onUpdated={() => {
                    this.loadOrgUsers(true);
                    this.closeModal();
                }} close={this.closeModal} /> :
                <></>
            }
        </>);
    }
};

const mapStateToProps = (state) => ({
    access_token: state.account.auth.access_token,
    orgs: state.orgs.orgs
});

export default withRouter(connect(mapStateToProps)(OrgUsers));