import React from 'react';
import CustomCheckbox from '../../../../common/CustomCheckbox';

const CheckboxConfigurer = ({ field, onChange }) => (
    <div className="flex justify-between items-center flex-wrap -mx-4">
        <div className="w-1/2 px-4">
            <label className="block text-primary text-sm mt-4 mb-2">
                <CustomCheckbox name="#text" checked={field['#text']} onChange={event => onChange(field, '#text', event.target.checked)} />
                &nbsp;&nbsp;&nbsp;Default to checked?
            </label>
        </div>
    </div>
);

export default CheckboxConfigurer;
