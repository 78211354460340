import _ from 'lodash';
import { getCheckedOrgs, getCheckedInTree } from '../../../utils';

export function orgTreeUpdated(updatedData, filters = undefined, updateFilters = undefined) {
    const oldIds = filters ? (filters.additional ? filters.additional.OrgIds : []) : [];
    const newValues = getCheckedInTree(updatedData);

    updateFilters &&
        updateFilters({
            ...filters,
            additional: {
                ...filters.additional,
                OrgIds: newValues,
                page: 0
            }
        });
    this.setState(
        {
            ...this.state,
            orgs: updatedData
        }
    );
}

export function orgTreeToggled(nodes, filters = undefined, updateFilters = undefined) {
    const timeDiff = Date.now() - nodes[0].lastChecked;
    const checkState = timeDiff <= 1000 ? !nodes[0].isChecked : nodes[0].isChecked;
    const applyCheckStateTo = (nodes, includeChildren = false) => {
        nodes.forEach(node => {
            node.isChecked = checkState;
            if (checkState) OrgIds.push(node.id);
            if (node.children && includeChildren) applyCheckStateTo(node.children);
        });
    };

    const OrgIds = [];
    if (nodes[0]) {
        // If the time difference is 700ms or less, then it is a double-click and we need
        // to update the children to what the parent is.
        if (timeDiff <= 1000 && nodes[0].children) {
            nodes[0].lastChecked = Date.now();
            applyCheckStateTo(nodes, true);
            // Otherwise we need to just update the ckecked state of the first node.
        } else {
            nodes[0].lastChecked = Date.now();
            applyCheckStateTo(nodes);
        }
    }

    updateFilters &&
        updateFilters({
            ...filters,
            additional: {
                ...filters.additional,
                OrgIds: checkState
                    ? [...(filters.additional.OrgIds || []), ...OrgIds]
                    : (filters.additional.OrgIds || []).filter(id => !OrgIds.includes(id))
            }
        });
}
