import React, { Component } from 'react';
import CaseForm from './CaseForm';
import { connect } from 'react-redux';
import axios from 'axios';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { orgOperations } from '../../orgs/duck';
import { baseUrl } from '../../../config';
import { makeCancelable, getFileName } from '../../../utils';
import { accountSelectors } from '../../account/duck';
import Button from '../../common/Button';
import Guard from '../../common/Guard';
import { roles, WorkflowState, tags } from '../../../constants';
import { toast } from 'react-toastify';
import Stepper from './Stepper';
import PageHeader from '../../common/PageHeader';
import { Divider } from '../../common/form/index';
import { Object } from 'es6-shim';

class CaseEditor extends Component {
    state = {
        loading: true,
        caseValues: {},
        errors: {},
    };

    steps = ['Awaiting Case Papers', 'Awaiting Data Entry', 'Data Entry In Progress', 'Awaiting Assignment', 'In Process', 'In Review', 'Complete'];

    async componentDidMount() {
        this.props.getOrgs();

        const {
            match: {
                params: { id },
            },
        } = this.props;

        this.props.getAttachments(id);
		this.fetchData();
        /*global $*/
    }

    async pollWorkflowState(name, message) {
        try {
            const {
                match: {
                    params: { id },
                },
            } = this.props;
            console.log(message);
            var caseData = JSON.parse(message);
            if (caseData.Id == id) {
                const response = await this.props.getWorkflowState(id);
                const name = response.data && response.data.Name;
                const dueDate = response.data && response.data.DueDate;
                const completionDate = response.data && response.data.CompletionDate;
                const caseValues = { ...this.state.caseValues, ...{ WorkflowState: name, DueDate: dueDate, CompletionDate: completionDate } };

                this.setState({
                    ...this.state,
                    caseValues,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount() {
        if (this.casePromise) {
            this.casePromise.cancel();
        }

    }

    componentDidCatch() {
        this.setState({ renderError: true });
    }

    fetchData = () => {
        const {
            match: {
                params: { id },
            },
            access_token,
        } = this.props;

        this.casePromise = makeCancelable(
            new Promise(async (resolve, reject) => {
                try {
                    const response = await axios.get(`${baseUrl}/api/cases/${id}`, { headers: { Authorization: `Bearer ${access_token}` } });
                    const data = response.data;
                    this.setState({
                        loading: false,
                        ScheduledDate: moment.utc(data.ScheduledDate).format(),
                        caseValues: {
                            Id: data.Id,
                            RequestingOfficial_Id: data.RequestingOfficial_Id,
                            Client_Id: data.Client_Id,
                            ContractType_Id: data.ContractType_Id,
                            DataEntryClerk_Id: data.DataEntryClerk_Id,
                            Reviewer_Id: data.Reviewer_Id,
                            Notes: data.Notes,
                            DataEntryComplete: data.DataEntryComplete,
                            CaseStatusType: data.CaseStatusType && data.CaseStatusType.Short,
                            WorkflowState: data.WorkflowState && data.WorkflowState.Name,
                            ReceivedDate: moment.utc(data.ReceivedDate).format(),
                            ScheduledDate: moment.utc(data.ScheduledDate).format(),
                            DueDate: moment.utc(data.DueDate).format(),
                            CompletionDate: data.CompletionDate ? moment(data.CompletionDate).format() : '',
                            Person_Id: data.Subject && data.Subject.Id,
                            FirstName: data.Subject && data.Subject.FirstName,
                            MiddleName: data.Subject && data.Subject.MiddleName,
                            LastName: data.Subject && data.Subject.LastName,
                            SSN: data.Subject && data.Subject.SSN,
                            CaseType_Id: data.CaseType_Id,
                            CaseNumber: data.CaseNumber,
                            CaseType: data.CaseType && data.CaseType.Short,
                            ContractType: data.ContractType && data.ContractType.Short,
                            Org: data.Org && data.Org.Name,
                            LockedTo_Id: data.LockedTo_Id,
                        },
                    });
                    resolve();
                } catch (error) {
                    reject();
                    this.setState({ loading: false });
                }
            })
        );
    };

    onSubmit = async (values) => {
        const { caseValues } = this.state;

        await this.props.editCase({
            caseModel: {
                Id: values.Id,
                RequestingOfficial_Id: values.RequestingOfficial_Id,
                DataEntryClerk_Id: values.DataEntryClerk_Id,
                Reviewer_Id: values.Reviewer_Id,
                CaseNumber: values.CaseNumber,
                ScheduledDate: values.ScheduledDate,
                ReceivedDate: values.ReceivedDate,
                DueDate: values.DueDate === 'Invalid date' ? null : values.DueDate,
                CompletionDate: values.CompletionDate,
                Notes: values.Notes,
                CaseType_Id: values.CaseType_Id,
            },
            subject: {
                Id: values.Person_Id,
                FirstName: values.FirstName,
                MiddleName: values.MiddleName,
                LastName: values.LastName,
                SSN: values.SSN,
            },
        });

        this.props.getItems(caseValues.Id, []);

        this.setState({ loading: true }, this.fetchData);
    };

    startDataEntry = async (id) => {
        try {
            let { user } = this.props;
            await this.props.startDataEntry(id);
            const caseValues = { ...this.state.caseValues, ...{ WorkflowState: 'DATA_ENTRY_IN_PROCESS', LockedTo_Id: user.Id } };
            this.setState({
                caseValues,
            });
        } catch (error) {
            console.log(error);
        }
    };

    discontinueCase = async (id) => {
        try {
            await this.props.Discontinue(id);
            const caseValues = { ...this.state.caseValues, ...{ WorkflowState: 'DISCONTINUED' } };
            this.setState({
                caseValues,
            });
        } catch (error) {
            console.log(error);
        }
    };

    resumeCase = async (id) => {
        try {
            const workflowState = await this.props.Resume(id);
            const caseValues = { ...this.state.caseValues, ...{ WorkflowState: workflowState } };
            this.setState({
                caseValues,
            });
        } catch (error) {
            console.log(error);
        }
    };

    toggleOnHold = async (id) => {
        try {
            await this.props.OnHold(id);
            const status = this.state.caseValues.CaseStatusType === 'HOLD' ? 'NORMAL' : 'HOLD';
            const caseValues = { ...this.state.caseValues, ...{ CaseStatusType: status } };

            this.setState(
                {
                    caseValues,
                },
                () => {
                    if (this.state.caseValues.CaseStatusType === 'HOLD') toast.success('The case has been put on hold.');
                    else toast.success('The case has been reactivated.');
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    downloadReport = async ($case) => {
        try {
            this.setState({
                downloading: true,
            });

            const response = await this.props.downloadReport($case.Id);
            let blob = await response.blob();

            FileSaver.saveAs(blob, getFileName(response.headers.get('content-disposition')));

            this.setState({
                downloading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({
                downloading: false,
            });
        }
    };
    onChange = _.debounce((name, value) => {
        if (name === 'ScheduledDate' && this.state[name] !== value) {
            this.setState({ ...this.state, ScheduledDate: value });
        } else if (name === 'CompletionDate' && !value) {
            this.setState(
                {
                    loading: true,
                },
                this.fetchData
            );
        }
    }, 300);

    onAttachmentAdded = () => {
        this.fetchData();
    };

    render() {
        const {
            user,
            saving,
            dataEntryComplete,
            startingDataEntry,
            discontinuing,
            resuming,
            puttingOnHold,
            history,
            hasPermission,
            hasRole,
        } = this.props;
        const { loading, caseValues, renderError, downloading, ScheduledDate } = this.state;

        if (renderError) return <>Unable to show this case.</>;

        const workflowState = caseValues.WorkflowState;
        const caseStatusType = caseValues.CaseStatusType;
        let activeIndex = -1;
        let disableFields = { DueDate: moment(caseValues.ScheduledDate).isSame(moment(ScheduledDate).format(), 'day') };
        const hideFields = { ContractType_Id: true, Client_Id: true };
        const requiredFields = {};
        // If the case is in an 'inactive' state, we need to lock the case down unless they have permissions to edit it, otherwise we can
        // pass through to the rest of the validation.
        if (['HOLD', 'DISCONTINUED', 'COMPLETE'].includes(workflowState) && !hasPermission([roles.Cases.EDIT_INACTIVE_CASES])) {
            Object.keys(caseValues).forEach((key) => {
                disableFields[key] = true;
            });
        } else if (user.Groups && !user.Groups.some((group) => group.Tags.some((t) => t.Name === tags.CASE_ADMIN || t.Name === tags.SYS_ADMIN))) {
            if ((workflowState === 'DATA_ENTRY_IN_PROCESS' || caseStatusType === 'HOLD') && user.Id !== caseValues.LockedTo_Id) {
                Object.keys(caseValues).forEach((key) => {
                    disableFields[key] = true;
                });
            }
        }

        if (['DISCONTINUED', 'COMPLETE'].includes(workflowState)) {
            requiredFields.CompletionDate = true;
        }

        if (!hasPermission([roles.Cases.EDIT_CASES])) {
            disableFields['save'] = true; // This will hide the save button.
            Object.keys(caseValues).forEach((key) => {
                disableFields[key] = true;
            });
        } else if (!hasRole([roles.General.SYSTEM_ADMINISTRATOR])) {
            disableFields.RequestingOfficial_Id = !hasRole([roles.Cases.CHANGE_REQUESTING_OFFICIAL]);
            disableFields.DataEntryClerk_Id = !hasRole([roles.Cases.ASSIGN_DATA_ENTRY]);
            disableFields.Reviewer_Id = !hasRole([roles.Cases.ASSIGN_REVIEWER]);
            disableFields.CompletionDate = !hasRole([roles.Cases.EDIT_CASE_COMPLETION_DATE]) || !['DISCONTINUED', 'COMPLETE'].includes(workflowState);
        }

        switch (workflowState) {
            case 'AWAITING_CASE_PAPERS':
                activeIndex = 0;
                break;
            case 'AWAITING_DATA_ENTRY':
                activeIndex = 1;
                break;
            case 'DATA_ENTRY_IN_PROCESS':
                activeIndex = 2;
                break;
            case 'AWAITING_ASSIGNMENT':
                activeIndex = 3;
                break;
            case 'IN_PROCESS':
                activeIndex = 4;
                break;
            case 'IN_REVIEW':
                activeIndex = 5;
                break;
            case 'COMPLETE':
                activeIndex = 6;
                break;
            default:
                activeIndex = 0;
                break;
        }

        return (
            <div className="w-full">
                <div className="flex justify-between w-screen">
                    <PageHeader>Case</PageHeader>
                    <div className="mr-6">
                        <Guard permissions={[roles.Cases.MARK_DATA_ENTRY_COMPLETE]}>
                            {workflowState === 'AWAITING_DATA_ENTRY' && (
                                <Button
                                    icon="fa-list-alt"
                                    className="w-full mb-2 justify-center"
                                    inverse
                                    onClick={this.startDataEntry.bind(this, caseValues.Id)}
                                    loading={startingDataEntry}
                                    disabled={loading}
                                >
                                    Start Data Entry
                                </Button>
                            )}
                            {workflowState === 'DATA_ENTRY_IN_PROCESS' && (
                                <Button
                                    icon="fa-list-alt"
                                    className="w-full mb-2 justify-center"
                                    inverse
                                    onClick={dataEntryComplete.bind(null, caseValues.Id)}
                                    disabled={loading}
                                    loading={startingDataEntry}
                                >
                                    Data Entry Complete
                                </Button>
                            )}
                        </Guard>
                        <div className="flex flex-wrap">
                            {workflowState !== WorkflowState.DISCONTINUED && workflowState !== WorkflowState.COMPLETE && (
                                <>
                                    {caseStatusType === 'NORMAL' && (
                                        <Guard permissions={[roles.Cases.PUT_CASE_ON_HOLD]}>
                                            <Button
                                                icon="fa-hourglass"
                                                className="mr-2 mb-2"
                                                inverse
                                                onClick={this.toggleOnHold.bind(this, caseValues.Id)}
                                                disabled={loading}
                                                loading={puttingOnHold}
                                            >
                                                Hold
                                            </Button>
                                        </Guard>
                                    )}
                                    {caseStatusType === 'HOLD' && (
                                        <Guard permissions={[roles.Cases.PUT_CASE_ON_HOLD]}>
                                            <Button
                                                icon="fa-check-circle"
                                                className="mr-2 mb-2"
                                                inverse
                                                onClick={this.toggleOnHold.bind(this, caseValues.Id)}
                                                disabled={loading}
                                                loading={puttingOnHold}
                                            >
                                                Reactivate
                                            </Button>
                                        </Guard>
                                    )}
                                    <Guard permissions={[roles.Cases.DISCONTINUE_CASE]}>
                                        <Button
                                            icon="fa-times"
                                            className="flex-grow justify-center mb-2"
                                            inverse
                                            onClick={this.discontinueCase.bind(this, caseValues.Id)}
                                            disabled={loading}
                                            loading={discontinuing}
                                        >
                                            Discontinue
                                        </Button>
                                    </Guard>
                                </>
                            )}
                            {workflowState === WorkflowState.DISCONTINUED && (
                                <>
                                    <Guard permissions={[roles.Cases.RESUME_CASE]}>
                                        <Button
                                            icon="fa-redo"
                                            className="flex-grow justify-center mb-2"
                                            inverse
                                            onClick={this.resumeCase.bind(this, caseValues.Id)}
                                            disabled={loading}
                                            loading={resuming}
                                        >
                                            Resume
                                        </Button>
                                    </Guard>
                                </>
                            )}
                            {(workflowState === WorkflowState.COMPLETE || hasPermission([roles.Cases.DOWNLOAD_INCOMPLETE_REPORT])) && (
                                <Guard permissions={[roles.Cases.DOWNLOAD_REPORT]}>
                                    <Button
                                        icon="fa-file-download"
                                        className="w-full justify-center mb-2"
                                        inverse
                                        onClick={this.downloadReport.bind(this, caseValues)}
                                        disabled={loading}
                                        loading={downloading}
                                    >
                                        Download Report
                                    </Button>
                                </Guard>
                            )}
                            <Button
                                onClick={() => {
                                    this.setState(
                                        {
                                            loading: true,
                                        },
                                        this.fetchData
                                    );
                                }}
                                inverse
                                icon="fa-redo"
                                loading={loading}
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="w-full bg-white flex flex-wrap">
                    <div className="flex p-4 cursor-pointer" onClick={history.goBack}>
                        <i className=" fas fa-arrow-left text-xl mr-4" /> <span className="uppercase">Back</span>
                    </div>
                    <div className="container">
                        <div className="xl:flex hidden flex-wrap w-full mb-4">
                            <div className="flex w-full mb-2 scrolling-touch">
                                <Stepper steps={this.steps} activeIndex={activeIndex} />
                            </div>
                        </div>
                        <div className="bg-white py-4 m-auto">
                            {loading || Object.keys(caseValues).length === 0 ? (
                                <>
                                    Loading... <i className="fas fa-spinner fa-pulse" />
                                </>
                            ) : (
                                <CaseForm
                                    user={user}
                                    onSubmit={this.onSubmit}
                                    defaultValues={caseValues}
                                    requiredFields={requiredFields}
                                    disableFields={disableFields}
                                    hideFields={{
                                        ...hideFields,
                                        CaseTemplate_Id: !hasRole(['System Administrator']),
                                    }}
                                    isSaving={saving}
                                    showDates={true}
                                    showAttachments={true}
                                    showMessages={true}
                                    showItems={true}
                                    onChange={this.onChange}
                                    onAttachmentAdded={this.onAttachmentAdded}
                                    canChangeCaseType={hasPermission([roles.Cases.CHANGE_CASE_TYPE])}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    access_token: state.account.auth.access_token,
    saving: state.cases._updating._savingCase,
    startingDataEntry: state.cases._updating._startingDataEntry,
    discontinuing: state.cases._updating._discontinuing,
    resuming: state.cases._updating._resuming,
    puttingOnHold: state.cases._updating._puttingOnHold,
    user: state.account.user,
    hasPermission: (permissions) => accountSelectors.hasPermission(state.account, permissions),
    hasRole: (roles) => accountSelectors.hasRole(state.account, roles),
});

const mapDispatch = (dispatch) => ({
    getOrgs: () => dispatch(orgOperations.getOrgs()),
    getAttachments: (caseId) => dispatch.cases.getAttachments(caseId),
    getRecipients: (caseId) => dispatch.messages.getRecipients(caseId),
    getMessages: (caseId) => dispatch.messages.getMessages(caseId),
    editCase: (payload) => dispatch.cases.editCase(payload),
    startDataEntry: dispatch.cases.startDataEntry,
    dataEntryComplete: dispatch.cases.dataEntryComplete,
    Discontinue: dispatch.cases.Discontinue,
    Resume: dispatch.cases.Resume,
    OnHold: dispatch.cases.OnHold,
    getWorkflowState: dispatch.cases.getWorkflowState,
    downloadReport: dispatch.cases.downloadReport,
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS }),
});
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch
    )(CaseEditor)
);
