import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import numeral from 'numeral';
import InvoiceExpenseList from './InvoiceExpenseList';
import CustomCheckbox from '../../common/CustomCheckbox';

const calculateCaseCost = _case =>
    _case.Cost + _case.Expenses.reduce((total, expense) => (expense.Passthrough ? total + (expense.Override || expense.Cost) : total), 0);

class InvoiceCaseList extends Component {
    state = {
        expanded: {}
    };

    expandRow = rowIndex => {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    };

    render() {
        const { cases, checkedCases } = this.props;
        const { expanded } = this.state;

        if (!cases) return <div />;

        const allChecked = cases.every(c => checkedCases.includes(c.Id));

        const columns = [
            {
                minWidth: 25,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center">
                        <CustomCheckbox
                            name={row._original.Id}
                            checked={checkedCases.includes(row._original.Id)}
                            onChange={event => {
                                event.stopPropagation();
                                this.props.updateChecked(event.nativeEvent);
                            }}
                        />
                    </div>
                ),
                Header: () => (
                    <div className="flex justify-center items-center">
                        <CustomCheckbox
                            name="CHECK_ALL"
                            checked={allChecked}
                            onChange={event => {
                                event.stopPropagation();
                                this.props.updateChecked(event.nativeEvent);
                            }}
                        />
                    </div>
                )
            },
            { Header: 'Case #', accessor: 'CaseNumber' },
            {
                id: 'Subject',
                Header: 'Subject',
                accessor: d => (d.Subject ? `${d.Subject.FirstName} ${d.Subject.LastName}`.replace('(Palmetto)', '') : ''),
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                Header: 'Completed On',
                accessor: 'CompletionDate',
                Cell: ({ value }) => <div className="text-center">{value ? moment.utc(value).format('MM/DD/YYYY') : ''}</div>
            },
            {
                Header: 'Discontinued?',
                accessor: 'Discontinued',
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                id: 'TotalCost',
                Header: 'Total Cost',
                Cell: ({ row }) => <div className="text-center font-mono">{numeral(calculateCaseCost(row._original)).format('$0,0.00')}</div>,
                Footer: (
                    <div className="text-center font-mono font-bold">
                        {numeral(cases.reduce((total, _case) => (total += calculateCaseCost(_case)), 0)).format('$0,0.00')}
                    </div>
                )
            }
        ];

        return (
            <>
                <div className="bg-white rounded shadow p-4 mb-4 overflow-x-auto">
                    <ReactTable
                        data={cases}
                        columns={columns}
                        pageSize={cases.length}
                        showPagination={false}
                        expanded={expanded}
                        defaultSorted={[{ id: 'CompletionDate', desc: true }]}
                        sortable={false}
                        filterable={false}
                        getTfootProps={() => ({
                            style: { boxShadow: 'none' }
                        })}
                        SubComponent={({ row }) => <InvoiceExpenseList expenses={row._original.Expenses} />}
                        getTdProps={(state, rowInfo, column) => ({
                            onClick: e => {
                                if (typeof column.Header === 'string' || column.expander) this.expandRow(rowInfo.viewIndex);
                            }
                        })}
                    />
                </div>
            </>
        );
    }
}

export default InvoiceCaseList;
