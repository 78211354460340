import React, { createContext, Component } from 'react';
import _ from 'lodash';

// React.createContext will be available on React 16.3
// If you want to use Context with React 16.2,
// use 'create-react-context' as a polyfill.
//
// import createContext from 'create-react-context';

const ValuesContext = createContext({});
const ErrorsContext = createContext({});
const SetValueContext = createContext(() => {});

function unFlatten(data) {
    var result = {};
    for (var i in data) {
        var keys = i.split('.');
        keys.reduce(function(r, e, j) {
            return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : []);
        }, result);
    }
    return result;
}

export class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.defaultValues || {},
            errors: props.errors || {}
        };
    }

    static getDerivedStateFromProps({ defaultValues = {}, values = {}, errors = {} }, prevState) {
        return {
            values: { ...defaultValues, ...prevState.values, ...values },
            errors: errors || {}
        };
    }

    componentDidMount() {
        this.props.provideConnection && this.props.provideConnection(this);
    }

    componentWillUnmount() {
        this.props.provideConnection && this.props.provideConnection(undefined);
    }

    componentDidUpdate() {
        if (this.props.onUpdate) this.props.onUpdate();
    }

    render() {
        const { children, className, noValidate, ...rest } = this.props;
        const setValue = this._setValue.bind(this);
        const removeValue = this._removeValue.bind(this);
        const forceSubmit = this._forceSubmit.bind(this);

        return (
            <ValuesContext.Provider value={this.state.values}>
                <ErrorsContext.Provider value={this.state.errors}>
                    <SetValueContext.Provider value={{ setValue, removeValue, forceSubmit }}>
                        <form className={className || ''} onSubmit={this.onSubmit} noValidate={!!noValidate}>
                            {children}
                        </form>
                    </SetValueContext.Provider>
                </ErrorsContext.Provider>
            </ValuesContext.Provider>
        );
    }

    _setValue(name, value, cb = () => {}) {
        this.setState(
            {
                ...this.state,
                values: {
                    ...this.state.values,
                    [name]: value
                }
            },
            cb
        );
    }

    _removeValue(name, cb = () => {}) {
        this.setState({ values: { ...this.state.values, [name]: undefined }, errors: { ...this.state.errors, [name]: undefined } }, cb);
    }

    _clear() {
        this.setState({
            values: {},
            errors: {}
        });
    }

    _forceSubmit() {
        this.props.onSubmit(unFlatten(this.state.values), { clear: this._clear.bind(this), forced: true });
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(unFlatten(this.state.values), { clear: this._clear.bind(this), forced: false });
    };
}

export class GhostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.defaultValues || {},
            errors: props.errors || {}
        };
    }

    static getDerivedStateFromProps({ defaultValues = {}, errors = {} }, prevState) {
        return {
            values: { ...defaultValues, ...prevState.values },
            errors
        };
    }

    componentDidMount() {
        this.props.provideConnection && this.props.provideConnection(this);
    }

    componentWillUnmount() {
        this.props.provideConnection && this.props.provideConnection(undefined);
    }

    render() {
        const { children, ...rest } = this.props;
        const setValue = this._setValue.bind(this);
        const removeValue = this._removeValue.bind(this);
        const forceSubmit = this._forceSubmit.bind(this);

        return (
            <ValuesContext.Provider value={this.state.values}>
                <ErrorsContext.Provider value={this.state.errors}>
                    <SetValueContext.Provider value={{ setValue, removeValue, forceSubmit }}>{children}</SetValueContext.Provider>
                </ErrorsContext.Provider>
            </ValuesContext.Provider>
        );
    }

    _setValue(name, value, cb = () => {}) {
        this.setState(
            {
                ...this.state,
                values: {
                    ...this.state.values,
                    [name]: value
                }
            },
            cb
        );
    }

    _removeValue(name, cb = () => {}) {
        const values = this.state.values || {};
        const errors = this.state.errors || {};
        delete values[name];
        delete errors[name];
        this.setState({ values: { ...values }, errors: { ...errors } }, cb);
    }

    _clear() {
        this.setState({
            values: {},
            errors: {}
        });
    }

    _forceSubmit() {
        this.props.onSubmit(unFlatten(this.state.values), { clear: this._clear.bind(this), forced: true });
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(unFlatten(this.state.values), { clear: this._clear.bind(this), forced: false });
    };
}

export const FormConsumer = ({ children }) => {
    return (
        <ErrorsContext.Consumer>
            {errors => (
                <ValuesContext.Consumer>
                    {values => (
                        <SetValueContext.Consumer>
                            {({ setValue, removeValue, forceSubmit }) => children({ errors, values, setValue, removeValue, forceSubmit })}
                        </SetValueContext.Consumer>
                    )}
                </ValuesContext.Consumer>
            )}
        </ErrorsContext.Consumer>
    );
};
