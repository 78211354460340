import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import axios from 'axios';
import _ from 'lodash';
import Select from 'react-select';
import { makeCancelable } from '../../../utils';
import { baseUrl } from '../../../config';
import { Label } from './';
import { FormConsumer } from './Form';

class FetchSelector extends Component {
    state = {
        loading: false,
        errors: null,
        options: []
    };

    async componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.fetchData();
        }
    }
    componentWillUnmount() {
        if (this.fetchPromise) this.fetchPromise.cancel();
    }

    fetchData = async () => {
        const { access_token, url, valueKey, textKey } = this.props;

        this.fetchPromise = makeCancelable(
            new Promise(r =>
                this.setState(
                    {
                        ...this.state,
                        errors: null,
                        loading: true
                    },
                    async () => {
                        try {
                            const options = await axios.get(`${baseUrl}/api/${encodeURI(url)}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });
                            this.setState({
                                ...this.state,
                                errors: null,
                                options: options.data
                                    .map(option => ({
                                        ...option,
                                        label: typeof textKey === 'string' || textKey instanceof String ? option[textKey] : textKey(option),
                                        value: typeof valueKey === 'string' || valueKey instanceof String ? option[valueKey] : valueKey(option)
                                    }))
                                    .sort((a, b) => {
                                        var x = a.label.toLowerCase();
                                        var y = b.label.toLowerCase();
                                        if (x < y) {
                                            return -1;
                                        }
                                        if (x > y) {
                                            return 1;
                                        }
                                        return 0;
                                    }),
                                loading: false
                            });
                        } catch (e) {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    }
                )
            )
        );
    };

    render() {
        const { label, name, valueKey, textKey, onChange, errors, value, disabled, required, isDisabled, customPlaceholder, ...rest } = this.props;
        const { loading, options } = this.state;

        return (
            <FormConsumer>
                {({ errors = {}, values, setValue, removeValue }) => (
                    <div>
                        {' '}
                        <Label>
                            {label} {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                        </Label>
                        <div className="relative">
                            <Select
                                {...rest}
                                options={options}
                                isLoading={loading}
                                placeholder="Select an option"
                                onChange={value => setValue(name, value[valueKey])}
                                defaultValue={values[name]}
                                disabled={disabled}
                                required={required}
                            />
                        </div>
                        {errors[name] ? <p className="text-red text-xs italic">{errors[name]}</p> : null}
                    </div>
                )}
            </FormConsumer>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(FetchSelector);
