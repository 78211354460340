import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import convert from 'xml-js';
import FileSaver from 'file-saver';
import ReactTable from 'react-table';
import _ from 'lodash';
import { Form, FormConsumer } from '../common/form/Form';
import { Checkbox, DatePicker, CustomSelect, Input } from '../common/form';
import Button from '../common/Button';

class Reports extends Component {
    state = {
        folders: [],
        report: {},
        columns: [],
        data: [],
        gettingReports: true,
        loadingReport: false,
        generatingReport: false
    };
    async componentDidMount() {
        try {
            await this.props.getReports();
            this.setState({
                ...this.state,
                gettingReports: false
            });
        } catch (error) {
            this.setState({
                ...this.state,
                gettingReports: false
            });
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { reports } = props;
        const folders = _.groupBy(reports, 'Group');

        return {
            ...state,
            folders
        };
    }

    getReport = async (group, name) => {
        try {
            this.setState({
                loadingReport: true,
                currentReport: name,
                tipMessage: ''
            });

            const response = await this.props.getReport(group, name);
            let defaultValues = {};
            response.data.Parameters.forEach(param => {
                if (param.validValuesField && param.validValuesField.length > 0) {
                    defaultValues[param.nameField] = param.validValuesField
                        .map(valuesField => ({
                            value: valuesField.valueField,
                            label: valuesField.labelField
                        }))
                        .find(valuesField => {
                            return (
                                valuesField.value ===
                                (Array.isArray(param.defaultValuesField) ? param.defaultValuesField[0] : param.defaultValuesField)
                            );
                        });
                } else if (Array.isArray(param.defaultValuesField)) {
                    if (param.parameterTypeNameField === 'Boolean') {
                        defaultValues[param.nameField] = param.defaultValuesField[0] === 'True' ? true : false;
                    } else {
                        defaultValues[param.nameField] = param.defaultValuesField[0];
                    }
                } else defaultValues[param.nameField] = param.defaultValuesField || '';
            });

            this.setState({
                loadingReport: false,
                defaultValues,
                report: {
                    Group: response.data.Group,
                    Name: response.data.Name,
                    Parameters: response.data.Parameters.sort(option => (option.ParameterTypeName === 'Boolean' ? -1 : 0))
                }
            });
        } catch (error) {
            console.log(error);
            this.setState({
                errors: error,
                loadingReport: false
            });
        }
    };

    submit = async values => {
        const { report } = this.state;
        let params = {
            group: report.Group,
            name: report.Name,
            Parameters: []
        };
        report.Parameters.forEach(param => {
            let value = values[param.nameField];

            if (param.nullableField && (values[param.nameField] == null || values[param.nameField] === '')) {
                value = null;
            } else if (moment(values[param.nameField], moment.ISO_8601, true).isValid()) {
                value = moment(values[param.nameField])
                    .utc()
                    .format('MM/DD/YYYY');
            } else if (typeof values[param.nameField] === 'object' && values[param.nameField].hasOwnProperty('value')) {
                value = values[param.nameField].value;
            }

            params.Parameters.push({
                Name: param.nameField,
                Value: value
            });
        });

        const tipTimeout = setTimeout(() => {
            this.setState({
                tipMessage: 'If the report is taking a while to download, add more parameters to narrow the fieldset.'
            });
        }, 30000);

        try {
            this.setState({
                generatingReport: true
            });

            const file = await this.props.generateReport(report.Group, report.Name, params, values.Preview ? 'xml' : values.Format.value);
            let blob = await file.blob();

            clearTimeout(tipTimeout);

            this.setState({
                tipMessage: ''
            });

            if (values.Preview) {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const result = JSON.parse(convert.xml2json(reader.result, { compact: true, spaces: 4 }));
                        let path = ['Report'];

                        // Find the path with the data
                        while (!Array.isArray(_.get(result, path)) && _.get(result, path)) {
                            path.push(Object.keys(_.get(result, path)).pop());
                        }

                        const rows = _.get(result, path, []);

                        if (rows && rows.length > 0) {
                            let columns = [];

                            if (rows[0].Details_Collection) {
                                const columnKey = Object.keys(rows[0]._attributes || {}).find(key => {
                                    return rows[0]._attributes[key].split(/  +/g).length > 0;
                                });

                                columns = rows[0]._attributes[columnKey].split(/  +/g).map(col => {
                                    const colName = col.split(':').shift();
                                    return {
                                        Header: colName,
                                        accessor: colName
                                    };
                                });
                                const mappedRows = rows.map(row => {
                                    const obj = {};
                                    const colObj = {};
                                    row._attributes[columnKey].split(/  +/g).forEach(col => {
                                        const key = col.split(':').shift();
                                        const value = col.split(':').pop();
                                        colObj[key] = value;
                                    });
                                    columns.forEach(col => {
                                        obj[col.accessor] = colObj[col.accessor];
                                        row.Details_Collection.Details = Array.isArray(row.Details_Collection.Details)
                                            ? row.Details_Collection.Details
                                            : [row.Details_Collection.Details];

                                        obj.Nested = {
                                            columns: Object.keys(_.get(row, 'Details_Collection.Details[0]._attributes', {})).map(col => ({
                                                Header: _.words(col).join(' '),
                                                accessor: col
                                            })),
                                            rows: row.Details_Collection.Details.map(detail => detail._attributes)
                                        };
                                    });

                                    return obj;
                                });

                                this.setState({
                                    columns,
                                    data: mappedRows,
                                    nested: true
                                });
                            } else {
                                const mappedRows = rows.map(row => ({ ...row._attributes }));
                                columns = Object.keys(mappedRows[0] || {}).map(col => ({ Header: _.words(col).join(' '), accessor: col }));

                                this.setState({
                                    columns,
                                    data: mappedRows,
                                    nested: false
                                });
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                };
                reader.readAsText(blob);
            } else {
                FileSaver.saveAs(blob, `${report.Name}.${values.Format.value}`);
            }
            this.setState({
                generatingReport: false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                generatingReport: false
            });
        }
    };

    render() {
        const {
            gettingReports,
            folders,
            loadingReport,
            report,
            defaultValues,
            generatingReport,
            columns,
            data,
            nested,
            currentReport,
            tipMessage
        } = this.state;
        return (
            <div className="container">
                <div className="flex mb-4 justify-between -mx-3">
                    <div className="px-3 sm:w-1/2 h-100 flex">
                        <div className="bg-white rounded shadow my-4 p-4 mb-4 w-full">
                            <h1 className="text-base mb-4 text-primary">Reports</h1>
                            {gettingReports ? (
                                <>
                                    <i className="fa fa-spinner fa-pulse" />
                                </>
                            ) : (
                                    Object.keys(folders).map(key => (
                                        <div key={key}>
                                            <div>
                                                <i className="fa fa-folder mr-2" />
                                                {key}
                                            </div>
                                            {folders[key].map(r => (
                                                <div
                                                    key={r.Name}
                                                    className={`pl-4 py-2 hover:bg-grey-lightest cursor-pointer ${
                                                        currentReport === r.Name ? 'bg-grey-lightest' : ''
                                                        }`}
                                                    onClick={this.getReport.bind(this, key, r.Name)}
                                                >
                                                    <i className="fa fa-file mr-2" />
                                                    {r.Name}
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                )}
                        </div>
                    </div>
                    <div className="px-3 sm:w-1/2 h-100 flex">
                        <div className="bg-white rounded shadow my-4 p-4 mb-4 w-full">
                            <h1 className="text-base mb-4 text-primary">{currentReport || 'Click on a report to get started'}</h1>
                            {loadingReport ? (
                                <i className="fa fa-spinner fa-pulse" />
                            ) : (
                                    <Form onSubmit={this.submit} defaultValues={defaultValues}>
                                        <div className="flex flex-col">
                                            {report.Parameters &&
                                                report.Parameters.filter(param => param.promptField).map(param => {
                                                    switch (param.parameterTypeNameField) {
                                                        case 'Boolean':
                                                            return (
                                                                <div className="mb-4 flex-grow" key={param.nameField}>
                                                                    <Checkbox
                                                                        name={param.nameField}
                                                                        label={param.promptField}
                                                                        required={!param.nullableField}
                                                                    />
                                                                </div>
                                                            );
                                                        case 'DateTime':
                                                            return (
                                                                <div className="mb-4 flex-grow DayPickerModal" key={param.nameField}>
                                                                    <DatePicker
                                                                        name={param.nameField}
                                                                        label={param.promptField}
                                                                        required={!param.nullableField}
                                                                        showLabel={true}
                                                                    />
                                                                </div>
                                                            );
                                                        case 'Integer':
                                                        case 'String':
                                                            if (param.validValuesField && param.validValuesField.length > 0) {
                                                                return (
                                                                    <div className="mb-4 flex-grow" key={param.nameField}>
                                                                        <CustomSelect
                                                                            name={param.nameField}
                                                                            label={param.promptField}
                                                                            required={!param.nullableField}
                                                                            options={param.validValuesField.map(f => ({
                                                                                value: f.valueField,
                                                                                label: f.labelField
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            return (
                                                                <div className="mb-4 flex-grow" key={param.nameField}>
                                                                    <Input
                                                                        name={param.nameField}
                                                                        label={param.promptField}
                                                                        required={!param.nullableField}
                                                                    />
                                                                </div>
                                                            );
                                                        default:
                                                            return <></>;
                                                    }
                                                })}
                                        </div>
                                        {report.Parameters && (
                                            <div className="flex -mx-3 flex-wrap">
                                                <div className="md:w-1/2 w-full px-3">
                                                    <CustomSelect
                                                        name="Format"
                                                        label="Format"
                                                        options={[
                                                            {
                                                                value: 'csv',
                                                                label: 'Excel/CSV'
                                                            },
                                                            {
                                                                value: 'pdf',
                                                                label: 'PDF'
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="md:w-1/2 px-3 flex items-center">
                                                    <FormConsumer>
                                                        {({ values, setValue }) => (
                                                            <>
                                                                <Button
                                                                    type="submit"
                                                                    disabled={values.Format ? false : true}
                                                                    loading={generatingReport}
                                                                    className="mt-3 mr-2"
                                                                    onClick={() => {
                                                                        setValue('Preview', false);
                                                                    }}
                                                                >
                                                                    Download Report
                                                            </Button>
                                                                <Button
                                                                    type="submit"
                                                                    loading={generatingReport}
                                                                    className="mt-3"
                                                                    onClick={() => {
                                                                        setValue('Preview', true);
                                                                    }}
                                                                >
                                                                    View
                                                            </Button>
                                                            </>
                                                        )}
                                                    </FormConsumer>
                                                </div>
                                            </div>
                                        )}
                                    </Form>
                                )}
                            <em>{tipMessage}</em>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-full">
                        <div className="bg-white rounded shadow p-4 mb-4 overflow-x-auto">
                            {columns.length > 0 ? (
                                <ReactTable
                                    columns={columns}
                                    data={data}
                                    filterable
                                    defaultPageSize={15}
                                    SubComponent={
                                        nested
                                            ? row => {
                                                if (!row.original.Nested) return null;
                                                return (
                                                    <div className="px-8 py-4 my-4 bg-grey-lighter rounded shadow-inner">
                                                        <div className="bg-white shadow rounded p-4 w-full">
                                                            <ReactTable
                                                                columns={row.original.Nested.columns}
                                                                data={row.original.Nested.rows}
                                                                filterable
                                                                defaultPageSize={10}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            : null
                                    }
                                />
                            ) : (
                                    <h1 className="text-base text-center text-primary">Click view to see the generated report here</h1>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = state => ({
    reports: state.reports.reports
});

const mapDispatch = dispatch => ({
    getReports: dispatch.reports.getReports,
    getReport: (group, name) => dispatch.reports.getReport({ group, name }),
    generateReport: (group, name, params, format) => dispatch.reports.generateReport({ group, name, params, format })
});
export default connect(
    mapState,
    mapDispatch
)(Reports);
