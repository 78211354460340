const GET_SECTIONS_REQUEST = 'app/sections/GET_SECTIONS_REQUEST';
const GET_SECTIONS_SUCCESS = 'app/sections/GET_SECTIONS_SUCCESS';
const GET_SECTIONS_FAILURE = 'app/sections/GET_SECTIONS_FAILURE';

const CREATE_SECTION_REQUEST = 'app/sections/CREATE_SECTION_REQUEST';
const CREATE_SECTION_SUCCESS = 'app/sections/CREATE_SECTION_SUCCESS';
const CREATE_SECTION_FAILURE = 'app/sections/CREATE_SECTION_FAILURE';

const DELETE_SECTION_REQUEST = 'app/sections/DELETE_SECTION_REQUEST';
const DELETE_SECTION_SUCCESS = 'app/sections/DELETE_SECTION_SUCCESS';
const DELETE_SECTION_FAILURE = 'app/sections/DELETE_SECTION_FAILURE';

const UPDATE_XSLT_SUCCESS = 'app/sections/UPDATE_XSLT_EDITOR';

export default {
    GET_SECTIONS_REQUEST,
    GET_SECTIONS_SUCCESS,
    GET_SECTIONS_FAILURE,
    CREATE_SECTION_REQUEST,
    CREATE_SECTION_SUCCESS,
    CREATE_SECTION_FAILURE,
    DELETE_SECTION_REQUEST,
    DELETE_SECTION_SUCCESS,
    DELETE_SECTION_FAILURE,
    UPDATE_XSLT_SUCCESS
};
