import { roles } from '../../constants';
import { buildDesktopNavigation } from '../../utils/navigation';

export const orgsNavigation = {
    linkText: 'Orgs',
    props: { to: '/orgs' },
    permissions: [roles.Orgs.EDIT_ORGANIZATION],
    children: []
};

export const orgsDesktopNavigation = buildDesktopNavigation(orgsNavigation);
