import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import Navigation from './Navigation';
import { accountSelectors } from '../account/duck';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        const { loggedIn, location, children } = this.props;
        const { hasError } = this.state;

        if (!loggedIn)
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: location }
                    }}
                />
            );

        return (
            <div className="h-full w-full flex flex-col">
                <Navigation />
                <div className="py-8 flex-grow pt-20">{hasError ? <h1>Unable to display this page.</h1> : children}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: accountSelectors.loggedIn(state.account)
});

export default withRouter(connect(mapStateToProps)(Layout));
