import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { Label } from '../../../../common/form';
import CustomCheckbox from '../../../../common/CustomCheckbox';

class DateConfigurer extends Component {
    state = {
        errors: {}
    };

    updateError = event => {
        this.setState({
            ...this.state,
            errors: {
                ...this.state.errors,
                [event.target.name]: !event.target.checkValidity()
            }
        });
    };

    onDayChange = (name, day) => {
        const { field, onChange } = this.props;
        onChange(field, name, day);
    };

    onChange = event => {
        const { field, onChange } = this.props;
        onChange(field, event.target.name, event.target.type !== 'checkbox' ? event.target.value : event.target.checked);
    };

    render() {
        const { field } = this.props;

        return (
            <>
                <div className="flex justify-between items-center -mx-4">
                    <div className="w-1/2 px-4 relative">
                        <Label>Disable Dates Before</Label>
                        <div className="DayPickerModal">
                            <DayPickerInput
                                inputProps={{ disabled: field['@disableDatesBeforeToday'] }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder="Choose a date..."
                                format="MMMM DD, YYYY"
                                value={field['@disableDatesBefore'] ? formatDate(field['@disableDatesBefore'], 'MMMM DD, YYYY') : ''}
                                onDayChange={day => this.onDayChange('@disableDatesBefore', day)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 px-4 relative">
                        <label className="block text-primary text-sm mt-4 mb-2">
                            <CustomCheckbox name="@disableDatesBeforeToday" checked={field['@disableDatesBeforeToday']} onChange={this.onChange} />
                            &nbsp;&nbsp;&nbsp;Disable Dates Before Today
                        </label>
                    </div>
                </div>

                <div className="flex justify-between items-center -mx-4 mt-4">
                    <div className="w-1/2 px-4 relative">
                        <Label>Disable Dates After</Label>
                        <div className="DayPickerModal">
                            <DayPickerInput
                                inputProps={{ disabled: field['@disableDatesAfterToday'] }}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder="Choose a date..."
                                format="MMMM DD, YYYY"
                                value={field['@disableDatesAfter'] ? formatDate(field['@disableDatesAfter'], 'MMMM DD, YYYY') : ''}
                                onDayChange={day => this.onDayChange('@disableDatesAfter', day)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 px-4 relative">
                        <label className="block text-primary text-sm mt-4 mb-2">
                            <CustomCheckbox name="@disableDatesAfterToday" checked={field['@disableDatesAfterToday']} onChange={this.onChange} />
                            &nbsp;&nbsp;&nbsp;Disable Dates After Today
                        </label>
                    </div>
                </div>
                <div className="flex justify-between items-center -mx-4 mt-4">
                    <div className="w-1/2 px-4">
                        <label className="block text-primary text-sm mb-2">
                            <CustomCheckbox name="@allowCurrent" checked={field['@allowCurrent']} onChange={this.onChange} />
                            &nbsp;&nbsp;&nbsp;Allow "Current"
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

export default DateConfigurer;
