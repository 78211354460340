import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';
import moment from 'moment';

export const items = {
    state: {
        items: {},
        _errors: {},
        _updating: {
            _gettingItems: {},
            _creatingItem: false,
            _deletingItem: false,
            _assigningItems: false,
            _updatingItems: false,
            _swappingItems: false
        }
    },
    reducers: {
        /* General Items */
        startUpdatingForKey: (state, key) => ({
            ...state,
            _updating: { ...state._updating, [key]: true }
        }),
        stopUpdatingForKey: (state, key) => ({
            ...state,
            _updating: { ...state._updating, [key]: false }
        }),

        getAllItemsRequest: state => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    _all: true
                }
            }
        }),
        getAllItemsSuccess: (state, items) => ({
            ...state,
            _errors: {},
            items: {
                ...state.items,
                _all: items
            },
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    _all: false
                }
            }
        }),
        getAllItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    _all: false
                }
            }
        }),

        getItemsRequest: (state, caseId) => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    [caseId]: true
                }
            }
        }),
        getItemsSuccess: (state, { caseId, items }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: items
            },
            _errors: {},
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    [caseId]: false
                }
            }
        }),
        getItemsFailure: (state, { caseId, errors }) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating,
                _gettingItems: {
                    ...state._updating._gettingItems,
                    [caseId]: false
                }
            }
        }),

        createItemRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _creatingItem: true }
        }),
        createItemSuccess: (state, { caseId, item }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: [...(state.items[caseId] || []), item]
            },
            _errors: {},
            _updating: { ...state._updating, _creatingItem: false }
        }),
        createItemFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _creatingItem: false }
        }),

        deleteItemRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _deletingItem: true }
        }),
        deleteItemSuccess: (state, { caseId, item }) => ({
            ...state,
            items: {
                ...{
                    ...state.items,
                    _all: state.items._all && state.items._all.filter(i => i.Id !== item.Id)
                },
                [caseId]: (state.items[caseId] || [])
                    .filter(i => i.Id !== item.Id)
                    .sort((a, b) => a.DisplayId > b.DisplayId)
                    .map((item, index) => ({ ...item, DisplayId: index + 1 }))
            },
            _errors: {},
            _updating: { ...state._updating, _deletingItem: false }
        }),
        deleteItemFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _deletingItem: false }
        }),

        updateAcdsForItemsRequest: state => ({
            ...state,
            _errors: {}
        }),
        updateAcdsForItemsSuccess: (state, { caseId, itemIds, ACD }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: (state.items[caseId] || []).map(i =>
                    !itemIds.includes(i.Id)
                        ? i
                        : {
                              ...i,
                              ACD
                          }
                )
            },
            _errors: {}
        }),
        updateAcdsForItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors
        }),

        /* Item Assignment */

        assignItemsRequest: state => ({
            ...state,
            _errors: {}
        }),
        assignItemsSuccess: (state, { caseId, itemIds, AssignedTo, ItemStatus }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: (state.items[caseId] || []).map(i =>
                    !itemIds.includes(i.Id)
                        ? i
                        : {
                              ...i,
                              AssignedTo,
                              ItemStatus
                          }
                )
            },
            _errors: {}
        }),
        assignItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors
        }),

        /* Item Workflow */
        acceptItemsRequest: state => ({
            ...state,
            _errors: {}
        }),
        acceptItemsSuccess: (state, { caseId, itemIds, ItemStatus }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: (state.items[caseId] || []).map(i =>
                    !itemIds.includes(i.Id)
                        ? i
                        : {
                              ...i,
                              ItemStatus,
                              CompletedDate: moment().format()
                          }
                )
            },
            _errors: {}
        }),
        acceptItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors
        }),
        rejectItemsRequest: state => ({
            ...state,
            _errors: {}
        }),
        submitItemsRequest: state => ({
            ...state,
            _errors: {}
        }),
        submitItemsFailure: state => ({
            ...state,
            _errors: {}
        }),
        submitItemsSuccess: state => ({
            ...state,
            _errors: {}
        }),
        rejectItemsSuccess: (state, { caseId, itemIds, ItemStatus }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: (state.items[caseId] || []).map(i =>
                    !itemIds.includes(i.Id)
                        ? i
                        : {
                              ...i,
                              ItemStatus,
                              CompletedDate: null
                          }
                )
            },
            _errors: {}
        }),
        rejectItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors
        }),

        swapItemsRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _swappingItems: true }
        }),
        swapItemsSuccess: (state, { caseId, swapResult }) => ({
            ...state,
            items: {
                ...state.items,
                [caseId]: (state.items[caseId] || []).map(item =>
                    swapResult[item.Id]
                        ? {
                              ...item,
                              DisplayId: +swapResult[item.Id]
                          }
                        : item
                )
            },
            _errors: {},
            _updating: { ...state._updating, _swappingItems: false }
        }),
        swapItemsFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _swappingItems: false }
        })
    },
    effects: dispatch => ({
        async getAllItems(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            dispatch.items.getAllItemsRequest();
            try {
                const response = await axios.post(`${baseUrl}/api/items/search`, payload, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.items.getAllItemsSuccess(response.data.items);
                return response;
            } catch (error) {
                if (error && error.response)
                    dispatch.items.getAllItemsFailure(
                        error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                    );
                throw error;
            }
        },
        async getItems({ caseId, ItemStateIDS = [] }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.getItemsRequest(caseId);

            try {
                const items = await axios.get(
                    `${baseUrl}/api/cases/${caseId}/items${ItemStateIDS.length > 0 ? `?ItemStateIDS=${ItemStateIDS.join(',')}` : '?ItemStateIDS='}`,
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                dispatch.items.getItemsSuccess({ caseId, items: items.data });
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.getItemsFailure({
                    caseId,
                    errors: error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                });
            }
        },
        async createItem({ _case, item }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.createItemRequest();

            try {
                const result = await axios.post(`${baseUrl}/api/cases/${_case.Id}/items`, item, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success(`The new item was added to case #${_case.CaseNumber} successfully.`);
                dispatch.items.createItemSuccess({ caseId: _case.Id, item: result.data });
                return Promise.resolve(result.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.createItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async deleteItem({ _case, item }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.deleteItemRequest();

            try {
                await axios.delete(`${baseUrl}/api/cases/${item.Case_Id}/items/${item.Id}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success(`The item was removed from case #${item.CaseNumber} successfully.`);
                dispatch.items.deleteItemSuccess({ caseId: item.Case_Id, item });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.deleteItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async swapItems({ _case, swapObject }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.swapItemsRequest();

            try {
                const result = await axios.put(`${baseUrl}/api/cases/${_case.Id}/items/swap`, swapObject, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success(`The items were reordered successfully.`);
                dispatch.items.swapItemsSuccess({ caseId: _case.Id, swapResult: result.data });
                return Promise.resolve(result.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.swapItemsFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async assignItems({ caseId, caseNumber, itemIds, investigatorId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.assignItemsRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/assign`,
                    {
                        Investigator_Id: investigatorId,
                        ItemIds: itemIds
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                if (investigatorId) toast.success(`The items in case #${caseNumber} were assigned successfully.`);
                else toast.success(`The items were unassigned.`);
                dispatch.items.assignItemsSuccess({ caseId, itemIds, AssignedTo: result.data.AssignedTo, ItemStatus: result.data.ItemStatus });
                return Promise.resolve();
            } catch (error) {
                console.dir(error);
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.assignItemsFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async updateAcdsForItems({ caseId, caseNumber, itemIds, ACD }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.updateAcdsForItemsRequest();

            try {
                await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/set-acds`,
                    {
                        ACD: ACD,
                        ItemIds: itemIds
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success(`The ACDs were updated successfully for items in case #${caseNumber}.`);
                dispatch.items.updateAcdsForItemsSuccess({ caseId, itemIds, ACD });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.updateAcdsForItemsFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async acceptItems({ caseId, caseNumber, itemIds }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.acceptItemsRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/accept-items`,
                    {
                        ItemIds: itemIds
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success(`The items in case #${caseNumber} were accepted successfully.`);
                dispatch.items.acceptItemsSuccess({ caseId, itemIds, ItemStatus: result.data });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.acceptItemsFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async rejectItems({ caseId, caseNumber, itemIds, Reason }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.rejectItemsRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/reject-items`,
                    {
                        Reason,
                        ItemIds: itemIds
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success(`The items in case #${caseNumber} were rejected successfully.`);
                dispatch.items.rejectItemsSuccess({ caseId, itemIds, ItemStatus: result.data });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.items.rejectItemsFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async submitItems({ caseId, caseNumber, itemIds }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.items.submitItemsRequest();

            try {
                const response = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/submit-items`,
                    { itemIds },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );
                const updatedItem = { ...rootState.item.current, ItemStatus: response.data };
                toast.success(`Items in case #${caseNumber} were submitted successfully.`);
                dispatch.items.submitItemsSuccess(updatedItem);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                else toast.error('Unable to submit items.');

                dispatch.items.submitItemsFailure(error.response);

                return Promise.reject();
            }
        }
    })
};
