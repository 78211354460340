import React, { Component } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import shortid from 'shortid';

import Guard from '../../../../common/Guard';
import { roles } from '../../../../../constants';
import { accountSelectors } from '../../../../account/duck';

import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';
import { GhostForm } from '../../../../common/form/Form';
import { TableCheckbox, TableField, TableEnumTypeSelector, Input, DatePicker } from '../../../../common/form';
import Modal from '../../../../common/Modal';
import Button from '../../../../common/Button';

class OrgDeadlineList extends Component {
    state = {
        isUpdating: false,
        isDeleting: false,
        errors: {}
    };

    componentWillUnmount() {
        if (this.deadlineUpdatePromise) this.deadlineUpdatePromise.cancel();
        if (this.deadlineDeletePromise) this.deadlineDeletePromise.cancel();
    }

    updatedDeadline = values => {
        const { access_token, orgId, isACD } = this.props;
        if (this.state.isDeleting) return;

        const updateObject = {
            CaseType_Id: +values['CaseType_Id'],
            ItemType_Id: +values['ItemType_Id'],
            DaysFromPending: +values['DaysFromPending'],
            IsBusinessDays: !!values['IsBusinessDays'],
            StartOnFull: !!values['StartOnFull'],
            IsACD: !!isACD,
            ActiveFrom: values['ActiveFrom'],
            ActiveTo: values['ActiveTo']
        };

        this.deadlineUpdatePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: false,
                        isUpdating: true
                    },
                    async () => {
                        try {
                            updateObject.Org_Id = orgId;
                            const result = values['Inherited']
                                ? await this.props.createOrgDeadline(updateObject)
                                : await axios.put(`${baseUrl}/api/orgs/${orgId}/deadlines/${values['Id']}?caseTypeId=${this.props.caseTypeId}`, updateObject, {
                                    headers: { Authorization: `Bearer ${access_token}` }
                                });

                            toast.success(`The ${isACD ? 'ACD' : 'CD'} was updated successfully.`);

                            this.props.deadlineUpdated(result.data);

                            this.setState({
                                ...this.state,
                                errors: {
                                    ...this.state.errors,
                                    [values['Id']]: {}
                                },
                                isDeleting: false,
                                isUpdating: false
                            });
                        } catch (e) {
                            toast.error(`There was an error updating the ${isACD ? 'ACD' : 'CD'}.`);
                            this.setState({
                                ...this.state,
                                isDeleting: false,
                                isUpdating: false,
                                errors: {
                                    ...this.state.errors,
                                    [values['Id']]: e.response.data.ModelState || {}
                                }
                            });
                        }
                    }
                );
            })
        );
    };

    deleteDeadline = deadline => {
        this.setState({
            deadlineToDelete: deadline
        });
    };

    cancelDeleteDeadline = () => {
        this.setState({
            deadlineToDelete: undefined
        });
    };

    confirmDeleteDeadline = () => {
        const { access_token, orgId, isACD } = this.props;
        const deadline = this.state.deadlineToDelete;
        if (this.state.isUpdating) return;

        this.deadlineDeletePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: true,
                        isUpdating: false,
                        deadlineToDelete: undefined
                    },
                    async () => {
                        try {
                            const result = await axios.delete(`${baseUrl}/api/orgs/${orgId}/deadlines/${deadline.Id}?caseTypeId=${this.props.caseTypeId}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            toast.success(`The ${isACD ? 'ACD' : 'CD'} was deleted successfully.`);

                            this.props.deadlineDeleted(result.data);
                            this.setState({
                                ...this.state,
                                errors: {
                                    ...this.state.errors,
                                    [deadline.Id]: {}
                                },
                                isDeleting: false,
                                isUpdating: false
                            });
                        } catch (e) {
                            toast.error(`There was an error deleting the ${isACD ? 'ACD' : 'CD'}.`);
                            this.setState({
                                ...this.state,
                                isDeleting: false,
                                isUpdating: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { deadlines, isACD, hasPermission, caseTypeId } = this.props;
        const { errors, isDeleting, isUpdating, deadlineToDelete } = this.state;

        const cantUpdate = !hasPermission([roles.Orgs.UPDATE_ORG_DEADLINE]);

        const columns = [
            ...(isACD
                ? [
                    {
                        id: 'ItemType',
                        Header: 'Item Template',
                        accessor: d => (d.ItemType ? d.ItemType.Description : ''),
                        filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                        Cell: ({ row }) => (
                            <TableEnumTypeSelector
                                type="GoalType"
                                name="ItemType_Id"
                                label="Item Template"
                                readOnly={cantUpdate}
                                className="w-full"
                                submitOnBlur
                                showError
                                showHidden
                            />
                        )
                    }
                ]
                : []),
            {
                Header: (
                    <span>
                        #<br />
                        Days
                    </span>
                ),
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: () => (
                    <TableField type="number" name="DaysFromPending" readOnly={cantUpdate} className="text-center w-full" submitOnBlur showError />
                )
            },
            {
                Header: (
                    <span>
                        Business
                        <br />
                        Days
                    </span>
                ),
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: () => <TableCheckbox type="checkbox" name="IsBusinessDays" readOnly={cantUpdate} submitOnBlur />
            },
            {
                Header: (
                    <span>
                        Start on
                        <br />
                        Full
                    </span>
                ),
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: () => <TableCheckbox type="checkbox" name="StartOnFull" readOnly={cantUpdate} submitOnBlur />
            },
            {
                Header: 'Active From',
                sortable: false,
                filterable: false,
                minWidth: 75,
                Cell: () => <DatePicker name="ActiveFrom" label="Active From" readOnly={cantUpdate} submitOnBlur showError />
            },
            {
                Header: 'Active To',
                sortable: false,
                filterable: false,
                minWidth: 75,
                Cell: () => <DatePicker name="ActiveTo" label="Active To" readOnly={cantUpdate} submitOnBlur showError />
            },
            {
                sortable: false,
                filterable: false,
                minWidth: 25,
                Cell: ({ row }) =>
                    row._original.Inherited ? (
                        <>
                            <Input type="hidden" name="inherited" value={row._original.Inherited} />
                            {isUpdating && <i className="fas fa-spinner fa-pulse" />}
                        </>
                    ) : (
                            <div className="flex justify-center items-center h-full">
                                <Guard permissions={[roles.Orgs.DELETE_ORG_DEADLINE]}>
                                    <span
                                        className={`text-red-light ${isDeleting || isUpdating ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            if (isDeleting || isUpdating) return;
                                            this.deleteDeadline(row._original);
                                        }}
                                    >
                                        <i className={isDeleting ? 'fas fa-spinner fa-pulse' : 'fas fa-trash-alt'} />
                                    </span>
                                </Guard>
                            </div>
                        )
            }
        ];

        return (
            <>
                <div className="mt-4">
                    <ReactTable
                        filterable
                        data={deadlines}
                        columns={columns}
                        showPageSizeOptions={false}
                        showPagination={deadlines.length > 5}
                        pageSize={Math.min(deadlines.length, 5)}
                        defaultSorted={[{ id: 'CaseType', desc: false }, ...(isACD ? [{ id: 'ItemType', desc: false }] : [])]}
                        defaultFiltered={[{ id: 'Inherited', value: 'NON-INHERITED' }]}
                        getTrGroupProps={(state, rowInfo, column, instance) => ({
                            formDefaults: rowInfo
                                ? {
                                    ...rowInfo.original,
                                    CaseType_Id: caseTypeId || 0,
                                    ItemType_Id: rowInfo.original.ItemType ? rowInfo.original.ItemType.Id : 0
                                }
                                : {},
                            errors: rowInfo ? errors[rowInfo.original.Id] : {}
                        })}
                        TrGroupComponent={({ formDefaults, errors, ...rest }) => (
                            <GhostForm
                                key={`form-${formDefaults ? formDefaults['Id'] : shortid.generate()}`}
                                defaultValues={formDefaults}
                                errors={errors}
                                onSubmit={this.updatedDeadline}
                            >
                                <ReactTableDefaults.TrGroupComponent {...rest} />
                            </GhostForm>
                        )}
                    />
                </div>
                <Modal show={deadlineToDelete} onClose={this.cancelDeleteDeadline} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>Remove this deadline? This action cannot be undone.</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={this.cancelDeleteDeadline}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.confirmDeleteDeadline}>
                            Remove
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});
const mapDispatchToProps = dispatch => ({
    createOrgDeadline: dispatch.orgDeadline.createOrgDeadline
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgDeadlineList);
