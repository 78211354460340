import React from 'react';

export default (props) => {
    const result = props.location.search.split('?')[1];
    window.opener.postMessage({
        QboResult: result
    }, window.opener.location.origin);

    return (
        <div className="flex justify-center items-center h-full w-ful">
            Authentication received, this window will close shortly...
        </div>
    );
};