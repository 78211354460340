import React, { Component } from 'react';
import 'react-day-picker/lib/style.css';

const currentYear = new Date().getFullYear();
const isChrome = !!window.chrome;
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear + 10, 11);
const years = [];
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
}

class YearMonthForm extends Component {
    state = {
        displayMonths: 'none',
        displayYears: 'none'
    };
    constructor(props) {
        super(props);
        this.yearInput = React.createRef();
        this.monthInput = React.createRef();
    }
    handleChange = e => {
        this.props.onChange(new Date(this.yearInput.current.value, this.monthInput.current.value));
    };
    render() {
        const { localeUtils, onChange, date } = this.props;
        const months = localeUtils.getMonths();

        return (
            <div className="DayPicker-Caption relative " style={{ width: '80%' }}>
                <select
                    name="month"
                    ref={this.monthInput}
                    value={date.getMonth()}
                    onChange={this.handleChange}
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ displayMonths: this.state.displayMonths === 'block' ? 'none' : 'block', displayYears: 'none' });
                    }}
                >
                    {months.map((month, i) => (
                        <option key={i} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    name="year"
                    ref={this.yearInput}
                    value={date.getFullYear()}
                    onChange={this.handleChange}
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ displayYears: this.state.displayYears === 'block' ? 'none' : 'block', displayMonths: 'none' });
                    }}
                >
                    {years.map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                {!isChrome && (
                    <div className="absolute p-2 h-64 w-100 bg-white z-50 overflow-scroll pin-l" style={{ display: this.state.displayMonths }}>
                        {months.map((month, i) => (
                            <div
                                className="hover:bg-grey"
                                key={month}
                                onClick={() => this.setState({ displayMonths: 'none' }, () => onChange(new Date(date.getFullYear(), i)))}
                            >
                                {month}
                            </div>
                        ))}
                    </div>
                )}
                {!isChrome && (
                    <div className="absolute p-2 h-64 w-32 bg-white z-50 overflow-scroll pin-r" style={{ display: this.state.displayYears }}>
                        {years.map(year => (
                            <div
                                className="hover:bg-grey"
                                key={year}
                                onClick={() => {
                                    this.setState({ displayYears: 'none' }, () => onChange(new Date(year, date.getMonth())));
                                }}
                            >
                                {year}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
export { YearMonthForm, currentYear, fromMonth, toMonth };
