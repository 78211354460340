import axios from 'axios';
import 'whatwg-fetch';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';
import { optional } from '../../../utils';

const CancelToken = axios.CancelToken;
const requestCalls = [];

export const cases = {
    state: {
        cases: [],
        attachments: [],
        showNewCaseModal: false,
        _updating: {
            _gettingCases: false,
            _savingCase: false,
            _uploadingFiles: false,
            _addingTackingInfo: false,
            _startingDataEntry: false,
            _discontinuing: false,
            _puttingOnHold: false,
            _resuming: false,
            _deletingFiles: false
        },
        _errors: {},
        _fileQueue: []
    },
    reducers: {
        setCases: (state, payload) => ({
            ...state,
            cases: payload,
            _updating: {
                ...state._updating,
                _gettingCases: false
            }
        }),
        setAttachments: (state, payload) => ({ ...state, ...{ attachments: payload } }),
        setDeletingFiles: (state, payload) => ({ ...state, ...{ _updating: { _deletingFiles: payload } } }),
        setIsLoading: (state, payload) => ({ ...state, ...{ _updating: { _gettingCases: payload } } }),
        setIsSaving: (state, payload) => ({ ...state, ...{ _updating: { _savingCase: payload } } }),
        setIsUploading: (state, payload) => ({ ...state, ...{ _updating: { _uploadingFiles: payload } } }),
        setIsAddingTrackingInfo: (state, payload) => ({ ...state, ...{ _updating: { _addingTackingInfo: payload } } }),
        setIsStartingDataEntry: (state, payload) => ({ ...state, ...{ _updating: { _startingDataEntry: payload } } }),
        setIsDiscontinuing: (state, payload) => ({ ...state, ...{ _updating: { _discontinuing: payload } } }),
        setIsResuming: (state, payload) => ({ ...state, ...{ _updating: { _resuming: payload } } }),
        setPuttingOnHold: (state, payload) => ({ ...state, ...{ _updating: { _puttingOnHold: payload } } }),
        setErrors: (state, payload) => ({
            ...state,
            _errors: payload,
            _updating: {
                ...state._updating,
                _gettingCases: false
            }
        }),
        showModal: state => ({ ...state, ...{ showNewCaseModal: true } }),
        hideModal: state => ({ ...state, ...{ showNewCaseModal: false } }),
        setFileQueue: (state, payload) => ({ ...state, ...{ _fileQueue: payload } })
    },
    effects: dispatch => ({
        async getCases(payload, rootState) {
			  console.log("asd");
              
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            dispatch.cases.setIsLoading(true);

            // If we have requests out, then cancel them.
            if (requestCalls.length) {
                requestCalls.shift().cancel();
            }
            requestCalls.push(CancelToken.source());

            try {
                const response = await axios.post(`${baseUrl}/api/cases/search`, payload, {
                    cancelToken: requestCalls[0].token,
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                //console.log(payload);
                dispatch.cases.setCases(response.data.cases);
                return response;
            } catch (error) {
                if (axios.isCancel(error)) {
                    throw 'Canceling existing getCases network request.';
                } else {
                    if (error && error.response) dispatch.cases.setErrors(error.response);

                    throw error;
                }
            }
        },
        async createCase(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsSaving(true);
                const response = await axios.post(`${baseUrl}/api/cases/`, payload, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                // let cases = rootState.cases.cases;
                // cases.unshift(response.data);
                // dispatch.cases.setCases(cases);
                dispatch.cases.hideModal();
                dispatch.cases.setIsSaving(false);
                toast.success(`Case ${response.data.CaseNumber} created successfully.`);

                return response;
            } catch (error) {
                if (!optional(error, t => t.response.data.ModelState.Duplicate, false)) toast.error('Unable to create case.');

                dispatch.cases.setIsSaving(false);

                if (error && error.response) dispatch.cases.setErrors(error.response);

                throw error;
            }
        },
        async editCase({ caseModel, subject }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsSaving(true);
                await axios.all([
                    axios.put(`${baseUrl}/api/cases/${caseModel.Id}`, caseModel, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }),

                    axios.put(`${baseUrl}/api/cases/${caseModel.Id}/subject/`, subject, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    })
                ]);
                dispatch.cases.setErrors({});
                dispatch.messages.getRecipients(caseModel.Id);
                toast.success(`Case was saved successfully.`);
            } catch (error) {
                if (error && error.response) {
                    toast.error(error.response.data.Message);
                    dispatch.cases.setErrors(error.response);
                } else {
                    toast.error('Unable to update case.');
                }
            }

            dispatch.cases.setIsSaving(false);
        },
        async deleteCase($case, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.delete(`${baseUrl}/api/cases/${$case.Id}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                const cases = rootState.cases.cases.filter(c => c.Id != $case.Id);
                dispatch.cases.setCases(cases);
                toast.success(`${$case.CaseNumber} was deleted.`);
                return response;
            } catch (error) {
                toast.error('Unable to delete case.');
                if (error && error.response) dispatch.cases.setErrors(error.response);
                throw error;
            }
        },
        async assignReviewer({ Id, CaseNumber, reviewerId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.put(`${baseUrl}/api/cases/${Id}/reviewer/${reviewerId}`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                return response;
            } catch (error) {
                toast.error(`Unable to update case ${CaseNumber}.`);
                if (error && error.response) {
                    dispatch.cases.setErrors(error.response);
                }

                throw error;
            }
        },
        async startDataEntry(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsStartingDataEntry(true);
                const response = await axios.put(`${baseUrl}/api/cases/${caseId}/start-data-entry`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                toast.success('Data entry started.');
                dispatch.cases.setIsStartingDataEntry(false);
                return response;
            } catch (error) {
                dispatch.cases.setIsStartingDataEntry(false);
                if (error && error.response) {
                    toast.error(error.response.data.Message);
                    dispatch.cases.setErrors(error.response);
                } else {
                    toast.error('Unable to start data entry.');
                }

                throw error;
            }
        },
        async dataEntryComplete(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsStartingDataEntry(true);
                await axios.put(`${baseUrl}/api/cases/${caseId}/data-entry-complete`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                toast.success('Data entry complete.');
                dispatch.cases.setIsStartingDataEntry(false);
            } catch (error) {
                dispatch.cases.setIsStartingDataEntry(false);
                if (error && error.response) {
                    toast.error(error.response.data.Message);
                    dispatch.cases.setErrors(error.response);
                } else {
                    toast.error('Unable to mark data entry complete.');
                }
            }
        },
        async OnHold(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setPuttingOnHold(true);
                const response = await axios.put(`${baseUrl}/api/cases/${caseId}/toggle-on-hold`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.cases.setPuttingOnHold(false);
                return response;
            } catch (error) {
                toast.error('Unable to put case on hold.');
                dispatch.cases.setPuttingOnHold(false);
                if (error && error.response) dispatch.cases.setErrors(error.response);

                throw error;
            }
        },
        async Resume(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsResuming(true);
                const result = await axios.put(`${baseUrl}/api/cases/${caseId}/resume`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                toast.success('The case has been resumed.');
                dispatch.cases.setIsResuming(false);
                return result.data.WorkflowState;
            } catch (error) {
                toast.error('Unable to resume case.');
                dispatch.cases.setIsResuming(false);
                if (error && error.response) dispatch.cases.setErrors(error.response);
            }
        },
        async Discontinue(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsDiscontinuing(true);
                await axios.put(`${baseUrl}/api/cases/${caseId}/discontinue`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                toast.success('The case has been discontinued.');
                dispatch.cases.setIsDiscontinuing(false);
            } catch (error) {
                toast.error('Unable to discontinue case.');
                dispatch.cases.setIsDiscontinuing(false);
                if (error && error.response) dispatch.cases.setErrors(error.response);
            }
        },
        async getWorkflowState(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/workflows/cases/${caseId}/`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                return response;
            } catch (error) {
                if (error && error.response) dispatch.cases.setErrors(error.response);

                throw error;
            }
        },
        async getAttachments(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/attachments/`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.cases.setAttachments(response.data);
            } catch (error) {
                toast.error('Unable to get papers.');
                if (error && error.response) dispatch.cases.setErrors(error.response);
            }
        },
        async addAttachments({ data, caseId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsUploading(true);

                if (caseId) {
                    const response = await axios.post(`${baseUrl}/api/cases/${caseId}/attachments/`, data, {
                        headers: { Authorization: `Bearer ${access_token}`, 'content-type': 'multipart/form-data' }
                    });
                    toast.success(`Files were uploaded.`);
                    dispatch.cases.setAttachments([...response.data]);
                    dispatch.cases.setIsUploading(false);
                    return response;
                } else {
                    dispatch.cases.setAttachments([...rootState.cases.attachments, data]);
                    dispatch.cases.setIsUploading(false);
                }
            } catch (error) {
                toast.error('Unable to get papers.');
                dispatch.cases.setIsUploading(false);
                if (error && error.response) dispatch.cases.setErrors(error.response);

                throw error;
            }
        },      
        async deleteAttachment({ Case_Id, Id }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                if (Case_Id) {
                    dispatch.cases.setDeletingFiles(true);

                    await axios.delete(`${baseUrl}/api/cases/${Case_Id}/attachments/${Id}`, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    });

                    dispatch.cases.setDeletingFiles(false);
                }

                const attachments = rootState.cases.attachments.filter(attachment => attachment.Id !== Id);

                dispatch.cases.setAttachments([...attachments]);

                toast.success('File was removed.');
            } catch (error) {
                dispatch.cases.setDeletingFiles(false);
                toast.error('Unable to remove file.');
            }
        },
        async addTrackingInfo(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.cases.setIsAddingTrackingInfo(true);
                if (payload.Case_Id) {
                    const response = await axios.post(`${baseUrl}/api/cases/${payload.Case_Id}/tracking`, payload, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    });
                    rootState.cases.attachments.push(response.data);
                    const attachments = rootState.cases.attachments;
                    dispatch.cases.setAttachments([...attachments]);
                    dispatch.cases.setIsAddingTrackingInfo(false);
                    toast.success(`${payload.Name} tracking info was added.`);
                    return response;
                } else {
                    const attachments = rootState.cases.attachments;
                    dispatch.cases.setAttachments([...attachments, payload]);
                    dispatch.cases.setIsAddingTrackingInfo(false);
                }
            } catch (error) {
                dispatch.cases.setIsAddingTrackingInfo(false);
                toast.error('Unable to add tracking info.');
                throw error;
            }
        },
        async downloadReport(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await fetch(`${baseUrl}/api/cases/${caseId}/report`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                return response;
            } catch (error) {
                throw error;
            }
        }
    })
};
