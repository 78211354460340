import React, { Component } from 'react';
import { connect } from 'react-redux';

import { typesOperations } from './duck';

import Guard from '../common/Guard';
import { roles } from '../../constants';

import Button from '../common/Button';
import Modal from '../common/Modal';
import TypeList from './components/TypeList';
import { Input } from '../common/form';
import { Form } from '../common/form/Form';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class Types extends Component {
    state = {
        showNewListModal: false
    };

    componentDidMount() {
        this.props.getTypes();
    }

    openModal = () => {
        this.setState({
            ...this.state,
            showNewListModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showNewListModal: false
        });
    };

    onSubmit = values => {
        this.props
            .addType(values.TypeName)
            .then(() => this.closeModal())
            .catch(() => {});
    };

    render() {
        const { showNewListModal } = this.state;
        const { addingType, gettingTypes, addErrors } = this.props;

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Lists</PageHeader>
                        <Guard permissions={[roles.Types.CREATE_TYPE]}>
                            <Button inverse icon="far fa-folder-open" onClick={this.openModal}>
                                Add List
                            </Button>
                        </Guard>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingTypes ? (
                            <TypeList />
                        ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                    </div>
                </div>
                <Modal show={showNewListModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showNewListModal ? (
                            <Form defaultValues={{ TypeName: '' }} errors={addErrors} onSubmit={this.onSubmit}>
                                <Input type="text" name="TypeName" label="List Name" required />
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!addingType ? (
                                        <Button type="submit">Add List</Button>
                                    ) : (
                                        <Button disabled>
                                            Adding List... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    addingType: state.types._updating._addingType,
    gettingTypes: state.types._updating._gettingTypes,
    addErrors: (state.types._errors && state.types._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    addType: type => dispatch(typesOperations.addType(type)),
    getTypes: () => dispatch(typesOperations.getTypes())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Types);
