import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditItemForm from './components/EditItemForm';

class ItemPage extends Component {
    componentDidMount() {
        const { caseId, itemId } = this.props;
        this.props.getItem(+caseId, +itemId);
    }

    render() {
        const { gettingItem, item, getItems, caseId, onSubmit } = this.props;

        return (
            <>
                <div className="container">
                    <div className="bg-white rounded shadow my-4 p-4 px-8 mb-8">
                        {!gettingItem && item ? (
                            <>
                                <EditItemForm
                                    item={item}
                                    onSubmit={() => {
                                        if (onSubmit) onSubmit();
                                    }}
                                />
                            </>
                        ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    item: state.item.current,
    gettingItem: state.item._updating._gettingItem,
    updatingItem: state.item._updating._updatingItem,
    errors: (state.item._errors && state.item._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    getItem: (caseId, itemId) => dispatch.item.getItem({ caseId, itemId }),
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS })
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ItemPage)
);
