import React, { Component } from 'react';
import SectionBuilder from '../../SectionBuilder';

class TemplateConfigurer extends Component {
    render() {
        const { field, errors, editing, existingFieldIds } = this.props;

        return (
            <div className="flex justify-between items-center flex-wrap -mx-4">
                <div className="w-full px-4">
                    <SectionBuilder editing={editing} existingFieldIds={existingFieldIds} parentField={field} name="Template" label="Template Fields" required errors={errors} disabledTypes={['template']} />
                </div>
            </div>);
    }
};

export default TemplateConfigurer;