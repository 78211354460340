import React, { Component } from 'react';

import Button from '../../common/Button';

class SectionEditModalClose extends Component {
    componentDidMount() {
        document.addEventListener('keyup', this.handleEscapeKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEscapeKeyPress);
    }

    handleEscapeKeyPress = e => {
        this.props.handleEscapeKeyPress(e, this.props.values);
    };

    render() {
        const { unsavedChanges, values } = this.props;
        return !unsavedChanges ?
            <Button onClick={() => this.props.attemptClose(values)} className="mr-8 bg-grey hover:bg-grey-dark">
                Close
            </Button> :
            <Button onClick={() => this.props.attemptClose(values)} className="mr-8 text-red bg-grey hover:bg-grey-dark">
                You have unsaved changes, click again to lose them.
            </Button>
    }
};

export default SectionEditModalClose;