import React from 'react';
import { Form } from '../../common/form/Form';
import { Input, EnumTypeSelector } from '../../common/form';

const AddressForm = ({ onSubmit, children, values }) => {
    return (
        <Form onSubmit={onSubmit} defaultValues={values}>
            <div className="flex flex-col sm:flex-row mt-2">
                <div className="mb-4 sm:mr-4 flex-grow">
                    <EnumTypeSelector name="AddressType_Id" label="Type" type="AddressType" />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-2">
                <div className="mb-4 sm:mr-4 flex-grow">
                    <Input name="Line1" label="Street" type="text" />
                </div>
                <div className="mb-4 sm:mr-4 flex-grow">
                    <Input name="Line2" label="Street 2" type="text" />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-2">
                <div className="mb-4 sm:mr-4 flex-grow">
                    <Input name="City" label="City" type="text" required={true} />
                </div>
                <div className="mb-4 sm:mr-4 flex-grow">
                    <EnumTypeSelector name="RegionType_Id" label="State" type="RegionType" />
                </div>
                <div className="mb-4 sm:mr-4 flex-grow">
                    <Input name="PostalCode" label="Postal Code" type="text" required={true} />
                </div>
            </div>
            {children}
        </Form>
    );
};

export default AddressForm;
