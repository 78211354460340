const GET_EXPENSE_TYPES_REQUEST = 'app/expense-types/GET_EXPENSE_TYPES_REQUEST';
const GET_EXPENSE_TYPES_SUCCESS = 'app/expense-types/GET_EXPENSE_TYPES_SUCCESS';
const GET_EXPENSE_TYPES_FAILURE = 'app/expense-types/GET_EXPENSE_TYPES_FAILURE';

const CREATE_EXPENSE_TYPE_REQUEST = 'app/expense-types/CREATE_EXPENSE_TYPE_REQUEST';
const CREATE_EXPENSE_TYPE_SUCCESS = 'app/expense-types/CREATE_EXPENSE_TYPE_SUCCESS';
const CREATE_EXPENSE_TYPE_FAILURE = 'app/expense-types/CREATE_EXPENSE_TYPE_FAILURE';

const UPDATE_EXPENSE_TYPE_REQUEST = 'app/expense-types/UPDATE_EXPENSE_TYPE_REQUEST';
const UPDATE_EXPENSE_TYPE_SUCCESS = 'app/expense-types/UPDATE_EXPENSE_TYPE_SUCCESS';
const UPDATE_EXPENSE_TYPE_FAILURE = 'app/expense-types/UPDATE_EXPENSE_TYPE_FAILURE';

const DELETE_EXPENSE_TYPE_REQUEST = 'app/expense-types/DELETE_EXPENSE_TYPE_REQUEST';
const DELETE_EXPENSE_TYPE_SUCCESS = 'app/expense-types/DELETE_EXPENSE_TYPE_SUCCESS';
const DELETE_EXPENSE_TYPE_FAILURE = 'app/expense-types/DELETE_EXPENSE_TYPE_FAILURE';

export default {
    GET_EXPENSE_TYPES_REQUEST,
    GET_EXPENSE_TYPES_SUCCESS,
    GET_EXPENSE_TYPES_FAILURE,
    CREATE_EXPENSE_TYPE_REQUEST,
    CREATE_EXPENSE_TYPE_SUCCESS,
    CREATE_EXPENSE_TYPE_FAILURE,
    UPDATE_EXPENSE_TYPE_REQUEST,
    UPDATE_EXPENSE_TYPE_SUCCESS,
    UPDATE_EXPENSE_TYPE_FAILURE,
    DELETE_EXPENSE_TYPE_REQUEST,
    DELETE_EXPENSE_TYPE_SUCCESS,
    DELETE_EXPENSE_TYPE_FAILURE
};