import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const caseTemplatesPersistConfig = {
    key: 'caseTemplates',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    caseTemplates: [],
    _errors: null,
    _updating: {
        _gettingCaseTemplates: false,
        _creatingCaseTemplate: false,
        _swappingItems: false,
        _caseTemplates: {}
    }
};

const caseTemplates = createReducer(initialState, {
    [types.GET_CASE_TEMPLATES_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _gettingCaseTemplates: true }
    }),
    [types.GET_CASE_TEMPLATES_SUCCESS]: (state, { payload: caseTemplates }) => ({
        ...state,
        caseTemplates,
        _errors: null,
        _updating: { ...state._updating, _gettingCaseTemplates: false }
    }),
    [types.GET_CASE_TEMPLATES_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _gettingCaseTemplates: false }
    }),

    [types.CREATE_CASE_TEMPLATE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _creatingCaseTemplate: true }
    }),
    [types.CREATE_CASE_TEMPLATE_SUCCESS]: (state, { payload: caseTemplate }) => ({
        ...state,
        caseTemplates: [...state.caseTemplates, caseTemplate],
        _errors: null,
        _updating: { ...state._updating, _creatingCaseTemplate: false }
    }),
    [types.CREATE_CASE_TEMPLATE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingCaseTemplate: false }
    }),

    [types.EDIT_CASE_TEMPLATE_REQUEST]: (state, { payload: caseTemplateId }) => ({
        ...state,
        _errors: null,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplateId]: true
            }
        }
    }),
    [types.EDIT_CASE_TEMPLATE_SUCCESS]: (state, { payload: caseTemplate }) => ({
        ...state,
        caseTemplates: state.caseTemplates.map(ct => (ct.Id !== caseTemplate.Id ? ct : caseTemplate)),
        _errors: null,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplate.Id]: false
            }
        }
    }),
    [types.EDIT_CASE_TEMPLATE_FAILURE]: (state, { payload: { caseTemplateId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplateId]: false
            }
        }
    }),

    [types.DELETE_CASE_TEMPLATE_REQUEST]: (state, { payload: caseTemplateId }) => ({
        ...state,
        _errors: null,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplateId]: true
            }
        }
    }),
    [types.DELETE_CASE_TEMPLATE_SUCCESS]: (state, { payload: caseTemplateId }) => ({
        ...state,
        caseTemplates: state.caseTemplates.filter(ct => ct.Id !== caseTemplateId),
        _errors: null,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplateId]: false
            }
        }
    }),
    [types.DELETE_CASE_TEMPLATE_FAILURE]: (state, { payload: { caseTemplateId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating,
            _caseTemplates: {
                ...state._updating._caseTemplates,
                [caseTemplateId]: false
            }
        }
    }),

    [types.SWAP_CASE_TEMPLATE_ITEM_REQUEST]: state => ({
        ...state,
        _errors: {},
        _updating: { ...state._updating, _swappingItems: true }
    }),
    [types.SWAP_CASE_TEMPLATE_ITEM_SUCCESS]: (state, { payload: { caseTemplateId, swapResult } }) => ({
        ...state,
        caseTemplates: state.caseTemplates.map(caseTemplate =>
            caseTemplate.Id !== caseTemplateId
                ? caseTemplate
                : {
                      ...caseTemplate,
                      Items: (caseTemplate.Items || []).map(item =>
                          swapResult[item.Id] !== undefined
                              ? {
                                    ...item,
                                    DisplayOrder: +swapResult[item.Id]
                                }
                              : item
                      )
                  }
        ),
        _errors: {},
        _updating: { ...state._updating, _swappingItems: false }
    }),
    [types.SWAP_CASE_TEMPLATE_ITEM_FAILURE]: (state, errors) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _swappingItems: false }
    })
});

export default caseTemplates;
