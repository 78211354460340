import React, { Component } from 'react';
import ReactTable from 'react-table';
import shortid from 'shortid';

import { TableCheckbox, EnumTypeSelector, TableField, Header, Divider, Section, PhoneNumber } from '../../../common/form';
import { optional } from '../../../../utils';
import Button from '../../../common/Button';
import { USER_PROFILE_PAGE_SIZE } from '../../../../constants';

class PhoneNumbersEditor extends Component {
    state = {
        columns: []
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            columns: [
                {
                    Header: 'Phone',
                    accessor: 'Number',
                    Cell: row => {
                        return <PhoneNumber name={`PhoneNumbers.${row.original.Id}.Number`} placeholder="Phone number" type="text" required={true} />;
                    }
                },
                {
                    Header: 'Type',
                    accessor: 'PhoneNumberType_Id',
                    Cell: row => <EnumTypeSelector name={`PhoneNumbers.${row.original.Id}.PhoneNumberType_Id`} label="" type="PhoneNumberType" />
                },
                {
                    Header: 'Primary',
                    accessor: 'IsPrimary',
                    Cell: row => <TableCheckbox name={`PhoneNumbers.${row.original.Id}.IsPrimary`} label="" type="checkbox" />
                },
                {
                    Header: '',
                    accessor: 'Actions',
                    filterable: false,
                    sortable: false,
                    Cell: row => (
                        <div className="flex justify-center items-center h-full">
                            <span
                                className="text-red-light cursor-pointer"
                                onClick={this.props.onDelete.bind(null, this.props.form, 'PhoneNumbers', row.original.Id)}
                            >
                                <i className="fas fa-trash-alt" />
                            </span>
                        </div>
                    )
                }
            ]
        });
    };

    render() {
        const { user, onAdd } = this.props;
        const { columns } = this.state;
        const data = optional(user, target => target.PhoneNumbers, []);

        return (
            <Section>
                <div className="w-full">
                    <div className="flex align-center justify-between">
                        <Header>Phone Numbers</Header>
                        <Button
                            icon="fas fa-phone"
                            onClick={onAdd.bind(null, 'PhoneNumbers', {
                                Id: shortid.generate(),
                                Number: '',
                                LocationType_Id: 1,
                                IsPrimary: false
                            })}
                        >
                            Add
                        </Button>
                    </div>
                    <Divider />
                    <div className="p-4">
                        {data.length > 0 ? (
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPagination={false}
                                pageSize={data.length}
                                defaultPageSize={USER_PROFILE_PAGE_SIZE}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        ) : (
                                <div className="mb-8 text-primary">No phone numbers.</div>
                            )}
                    </div>
                </div>
            </Section>
        );
    }
}

export default PhoneNumbersEditor;
