import React from 'react';
import { connect } from 'react-redux';
import Button from '../../common/Button';
import { TextArea, MultiSelect } from '../../common/form';
import { Form } from '../../common/form/Form';

const NewMessageForm = ({ onSubmit, defaultValues, onClose, isSendingMessage, recipients, errors }) => (
    <Form onSubmit={onSubmit} defaultValues={defaultValues} errors={errors}>
        <div className="flex flex-col sm:flex-row">
            <div className="mb-4 w-full">
                <MultiSelect
                    name="Recipients"
                    label="Recipients"
                    isMulti
                    getOptionLabel={option => `${option.FirstName} ${option.LastName}`}
                    getOptionValue={option => option.Id}
                    options={recipients}
                    required={true}
                />
            </div>
        </div>
        <div className="w-full flex mt-4">
            <div className="mb-4 flex-grow w-full">
                <TextArea name="Message" label="Message" required={true} />
            </div>
        </div>
        <div className="w-full flex mt-4">
            <Button className="bg-grey ml-auto mr-4" onClick={onClose} disabled={isSendingMessage}>
                Cancel
            </Button>
            {isSendingMessage ? (
                <Button disabled={true}>
                    Sending... <i className="fas fa-spinner fa-pulse" />
                </Button>
            ) : (
                <Button type="submit">Send Message</Button>
            )}
        </div>
    </Form>
);
const mapStateToProps = state => ({
    recipients: state.messages.recipients,
    errors: state.messages.errors.ModelState || {},
    isSendingMessage: state.messages._updating._sendingMessage
});
export default connect(mapStateToProps)(NewMessageForm);
