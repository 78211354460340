import React from 'react';
import { connect } from 'react-redux';
import { Label } from '../../../common/form';
import TypeSelector from '../../../common/TypeSelector';
import { optional } from '../../../../utils';
import shortid from 'shortid';

const AddressSectionField = ({ field, onChange, RegionTypes, disabled }) => {
    const Region = RegionTypes.find(rt => rt.Description === field['@region']);

    return (
        <div className="mb-4">
            <div className={`block text-sm font-bold text-${!!field['@showInReport'] ? 'primary' : 'grey-dark'} mb-2`}>
                {optional(field, '@label', '')}
                {optional(field, '@instructions', '') ? (
                    <>
                        <br />
                        <span className="text-sm font-normal text-grey-dark italic block">{optional(field, '@instructions', '')}</span>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
                <div className="w-1/2 px-4">
                    <Label>
                        Street
                        {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <input
                        className="input-field"
                        type="text"
                        name="@line1"
                        value={optional(field, '@line1', '')}
                        onChange={event => onChange(field, event.target.name, event.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div className="w-1/2 px-4">
                    <Label>&nbsp;</Label>
                    <input
                        className="input-field"
                        type="text"
                        name="@line2"
                        value={optional(field, '@line2', '')}
                        onChange={event => onChange(field, event.target.name, event.target.value)}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
                <div className="w-1/3 px-4">
                    <Label>
                        City
                        {field['@required'] ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <input
                        className="input-field"
                        type="text"
                        name="@city"
                        value={optional(field, '@city', '')}
                        onChange={event => onChange(field, event.target.name, event.target.value)}
                        disabled={disabled}
                    />
                </div>
                <div className="w-1/3 px-4">
                    <TypeSelector
                        customKey={`${field['@id']}-@region`}
                        label="State"
                        type="RegionType"
                        name="@region"
                        value={Region ? Region.Id : 0}
                        required={optional(field, '@required', '')}
                        onChange={event => {
                            onChange(field, event.target.name, RegionTypes.find(rt => rt.Id === +event.target.value).Description);
                        }}
                        disabled={disabled}
                    />
                </div>
                <div className="w-1/3 px-4">
                    <Label>
                        Postal Code
                        {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <input
                        className="input-field"
                        type="text"
                        name="@postalCode"
                        value={optional(field, '@postalCode', '')}
                        onChange={event => onChange(field, event.target.name, event.target.value)}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    RegionTypes: optional(state, s => s.types['RegionType'].Types, [])
});

export default connect(mapStateToProps)(AddressSectionField);
