import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { typesOperations } from '../duck';
import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Button from '../../common/Button';
import Modal from '../../common/Modal';
import { Input } from '../../common/form';
import { Form } from '../../common/form/Form';

class TypeItemsList extends Component {
    state = {
        editing: null,
        deleting: null,
        showCreateModal: false,
        showEditModal: false,
        showDeleteModal: false
    };

    openCreateModal = () => {
        this.setState({
            ...this.state,
            editing: null,
            deleting: null,
            showCreateModal: true,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    openEditModal = item => {
        this.setState({
            ...this.state,
            editing: item,
            deleting: null,
            showCreateModal: false,
            showEditModal: true,
            showDeleteModal: false
        });
    };

    openDeleteModal = item => {
        this.setState({
            ...this.state,
            editing: null,
            deleting: item,
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            editing: null,
            deleting: null,
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    onCreate = values => {
        this.props
            .addItemType(this.props.parent, values)
            .then(() => this.closeModal())
            .catch(() => {});
    };

    onEdit = values => {
        this.props
            .editItemType(this.props.parent, {
                ...this.state.editing,
                ...values
            })
            .then(() => this.closeModal())
            .catch(() => {});
    };

    onDelete = () => {
        this.props
            .deleteItemType(this.props.parent, this.state.deleting)
            .then(() => this.closeModal())
            .catch(() => {});
    };

    render() {
        const { creatingItemType, editingItemType, deletingItemType, items, errors, parent } = this.props;
        const { deleting, editing, showCreateModal, showEditModal, showDeleteModal } = this.state;

        const columns = [
            {
                Header: 'Short',
                accessor: 'Short',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Description',
                accessor: 'Description',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Status',
                accessor: 'Hidden',
                sortable: false,
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'NOTHIDDEN') return row[filter.id] === false;
                    if (filter.value === 'HIDDEN') return row[filter.id] === true;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">
                        <select
                            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 rounded"
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'ALL'}
                        >
                            <option value="ALL">Show All</option>
                            <option value="NOTHIDDEN">Enabled</option>
                            <option value="HIDDEN">Disabled</option>
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {!value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <Guard permissions={[roles.Types.EDIT_TYPE]}>
                            <span
                                className="cursor-pointer mr-4 cursor-pointer text-grey"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.openEditModal(row._original);
                                }}
                            >
                                <i className="fas fa-pencil-alt" />
                            </span>
                        </Guard>
                        <Guard permissions={[roles.Types.DELETE_TYPE]}>
                            <span
                                className={`text-${row._original.Hidden ? 'green' : 'red'}-light cursor-pointer`}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.openDeleteModal(row._original);
                                }}
                            >
                                <i className={`fas ${row._original.Hidden ? 'fa-undo' : 'fa-trash-alt'}`} />
                            </span>
                        </Guard>
                    </div>
                )
            }
        ];

        return (
            <div className="px-8 py-4 my-4 bg-table-search rounded shadow-inner">
                <div className="flex justify-between items-center mb-4">
                    <span className="font-bold text-primary text-sm">{parent.FormattedTableName}s</span>
                    <Guard permissions={[roles.Types.CREATE_TYPE]}>
                        <Button inverse icon="fas fa-plus" onClick={this.openCreateModal}>
                            Add {parent.FormattedTableName}
                        </Button>
                    </Guard>
                </div>
                <div className="bg-white shadow rounded p-4">
                    {items.length > 0 ? (
                        <ReactTable
                            filterable
                            pageSize={Math.min(items.length, 10)}
                            data={items.filter(i => i)}
                            defaultSorted={[
                                {
                                    id: 'Short',
                                    desc: false
                                }
                            ]}
                            defaultFiltered={[{ id: 'Hidden', value: 'NOTHIDDEN' }]}
                            columns={columns}
                            showPagination={items.length > 10}
                            showPageSizeOptions={false}
                            getTheadProps={() => ({
                                style: { background: 'white' }
                            })}
                            getTheadFilterProps={() => ({
                                style: { background: 'white' }
                            })}
                        />
                    ) : (
                        <div className="text-primary">There are no items in this list.</div>
                    )}
                    <Modal show={showCreateModal} onClose={this.closeModal}>
                        <div className="p-4">
                            {showCreateModal ? (
                                <Form errors={errors} onSubmit={this.onCreate}>
                                    <div className="flex flex-wrap md:-mx-4">
                                        <div className="w-full md:w-1/2  md:px-4">
                                            <Input type="text" name="Short" label="Short" required />
                                        </div>
                                        <div className="w-full md:w-1/2  md:px-4">
                                            <Input type="text" name="Description" label="Description" />
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-end mt-4">
                                        <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                            Cancel
                                        </Button>
                                        {!creatingItemType ? (
                                            <Button type="submit">Add {parent.FormattedTableName}</Button>
                                        ) : (
                                            <Button disabled>
                                                Adding {parent.FormattedTableName}... <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal>
                    <Modal show={showEditModal} onClose={this.closeModal}>
                        <div className="p-4">
                            {showEditModal ? (
                                <Form defaultValues={editing} errors={errors} onSubmit={this.onEdit}>
                                    <div className="flex flex-wrap md:-mx-4">
                                        <div className="w-full md:w-1/2  md:px-4">
                                            <Input type="text" name="Short" label="Short" required />
                                        </div>
                                        <div className="w-full md:w-1/2  md:px-4">
                                            <Input type="text" name="Description" label="Description" />
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-end mt-4">
                                        <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                            Cancel
                                        </Button>
                                        {!editingItemType ? (
                                            <Button type="submit">Update {editing.Short}</Button>
                                        ) : (
                                            <Button disabled>
                                                Updating {editing.short}... <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal>
                    <Modal show={showDeleteModal} onClose={this.closeModal}>
                        <div className="p-4">
                            {showDeleteModal ? (
                                <Form onSubmit={this.onDelete}>
                                    <p>{`Are you sure you would like to ${deleting.Hidden ? 'enable' : 'disable'} ${deleting.Short}?`}</p>
                                    <div className="flex justify-end items-end mt-4">
                                        <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                            Cancel
                                        </Button>
                                        {!deletingItemType ? (
                                            <Button
                                                type="submit"
                                                className={`text-${deleting.Hidden ? 'green' : 'red'} bg-white hover:bg-grey-lighter`}
                                            >
                                                Yes, {deleting.Hidden ? 'enable' : 'disable'} {deleting.FormattedTableName}
                                            </Button>
                                        ) : (
                                            <Button className={`text-${deleting.Hidden ? 'green' : 'red'} bg-white hover:bg-grey-lighter`} disabled>
                                                {deleting.Hidden ? 'Enabling' : 'Disabling'} {deleting.Short}...{' '}
                                                <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deletingItemType: state.types._updating._deletingItemType,
    creatingItemType: state.types._updating._creatingItemType,
    editingItemType: state.types._updating._editingItemType,
    errors: (state.types._errors && state.types._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    addItemType: (_type, item) => dispatch(typesOperations.addItemType(_type, item)),
    editItemType: (_type, item) => dispatch(typesOperations.editItemType(_type, item)),
    deleteItemType: (_type, item) => dispatch(typesOperations.deleteItemType(_type, item))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeItemsList);
