import axios from 'axios';
import { baseUrl } from '../../config';
import batchMessages from '../../utils/batch-messages';

export const security = {
    state: {
        settings: {
            AutoLogoutTime: undefined,
            EnablePasswordExpiration: true,
            PasswordExpirationTime: undefined
        },
        isLoading: false,
        isSaving: false,
        errors: {}
    },
    reducers: {
        updateSecuritySettings(state, payload) {
            return { ...state, ...{ settings: { ...payload } } };
        },
        isLoading(state, payload) {
            return { ...state, ...{ isLoading: payload } };
        },
        isSaving(state, payload) {
            return { ...state, ...{ isSaving: payload } };
        },
        updateSecuritySettingsFailure(state, payload) {
            return { ...state, ...{ errors: { ...payload } } };
        }
    },
    effects: dispatch => ({
        async getSecuritySettings(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.security.isLoading(true);
            const response = await axios.get(`${baseUrl}/api/security`, {
                headers: { Authorization: `Bearer ${access_token}` }
            });
            dispatch.security.isLoading(false);
            dispatch.security.updateSecuritySettings(response.data);
        },
        async saveSecuritySettings(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            try {
                dispatch.security.isSaving(true);
                const response = await axios.put(`${baseUrl}/api/security`, payload, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                batchMessages('Security settings have been saved.', 'success');
                dispatch.security.updateSecuritySettings(response.data);
            } catch (error) {
                dispatch.security.updateSecuritySettingsFailure(error.response);
            }
            dispatch.security.isSaving(false);
        }
    })
};
