import React, { Component } from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import { Label } from '../../../common/form';
import StockPhraseManager from './StockPhraseManager';
import { optional } from '../../../../utils';

class TextareaSectionField extends Component {
    state = {
        showModal: false
    };

    openModal = () => {
        this.setState({ ...this.state, showModal: true });
    };

    closeModal = () => {
        this.setState({ ...this.state, showModal: false });
    };

    onPhraseSelected = phrase => {
        const { field, onChange } = this.props;
        onChange(field, '#text', `${field['#text'] || ''} ${phrase}`.trim());
        this.closeModal();
    };

    render() {
        const { field, onChange, section, disabled } = this.props;
        const { showModal } = this.state;

        return (
            <>
                <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
                    <div className="w-full px-4">
                        <div className={`flex justify-between items-center`}>
                            <Label instructions={optional(field, '@instructions', '')} muted={!!!field['@showInReport']}>
                                {optional(field, '@label', '')}
                                {field['@required'] ? <strong className="text-red">&nbsp;*</strong> : <></>}
                            </Label>
                            {optional(field, '@allowStockPhrases', '') ? (
                                <Button onClick={this.openModal} inverse className="mb-2" disabled={disabled}>
                                    Add Stock Phrase
                                </Button>
                            ) : (
                                <></>
                            )}
                        </div>
                        <textarea
                            rows="5"
                            className="input-field"
                            name="#text"
                            onChange={event => onChange(field, event.target.name, event.target.value)}
                            value={optional(field, '#text', '')}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <Modal show={showModal} onClose={this.closeModal} zIndex={999} style={{ width: '33%', zIndex: 999 }}>
                    <div className="p-4">
                        {showModal ? (
                            <StockPhraseManager
                                fieldKey={`${section.Section.SectionName}${optional(field, '@name', '')}`}
                                closeModal={this.closeModal}
                                onPhraseSelected={this.onPhraseSelected}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default TextareaSectionField;
