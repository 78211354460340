import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import shortid from 'shortid';

import Guard from '../../../common/Guard';
import { roles } from '../../../../constants';

import { caseTemplatesOperations } from '../../duck';

import Button from '../../../common/Button';
import TypeSelector from '../../../common/TypeSelector';
import CustomCheckbox from '../../../common/CustomCheckbox';

class CCTItems extends Component {
    state = {
        selectedItemType: undefined
    };
    itemTable = undefined;

    onChange = event => {
        this.setState({
            ...this.state,
            selectedItemType: +event.target.value
        });
    };

    addItemType = event => {
        event.preventDefault();
        const { values, setValue, itemTypes } = this.props;
        const { selectedItemType } = this.state;

        const newDisplayOrder =
            values['ItemTypeIds'] && values['ItemTypeIds'].length ? values['ItemTypeIds'][values['ItemTypeIds'].length - 1].DisplayOrder + 1 : 0;

        setValue('ItemTypeIds', [
            ...(values['ItemTypeIds'] || []),
            {
                ...itemTypes.find(it => it.Id === selectedItemType),
                TempId: shortid.generate(),
                DisplayOrder: newDisplayOrder
            }
        ]);

        this.setState({
            ...this.state,
            selectedItemType: undefined
        });
    };

    updateChecked = (id, checked, prop) => {
        const { values, setValue } = this.props;
        let item = values['ItemTypeIds'].find(it => it.TempId === id);

        if (item) {
            item[prop] = checked;
        }
        setValue('ItemTypeIds', values['ItemTypeIds'] || []);
    };

    removeItemType = tempId => {
        const { values, setValue } = this.props;

        setValue('ItemTypeIds', values['ItemTypeIds'].filter(it => it.TempId !== tempId));
    };

    processSwap = swapResult => {
        const { values, setValue } = this.props;

        values['ItemTypeIds'].forEach(it => {
            if (swapResult[it.TempId] !== undefined && swapResult[it.TempId] !== null) {
                it.DisplayOrder = swapResult[it.TempId];
            }
        });

        setValue('ItemTypeIds', values['ItemTypeIds']);
    };

    render() {
        const { values, swappingItems } = this.props;
        const { selectedItemType } = this.state;

        return (
            <div className="mt-2">
                <div className="flex mb-4">
                    <TypeSelector
                        hideLabel
                        className="flex-grow"
                        type="GoalType"
                        name="selectedItemType"
                        label="Item Template"
                        showShort={true}
                        value={selectedItemType}
                        onChange={this.onChange}
                    />
                    <Button disabled={selectedItemType === undefined} className="ml-2 py-2" onClick={this.addItemType}>
                        Add Item
                    </Button>
                </div>
                {values['ItemTypeIds'] && values['ItemTypeIds'].length > 0 ? (
                    <ReactTable
                        data={values['ItemTypeIds']}
                        defaultSorted={[{ id: 'DisplayOrder', desc: false }]}
                        sortable={false}
                        filterable={false}
                        columns={[
                            {
                                Header: 'Item Template',
                                id: 'Description',
                                accessor: d => `${d.Short} (${d.Description})`
                            },
                            {
                                Header: 'Auto Add Item',
                                accessor: 'AutoAdd',
                                Cell: ({ row }) => (
                                    <div className="flex justify-center items-center">
                                        {row._original && (
                                            <CustomCheckbox
                                                checked={row._original.AutoAdd}
                                                name={row._original.TempId}
                                                onChange={e => {
                                                    this.updateChecked(row._original.TempId, e.target.checked, 'AutoAdd');
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            },
                            {
                                Header: 'Show In Report',
                                accessor: 'ShowInReport',
                                Cell: ({ row }) => (
                                    <div className="flex justify-center items-center">
                                        {row._original && (
                                            <CustomCheckbox
                                                checked={row._original.ShowInReport}
                                                name={row._original.TempId}
                                                onChange={e => {
                                                    this.updateChecked(row._original.TempId, e.target.checked, 'ShowInReport');
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            },
                            {
                                Header: 'Show Title',
                                accessor: 'ShowTitle',
                                Cell: ({ row }) => (
                                    <div className="flex justify-center items-center">
                                        {row._original && (
                                            <CustomCheckbox
                                                checked={row._original.ShowTitle}
                                                name={row._original.TempId}
                                                onChange={e => {
                                                    this.updateChecked(row._original.TempId, e.target.checked, 'ShowTitle');
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            },
                            {
                                Cell: ({ row }) => (
                                    <div className="flex justify-center items-center h-full">
                                        <Guard permissions={[roles.CaseTemplates.REMOVE_ITEM_FROM_CASE_TEMPLATE]}>
                                            <span
                                                className="text-red-light cursor-pointer"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    this.removeItemType(row._original.TempId);
                                                }}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </Guard>
                                    </div>
                                )
                            },
                            {
                                accessor: 'DisplayOrder',
                                id: 'DisplayOrder',
                                sortable: false,
                                filterable: false,
                                minWidth: 65,
                                Cell: ({ row, index, viewIndex }) => (
                                    <Guard permissions={[roles.CaseTemplates.REORDER_CASE_TEMPLATE_ITEMS]}>
                                        {viewIndex !== 0 && values['ItemTypeIds'].length > 1 ? (
                                            !swappingItems ? (
                                                <span
                                                    className="text-grey cursor-pointer mr-4"
                                                    onClick={e => {
                                                        const temp = this.itemTable.state.resolvedData[viewIndex]._original.DisplayOrder;
                                                        this.processSwap({
                                                            [this.itemTable.state.resolvedData[viewIndex]._original.TempId]: this.itemTable.state
                                                                .resolvedData[viewIndex - 1]._original.DisplayOrder,
                                                            [this.itemTable.state.resolvedData[viewIndex - 1]._original.TempId]: temp
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-chevron-up" />
                                                </span>
                                            ) : (
                                                <span className="text-grey-light cursor-not-allowed mr-4">
                                                    <i className="fas fa-spinner fa-pulse" />
                                                </span>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                        {viewIndex !== values['ItemTypeIds'].length - 1 && values['ItemTypeIds'].length > 1 ? (
                                            !swappingItems ? (
                                                <span
                                                    className="text-grey cursor-pointer mr-4"
                                                    onClick={e => {
                                                        const temp = this.itemTable.state.resolvedData[viewIndex]._original.DisplayOrder;
                                                        this.processSwap({
                                                            [this.itemTable.state.resolvedData[viewIndex]._original.TempId]: this.itemTable.state
                                                                .resolvedData[viewIndex + 1]._original.DisplayOrder,
                                                            [this.itemTable.state.resolvedData[viewIndex + 1]._original.TempId]: temp
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-chevron-down" />
                                                </span>
                                            ) : (
                                                <span className="text-grey-light cursor-not-allowed mr-4">
                                                    <i className="fas fa-spinner fa-pulse" />
                                                </span>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </Guard>
                                )
                            }
                        ]}
                        getTheadProps={() => ({
                            style: { background: 'white' }
                        })}
                        getTheadFilterProps={() => ({
                            style: { background: 'white' }
                        })}
                        pageSize={values['ItemTypeIds'].length}
                        showPagination={false}
                    >
                        {(state, makeTable, instance) => {
                            this.itemTable = instance;
                            return makeTable();
                        }}
                    </ReactTable>
                ) : (
                    <div className="text-primary">This case template has no items yet.</div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    itemTypes: state.types['GoalType'] ? state.types['GoalType'].Types : [],
    swappingItems: state.caseTemplates._updating._swappingItems
});

const mapDispatchToProps = (dispatch, props) => ({
    swapItems: swapObject => dispatch(caseTemplatesOperations.swapCaseTemplateItems({ caseTemplate: props.values, swapObject })) //dispatch.items.swapItems({ _case, swapObject }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CCTItems);
