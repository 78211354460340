import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';

import { orgOperations } from '../orgs/duck';
import { accountSelectors } from '../account/duck';
import Modal from '../common/Modal';
import Button from '../common/Button';
import { orgTreeUpdated, orgTreeToggled } from '../common/methods';
import { buildOrgTree, optional, setCheckedInTree, getCheckedInTree, getFileName } from '../../utils';
import { tags, WorkflowState, roles } from '../../constants';
import ItemsList from '../items/ItemsList';
import getCaseColumns from './methods/getCaseColumns';
import { FetchSelectorConsumer } from '../common/form';
import { Form } from '../common/form/Form';
import Guard from '../common/Guard';
import { toast } from 'react-toastify';
import CaseCreator from './components/CaseCreator';
import SideNav from '../general/SideNav';
import PageHeader from '../common/PageHeader';
import CustomCheckbox from '../common/CustomCheckbox';
import CustomSearchBox from '../common/CustomSearchBox';
import SideNavMenuItem from '../general/SideNavItem';
import CustomTreeView from '../common/CustomTreeView';
import FetchSelector from '../common/FetchSelector';

class Cases extends Component {
    state = {
        additional: {
            OrgIds: [],
            StartDate: '',
            EndDate: '',
            IncludePeopleWhoWorkForMe: false,
        },
        casesStatusTree: [],
        itemStatusTree: [],
        orgs: [],
        showNewCaseModal: false,
        showReviewerModal: false,
        casesProcessing: {},
        checkedCases: {},
        CreateDuplicate: false,
        subject: {},
        filtered: [],
        sorted: [],
        expanded: {},
        downloading: {},
    };
    caseNumberInput = React.createRef();
    caseSubjectInput = React.createRef();

    columns = getCaseColumns.call(this, this.props.userGroups);

    constructor(props) {
        super(props);
        this.orgTreeUpdated = orgTreeUpdated.bind(this);
        this.orgTreeToggled = orgTreeToggled.bind(this);
        this.submitBtn = React.createRef();
    }

    componentDidMount() {
        this.props.getCaseStates();
        this.props.getItemStates();
        this.props.getOrgs();
        this.props.setAttachments([]);
        /*global $*/
    }

    componentWillUnmount() {

    }
    caseUpdated = _.debounce((name, message) => {
        const caseData = JSON.parse(message);
        const caseIsInList = this.props.cases.some((c) => c.Id == caseData.Id);
        if (caseIsInList) {
            toast.info(
                ({ closeToast }) => (
                    <div
                        onClick={() => {
                            this.fetchImmediate(this.state, null);
                            closeToast();
                        }}
                    >
                        Case #{caseData.CaseNumber} <strong>{caseData.Subject.LastName}</strong> was updated. Click to refresh.
                    </div>
                ),
                {
                    autoClose: 6000,
                    pauseOnHover: true,
                    toastId: caseData.Id,
                }
            );
        } else {
            toast.info(
                ({ closeToast }) => (
                    <div
                        onClick={() => {
                            this.fetchImmediate(this.state, null);
                            closeToast();
                        }}
                    >
                        A new case was added. Click to refresh.
                    </div>
                ),
                {
                    autoClose: 6000,
                    pauseOnHover: true,
                    toastId: caseData.Id,
                }
            );
        }
    }, 300);

    static getDerivedStateFromProps({ loadingOrgs, orgs, CaseStates, ItemStates, caseFilters }, prevState) {
        // Skip if we already generated the trees
        if (prevState.orgs.length && prevState.casesStatusTree.length && prevState.itemStatusTree.length) {
            // We will want to update for any saved filters, however.

            return {
                ...prevState,
                casesStatusTree: setCheckedInTree(prevState.casesStatusTree, caseFilters.additional.CaseStateIDs || []),
                itemStatusTree: setCheckedInTree(prevState.itemStatusTree, caseFilters.additional.ItemStateIDS || []),
                orgs: setCheckedInTree(prevState.orgs, caseFilters.additional.OrgIds || []),
            };
        }

        const orgTree = buildOrgTree(orgs, orgs[0]);
        orgTree.isExpanded = true;
        const active = CaseStates.filter(
            (state) =>
                state.Name !== WorkflowState.ON_HOLD &&
                state.Name !== WorkflowState.COMPLETE &&
                state.Name !== WorkflowState.CHECKING_DUPLICATION &&
                state.Name !== WorkflowState.LINKING_CASE &&
                state.Name !== WorkflowState.SETTING_DEADLINES &&
                state.Name !== WorkflowState.DISCONTINUED
        );
        const hold = CaseStates.find((state) => state.Name === WorkflowState.ON_HOLD) || {};
        const closed = CaseStates.find((state) => state.Name === WorkflowState.COMPLETE) || {};
        const discontinued = CaseStates.find((state) => state.Name === WorkflowState.DISCONTINUED) || {};
        const casesStatusTree = [
            {
                id: 'Active',
                name: 'Active',
                children: active.map(({ Id, Name }) => ({ id: Id, name: _.startCase(_.toLower(Name)) })),
                isExpanded: true,
            },
            {
                id: hold.Id,
                name: 'Hold',
            },
            {
                id: closed.Id,
                name: 'Closed',
            },
            {
                id: discontinued.Id,
                name: 'Discontinued',
            },
        ];

        const itemStatusTree = ItemStates.map(({ Id, Short }) => ({ id: Id, name: _.startCase(_.toLower(Short)) }));

        return loadingOrgs
            ? null
            : {
                  ...prevState,
                  orgs: [orgTree],
                  casesStatusTree,
                  itemStatusTree,
              };
    }

    checkAll = (checked) => {
        const { checkedCases } = this.state;
        this.props.cases.forEach(($case) => (checkedCases[$case.Id] = checked));

        this.setState({
            ...this.state,
            allChecked: checked,
            checkedCases,
        });
    };

    updateChecked = (id, index, checked) => {
        let { checkedCases } = this.state;
        checkedCases[id] = checked;

        this.setState({
            ...this.state,
            checkedCases,
            lastCheckedIndex: index,
        });
    };

    multiChecked = (index, checked) => {
        const { lastCheckedIndex, checkedCases } = this.state;

        const cases = this.props.cases.slice(Math.min(index, lastCheckedIndex), Math.max(index, lastCheckedIndex));

        cases.forEach(($case) => (checkedCases[$case.Id] = checked));

        this.setState({
            ...this.state,
            checkedCases,
            lastCheckedIndex: index,
        });
    };

    hideForTags = (filterTags) => {
        return (
            this.props.user &&
            this.props.user.Groups &&
            !this.props.user.Groups.some((group) => group.Tags.some((tag) => filterTags.includes(tag.Name)))
        );
    };

    refreshData = (closeRows = true) => {
        this.setState(
            () => {
                return {
                    ...this.state,
                    expanded: closeRows ? {} : this.state.expanded,
                };
            },
            () => {
                this.fetchData(this.state, null);
            }
        );
    };

    shouldDeleteCase = ($case) => {
        this.setState({
            showDeleteCaseModal: true,
            caseToDelete: { ...$case },
        });
    };

    deleteCase = ($case) => {
        this.setState(
            {
                showDeleteCaseModal: false,
                ...{ casesProcessing: { [$case.Id]: true } },
            },
            async () => {
                try {
                    await this.props.deleteCase($case);
                    let casesProcessing = this.state.casesProcessing;
                    delete casesProcessing[$case.Id];
                    this.setState({
                        showDeleteCaseModal: false,
                        casesProcessing,
                    });
                } catch (error) {
                    this.setState({
                        showDeleteCaseModal: false,
                    });
                }
            }
        );
    };

    async fetchImmediate(state = undefined) {
        const { pageSize, page, sorted, additional, filtered } = this.props.caseFilters;
        if (!state) state = this.state;

        try {
            const response = await this.props.getCases({
                pageSize,
                page: page || 0,
                sorted: sorted || [],
                filtered: filtered || [],
                additional: {
                    ...this.state.additional,
                    ...additional,
                },
            });
            this.props.updateCaseFilters({
                ...this.props.caseFilters,
                pages: response.data.pageCount,
                page: Math.min(this.props.caseFilters.page, Math.max(response.data.pageCount - 1, 0)),
            });
            this.setState({ ...state, additional: { ...this.state.additional }, pages: response.data.pageCount });
        } catch (e) {
            console.log(e);
        }
    }

    fetchData = _.debounce(this.fetchImmediate.bind(this), 250);

    debounceChange = _.debounce((id, e) => {
        this.updateFilter(id, e.target.value);
    }, 250);

    submitReviewer = async (values) => {
        try {
            const cases = this.props.cases.filter((c) => this.state.checkedCases[c.Id]);
            let promises = [];

            this.setState(
                {
                    assigning: true,
                },
                async () => {
                    cases.forEach((c) => {
                        c.reviewerId = values.Reviewer_Id;
                        promises.push(this.props.assignReviewer(c));
                    });

                    await Promise.all(promises);

                    toast.success('Cases updated.');

                    this.setState(
                        {
                            assigning: false,
                            showReviewerModal: false,
                        },
                        () => {
                            this.fetchData(this.state, null);
                        }
                    );
                }
            );
        } catch (error) {
            this.setState({
                assigning: false,
                showReviewerModal: true,
            });
        }
    };

    clearFilters = () => {
        function unCheck(node) {
            node.isChecked = false;

            if (node.children) {
                node.children.forEach((child) => unCheck(child));
            }
        }

        let { casesStatusTree, itemStatusTree, orgs } = this.state;
        casesStatusTree.forEach((node) => unCheck(node));
        itemStatusTree.forEach((node) => unCheck(node));
        orgs.forEach((node) => unCheck(node));

        this.caseNumberInput.current.value = '';
        this.caseSubjectInput.current.value = '';

        this.props.updateCaseFilters({
            page: 0,
            pageSize: 20,
            additional: {
                CaseStateIDs: [],
                OrgIds: [],
                ItemStateIDS: [],
                StartDate: '',
                EndDate: '',
                IncludePeopleWhoWorkForMe: false,
            },
            sorted: [],
            filtered: [],
        });

        this.setState(
            {
                ...this.state,
                casesStatusTree: [],
                itemStatusTree: [],
                orgs: [],
            },
            () =>
                this.setState(
                    {
                        casesStatusTree,
                        itemStatusTree,
                        orgs,
                    },
                    () => this.fetchData(this.state)
                )
        );
    };

    updateFilter = (id, value) => {
        const { filtered } = this.props.caseFilters;
        if (filtered) {
            const filter = filtered.find((f) => f.id === id);

            if (filter) {
                filter.value = value;
            } else {
                filtered.push({
                    id,
                    value,
                });
            }
            this.props.updateCaseFilters({
                ...this.props.caseFilters,
                filtered,
            });
            //this.fetchData(this.state);
        }
    };

    downloadReport = async ($case) => {
        try {
            this.setState({
                ...this.state,
                downloading: {
                    ...this.state.downloading,
                    [$case.Id]: true,
                },
            });

            const response = await this.props.downloadReport($case.Id);
            let blob = await response.blob();

            FileSaver.saveAs(blob, getFileName(response.headers.get('content-disposition')));

            this.setState({
                ...this.state,
                downloading: {
                    ...this.state.downloading,
                    [$case.Id]: false,
                },
            });
        } catch (error) {
            this.setState({
                ...this.state,
                downloading: {
                    ...this.state.downloading,
                    [$case.Id]: false,
                },
            });
        }
    };

    render() {
        let { loadingOrgs, cases, user, saving, showNewCaseModal, hideModal, showModal, loading, caseFilters, userRoles } = this.props;
        let {
            showDeleteCaseModal,
            showReviewerModal,
            caseToDelete,
            orgs,
            casesStatusTree,
            itemStatusTree,
            checkedCases,
            assigning,
            expanded,
        } = this.state;
        const { additional, filtered } = caseFilters;
        const expandedIndex = Object.keys(expanded).find((k) => !!expanded[k]);
        const selectedCases = cases.filter(($case) => checkedCases[$case.Id]);
        const userTags = (this.props.user && _.flatten(this.props.user.Groups.map((g) => g.Tags.map((t) => t.Name)))) || [];
        const allColumns = userTags.some((t) => t === tags.SYS_ADMIN || t === tags.CASE_ADMIN);
        const columns = this.columns.filter((col) => {
            if (allColumns) return true;
            switch (col.accessor) {
                case 'Code':
                    return userRoles.includes(roles.CaseColumns.ORG);
                case 'Reviewer':
                    return userRoles.includes(roles.CaseColumns.REVIEWER);
                case 'CompletionDate':
                    return userRoles.includes(roles.CaseColumns.COMPLETION_DATE);
                case 'ReceivedDate':
                    return userRoles.includes(roles.CaseColumns.RECEIVED_DATE);
                default:
                    return true;
            }
        });

        return (
            <>
                <SideNav icons={['fa-address-book', 'fa-briefcase', 'fa-paperclip']}>
                    <div className="w-100">
                        <div className="my-4 flex">
                            <Button className="m-auto" inverse onClick={this.clearFilters}>
                                Clear Filters
                            </Button>
                            <Button className="m-auto ml-4" inverse onClick={() => this.fetchImmediate()}>
                                Apply Filters
                            </Button>
                        </div>
                        <SideNavMenuItem icon="fa-address-book" title="Orgs">
                            <Scrollbars autoHeight autoHeightMin={300}>
                                {loadingOrgs ? (
                                    <span>
                                        Loading orgs...
                                        {'\u00A0'}
                                        {'\u00A0'}
                                        <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                ) : (
                                    <CustomTreeView
                                        data={orgs}
                                        onUpdateCb={(updatedData, checkedValues) => {
                                            this.orgTreeUpdated(updatedData, caseFilters, this.props.updateCaseFilters);
                                        }}
                                        onCheckToggleCb={(nodes) => {
                                            this.orgTreeToggled(nodes, caseFilters, this.props.updateCaseFilters);
                                        }}
                                        isExpandable={(node) => node.children && node.children.length > 0}
                                        node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                                    />
                                )}
                            </Scrollbars>
                        </SideNavMenuItem>
                        <SideNavMenuItem icon="fa-briefcase" title="Case Status">
                            <CustomTreeView
                                header="Case Status"
                                data={casesStatusTree}
                                onUpdateCb={(updatedData, checkedValues) => {
                                    const newValues = getCheckedInTree(updatedData);
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        additional: {
                                            ...caseFilters.additional,
                                            CaseStateIDs: newValues,
                                        },
                                    });
                                    this.setState({
                                        casesStatusTree: updatedData,
                                    });
                                }}
                                onCheckToggleCb={(nodes) => {
                                    const checkState = nodes[0].isChecked;
                                    const CaseStateIDs = [];
                                    const applyCheckStateTo = (nodes) => {
                                        nodes.forEach((node) => {
                                            node.isChecked = checkState;
                                            if (checkState) CaseStateIDs.push(node.id);
                                            if (node.children) applyCheckStateTo(node.children);
                                        });
                                    };

                                    applyCheckStateTo(nodes);
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        additional: {
                                            ...caseFilters.additional,
                                            CaseStateIDs: checkState
                                                ? [...(caseFilters.additional.CaseStateIDs || []), ...CaseStateIDs]
                                                : (caseFilters.additional.CaseStateIDs || []).filter((id) => !CaseStateIDs.includes(id)),
                                        },
                                    });
                                }}
                                node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                            />
                        </SideNavMenuItem>
                        <SideNavMenuItem icon="fa-paperclip" title="Item Status">
                            <CustomTreeView
                                header="Item Status"
                                data={itemStatusTree}
                                onUpdateCb={(updatedData, checkedValues) => {
                                    const oldIds = caseFilters.additional ? caseFilters.additional.ItemStateIDS : [];
                                    const newValues = getCheckedInTree(updatedData);
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        additional: {
                                            ...caseFilters.additional,
                                            ItemStateIDS: newValues,
                                        },
                                    });
                                    this.setState({
                                        itemStatusTree: updatedData,
                                    });
                                }}
                                onCheckToggleCb={(nodes) => {
                                    const checkState = nodes[0].isChecked;
                                    const ItemStateIDS = [];

                                    const applyCheckStateTo = (nodes) => {
                                        nodes.forEach((node) => {
                                            node.isChecked = checkState;
                                            if (checkState) ItemStateIDS.push(node.id);
                                            if (node.children) applyCheckStateTo(node.children);
                                        });
                                    };

                                    applyCheckStateTo(nodes);
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        additional: {
                                            ...caseFilters.additional,
                                            ItemStateIDS: checkState
                                                ? [...(caseFilters.additional.ItemStateIDS || []), ...ItemStateIDS]
                                                : (caseFilters.additional.ItemStateIDS || []).filter((id) => !ItemStateIDS.includes(id)),
                                        },
                                    });
                                }}
                                node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                            />
                        </SideNavMenuItem>
                    </div>
                </SideNav>
                <div className="flex">
                    <div className="w-full ml-12">
                        <div className="flex justify-between items-center w-full flex-wrap">
                            <PageHeader>Cases</PageHeader>
                            <div className="flex mr-4 my-4 flex-wrap">
                                <Button
                                    onClick={() => {
                                        this.fetchImmediate(this.state, null);
                                    }}
                                    inverse
                                    icon="fa-redo"
                                    className="mx-1"
                                    loading={loading}
                                >
                                    Refresh
                                </Button>
                                <Guard permissions={[roles.Cases.ASSIGN_REVIEWER]}>
                                    {selectedCases && selectedCases.length > 0 && (
                                        <Button
                                            className="mx-1"
                                            onClick={() =>
                                                this.setState({
                                                    showReviewerModal: true,
                                                })
                                            }
                                            inverse
                                            icon="fa-user-plus"
                                        >
                                            Assign Reviewer
                                        </Button>
                                    )}
                                </Guard>
                                <Button onClick={showModal} inverse icon="fa-briefcase" className="mx-1">
                                    Add Case
                                </Button>
                            </div>
                        </div>

                        <div className="flex flex-wrap w-full bg-table-search p-4">
                            <div className="flex px-8 flex  items-center w-full md:w-1/3">
                                <div className="flex flex-wrap -mx-8">
                                    <div className="px-8 flex items-center justify-center my-2 w-1/3 md:w-1/2">
                                        <CustomSearchBox
                                            defaultValue={optional(filtered.find((f) => f.id === 'CaseNumber'), (t) => t.value, '')}
                                            className="input-field"
                                            placeholder="Search Case"
                                            onChange={(e) => this.debounceChange('CaseNumber', e.nativeEvent)}
                                            onBlur={(e) => this.fetchImmediate(this.state)}
                                            ref={this.caseNumberInput}
                                        />
                                    </div>
                                    <div className="px-8 flex items-center justify-center my-2 w-1/3 md:w-1/2">
                                        <CustomSearchBox
                                            defaultValue={optional(filtered.find((f) => f.id === 'Subject'), (t) => t.value, '')}
                                            className="input-field"
                                            placeholder="Search Name"
                                            onChange={(e) => {
                                                this.debounceChange('Subject', e.nativeEvent);
                                            }}
                                            onBlur={(e) => this.fetchImmediate(this.state)}
                                            ref={this.caseSubjectInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full md:w-1/3">
                                <div className="px-8 flex items-center justify-center my-2 w-full">
                                    <FetchSelector
                                        label="Reviewer"
                                        name="Reviewer_Id"
                                        url={`reviewers`}
                                        valueKey="Id"
                                        textKey="Name"
                                        errors={{}}
                                        onChange={(event) => {
                                            this.props.updateCaseFilters({
                                                ...caseFilters,
                                                additional: {
                                                    ...caseFilters.additional,
                                                    Reviewer_Id: event.target.value,
                                                },
                                            });
                                        }}
                                        onBlur={(e) => this.fetchImmediate(this.state)}
                                        value={caseFilters.additional.Reviewer_Id}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center flex-wrap">
                                <div className="flex -mx-8 flex-wrap">
                                    <div className="px-8 flex flex-col items-start justify-center my-2 w-full md:w-1/2">
                                        <CustomCheckbox
                                            className="mb-2"
                                            checked={caseFilters.additional.IncludePeopleWhoWorkForMe}
                                            onChange={(event) => {
                                                this.props.updateCaseFilters({
                                                    ...caseFilters,
                                                    additional: {
                                                        ...caseFilters.additional,
                                                        IncludePeopleWhoWorkForMe: event.target.checked,
                                                    },
                                                });
                                                this.fetchData(this.state);
                                            }}
                                            label="Include people assigned to me"
                                        />
                                        <CustomCheckbox
                                            checked={caseFilters.additional.OnlyShowCasesAssignedToMe}
                                            onChange={(event) => {
                                                this.props.updateCaseFilters({
                                                    ...caseFilters,
                                                    additional: {
                                                        ...caseFilters.additional,
                                                        OnlyShowCasesAssignedToMe: event.target.checked,
                                                    },
                                                });
                                                this.fetchData(this.state);
                                            }}
                                            label="Only show cases assigned to me"
                                        />
                                    </div>
                                    <div className="px-8 flex items-center justify-center relative my-2 w-full md:w-1/2">
                                        <div
                                            className="hidden"
                                            onClick={() => {
                                                this.setState({ focusedSearchDates: ['startDate', 'endDate'] });
                                            }}
                                        >
                                            <i className="fas fa-calendar-alt mr-2 text-accent" />
                                            <span className="uppercase text-primary">Search Dates</span>
                                        </div>
                                        <DateRangePicker
                                            startDate={additional.StartDate ? moment(additional.StartDate) : null}
                                            startDateId="StartDate"
                                            endDate={additional.EndDate ? moment(additional.EndDate) : null}
                                            endDateId="EndDate"
                                            onDatesChange={({ startDate, endDate }) => {
                                                this.props.updateCaseFilters({
                                                    ...caseFilters,
                                                    additional: {
                                                        ...caseFilters.additional,
                                                        StartDate: startDate,
                                                        EndDate: endDate,
                                                    },
                                                });
                                            }}
                                            focusedInput={this.state.focusedSearchDates || null}
                                            onFocusChange={(focusedInput) => {
                                                this.setState(
                                                    {
                                                        focusedSearchDates: focusedInput,
                                                    },
                                                    () => {
                                                        if (!focusedInput) this.fetchImmediate(this.state);
                                                    }
                                                );
                                            }}
                                            enableOutsideDays={true}
                                            isOutsideRange={() => false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded pl-4 shadow mb-8 overflow-x-auto pb-4">
                            <ReactTable
                                className="text-primary"
                                columns={columns}
                                data={cases}
                                loading={loading}
                                expanded={expanded}
                                manual
                                filterable
                                onFetchData={() => this.fetchImmediate()}
                                pages={caseFilters.pages}
                                page={caseFilters.page}
                                pageSize={caseFilters.pageSize}
                                defaultPageSize={20}
                                onExpandedChange={(expanded) => {
                                    this.setState({ expanded });
                                }}
                                onPageChange={(page) => {
				console.log("Page");
				console.log(page);
                                    caseFilters.page = page;
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        page,
                                    });
                                }}
                                onPageSizeChange={(pageSize, page) => {
                                 caseFilters.pageSize=pageSize;
                                  //  console.log(caseFilters.pageSize);
                                    this.props.updateCaseFilters({
                                        ...caseFilters,
                                        page,
                                        pageSize,
                                    });
                                    // console.log(caseFilters.pageSize);
                                }}
                                onSortedChange={(sorted) => this.props.updateCaseFilters({ ...caseFilters, sorted })}
                                sorted={caseFilters.sorted}
                                SubComponent={({ row }) => (
                                    <div className="p-4">
                                        <ItemsList _case={row._original} ItemStateIDS={caseFilters.additional.ItemStateIDS || []} />
                                    </div>
                                )}
                                getTdProps={(state, rowInfo, column) => ({
                                    onClick: (e, handleOriginal) => {
                                        if (typeof column.Header === 'string' && this.props.hasPermission([roles.Cases.VIEW_CASES])) {
                                            this.props.history.push(`/cases/${rowInfo.row._original.Id}`);
                                        } else {
                                            handleOriginal && handleOriginal();
                                        }
                                    },
                                    className: `${typeof column.Header === 'string' ? 'cursor-pointer' : ''} ${
                                        typeof expandedIndex === 'string' && !!!expanded[rowInfo && rowInfo.index] ? `opacity-25` : ''
                                    }`,
                                })}
                            />
                        </div>
                    </div>
                </div>
                <Modal show={showNewCaseModal} onClose={hideModal}>
                    {user && showNewCaseModal ? (
                        <CaseCreator
                            user={user}
                            isSaving={saving}
                            onClose={hideModal}
                            onSave={() => {
                                this.fetchImmediate(this.state);
                            }}
                        />
                    ) : (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                </Modal>
                <Modal show={showDeleteCaseModal} onClose={() => this.setState({ showDeleteCaseModal: false })} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>{`Delete case ${caseToDelete && caseToDelete.CaseNumber}?`} This action cannot be undone.</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={() => this.setState({ showDeleteCaseModal: false })}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.deleteCase.bind(this, caseToDelete)}>
                            Delete Case
                        </Button>
                    </div>
                </Modal>
                <Modal show={showReviewerModal} onClose={() => this.setState({ showReviewerModal: false })} className="max-w-md">
                    <h2>Assign Reviewer</h2>
                    <ul className="mt-2">
                        {selectedCases.map(($case) => (
                            <li key={$case.Id}>{$case.CaseNumber}</li>
                        ))}
                    </ul>
                    <Form onSubmit={this.submitReviewer}>
                        <div className="flex flex-wrap mt-2">
                            <div className="mb-4 flex-grow">
                                <FetchSelectorConsumer
                                    label="Reviewer"
                                    name="Reviewer_Id"
                                    url={`cases/assignees/${user.Org_Id}/${tags.REVIEWER}`}
                                    valueKey="Id"
                                    textKey="Name"
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="flex w-full">
                            <Button className="bg-grey ml-auto mr-2" onClick={() => this.setState({ showReviewerModal: false })}>
                                Cancel
                            </Button>
                            <Button type="submit" loading={assigning}>
                                Assign
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }
}

const mapState = (state) => ({
    cases: state.cases.cases,
    loading: state.cases._updating._gettingCases,
    saving: state.cases._updating._savingCase,
    showNewCaseModal: state.cases.showNewCaseModal,
    orgs: state.orgs.orgs,
    loadingOrgs: state.orgs._updating._gettingOrgs,
    user: state.account.user,
    CaseStates: state.workflowState.CaseStates || [],
    ItemStates: state.workflowState.ItemStates || [],
    access_token: optional(state, (t) => t.account.auth.access_token, ''),
    caseFilters: state.filters.caseFilters,
    hasPermission: (permissions) => accountSelectors.hasPermission(state.account, permissions),
    userRoles: state.account.permissions.roles,
    userGroups: state.account.permissions.groups,
});

const mapDispatch = (dispatch) => ({
    getCases: dispatch.cases.getCases,
    getOrgs: () => dispatch(orgOperations.getOrgs()),
    deleteCase: (payload) => dispatch.cases.deleteCase(payload),
    assignReviewer: dispatch.cases.assignReviewer,
    setAttachments: dispatch.cases.setAttachments,
    showModal: dispatch.cases.showModal,
    hideModal: dispatch.cases.hideModal,
    getCaseStates: dispatch.workflowState.getCaseStates,
    getItemStates: dispatch.workflowState.getItemStates,
    updateCaseFilters: (filters) => dispatch.filters.updateCaseFilters(filters),
    downloadReport: dispatch.cases.downloadReport,
});

export default withRouter(
    connect(
        mapState,
        mapDispatch
    )(Cases)
);
