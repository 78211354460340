import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ItemTypes from './ItemTypes';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const itemTypesRouter = () => (
    <Route key="item-types-router" path="/item-types">
        <Switch key="item-types-router-switch">
            <Guard key='item-types-route' exact path='/item-types' notFound={NotFound} permissions={[roles.ItemTypes.VIEW_ITEM_TYPES]}>
                <AuthRoute exact path='/item-types' component={ItemTypes} />
            </Guard>
        </Switch>
    </Route>
);

export default itemTypesRouter;