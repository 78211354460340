import React, { Component } from 'react';
import { connect } from 'react-redux';

import ControlledInput from '../../common/ControlledInput';
import Button from '../../common/Button';

import { accountOperations } from '../duck';

class ChangePassword extends Component {
    state = {
        OldPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
    };

    submitUpdateForm = (event) => {
        event.preventDefault();

        const { OldPassword, NewPassword, ConfirmPassword } = this.state;
        const canChangePassword = !!OldPassword && !!NewPassword && !!ConfirmPassword;

        if (!canChangePassword) return;

        if (!this.props.updatingPassword) this.props.updatePassword(this.state);
    };

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { updatingPassword } = this.props;
        const { OldPassword, NewPassword, ConfirmPassword } = this.state;
        const errors = this.props.errors ? this.props.errors.ModelState : {};

        return (
            <>
                <h1 className="text-primary text-xl uppercase tracking-wide font-medium mb-4">Password</h1>
                <div className="bg-white mb-4 p-4 mb-8">
                    <form className="flex flex-col" onSubmit={this.submitUpdateForm}>
                        <div className="flex flex-col sm:flex-row">
                            <div className="mb-4 sm:mr-4 flex-1">
                                <ControlledInput
                                    label="Current Password"
                                    name="OldPassword"
                                    errors={errors}
                                    type="password"
                                    onChange={this.handleInputChange}
                                    value={OldPassword}
                                />
                            </div>
                            <div className="mb-4 sm:mr-4 flex-1">
                                <ControlledInput
                                    label="New Password"
                                    name="NewPassword"
                                    errors={errors}
                                    type="password"
                                    onChange={this.handleInputChange}
                                    value={NewPassword}
                                />
                            </div>
                            <div className="mb-4 flex-1">
                                <ControlledInput
                                    label="Confirm Password"
                                    name="ConfirmPassword"
                                    errors={errors}
                                    type="password"
                                    onChange={this.handleInputChange}
                                    value={ConfirmPassword}
                                />
                            </div>
                        </div>
                        {!updatingPassword ? (
                            <Button className="ml-auto" type="submit">
                                Change Password
                            </Button>
                        ) : (
                            <Button className="ml-auto" disabled>
                                Changing password... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                    </form>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    updatingPassword: state.account ? state.account._updating._updatingPassword : null,
    errors: state.account ? state.account.errors : null,
});

const mapDispatchToProps = (dispatch) => ({
    updatePassword: (passwordObject) => dispatch(accountOperations.updatePassword(passwordObject)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);
