import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { Input, ValidationError } from '../common/form';
import Button from '../common/Button';
import { Form } from '../common/form/Form';
import { baseUrl } from '../../config';
import Creators from './duck/actions';

export class ChangePassword extends Component {
    state = {
        passwordChanged: false,
        loading: false,
        errors: {},
        message: []
    };

    onSubmit = async values => {
        this.setState(
            {
                loading: true
            },
            async () => {
                try {
                    await axios.post(`${baseUrl}/api/user/anonymous-password`, values);

                    this.setState({
                        passwordChanged: true,
                        loading: false
                    });

                    this.props.clearLoginErrors();
                } catch (error) {
                    if (error.response && error.response.data) {
                        this.setState({
                            ...this.state,
                            loading: false,
                            errors: error.response.data.ModelState,
                            message: error.response.data.ModelState.invalid_login
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            loading: false,
                            errors: {},
                            message: ['Username or password is incorrect']
                        });
                    }
                }
            }
        );
    };

    render() {
        const { loading, passwordChanged, errors, message } = this.state;

        if (passwordChanged) {
            return (
                <Redirect
                    to={{
                        pathname: '/login'
                    }}
                />
            );
        }
        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="mb-2 text-base text-primary">Your password has expired.</h1>
                        <p className="mb-4 text-sm text-primary">
                            If you were given a temporary password, please change it now. Otherwise, for security reasons, we require that your
                            password be changed regularly. Please enter a new password that differs from your old one.
                        </p>
                        <Form onSubmit={this.onSubmit} errors={errors}>
                            <div className="mb-4">
                                <Input name="UserName" label="Username" required={true} />
                            </div>
                            <div className="mb-4">
                                <Input name="OldPassword" label="Old Password" required={true} type="password" />
                            </div>
                            <div className="mb-4">
                                <Input name="NewPassword" label="New Password" required={true} type="password" />
                            </div>
                            <div className="mb-4">
                                <Input name="ConfirmPassword" label="Confirm Password" required={true} type="password" />
                            </div>
                            <div className="flex items-center justify-between">
                                {loading ? (
                                    <Button disabled={true}>
                                        <i className="fas fa-spinner fa-pulse" />
                                    </Button>
                                ) : (
                                    <Button type="submit">Submit</Button>
                                )}

                                <Link
                                    to="/login"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </Form>
                        <ValidationError>{message}</ValidationError>
                    </div>

                    <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    clearLoginErrors: () => dispatch(Creators.logInFailure(null))
});

export default connect(
    null,
    mapDispatchToProps
)(ChangePassword);
