import React from 'react';
import InvoiceManager from './InvoiceManager';

const countCases = invoices => Object.keys(invoices).reduce((sum, key) => sum + invoices[key].length, 0);

const InvoiceCompany = ({ companyName, invoices, isExpanded }) => {
    const numCases = invoices ? invoices.length : 0;
    return (
        <div
            className={`bg-white rounded shadow ${
                numCases && !isExpanded ? 'cursor-pointer hover:shadow-md' : isExpanded ? 'shadow-md' : ''
            } p-4 mb-4 overflow-x-auto`}
        >
            <div className={`flex justify-between items-center ${invoices ? 'text-primary' : 'text-grey'}`}>
                <h1 className="text-lg">{companyName}</h1>
                <em>
                    {numCases} uninvoiced case{numCases !== 1 ? 's' : ''}
                </em>
            </div>
            {isExpanded ? (
                <div className="px-8 py-4 my-4 bg-table-search rounded shadow-inner" onClick={e => e.stopPropagation()}>
                    <InvoiceManager companyName={companyName} invoices={invoices} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default InvoiceCompany;
