import React from 'react';
import Button from '../../common/Button';
import { Input } from '../../common/form';
import { Form } from '../../common/form/Form';

export const TrackingInfoForm = ({ submitTrackingInfo, defaultValues, onClose, isAddingTackingInfo }) => (
    <Form onSubmit={submitTrackingInfo} defaultValues={{ Case_Id: defaultValues.Id }}>
        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
            <div className="mb-4 flex-grow md:w-1/2 px-3">
                <Input name="Name" required={true} label="Name" />
            </div>
            <div className="mb-4 flex-grow">
                <Input name="FedExTracking" required={true} label="Tracking Number" />
            </div>
        </div>
        <div className="w-full flex mt-4">
            <Button className="bg-grey ml-auto mr-4" onClick={onClose} disabled={isAddingTackingInfo}>
                Cancel
            </Button>
            {isAddingTackingInfo ? (
                <Button disabled={true}>
                    Adding... <i className="fas fa-spinner fa-pulse" />
                </Button>
            ) : (
                <Button type="submit">Ok</Button>
            )}
        </div>
    </Form>
);

export default TrackingInfoForm;
