import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import _ from 'lodash';
import { toast } from 'react-toastify';

import Guard from '../common/Guard';
import { roles } from '../../constants';
import { accountSelectors } from '../account/duck';

import Modal from '../common/Modal';
import Button from '../common/Button';

import RemoveItemModal from './components/RemoveItemModal';
import NewItemForm from './components/NewItemForm';
import AssignInvestigatorModal from './components/AssignInvestigatorModal';
import SetACDModal from './components/SetACDModal';
import AcceptItemsModal from './components/AcceptItemsModal';
import RejectItemsModal from './components/RejectItemsModal';
import ItemEditor from './ItemEditor';
import { expandRow } from '../identity/components/methods';
import SubmitItemsModal from './components/SubmitItemsModal';
import { optional } from '../../utils';
import PageHeader from '../common/PageHeader';

import CustomSearchBox from '../common/CustomSearchBox';
import getItemColumns from './methods/getItemColumns';

class ItemsList extends Component {
    state = {
        checkedItems: {},
        showCreateModal: false,
        showDeleteModal: false,
        showAssignModal: false,
        showACDModal: false,
        showAcceptModal: false,
        showRejectModal: false,
        itemToDelete: undefined,
        expanded: {},
        filtered: [],
    };
    itemTable = undefined;
    caseNumberInput = React.createRef();
    itemLocationInput = React.createRef();
    itemLastNameInput = React.createRef();
    itemTemplateInput = React.createRef();

    constructor(props) {
        super(props);
        this.expandRow = expandRow.bind(this);
    }

    componentDidMount() {
        const { _case, ItemStateIDS, all } = this.props;
        if (!all) this.props.getItems(_case.Id, ItemStateIDS || []);
        this.props.provideConnection && this.props.provideConnection(this);
        /*global $*/

    }

    componentWillUnmount() {
        this.props.provideConnection && this.props.provideConnection(undefined);
    }

    itemAdded = _.debounce((name, message) => {
        const data = JSON.parse(message);
        console.log(name, data);

        toast.info(
            ({ closeToast }) => (
                <div
                    onClick={() => {
                        this.fetchImmediate(this.state, null);
                        closeToast();
                    }}
                >
                    A new item was added. Click to refresh.
                </div>
            ),
            {
                autoClose: 6000,
                pauseOnHover: true,
                toastId: name,
            }
        );
    }, 300);

    itemUpdated = _.debounce((name, message) => {
        const data = JSON.parse(message);
        const item = this.props.items.find((c) => data.includes(c.Id));

        if (item) {
            toast.info(
                ({ closeToast }) => (
                    <div
                        onClick={() => {
                            this.fetchImmediate(this.state, null);
                            closeToast();
                        }}
                    >
                        Item #{item.DisplayId} for case #{item.Case.Subject.LastName} was updated. Click to refresh.
                    </div>
                ),
                {
                    autoClose: 6000,
                    pauseOnHover: true,
                    toastId: name,
                }
            );
        }
    }, 300);

    openModal = (modalName) => {
        this.setState({
            ...this.state,
            [`show${modalName}Modal`]: true,
        });
    };

    closeModal = (modalName) => {
        this.setState({
            ...this.state,
            [`show${modalName}Modal`]: false,
        });
    };

    openDeleteModal = (item) => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
            itemToDelete: item,
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            itemToDelete: undefined,
        });
    };

    updateChecked = (event) => {
        const { items } = this.props;

        if (event.target.name === 'CHECK_ALL')
            this.setState({
                ...this.state,
                checkedItems: items.reduce(
                    (checks, item) => ({
                        ...checks,
                        [item.Id]: event.target.checked,
                    }),
                    {}
                ),
            });
        else
            this.setState({
                ...this.state,
                checkedItems: {
                    ...this.state.checkedItems,
                    [event.target.name]: event.target.checked,
                },
            });
    };

    updateFilter = (id, value) => {
        const { all } = this.props;
        const { filtered } = all ? this.props.itemFilters : this.state;
        if (filtered) {
            const filter = filtered.find((f) => f.id === id);

            if (filter) {
                filter.value = value;
            } else {
                filtered.push({
                    id,
                    value,
                });
            }

            if (all) {
                this.props.updateItemFilters({
                    ...this.props.itemFilters,
                    filtered,
                });
                this.fetchData(this.state);
            } else {
                this.setState({
                    ...this.state,
                    filtered,
                });
            }
        }
    };

    clearFilters = () => {
        this.caseNumberInput.current.value = '';
        this.itemLocationInput.current.value = '';
        this.itemLastNameInput.current.value = '';
        this.itemTemplateInput.current.value = '';

        this.props.updateItemFilters({
            page: 0,
            pageSize: 20,
            additional: {
                ItemStateIDS: [],
            },
            sorted: [],
            filtered: [],
        });
    };

    async fetchImmediate() {
        const { pageSize, page, sorted, additional, filtered } = this.props.itemFilters;
        try {
            const response = await this.props.getAllItems({
                pageSize,
                page: page || 0,
                sorted: sorted || [],
                filtered: filtered || [],
                additional: {
                    ...this.state.additional,
                    ...additional,
                },
            });
            this.props.updateItemFilters({
                ...this.props.itemFilters,
                pages: response.data.pageCount,
                page: Math.min(this.props.itemFilters.page, Math.max(response.data.pageCount - 1, 0)),
                additional: additional,
            });
        } catch (e) {
            console.log(e);
        }
    }

    fetchData = _.debounce(this.fetchImmediate.bind(this), 500);

    debounceChange = _.debounce((id, e) => {
        this.updateFilter(id, e.target.value);
    }, 250);

    render() {
        const { gettingItems, items, ItemStateIDS, _case, hasPermission, swappingItems, all, itemFilters } = this.props;
        const {
            checkedItems,
            showCreateModal,
            showDeleteModal,
            showAssignModal,
            showACDModal,
            showAcceptModal,
            showRejectModal,
            showSubmitModal,
            itemToDelete,
            expanded,
        } = this.state;
        const { filtered } = all ? itemFilters : this.state;
        const allChecked = items.every((i) => !!checkedItems[i.Id]);
        const anyChecked = Object.keys(checkedItems).some((key) => checkedItems[key]);
        const expandedIndex = Object.keys(expanded).find((k) => !!expanded[k]);

        const columns = getItemColumns.call(this, {
            roles,
            all,
            allChecked,
            expandedIndex,
            hasPermission,
            swappingItems,
            _case,
            items,
            checkedItems,
        });

        return (
            <>
                {all || !gettingItems || (items && items.length) ? (
                    <div className={`bg-grey-lighter ${!all ? 'shadow-inner' : ''}`}>
                        <div className="flex justify-between items-center mb-4 pr-4">
                            <div className="flex items-center">
                                <PageHeader>Items</PageHeader>
                                {!all && (
                                    <Guard permissions={[roles.Items.CREATE_ITEMS]}>
                                        <Button inverse icon="fas fa-paperclip" className="ml-4" onClick={() => this.openModal('Create')}>
                                            Add Item
                                        </Button>
                                    </Guard>
                                )}
                            </div>
                            <div className="flex items-center">
                                <Button
                                    onClick={() => {
                                        this.fetchImmediate(this.state, null);
                                    }}
                                    inverse
                                    icon="fa-redo"
                                    className="mx-1"
                                    loading={gettingItems}
                                >
                                    Refresh
                                </Button>
                                {anyChecked ? (
                                    <>
                                        <Guard permissions={[roles.ItemAssignments.ADD_ITEM_ASSIGNMENTS]}>
                                            <Button icon="fa-user-plus" inverse className="mx-1" onClick={() => this.openModal('Assign')}>
                                                Assign Items
                                            </Button>
                                        </Guard>
                                        <Guard permissions={[roles.CaseItems.MANAGE_CASE_ITEM_DEADLINES]}>
                                            <Button icon="fa-user-clock" inverse className="mx-1" onClick={() => this.openModal('ACD')}>
                                                Update ACDs
                                            </Button>
                                        </Guard>
                                        <Guard permissions={[roles.CaseItems.REVIEW_CASE_ITEMS]}>
                                            <Button icon="fa-clipboard-check" inverse className="mx-1" onClick={() => this.openModal('Accept')}>
                                                Accept Items
                                            </Button>
                                        </Guard>
                                        <Guard permissions={[roles.CaseItems.REVIEW_CASE_ITEMS]}>
                                            <Button icon="fa-times-circle" inverse className="mx-1" onClick={() => this.openModal('Reject')}>
                                                Reject Items
                                            </Button>
                                        </Guard>
                                        <Guard permissions={[roles.CaseItems.UPDATE_CASE_ITEM_STATUS]}>
                                            <Button icon="fa-paper-plane" inverse onClick={() => this.openModal('Submit')}>
                                                Submit Items For Review
                                            </Button>
                                        </Guard>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="flex w-full bg-table-search p-4">
                            <div className="flex px-8 flex items-center w-full">
                                <div className="flex -mx-8">
                                    {all && (
                                        <div className="px-8 flex items-center" id="Location">
                                            <CustomSearchBox
                                                defaultValue={optional(filtered.find((f) => f.id === 'CaseNumber'), (t) => t.value, '')}
                                                className="input-field"
                                                placeholder="Search Case #"
                                                onChange={(e) => {
                                                    e.persist();
                                                    this.debounceChange('CaseNumber', e.nativeEvent);
                                                }}
                                                ref={this.caseNumberInput}
                                            />
                                        </div>
                                    )}
                                    <div className="px-8 flex items-center" id="Location">
                                        <CustomSearchBox
                                            defaultValue={optional(filtered.find((f) => f.id === 'Location'), (t) => t.value, '')}
                                            className="input-field"
                                            placeholder="Search Location, City, State"
                                            onChange={(e) => {
                                                e.persist();
                                                this.debounceChange('Location', e.nativeEvent);
                                            }}
                                            ref={this.itemLocationInput}
                                        />
                                    </div>
                                    <div className="px-8 flex items-center" id="LastName">
                                        <CustomSearchBox
                                            defaultValue={optional(filtered.find((f) => f.id === 'LastName'), (t) => t.value, '')}
                                            className="input-field"
                                            placeholder="Search Last Name"
                                            onChange={(e) => {
                                                e.persist();
                                                this.debounceChange('LastName', e.nativeEvent);
                                            }}
                                            ref={this.itemLastNameInput}
                                        />
                                    </div>
                                    {!all && (
                                        <div className="px-8 flex items-center">
                                            <div className="relative w-full">
                                                <select
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
                                                    onChange={(e) => {
                                                        this.updateFilter('ItemStatus', e.target.value);
                                                    }}
                                                    value={optional(filtered.find((f) => f.id === 'ItemStatus'), (t) => t.value, '')}
                                                >
                                                    <option value="ALL">Show All</option>
                                                    <option value="AWAITING_ASSIGNMENT">Awaiting Assignment</option>
                                                    <option value="ASSIGNED">Assigned</option>
                                                    <option value="IN_REVIEW">In Review</option>
                                                    <option value="ACCEPTED">Accepted</option>
                                                    <option value="REJECTED">Rejected</option>
                                                </select>
                                                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="px-8 flex items-center" id="ItemType">
                                        <CustomSearchBox
                                            defaultValue={optional(filtered.find((f) => f.id === 'ItemType'), (t) => t.value, '')}
                                            className="input-field"
                                            placeholder="Search Item Template"
                                            onChange={(e) => {
                                                e.persist();
                                                this.debounceChange('ItemType', e.nativeEvent);
                                            }}
                                            ref={this.itemTemplateInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white shadow rounded">
                            {items.length > 0 || all ? (
                                <ReactTable
                                    filterable
                                    defaultSorted={[{ id: 'Id', desc: false }, ...(all ? [{ id: 'CaseNumber', desc: false }] : [])]}
                                    data={items}
                                    manual={all || undefined}
                                    onFetchData={all && this.fetchData}
                                    columns={columns}
                                    pages={all && itemFilters.pages}
                                    page={all && itemFilters.page}
                                    pageSize={all ? itemFilters.pageSize : items.length}
                                    showPagination={!!all}
                                    showPageSizeOptions={!!all}
                                    expanded={expanded}
                                    filtered={all ? filtered : this.state.filtered}
                                    loading={gettingItems}
                                    onPageChange={
                                        all &&
                                        ((page) =>
                                            this.props.updateItemFilters({
                                                ...itemFilters,
                                                page,
                                            }))
                                    }
                                    onPageSizeChange={
                                        all &&
                                        ((pageSize, page) =>
                                            this.props.updateItemFilters({
                                                ...itemFilters,
                                                page,
                                                pageSize,
                                            }))
                                    }
                                    onSortedChange={all && ((sorted) => this.props.updateItemFilters({ ...itemFilters, sorted }))}
                                    sorted={all && itemFilters.sorted}
                                    getTdProps={(state, rowInfo, column) => ({
                                        className: `${
                                            typeof expandedIndex === 'string' && !!!expanded[rowInfo && rowInfo.index] ? `opacity-25` : ''
                                        }`,
                                        onClick: (e) => {
                                            if (typeof column.Header === 'string' || column.expander) this.expandRow(rowInfo.viewIndex);
                                        },
                                    })}
                                    SubComponent={(row) => {
                                        return (
                                            <div className="p-4 bg-grey-lighter rounded shadow-inner">
                                                <ItemEditor
                                                    caseId={row.original.Case_Id}
                                                    itemId={row.original.Id}
                                                    onSubmit={() =>
                                                        this.setState(
                                                            {
                                                                expanded: {},
                                                            },
                                                            () => {
                                                                this.props.getItems(row.original.Case_Id, this.props.ItemStateIDS || []);
                                                            }
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    }}
                                >
                                    {(state, makeTable, instance) => {
                                        this.itemTable = instance;
                                        return makeTable();
                                    }}
                                </ReactTable>
                            ) : (
                                <div className="text-primary p-4">There are no items on this case.</div>
                            )}
                        </div>
                    </div>
                ) : (
                    <span>
                        Loading... <i className="fas fa-spinner fa-pulse" />
                    </span>
                )}
                <Modal show={showCreateModal} onClose={() => this.closeModal('Create')}>
                    <div className="p-4">
                        {showCreateModal ? (
                            <NewItemForm
                                closeModal={(created) => {
                                    this.closeModal('Create');
                                    created === true && this.props.onChange && this.props.onChange('CompletionDate', undefined);
                                }}
                                _case={_case}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showDeleteModal} onClose={this.closeDeleteModal} className="max-w-md">
                    <div className="p-4">
                        {showDeleteModal ? <RemoveItemModal _case={_case} item={itemToDelete} closeModal={this.closeDeleteModal} /> : <></>}
                    </div>
                </Modal>
                <Modal show={showAssignModal} onClose={() => this.closeModal('Assign')} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        {showAssignModal ? (
                            <AssignInvestigatorModal
                                _case={_case}
                                items={items.filter((item) => checkedItems[item.Id])}
                                closeModal={(canceled) => {
                                    this.closeModal('Assign');
                                    all && !canceled && this.fetchData();
                                }}
                                ItemStateIDS={ItemStateIDS || []}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showACDModal} onClose={() => this.closeModal('ACD')} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        {showACDModal ? (
                            <SetACDModal
                                _case={_case}
                                items={items.filter((item) => checkedItems[item.Id])}
                                closeModal={(canceled) => {
                                    this.closeModal('ACD');
                                    all && !canceled && this.fetchData();
                                }}
                                ItemStateIDS={ItemStateIDS || []}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showAcceptModal} onClose={() => this.closeModal('Accept')} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        {showAcceptModal ? (
                            <AcceptItemsModal
                                _case={_case}
                                items={items.filter((item) => checkedItems[item.Id])}
                                closeModal={(canceled) => {
                                    this.closeModal('Accept');
                                    this.props.onChange && this.props.onChange('CompletionDate', undefined);
                                    all && !canceled && this.fetchData();
                                }}
                                ItemStateIDS={ItemStateIDS || []}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showRejectModal} onClose={() => this.closeModal('Reject')} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        {showRejectModal ? (
                            <RejectItemsModal
                                _case={_case}
                                items={items.filter((item) => checkedItems[item.Id])}
                                closeModal={(canceled) => {
                                    this.closeModal('Reject');
                                    all && !canceled && this.fetchData();
                                }}
                                ItemStateIDS={ItemStateIDS || []}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showSubmitModal} onClose={() => this.closeModal('Submit')} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        {showSubmitModal && (
                            <SubmitItemsModal
                                _case={_case}
                                items={items.filter((item) => checkedItems[item.Id])}
                                closeModal={(canceled) => {
                                    this.closeModal('Submit');
                                    all && !canceled && this.fetchData();
                                }}
                                ItemStateIDS={ItemStateIDS || []}
                            />
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    items: state.items.items[props.all ? '_all' : props._case.Id] || [],
    gettingItems: state.items._updating._gettingItems[props.all ? '_all' : props._case.Id],
    swappingItems: state.items._updating._swappingItems,
    hasPermission: (permissions) => accountSelectors.hasPermission(state.account, permissions),
    itemFilters: state.filters.itemFilters,
});

const mapDispatchToProps = (dispatch) => ({
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS }),
    getAllItems: (payload) => dispatch.items.getAllItems(payload),
    swapItems: (_case, swapObject) => dispatch.items.swapItems({ _case, swapObject }),
    updateItemFilters: (filters) => dispatch.filters.updateItemFilters(filters),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemsList);
