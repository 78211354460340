import React from 'react';
import _ from 'lodash';

import Guard from '../../../../common/Guard';
import { roles } from '../../../../../constants';

import Button from '../../../../common/Button';
import { Header, Divider, Input, EnumTypeSelector, PhoneNumber, Checkbox } from '../../../../common/form';
import { Form, FormConsumer } from '../../../../common/form/Form';

const OrgGeneralForm = ({ defaultValues, errors, onSubmit, updating }) => (
    <Form defaultValues={defaultValues} errors={errors} onSubmit={onSubmit}>
        <>
            <div className="flex flex-wrap -mx-2">
                <div className="w-1/3 px-2">
                    <Input type="text" name="Name" label="Name" required />
                </div>
                <div className="w-1/3 px-2">
                    <Input type="text" name="Code" label="Code" required />
                </div>
                <div className="w-1/3 px-2">
                    <EnumTypeSelector type="ContractType" name="ContractType_Id" label="Contract Type" />
                </div>
            </div>
            <div className="mt-8 mb-4">
                <Header>Address</Header>
                <Divider />
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-1/2 px-2">
                    <Input type="text" name="Address1" label="Street" required />
                </div>
                <div className="w-1/2 px-2">
                    <Input type="text" name="Address2" label="&nbsp;" />
                </div>
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-1/3 px-2">
                    <Input type="text" name="City" label="City" required />
                </div>
                <div className="w-1/3 px-2">
                    <EnumTypeSelector type="RegionType" name="RegionType_Id" label="State" />
                </div>
                <div className="w-1/3 px-2">
                    <Input type="text" name="PostalCode" label="PostalCode" required />
                </div>
            </div>
            <div className="mt-8 mb-4">
                <Header>Phones</Header>
                <Divider />
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-1/3 px-2">
                    <PhoneNumber type="text" name="Phone1" label="Phone 1" required />
                </div>
                <div className="w-1/3 px-2">
                    <PhoneNumber type="text" name="Phone2" label="Phone 2" />
                </div>
                <div className="w-1/3 px-2">
                    <PhoneNumber type="text" name="Fax" label="Fax" />
                </div>
            </div>
            <div className="mt-8 mb-4">
                <Header>Report Generation Settings</Header>
                <Divider />
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
                <div className="w-1/3 px-2">
                    <Input type="password" name="ROIEncrpytionPassword" label="ROI Encryption Password" />
                </div>
                <div className="w-1/3 px-2 mt-4">
                    <Checkbox type="checkbox" name="PrependAttachmentsToROI" label="Prepend Attachments to ROI" />
                </div>
            </div>
            <Guard permissions={[roles.Orgs.EDIT_ORGANIZATION]}>
                <div className="flex justify-end">
                    <FormConsumer>
                        {({ values }) => <Button disabled={_.isEqual(values, defaultValues) || updating} type="submit">
                            {!updating ?
                                'Save Changes' :
                                <>Saving Changes... <i className="fas fa-spinner fa-pulse" /></>
                            }
                        </Button>}
                    </FormConsumer>
                </div>
            </Guard>
        </>
    </Form >
);

export default OrgGeneralForm;