import types from './types';

const getItemTypesRequest = () => ({
    type: types.GET_ITEM_TYPES_REQUEST
});
const getItemTypesSuccess = itemTypes => ({
    type: types.GET_ITEM_TYPES_SUCCESS,
    payload: itemTypes
});
const getItemTypesFailure = errors => ({
    type: types.GET_ITEM_TYPES_FAILURE,
    payload: errors
});

const createItemTypeRequest = () => ({
    type: types.CREATE_ITEM_TYPE_REQUEST
});
const createItemTypeSuccess = itemType => ({
    type: types.CREATE_ITEM_TYPE_SUCCESS,
    payload: itemType
});
const createItemTypeFailure = errors => ({
    type: types.CREATE_ITEM_TYPE_FAILURE,
    payload: errors
});

const updateItemTypeRequest = itemTypeId => ({
    type: types.UPDATE_ITEM_TYPE_REQUEST,
    payload: itemTypeId
});
const updateItemTypeSuccess = itemType => ({
    type: types.UPDATE_ITEM_TYPE_SUCCESS,
    payload: itemType
});
const updateItemTypeFailure = (itemTypeId, errors) => ({
    type: types.UPDATE_ITEM_TYPE_FAILURE,
    payload: { itemTypeId, errors }
});

const deleteItemTypeRequest = itemTypeId => ({
    type: types.DELETE_ITEM_TYPE_REQUEST,
    payload: itemTypeId
});
const deleteItemTypeSuccess = itemTypeId => ({
    type: types.DELETE_ITEM_TYPE_SUCCESS,
    payload: itemTypeId
});
const deleteItemTypeFailure = (itemTypeId, errors) => ({
    type: types.DELETE_ITEM_TYPE_FAILURE,
    payload: { itemTypeId, errors }
});

const addSectionToItemTypeRequest = itemTypeId => ({
    type: types.ADD_SECTION_TO_ITEM_TYPE_REQUEST,
    payload: itemTypeId
});
const addSectionToItemTypeSuccess = itemType => ({
    type: types.ADD_SECTION_TO_ITEM_TYPE_SUCCESS,
    payload: itemType
});
const addSectionToItemTypeFailure = (itemTypeId, errors) => ({
    type: types.ADD_SECTION_TO_ITEM_TYPE_FAILURE,
    payload: { itemTypeId, errors }
});

const updateSectionOnItemTypeRequest = (itemTypeId, sectionId) => ({
    type: types.UPDATE_SECTION_ON_ITEM_TYPE_REQUEST,
    payload: { itemTypeId, sectionId }
});
const updateSectionOnItemTypeSuccess = (itemType) => ({
    type: types.UPDATE_SECTION_ON_ITEM_TYPE_SUCCESS,
    payload: itemType
});
const updateSectionOnItemTypeFailure = (errors) => ({
    type: types.UPDATE_SECTION_ON_ITEM_TYPE_FAILURE,
    payload: errors
});

const removeSectionFromItemTypeRequest = (itemTypeId, sectionId) => ({
    type: types.REMOVE_SECTION_FROM_ITEM_TYPE_REQUEST,
    payload: { itemTypeId, sectionId }
});
const removeSectionFromItemTypeSuccess = (itemType, sectionId) => ({
    type: types.REMOVE_SECTION_FROM_ITEM_TYPE_SUCCESS,
    payload: { itemType, sectionId }
});
const removeSectionFromItemTypeFailure = (itemTypeId, sectionId, errors) => ({
    type: types.REMOVE_SECTION_FROM_ITEM_TYPE_FAILURE,
    payload: { itemTypeId, sectionId, errors }
});

const swapItemTypeSectionRequest = () => ({
    type: types.SWAP_ITEM_TYPE_SECTION_REQUEST
});
const swapItemTypeSectionSuccess = (itemTypeId, swapResult) => ({
    type: types.SWAP_ITEM_TYPE_SECTION_SUCCESS,
    payload: { itemTypeId, swapResult }
});
const swapItemTypeSectionFailure = (errors) => ({
    type: types.SWAP_ITEM_TYPE_SECTION_FAILURE,
    payload: errors
});

export default {
    getItemTypesRequest,
    getItemTypesSuccess,
    getItemTypesFailure,
    createItemTypeRequest,
    createItemTypeSuccess,
    createItemTypeFailure,
    updateItemTypeRequest,
    updateItemTypeSuccess,
    updateItemTypeFailure,
    deleteItemTypeRequest,
    deleteItemTypeSuccess,
    deleteItemTypeFailure,
    addSectionToItemTypeRequest,
    addSectionToItemTypeSuccess,
    addSectionToItemTypeFailure,
    updateSectionOnItemTypeRequest,
    updateSectionOnItemTypeSuccess,
    updateSectionOnItemTypeFailure,
    removeSectionFromItemTypeRequest,
    removeSectionFromItemTypeSuccess,
    removeSectionFromItemTypeFailure,
    swapItemTypeSectionRequest,
    swapItemTypeSectionSuccess,
    swapItemTypeSectionFailure
};