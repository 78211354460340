import React, { Component } from 'react';
import JSONEditor from 'jsoneditor';

class JsonEditor extends Component {
    jsonEditorRef = React.createRef();

    componentDidMount() {
        const { options, initialValue } = this.props;
        this.jsonEditor = new JSONEditor(this.jsonEditorRef.current, {
            ...options,
            onChange: this.onChange
        });
        this.jsonEditor.set(initialValue);
    }

    componentWillUnmount() {
        if (this.jsonEditor) this.jsonEditor.destroy();
    }

    onChange = () => {
        if (!this.jsonEditor) return;

        try {
            this.props.onChange(this.jsonEditor.get());
        } catch (_) { }
    };

    render() {
        const { className } = this.props;
        return <div className={className || ''} ref={this.jsonEditorRef}></div>;
    }
};

export default JsonEditor;