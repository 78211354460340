import { roles } from './constants';

export default {
    'Assigned To Case': 'Only show cases where the user has been assigned as data entry, reviewer, or investigator.',
    'Awaiting Data Entry': 'Only show cases where the status is "Awaiting Data Entry".',
    'Data Entry In Process': 'Only show cases where the status is "Data Entry In Process".',
    [roles.General.SYSTEM_ADMINISTRATOR]: 'Gives the group every role. A group with this role will have access to everything in the system.',
    [roles.General.VIEW_JSON_EDITOR]: 'For developers only. Does not affect site behavior.',
    [roles.Cases.CREATE_CASE_TEMPLATE]: 'Gives the group permission to create case templates.',
    [roles.Cases.VIEW_CASE_TEMPLATE]: 'Gives the group permission to view case templates. This does not give them permission to edit or create them.',
    // Cases
    [roles.Cases.ASSIGN_DATA_ENTRY]: 'Gives the group permission to assign the data entry person for a case.',
    [roles.Cases.ASSIGN_REVIEWER]: 'Gives the group permission to assign the reviewer for a case.',
    [roles.Cases.CHANGE_REQUESTING_OFFICIAL]: 'Gives the group permission to change the requesting official after the case has been made.',
    [roles.Cases.VIEW_CASES]:
        'Gives the group permission to view the case list or a single case. This does not give them permission to edit or create them.',
    [roles.Cases.CREATE_CASE]: 'Gives the group permission to create cases.',
    [roles.Cases.EDIT_CASES]: 'Gives the group permission to edit cases. Does not give permission to create cases.',
    [roles.Cases.DELETE_CASES]: 'Gives the group permission to delete cases.',
    [roles.Cases.DOWNLOAD_REPORT]: 'Gives the group permission to download the ROI for a case.',
    [roles.Cases.DOWNLOAD_INCOMPLETE_REPORT]: 'Gives the group permission to download the ROI for cases that are not yet complete.',
    [roles.Cases.MARK_DATA_ENTRY_COMPLETE]: 'Gives the group permission to mark data entry complete for a case.',
    [roles.Cases.PUT_CASE_ON_HOLD]: 'Gives the group permission to put a case on hold.',
    [roles.Cases.DISCONTINUE_CASE]: 'Gives the group permission to discontinue a case.',
    [roles.Cases.VIEW_CASE_CRITICAL_DATE]:
        'Gives the group permission to see but not edit the critical date for a case. The field will be hidden otherwise.',
    [roles.Cases.EDIT_CASE_CRITICAL_DATE]:
        'Gives the group permission to edit the critical date of a case if they also have permission to view the critical date.',
    [roles.Cases.EDIT_CASE_COMPLETION_DATE]: 'Gives the group permission to edit the completion date of a case.',
    [roles.Cases.EDIT_INACTIVE_CASES]: 'Gives the group permission to edit a case that is on hold, discontinued, or completed.',
    [roles.Cases.CHANGE_CASE_TYPE]: 'Gives the group permission to change the type of a case after creation.',
    [roles.CaseMessages.VIEW_MESSAGES]: 'Gives the group permission to view case messages.',
    [roles.CaseMessages.CREATE_MESSAGE]: 'Gives the group permission to add a message to a case.',
    //Invoice
    [roles.Invoices.CREATE_INVOICES]: 'Gives the group permission to create an invoice for a case if they also have permission to view invoices.',
    [roles.Invoices.VIEW_INVOICE_DETAILS]: 'Gives the group permission to view invoices through QuickBooks but not create them.',
    //Case Attachments
    [roles.CaseAttachments.CREATE_ATTACHMENTS]:
        'Gives the group permission to upload an attachment for a case if they also have permission to view attachments.',
    [roles.CaseAttachments.EDIT_ATTACHMENTS]:
        'Gives the group permission to edit an attachment for a case if they also have permission to view attachments.',
    [roles.CaseAttachments.VIEW_ATTACHMENTS]:
        'Gives the group permission to view the list of attachments for a case. Does not give permission to edit or upload new attachments.',
    [roles.CaseAttachments.DELETE_ATTACHMENTS]:
        'Gives the group permission to remove an attachment from a case if they also have permission to view attachments.',
    // Items
    [roles.CaseItems.CREATE_CASE_ITEMS]: 'Gives the group permission to add items to a case if they also have the permission to view items.',
    [roles.CaseItems.EDIT_CASE_ITEMS]:
        'Gives the group permission to edit items if they also have permission to view items. Does not give permission to create items.',
    [roles.CaseItems.VIEW_CASE_ITEMS]: 'Gives the group permission to view items. Does not give permission to create or edit items.',
    [roles.CaseItems.DELETE_CASE_ITEMS]: 'Gives the group permission to delete items if they also have permission to view items.',
    [roles.CaseItems.MANAGE_CASE_ITEM_DEADLINES]: 'Gives the group permission to edit an ACD for an item.',
    [roles.CaseItems.UPDATE_CASE_ITEM_STATUS]: 'Gives the group permission to submit items for review.',
    [roles.CaseItems.VIEW_ITEM_HISTORY]: "Gives the group permission to view an item's history.",
    [roles.Items.SEARCH_ITEMS]: 'Gives the group permission to view the items page.',
    [roles.CaseItems.ADD_ITEM_ISSUE_CODES]: 'Gives the group permission to add issue codes to an item.',
    [roles.CaseItems.DELETE_ITEM_ISSUE_CODES]: 'Gives the group permission to remove issue codes from an item.',
    'Add Section to Item': 'Gives the group permission to add a section to an item.',
    'Remove Section from Item': 'Gives the group permission to remove a section from an item.',
    'Update Section on Item': 'Gives the group permission to edit a section on an item.',
    'View Item Issue Codes': 'Gives the group permission to view the issue codes for an item.',
    [roles.CaseItems.REORDER_CASE_ITEMS]: 'Gives the group permission to reorder items on a case.',
    [roles.CaseItems.REVIEW_CASE_ITEMS]: 'Gives the group permission to accept or rejected submitted items.',
    [roles.CaseItems.EDIT_ITEMS_IN_REVIEW]: 'Gives the group permission to edit items where the status is "In Review".',
    // Item Attachments
    [roles.ItemAttachments.ADD_ITEM_ATTACHMENTS]:
        'Gives the group permission to upload an attachment or change the order for an item if they also have permission to view attachments.',
    [roles.ItemAttachments.EDIT_ATTACHMENTS]:
        'Gives the group permission to edit an attachment for an item if they also have permission to view attachments.',
    [roles.ItemAttachments.VIEW_ITEM_ATTACHMENTS]: 'Gives the group permission to download item attachments.',
    [roles.ItemAttachments.DELETE_ITEM_ATTACHMENTS]:
        'Gives the group permission to remove an attachment from an item if they also have permission to view attachments.',
    // Expense
    [roles.ItemExpenses.ADD_EXPENSE]: 'Gives the group permission to add an expense for an item if they also have permission to view expenses.',
    [roles.ItemExpenses.EDIT_EXPENSES]: 'Gives the group permission to edit an expense for an item if they also have permission to view expenses.',
    [roles.ItemExpenses.VIEW_EXPENSES]: 'Gives the group permission to view expenses for an item if they also have permission to view expenses.',
    [roles.ItemExpenses.DELETE_EXPENSE]: 'Gives the group permission to delete an expense from an item.',
    [roles.ExpenseTypes.CREATE_EXPENSE_TYPE]: 'Gives the group permission to create expense types if they also have permission to view.',
    [roles.ExpenseTypes.DELETE_EXPENSE_TYPE]: 'Gives the group permission to delete expense types if they also have permission to view.',
    [roles.ExpenseTypes.EDIT_EXPENSE_TYPE]: 'Gives the group permission to edit expense types if they also have permission to view.',
    [roles.ExpenseTypes.VIEW_EXPENSE_TYPE]: 'Gives the group permission to view expense types.',
    // Item Types
    [roles.ItemTypes.CREATE_ITEM_TYPES]: 'Gives the group permission to create item templates if they also have permission to view item templates.',
    [roles.ItemTypes.EDIT_ITEM_TYPES]: 'Gives the group permission to edit item templates.',
    [roles.ItemTypes.VIEW_ITEM_TYPES]:
        'Gives the group permission to see any list of item templates. This is a required role if the groups needs to add items to a case.',
    [roles.ItemTypes.DELETE_ITEM_TYPES]: 'Gives the group permission to delete item templates if they also have permission to view item templates.',
    [roles.ItemTypes.REMOVE_SECTION_FROM_ITEM_TYPE]: 'Gives the group permission to remove a section from an item template.',
    [roles.ItemTypes.ADD_SECTION_TO_ITEM_TYPE]: 'Gives the group permission to add sections to an item template.',
    [roles.ItemTypes.REORDER_ITEM_TYPE_SECTIONS]: 'Gives the group permission to re-order sections on an item template.',
    // Sections
    [roles.Sections.CREATE_SECTION_TEMPLATES]: 'Giver the group permission to create section templates if they also have permission to view.',
    [roles.Sections.ADMIN_SECTION_TEMPLATES]: 'Gives the group permission to create section templates.',
    [roles.Sections.EDIT_SECTION_TEMPLATES]: 'Gives the group permission to edit section templates if they also have permission to view.',
    [roles.Sections.VIEW_SECTION_TEMPLATES]: 'Gives the group permission to view section templates.',
    [roles.Sections.DELETE_SECTION_TEMPLATES]: 'Gives the group permission to delete section templates if they also have permission to view.',
    // Org
    [roles.OrgDeadlines.CREATE_ORGANIZATION_DEADLINE]:
        'Gives the group permission to add deadlines to an org for case types and item templates if they also have permission to view.',
    [roles.OrgDeadlines.DELETE_ORGANIZATION_DEADLINE]: 'Gives the group permission to delete deadlines.',
    [roles.OrgDeadlines.EDIT_ORGANIZATION_DEADLINE]:
        'Gives the group permission to edit deadlines on an org for case types and item templates if they also have permission to view.',
    [roles.OrgDeadlines.VIEW_ORGANIZATION_DEADLINES]: 'Gives the group permission to view deadlines.',
    'Admin Organization Case Types': 'Gives the group permission to add/update case types to an org if they also have permission to view.',
    [roles.Orgs.ADD_ORG_DEADLINE]:
        'Gives the group permission to add/update deadlines to an org for case types and item templates if they also have permission to view.',
    [roles.Orgs.ADD_ORG_EXPENSE_RULE]: 'Gives the group permission to add/update expense rules for an org if they also have permission to view.',
    [roles.Orgs.CHANGE_PARENT_ORGANIZATION]: 'Gives the group permission to move an org in the org tree.',
    [roles.Orgs.CREATE_ORGANIZATION]: 'Gives the group permission to create an org if they also have permission to view orgs.',
    [roles.Orgs.DELETE_ORGANIZATION]: 'Gives the group permission to delete orgs if they also have permission to view.',
    [roles.Orgs.EDIT_ORGANIZATION]: 'Gives the group permission to edit an org if they also have permission to view.',
    [roles.Orgs.VIEW_ORGANIZATIONS]: 'Gives the group permission to view orgs.',
    'Admin Organization Users': 'Gives the group permission to edit users under an org.',
    // Stock phrase
    'Add Stock Phrase': 'Gives the group permission to add new stock phrases if they also have permission to view.',
    'Delete Stock Phrase': 'Gives the group permission to delete stock phrases if they also have permission to view.',
    'View Stock Phrases': 'Gives the group permission to view the admin list of stock phrases. They can still be used.',
    // Other
    [roles.ItemTemplateAsset.VIEW_ITEM_ASSETS]: 'Gives the group permission to download files attached to an item template.',
    [roles.ItemTemplateAsset.VIEW_ITEM_TEMPLATES]: 'Gives the group permission to view item templates.',
    [roles.ItemAssignments.ADD_ITEM_ASSIGNMENTS]: 'Gives the group permission to assign users to an item.',
    [roles.Roles.CREATE_ROLE]: 'Gives the group permission to create roles.',
    [roles.Groups.CREATE_GROUP]: 'Gives the group permission to create groups.',
    [roles.Groups.EDIT_GROUP]: 'Gives the group permission to edit groups.',
    [roles.Orgs.VIEW_ORGANIZATIONS]:
        'Gives the group permission to view any list of organizations. This does not give them permission to edit or create them. This should be a required role for all groups.',
    [roles.ReportData.VIEW_REPORT]: 'Gives the group permission to view and generate reports from the reports page.',
    [roles.Sections.ADMIN_SECTION_TEMPLATES]: 'Gives the group permission to view and edit section templates.',
    [roles.Types.CREATE_TYPE]: 'Gives the group permission to create new lists.',
    [roles.Types.EDIT_TYPE]: 'Gives the group permission to edit lists.',
    [roles.Types.VIEW_TYPE]: 'Gives the group permission to view lists. This does not give them permission to edit or create them.',
    [roles.Types.DELETE_TYPE]: 'Gives the group permission to delete lists.',
    [roles.CaseEngine.ENGINE_XSL]: 'For developers only. Does not affect site behavior.',
    'Admin Users': 'Gives the group permission to create or edit users.',
    'Admin Item Templates': 'Gives the group permission to upload files to item templates. These appear under "Downloadable Assets" for an item.',
    'Admin Security': 'Gives the group permission to edit SSA security settings.',
    [roles.CaseColumns.COMPLETION_DATE]: 'Gives the group permission to see the CM column for the case list.',
    [roles.CaseColumns.ORG]: 'Gives the group permission to see the Org column for the case list.',
    [roles.CaseColumns.RECEIVED_DATE]: 'Gives the group permission to see the RD column for the case list.',
    [roles.CaseColumns.REVIEWER]: 'Gives the group permission to see the Reviewer column for the case list.'
};
