import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import axios from 'axios';
import Select from 'react-select';

import { makeCancelable } from '../../utils';
import { baseUrl } from '../../config';
import { HandleUnmount, Label, ValidationError } from './form';
import { FormConsumer } from './form/Form';

class AssignInvestigatorSelect extends Component {
    state = {
        loading: false,
        errors: null,
        options: []
    };

    async componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        if (this.fetchPromise) this.fetchPromise.cancel();
    }

    fetchData = async () => {
        const { access_token, caseId, itemIds } = this.props;

        this.fetchPromise = makeCancelable(
            new Promise(r =>
                this.setState(
                    {
                        ...this.state,
                        errors: null,
                        loading: true
                    },
                    async () => {
                        try {
                            const response = await axios.post(
                                `${baseUrl}/api/cases/${caseId}/items/assign-list`,
                                {
                                    ItemIds: itemIds
                                },
                                {
                                    headers: { Authorization: `Bearer ${access_token}` }
                                }
                            );

                            const options = [
                                { label: 'Unassigned', value: null },
                                ...response.data.map(option => ({
                                    ...option,
                                    label: `${option.FirstName} ${option.LastName}`,
                                    value: option.Id
                                }))
                            ];

                            this.setState({
                                ...this.state,
                                errors: null,
                                options,
                                loading: false
                            });
                        } catch (e) {
                            console.log(e);
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    }
                )
            )
        );
    };

    render() {
        const { label, name, value, disabled, required, isDisabled, ...rest } = this.props;
        const { loading, options } = this.state;

        return (
            <>
                <FormConsumer>
                    {({ errors = {}, values, setValue, removeValue }) => (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            <Label>
                                {label} {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                            </Label>
                            <Select
                                options={options}
                                isLoading={loading}
                                components={{
                                    Option: ({ data, innerProps }) => {
                                        return (
                                            <div
                                                className="flex justify-between items-center py-4 px-6 cursor-pointer hover:bg-grey-lighter"
                                                {...innerProps}
                                            >
                                                <div className="flex items-center">
                                                    {data.WorkStatus ? (
                                                        <div
                                                            className={`bg-${data.WorkStatus.Short.toLowerCase()} inline-block h-2 w-2 rounded-full mr-3`}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <span>{data.label}</span>
                                                </div>
                                                {data.AverageDistance !== -1 ? (
                                                    <div className="flex items-center text-sm italic text-grey-darker">
                                                        ({data.AverageDistance && data.AverageDistance.toFixed(2)} mi)
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        );
                                    },
                                    SingleValue: ({ data, innerProps }) => {
                                        return (
                                            <div
                                                style={{ width: '99%' }}
                                                className="flex justify-between items-center px-4 cursor-pointer"
                                                {...innerProps}
                                            >
                                                <div className="flex items-center">
                                                    {data.WorkStatus ? (
                                                        <div
                                                            className={`bg-${data.WorkStatus.Short.toLowerCase()} inline-block h-2 w-2 rounded-full mr-3`}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <span>{data.label}</span>
                                                </div>
                                                {data.AverageDistance !== -1 ? (
                                                    <div className="flex items-center text-sm italic text-grey">
                                                        ({data.AverageDistance && data.AverageDistance.toFixed(2)} mi)
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        );
                                    }
                                }}
                                placeholder="Select an investigator..."
                                onChange={value => setValue(name, value['Id'])}
                                defaultValue={values[name]}
                            />
                            <ValidationError>{errors ? errors[name] : []}</ValidationError>
                        </HandleUnmount>
                    )}
                </FormConsumer>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(AssignInvestigatorSelect);
