import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getExpenseTypes = () => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.getExpenseTypesRequest());

    try {
        const expenseTypes = await axios.get(`${baseUrl}/api/expense-types`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        dispatch(Creators.getExpenseTypesSuccess(expenseTypes.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getExpenseTypesFailure(
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
    }
};

const createExpenseType = (expenseType) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.createExpenseTypeRequest());

    try {
        const result = await axios.post(`${baseUrl}/api/expense-types`, expenseType, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`The expense type '${expenseType.Short}' was created successfully.`);
        dispatch(Creators.createExpenseTypeSuccess(result.data));
        return Promise.resolve(result.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.createExpenseTypeFailure(
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
        return Promise.reject();
    }
};

const updateExpenseType = (expenseType) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.updateExpenseTypeRequest(expenseType.Id));

    try {
        const result = await axios.put(`${baseUrl}/api/expense-types/${expenseType.Id}`, {
            Short: expenseType.Short,
            Description: expenseType.Description,
            UOMType_Id: expenseType.UOMType_Id,
            MultipleUnitsAllowed: expenseType.MultipleUnitsAllowed,
            Reimbursable: expenseType.Reimbursable
        }, {
                headers: { 'Authorization': `Bearer ${access_token}` }
            });

        toast.success(`The expense type '${expenseType.Short} was updated successfully.`);
        dispatch(Creators.updateExpenseTypeSuccess(result.data));
        return Promise.resolve(result.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.updateExpenseTypeFailure(expenseType.Id,
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
        return Promise.reject();
    }
};

const deleteExpenseType = (expenseType) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.deleteExpenseTypeRequest(expenseType.Id));

    try {
        await axios.delete(`${baseUrl}/api/expense-types/${expenseType.Id}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`The expense type ${expenseType.Hidden ? 'activated' : 'deactivated'} successfully.`);
        dispatch(Creators.deleteExpenseTypeSuccess(expenseType.Id));
        return Promise.resolve();
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.deleteExpenseTypeFailure(expenseType.Id,
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
        return Promise.reject();
    }
};

export default {
    getExpenseTypes,
    createExpenseType,
    updateExpenseType,
    deleteExpenseType
};