import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { accountOperations } from '../account/duck';
import Modal from '../common/Modal';
import { baseUrl } from '../../config';

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

/*
    Source: https://stackoverflow.com/questions/38774332/session-timeout-warning-modal-using-react
*/
const WARNING_TIME = 1000 * 60 * 10; // 10 minutes
const MIN_WARNING_TIME = 1000 * 60 * 2; // 2 minutes

class AutoLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningTime: WARNING_TIME, // 10 minutes
            timeLeft: WARNING_TIME, // 10 minutes
            showModal: false,
        };
    }

    componentDidMount() {
        try {
            this.props.getSecuritySettings();
        } finally {
            if (!localStorage.AutoLogoutTime || localStorage.AutoLogoutTime < moment().unix()) {
                this.logout();
            }

            this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

            this.events.forEach((event) => {
                window.addEventListener(event, this.resetTimeout);
            });

            this.setTimeout();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.AutoLogoutTime !== this.props.AutoLogoutTime) {
            this.clearTimeoutFunc();
            this.setTimeout();
        }
    }

    componentWillUnmount() {
        this.clearTimeoutFunc();
    }

    clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);

        if (this.timer) clearInterval(this.timer);
    };

    setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, Math.max(this.props.AutoLogoutTime - WARNING_TIME, MIN_WARNING_TIME));
        this.logoutTimeout = setTimeout(this.logout, this.props.AutoLogoutTime);
        localStorage.AutoLogoutTime = moment()
            .add(this.props.AutoLogoutTime, 'ms')
            .unix();
    };

    countDown = () => {
        const timeLeft = Math.max(this.state.timeLeft - 1000, 0);
        this.setState({
            timeLeft,
        });
    };
    resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
        this.closeModal();
    };

    warn = () => {
        const timeLeft = this.props.AutoLogoutTime - WARNING_TIME >= WARNING_TIME ? WARNING_TIME : this.props.AutoLogoutTime - MIN_WARNING_TIME;

        this.setState({
            timeLeft,
            showModal: true,
        });

        if (this.timer) clearInterval(this.timer);

        this.timer = setInterval(this.countDown, 1000);
    };
    closeModal = () => {
        this.setState({
            showModal: false,
        });
    };

    logout = () => {
        this.destroy();
        this.props.logOut();
    };

    destroy = () => {
        this.clearTimeoutFunc();
    };

    render() {
        let { showModal, timeLeft } = this.state;
        timeLeft /= 1000;
        let divisor_for_minutes = timeLeft % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return (
            <div>
                <Modal show={showModal} className="max-w-md">
                    <p className="my-4">
                        Your session will end in{' '}
                        <strong>
                            {minutes}:
                            {seconds.toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                            })}
                        </strong>
                    </p>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    AutoLogoutTime: (state.security.settings.AutoLogoutTime || 30) * 1000 * 60,
});

const mapDispatch = (dispatch) => ({
    getSecuritySettings: dispatch.security.getSecuritySettings,
    logOut: () => dispatch(accountOperations.logOut()),
});

export default connect(
    mapState,
    mapDispatch
)(AutoLogout);
