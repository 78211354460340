import moment from 'moment';

const typeIsLoading = (types, type) => {
    const isLoading = (types[type] && types._updating[type]) || types._updating._gettingTypes;
    return isLoading
};

const shouldUpdateType = (types, type) => {
    const lastRetrieved = (types[type] && types[type]._lastRetrieved) || types._lastRetrieved;
    return moment().diff(moment.unix(lastRetrieved), 'minutes') > 5;
};

const getStaticTypes = (types) => types.filter(type => !type.IsDynamic);
const getDynamicTypes = (types) => types.filter(type => type.IsDynamic);

export default {
    typeIsLoading,
    getStaticTypes,
    getDynamicTypes,
    shouldUpdateType
};