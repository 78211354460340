import React, { Component } from 'react';
import ReactTable from 'react-table';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import ItemTypeSectionsList from './ItemTypeSectionsList';
import { Object } from 'es6-shim';

class ItemTypesList extends Component {
    state = {
        expanded: {}
    };

    expandRow = rowIndex => {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    };

    closeAllRows = () => {
        const { expanded } = this.state;

        Object.keys(expanded).forEach(key => (expanded[key] = false));

        this.setState({
            ...this.state,
            expanded
        });
    };

    render() {
        const { itemTypes, deleteItemType, updatingItemTypes } = this.props;
        const { expanded } = this.state;
        const expandedIndex = Object.keys(expanded || {}).find(k => !!expanded[k]);

        const columns = [
            {
                Header: 'Name',
                id: 'Name',
                accessor: d => `${d.Name} (${d.Description})`,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Status',
                accessor: 'Hidden',
                sortable: false,
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'NOTHIDDEN') return row[filter.id] === false;
                    if (filter.value === 'HIDDEN') return row[filter.id] === true;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">
                        <select
                            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 rounded"
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'ALL'}
                        >
                            <option value="ALL">Show All</option>
                            <option value="NOTHIDDEN">Enabled</option>
                            <option value="HIDDEN">Disabled</option>
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {!value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <Guard permissions={[roles.ItemTypes.EDIT_ITEM_TYPES]}>
                            <span className="cursor-pointer mr-4 cursor-pointer text-grey" onClick={e => {}}>
                                <i className="fas fa-pencil-alt" />
                            </span>
                        </Guard>
                        <Guard permissions={[roles.ItemTypes.DELETE_ITEM_TYPES]}>
                            <span
                                className={`text-${row._original.Hidden ? 'green' : 'red'}-light ${
                                    updatingItemTypes[row._original.Id] ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                }`}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (!updatingItemTypes[row._original.Id]) deleteItemType(row._original);
                                }}
                            >
                                <i
                                    className={`fas ${
                                        !updatingItemTypes[row._original.Id]
                                            ? row._original.Hidden
                                                ? 'fa-undo'
                                                : 'fa-trash-alt'
                                            : 'fa-spinner fa-pulse'
                                    }`}
                                />
                            </span>
                        </Guard>
                    </div>
                )
            }
        ];

        return (
            <>
                <ReactTable
                    filterable
                    data={itemTypes}
                    columns={columns}
                    expanded={expanded}
                    defaultSorted={[
                        {
                            id: 'Name',
                            desc: false
                        }
                    ]}
                    defaultFiltered={[{ id: 'Hidden', value: 'NOTHIDDEN' }]}
                    SubComponent={({ row }) => <ItemTypeSectionsList itemType={row._original} />}
                    getTrProps={(state, rowInfo, column) => ({
                        onClick: e => this.expandRow(rowInfo.viewIndex)
                    })}
                    getTheadProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTheadFilterProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTdProps={(_, rowInfo) => ({
                        className: `${typeof expandedIndex === 'string' && !!!expanded[rowInfo && rowInfo.viewIndex] ? `opacity-25` : ''}`
                    })}
                    onFetchData={this.closeAllRows}
                />
            </>
        );
    }
}

export default ItemTypesList;
