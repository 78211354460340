import axios from 'axios';
import 'whatwg-fetch';
import { baseUrl } from '../../../../../../config';


export const orgCaseType = {
    state: {},
    reducers: {},
    effects: dispatch => ({
        async createOrgCaseType(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.post(
                    `${baseUrl}/api/orgs/${payload.Org_Id}/case-types${payload.Override ? '?overrideCaseTypes=true' : ''}`,
                    {
                        CaseType_Id: payload.CaseType_Id,
                        CaseTemplate_Id: payload.CaseTemplate_Id,
                        BasePrice: payload.BasePrice,
                        ActiveFrom: payload.ActiveFrom,
                        ActiveTo: payload.ActiveTo
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                return response;
            } catch (error) {
                throw error;
            }
        }
    })
};
