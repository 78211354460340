import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const additionalinformations = {
    state: {
        additionalinformations: [],					   
        errors: {},
        _updating: {
            _sendingAdditionalInformation: false
        }
    },
    reducers: {
        setAdditionalInformations: (state, payload) => ({ ...state, ...{ additionalinformations: payload, errors: {} } }),
        setSendingAdditionalInformation: (state, payload) => ({ ...state, ...{ _updating: { _sendingAdditionalInformation: payload } } }),
																											  
        setErrors: (state, payload) => ({ ...state, ...{ errors: payload } })
    },
    effects: dispatch => ({
        async getAdditionalInformations(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/CaseCustomParameters`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.additionalinformations.setAdditionalInformations(response.data);
            } catch (error) {
                if (error && error.response) {
                    dispatch.cases.setErrors(error.response);
                }
            }
        },
       
    })
};
