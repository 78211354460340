import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { Form } from '../../common/form/Form';
import AssignInvestigatorSelect from '../../common/AssignInvestigatorSelect';
import { buildCaseToItemMap, optional } from '../../../utils';

class AssignInvestigatorModal extends Component {
    assignInvestigator = values => {
        const { items, ItemStateIDS } = this.props;
        const itemMap = buildCaseToItemMap(items);
        const caseIds = Object.keys(itemMap);

        this.props.dispatch.items.startUpdatingForKey('_assigningItems');
        Promise.all(
            caseIds.map(caseId =>
                this.props.assignItems(caseId, itemMap[caseId].CaseNumber, values['Investigator_Id'], itemMap[caseId].items.map(i => i.Id))
            )
        )
            .then(() => {
                this.props.dispatch.items.stopUpdatingForKey('_assigningItems');
                this.props.closeModal();
                caseIds.forEach(caseId => this.props.getItems(caseId, ItemStateIDS));
                caseIds.forEach(caseId => this.props.getRecipients(caseId));
            })
            .catch(() => {});
    };

    render() {
        const { closeModal, _case, items, assigningItems, errors } = this.props;
        const itemMap = buildCaseToItemMap(items);
        const caseIds = Object.keys(itemMap);

        return (
            <>
                <Form onSubmit={this.assignInvestigator} errors={errors}>
                    <h1 className="text-lg mb-4">Choose an investigator</h1>
                    <p>Please choose an investigator to assign the following items to:</p>
                    <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
                        {caseIds.map(caseId => (
                            <div key={`case-item-listing-${caseId}`}>
                                <b className="block mt-4">Case #{itemMap[caseId]['CaseNumber']}</b>
                                <ul>
                                    {itemMap[caseId]['items'].map(item => (
                                        <li key={`item-${item.Id}`}>
                                            {optional(item, t => t.ItemType.Description, '')}
                                            {' at '}
                                            {optional(
                                                item,
                                                t => t.Location.Label,
                                                optional(item, t => `${t.Location.City}/${t.Location.Region.Short}`, '(no location)')
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4">
                        <AssignInvestigatorSelect
                            caseId={caseIds[0]}
                            itemIds={items.map(i => i.Id)}
                            name="Investigator_Id"
                            label="Investigator"
                            required
                        />
                        {caseIds.length === 1 ? (
                            <></>
                        ) : (
                            <p className="text-red">
                                You have selected items from multiple cases. Only investigators that have access to all these cases will be shown.
                            </p>
                        )}
                    </div>
                    <div className="flex items-end mt-4">
                        <Button onClick={() => closeModal(true)} className="ml-auto mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!assigningItems ? (
                            <Button type="submit">Assign Items</Button>
                        ) : (
                            <Button disabled>
                                Assigning Items... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    assigningItems: state.items._updating._assigningItems,
    errors: (state.items._errors && state.items._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS }),
    assignItems: (caseId, caseNumber, investigatorId, itemIds) => dispatch.items.assignItems({ caseId, caseNumber, investigatorId, itemIds }),
    getRecipients: caseId => dispatch.messages.getRecipients(caseId)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignInvestigatorModal);
