import types from './types';

const getExpenseTypesRequest = () => ({
    type: types.GET_EXPENSE_TYPES_REQUEST
});
const getExpenseTypesSuccess = (expenseTypes) => ({
    type: types.GET_EXPENSE_TYPES_SUCCESS,
    payload: expenseTypes
});
const getExpenseTypesFailure = (errors) => ({
    type: types.GET_EXPENSE_TYPES_FAILURE,
    payload: errors
});

const createExpenseTypeRequest = () => ({
    type: types.CREATE_EXPENSE_TYPE_REQUEST
});
const createExpenseTypeSuccess = (expenseType) => ({
    type: types.CREATE_EXPENSE_TYPE_SUCCESS,
    payload: expenseType
});
const createExpenseTypeFailure = (errors) => ({
    type: types.CREATE_EXPENSE_TYPE_FAILURE,
    payload: errors
});

const updateExpenseTypeRequest = (expenseTypeId) => ({
    type: types.UPDATE_EXPENSE_TYPE_REQUEST,
    payload: expenseTypeId
});
const updateExpenseTypeSuccess = (expenseType) => ({
    type: types.UPDATE_EXPENSE_TYPE_SUCCESS,
    payload: expenseType
});
const updateExpenseTypeFailure = (expenseTypeId, errors) => ({
    type: types.UPDATE_EXPENSE_TYPE_FAILURE,
    payload: { expenseTypeId, errors }
});

const deleteExpenseTypeRequest = (expenseTypeId) => ({
    type: types.DELETE_EXPENSE_TYPE_REQUEST,
    payload: expenseTypeId
});
const deleteExpenseTypeSuccess = (expenseTypeId) => ({
    type: types.DELETE_EXPENSE_TYPE_SUCCESS,
    payload: expenseTypeId
});
const deleteExpenseTypeFailure = (expenseTypeId, errors) => ({
    type: types.DELETE_EXPENSE_TYPE_FAILURE,
    payload: { expenseTypeId, errors }
});

export default {
    getExpenseTypesRequest,
    getExpenseTypesSuccess,
    getExpenseTypesFailure,
    createExpenseTypeRequest,
    createExpenseTypeSuccess,
    createExpenseTypeFailure,
    updateExpenseTypeRequest,
    updateExpenseTypeSuccess,
    updateExpenseTypeFailure,
    deleteExpenseTypeRequest,
    deleteExpenseTypeSuccess,
    deleteExpenseTypeFailure
};