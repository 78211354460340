import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Reports from './Reports';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';
import { roles } from '../../constants';

const resportsRouter = () => (
    <Route key="reports-router" path="/reports">
        <Switch key="reports-router-switch">
            <Guard key="reports-route" path="/reports" notFound={NotFound} permissions={[roles.ReportData.VIEW_REPORT]}>
                <AuthRoute path="/reports" component={Reports} />
            </Guard>
        </Switch>
    </Route>
);

export default resportsRouter;
