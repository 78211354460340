import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Sections from './Sections';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const sectionsRouter = () => (
    <Route key="sections-router" path="/sections">
        <Switch key="sections-router-switch">
            <Guard key="sections-route" exact path="/sections" notFound={NotFound} permissions={[roles.Sections.VIEW_SECTION_TEMPLATES]}>
                <AuthRoute exact path="/sections" component={Sections} />
            </Guard>
        </Switch>
    </Route>
);

export default sectionsRouter;
