import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Admin from './Admin';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

export default () => (
    <Route key="admin-router" path="/admin">
        <Switch key="admin-router-switch">
            <Guard key='admin-route' exact path='/admin' notFound={NotFound} permissions={[roles.General.SYSTEM_ADMINISTRATOR]}>
                <AuthRoute exact path='/admin' component={Admin} />
            </Guard>
        </Switch>
    </Route>
);