import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { baseUrl } from '../../../../config';

import { makeCancelable } from '../../../../utils';
import OrgInvoicesForm from './OrgInvoices/OrgInvoicesForm';

class OrgInvoices extends Component {
    state = {
        updatingInvoiceSettings: false,
        loadingInvoiceSettings: false,
        orgInvoiceSettings: undefined,
        errors: {}
    };

    componentDidMount() {
        const { access_token, match: { params: { orgId } } } = this.props;

        this.orgInvoicesPromise = makeCancelable(
            new Promise(r => {
                this.setState({
                    ...this.state,
                    loadingInvoiceSettings: true
                }, async () => {
                    try {
                        const orgInvoiceSettings = await axios.get(`${baseUrl}/api/orgs/${orgId}/invoice-settings`, {
                            headers: { Authorization: `Bearer ${access_token}` }
                        });

                        this.setState({
                            ...this.state,
                            loadingInvoiceSettings: false,
                            orgInvoiceSettings: orgInvoiceSettings.data
                        });
                    } catch (e) {
                        toast.error('There was an error loading the invoice settings for this org.');
                        this.setState({
                            ...this.state,
                            orgInvoiceSettings: undefined,
                            loadingInvoiceSettings: false
                        });
                    }
                });
            })
        );
    }

    componentWillUnmount() {
        if (this.orgInvoicesPromise) this.orgInvoicesPromise.cancel();
        if (this.orgInvoiceUpdate) this.orgInvoiceUpdate.cancel();
    }

    updateOrgInvoiceSettings = (values) => {
        const { access_token, match: { params: { orgId } } } = this.props;

        this.orgInvoiceUpdate = makeCancelable(
            new Promise(r => {
                this.setState({
                    ...this.state,
                    updatingInvoiceSettings: true
                }, async () => {
                    try {
                        await axios.put(`${baseUrl}/api/orgs/${orgId}/invoice-settings`, {
                            QboId: +values['QboId'] === -1 ? null : +values['QboId'],
                            IncludeLast4SSN: !!values['IncludeLast4SSN']
                        }, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                        toast.success(`The invoice settings were updated successfully.`);
                        this.setState({
                            ...this.state,
                            errors: {},
                            updatingInvoiceSettings: false,
                            orgInvoiceSettings: values
                        });
                    } catch (error) {
                        toast.error('There was an error when trying to update the invoice settings.');
                        this.setState({
                            ...this.state,
                            errors: error.response.data,
                            updatingInvoiceSettings: false
                        });
                    }
                });
            })
        );
    };

    render() {
        const { updatingInvoiceSettings, loadingInvoiceSettings, orgInvoiceSettings, errors } = this.state;

        return (<>
            {!loadingInvoiceSettings ?
                orgInvoiceSettings !== undefined ?
                    <OrgInvoicesForm updating={updatingInvoiceSettings} errors={errors} onSubmit={this.updateOrgInvoiceSettings} defaultValues={orgInvoiceSettings} /> :
                    <span>There was an error loading the invoice settings for this org. Please try again.</span> :
                <span>
                    Loading... <i className="fas fa-spinner fa-pulse" />
                </span>
            }
        </>);
    }
};

const mapStateToProps = (state) => ({
    access_token: state.account.auth.access_token
});

export default withRouter(connect(mapStateToProps)(OrgInvoices));