import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import axios from 'axios';
import _ from 'lodash';
import { makeCancelable } from '../../utils';
import { baseUrl } from '../../config';
import { Label } from './form';

class FetchSelector extends Component {
    state = {
        loading: false,
        errors: null,
        options: []
    };

    async componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.fetchData();
        }
    }
    componentWillUnmount() {
        if (this.fetchPromise) this.fetchPromise.cancel();
    }

    fetchData = async () => {
        const { access_token, url, textKey } = this.props;

        this.fetchPromise = makeCancelable(
            new Promise(r =>
                this.setState(
                    {
                        ...this.state,
                        errors: null,
                        loading: true
                    },
                    async () => {
                        try {
                            const response = await axios.get(`${baseUrl}/api/${encodeURI(url)}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            const options = response.data.sort((left, right) => {
                                const accessor = typeof textKey === 'function' ? textKey : _.get;

                                if (accessor(left, textKey).toUpperCase() > accessor(right, textKey).toUpperCase()) return 1;
                                if (accessor(left, textKey).toUpperCase() < accessor(right, textKey).toUpperCase()) return -1;

                                return 0;
                            });

                            this.setState({
                                ...this.state,
                                errors: null,
                                options: options,
                                loading: false
                            });
                        } catch (e) {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    }
                )
            )
        );
    };

    render() {
        const {
            label,
            name,
            valueKey,
            textKey,
            onChange,
            onBlur,
            errors,
            value,
            disabled,
            required,
            isDisabled,
            customPlaceholder,
            ...rest
        } = this.props;
        const { loading, options } = this.state;

        return (
            <>
                <Label>
                    {label} {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                </Label>
                <div className="relative">
                    <select
                        className={`input-field ${errors[name] ? 'border-red' : ''}`}
                        name={name}
                        placeholder=""
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                        required={required}
                    >
                        {loading ? (
                            <option value={value} disabled>
                                Loading...
                            </option>
                        ) : options ? (
                            _.uniqBy(options, valueKey)
                                .filter(option => _.get(option, valueKey))
                                .reduce(
                                    (options, option) => [
                                        ...options,
                                        <option
                                            key={`${shortid.generate()}-${
                                                typeof valueKey === 'function' ? valueKey(option) : _.get(option, valueKey)
                                            }`}
                                            value={typeof valueKey === 'function' ? valueKey(option) : _.get(option, valueKey)}
                                        >
                                            {typeof textKey === 'function' ? textKey(option) : _.get(option, textKey)}
                                        </option>
                                    ],
                                    [
                                        <option key={`${shortid.generate()}`} value="">
                                            {customPlaceholder ? customPlaceholder : `Select ${label.toLowerCase()}`}
                                        </option>
                                    ]
                                )
                        ) : (
                            <option value={value} selected disabled>
                                No options...
                            </option>
                        )}
                    </select>
                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                {errors[name] ? <p className="text-red text-xs italic">{errors[name]}</p> : null}
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(FetchSelector);
