import actions from './app/account/duck/actions';
import batchMessages from './utils/batch-messages';

export const authInterceptor = ({ dispatch }) => next => action => {
    const status = action.payload && (action.payload.status || (action.payload.error && action.payload.error.status));
    if (action.payload && status === 401) {
        batchMessages('Your session has expired.', 'warn');
        return dispatch(actions.logOut());
    } else if (action.payload && status === 419) {
        batchMessages('You have been logged out because you signed in on another device.', 'warn');
        return dispatch(actions.logOut());
    } else if (action.payload && status === 403) {
        batchMessages(action.payload.data || 'Forbidden.', 'warn');
        return next(action);
    } else if (action.payload && status && action.payload.data) {
        action.payload = { ...action.payload.data, error_description: 'There was an error connecting to the server.' };
        return next(action);
    } else {
        return next(action);
    }
};
