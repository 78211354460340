import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Guard from '../../../common/Guard';
import { roles } from '../../../../constants';
import { baseUrl } from '../../../../config';

import Modal from '../../../common/Modal';
import { makeCancelable } from '../../../../utils';
import Button from '../../../common/Button';
import OrgDeadlineList from './OrgDeadlines/OrgDeadlineList';
import CreateOrgDeadline from './OrgDeadlines/CreateOrgDeadline';

class OrgDeadlines extends Component {
    state = {
        loadingDeadlines: false,
        deadlines: {
            CaseTypes: [],
            ItemTypes: []
        },
        showCreateModal: false,
        creatingACD: false
    };

    componentDidMount() {
        this.loadDeadlines();
    }

    componentWillUnmount() {
        if (this.orgDeadlinesPromise) this.orgDeadlinesPromise.cancel();
    }

    showCreateModal = isACD => {
        this.setState({
            ...this.state,
            showCreateModal: true,
            creatingACD: isACD
        });
    };

    closeCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false,
            creatingACD: false
        });
    };

    loadDeadlines = (refresh = false) => {
        const {
            access_token,
            match: {
                params: { orgId }
            },
            caseTypeId
        } = this.props;
        this.orgDeadlinesPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        loadingDeadlines: true
                    },
                    async () => {
                        try {
                            const deadlines = await axios.get(`${baseUrl}/api/orgs/${orgId}/deadlines?caseTypeId=${caseTypeId}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.setState({
                                ...this.state,
                                loadingDeadlines: false,
                                deadlines: deadlines.data
                            });
                        } catch (e) {
                            toast.error(`There was an error ${refresh ? 'refreshing' : 'loading'} the deadlines for this org.`);
                            this.setState({
                                ...this.state,
                                deadlines: refresh
                                    ? this.state.deadlines
                                    : {
                                        CaseTypes: [],
                                        ItemTypes: []
                                    },
                                loadingDeadlines: false
                            });
                        }
                    }
                );
            })
        );
    };

    deadlineOverrode = (deadline, oldId) => {
        this.setState({
            ...this.state,
            deadlines: this.state.deadlines.map(er => er.Id === oldId ? deadline : er)
        })
    };

    deadlineUpdated = (deadlines, isACD) => {
        this.setState({
            ...this.state,
            deadlines
        });
    };

    deadlineDeleted = (deadlines, isACD) => {
        this.setState({
            ...this.state,
            deadlines
        });
    };

    deadlineCreated = (deadlines, isACD) => {
        this.setState({
            ...this.state,
            deadlines
        });
        this.closeCreateModal();
    };

    render() {
        const { loadingDeadlines, deadlines, showCreateModal, creatingACD } = this.state;
        const {
            match: {
                params: { orgId }
            },
            caseTypeId,
            inherited
        } = this.props;

        const inheritedFilter = this.props.filtered.find((filter) => filter.id === 'Inherited');
        const deadlineFilter = (deadline) => {
            if (!inheritedFilter) return true;
            if (inheritedFilter.value === 'ALL') return true;

            return (inheritedFilter.value === 'INHERITED' && deadline.Inherited) ||
                (inheritedFilter.value === 'NON-INHERITED' && !deadline.Inherited);
        };

        return (
            <>
                <div className="flex justify-between items-center">
                    <span className="font-bold text-primary text-sm">CDs</span>
                    <Guard roles={[roles.Orgs.ADD_ORG_DEADLINE]}>
                        <Button inverse onClick={() => this.showCreateModal(false)}>
                            Add CD
                        </Button>
                    </Guard>
                </div>
                {!loadingDeadlines ? (
                    deadlines['CaseTypes'].length ? (
                        <OrgDeadlineList
                            deadlines={deadlines['CaseTypes'].filter(deadlineFilter)}
                            orgId={orgId}
                            deadlineUpdated={this.deadlineUpdated}
                            deadlineDeleted={this.deadlineDeleted}
                            deadlineOverrode={this.deadlineOverrode}
                            caseTypeId={caseTypeId}
                            inherited={inherited}
                        />
                    ) : (
                            <div className="text-primary">There are no CDs for this case type.</div>
                        )
                ) : (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                <div className="flex justify-between items-center mt-8">
                    <span className="font-bold text-primary text-sm">ACDs</span>
                    <Guard roles={[roles.Orgs.ADD_ORG_DEADLINE]}>
                        <Button inverse onClick={() => this.showCreateModal(true)}>
                            Add ACD
                        </Button>
                    </Guard>
                </div>
                {!loadingDeadlines ? (
                    deadlines['ItemTypes'].length ? (
                        <OrgDeadlineList
                            deadlines={deadlines['ItemTypes'].filter(deadlineFilter)}
                            orgId={orgId}
                            deadlineUpdated={this.deadlineUpdated}
                            deadlineDeleted={this.deadlineDeleted}
                            inherited={inherited}
                            caseTypeId={caseTypeId}
                            isACD
                        />
                    ) : (
                            <div className="text-primary">There are no ACDs for this case type.</div>
                        )
                ) : (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                <Modal show={showCreateModal} onClose={this.closeCreateModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? (
                            <CreateOrgDeadline
                                isACD={creatingACD}
                                orgId={orgId}
                                caseTypeId={caseTypeId}
                                closeModal={this.closeCreateModal}
                                deadlineCreated={this.deadlineCreated}
                            />
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default withRouter(connect(mapStateToProps)(OrgDeadlines));
