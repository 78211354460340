import { persistReducer } from 'redux-persist';
import accountReducer, { accountPersistConfig } from './app/account/duck/reducers';
import typesReducer, { typesPersistConfig } from './app/types/duck/reducers';
import identityReducer from './app/identity/duck/reducers';
import orgReducer, { orgPersistConfig } from './app/orgs/duck/reducers';
import sectionsReducer, { sectionsPersistConfig } from './app/sections/duck/reducers';
import itemTypesReducer, { itemTypesPersistConfig } from './app/item-types/duck/reducers';
import caseTemplatesReducer, { caseTemplatesPersistConfig } from './app/case-templates/duck/reducers';
import expenseTypesReducer, { expenseTypesPersistConfig } from './app/expense-types/duck/reducers';
import { reducer as formReducer } from 'redux-form';

const rootReducer = {
    account: persistReducer(accountPersistConfig, accountReducer),
    orgs: persistReducer(orgPersistConfig, orgReducer),
    types: persistReducer(typesPersistConfig, typesReducer),
    sections: persistReducer(sectionsPersistConfig, sectionsReducer),
    itemTypes: persistReducer(itemTypesPersistConfig, itemTypesReducer),
    caseTemplates: persistReducer(caseTemplatesPersistConfig, caseTemplatesReducer),
    expenseTypes: persistReducer(expenseTypesPersistConfig, expenseTypesReducer),
    identity: identityReducer,
    form: formReducer
};

export default rootReducer;
