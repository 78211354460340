import moment from 'moment';
import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const orgPersistConfig = {
    key: 'orgs',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    orgs: [],
    _errors: null,
    _updating: {
        _creatingOrg: false,
        _gettingOrgs: false,
        _orgs: {}
    }
};

const orgs = createReducer(initialState, {
    [types.GET_ORGS_REQUEST]: (state) => ({ ...state, _errors: null, _updating: { ...state._updating, _gettingOrgs: true } }),
    [types.GET_ORGS_SUCCESS]: (state, { payload: orgs }) => ({
        ...state,
        orgs,
        _errors: null,
        _lastRetrieved: moment().unix(),
        _updating: { ...state._updating, _gettingOrgs: false }
    }),
    [types.GET_ORGS_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _gettingOrgs: false }
    }),

    [types.UPDATE_ORG_PARENT_REQUEST]: (state, { payload: orgId }) => ({
        ...state,
        _errors: null,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: true
            }
        }
    }),
    [types.UPDATE_ORG_PARENT_SUCCESS]: (state, { payload: { orgId, parentOrgId } }) => ({
        ...state,
        orgs: state.orgs.map(o => o.Id !== orgId ? o : {
            ...o,
            ParentOrg_Id: parentOrgId
        }),
        _errors: null,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: false
            }
        }
    }),
    [types.UPDATE_ORG_PARENT_REQUEST]: (state, { payload: { orgId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: false
            }
        }
    }),

    [types.CREATE_ORG_REQUEST]: (state) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _creatingOrg: true }
    }),
    [types.CREATE_ORG_SUCCESS]: (state, { payload: org }) => ({
        ...state,
        orgs: [
            ...state.orgs,
            org
        ],
        _errors: null,
        _updating: { ...state._updating, _creatingOrg: false }
    }),
    [types.CREATE_ORG_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingOrg: false }
    }),

    [types.DELETE_ORG_REQUEST]: (state, { payload: orgId }) => ({
        ...state,
        _errors: null,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: true
            }
        }
    }),
    [types.DELETE_ORG_SUCCESS]: (state, { payload: orgId }) => ({
        ...state,
        orgs: state.orgs.map(o => o.Id !== orgId ? o : {
            ...o,
            Hidden: !o.Hidden
        }),
        _errors: null,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: false
            }
        }
    }),
    [types.DELETE_ORG_FAILURE]: (state, { payload: { orgId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating, _orgs: {
                ...state._updating._orgs,
                [orgId]: false
            }
        }
    }),
});

export default orgs;