import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';

class RemoveItemModal extends Component {
    deleteItem = event => {
        event.preventDefault();
        const { _case, item } = this.props;

        this.props
            .deleteItem(_case, item)
            .then(() => this.props.closeModal())
            .catch(() => {});
    };

    render() {
        const { deletingItem, closeModal, item } = this.props;

        return (
            <>
                <h2 className="pb-4">
                    <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                </h2>
                <p>{`Delete item ${(item.DisplayId || item.Id).toString().padLeft(3, '0')}?`} This action cannot be undone.</p>
                <div className="flex justify-end items-end mt-4">
                    <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                        Cancel
                    </Button>
                    {!deletingItem ? (
                        <Button className="text-red bg-white hover:bg-grey-dark border-red" onClick={this.deleteItem}>
                            Delete Item
                        </Button>
                    ) : (
                        <Button className="text-red bg-white hover:bg-grey-dark border-red" disabled>
                            Deleting Item... <i className="fas fa-spinner fa-pulse" />
                        </Button>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    deletingItem: state.items._updating._deletingItem
});

const mapDispatchToProps = dispatch => ({
    deleteItem: (_case, item) => dispatch.items.deleteItem({ _case, item })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveItemModal);
