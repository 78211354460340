import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getItemTypes = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getItemTypesRequest());

    try {
        const itemTypes = await axios.get(`${baseUrl}/api/item-types`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getItemTypesSuccess(itemTypes.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.getItemTypesFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const createItemType = itemType => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createItemTypeRequest());

    try {
        const createdItemType = await axios.post(`${baseUrl}/api/item-types`, itemType, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`${itemType.Name} was created successfully.`);
        dispatch(Creators.createItemTypeSuccess(createdItemType.data));
        return Promise.resolve(createdItemType.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.createItemTypeFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const updateItemType = itemType => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateItemTypeRequest(itemType.Id));

    try {
        const updatedItemType = await axios.patch(`${baseUrl}/api/item-types/${itemType.Id}`, itemType, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`${itemType.Name} was updated successfully.`);
        dispatch(Creators.updateItemTypeSuccess(updatedItemType.data));
        return Promise.resolve(updatedItemType.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        else if (error.response.data.ExceptionMessage) toast.error(error.response.data.ExceptionMessage);
        dispatch(
            Creators.updateItemTypeFailure(
                itemType.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const deleteItemType = itemType => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteItemTypeRequest(itemType.Id));

    try {
        await axios.delete(`${baseUrl}/api/item-types/${itemType.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`${itemType.Name} was ${itemType.Hidden ? 'enabled' : 'disabled'} successfully.`);
        dispatch(Creators.deleteItemTypeSuccess(itemType.Id));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.deleteItemTypeFailure(
                itemType.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const addSectionToItemType = (section, sectionRequired, itemType) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.addSectionToItemTypeRequest(itemType.Id));

    try {
        const newItemType = await axios.post(
            `${baseUrl}/api/item-types/${itemType.Id}/sections`,
            {
                SectionId: section.Id,
                Required: !!sectionRequired
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        toast.success(`${section.SectionName} was added to ${itemType.Name} successfully.`);
        dispatch(Creators.addSectionToItemTypeSuccess(newItemType.data));
        return Promise.resolve(newItemType.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.addSectionToItemTypeFailure(
                itemType.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const updateSectionOnItemType = (section, sectionRequired, itemType) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateSectionOnItemTypeRequest());

    try {
        const newItemType = await axios.post(
            `${baseUrl}/api/item-types/${itemType.Id}/sections/${section.Id}`,
            {
                Required: sectionRequired
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        toast.success(`${section.SectionName} was updated on ${itemType.Name} successfully.`);
        dispatch(Creators.updateSectionOnItemTypeSuccess(newItemType.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.updateSectionOnItemTypeFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const removeSectionFromItemType = (section, itemType) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.removeSectionFromItemTypeRequest(itemType.Id, section.Id));

    try {
        const newItemType = await axios.delete(`${baseUrl}/api/item-types/${itemType.Id}/sections/${section.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`${section.SectionName} was removed from ${itemType.Name} successfully.`);
        dispatch(Creators.removeSectionFromItemTypeSuccess(newItemType.data, section.Id));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.removeSectionFromItemTypeFailure(
                itemType.Id,
                section.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const swapItemTypeSections = ({ itemType, swapObject }) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.swapItemTypeSectionRequest());

    try {
        const result = await axios.put(`${baseUrl}/api/item-types/${itemType.Id}/sections/swap`, swapObject, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`The sections were reordered successfully.`);
        dispatch(Creators.swapItemTypeSectionSuccess(itemType.Id, result.data));
        return Promise.resolve(result.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.swapItemTypeSectionFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

export default {
    getItemTypes,
    createItemType,
    deleteItemType,
    addSectionToItemType,
    updateSectionOnItemType,
    removeSectionFromItemType,
    swapItemTypeSections,
    updateItemType
};
