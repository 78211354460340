import React from 'react';

export default ({ icon, onClick, children, className, type, inverse, disabled, loading }) => (
    <button
        className={`flex items-center bg-white text-primary-dark font-normal uppercase py-2 px-4 border border-grey rounded shadow-button hover:bg-grey-lighter ${className} ${
            disabled || loading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        type={type || 'button'}
        disabled={disabled || loading}
        onClick={e => {
            type !== 'submit' && e.preventDefault();
            onClick && onClick(e);
        }}
    >
        {icon ? <i className={`fas ${icon} fa-xs mr-2`} /> : <></>}

        <span className="text-xs">{loading ? <i className="fas fa-spinner fa-pulse" /> : children}</span>
    </button>
);
