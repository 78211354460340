import React from 'react';
import { roles } from '../../constants';
import { buildDesktopNavigation, MobileNavigationItem } from '../../utils/navigation';
import { orgsNavigation } from '../orgs/navigation';
import { identityNavigation } from '../identity/navigation';
import { securityNavigation } from '../security/navigation';

const adminNavigation = {
    linkText: 'Admin',
    icon: 'fa-lock',
    props: { to: '/admin' },
    permissions: [
        roles.CaseTemplates.GET_CASE_TEMPLATES,
        roles.ItemTypes.EDIT_ITEM_TYPES,
        roles.Sections.EDIT_SECTION_TEMPLATES,
        roles.ExpenseTypes.EDIT_EXPENSE_TYPE,
        roles.Types.EDIT_TYPE,
        roles.Users.EDIT_USERS,
        roles.Roles.EDIT_ROLE,
        roles.Groups.EDIT_GROUP,
        roles.Admin.ADMIN_SECURITY
    ],
    children: [
        { ...orgsNavigation },
        {
            linkText: 'Case Templates',
            props: { to: '/case-templates' },
            permissions: [roles.CaseTemplates.GET_CASE_TEMPLATES]
        },
        {
            linkText: 'Item Templates',
            props: { to: '/item-types' },
            permissions: [roles.ItemTypes.EDIT_ITEM_TYPES]
        },
        {
            linkText: 'Section Templates',
            props: { to: '/sections' },
            permissions: [roles.Sections.EDIT_SECTION_TEMPLATES]
        },
        {
            linkText: 'Expense Types',
            props: { to: '/expense-types' },
            permissions: [roles.ExpenseTypes.EDIT_EXPENSE_TYPE]
        },
        {
            linkText: 'Lists',
            props: { to: '/lists' },
            permissions: [roles.Types.EDIT_TYPE]
        },
        { ...identityNavigation },
        { ...securityNavigation }
    ]
};

export const adminDesktopNavigation = buildDesktopNavigation(adminNavigation);
export const adminMobileNavigation = <MobileNavigationItem key="admin-mobile-nav" navigation={adminNavigation} menuLevel={0} />;
