import React from 'react';
import Cleave from 'cleave.js/react';

import { Label } from '../../../common/form';
import { optional } from '../../../../utils';

const PhoneNumberSectionField = ({ field, onChange, disabled }) => (
    <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
        <div className="w-full px-4">
            <Label instructions={field['@instructions']} muted={!!!field['@showInReport']}>
                {optional(field, '@label', '')}
                {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
            </Label>
            <Cleave
                name="#text"
                options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                value={field['#text']}
                required={field['@required']}
                onChange={event => {
                    onChange(field, event.target.name, event.target.value);
                }}
                className="input-field"
                disabled={disabled}
            />
        </div>
    </div>
);

export default PhoneNumberSectionField;
