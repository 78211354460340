import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { makeCancelable, optional } from '../../../utils';
import { typesSelectors, typesOperations } from '../../types/duck';
import { identityOperations } from '../../identity/duck';
import { TextArea } from '../../common/form';
import OrgSelector from '../../common/OrgSelector';
import General from './General';
import Settings from './Settings';
import PhoneNumbersEditor from './FormDataTable/PhoneNumbersEditor';
import EmailsEditor from './FormDataTable/EmailsEditor';
import CredentialsEditor from './FormDataTable/CredentialsEditor';
import ClearancesEditor from './FormDataTable/ClearancesEditor';
import AddressesEditor from './FormDataTable/AddressesEditor';
import { Form } from '../../common/form/Form';
import Modal from '../../common/Modal';
import AddressForm from './AddressForm';
import {
    handleInputChange,
    orgSelected,
    updateContractTypes,
    showOrgSelector,
    closeOrgSelector,
    updateGroups,
    addRow,
    removeRow,
    openModal,
    closeModal,
    submitAddressForm,
    submitUpdateForm
} from './methods';
import Button from '../../common/Button';

const modalRoot = document.getElementById('modal-root');
const FORM_NAME = 'NewUser';

class UserCreator extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.handleInputChange = handleInputChange.bind(this);
        this.orgSelected = orgSelected.bind(this);
        this.updateContractTypes = updateContractTypes.bind(this);
        this.showOrgSelector = showOrgSelector.bind(this);
        this.closeOrgSelector = closeOrgSelector.bind(this);
        this.updateGroups = updateGroups.bind(this);
        this.addRow = addRow.bind(this);
        this.removeRow = removeRow.bind(this);
        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
        this.submitAddressForm = submitAddressForm.bind(this);
        this.submitUpdateForm = submitUpdateForm.bind(this);

        this.state = {
            formName: FORM_NAME,
            user: {
                UserName: '',
                Email: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Org_Id: this.props.Org_Id || null,
                Org: this.props.Org || '',
                WorkStatusType_Id: null,
                PINumber: '',
                CredNumber: '',
                Manager_Id: null,
                ContractTypeIds: [],
                Groups: this.props.defaultGroups || [],
                Addresses: []
            },
            errors: {},
            loading: false,
            updatingUser: false,
            showOrgSelector: false,
            values: {}
        };
    }

    async componentDidMount() {
        const { shouldUpdateContractTypes } = this.props;
        modalRoot.appendChild(this.el);
        document.addEventListener('keyup', this.handleEscapeKeyPress);

        this.userPromise = makeCancelable(
            new Promise(r => {
                if (shouldUpdateContractTypes) this.props.updateContractTypes();
                this.props.getGroups();
            })
        );
    }

    componentWillUnmount() {
        if (this.userPromise) this.userPromise.cancel();
        if (this.createUserPromise) this.createUserPromise.cancel();
        document.removeEventListener('keyup', this.handleEscapeKeyPress);
        modalRoot.removeChild(this.el);
    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }

    handleEscapeKeyPress = e => {
        if (e.code === 'Escape') {
            this.props.close && this.props.close();
        }
    };

    render() {
        return ReactDOM.createPortal(this.renderUserCreator(), this.el);
    }

    renderUserCreator = () => {
        const { loading, updatingUser, user, currentAddress, hasError } = this.state;
        const { contractTypes, groups, loadingGroups, loadingContractTypes, showSettings, defaultGroups, Org_Id } = this.props;

        if (hasError) return <p>An unexpected error occurred.</p>;

        const errors = this.state.errors && this.state.errors.ModelState;

        return (
            <>
                <div className="flex justify-center items-center pin select-none fixed z-50">
                    <div className="absolute pin bg-black opacity-75" />
                    <div className="z-20 bg-white rounded shadow-lg w-5/6 overflow-y-auto max-h-screen-90">
                        <Scrollbars autoHeight autoHeightMin="90vh">
                            <div className="px-6 py-4">
                                {loading || !user || loadingGroups || loadingContractTypes ? (
                                    <span>
                                        Loading... <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                ) : (
                                    <>
                                        <Form
                                            defaultValues={{ Org_Id }}
                                            onSubmit={values => {
                                                this.submitUpdateForm(values, '/api/identity/users/create', 'post');
                                            }}
                                            errors={errors}
                                        >
                                            <General
                                                errors={errors}
                                                user={user}
                                                defaultGroups={defaultGroups}
                                                groups={groups}
                                                handleInputChange={this.handleInputChange}
                                                showOrgSelector={this.showOrgSelector}
                                                updateGroups={this.updateGroups}
                                            />
                                            {showSettings ? (
                                                <Settings
                                                    errors={errors}
                                                    user={user}
                                                    groups={groups}
                                                    contractTypes={contractTypes}
                                                    handleInputChange={this.handleInputChange}
                                                    updateContractTypes={this.updateContractTypes}
                                                    updateGroups={this.updateGroups}
                                                />
                                            ) : (
                                                ''
                                            )}

                                            <PhoneNumbersEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form="NewUser" />
                                            <EmailsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form="NewUser" />
                                            <AddressesEditor
                                                user={user}
                                                onAdd={this.addRow}
                                                onDelete={this.removeRow}
                                                form="NewUser"
                                                openModal={this.openModal}
                                            />
                                            {showSettings ? (
                                                <CredentialsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form="NewUser" />
                                            ) : (
                                                <></>
                                            )}
                                            {showSettings ? (
                                                <ClearancesEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form="NewUser" />
                                            ) : (
                                                <></>
                                            )}

                                            <TextArea name="Notes" label="Notes" type="textarea" />
                                            <div className="flex items-end w-full">
                                                <Button className="mr-2 ml-auto mr-4 bg-grey hover:bg-grey-dark" onClick={this.props.close}>
                                                    Cancel
                                                </Button>
                                                {!updatingUser ? (
                                                    <Button type="submit">Save</Button>
                                                ) : (
                                                    <Button>
                                                        Saving... <i className="fas fa-spinner fa-pulse" />
                                                    </Button>
                                                )}
                                            </div>
                                        </Form>
                                    </>
                                )}
                                {this.state.showOrgSelector ? <OrgSelector close={this.closeOrgSelector} orgSelected={this.orgSelected} /> : <></>}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
                <Modal show={this.state.showModal} onClose={this.closeModal} zIndex={999}>
                    {this.state.showModal ? (
                        <AddressForm onSubmit={this.submitAddressForm} values={_.get(user, `Addresses[${currentAddress}]`, {})}>
                            <div className="flex justify-end items-end">
                                <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                    Cancel
                                </Button>
                                <Button type="submit">Ok</Button>
                            </div>
                        </AddressForm>
                    ) : (
                        <></>
                    )}
                </Modal>
            </>
        );
    };
}

const mapStateToProps = (state, props) => ({
    groups: state.identity.groups,
    contractTypes: state.types['ContractType'],
    access_token: state.account.auth.access_token,
    loadingGroups: state.identity._updating._gettingGroups,
    loadingContractTypes: typesSelectors.typeIsLoading(state.types, 'ContractType'),
    shouldUpdateContractTypes: typesSelectors.shouldUpdateType(state.types, 'ContractType'),
    regions: state.types.RegionType,
    Org_Id: props.Org_Id || optional(state, t => t.account.user.Org_Id, 0)
});

const mapDispatchToProps = dispatch => ({
    getGroups: () => dispatch(identityOperations.getGroups()),
    updateContractTypes: () => dispatch(typesOperations.updateType('ContractType')),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCreator);
