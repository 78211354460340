import React from 'react';
import { optional } from '../../../../utils';
import CustomCheckbox from '../../../common/CustomCheckbox';

const CheckboxSectionField = ({ field, onChange, disabled }) => (
    <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
        <div className="w-full px-4">
            <label className="flex items-center text-primary text-sm my-4">
                <CustomCheckbox
                    className="mr-4"
                    name="#text"
                    checked={optional(field, '#text', '').toString() === 'true'}
                    onChange={event => onChange(field, event.target.name, event.target.checked)}
                    disabled={disabled}
                />
                <span>
                    {optional(field, '@label', '')}
                    {optional(field, '@instructions', '') ? (
                        <>
                            <br />
                            <span className="text-sm text-grey-dark italic block">{optional(field, '@instructions', '')}</span>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </label>
        </div>
    </div>
);

export default CheckboxSectionField;
