import React, { Component } from 'react';
import { connect } from 'react-redux';

import Guard from '../common/Guard';
import { roles } from '../../constants';

import Modal from '../common/Modal';
import Button from '../common/Button';
import { caseTemplatesOperations } from './duck';
import CaseTemplateList from './components/CaseTemplateList';
import CreateCaseTemplate from './components/CreateCaseTemplate';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

const DEFAULT_CREATE_VALUES = {
    Label: '',
    CaseType_Id: 0,
    ContractType_Id: 0,
    ROICoversheetTemplate: '',
    ROIStylesheet: '',
    ROIHeader: '',
    ROIFooter: '',
    ShowCoverPage: true,
    ItemTypeIds: []
};

class CaseTemplates extends Component {
    state = {
        showCreateModal: false,
        createValues: { ...DEFAULT_CREATE_VALUES }
    };

    componentDidMount() {
        this.props.getCaseTemplates();
    }

    openCreateModal = (_, defaultValues = DEFAULT_CREATE_VALUES) => {
        this.setState({
            ...this.state,
            createValues: { ...defaultValues },
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            createValues: { ...DEFAULT_CREATE_VALUES },
            showCreateModal: false
        });
    };

    render() {
        const { createValues, showCreateModal } = this.state;
        const { gettingCaseTemplates } = this.props;

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Case Templates</PageHeader>
                        <Guard permissions={[roles.CaseTemplates.CREATE_CASE_TEMPLATE]}>
                            <Button inverse icon="far fa-clone" onClick={this.openCreateModal}>
                                Add Case Template
                            </Button>
                        </Guard>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingCaseTemplates ? (
                            <CaseTemplateList openCreateModal={this.openCreateModal} />
                        ) : (
                                <span>
                                    Loading... <i className="fas fa-spinner fa-pulse" />
                                </span>
                            )}
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? <CreateCaseTemplate createValues={createValues} closeModal={this.closeModal} /> : <></>}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    caseTemplates: state.caseTemplates.caseTemplates,
    gettingCaseTemplates: state.caseTemplates._updating._gettingCaseTemplates
});

const mapDispatchToProps = dispatch => ({
    getCaseTemplates: () => dispatch(caseTemplatesOperations.getCaseTemplates())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseTemplates);
