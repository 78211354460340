import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { identitySelectors, identityOperations } from './duck';
import GroupEditor from './components/GroupEditor';
import { expandRow } from './components/methods';
import Modal from '../common/Modal';
import Button from '../common/Button';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class Groups extends Component {
    state = {
        currentGroup: '',
        expanded: {},
        columns: [
            {
                Header: 'Name',
                accessor: 'Name'
            },
            {
                Header: 'Tags',
                id: 'Tag',
                accessor: d => d.Tags.map(tag => tag.Name).join(', ')
            },
            {
                Header: '',
                id: 'Actions',
                accessor: d => d.Id,
                Cell: row => (
                    <div className="flex justify-center items-center h-full">
                        <span className="cursor-pointer mr-4 text-grey">
                            <i className="fas fa-pencil-alt" />
                        </span>
                        <span
                            className="text-red-light cursor-pointer"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.confirmDelete(row.value);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </span>
                    </div>
                )
            }
        ]
    };

    constructor(props) {
        super(props);
        this.expandRow = expandRow.bind(this);
    }

    componentDidMount() {
        this.props.getRoles();
        this.props.getGroups();
        this.props.getCategories();
        this.props.getTags();
    }

    updateGroupSelect = event => {
        event.preventDefault();
        this.setState({
            ...this.state,
            currentGroup: event.target.value
        });
    };

    updateGroup = async (group, tags) => {
        // Clone the tags so they don't hold a reference
        // and change when the group is updated
        tags = tags.map(tag => ({ ...tag }));

        try {
            const newId = await this.props.updateGroup(group);
            this.props.addTagsToGroup({ Id: newId }, tags);
            this.setState({
                expanded: {},
                showNewGroupModal: false
            });
        } catch (e) {
            return;
        }
    };

    deleteGroup = async () => {
        try {
            const group = this.props.groups.find(group => group.Id == this.state.currentGroup);
            await this.props.deleteGroup(group);
            this.props.getGroups();
            this.setState({ currentGroup: '' });
        } catch (e) {
            return;
        }
    };

    confirmDelete = Id => {
        this.setState({ currentGroup: Id });
    };

    addTagsToGroup = tags => {
        this.props.addTagsToGroup({ Id: this.state.currentGroup }, tags);
    };

    render() {
        const {
            getRoleWithName,
            gettingCategories,
            gettingGroups,
            gettingRoles,
            addRoleToGroup,
            removeRoleFromGroup,
            categories,
            groups,
            tags
        } = this.props;
        const { expanded, columns, showNewGroupModal } = this.state;
        const currentGroup = groups.find(g => g.Id === this.state.currentGroup);
        const expandedIndex = Object.keys(expanded).find(k => !!expanded[k]);

        return (
            <div className="container">
                <div className="flex justify-between items-center">
                    <PageHeader>Groups</PageHeader>
                    <Button inverse icon="fas fa-plus" onClick={e => this.setState({ showNewGroupModal: true })}>
                        New Group
                    </Button>
                </div>
                <Divider />
                <div className="bg-white mb-4 p-4 mb-8 overflow-x-auto">
                    <ReactTable
                        columns={columns}
                        data={groups}
                        expanded={expanded}
                        showPagination={false}
                        loading={gettingCategories || gettingGroups || gettingRoles}
                        getTrProps={(state, rowInfo, column) => ({
                            onClick: e => {
                                this.expandRow(rowInfo.viewIndex);
                            }
                        })}
                        getTdProps={(state, rowInfo) => ({
                            className: `${typeof expandedIndex === 'string' && !!!expanded[rowInfo && rowInfo.viewIndex] ? `opacity-25` : ''}`
                        })}
                        getTheadProps={() => ({
                            style: { background: 'white' }
                        })}
                        getTheadFilterProps={() => ({
                            style: { background: 'white' }
                        })}
                        SubComponent={row => {
                            return (
                                <div className="px-4 py-4">
                                    <GroupEditor
                                        currentGroup={row.original}
                                        updateGroupSelect={this.updateGroupSelect}
                                        tags={tags}
                                        updatingGroup={this.props.updatingGroup}
                                        addTagsToGroup={this.addTagsToGroup}
                                        updateGroup={this.updateGroup}
                                        errors={this.props.errors}
                                        categories={categories}
                                        getRoleWithName={getRoleWithName}
                                        addRoleToGroup={addRoleToGroup}
                                        removeRoleFromGroup={removeRoleFromGroup}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <Modal show={!!currentGroup} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>{`Delete group ${currentGroup && currentGroup.Name}?`}</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={e => this.setState({ currentGroup: '' })}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.deleteGroup}>
                            {!this.props.updatingGroup ? (
                                <>Delete Group</>
                            ) : (
                                <>
                                    Deleting... <i className="fas fa-spinner fa-pulse" />
                                </>
                            )}
                        </Button>
                    </div>
                </Modal>
                <Modal show={showNewGroupModal} onClose={e => this.setState({ showNewGroupModal: false })}>
                    <div className="px-4 py-4">
                        <GroupEditor
                            updateGroupSelect={this.updateGroupSelect}
                            tags={tags}
                            updatingGroup={this.props.updatingGroup}
                            addTagsToGroup={this.addTagsToGroup}
                            updateGroup={this.updateGroup}
                            errors={this.props.errors}
                            categories={categories}
                            getRoleWithName={getRoleWithName}
                            addRoleToGroup={addRoleToGroup}
                            removeRoleFromGroup={removeRoleFromGroup}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    getRoleWithName: name => identitySelectors.getRoleWithName(state.identity, name),
    gettingCategories: state.identity._updating._gettingCategories,
    gettingGroups: state.identity._updating._gettingGroups,
    gettingRoles: state.identity._updating._gettingRoles,
    updatingGroup: state.identity._updating._updatingGroup,
    categories: state.identity.categories,
    errors: state.identity.errors,
    groups: state.identity.groups,
    tags: state.identity.tags
});

const mapDispatchToProps = dispatch => ({
    getRoles: () => dispatch(identityOperations.getRoles()),
    getGroups: () => dispatch(identityOperations.getGroups()),
    getCategories: () => dispatch(identityOperations.getCategories()),
    getTags: () => dispatch(identityOperations.getTags()),
    updateGroup: group => dispatch(identityOperations.updateGroup(group)),
    deleteGroup: group => dispatch(identityOperations.deleteGroup(group)),
    addRoleToGroup: (group, role) => dispatch(identityOperations.addRoleToGroup(group, role)),
    removeRoleFromGroup: (group, role) => dispatch(identityOperations.removeRoleFromGroup(group, role)),
    addTagsToGroup: (group, tags) => dispatch(identityOperations.addTagsToGroup(group, tags))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Groups);
