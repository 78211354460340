import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getCaseTemplates = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getCaseTemplatesRequest());

    try {
        const caseTemplates = await axios.get(`${baseUrl}/api/case-templates`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getCaseTemplatesSuccess(caseTemplates.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.getCaseTemplatesFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const createCaseTemplate = caseTemplate => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createCaseTemplateRequest());

    try {
        const newCaseTemplate = await axios.post(`${baseUrl}/api/case-templates`, caseTemplate, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`The case template ${caseTemplate.Label} was created successfully.`);
        dispatch(Creators.createCaseTemplateSuccess(newCaseTemplate.data));
        return Promise.resolve(newCaseTemplate.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.createCaseTemplateFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const editCaseTemplate = ({ Id, ...caseTemplate }) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.editCaseTemplateRequest(Id));

    try {
        const updatedCaseTemplate = await axios.patch(`${baseUrl}/api/case-templates/${Id}`, caseTemplate, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`The case template ${caseTemplate.Label} was edited successfully.`);
        dispatch(Creators.editCaseTemplateSuccess(updatedCaseTemplate.data));
        return Promise.resolve(updatedCaseTemplate.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.editCaseTemplateFailure(
                Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const deleteCaseTemplate = caseTemplate => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteCaseTemplateRequest(caseTemplate.Id));

    try {
        await axios.delete(`${baseUrl}/api/case-templates/${caseTemplate.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`The case template ${caseTemplate.Label} was deleted successfully.`);
        dispatch(Creators.deleteCaseTemplateSuccess(caseTemplate.Id));
        return Promise.resolve();
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.deleteCaseTemplateFailure(
                caseTemplate.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

const swapCaseTemplateItems = ({ caseTemplate, swapObject }) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.swapCaseTemplateItemsRequest());

    try {
        const result = await axios.put(`${baseUrl}/api/case-templates/${caseTemplate.Id}/items/swap`, swapObject, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        toast.success(`The items were reordered successfully.`);
        dispatch(Creators.swapCaseTemplateItemsSuccess(caseTemplate.Id, result.data));
        return Promise.resolve(result.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.swapCaseTemplateItemsFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
        return Promise.reject(error.response.data);
    }
};

export default {
    getCaseTemplates,
    createCaseTemplate,
    editCaseTemplate,
    deleteCaseTemplate,
    swapCaseTemplateItems
};
