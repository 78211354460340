import React from 'react';

const NotFound = () => (
    <div className="flex justify-center items-center h-full">
        <div className="text-xl">
            <h1 className="text-grey-darker font-light text-5xl mb-4">(404) Not Found</h1>
            <p className="text-grey-dark">The page you are looking for could not be found.</p>
        </div>
    </div>
);

export default NotFound;