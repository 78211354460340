import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { accountSelectors } from '../account/duck';

const Guard = props => {
    const hasPermission = props.permissions !== undefined ? props.hasPermission(props.permissions) : true;

    if (!hasPermission) return props.notFound !== undefined ? <props.notFound /> : '';
    return props.children;
};

const mapStateToProps = state => ({
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions),
    loggedIn: accountSelectors.loggedIn(state.account)
});

export default withRouter(connect(mapStateToProps)(Guard));
