import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { buildCaseToItemMap, optional } from '../../../utils';

class SubmitItemsModal extends Component {
    submitItems = () => {
        const { items, ItemStateIDS } = this.props;
        const itemMap = buildCaseToItemMap(items);
        const caseIds = Object.keys(itemMap);

        this.props.dispatch.items.startUpdatingForKey('_updatingItems');
        Promise.all(caseIds.map(caseId =>
            this.props.submitItems(
                caseId,
                itemMap[caseId].CaseNumber,
                itemMap[caseId].items.map(i => i.Id)
            )
        ))
            .then(() => {
                this.props.dispatch.items.stopUpdatingForKey('_updatingItems');
                this.props.closeModal();
                caseIds.forEach(caseId => this.props.getItems(caseId, ItemStateIDS));
            })
            .catch(() => { });
    };

    render() {
        const { closeModal, items, updatingItems } = this.props;
        const itemMap = buildCaseToItemMap(items);

        return (
            <>
                <h1 className="text-lg mb-4">Mass-Submit</h1>
                <p>Are you sure you wish to submit the following items for review?</p>
                {Object.keys(itemMap).map(key => (
                    <div key={`case-item-listing-${key}`}>
                        <b className="block mt-4">Case #{itemMap[key]['CaseNumber']}</b>
                        <ul>
                            {itemMap[key]['items'].map(item => (
                                <li key={`item-${item.Id}`}>
                                    {optional(item, t => t.ItemType.Description, '')}
                                    {' at '}
                                    {optional(
                                        item,
                                        t => t.Location.Label,
                                        optional(item, t => `${t.Location.City}/${t.Location.Region.Short}`, '(no location)')
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
                <div className="flex justify-end items-end mt-4">
                    <Button onClick={() => closeModal(true)} className="mr-4 bg-grey hover:bg-grey-dark" disabled={updatingItems}>
                        Cancel
                    </Button>
                    {!updatingItems ? (
                        <Button onClick={this.submitItems}>Submit Items</Button>
                    ) : (
                            <Button disabled>
                                Submitting Items... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    updatingItems: state.items._updating._updatingItems,
    errors: (state.items._errors && state.items._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS }),
    submitItems: (caseId, caseNumber, itemIds) => dispatch.items.submitItems({ caseId, caseNumber, itemIds })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitItemsModal);
