import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { Form } from '../../common/form/Form';
import { DatePicker } from '../../common/form';
import { buildCaseToItemMap, optional } from '../../../utils';

class SetACDModal extends Component {
    updateACD = values => {
        const { items, ItemStateIDS } = this.props;
        const itemMap = buildCaseToItemMap(items);
        const caseIds = Object.keys(itemMap);

        this.props.dispatch.items.startUpdatingForKey('_updatingItems');
        Promise.all(caseIds.map(caseId =>
            this.props.updateAcdsForItems(
                caseId,
                itemMap[caseId].CaseNumber,
                itemMap[caseId].items.map(i => i.Id),
                values['ACD']
            )
        ))
            .then(() => {
                this.props.dispatch.items.stopUpdatingForKey('_updatingItems');
                this.props.closeModal();
                caseIds.forEach(caseId => this.props.getItems(caseId, ItemStateIDS));
            })
            .catch(() => { });
    };

    render() {
        const { closeModal, items, updatingItems, errors } = this.props;
        const itemMap = buildCaseToItemMap(items);

        return (
            <>
                <Form onSubmit={this.updateACD} errors={errors}>
                    <h1 className="text-lg mb-4">Choose New ACD</h1>
                    <p>Please choose the new ACD for the following items to:</p>
                    {Object.keys(itemMap).map(key => (
                        <div key={`case-item-listing-${key}`}>
                            <b className="block mt-4">Case #{itemMap[key]['CaseNumber']}</b>
                            <ul>
                                {itemMap[key]['items'].map(item => (
                                    <li key={`item-${item.Id}`}>
                                        {optional(item, t => t.ItemType.Description, '')}
                                        {' at '}
                                        {optional(
                                            item,
                                            t => t.Location.Label,
                                            optional(item, t => `${t.Location.City}/${t.Location.Region.Short}`, '(no location)')
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <div className="mt-4 DayPickerModal">
                        <DatePicker disabledDays={[{ before: new Date() }]} name="ACD" label="ACD" type="date" required showLabel showError />
                    </div>
                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={() => closeModal(true)} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!updatingItems ? (
                            <Button type="submit">Update ACD</Button>
                        ) : (
                                <Button disabled>
                                    Updating ACD... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    updatingItems: state.items._updating._updatingItems,
    errors: (state.items._errors && state.items._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    getItems: (caseId, ItemStateIDS) => dispatch.items.getItems({ caseId, ItemStateIDS }),
    updateAcdsForItems: (caseId, caseNumber, itemIds, ACD) => dispatch.items.updateAcdsForItems({ caseId, caseNumber, itemIds, ACD })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetACDModal);
