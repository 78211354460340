import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getRoles = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getRolesRequest());

    try {
        const roles = await axios.get(`${baseUrl}/api/identity/roles`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getRolesSuccess(roles.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getRolesFailure(error.response));
    }
};

const createRole = role => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createRoleRequest());

    try {
        const newRole = await axios.post(`${baseUrl}/api/identity/roles/`, role, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.createRoleSuccess(newRole.data));
        toast.success(`The role ${role.Name} was created successfully.`);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.createRoleFailure(error.response));
    }
};

const updateRole = role => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateRoleRequest(role.Id));

    try {
        const newRole = await axios.patch(`${baseUrl}/api/identity/roles/${role.Id}`, role, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.updateRoleSuccess(newRole.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.updateRoleFailure(role.Id, error.response));
    }
};

const deleteRole = role => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteRoleRequest(role.Id));

    try {
        await axios.delete(`${baseUrl}/api/identity/roles/${role.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.deleteRoleSuccess(role.Id));
        toast.success(`The role ${role.Name} was deleted successfully.`);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.deleteRoleFailure(role.Id, error.response));
    }
};

const getGroups = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getGroupsRequest());

    try {
        const groups = await axios.get(`${baseUrl}/api/identity/groups`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getGroupsSuccess(groups.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getGroupsFailure(error.response));
    }
};

const updateGroup = group => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateGroupRequest());

    return new Promise(async (resolve, reject) => {
        try {
            const newGroup = await axios({
                method: group.Id ? 'PATCH' : 'POST',
                url: `${baseUrl}/api/identity/groups/${group.Id || ''}`,
                data: group,
                headers: { Authorization: `Bearer ${access_token}` }
            });

            dispatch(Creators.updateGroupSuccess(newGroup.data));
            resolve(newGroup.data.Id);
            toast.success(`The group ${group.Name} was ${group.Id === '' ? 'created' : 'updated'} successfully.`);
        } catch (error) {
            if (error.response === undefined) toast.error('There was an error connecting to the server.');
            dispatch(
                Creators.updateGroupFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                )
            );
            reject();
        }
    });
};

const deleteGroup = group => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteGroupRequest());

    return new Promise(async (resolve, reject) => {
        try {
            await axios.delete(`${baseUrl}/api/identity/groups/${group.Id}`, {
                headers: { Authorization: `Bearer ${access_token}` }
            });

            dispatch(Creators.deleteGroupSuccess());
            toast.success(`${group.Name} was deleted`);
            resolve();
        } catch (error) {
            toast.error(`Unable to delete ${group.Name}`);
            dispatch(
                Creators.deleteGroupFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                )
            );
            reject();
        }
    });
};

const getCategories = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getCategoriesRequest());

    try {
        const categories = await axios.get(`${baseUrl}/api/identity/categories`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getCategoriesSuccess(categories.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getCategoriesFailure(error.response));
    }
};

const addRoleToGroup = (group, role) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.addRoleToGroupRequest());

    try {
        group.Roles.push(role.Id);
        dispatch(Creators.addRoleToGroupSuccess(group));

        const newGroup = await axios.post(`${baseUrl}/api/identity/groups/${group.Id}/roles`, role, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.addRoleToGroupSuccess(newGroup.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.addRoleToGroupFailure(error.response));
    }
};

const removeRoleFromGroup = (group, role) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.removeRoleFromGroupRequest());

    try {
        group.Roles = group.Roles.filter(r => r !== role.Id);
        dispatch(Creators.removeRoleFromGroupSuccess(group));
        const newGroup = await axios.delete(`${baseUrl}/api/identity/groups/${group.Id}/roles/${role.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.removeRoleFromGroupSuccess(newGroup.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.removeRoleFromGroupFailure(error.response));
    }
};

const getUsers = userSearch => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getUsersRequest());

    return new Promise(async (resolve, reject) => {
        try {
            const users = await axios.post(`${baseUrl}/api/identity/users`, userSearch, {
                headers: { Authorization: `Bearer ${access_token}` }
            });

            dispatch(Creators.getUsersSuccess(users.data.users));
            resolve(users.data.pageCount);
        } catch (error) {
            if (error.response === undefined) toast.error('There was an error connecting to the server.');
            dispatch(
                Creators.getUsersFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                )
            );
            reject();
        }
    });
};

const getTags = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.getCategoriesRequest());

    try {
        const response = await axios.get(`${baseUrl}/api/identity/tags`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.getTagsSuccess(response.data));
    } catch (error) {
        toast.error('Unable to get tags.');
    }
};

const addTagsToGroup = (group, tags) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();

    try {
        const response = await axios.put(`${baseUrl}/api/identity/groups/${group.Id}/tags/`, tags, {
            headers: { Authorization: `Bearer ${access_token}` }
        });
        dispatch(Creators.updateGroupSuccess(response.data));
    } catch (error) {
        toast.error('Unable to save tags.');
    }
};

export default {
    getRoles,
    createRole,
    updateRole,
    deleteRole,
    getGroups,
    updateGroup,
    deleteGroup,
    getCategories,
    addRoleToGroup,
    removeRoleFromGroup,
    getUsers,
    getTags,
    addTagsToGroup
};
