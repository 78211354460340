import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Cases from './Cases';
import CaseEditor from './components/CaseEditor';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const casesRouter = () => (
    <Route key="cases-router" exact path="/cases">
        <Switch key="cases-router-switch">
            <Guard key="cases-route" exact path="/cases" notFound={NotFound} permissions={[roles.Cases.VIEW_CASES]}>
                <AuthRoute exact path="/cases" component={Cases} />
            </Guard>
        </Switch>
    </Route>
);

const casesEditRouter = () => (
    <Route key="cases-edit-router" exact path="/cases/:id">
        <Switch key="cases-edit-router-switch">
            <Guard key="cases-route-edit" exact path="/cases/:id" notFound={NotFound} permissions={[roles.Cases.VIEW_CASES]}>
                <AuthRoute exact path="/cases/:id" component={CaseEditor} />
            </Guard>
        </Switch>
    </Route>
);

export { casesRouter, casesEditRouter };
