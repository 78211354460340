import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Guard from '../../common/Guard';
import CustomCheckbox from '../../common/CustomCheckbox';
import StatusPill from '../../common/StatusPill';
import { optional } from '../../../utils';

export default function getItemColumns({ roles, all, allChecked, expandedIndex, hasPermission, swappingItems, _case, items, checkedItems }) {
    const disableEdit = _case && _case.WorkflowState && ['HOLD', 'DISCONTINUED', 'COMPLETE'].includes(_case.WorkflowState) && !hasPermission([roles.Cases.EDIT_INACTIVE_CASES]);

    return [
        ...(hasPermission([roles.ItemAssignments.ADD_ITEM_ASSIGNMENTS, roles.CaseItems.REVIEW_CASE_ITEMS, roles.CaseItems.MANAGE_CASE_ITEM_DEADLINES])
            ? [
                {
                    filterable: false,
                    sortable: false,
                    minWidth: 25,
                    Cell: ({ row }) => (
                        <div className="flex justify-center items-center">
                            <CustomCheckbox name={row._original.Id} checked={!!checkedItems[row._original.Id]} onChange={this.updateChecked} />
                        </div>
                    ),
                    Header: () => (
                        <div className="flex justify-center items-center">
                            <CustomCheckbox name="CHECK_ALL" checked={allChecked} onChange={this.updateChecked} />
                        </div>
                    )
                }
            ]
            : []),
        ...(all
            ? [
                {
                    id: 'CaseNumber',
                    filterable: false,
                    Header: 'Case #',
                    accessor: d => d.CaseNumber,
                    minWidth: 75,
                    maxWidth: all ? 100 : 75,
                    Cell: ({ value, row }) => (
                        <div className="flex justify-between items-center text-accent">
                            {hasPermission([roles.Cases.EDIT_CASES]) ? (
                                <Link className="text-accent" to={`/cases/${row._original.Case_Id}`}>
                                    <div className="group">
                                        <span className="hidden group-hover:block">{row._original.CaseNumber}</span>
                                        <span className="block group-hover:hidden">
                                            {row._original.CaseNumber
                                                ? (row._original.CaseNumber.length - 6 > 0 ? '...' : '') +
                                                row._original.CaseNumber.substring(Math.max(row._original.CaseNumber.length - 6, 0))
                                                : ''}
                                        </span>
                                    </div>
                                </Link>
                            ) : (
                                    <div className="group">
                                        <span className="hidden group-hover:block">{row._original.CaseNumber}</span>
                                        <span className="block group-hover:hidden">
                                            {row._original.CaseNumber
                                                ? (row._original.CaseNumber.length - 6 > 0 ? '...' : '') +
                                                row._original.CaseNumber.substring(Math.max(row._original.CaseNumber.length - 6, 0))
                                                : ''}
                                        </span>
                                    </div>
                                )}
                        </div>
                    )
                },
                {
                    filterable: false,
                    Header: 'Case Name',
                    accessor: 'Case.Subject.LastName',
                    minWidth: 75,
                    maxWidth: all ? 100 : 75,
                    Cell: ({ value, row }) => (
                        <div className="flex justify-between items-center text-accent">
                            {hasPermission([roles.Cases.EDIT_CASES]) ? (
                                <Link className="text-accent" to={`/cases/${row._original.Case_Id}`}>
                                    <div className="group">
                                        <span className="hidden group-hover:block">{optional(row._original, t => t.Case.Subject.LastName)}</span>
                                        <span className="block group-hover:hidden">
                                            {optional(row._original, t => t.Case.Subject.LastName)
                                                ? (optional(row._original, t => t.Case.Subject.LastName) - 6 > 0 ? '...' : '') +
                                                optional(row._original, t => t.Case.Subject.LastName).substring(
                                                    Math.max(optional(row._original, t => t.Case.Subject.LastName) - 6, 0)
                                                )
                                                : ''}
                                        </span>
                                    </div>
                                </Link>
                            ) : (
                                    <div className="group">
                                        <span className="hidden group-hover:block">{optional(row._original, t => t.Case.Subject.LastName)}</span>
                                        <span className="block group-hover:hidden">
                                            {optional(row._original, t => t.Case.Subject.LastName)
                                                ? (optional(row._original, t => t.Case.Subject.LastName) - 6 > 0 ? '...' : '') +
                                                optional(row._original, t => t.Case.Subject.LastName).substring(
                                                    Math.max(optional(row._original, t => t.Case.Subject.LastName) - 6, 0)
                                                )
                                                : ''}
                                        </span>
                                    </div>
                                )}
                        </div>
                    )
                },
                {
                    id: 'Case.Org.Code',
                    filterable: false,
                    Header: 'Org',
                    accessor: d => optional(d, t => t.Case.Org.Code),
                    minWidth: 75,
                    maxWidth: all ? 100 : 75
                },
                {
                    id: 'Case.CaseType.Short',
                    filterable: false,
                    Header: 'Case Type',
                    accessor: d => optional(d, t => t.Case.CaseType.Short),
                    minWidth: 75,
                    maxWidth: all ? 100 : 75
                }
            ]
            : []),
        {
            id: 'Id',
            filterable: false,
            Header: 'Item No',
            accessor: d => d.DisplayId || d.Id,
            minWidth: 75,
            maxWidth: all ? 100 : 75,
            Cell: ({ value, row }) => (
                <div className="flex justify-between items-center text-center text-accent">
                    {row._original.HasAttachments ? <i className={`fas fa-paperclip text-accent mr-2`} /> : <></>}
                    {hasPermission([roles.Items.EDIT_ITEMS]) ? (
                        <Link className="text-accent" to={`/cases/${row._original.Case_Id}/items/${row._original.Id}`}>
                            #{('' + value).padLeft(3, '0')}
                        </Link>
                    ) : (
                            <span className="flex-grow text-center">#{('' + value).padLeft(3, '0')}</span>
                        )}
                </div>
            )
        },
        {
            id: 'Location',
            Header: 'Location',
            filterable: true,
            minWidth: 150,
            maxWidth: 200,
            accessor: d =>
                optional(
                    d,
                    t => {
                        if (t.Location.Label && t.Location.City) {
                            return `${t.Location.Label} - ${t.Location.City}, ${t.Location.Region.Short}`;
                        }
                        return t.Location.Label || `${t.Location.City}, ${t.Location.Region.Short}`;
                    },
                    ''
                ),
            Cell: ({ value }) => value,
            className: 'text-center',
            Filter: () => null
        },
        {
            id: 'AssignedTo.LastName',
            accessor: 'AssignedTo.LastName',
            Header: 'Assignee',
            filterable: true,
            width: 150,
            accessor: d => optional(d, t => `${t.AssignedTo.LastName}, ${t.AssignedTo.FirstName}`),
            Cell: ({ value }) => <span className="text-xl">{value}</span>,
            Filter: () => null
        },
        {
            id: 'Case.Reviewer.Name',
            filterable: false,
            Header: 'Reviewer',
            show: all,
            accessor: d => optional(d, t => t.Case.Reviewer.Name),
            minWidth: 75,
            maxWidth: all ? 100 : 0
        },
        {
            filterable: false,
            id: 'ACD',
            Header: 'ACD',
            minWidth: 50,
            accessor: d => (d.ACD ? moment(d.ACD).format('MM/DD/YY') : ''),
            Cell: ({ value }) => <div className="text-center text-sm">{value}</div>
        },
        {
            id: 'ItemStatus',
            Header: 'Item Status',
            accessor: d => (d.ItemStatus ? d.ItemStatus.Description : ''),
            sortable: false,
            maxWidth: 125,
            filterMethod: (filter, row) => {
                if (filter.value === 'ALL') return true;
                if (filter.value === 'AWAITING_ASSIGNMENT') return row[filter.id] === 'Awaiting Assignment';
                if (filter.value === 'ASSIGNED') return row[filter.id] === 'Assigned';
                if (filter.value === 'IN_REVIEW') return row[filter.id] === 'In Review';
                if (filter.value === 'ACCEPTED') return row[filter.id] === 'Accepted';
                if (filter.value === 'REJECTED') return row[filter.id] === 'Rejected';
            },
            Filter: () => null,
            Cell: ({ value }) => <div className="text-center">{value && <StatusPill>{value}</StatusPill>}</div>
        },
        {
            id: 'ItemType',
            Header: 'Item Type',
            accessor: d => d.ItemType.Description,
            Cell: ({ value }) => value,
            className: 'text-center',
            Filter: () => null,
            minWidth: 50
        },
        {
            filterable: false,
            id: 'CompletedDate',
            Header: 'Completed Date',
            minWidth: 50,
            accessor: d => (d.CompletedDate ? moment(d.CompletedDate).format('MM/DD/YY') : ''),
            Cell: ({ value }) => <div className="text-center text-sm">{value}</div>
        },
        {
            sortable: false,
            filterable: false,
            minWidth: 65,
            maxWidth: all ? 80 : undefined,
            Cell: ({ row, index, viewIndex }) => disableEdit ? <></> : (
                <div className="flex justify-start items-center h-full">
                    <Guard permissions={[roles.Items.EDIT_ITEMS]}>
                        <Link className="text-primary cursor-pointer mr-4" to={`/cases/${row._original.Case_Id}/items/${row._original.Id}`}>
                            <i className="fas fa-pencil-alt text-grey" />
                        </Link>
                    </Guard>
                    <Guard permissions={[roles.Items.DELETE_ITEMS]}>
                        <span
                            className="text-red-light cursor-pointer mr-4"
                            onClick={e => {
                                e.stopPropagation();
                                this.openDeleteModal(row._original);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </span>
                    </Guard>
                    {!all && (
                        <Guard permissions={[roles.Items.REORDER_ITEMS]}>
                            {viewIndex !== 0 && items.length > 1 ? (
                                !swappingItems ? (
                                    <span
                                        className="text-grey cursor-pointer mr-4"
                                        onClick={e => {
                                            this.props.swapItems(_case, {
                                                SwapFromItem_Id: this.itemTable.state.resolvedData[viewIndex]._original.Id,
                                                SwapToItem_Id: this.itemTable.state.resolvedData[viewIndex - 1]._original.Id
                                            });
                                        }}
                                    >
                                        <i className="fas fa-chevron-up" />
                                    </span>
                                ) : (
                                        <span className="text-grey-light cursor-not-allowed mr-4">
                                            <i className="fas fa-spinner fa-pulse" />
                                        </span>
                                    )
                            ) : (
                                    <></>
                                )}
                            {viewIndex !== items.length - 1 && items.length > 1 ? (
                                !swappingItems ? (
                                    <span
                                        className="text-grey cursor-pointer mr-4"
                                        onClick={e => {
                                            this.props.swapItems(_case, {
                                                SwapFromItem_Id: this.itemTable.state.resolvedData[viewIndex]._original.Id,
                                                SwapToItem_Id: this.itemTable.state.resolvedData[viewIndex + 1]._original.Id
                                            });
                                        }}
                                    >
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                ) : (
                                        <span className="text-grey-light cursor-not-allowed mr-4">
                                            <i className="fas fa-spinner fa-pulse" />
                                        </span>
                                    )
                            ) : (
                                    <></>
                                )}
                        </Guard>
                    )}
                </div>
            )
        }
    ];
}
