import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import shortid from 'shortid';

import { baseUrl } from '../../config';
import { makeCancelable } from '../../utils';

import Modal from '../common/Modal';
import { FormConsumer } from './form/Form';
import { HandleUnmount, Label } from './form';

class QboCompanySelector extends Component {
    state = {
        updatingQbo: false,
        updatingCompanies: false,
        qboCompanies: []
    };

    componentDidMount() {
        this.getQboCustomers();
    }

    componentWillUnmount() {
        if (this.qboCustomersPromise) this.qboCustomersPromise.cancel();
        if (this.qboUpdatePromise) this.qboUpdatePromise.cancel();
    }

    getQboCustomers = () => {
        const { access_token } = this.props;

        this.qboCustomersPromise = makeCancelable(
            new Promise(r => {
                this.setState({
                    ...this.state,
                    updatingCompanies: true
                }, async () => {
                    try {
                        const result = await axios.get(`${baseUrl}/api/qbo/customers`, {
                            headers: { Authorization: `Bearer ${access_token}` }
                        });

                        this.setState({
                            ...this.state,
                            qboCompanies: result.data,
                            updatingCompanies: false,
                            updatingQbo: false
                        });
                    } catch (e) {
                        // Quickbooks isn't authorized...
                        if (e.response.status === 400) {
                            this.qboWindow = window.open(
                                e.response.data.ModelState.Qbo[0],
                                '_blank',
                                'toolbar=0,location=0,menubar=0'
                            );

                            this.messageListener = window.addEventListener('message', (event) => {
                                if (event.data.QboResult) {
                                    this.qboWindow.close();
                                    this.updateQboToken(event.data.QboResult);
                                    window.removeEventListener('message', this.messageListener);
                                }
                            }, false);
                        } else {
                            toast.error('There was an error connecting with QBO.');
                            this.setState({
                                ...this.state,
                                qboCompanies: [],
                                updatingCompanies: false,
                                updatingQbo: false
                            });
                        }
                    }
                });
            })
        );
    };

    updateQboToken = async (qboResult) => {
        const { access_token } = this.props;

        this.qboUpdatePromise = makeCancelable(
            new Promise(r => {
                this.setState({
                    ...this.state,
                    updatingQbo: true
                }, async () => {
                    try {
                        await axios.post(`${baseUrl}/api/qbo/token`, {
                            QboResult: qboResult
                        }, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                        this.setState({
                            ...this.state,
                            updatingQbo: false
                        }, () => {
                            this.getQboCustomers();
                        });
                    } catch (e) {
                        toast.error('We were unable to save the QBO token.');
                        this.setState({
                            ...this.state,
                            updatingQbo: false
                        });
                    }
                });
            })
        );
    };

    render() {
        const { value, name, label, hideLabel, className, required, readOnly, disabled, submitOnBlur } = this.props;
        const { updatingQbo, qboCompanies, updatingCompanies } = this.state;
        return (<>
            <FormConsumer>
                {({ values, setValue, removeValue, forceSubmit, errors }) => (
                    <HandleUnmount handleUnmount={() => removeValue(name)}>
                        <div className={className}>
                            {label && !hideLabel ?
                                <Label>
                                    {label}
                                    {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label> :
                                ''
                            }
                            <div className="relative">
                                <select
                                    className={`${!readOnly ? 'input-field' :
                                        hideLabel ? 'text-primary appearance-none' :
                                            'font-medium text-primary appearance-none'
                                        } ${this.state.error || (errors && errors[name]) ? 'border-red-light' : ''}`}
                                    name={name}
                                    placeholder=""
                                    value={values[name] === null ? -1 : values[name]}
                                    onChange={event => {
                                        if (readOnly) return;
                                        event.preventDefault();

                                        setValue(name, +event.target.value, () => {
                                            if (submitOnBlur) forceSubmit();
                                        });
                                    }}
                                    disabled={disabled || readOnly}
                                    required={required}
                                    readOnly={readOnly}
                                >
                                    {updatingCompanies ? (
                                        <option value={values[name] === null ? -1 : values[name]} disabled>
                                            Loading...
                                        </option>
                                    ) : (
                                            qboCompanies.reduce(
                                                (options, option = {}) => [
                                                    ...options,
                                                    <option key={`${shortid.generate()}-${option.idField}`} value={+option.idField}>
                                                        {option.displayNameField}
                                                    </option>
                                                ],
                                                [
                                                    <option key={`${shortid.generate()}`} value={-1}>
                                                        No company
                                                    </option>
                                                ]
                                            )
                                        )}
                                </select>
                                {!readOnly ?
                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div> :
                                    <></>
                                }
                            </div>
                            {errors[name] ? <p className="text-red text-xs italic">{errors[name]}</p> : null}
                        </div>
                    </HandleUnmount>
                )}
            </FormConsumer>
            <Modal show={updatingQbo} hideClose>
                <div className="flex justify-center items-center">
                    Syncing QBO authorization with the server. <i className="fas fa-spinner fa-pulse" />
                </div>
            </Modal>
        </>);
    }
};

const mapStateToProps = (state) => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(QboCompanySelector);