import React from 'react';
import { reports } from '../reports/models/reports';

const CustomCheckbox = props => {
    return (
        <label className={`${props.label ? `flex items-center` : ''} ${props.className}`}>
            <i
                className={`${props.label ? 'mr-2' : ''} fas fa-${
                    props.checked ? 'check-square bg-white text-accent' : `square ${props.backgroundClass || 'text-grey'}`
                }`}
                style={{ lineHeight: '0.5' }}
            />
            <input className="hidden" name={props.name} type="checkbox" checked={props.checked} onChange={props.onChange} disabled={props.disabled} />
            <span className="text-primary text-sm">{props.label}</span>
        </label>
    );
};

export default CustomCheckbox;
