import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import _ from 'lodash';

import { accountSelectors } from '../../account/duck';
import Button from '../../common/Button';
import { Form, FormConsumer } from '../../common/form/Form';
import { EnumTypeSelector, DatePicker, Input, Checkbox } from '../../common/form';
import Guard from '../../common/Guard';
import { roles, GoalStatusType } from '../../../constants';

const DEFAULT_CREATE_VALUES = {
    ItemType_Id: 0,
    ACD: null,
    Label: '',
    Address1: null,
    Address2: null,
    City: null,
    RegionType_Id: 0,
    PostalCode: null,
    ShowInReport: true
};

class EditItemForm extends Component {
    state = {
        formKey: shortid.generate()
    };

    editItem = values => {
        const { item, onSubmit } = this.props;

        this.props
            .updateItem(item.Case.Id, item.Id, {
                ItemType_Id: +values['ItemType_Id'],
                ACD: values['ACD'],
                Label: values['Label'],
                Address1: values['Address1'],
                Address2: values['Address2'],
                City: values['City'],
                RegionType_Id: +values['RegionType_Id'],
                PostalCode: values['PostalCode'],
                ShowInReport: !!values['ShowInReport']
            })
            .then(() => {
                this.setState({ ...this.state, formKey: shortid.generate() });
                if (onSubmit) onSubmit(values);
            })
            .catch(() => { });
    };

    render() {
        const { updatingItem, errors, item } = this.props;
        const { formKey } = this.state;
        const itemDefaults = {
            ItemType_Id: item.ItemType ? item.ItemType.Id : 0,
            ACD: item.ACD,
            Label: item.Location ? item.Location.Label : '',
            Address1: item.Location ? item.Location.Address1 : '',
            Address2: item.Location ? item.Location.Address2 : '',
            City: item.Location ? item.Location.City : '',
            RegionType_Id: item.Location && item.Location.Region ? item.Location.Region.Id : 0,
            PostalCode: item.Location ? item.Location.PostalCode : '',
            ShowInReport: item.ShowInReport
        };

        const inReview =
            ((
                ['HOLD', 'DISCONTINUED', 'COMPLETE'].includes(item.Case.WorkflowState) ||
                item.ItemStatus.Short == GoalStatusType.ACCEPTED
            ) && !this.props.hasPermission([roles.Cases.EDIT_INACTIVE_CASES])) ||
            (item && item.ItemStatus.Short == GoalStatusType.IN_REVIEW && !this.props.hasPermission([roles.CaseItems.EDIT_ITEMS_IN_REVIEW]));

        return (
            <div className="mt-2 mb-4">
                <Form
                    key={formKey}
                    defaultValues={{
                        ...DEFAULT_CREATE_VALUES,
                        ...itemDefaults
                    }}
                    errors={errors}
                    onSubmit={this.editItem}
                >
                    <div className="flex -mx-2">
                        <div className="w-1/2 px-2">
                            <EnumTypeSelector
                                customKey={`${item.Id}-ItemType_Id`}
                                type="GoalType"
                                name="ItemType_Id"
                                label="Item Template"
                                readOnly
                            />
                        </div>
                        <div className="w-1/2 px-2 DayPickerModal">
                            <DatePicker
                                disabledDays={[{ before: new Date() }]}
                                name="ACD"
                                label="ACD"
                                type="date"
                                showLabel
                                showError
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-4">
                        <div className="w-full px-2">
                            <Input
                                type="text"
                                name="Label"
                                label="Description"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-8">
                        <div className="w-1/2 px-2">
                            <Input
                                type="text"
                                name="Address1"
                                label="Street"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <Input
                                type="text"
                                name="Address2"
                                label="&nbsp;"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-4">
                        <div className="w-1/3 px-2">
                            <Input
                                type="text"
                                name="City"
                                label="City"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                        <div className="w-1/3 px-2">
                            <EnumTypeSelector
                                customKey={`${item.Id}-RegionType_Id`}
                                type="RegionType"
                                name="RegionType_Id"
                                label="State"
                                allowBlank
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                        <div className="w-1/3 px-2">
                            <Input
                                type="text"
                                name="PostalCode"
                                label="Postal Code"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between items-end mt-4">
                        <div className="flex h-full flex-grow items-center mb-2">
                            <Checkbox
                                type="checkbox"
                                name="ShowInReport"
                                label="Show this item in the ROI?"
                                readOnly={!this.props.hasPermission([roles.CaseItems.EDIT_CASE_ITEMS]) || inReview}
                            />
                        </div>
                        <div>
                            <Guard permissions={[roles.CaseItems.EDIT_CASE_ITEMS]}>
                                <FormConsumer>
                                    {({ values }) =>
                                        !updatingItem ? (
                                            <Button disabled={_.isEqual(values, itemDefaults) || inReview} type="submit">
                                                Save Changes
                                            </Button>
                                        ) : (
                                                <Button disabled>
                                                    Saving Changes... <i className="fas fa-spinner fa-pulse" />
                                                </Button>
                                            )
                                    }
                                </FormConsumer>
                            </Guard>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    updatingItem: state.item._updating._updatingItem,
    errors: (state.item._errors && state.item._errors.ModelState) || {},
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

const mapDispatchToProps = dispatch => ({
    updateItem: (caseId, itemId, item) => dispatch.item.updateItem({ caseId, itemId, item })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditItemForm);
