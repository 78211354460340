import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';

import { Label } from '../../../common/form';
import { YearMonthForm, fromMonth, toMonth } from '../../../common/YearMonthForm';
import { optional } from '../../../../utils';
import CustomCheckbox from '../../../common/CustomCheckbox';

class DateSectionField extends Component {
    state = { month: new Date() };

    handleYearMonthChange = month => {
        this.setState({ month });
    };

    render() {
        const { month } = this.state;
        const { field, onChange, disabled } = this.props;
        const after = optional(field, '@disableDatesAfterToday', '')
            ? new Date()
            : optional(field, '@disableDatesAfter', '')
                ? new Date(optional(field, '@disableDatesAfter', ''))
                : undefined;
        const before = optional(field, '@disableDatesBeforeToday', '')
            ? new Date()
            : optional(field, '@disableDatesBefore', '')
                ? new Date(optional(field, '@disableDatesBefore', ''))
                : undefined;

        return (
            <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
                <div className="w-full px-4 DayPickerModal">
                    <Label instructions={optional(field, '@instructions', '')} muted={!!!field['@showInReport']}>
                        {optional(field, '@label', '')}
                        {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <DayPickerInput
                        inputProps={{ disabled: field['#text'] === 'current' || disabled }}
                        dayPickerProps={{
                            disabledDays: [{ after: after, before: before }],
                            captionElement: ({ date, localeUtils }) => (
                                <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleYearMonthChange} />
                            ),
                            fromMonth: fromMonth,
                            toMonth: toMonth,
                            month: month
                        }}
                        overlayComponent={({ children, classNames, selectedDay, ...props }) => (
                            <div
                                {...props}
                                className=""
                                onClick={e => {
                                    if (e.target.className === 'DayPickerInput-OverlayWrapper') {
                                        props.input.blur();
                                    }
                                }}
                            >
                                {children}
                            </div>
                        )}
                        clickUnselectsDay={true}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={`${formatDate(new Date(), 'MM/DD/YYYY')}`}
                        format="MM/DD/YYYY"
                        value={
                            optional(field, '#text', '') && optional(field, '#text', '') !== 'current'
                                ? formatDate(optional(field, '#text', ''), 'MM/DD/YYYY')
                                : ''
                        }
                        onDayChange={day => {
                            onChange(field, '#text', day ? moment(day).toISOString() : '');
                        }}
                        disabled={disabled}
                    />
                </div>
                {field['@allowCurrent'] ? (
                    <div className="w-full px-4 DayPickerModal">
                        <label className="flex items-center text-primary text-sm mt-2 mb-2 pl-2">
                            <CustomCheckbox
                                className="mr-4"
                                name="#text"
                                checked={optional(field, '#text', '') === 'current'}
                                onChange={event => onChange(field, event.target.name, event.target.checked ? 'current' : '')}
                                disabled={disabled}
                            />
                            Current
                        </label>
                    </div>
                ) : (
                        <></>
                    )}
            </div>
        );
    }
}

export default DateSectionField;
