import React, { Component } from 'react';
import { getScrollBarWidth } from '../../utils';

class SideNavMenuItem extends Component {
    state = {
        open: true
    };

    render() {
        const { open } = this.state;
        const { title, icon, children } = this.props;
        const scrollBarWidth = getScrollBarWidth();
        return (
            <div className="bg-secondary">
                <div className="p-3 bg-primary flex justify-between items-center">
                    <h3 className="text-primary tracking-wide font-medium text-white">
                        <i className={`fa mr-4 ${icon}`} />
                        {title}
                    </h3>

                    <i
                        className={`cursor-pointer fa text-white text-xl ${open ? 'fa-chevron-down' : 'fa-chevron-right'}`}
                        style={{ marginRight: scrollBarWidth }}
                        onClick={() => {
                            this.setState({
                                open: !open
                            });
                        }}
                    />
                </div>

                {open && <div className="pl-4">{children}</div>}
            </div>
        );
    }
}

export default SideNavMenuItem;
