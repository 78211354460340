import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import numeral from 'numeral';

import Guard from '../../../../../common/Guard';
import { roles } from '../../../../../../constants';
import { baseUrl } from '../../../../../../config';
import { makeCancelable } from '../../../../../../utils';
import { Form, FormConsumer } from '../../../../../common/form/Form';
import { Input, CurrencyPercentage, Checkbox } from '../../../../../common/form';
import Button from '../../../../../common/Button';

const DEFAULT_CREATE_VALUES = {
    DaysAfter: 0,
    PriceAdjustment: 0,
    IsPercentage: false,
    ForDiscontinued: false
};

class CaseFeeForm extends Component {
    state = {
        errors: {},
        isUpdating: false,
        isDeleting: false
    };

    componentWillUnmount() {
        if (this.caseFeePromise) this.caseFeePromise.cancel();
        if (this.caseFeeDeletePromise) this.caseFeeDeletePromise.cancel();
    }

    handleSubmit = values => {
        if (this.state.isDeleting) return;

        const { access_token, forDiscontinued, caseFee, caseType, orgId } = this.props;
        const isCreate = caseFee === undefined;
        const submitData = {
            DaysAfter: +values['DaysAfter'],
            PriceAdjustment: numeral(values['PriceAdjustment']).value(),
            IsPercentage: values['IsPercentage'],
            ForDiscontinued: forDiscontinued
        };

        this.caseFeePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isUpdating: true
                    },
                    async () => {
                        try {
                            if (caseType.Inherited) {
                                const response = await this.props.createOrgCaseType({
                                    Org_Id: orgId,
                                    CaseType_Id: caseType.CaseType.Id,
                                    CaseTemplate_Id: caseType.CaseTemplate.Id,
                                    BasePrice: caseType.BaseCost
                                });

                                caseType.Id = response.data.Id;
                                this.props.onOverride(response.data, caseType.Id);
                            }

                            const result = isCreate
                                ? await axios.post(`${baseUrl}/api/orgs/${orgId}/case-types/${caseType.Id}/fees`, submitData, {
                                      headers: { Authorization: `Bearer ${access_token}` }
                                  })
                                : await axios.put(`${baseUrl}/api/orgs/${orgId}/case-types/${caseType.Id}/fees/${caseFee.Id}`, submitData, {
                                      headers: { Authorization: `Bearer ${access_token}` }
                                  });

                            this.setState({
                                ...this.state,
                                isUpdating: false
                            });
                            toast.success(`The case fee was ${isCreate ? 'created' : 'updated'} successfully.`);
                            if (isCreate) {
                                this.props.onFeeCreate(result.data, caseType.Id, forDiscontinued);
                                this.props.closeModal();
                            } else this.props.onFeeEdit(result.data, caseType.Id, forDiscontinued);
                        } catch (e) {
                            toast.error(`There was an error ${isCreate ? 'creating' : 'updating'} the case fee.`);
                            this.setState({
                                ...this.state,
                                errors: e.response.data.ModelState || {},
                                isUpdating: false
                            });
                        }
                    }
                );
            })
        );
    };

    deleteCaseFee = event => {
        event.preventDefault();

        if (this.state.isUpdating) return;
        const { access_token, forDiscontinued, caseFee, caseType, orgId } = this.props;

        this.caseFeeDeletePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: true
                    },
                    async () => {
                        try {
                            await axios.delete(`${baseUrl}/api/orgs/${orgId}/case-types/${caseType.Id}/fees/${caseFee.Id}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.setState({
                                ...this.state,
                                isDeleting: false
                            });
                            toast.success(`The case fee was deleted successfully.`);
                            this.props.onFeeDelete(caseFee, caseType.Id, forDiscontinued);
                        } catch (e) {
                            toast.error(`There was an error deleting the case fee.`);
                            this.setState({
                                ...this.state,
                                isDeleting: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { caseFee, forDiscontinued, readOnly, closeModal } = this.props;
        const { errors, isUpdating, isDeleting } = this.state;
        const isCreate = caseFee === undefined;

        return (
            <>
                <Form
                    defaultValues={{
                        ...DEFAULT_CREATE_VALUES,
                        ...caseFee,
                        ForDiscontinued: forDiscontinued
                    }}
                    errors={errors}
                    onSubmit={this.handleSubmit}
                >
                    <div className="flex items-center -mx-2">
                        <div className={`${isCreate ? 'w-1/3' : 'w-1/4'} px-2`}>
                            <Input
                                type="number"
                                name="DaysAfter"
                                label="Days After Critical Date"
                                readOnly={readOnly}
                                submitOnBlur={!isCreate}
                                required
                            />
                        </div>
                        <div className={`${isCreate ? 'w-1/3' : 'w-1/4'} px-2`}>
                            <FormConsumer>
                                {({ values }) => (
                                    <CurrencyPercentage
                                        isPercentage={values['IsPercentage']}
                                        type="text"
                                        name="PriceAdjustment"
                                        label="Price Adjustment"
                                        readOnly={readOnly}
                                        submitOnBlur={!isCreate}
                                        required
                                    />
                                )}
                            </FormConsumer>
                        </div>
                        <div className={`${isCreate ? 'w-1/3' : 'w-1/4'} px-2`}>
                            <Checkbox
                                label="Is Percentage of Original"
                                readOnly={readOnly}
                                name="IsPercentage"
                                submitOnBlur={!isCreate}
                                className="mt-4"
                            />
                        </div>
                        {!readOnly && !isCreate ? (
                            <div className="w-1/4 px-2">
                                <Guard permissions={[roles.Orgs.DELETE_FEE_FROM_ORG_CASE_TYPE]}>
                                    <Button inverse className="text-red mt-3" disabled={isUpdating || isDeleting} onClick={this.deleteCaseFee}>
                                        <i className="fas fa-trash-alt mr-2" />
                                        {!isDeleting ? 'Delete' : 'Deleting...'}
                                    </Button>
                                </Guard>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    {isCreate ? (
                        <div className="flex justify-end items-end mt-4">
                            <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                Cancel
                            </Button>
                            {!isUpdating ? (
                                <Button type="submit">Add {forDiscontinued ? 'Discontinued' : 'Completed'} Case Fee</Button>
                            ) : (
                                <Button disabled>
                                    Adding {forDiscontinued ? 'Discontinued' : 'Completed'} Case Fee... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

const mapDispatchToProps = dispatch => ({
    createOrgCaseType: dispatch.orgCaseType.createOrgCaseType
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseFeeForm);
