import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { sectionsOperations } from './duck';
import Guard from '../common/Guard';
import { roles } from '../../constants';

import Modal from '../common/Modal';
import { Input } from '../common/form';
import { Form, FormConsumer } from '../common/form/Form';
import Button from '../common/Button';

import SectionBuilder from './components/SectionBuilder';
import SectionList from './components/SectionList';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class Sections extends Component {
    state = {
        section: undefined,
        errors: {},
        showCreateModal: false,
        showJSON: false,
        showXsltEditor: false
    };

    componentDidMount() {
        this.props.getSections();
        document.addEventListener('keyup', this.handleNewKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleNewKeyPress);
    }

    handleNewKeyPress = e => {
        if (e.code === 'KeyN' && !this.state.showCreateModal && document.activeElement.nodeName !== 'INPUT') {
            e.stopPropagation();
            e.preventDefault();
            this.openCreateModal();
        }
    };

    openCreateModal = (event, section = undefined) => {
        this.setState({
            ...this.state,
            section: section || { SectionName: '', Template: { fields: { field: [] } } },
            showCreateModal: true,
            showJSON: false,
            showXsltEditor: false
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            section: undefined,
            showCreateModal: false
        });
    };

    onSubmit = values => {
        const errors = values['Template'].fields.field.reduce((errors, field) => {
            if (!field['@name']) {
                errors[field['@id']] = {
                    ...errors[field['@id']],
                    '@name': 'You must enter a name for this field.'
                };
            }
            if (!field['@label']) {
                errors[field['@id']] = {
                    ...errors[field['@id']],
                    '@label': 'You must enter a label for this field.'
                };
            }
            return errors;
        }, {});

        if (this.state.showXsltEditor && values && values.Id) {
            this.props.editSectionROI(values);
        } else if (Object.keys(errors).length) {
            toast.error('Some of your fields are missing required inputs.');
            this.setState({
                ...this.state,
                errors
            });
        } else {
            this.props
                .createSection(values)
                .then(() => this.closeModal())
                .catch(() => {});
        }
    };

    toggleEditor = () => {
        this.setState({
            ...this.state,
            showJSON: !this.state.showJSON,
            showXsltEditor: false
        });
    };

    toggleXsltEditor = () => {
        this.setState({
            ...this.state,
            showJSON: false,
            showXsltEditor: !this.state.showXsltEditor
        });
    };

    render() {
        const { section, showCreateModal, showJSON, showXsltEditor } = this.state;
        const { creatingSection, gettingSections, updatingSections, deleteSection, sections } = this.props;
        const errors = { ...this.state.errors, ...(this.props.errors && this.props.errors.ModelState) };

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Section Templates</PageHeader>
                        <Guard permissions={[roles.Sections.CREATE_SECTION_TEMPLATES]}>
                            <Button inverse icon="fas fa-columns" onClick={this.openCreateModal}>
                                Add Section Template
                            </Button>
                        </Guard>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingSections ? (
                            <SectionList
                                sections={sections}
                                updatingSections={updatingSections}
                                deleteSection={deleteSection}
                                openCreateModal={this.openCreateModal}
                            />
                        ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? (
                            <Form defaultValues={section} errors={errors} onSubmit={this.onSubmit}>
                                <FormConsumer>
                                    {({ values }) => {
                                        const editing = sections.some(s => s.SectionName === values['SectionName']);
                                        const existingFieldIds = {
                                            general: section.Template.fields.field.map(field => field['@id']) || [],
                                            templates: section.Template.fields.field
                                                .filter(field => field['@type'] === 'template')
                                                .reduce(
                                                    (templateMap, template) => ({
                                                        [template['@id']]: template.Template.fields.field.map(f => f['@id'])
                                                    }),
                                                    {}
                                                )
                                        };

                                        return (
                                            <>
                                                <Input type="text" name="SectionName" label="Section Template Name" autoFocus={true} required />
                                                {editing ? (
                                                    <p className="text-sm italic text-primary">
                                                        ** A section template with this name already exists, this will be saved as a new version of
                                                        that section.
                                                    </p>
                                                ) : (
                                                    <></>
                                                )}
                                                <SectionBuilder
                                                    existingFieldIds={existingFieldIds}
                                                    editing={editing}
                                                    name="Template"
                                                    label="Fields"
                                                    required
                                                    errors={errors}
                                                    showJSON={showJSON}
                                                    showXsltEditor={showXsltEditor}
                                                />
                                                <div className="flex justify-between items-center mt-4">
                                                    <div className="flex">
                                                        <Guard permissions={[roles.General.VIEW_JSON_EDITOR]}>
                                                            <Button
                                                                onClick={this.toggleEditor}
                                                                inverse
                                                                icon={`fas ${!showJSON ? 'fa-code' : 'file-invoice'}`}
                                                            >
                                                                Switch to {!showJSON ? 'JSON' : 'Form'}
                                                            </Button>
                                                        </Guard>
                                                        <Guard permissions={[roles.Sections.ADMIN_SECTION_TEMPLATES]}>
                                                            <Button
                                                                onClick={this.toggleXsltEditor}
                                                                inverse
                                                                icon={`fas ${!showXsltEditor ? 'fa-i-cursor' : 'file-invoice'}`}
                                                                className="ml-4"
                                                            >
                                                                Switch to {!showXsltEditor ? 'XSLT Editor' : 'Form'}
                                                            </Button>
                                                        </Guard>
                                                    </div>
                                                    <div className="flex justify-end items-center">
                                                        <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                                            Cancel
                                                        </Button>
                                                        {!creatingSection ? (
                                                            <Button type="submit">
                                                                {showXsltEditor && values && values['Id'] ? 'Save XSLT' : 'Add Section Template'}
                                                            </Button>
                                                        ) : (
                                                            <Button disabled>
                                                                {showXsltEditor && values && values['Id']
                                                                    ? 'Saving XSLT'
                                                                    : 'Adding Section Template...'}
                                                                <i className="fas fa-spinner fa-pulse" />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }}
                                </FormConsumer>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    gettingSections: state.sections._updating._gettingSections,
    creatingSection: state.sections._updating._creatingSection,
    updatingSections: state.sections._updating._sections,
    sections: state.sections.sections,
    errors: state.sections._errors
});

const mapDispatchToProps = dispatch => ({
    getSections: () => dispatch(sectionsOperations.getSections()),
    createSection: section => dispatch(sectionsOperations.createSection(section)),
    deleteSection: section => dispatch(sectionsOperations.deleteSection(section)),
    editSectionROI: section => dispatch(sectionsOperations.editSectionROI(section))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sections);
