import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getScrollBarWidth } from '../../utils';

class SideNav extends Component {
    state = {
        navBarHeight: 0
    };

    componentDidMount() {
        this.props.showSideNavButton();
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        this.props.hideSideNavButton();
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({
            navBarHeight: document.getElementById('nav').clientHeight
        });
    };
    render() {
        const scrollBarWidth = getScrollBarWidth();
        const props = this.props;
        const { navBarHeight } = this.state;

        return (
            <>
                <div
                    id="mySidenav"
                    className="sidenav z-50 bg-primary h-screen"
                    style={{
                        width: 300 + scrollBarWidth,
                        left: props.sideNavOpen ? '0' : -scrollBarWidth - 300,
                        top: navBarHeight,
                        height: `calc(100vh - ${navBarHeight}px)`
                    }}
                >
                    <div>{props.children}</div>
                </div>
                {props.sideNavOpen ?
                    <div
                        className="w-screen h-screen pin fixed bg-black opacity-75 z-40 cursor-pointer"
                        onClick={props.toggleSideNav}
                    ></div> :
                    <></>
                }
                <div
                    className="z-50 bg-primary h-screen fixed pin-l w-16"
                    style={{ display: props.sideNavOpen ? 'none' : 'block', top: navBarHeight }}
                >
                    <div className="sm-px-0 flex flex-col">
                        {props.icons.map((icon, index) => (
                            <div
                                className="py-4 flex items-center justify-center border-accent border-solid border-b-2 relative cursor-pointer"
                                key={index}
                                onClick={props.toggleSideNav}
                            >
                                <i className={`fas ${icon} text-white text-2xl`} />
                                <span className="triangle triangle-bottom-left" />
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    sideNavOpen: state.navigation.sideNavOpen
});

const mapDispatchToProps = dispatch => ({
    showSideNavButton: dispatch.navigation.showSideNavButton,
    hideSideNavButton: dispatch.navigation.hideSideNavButton,
    toggleSideNav: dispatch.navigation.toggleSideNav
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideNav);
