import 'babel-polyfill';
import 'airbnb-js-shims';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import './extensions';
import App from './app/App';
import { store, persistor } from './store';
import { unregister } from './registerServiceWorker';

import 'jsoneditor/dist/jsoneditor.min.css';
import 'react-table/react-table.css';
import 'react-day-picker/lib/style.css';
import 'react-super-treeview/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} closeOnClick pauseOnHover draggable />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

unregister();
