import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect, NavLink, Switch, Route, withRouter } from 'react-router-dom';

import { orgOperations } from './duck';
import Guard from '../common/Guard';
import { roles } from '../../constants';

import OrgGeneral from './components/OrgDetails/OrgGeneral';
import OrgCaseTypes from './components/OrgDetails/OrgCaseTypes';
import OrgUsers from './components/OrgDetails/OrgUsers';
import OrgDeadlines from './components/OrgDetails/OrgDeadlines';
import OrgExpenses from './components/OrgDetails/OrgExpenses';
import OrgInvoices from './components/OrgDetails/OrgInvoices';
import Button from '../common/Button';
import Modal from '../common/Modal';

const sections = [
    {
        title: 'General',
        key: 'general',
        component: OrgGeneral,
        permissions: [roles.Orgs.VIEW_ORGANIZATIONS]
    },
    {
        title: 'Case Types',
        key: 'case-types',
        component: OrgCaseTypes,
        permissions: [roles.Orgs.GET_ORG_CASE_TYPES]
    },
    {
        title: 'Users',
        key: 'users',
        component: OrgUsers,
        permissions: [roles.Orgs.GET_ORG_USERS]
    },
    {
        title: 'Expenses',
        key: 'expenses',
        component: OrgExpenses,
        permissions: [roles.Orgs.GET_ORG_EXPENSE_RULES]
    },
    {
        title: 'Invoices',
        key: 'invoices',
        component: OrgInvoices,
        permissions: [roles.Orgs.GET_ORG_INVOICE_SETTINGS]
    }
];

class OrgDetails extends Component {
    state = {
        showDeleteModal: false
    };

    showDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: true
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false
        });
    };

    deleteOrg = () => {
        const { match, orgs } = this.props;
        const org = orgs.find(o => o.Id === +match.params['orgId']);

        this.props
            .deleteOrg(org)
            .then(() => {
                this.closeDeleteModal();
                this.props.refreshOrgs();
            })
            .catch(() => { });
    };

    render() {
        const { match, gettingOrgs, orgs, updatingOrgs } = this.props;
        const { showDeleteModal } = this.state;
        const org = orgs.find(o => o.Id === +match.params['orgId']);
        const isDeleting = updatingOrgs[+match.params['orgId']];

        return (
            <>
                <div className="flex justify-between items-center mb-4">
                    <div className="flex">
                        {sections.map((section, index) => (
                            <Guard key={`${section.key}-link`} permissions={section.permissions}>
                                <NavLink
                                    className={`shadow px-4 py-2 bg-white ${
                                        match.params.section === section.key ? 'cursor-default' : 'text-grey hover:text-primary'
                                        } no-underline text-xs ${index === 0 ? 'rounded-l' : index === sections.length - 1 ? 'rounded-r' : ''}`}
                                    activeClassName="text-primary font-bold"
                                    to={`/orgs/${match.params.orgId}/${section.key}`}
                                >
                                    {section.title}
                                </NavLink>
                            </Guard>
                        ))}
                    </div>
                    {!gettingOrgs && org ? (
                        <Guard permissions={[roles.Orgs.DELETE_ORGANIZATION]}>
                            <Button inverse className={org.Hidden ? 'text-green-light' : 'text-red-light'} onClick={this.showDeleteModal}>
                                {org.Hidden ? 'Activate' : 'Deactivate'}
                            </Button>
                        </Guard>
                    ) : (
                            <></>
                        )}
                </div>
                <div className="bg-white rounded shadow p-4 mb-8 overflow-x-auto">
                    <Switch>
                        {sections.map(section => (
                            <Route key={`${section.key}-route`} exact path={`/orgs/:orgId/${section.key}`}>
                                <section.component />
                            </Route>
                        ))}
                        <Route>
                            <Redirect to={`/orgs/${match.params.orgId}/general`} />
                        </Route>
                    </Switch>
                </div>
                <Modal show={showDeleteModal} onClose={this.closeDeleteModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showDeleteModal ? (
                            <>
                                <p>
                                    Are you sure you wish to {org.Hidden ? 'activate' : 'deactivate'} <strong>{org.Name}</strong>?
                                </p>
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeDeleteModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!isDeleting ? (
                                        <Button
                                            className={`text-${org.Hidden ? 'green' : 'red'} bg-white hover:bg-grey-lighter`}
                                            onClick={this.deleteOrg}
                                        >
                                            {org.Hidden ? 'Activate' : 'Deactivate'} {org.Name}
                                        </Button>
                                    ) : (
                                            <Button
                                                className={`text-${org.Hidden ? 'green' : 'red'} bg-white hover:bg-grey-lighter`}
                                                disabled
                                            >
                                                {org.Hidden ? 'Activating' : 'Deactivating'} {org.Name}... <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                </div>
                            </>
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    orgs: state.orgs.orgs,
    gettingOrgs: state.orgs._updating._gettingOrgs,
    updatingOrgs: state.orgs._updating._orgs
});

const mapDispatchToProps = dispatch => ({
    deleteOrg: org => dispatch(orgOperations.deleteOrg(org)),
    refreshOrgs: () => dispatch(orgOperations.getOrgs())
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OrgDetails)
);
