import React, { Component } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import _ from 'lodash';

import { roles } from '../../../../../constants';
import { accountSelectors } from '../../../../account/duck';

import { baseUrl } from '../../../../../config';
import Modal from '../../../../common/Modal';
import UserEditor from '../../../../identity/components/UserEditor';
import { GhostForm } from '../../../../common/form/Form';
import { TableCheckbox } from '../../../../common/form';

const formFields = [
    {
        Header: 'PoC',
        Name: 'PointOfContact'
    },
    {
        Header: (
            <span>
                Send
                <br />
                On Hold
                <br />
                Notice
            </span>
        ),
        Name: 'SendOnHoldNotice'
    },
    {
        Header: (
            <span>
                Send
                <br />
                Submission
                <br />
                Notice
            </span>
        ),
        Name: 'SendSubmissionNotice'
    },
    {
        Header: (
            <span>
                Send
                <br />
                Completion
                <br />
                Notice
            </span>
        ),
        Name: 'SendCompletionNotice'
    },
    {
        Header: 'Include ROI',
        Name: 'IncludeROI'
    },
    {
        Header: 'Encrypt ROI',
        Name: 'EncryptROI'
    },
    {
        Header: (
            <span>
                View Closed
                <br />
                Cases
            </span>
        ),
        Name: 'ViewClosedCases'
    }
];

class OrgUsersList extends Component {
    state = {
        showEditModal: false,
        userId: ''
    };

    componentWillUnmount() {
        if (this.userSettingsPromise) this.userSettingsPromise.cancel();
    }

    openModal = (userId = '') => {
        this.setState({
            showEditModal: true,
            userId
        });
    };

    closeModal = () => {
        this.setState({
            showEditModal: false,
            userId: ''
        });
    };

    updatedSettings = async (values, userId) => {
        const { access_token, orgId } = this.props;

        try {
            const result = await axios.put(`${baseUrl}/api/orgs/${orgId}/users/${userId}/settings`, values, {
                headers: { Authorization: `Bearer ${access_token}` }
            });

            toast.success(`${result.data.FirstName} ${result.data.LastName}'s settings were updated successfully.`);
            console.log(result.data);
            //this.props.userUpdated(result.data);
        } catch (e) {
            toast.error(`There was an error updating the settings.`);
        }
    };

    render() {
        const { showEditModal, userId } = this.state;
        const { users, hasPermission } = this.props;

        const canUpdate = hasPermission([roles.Orgs.UPDATE_USER_ORG_SETTINGS]);
        const canUpdateUser = hasPermission([roles.Users.EDIT_USERS]);

        const columns = [
            {
                id: 'Name',
                Header: 'Name',
                accessor: d => `${d.FirstName}${d.MiddleName ? ' ' + d.MiddleName + ' ' : ' '}${d.LastName}`,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                Cell: ({ row, value }) =>
                    !row._original.Inherited && canUpdateUser ? (
                        <span className="text-primary underline cursor-pointer" onClick={() => this.openModal(row._original.Id)}>
                            {value}
                        </span>
                    ) : (
                        value
                    )
            },
            {
                id: 'Groups',
                Header: 'Groups',
                accessor: d => d.Groups.map(g => g.Name).join(', '),
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                id: 'Phones',
                Header: 'Phone',
                accessor: d => d.Phones.map(p => p.Number).join(', '),
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                id: 'Emails',
                Header: 'Email',
                accessor: d => {
                    const emails = d.Emails.map(e => e.Address);
                    return _.uniq([d.Email, ...emails]).join(', ');
                },
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Inherited',
                accessor: 'Inherited',
                sortable: false,
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                ),
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'INHERITED') return row[filter.id] === true;
                    if (filter.value === 'NON-INHERITED') return row[filter.id] === false;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">
                        <select
                            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 rounded"
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'ALL'}
                        >
                            <option value="ALL">Show All</option>
                            <option value="INHERITED">Inherited</option>
                            <option value="NON-INHERITED">Non-Inherited</option>
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                )
            },
            ...formFields.map(field => ({
                Header: field.Header,
                sortable: false,
                filterable: false,
                Cell: () => <TableCheckbox type="checkbox" name={field.Name} readOnly={!canUpdate} submitOnBlur />
            }))
        ];

        return (
            <>
                <div className="mt-4">
                    <ReactTable
                        filterable
                        data={users}
                        columns={columns}
                        showPagination={users.length > 10}
                        defaultPageSize={Math.min(10, users.length)}
                        defaultSorted={[{ id: 'Name', desc: false }]}
                        defaultFiltered={[{ id: 'Inherited', value: 'NON-INHERITED' }]}
                        getTrGroupProps={(state, rowInfo, column) => ({
                            formDefaults: rowInfo ? rowInfo.original.Settings : {},
                            userId: rowInfo ? rowInfo.original.Id : ''
                        })}
                        TrGroupComponent={({ formDefaults, userId, ...rest }) => (
                            <GhostForm key={userId} defaultValues={formDefaults} onSubmit={values => this.updatedSettings(values, userId)}>
                                <ReactTableDefaults.TrGroupComponent {...rest} />
                            </GhostForm>
                        )}
                    />
                </div>
                <Modal show={showEditModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showEditModal ? (
                            <UserEditor
                                userId={userId}
                                onUpdated={() => {
                                    this.props.usersChanged(true);
                                    this.closeModal();
                                }}
                                close={this.closeModal}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

export default connect(mapStateToProps)(OrgUsersList);
