import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const item = {
    state: {
        current: undefined,
        history: [],
        issueCodes: [],
        attachments: [],
        expenses: [],
        currentSection: undefined,
        _errors: {},
        _updating: {
            _gettingItem: false,
            _updatingItem: false,
            _gettingItemHistory: false,
            _deletingIssueCode: {},
            _addingIssueCode: false,
            _gettingIssueCodes: false,
            _deletingSection: {},
            _addingSection: false,
            _updatingSection: false,
            _updatingExpense: false,
            _creatingExpense: false,
            _gettingExpenses: false,
            _swappingAttachments: false,
            _deletingExpense: {},
            _deletingFiles: false
        }
    },
    reducers: {
        getItemRequest: state => ({
            ...state,
            current: undefined,
            _errors: {},
            _updating: { ...state._updating, _gettingItem: true }
        }),
        getItemSuccess: (state, item) => ({
            ...state,
            current: item,
            _errors: {},
            _updating: { ...state._updating, _gettingItem: false }
        }),
        getItemFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingItem: false }
        }),

        updateItemRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _updatingItem: true }
        }),
        updateItemSuccess: (state, item) => ({
            ...state,
            current: item,
            _errors: {},
            _updating: { ...state._updating, _updatingItem: false }
        }),
        updateItemFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _updatingItem: false }
        }),

        getItemHistoryRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _gettingItemHistory: true }
        }),
        getItemHistorySuccess: (state, history) => ({
            ...state,
            history,
            _errors: {},
            _updating: { ...state._updating, _gettingItemHistory: false }
        }),
        getItemHistoryFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingItemHistory: false }
        }),

        getItemIssueCodesRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _gettingIssueCodes: true }
        }),
        getItemIssueCodesSuccess: (state, issueCodes) => ({
            ...state,
            issueCodes,
            _errors: {},
            _updating: { ...state._updating, _gettingIssueCodes: false }
        }),
        getItemIssueCodesFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingIssueCodes: false }
        }),

        addItemIssueCodeRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _addingIssueCode: true }
        }),
        addItemIssueCodeSuccess: (state, issueCodes) => ({
            ...state,
            issueCodes,
            _errors: {},
            _updating: { ...state._updating, _addingIssueCode: false }
        }),
        addItemIssueCodeFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _addingIssueCode: false }
        }),
        deleteItemIssueCodeRequest: (state, issueCodeId) => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingIssueCode: {
                    ...state._updating._deletingIssueCode,
                    [issueCodeId]: true
                }
            }
        }),
        deleteItemIssueCodeSuccess: (state, { issueCodeId, issueCodes }) => ({
            ...state,
            issueCodes,
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingIssueCode: {
                    ...state._updating._deletingIssueCode,
                    [issueCodeId]: false
                }
            }
        }),
        deleteItemIssueCodeFailure: (state, { issueCodeId, errors }) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating,
                _deletingIssueCode: {
                    ...state._updating._deletingIssueCode,
                    [issueCodeId]: false
                }
            }
        }),

        addSectionToItemRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _addingSection: true }
        }),
        addSectionToItemSuccess: (state, item) => ({
            ...state,
            current: item,
            _errors: {},
            _updating: { ...state._updating, _addingSection: false }
        }),
        setCurrentSectionForItemSuccess: (state, Section_Id) => ({
            ...state,
            currentSection: Section_Id,
            _errors: {},
            _updating: { ...state._updating, _addingSection: false }
        }),
        addSectionToItemFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _addingSection: false }
        }),
        updateSectionRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _updatingSection: true }
        }),
        updateSectionSuccess: (state, item) => ({
            ...state,
            current: item,
            _errors: {},
            _updating: { ...state._updating, _updatingSection: false }
        }),
        updateSectionFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _updatingSection: false }
        }),
        removeSectionFromItemRequest: (state, sectionId) => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingSection: {
                    ...state._updating._deletingSection,
                    [sectionId]: true
                }
            }
        }),
        removeSectionFromItemSuccess: (state, { sectionId, item }) => ({
            ...state,
            current: item,
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingSection: {
                    ...state._updating._deletingSection,
                    [sectionId]: false
                }
            }
        }),
        removeSectionFromItemFailure: (state, { sectionId, errors }) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating,
                _deletingSection: {
                    ...state._updating._deletingSection,
                    [sectionId]: false
                }
            }
        }),
        setAttachments: (state, payload) => ({
            ...state,
            attachments: payload
        }),

        getItemExpensesRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _gettingExpenses: true }
        }),
        getItemExpensesSuccess: (state, expenses) => ({
            ...state,
            expenses,
            _errors: {},
            _updating: { ...state._updating, _gettingExpenses: false }
        }),
        getItemExpensesFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingExpenses: false }
        }),

        createItemExpenseRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _creatingExpense: true }
        }),
        createItemExpenseSuccess: (state, expense) => ({
            ...state,
            expenses: [...state.expenses, expense],
            _errors: {},
            _updating: { ...state._updating, _creatingExpense: false }
        }),
        createItemExpenseFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _creatingExpense: false }
        }),

        updateItemExpenseRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _updatingExpense: true }
        }),
        updateItemExpenseSuccess: (state, expense) => ({
            ...state,
            expenses: state.expenses.map(e => (e.Id !== expense.Id ? e : expense)),
            _errors: {},
            _updating: { ...state._updating, _updatingExpense: false }
        }),
        updateItemExpenseFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _updatingExpense: false }
        }),

        deleteItemExpenseRequest: (state, expenseId) => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingExpense: {
                    ...state._updating._deletingExpense,
                    [expenseId]: true
                }
            }
        }),
        deleteItemExpenseSuccess: (state, expenseId) => ({
            ...state,
            expenses: state.expenses.filter(e => e.Id !== expenseId),
            _errors: {},
            _updating: {
                ...state._updating,
                _deletingExpense: {
                    ...state._updating._deletingExpense,
                    [expenseId]: false
                }
            }
        }),
        deleteItemExpenseFailure: (state, { expenseId, errors }) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating,
                _deletingExpense: {
                    ...state._updating._deletingExpense,
                    [expenseId]: false
                }
            }
        }),

        swapAttachmentRequest: (state, swapping) => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _swappingAttachments: swapping }
        }),
        setDeletingFiles: (state, payload) => ({ ...state, ...{ _updating: { _deletingFiles: payload } } })
    },
    effects: dispatch => ({
        async getItem({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.getItemRequest();

            try {
                const item = await axios.get(`${baseUrl}/api/cases/${caseId}/items/${itemId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.item.getItemSuccess(item.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.getItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async updateItem({ caseId, itemId, item }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateItemRequest();

            try {
                const result = await axios.put(`${baseUrl}/api/cases/${caseId}/items/${itemId}`, item, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The item was updated successfully.');
                dispatch.item.updateItemSuccess(result.data);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async getItemHistory({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.getItemHistoryRequest();

            try {
                const result = await axios.get(`${baseUrl}/api/cases/${caseId}/items/${itemId}/history`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.item.getItemHistorySuccess(result.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.getItemHistoryFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async getItemIssueCodes({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.getItemIssueCodesRequest();

            try {
                const result = await axios.get(`${baseUrl}/api/cases/${caseId}/items/${itemId}/issue-codes`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.item.getItemIssueCodesSuccess(result.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.getItemIssueCodesFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async addItemIssueCode({ caseId, itemId, IssueCodeType_Id }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.addItemIssueCodeRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/${itemId}/issue-codes`,
                    {
                        IssueCodeType_Id
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success('The item code was added successfully.');
                dispatch.item.addItemIssueCodeSuccess(result.data);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.addItemIssueCodeFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async deleteItemIssueCode({ caseId, itemId, issueCodeId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.deleteItemIssueCodeRequest(issueCodeId);

            try {
                const result = await axios.delete(`${baseUrl}/api/cases/${caseId}/items/${itemId}/issue-codes/${issueCodeId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The item code was removed successfully.');
                dispatch.item.deleteItemIssueCodeSuccess({ issueCodeId, issueCodes: result.data });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.deleteItemIssueCodeFailure({
                    issueCodeId,
                    errors: error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                });
                return Promise.reject();
            }
        },
        async addSectionToItem({ caseId, itemId, Section_Id }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.addSectionToItemRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/${itemId}/sections`,
                    {
                        Section_Id
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success('The section was added to the item successfully.');

                if (result.data.Sections && result.data.Sections.length > 0) {
                    const sections = result.data.Sections;
                    const section = sections[sections.length - 1] || {};
                    dispatch.item.setCurrentSectionForItemSuccess(section.Id);
                }

                dispatch.item.addSectionToItemSuccess(result.data);

                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.addSectionToItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async updateSection({ caseId, itemId, sectionId, Definition }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateSectionRequest();

            try {
                const result = await axios.put(
                    `${baseUrl}/api/cases/${caseId}/items/${itemId}/sections/${sectionId}`,
                    {
                        Definition
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success('The section was updated successfully.');
                dispatch.item.updateSectionSuccess(result.data);
                return Promise.resolve();
            } catch (error) {
                if (error && error.response && error.response.data.Message) {
                    toast.error(error.response.data.ModelState.Definition.join(''));
                    dispatch.cases.setErrors(error.response);
                } else if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateSectionFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async removeSectionFromItem({ caseId, itemId, sectionId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.removeSectionFromItemRequest(sectionId);

            try {
                const result = await axios.delete(`${baseUrl}/api/cases/${caseId}/items/${itemId}/sections/${sectionId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The section was removed from the item successfully.');
                dispatch.item.removeSectionFromItemSuccess({ sectionId, item: result.data });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.removeSectionFromItemFailure({
                    sectionId,
                    errors: error.response !== undefined ? error.response.data : error.response
                });
                return Promise.reject();
            }
        },
        async submitItem({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateItemRequest();

            try {
                const response = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/submit-items`,
                    {
                        ItemIds: [itemId]
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );
                const updatedItem = { ...rootState.item.current, ItemStatus: response.data };
                toast.success(`The item was submitted successfully.`);
                dispatch.item.updateItemSuccess(updatedItem);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateItemFailure(error.response);
                return Promise.reject();
            }
        },
        async rejectItem({ caseId, itemId, Reason }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateItemRequest();

            try {
                const response = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/reject-items`,
                    {
                        Reason,
                        ItemIds: [itemId]
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                const updatedItem = { ...rootState.item.current, ItemStatus: response.data, RejectionReason: Reason };
                toast.success(`The item was rejected successfully.`);
                dispatch.item.updateItemSuccess(updatedItem);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async acceptItem({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateItemRequest();

            try {
                const response = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/items/accept-items`,
                    {
                        ItemIds: [itemId]
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                const updatedItem = { ...rootState.item.current, ItemStatus: response.data };
                toast.success(`The item was accepted successfully.`);
                dispatch.item.updateItemSuccess(updatedItem);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async getAttachments({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/items/${itemId}/attachments/`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.item.setAttachments(response.data);
            } catch (error) {
                toast.error('Unable to get attachments.');
                if (error && error.response) dispatch.cases.setErrors(error.response);
            }
        },
        async addAttachments({ data, caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.post(`${baseUrl}/api/cases/${caseId}/items/${itemId}/attachments/`, data, {
                    headers: { Authorization: `Bearer ${access_token}`, 'content-type': 'multipart/form-data' }
                });
                toast.success(`Files were uploaded.`);
                dispatch.item.setAttachments([...response.data]);
                return response;
            } catch (error) {
                toast.error('Unable to get attachments.');
                if (error && error.response) dispatch.cases.setErrors(error.response);

                throw error;
            }
        },
        async deleteAttachment({ Case_Id, Goal_Id, Id }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.item.setDeletingFiles(true);

                await axios.delete(`${baseUrl}/api/cases/${Case_Id}/items/${Goal_Id}/attachments/${Id}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.item.setDeletingFiles(false);

                const attachments = rootState.item.attachments.filter(attachment => attachment.Id !== Id);

                dispatch.item.setAttachments([...attachments]);

                toast.success('File was removed.');
            } catch (error) {
                dispatch.item.setDeletingFiles(false);
                console.log(error);
                toast.error('Unable to remove attachment.');
            }
        },
        async swapAttachments({ Case_Id, Goal_Id, swapObject }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            dispatch.item.swapAttachmentRequest(true);

            try {
                const response = await axios.put(`${baseUrl}/api/cases/${Case_Id}/items/${Goal_Id}/attachments/swap`, swapObject, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success(`The attachments were reordered successfully.`);
                dispatch.item.swapAttachmentRequest(false);

                dispatch.item.setAttachments([...response.data]);

                return Promise.resolve(response.data);
            } catch (error) {
                dispatch.item.swapAttachmentRequest(false);
                toast.error('There was an error connecting to the server.');
                return Promise.reject();
            }
        },
        async getExpenses({ caseId, itemId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.getItemExpensesRequest();

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/items/${itemId}/expenses/`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.item.getItemExpensesSuccess(response.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.getItemExpensesFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async createExpense({ caseId, itemId, expense }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.createItemExpenseRequest();

            try {
                const response = await axios.post(`${baseUrl}/api/cases/${caseId}/items/${itemId}/expenses`, expense, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The expense was updated successfully.');
                dispatch.item.createItemExpenseSuccess(response.data);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.createItemExpenseFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async updateExpense({ caseId, itemId, expense }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.updateItemExpenseRequest();

            try {
                const response = await axios.put(
                    `${baseUrl}/api/cases/${caseId}/items/${itemId}/expenses/${expense.Id}`,
                    {
                        ExpenseType_Id: expense.ExpenseType_Id,
                        Units: expense.Units,
                        IncurredBy_Id: expense.IncurredBy_Id,
                        Note: expense.Note,
                        Reimbursable: expense.Reimbursable,
                        Override: expense.Override
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success('The expense was updated successfully.');
                dispatch.item.updateItemExpenseSuccess(response.data);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.updateItemExpenseFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async deleteExpense({ caseId, itemId, expense }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.item.deleteItemExpenseRequest(expense.Id);

            try {
                await axios.delete(`${baseUrl}/api/cases/${caseId}/items/${itemId}/expenses/${expense.Id}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The expense was deleted successfully.');
                dispatch.item.deleteItemExpenseSuccess(expense.Id);
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.deleteItemExpenseFailure({
                    expenseId: expense.Id,
                    errors: error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                });
                return Promise.reject();
            }
        }
    })
};
