import types from './types';

const getSectionsRequest = () => ({
    type: types.GET_SECTIONS_REQUEST
});
const getSectionsSuccess = sections => ({
    type: types.GET_SECTIONS_SUCCESS,
    payload: sections
});
const getSectionsFailure = errors => ({
    type: types.GET_SECTIONS_FAILURE,
    payload: errors
});

const createSectionRequest = () => ({
    type: types.CREATE_SECTION_REQUEST
});
const createSectionSuccess = section => ({
    type: types.CREATE_SECTION_SUCCESS,
    payload: section
});
const createSectionFailure = errors => ({
    type: types.CREATE_SECTION_FAILURE,
    payload: errors
});
const updateXSLTSuccess = section => ({
    type: types.UPDATE_XSLT_SUCCESS,
    payload: section
});
const deleteSectionRequest = section => ({
    type: types.DELETE_SECTION_REQUEST,
    payload: section
});
const deleteSectionSuccess = sectionId => ({
    type: types.DELETE_SECTION_SUCCESS,
    payload: sectionId
});
const deleteSectionFailure = (sectionId, errors) => ({
    type: types.DELETE_SECTION_FAILURE,
    payload: { sectionId, errors }
});

export default {
    getSectionsRequest,
    getSectionsSuccess,
    getSectionsFailure,
    createSectionRequest,
    createSectionSuccess,
    createSectionFailure,
    deleteSectionRequest,
    deleteSectionSuccess,
    deleteSectionFailure,
    updateXSLTSuccess
};
