import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const messages = {
    state: {
        messages: [],
        recipients: [],
        errors: {},
        _updating: {
            _sendingMessage: false
        }
    },
    reducers: {
        setMessages: (state, payload) => ({ ...state, ...{ messages: payload, errors: {} } }),
        setRecipients: (state, payload) => ({ ...state, ...{ recipients: payload } }),
        setSendingMessage: (state, payload) => ({ ...state, ...{ _updating: { _sendingMessage: payload } } }),
        setErrors: (state, payload) => ({ ...state, ...{ errors: payload } })
    },
    effects: dispatch => ({
        async getMessages(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/messages`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.messages.setMessages(response.data);
            } catch (error) {
                if (error && error.response) {
                    dispatch.cases.setErrors(error.response);
                }
            }
        },
        async addMessage({ caseId, Message, Recipients }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                dispatch.messages.setSendingMessage(true);
                const response = await axios.post(
                    `${baseUrl}/api/cases/${caseId}/messages`,
                    { Message, Recipients },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );
                toast.success('Message sent.');
                rootState.messages.messages.push(response.data);
                dispatch.messages.setSendingMessage(false);
                dispatch.messages.setMessages([...rootState.messages.messages]);
                return response;
            } catch (error) {
                toast.error('Unable to send message.');
                dispatch.messages.setSendingMessage(false);
                if (error && error.response) {
                    dispatch.messages.setErrors(error.response);
                }

                throw error;
            }
        },
        async getRecipients(caseId, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/cases/${caseId}/recipients`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.messages.setRecipients(response.data);
            } catch (error) {
                if (error && error.response) {
                    dispatch.cases.setErrors(error.response);
                }
            }
        }
    })
};
