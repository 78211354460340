import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'whatwg-fetch';
import Dropzone from 'react-dropzone';
import FileSaver from 'file-saver';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { USER_PROFILE_PAGE_SIZE } from '../../../constants';
import Modal from '../../common/Modal';
import Button from '../../common/Button';

class ItemTypeAssets extends Component {
    state = {
        assets: [],
        errors: {},
        uploading: false
    };
    columns = [
        {
            Header: 'Name',
            accessor: 'Name',
            Cell: row => (
                <div className="flex flex-grow items-center h-full">
                    <input
                        className="input-field "
                        type="text"
                        onChange={e => {
                            e.preventDefault();
                            this.changeName(row.original, e.target.value);
                        }}
                        defaultValue={row.original.Name.split('.').shift()}
                    />
                </div>
            )
        },
        {
            Header: 'Added On',
            accessor: 'CreatedDate',
            Cell: row => (
                <div className="w-full text-center">
                    {moment(row.original.CreatedDate)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            Header: '',
            id: 'Actions',
            filterable: false,
            sortable: false,
            accessor: d => d,
            Cell: row => (
                <div className="flex justify-center items-center h-full">
                    <span
                        className="text-primary cursor-pointer mr-4"
                        onClick={e => {
                            e.preventDefault();
                            this.downloadFile(row.original);
                        }}
                    >
                        <i className="fas fa-download" />
                    </span>

                    <span className="text-red-light cursor-pointer" onClick={this.confirmDelete.bind(this, row.original)}>
                        <i className="fas fa-trash-alt" />
                    </span>
                </div>
            )
        }
    ];

    componentDidMount() {
        this.props.getAssets(this.props.itemType.Id);
    }

    confirmDelete = attachment => {
        this.setState({
            showModal: true,
            fileToDelete: { ...attachment }
        });
    };

    changeName = _.debounce(async (asset, name) => {
        let { itemType } = this.props;

        if (!name) return;

        try {
            await this.props.changeName({ itemTypeId: itemType.Id, assetId: asset.Id, name });
        } catch (error) {
            toast.error('Unable to change filename.');
        }
    }, 500);

    downloadFile = async asset => {
        let { itemType } = this.props;
        try {
            const response = await this.props.downloadAsset({ itemTypeId: itemType.Id, assetId: asset.Id });

            let blob = await response.blob();

            FileSaver.saveAs(blob, asset.Name + '.' + asset.FileName.split('.').pop());
        } catch (error) {
            console.log(error);
            toast.error('Unable to retrieve file.');
        }
    };

    uploadFile = async acceptedFiles => {
        let data = new FormData();
        const { itemType } = this.props;
        acceptedFiles.forEach((file, index) => {
            data.append(index, file, file.name);
        });

        try {
            this.setState({
                uploading: true
            });
            await this.props.addAsset({ data, itemTypeId: itemType.Id });
            this.setState({
                uploading: false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                uploading: false
            });
        }
    };

    deleteAsset = asset => {
        this.setState({
            showModal: false,
            fileToDelete: undefined
        });
        const itemType = this.props.itemType;
        this.props.deleteAsset({ itemTypeId: itemType.Id, assetId: asset.Id });
    };

    render() {
        const { showModal, fileToDelete, uploading } = this.state;

        return (
            <div className="mt-8 mb-4">
                <div className="mt-2" />
                <ReactTable
                    columns={this.columns}
                    data={this.props.assets}
                    getNoDataProps={() => ({
                        style: { display: 'none' }
                    })}
                    showPagination={false}
                    defaultPageSize={USER_PROFILE_PAGE_SIZE}
                    pageSize={this.props.assets.length}
                    getTheadProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTheadFilterProps={() => ({
                        style: { background: 'white' }
                    })}
                />
                <div className="flex w-full mb-4">
                    <Dropzone multiple className="w-full h-64 border-dashed border-4" onDrop={this.uploadFile}>
                        <div className="flex w-full justify-center items-center h-full">
                            {uploading ? (
                                <i className="fas fa-spinner fa-pulse fa-3x" />
                            ) : (
                                    <h3 className="text-primary text-base">Click or drop files here (zip files will be extracted)</h3>
                                )}
                        </div>
                    </Dropzone>
                </div>
                <Modal show={showModal} className="max-w-md" onClose={() => this.setState({ showModal: false })}>
                    <h2>{`Delete ${fileToDelete && fileToDelete.Name}?`}</h2>
                    <div className="w-full flex mt-4">
                        <Button className="bg-grey ml-auto mr-4" onClick={() => this.setState({ showModal: false })}>
                            Cancel
                        </Button>
                        <Button className="text-red bg-white hover:bg-grey-lighter" onClick={this.deleteAsset.bind(this, fileToDelete)}>
                            Delete
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
const mapState = state => ({
    assets: state.itemTypeAssets.assets,
    access_token: state.account.auth.access_token,
    uploading: state.item._updating._uploadingFiles
});
const mapDispatch = dispatch => ({
    addAsset: payload => dispatch.itemTypeAssets.addAsset(payload),
    deleteAsset: payload => dispatch.itemTypeAssets.deleteAsset(payload),
    getAssets: payload => dispatch.itemTypeAssets.getAssets({ itemTypeId: payload }),
    downloadAsset: payload => dispatch.itemTypeAssets.downloadAsset(payload),
    changeName: payload => dispatch.itemTypeAssets.changeName(payload)
});
export default connect(
    mapState,
    mapDispatch
)(ItemTypeAssets);
