import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import axios from 'axios';
import moment from 'moment';

import Guard from '../../../../common/Guard';
import { roles } from '../../../../../constants';
import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';

import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal';
import { Form } from '../../../../common/form/Form';
import OrgCaseTypeDetails from './OrgCaseTypesList/OrgCaseTypeDetails';
import OrgCaseTypeManager from './OrgCaseTypesList/OrgCaseTypeManager';

class OrgCaseTypesList extends Component {
    state = {
        showDeleteModal: false,
        isDeleting: false,
        caseTypeToDelete: undefined,
        expanded: {}
    };

    componentWillUnmount() {
        if (this.deleteOrgCaseTypePromise) this.deleteOrgCaseTypePromise.cancel();
    }

    expandRow = rowIndex => {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    };

    openDeleteModal = caseType => {
        this.setState({
            showDeleteModal: true,
            caseTypeToDelete: caseType
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            isDeleting: false,
            caseTypeToDelete: undefined
        });
    };

    onDelete = () => {
        const { access_token, orgId } = this.props;
        const { caseTypeToDelete } = this.state;

        this.deleteOrgCaseTypePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: true
                    },
                    async () => {
                        try {
                            await axios.delete(`${baseUrl}/api/orgs/${orgId}/case-types/${caseTypeToDelete.Id}?deleteAll=true`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.props.onDelete(caseTypeToDelete.Id);
                            toast.success('The case type was deleted successfully.');
                            this.closeDeleteModal();
                        } catch (e) {
                            toast.error('There was an error deleting the case type.');
                            this.setState({
                                ...this.state,
                                isDeleting: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { caseTypes, orgId } = this.props;
        const { expanded, showDeleteModal, caseTypeToDelete, isDeleting } = this.state;

        const nameToDelete =
            caseTypeToDelete !== undefined
                ? `${caseTypeToDelete.CaseType.Description} ${caseTypeToDelete.CaseTemplate ? `(${caseTypeToDelete.CaseTemplate.Label})` : ``}`
                : ``;

        const columns = [
            {
                id: 'CaseType',
                Header: 'Case Type',
                accessor: d => `${d.CaseType.Short} (${d.CaseType.Description})`,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                id: 'TemplateName',
                Header: 'Template Name',
                accessor: d => (d.CaseTemplate ? d.CaseTemplate.Label : ''),
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Inherited',
                accessor: 'Inherited',
                sortable: false,
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                ),
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'INHERITED') return row[filter.id] === true;
                    if (filter.value === 'NON-INHERITED') return row[filter.id] === false;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">

                    </div>
                )
            }//,
            // {
            //     filterable: false,
            //     sortable: false,
            //     Cell: ({ row }) =>
            //         !row._original.Inherited ? (
            //             <div className="flex justify-center items-center h-full">
            //                 <Guard permissions={[roles.Orgs.REMOVE_CASE_TYPE_FROM_ORG]}>
            //                     <span
            //                         className="text-red-light cursor-pointer"
            //                         onClick={e => {
            //                             e.preventDefault();
            //                             e.stopPropagation();
            //                             this.openDeleteModal(row._original);
            //                         }}
            //                     >
            //                         <i className="fas fa-trash-alt" />
            //                     </span>
            //                 </Guard>
            //             </div>
            //         ) : (
            //                 <></>
            //             )
            // }
        ];

        const filteredCaseTypes = caseTypes.reduce((filteredTypes, caseType) => ([
            ...filteredTypes,
            ...[...(!filteredTypes.some((filteredType) => filteredType.CaseType.Id == caseType.CaseType.Id && (
                (filteredType.CaseTemplate ? filteredType.CaseTemplate.Id : -1) == (caseType.CaseTemplate ? caseType.CaseTemplate.Id : -2)
            ) && filteredType.Inherited == caseType.Inherited) ? [caseType] : [])]
        ]), []);

        return (
            <>
                <div className="mt-4">
                    <ReactTable
                        filterable
                        data={filteredCaseTypes}
                        columns={columns}
                        showPagination={false}
                        pageSize={filteredCaseTypes.length}
                        expanded={expanded}
                        defaultSorted={[
                            {
                                id: 'CaseType',
                                desc: false
                            },
                            {
                                id: 'TemplateName',
                                desc: false
                            }
                        ]}
                        filtered={this.props.filtered}
                        onFilteredChange={this.props.onFilteredChanged}
                        SubComponent={({ row }) => (
                            <OrgCaseTypeManager
                                onPriceUpdated={this.props.onPriceUpdated}
                                onOverride={this.props.onOverride}
                                onFeeCreate={this.props.onFeeCreate}
                                onFeeEdit={this.props.onFeeEdit}
                                onFeeDelete={this.props.onFeeDelete}
                                onCreated={this.props.onCreated}
                                onDelete={this.props.onDelete}
                                caseTypes={caseTypes.filter(caseType =>
                                    caseType.Inherited === row.Inherited &&
                                    caseType.CaseType.Id === row._original.CaseType.Id &&
                                    ((caseType.CaseTemplate ? caseType.CaseTemplate.Id : -1) ===
                                        (row._original.CaseTemplate ? row._original.CaseTemplate.Id : -1))
                                ).sort((a, b) => {
                                    if (a.ActiveFrom === null && b.ActiveFrom !== null) return 1;
                                    if (a.ActiveFrom !== null && b.ActiveFrom === null) return -1;
                                    return moment(a.ActiveFrom).isBefore(b.ActiveFrom) ? 1 : -1;
                                })}
                                orgId={orgId}
                                filtered={this.props.filtered}
                                inherited={row.Inherited} />
                        )}
                        getTrProps={(state, rowInfo, column) => ({
                            onClick: e => {
                                this.expandRow(rowInfo.viewIndex);
                            }
                        })}
                    />
                </div>
                <Modal show={showDeleteModal} onClose={this.closeDeleteModal} className="max-w-md">
                    <div className="p-4">
                        <h2 className="pb-4">
                            <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                        </h2>
                        {showDeleteModal ? (
                            <Form onSubmit={this.onDelete}>
                                <p>
                                    Are you sure you would like to remove <strong>{nameToDelete}</strong>? This will remove all versions of this case type from this org. This action cannot be undone.
                                </p>
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeDeleteModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!isDeleting ? (
                                        <Button type="submit" className="text-red border-red hover:bg-grey-dark">
                                            Remove
                                        </Button>
                                    ) : (
                                            <Button className="text-red border-red hover:bg-grey-dark" disabled>
                                                Removing... <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                </div>
                            </Form>
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(OrgCaseTypesList);
