import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '../common/form/Form';
import { Input } from '../common/form';
import Button from '../common/Button';
import { baseUrl } from '../../config';
import Select from 'react-select';


class FileUpload extends Component {
    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    };
    locationChange = event => {
        this.setState({ fileLocation: event.target.value });
    };

    state = {
        fileLocation: "PalmettoRequestsFolder",
        selectedFile: null,
        uploadingfile: false,
        errors: {}
    };


    onSubmit = async values => {

        const formData = new FormData();

        // Update the formData object 
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );
        formData.append(
            "Folder",
            this.state.fileLocation
        );
        console.log(values);
        try {
            this.setState({
                uploadingfile: true
            });

            await axios.post(`${baseUrl}/api/user/UploadFile`, formData);

            //await axios.post(`http://localhost:55910/api/user/UploadFile`, formData);
            this.fileInput.value = "";

            toast.success('File Has Been Uploaded.');
        } catch (error) {
            toast.success('ERROR UPLOADING FILE.');
            console.log(error);
            this.setState({
                uploadingfile: false,
                errors: { ...error }
            });
        }

        this.setState({
            uploadingfile: false
        });
    };

    render() {
        let { uploadingfile } = this.state;
        const valuesArray = JSON.parse(localStorage.getItem("persist:account"));
        const valuesArray1 = JSON.parse(valuesArray.permissions);
        var isPalmetto = false;
        if (valuesArray1 != null) {
            for (let i = 0; i < valuesArray1.groups.length; i++) {
                if (valuesArray1.groups[i] == "Palmetto") {
                    isPalmetto = true;
                }
            }
        }
        else {
            console.log(valuesArray);
            console.log(valuesArray1);
        }
        if (!isPalmetto) {
            return (<span>You Are Not Authorized</span>);
        }
        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">

                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <span> <a href="/fileu">Upload Palmetto</a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="/filed">Download Palmetto</a>
                        </span>
                        <Form onSubmit={this.onSubmit}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        Select Location*
                                        <br />
                                        <select value={this.state.fileLocation} onChange={this.locationChange} >
                                            <option value="PalmettoRequestsFolder">Palmetto Requests</option>
                                            <option value="PalmettoAcknowledgmentsFolder">Palmetto Acknowledgments</option>
                                            <option value="PalmettoProcessingFolder">Palmetto Processing</option>
                                            <option value="Palmetto202102RequestsFolder">Palmetto 2021 Requests</option>
                                            <option value="Palmetto202102AcknowledgmentsFolder">Palmetto 2021 Acknowledgments</option>
                                            <option value="Palmetto202102ProcessingFolder">Palmetto 2021 Processing</option>


                                        </select>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>



                            <div className="mb-4">
                                Upload File*
                                <br />
                                <input name="file" type="file" label="File To Upload" placeholder="" required={true} onChange={this.onFileChange} ref={ref => this.fileInput = ref} />

                            </div>
                            <div className="flex items-center justify-between">
                                <Button type="submit" loading={uploadingfile}>
                                    Upload File
                                </Button>

                            </div>
                        </Form>
                        <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileUpload;
