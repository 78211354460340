import React, { Component } from 'react';
import ReactTable from 'react-table';
import shortid from 'shortid';

import { EnumTypeSelector, Header, TableField, Divider, DatePicker } from '../../../common/form';

import { optional } from '../../../../utils';
import Button from '../../../common/Button';
import { USER_PROFILE_PAGE_SIZE } from '../../../../constants';

class CredentialsEditor extends Component {
    state = {
        columns: []
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            columns: [
                {
                    Header: 'Type',
                    accessor: 'CredentialType_Id',
                    Cell: row => (
                        <EnumTypeSelector
                            name={`Credentials.${row.original.Id}.CredentialType_Id`}
                            label=""
                            type="CredentialType"
                            required={true}
                            showShort={true}
                        />
                    )
                },
                {
                    Header: 'Cred License',
                    accessor: 'License',
                    Cell: row => {
                        return <TableField name={`Credentials.${row.original.Id}.License`} label="License number" type="text" required={true} />;
                    }
                },
                {
                    Header: 'State',
                    accessor: 'RegionType_Id',
                    Cell: row => <EnumTypeSelector name={`Credentials.${row.original.Id}.RegionType_Id`} label="" type="RegionType" required={true} />
                },
                {
                    Header: 'Expiration',
                    accessor: 'Expiration',
                    className: 'overflow-auto',
                    Cell: row => <DatePicker name={`Credentials.${row.original.Id}.Expiration`} label="Expiration date" type="date" required={true} />
                },
                {
                    Header: 'Actions',
                    accessor: 'Actions',
                    filterable: false,
                    sortable: false,
                    Cell: row => (
                        <div className="flex justify-center items-center h-full">
                            <span
                                className="text-red-light cursor-pointer"
                                onClick={this.props.onDelete.bind(null, this.props.form, 'Credentials', row.original.Id)}
                            >
                                <i className="fas fa-trash-alt" />
                            </span>
                        </div>
                    )
                }
            ]
        });
    };

    render() {
        let { user, onAdd } = this.props;
        let { columns } = this.state;
        const data = optional(user, target => target.Credentials, []);
        return (
            <>
                <div className="w-full">
                    <div className="flex align-center justify-between">
                        <Header>Credentials</Header>
                        <Button
                            icon="fas fa-id-badge"
                            onClick={onAdd.bind(null, 'Credentials', {
                                Id: shortid.generate(),
                                CredentialType_Id: 1,
                                License: '',
                                RegionType_Id: 1,
                                Expiration: undefined
                            })}
                        >
                            Add
                        </Button>
                    </div>
                    <Divider />
                    <div className="p-4">
                        {data.length > 0 ? (
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPagination={false}
                                pageSize={data.length}
                                defaultPageSize={USER_PROFILE_PAGE_SIZE}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        ) : (
                            <div className="mb-8 text-primary">No credentials.</div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default CredentialsEditor;
