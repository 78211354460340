import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../common/Button';
import EditItemForm from './components/EditItemForm';
import ItemHistoryList from './components/ItemHistoryList';
import ItemIssueCodeList from './components/ItemIssueCodeList';
import ItemSectionsManager from './components/ItemSectionsManager';
import Stepper from '../cases/components/Stepper';
import { GoalStatusType, roles } from '../../constants';
import Guard from '../common/Guard';
import { Form } from '../common/form/Form';
import { EnumTypeSelector } from '../common/form';
import Modal from '../common/Modal';
import ItemAttachments from './components/ItemAttachments';
import ItemExpensesList from './components/ItemExpensesList';
import ItemAssets from './ItemAssets';
import Messages from '../cases/components/Messages';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';
import { accountSelectors } from '../account/duck';

class ItemPage extends Component {
    state = {
        showRejectModal: false,
        requiredSections: [],
        sectionErrors: {}
    };
    steps = ['Awaiting Assignment', 'In Progress', 'In Review', 'Accepted'];

    componentDidMount() {
        const {
            match: {
                params: { caseId, itemId }
            }
        } = this.props;
        this.props.getItem(+caseId, +itemId);
        this.props.getAttachments(+caseId, +itemId);
        this.props.getMessages(caseId);
        this.props.getRecipients(caseId);
    }

    validateFields = fields => {
        return fields.field.some(field => {
            if (field['@required'] && field['@type'] !== 'checkbox') {
                if (field['@type'] === 'address' && ['@line1', '@city', '@region', '@postalCode'].some(key => !!!field[key])) {
                    return true;
                } else if (field['@type'] === 'template') {
                    return field.Entries.some(e => this.validateFields(e.fields));
                } else if (field['@type'] !== 'address' && !!!field['#text']) {
                    return true;
                }
            }

            return false;
        });
    };

    validateSections = () => {
        const { item } = this.props;
        const sectionErrors = {};

        item.Sections.forEach(section => {
            const Definition = JSON.parse(section.Definition);
            const sectionValidated = this.validateFields(Definition.fields);

            if (sectionValidated) {
                sectionErrors[section.Id] = true;
            }
        });

        if (Object.keys(sectionErrors).length > 0) {
            this.setState({
                ...this.state,
                sectionErrors
            });
            return false;
        } else {
            this.setState({
                ...this.state,
                sectionErrors: {}
            });
            return true;
        }
    };

    hasRequiredSections = () => {
        const { item } = this.props;

        // Get a list of all of the required sections, then remove
        // sections from the list as we encounter them.
        let requiredSections = item.AvailableSections.filter(s => s.Required && s.Enabled);
        item.Sections.forEach(s => {
            requiredSections = requiredSections.filter(rs => rs.SectionName !== s.Section.SectionName);
        });

        if (requiredSections.length > 0) {
            this.setState({
                ...this.state,
                requiredSections
            });
        } else {
            this.setState({
                ...this.state,
                requiredSections: []
            });
        }

        return requiredSections.length === 0;
    };

    submitItem = async () => {
        const {
            match: {
                params: { caseId, itemId }
            }
        } = this.props;

        if (!this.hasRequiredSections()) {
            toast.error('You are missing required sections.');
            return;
        }

        if (this.validateSections()) {
            await this.props.submitItem(+caseId, +itemId);
            this.props.getItemHistory(+caseId, +itemId);
        } else {
            toast.error('You have sections that are not finished.');
        }
    };

    submitReject = async values => {
        const {
            match: {
                params: { caseId, itemId }
            }
        } = this.props;

        try {
            await this.props.rejectItem(+caseId, +itemId, values.Reason);
            this.props.getItemHistory(+caseId, +itemId);
            this.setState({
                showRejectModal: false
            });
        } catch (error) {
            this.setState({
                showRejectModal: true
            });
        }
    };

    acceptItem = async () => {
        const {
            match: {
                params: { caseId, itemId }
            }
        } = this.props;

        await this.props.acceptItem(+caseId, +itemId);
        this.props.getItemHistory(+caseId, +itemId);
    };

    render() {
        const { gettingItem, item, history, errors, updatingItem } = this.props;
        const { showRejectModal, requiredSections, sectionErrors } = this.state;
        const workflowState = item && item.ItemStatus && item.ItemStatus.Short;

        const inReview = item &&
            ((
                ['HOLD', 'DISCONTINUED', 'COMPLETE'].includes(item.Case.WorkflowState) ||
                item.ItemStatus.Short == GoalStatusType.ACCEPTED
            ) && !this.props.hasPermission([roles.Cases.EDIT_INACTIVE_CASES])) ||
            (item && item.ItemStatus.Short == GoalStatusType.IN_REVIEW && !this.props.hasPermission([roles.CaseItems.EDIT_ITEMS_IN_REVIEW]));

        let activeIndex = -1;
        switch (workflowState) {
            case GoalStatusType.AWAITING_ASSIGNMENT:
                activeIndex = 0;
                break;
            case GoalStatusType.ASSIGNED:
                activeIndex = 1;
                break;
            case GoalStatusType.REJECTED:
                activeIndex = 1;
                break;
            case GoalStatusType.IN_REVIEW:
                activeIndex = 2;
                break;
            case GoalStatusType.ACCEPTED:
                activeIndex = 3;
                break;
        }

        return (
            <div className="w-full">
                <div className="flex justify-between">
                    <PageHeader>Item</PageHeader>
                    <div className="mr-4">
                        {workflowState === GoalStatusType.ASSIGNED && (
                            <Button icon="fa-paper-plane" onClick={this.submitItem} inverse loading={updatingItem}>
                                Submit for Review
                            </Button>
                        )}
                        {workflowState === GoalStatusType.REJECTED && (
                            <Button icon="fa-paper-plane" onClick={this.submitItem} inverse loading={updatingItem}>
                                Resubmit
                            </Button>
                        )}
                        {workflowState === GoalStatusType.IN_REVIEW && (
                            <Guard permissions={[roles.CaseItems.REVIEW_CASE_ITEMS]}>
                                <Button
                                    icon="fa-times-circle"
                                    onClick={() =>
                                        this.setState({
                                            showRejectModal: true
                                        })
                                    }
                                    className="text-red mr-2"
                                    inverse
                                    disabled={updatingItem}
                                >
                                    Reject
                                </Button>
                                <Button icon="fa-clipboard-check" onClick={this.acceptItem} inverse loading={updatingItem}>
                                    Accept
                                </Button>
                            </Guard>
                        )}
                    </div>
                </div>
                <Divider />
                <div className="w-full bg-white flex flex-wrap">
                    <div className="flex p-4 cursor-pointer" onClick={history.goBack}>
                        <i className=" fas fa-arrow-left text-xl mr-4" /> <span className="uppercase">Back</span>
                    </div>
                    <div className="container">
                        <div className="flex flex-wrap w-full mb-4">
                            <div className="flex w-full mb-2 scrolling-touch">
                                <Stepper steps={this.steps} activeIndex={activeIndex} />
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between items-center mb-4 -mx-3">
                            <div className="flex items-center px-3 md:w-1/4">
                                {!gettingItem && item ? (
                                    <span className="font-bold text-primary text-sm">
                                        Case #{item.Case.CaseNumber} - Item #{item.Id}
                                    </span>
                                ) : (
                                        <></>
                                    )}
                            </div>
                            <div className="flex flex-col px-3 md:w-1/4 items-center justify-center mb-1 md:mb-0">
                                {workflowState === GoalStatusType.REJECTED && (
                                    <>
                                        <h1 className="text-red text-lg mb-2">Rejected</h1>
                                        <p className="text-sm">{item && item.RejectionReason}</p>
                                    </>
                                )}
                            </div>
                        </div>
                        {requiredSections.length > 0 && (
                            <div className="mt-4 border-l-4 border-red p-4 text-primary shadow bg-white">
                                <p className="mb-2">The following required sections are missing:</p>
                                <ul>
                                    {requiredSections.map(rs => (
                                        <li key={`required-section-${rs.Id}`}>{rs.SectionName}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="bg-white py-4 px-6 m-auto">
                            {!gettingItem && item ? (
                                <>
                                    <EditItemForm item={item} />
                                    {item && item.ItemType.GoalTypeAssets && <ItemAssets item={item} />}
                                    <ItemSectionsManager item={item} sectionErrors={sectionErrors} disabled={inReview} />
                                    <ItemIssueCodeList item={item} disabled={inReview} />
                                    <ItemExpensesList item={item} disabled={inReview} />
                                    <ItemAttachments caseId={item.Case.Id} itemId={item.Id} disabled={inReview} />
                                    <ItemHistoryList item={item} />
                                    <Messages caseId={item.Case.Id} />
                                </>
                            ) : (
                                    <span>
                                        Loading... <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                )}
                        </div>
                    </div>
                </div>
                <Modal show={showRejectModal} onClose={() => this.setState({ showRejectModal: false })} style={{ overflow: 'visible' }}>
                    <div className="p-4">
                        <Form onSubmit={this.submitReject} errors={errors}>
                            <h1 className="text-lg mb-4">Reject Item #{item && item.Id}</h1>
                            <p>Please choose a reason for rejecting the following item:</p>
                            <div className="mt-4">
                                <EnumTypeSelector name="Reason" label="Reason" type="Rejection Reason Type" valueField="Description" required />
                            </div>
                            <div className="flex justify-end items-end mt-4">
                                <Button onClick={() => this.setState({ showRejectModal: false })} className="mr-4 bg-grey hover:bg-grey-dark">
                                    Cancel
                                </Button>
                                <Button type="submit" loading={updatingItem}>
                                    Reject Items
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    item: state.item.current,
    gettingItem: state.item._updating._gettingItem,
    updatingItem: state.item._updating._updatingItem,
    access_token: state.account.auth.access_token,
    errors: (state.item._errors && state.item._errors.ModelState) || {},
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

const mapDispatchToProps = dispatch => ({
    getItem: (caseId, itemId) => dispatch.item.getItem({ caseId, itemId }),
    submitItem: (caseId, itemId) => dispatch.item.submitItem({ caseId, itemId }),
    rejectItem: (caseId, itemId, Reason) => dispatch.item.rejectItem({ caseId, itemId, Reason }),
    acceptItem: (caseId, itemId) => dispatch.item.acceptItem({ caseId, itemId }),
    getAttachments: (caseId, itemId) => dispatch.item.getAttachments({ caseId, itemId }),
    getItemHistory: (caseId, itemId) => dispatch.item.getItemHistory({ caseId, itemId }),
    addMessage: dispatch.messages.addMessage,
    getMessages: caseId => dispatch.messages.getMessages(caseId),
    getRecipients: caseId => dispatch.messages.getRecipients(caseId)
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ItemPage)
);
