import React, { Component } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import shortid from 'shortid';
import numeral from 'numeral';
import moment from 'moment';

import Guard from '../../../../common/Guard';
import { roles } from '../../../../../constants';
import { accountSelectors } from '../../../../account/duck';
import { typesOperations } from '../../../../types/duck';
import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';
import { GhostForm } from '../../../../common/form/Form';
import { TableCheckbox, TableEnumTypeSelector, DatePicker, TableCurrency, FormDateRangePicker, Input } from '../../../../common/form';
import Modal from '../../../../common/Modal';
import Button from '../../../../common/Button';

class OrgExpenseRulesList extends Component {
    state = {
        isUpdating: false,
        isDeleting: false,
        errors: {}
    };

    componentDidMount() {
        this.props.updateType('ExpenseType');
    }
    componentWillUnmount() {
        if (this.expenseRuleUpdatePromise) this.expenseRuleUpdatePromise.cancel();
        if (this.expenseRuleDeletePromise) this.expenseRuleDeletePromise.cancel();
    }

    updatedExpenseRule = values => {
        const { access_token, orgId } = this.props;
        if (this.state.isDeleting) return;

        const updateObject = {
            CPU: numeral(values['CPU']).value(),
            IsPassthrough: !!values['IsPassthrough'],
            ActiveFrom: values['ActiveFrom'],
            ActiveTo: values['ActiveTo'],
            ExpenseType_Id: +values['ExpenseType_Id']
        };

        this.expenseRuleUpdatePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: false,
                        isUpdating: true
                    },
                    async () => {
                        try {
                            updateObject.Org_Id = orgId;

                            const result = values['Inherited']
                                ? await this.props.createOrgExpenseRule(updateObject)
                                : await axios.put(`${baseUrl}/api/orgs/${orgId}/expense-rules/${values['Id']}`, updateObject, {
                                      headers: { Authorization: `Bearer ${access_token}` }
                                  });

                            toast.success(`The expense rule was updated successfully.`);

                            if (values['Inherited']) {
                                this.props.expenseRuleOverrode(result.data, values['Id']);
                            } else {
                                this.props.expenseRuleUpdated(result.data);
                            }

                            this.setState({
                                ...this.state,
                                errors: {
                                    ...this.state.errors,
                                    [values['Id']]: {}
                                },
                                isDeleting: false,
                                isUpdating: false
                            });
                        } catch (e) {
                            toast.error(`There was an error updating the expense rule.`);
                            this.setState({
                                ...this.state,
                                isDeleting: false,
                                isUpdating: false,
                                errors: {
                                    ...this.state.errors,
                                    [values['Id']]: e.response.data.ModelState || {}
                                }
                            });
                        }
                    }
                );
            })
        );
    };

    deleteExpenseRule = expenseRule => {
        this.setState({
            expenseRuleToDelete: expenseRule
        });
    };

    cancelDeleteExpenseRule = () => {
        this.setState({
            expenseRuleToDelete: undefined
        });
    };

    confirmDeleteExpenseRule = () => {
        const { access_token, orgId } = this.props;
        const expenseRule = this.state.expenseRuleToDelete;

        if (this.state.isUpdating) return;

        this.expenseRuleDeletePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: true,
                        isUpdating: false,
                        expenseRuleToDelete: undefined
                    },
                    async () => {
                        try {
                            await axios.delete(`${baseUrl}/api/orgs/${orgId}/expense-rules/${expenseRule.Id}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            toast.success(`The expense rule was deleted successfully.`);

                            this.props.expenseRuleDeleted(expenseRule);
                            this.setState({
                                ...this.state,
                                errors: {
                                    ...this.state.errors,
                                    [expenseRule.Id]: {}
                                },
                                isDeleting: false,
                                isUpdating: false
                            });
                        } catch (e) {
                            toast.error(`There was an error deleting the expense rule.`);
                            this.setState({
                                ...this.state,
                                isDeleting: false,
                                isUpdating: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { expenseRules, hasPermission } = this.props;
        const { errors, isDeleting, isUpdating, expenseRuleToDelete } = this.state;

        const cantUpdate = !hasPermission([roles.Orgs.UPDATE_ORG_EXPENSE_RULE]);

        const columns = [
            {
                id: 'ExpenseType',
                Header: 'Expense Type',
                accessor: d => d.ExpenseType.Description,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                Cell: () => (
                    <TableEnumTypeSelector
                        type="ExpenseType"
                        name="ExpenseType_Id"
                        label="Expense Type"
                        readOnly={cantUpdate}
                        className="w-full"
                        submitOnBlur
                        showError
                    />
                )
            },
            {
                Header: 'CPU',
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: () => <TableCurrency type="text" name="CPU" readOnly={cantUpdate} className="text-center w-full" submitOnBlur showError />
            },
            {
                id: 'UOM',
                Header: 'UOM',
                accessor: d => (d.UOM ? d.UOM.Description : ''),
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: ({ value }) => <div className="w-full text-center">{value}</div>
            },
            {
                Header: 'Start Date',
                sortable: false,
                filterable: false,
                minWidth: 75,
                Cell: () => <DatePicker name="ActiveFrom" label="Active From" readOnly={cantUpdate} submitOnBlur showError />
            },
            {
                Header: 'End Date',
                sortable: false,
                filterable: false,
                minWidth: 75,
                Cell: () => <DatePicker name="ActiveTo" label="Active To" readOnly={cantUpdate} submitOnBlur showError />
            },

            {
                Header: 'Passthrough',
                sortable: false,
                filterable: false,
                minWidth: 50,
                Cell: () => <TableCheckbox type="checkbox" name="IsPassthrough" readOnly={cantUpdate} submitOnBlur />
            },
            {
                Header: 'Inherited',
                accessor: 'Inherited',
                sortable: false,
                minWidth: 75,
                Cell: ({ value }) => (
                    <div className="flex justify-center items-center h-full">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                ),
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'INHERITED') return row[filter.id] === true;
                    if (filter.value === 'NON-INHERITED') return row[filter.id] === false;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">
                        <select
                            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 rounded"
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'ALL'}
                        >
                            <option value="ALL">Show All</option>
                            <option value="INHERITED">Inherited</option>
                            <option value="NON-INHERITED">Non-Inherited</option>
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                )
            },
            {
                sortable: false,
                filterable: false,
                minWidth: 25,
                Cell: ({ row }) =>
                    row._original.Inherited ? (
                        <>
                            <Input type="hidden" name="inherited" value={row._original.Inherited} />
                            {isUpdating && <i className="fas fa-spinner fa-pulse" />}
                        </>
                    ) : (
                        <div className="flex justify-center items-center h-full">
                            <Guard permissions={[roles.Orgs.DELETE_ORG_EXPENSE_RULE]}>
                                <span
                                    className={`text-red-light ${isDeleting || isUpdating ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                    onClick={e => {
                                        e.preventDefault();
                                        if (isDeleting || isUpdating) return;
                                        this.deleteExpenseRule(row._original);
                                    }}
                                >
                                    <i className={isDeleting ? 'fas fa-spinner fa-pulse' : 'fas fa-trash-alt'} />
                                </span>
                            </Guard>
                        </div>
                    )
            }
        ];

        return (
            <>
                <div className="mt-4 table-date-picker">
                    <ReactTable
                        filterable
                        data={expenseRules}
                        columns={columns}
                        showPageSizeOptions={false}
                        showPagination={expenseRules.length > 10}
                        pageSize={Math.min(expenseRules.length, 10)}
                        defaultSorted={[{ id: 'ExpenseType', desc: false }]}
                        defaultFiltered={[{ id: 'Inherited', value: 'NON-INHERITED' }]}
                        getTrGroupProps={(state, rowInfo, column, instance) => ({
                            formDefaults: rowInfo
                                ? {
                                      ...rowInfo.original,
                                      ExpenseType_Id: rowInfo.original.ExpenseType.Id
                                  }
                                : {},
                            errors: rowInfo ? errors[rowInfo.original.Id] : {},
                            className: moment(rowInfo && rowInfo.original.ActiveTo).isBefore(moment())
                                ? 'border-grey-lighter border-l-4'
                                : 'border-green-lighter border-l-4'
                        })}
                        TrGroupComponent={({ formDefaults, errors, ...rest }) => (
                            <GhostForm
                                key={`form-${formDefaults ? formDefaults['Id'] : shortid.generate()}`}
                                defaultValues={formDefaults}
                                errors={errors}
                                onSubmit={this.updatedExpenseRule}
                            >
                                <ReactTableDefaults.TrGroupComponent {...rest} />
                            </GhostForm>
                        )}
                    />
                </div>
                <Modal show={expenseRuleToDelete} onClose={this.cancelDeleteExpenseRule} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>Remove this expense rule? This action cannot be undone.</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={this.cancelDeleteExpenseRule}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.confirmDeleteExpenseRule}>
                            Remove
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});
const mapDispatchToProps = dispatch => ({
    updateType: type => dispatch(typesOperations.updateType(type)),
    createOrgExpenseRule: dispatch.orgExpenseRule.createOrgExpenseRule
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgExpenseRulesList);
