import React, { Component } from 'react';
import Select from 'react-select';
import TypeSelector from '../TypeSelector';
import FetchSelector from '../FetchSelector';
import shortid from 'shortid';
import moment from 'moment';
import Cleave from 'cleave.js/react';
import { DateRangePicker } from 'react-dates';
import * as cleave_addons from 'cleave.js/dist/addons/cleave-phone.us';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { FormConsumer } from './Form';
import { YearMonthForm, fromMonth, toMonth } from '../YearMonthForm';
import CustomCheckbox from '../CustomCheckbox';

export const Header = props => <h1 className="flex items-center text-primary text-xl uppercase tracking-wide font-medium">{props.children}</h1>;
export const Divider = () => <div className="w-full mt-2 border-b-2 border-solid border-accent" />;
export const Section = ({ children }) => <div className="mt-8">{children}</div>;
export const ValidationError = ({ children = [] }) => (
    <p className="text-red-light text-xs mt-2">{Array.isArray(children) ? children.map((c, index) => <span key={index}>{c} </span>) : ''}</p>
);

export const Label = ({ children, instructions, muted }) => (
    <label className={`block text-${muted ? 'grey-dark' : 'primary'} text-sm mb-2 mr-2`}>
        {children}
        {instructions ? (
            <>
                <br />
                <span className="text-sm text-grey-dark italic block">{instructions}</span>
            </>
        ) : (
            <></>
        )}
    </label>
);

export class HandleUnmount extends Component {
    componentWillUnmount() {
        // TODO: React Table only unmounts the last row when removing a row
        // so this fires for the wrong field. Fix this later?
        //if (this.props.handleUnmount) this.props.handleUnmount();
    }

    render = () => {
        let { children } = this.props;

        return <>{children}</>;
    };
}
export class TableCheckbox extends Component {
    render = () => {
        let { name, label, type, readOnly, submitOnBlur, ...rest } = this.props;

        return (
            <div className="flex flex-grow justify-center items-center h-full">
                <FormConsumer>
                    {({ values, setValue, removeValue, forceSubmit }) => (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            <CustomCheckbox
                                {...rest}
                                placeholder={label}
                                type={type}
                                checked={!!values[name]}
                                disabled={readOnly}
                                onChange={event => {
                                    setValue(name, event.target.checked, () => {
                                        if (submitOnBlur) forceSubmit();
                                    });
                                }}
                            />
                        </HandleUnmount>
                    )}
                </FormConsumer>
            </div>
        );
    };
}

export class Input extends Component {
    state = {
        error: false
    };

    render = () => {
        let { name, label, type, disabled, required, readOnly, submitOnBlur, hideLabel, className, onBlur, onChange, ...rest } = this.props;
        return (
            <>
                {!hideLabel ? (
                    <Label>
                        {label}
                        {required && !readOnly ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                ) : (
                    <></>
                )}
                <div className={className || ''}>
                    <FormConsumer>
                        {({ errors = {}, values, setValue, removeValue, forceSubmit }) => {
                            return (
                                <HandleUnmount handleUnmount={() => removeValue(name)}>
                                    <input
                                        autoComplete="nope"
                                        name={name}
                                        {...rest}
                                        required={required}
                                        value={!values[name] ? '' : values[name]}
                                        placeholder={readOnly ? '' : label}
                                        onBlur={e => {
                                            this.setState({ error: !e.target.checkValidity() });
                                            if (submitOnBlur) forceSubmit();
                                            if (onBlur) onBlur(name, e.target.value);
                                        }}
                                        type={type}
                                        onChange={event => {
                                            event.preventDefault();
                                            event.persist();
                                            setValue(name, event.target.value, () => {
                                                if (onChange) onChange(name, event.target.value);
                                            });
                                        }}
                                        readOnly={readOnly}
                                        disabled={disabled}
                                        className={`${!readOnly ? 'input-field' : 'font-medium text-primary'} ${
                                            this.state.error || (errors && errors[name]) ? 'border-red-light' : ''
                                        }`}
                                    />
                                    <ValidationError>{errors ? errors[name] : []}</ValidationError>
                                </HandleUnmount>
                            );
                        }}
                    </FormConsumer>
                </div>
            </>
        );
    };
}
export class TableField extends Component {
    state = {
        error: false
    };

    render() {
        let { name, label, type, readOnly, showError, disabled, className, submitOnBlur, ...rest } = this.props;
        return (
            <FormConsumer>
                {({ values, setValue, errors, removeValue, forceSubmit }) => {
                    return (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            <input
                                name={name}
                                value={values[name] || ''}
                                placeholder={label}
                                onBlur={e => {
                                    if (readOnly) return;
                                    this.setState({ error: !e.target.checkValidity() });
                                    if (submitOnBlur) forceSubmit();
                                }}
                                type={type}
                                onChange={event => {
                                    if (readOnly) return;
                                    event.preventDefault();
                                    setValue(name, event.target.value);
                                }}
                                onKeyUp={event => {
                                    if (readOnly) return;
                                    if (event.which === 13 && submitOnBlur) forceSubmit();
                                }}
                                readOnly={readOnly}
                                disabled={disabled}
                                className={`${!readOnly ? 'input-field' : 'text-primary'} ${
                                    this.state.error || (errors && errors[name]) ? 'border-red-light' : ''
                                } ${className}`}
                                {...rest}
                            />
                            {showError ? <ValidationError>{errors ? errors[name] : []}</ValidationError> : <></>}
                        </HandleUnmount>
                    );
                }}
            </FormConsumer>
        );
    }
}

export class TableEnumTypeSelector extends Component {
    render = () => {
        const { input, label, type, name, readOnly, showError, submitOnBlur, required, showHidden } = this.props;
        return (
            <FormConsumer>
                {({ errors = {}, values, setValue, removeValue, forceSubmit }) => (
                    <HandleUnmount handleUnmount={() => removeValue(name)}>
                        <TypeSelector
                            {...input}
                            showHidden={showHidden}
                            value={values[name] || ''}
                            label={label}
                            type={type}
                            required={required}
                            onChange={event => {
                                if (readOnly) return;
                                event.preventDefault();

                                setValue(name, +event.target.value, () => {
                                    if (submitOnBlur) forceSubmit();
                                });
                            }}
                            readOnly={readOnly}
                            hideLabel
                        />
                        {showError ? <ValidationError>{errors ? errors[name] : []}</ValidationError> : <></>}
                    </HandleUnmount>
                )}
            </FormConsumer>
        );
    };
}

export class EnumTypeSelector extends Component {
    render = () => {
        const { customKey, input, label, type, name, required, allowBlank, readOnly, showHidden, valueField, showShort } = this.props;
        return (
            <FormConsumer>
                {({ errors = {}, values, setValue, removeValue }) => (
                    <HandleUnmount handleUnmount={() => removeValue(name)}>
                        <TypeSelector
                            {...input}
                            customKey={customKey}
                            showHidden={showHidden}
                            allowBlank={allowBlank}
                            value={values[name] || ''}
                            label={label}
                            type={type}
                            required={required}
                            onChange={event => {
                                setValue(name, event.target.value);
                            }}
                            showShort={showShort}
                            valueField={valueField}
                            readOnly={readOnly}
                        />
                        <ValidationError>{errors ? errors[name] : []}</ValidationError>
                    </HandleUnmount>
                )}
            </FormConsumer>
        );
    };
}

export const MultiSelect = ({ label, type, name, options, disabled, required, ...rest }) => {
    return (
        <>
            <Label>
                {label}
                {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
            </Label>
            <FormConsumer>
                {({ errors, values, setValue, removeValue }) => (
                    <HandleUnmount handleUnmount={() => removeValue(name)}>
                        <Select
                            {...rest}
                            isMulti
                            defaultValue={values[name]}
                            options={options.sort((a, b) => {
                                const aLabel = rest['getOptionLabel'] ? rest['getOptionLabel'](a) : a.Name;
                                const bLabel = rest['getOptionLabel'] ? rest['getOptionLabel'](b) : b.Name;
                                if (aLabel.toUpperCase() > bLabel.toUpperCase()) return 1;
                                if (aLabel.toUpperCase() < bLabel.toUpperCase()) return -1;
                                return 0;
                            })}
                            onChange={value => {
                                setValue(name, value);
                            }}
                            isDisabled={disabled}
                        />
                        <ValidationError>{errors ? errors[name] : []}</ValidationError>
                    </HandleUnmount>
                )}
            </FormConsumer>
        </>
    );
};

export const CustomSelect = ({ label, type, name, options, disabled, required, submitOnEnter, ...rest }) => {
    return (
        <>
            <Label>
                {label}
                {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
            </Label>
            <FormConsumer>
                {({ errors, values, setValue, removeValue, forceSubmit }) => (
                    <HandleUnmount handleUnmount={() => removeValue(name)}>
                        <Select
                            {...rest}
                            required={required}
                            value={values[name]}
                            options={options}
                            onChange={value => {
                                setValue(name, value);
                            }}
                            isDisabled={disabled}
                            onKeyDown={e => {
                                if (submitOnEnter && e.nativeEvent.code === 'Enter') forceSubmit();
                            }}
                            autoFocus={true}
                        />
                        <ValidationError>{errors ? errors[name] : []}</ValidationError>
                    </HandleUnmount>
                )}
            </FormConsumer>
        </>
    );
};

export const FetchSelectorConsumer = ({ label, type, name, disabled, onChange, hidden, ...rest }) => {
    if (hidden) return null;
    return (
        <FormConsumer>
            {({ errors = {}, values, setValue, removeValue }) => (
                <HandleUnmount handleUnmount={() => removeValue(name)}>
                    <FetchSelector
                        {...rest}
                        errors={errors}
                        label={label}
                        name={name}
                        value={values[name]}
                        onChange={event => {
                            event.preventDefault();
                            event.persist();
                            setValue(name, event.target.value, () => {
                                if (onChange) onChange(name, event.target.value);
                            });
                        }}
                        disabled={disabled}
                        isDisabled={disabled}
                    />
                </HandleUnmount>
            )}
        </FormConsumer>
    );
};

export class TextArea extends Component {
    render = () => {
        let { label, type, name, required, className, ...rest } = this.props;
        return (
            <div className="flex flex-col sm:flex-row">
                <div className="mb-4 flex-grow">
                    <Label>
                        {label}
                        {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <FormConsumer>
                        {({ values, setValue, removeValue }) => {
                            return (
                                <HandleUnmount handleUnmount={() => removeValue(name)}>
                                    <textarea
                                        {...rest}
                                        name={name}
                                        value={values[name] || ''}
                                        required={required}
                                        onChange={event => {
                                            event.preventDefault();
                                            setValue(name, event.target.value);
                                        }}
                                        className={`input-field ${className}`}
                                    />
                                </HandleUnmount>
                            );
                        }}
                    </FormConsumer>
                </div>
            </div>
        );
    };
}

export class PhoneNumber extends Component {
    render() {
        let { label, type, name, required, readOnly, submitOnBlur, ...rest } = this.props;
        return (
            <FormConsumer>
                {({ values, setValue, removeValue, errors, forceSubmit }) => {
                    return (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            {label ? (
                                <Label>
                                    {label}
                                    {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label>
                            ) : (
                                <></>
                            )}
                            <Cleave
                                {...rest}
                                name={name}
                                options={{ phone: true, phoneRegionCode: 'US', delimiter: '-' }}
                                value={values[name] || ''}
                                required={required}
                                onChange={event => {
                                    setValue(name, event.target.value);
                                }}
                                readOnly={readOnly}
                                onBlur={event => {
                                    submitOnBlur && forceSubmit();
                                }}
                                className={`${!readOnly ? 'input-field' : 'font-medium text-primary font-mono'} ${
                                    errors[name] ? 'border-red-light' : ''
                                }`}
                            />
                            <ValidationError>{errors ? errors[name] : []}</ValidationError>
                        </HandleUnmount>
                    );
                }}
            </FormConsumer>
        );
    }
}

export class Currency extends Component {
    render() {
        let { label, type, name, required, readOnly, submitOnBlur, ...rest } = this.props;
        return (
            <FormConsumer>
                {({ values, setValue, removeValue, errors, forceSubmit }) => {
                    return (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            {label ? (
                                <Label>
                                    {label}
                                    {required && !readOnly ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label>
                            ) : (
                                <></>
                            )}
                            <Cleave
                                {...rest}
                                name={name}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    prefix: '$'
                                }}
                                value={values[name] === undefined ? '' : values[name]}
                                required={required}
                                onChange={event => {
                                    setValue(name, event.target.value);
                                }}
                                readOnly={readOnly}
                                onBlur={event => {
                                    submitOnBlur && forceSubmit();
                                }}
                                className={`${!readOnly ? 'input-field' : 'font-medium text-primary font-mono'} ${
                                    errors[name] ? 'border-red-light' : ''
                                }`}
                            />
                            <ValidationError>{errors ? errors[name] : []}</ValidationError>
                        </HandleUnmount>
                    );
                }}
            </FormConsumer>
        );
    }
}

export class TableCurrency extends Component {
    render() {
        let { label, type, name, required, className, readOnly, showError, submitOnBlur, ...rest } = this.props;
        return (
            <FormConsumer>
                {({ values, setValue, removeValue, errors, forceSubmit }) => {
                    return (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            <Cleave
                                {...rest}
                                name={name}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    prefix: '$'
                                }}
                                value={values[name] === undefined ? '' : values[name]}
                                required={required}
                                onChange={event => {
                                    setValue(name, event.target.value);
                                }}
                                onKeyUp={event => {
                                    if (readOnly) return;
                                    if (event.which === 13 && submitOnBlur) forceSubmit();
                                }}
                                readOnly={readOnly}
                                onBlur={event => {
                                    submitOnBlur && forceSubmit();
                                }}
                                className={`${!readOnly ? 'input-field' : 'text-primary font-mono'} ${
                                    errors[name] ? 'border-red-light' : ''
                                } ${className}`}
                            />
                            {showError ? <ValidationError>{errors ? errors[name] : []}</ValidationError> : <></>}
                        </HandleUnmount>
                    );
                }}
            </FormConsumer>
        );
    }
}

export class CurrencyPercentage extends Component {
    render() {
        let { label, type, name, required, readOnly, submitOnBlur, isPercentage, ...rest } = this.props;
        return (
            <FormConsumer>
                {({ values, setValue, removeValue, errors, forceSubmit }) => {
                    return (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            {label ? (
                                <Label>
                                    {label}
                                    {required && !readOnly ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label>
                            ) : (
                                <></>
                            )}
                            <Cleave
                                {...rest}
                                name={name}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    prefix: isPercentage ? '' : ''
                                }}
                                value={values[name] === undefined ? '' : values[name]}
                                required={required}
                                onChange={event => {
                                    setValue(name, event.target.value);
                                }}
                                readOnly={readOnly}
                                onBlur={event => {
                                    submitOnBlur && forceSubmit();
                                }}
                                className={`${!readOnly ? 'input-field' : 'font-medium text-primary'} ${
                                    !isPercentage && readOnly ? 'font-mono' : ''
                                } ${errors[name] ? 'border-red-light' : ''}`}
                            />
                            <ValidationError>{errors ? errors[name] : []}</ValidationError>
                        </HandleUnmount>
                    );
                }}
            </FormConsumer>
        );
    }
}

export class DatePicker extends Component {
    state = { month: new Date() };

    handleYearMonthChange = month => {
        this.setState({ month });
    };

    render() {
        let {
            label,
            type,
            name,
            required,
            showError,
            submitOnBlur,
            readOnly,
            disabled,
            showLabel,
            dayPickerProps,
            onChange,
            placeholder,
            ...rest
        } = this.props;
        const { month } = this.state;

        return (
            <div className="relative">
                <FormConsumer>
                    {({ values, setValue, removeValue, forceSubmit, errors }) => {
                        return (
                            <div className="">
                                <HandleUnmount handleUnmount={() => removeValue(name)}>
                                    {showLabel ? (
                                        <Label>
                                            {label}
                                            {required && !readOnly ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                        </Label>
                                    ) : (
                                        <></>
                                    )}
                                    {!readOnly ? (
                                        <>
                                            <DayPickerInput
                                                {...rest}
                                                dayPickerProps={{
                                                    ...dayPickerProps,
                                                    captionElement: ({ date, localeUtils }) => (
                                                        <YearMonthForm date={date} localeUtils={localeUtils} onChange={this.handleYearMonthChange} />
                                                    ),
                                                    fromMonth: fromMonth,
                                                    toMonth: toMonth,
                                                    month: month
                                                }}
                                                formatDate={formatDate}
                                                overlayComponent={({ children, ...props }) => (
                                                    <div
                                                        {...props}
                                                        className=""
                                                        onClick={e => {
                                                            if (e.target.className === 'DayPickerInput-OverlayWrapper') {
                                                                props.input.blur();
                                                            }
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                )}
                                                clickUnselectsDay={true}
                                                parseDate={parseDate}
                                                placeholder={placeholder || `${formatDate(new Date(), 'MM/DD/YYYY')}`}
                                                format="MM/DD/YYYY"
                                                value={values[name] ? formatDate(values[name], 'MM/DD/YYYY') : ''}
                                                inputProps={{ required, disabled }}
                                                onDayChange={day => {
                                                    setValue(
                                                        name,
                                                        day
                                                            ? moment(day)
                                                                  .utc()
                                                                  .format()
                                                            : null,
                                                        () => {
                                                            if (onChange)
                                                                onChange(
                                                                    name,
                                                                    day
                                                                        ? moment(day)
                                                                              .utc()
                                                                              .format()
                                                                        : null
                                                                );
                                                        }
                                                    );
                                                }}
                                                onDayPickerHide={() => {
                                                    if (submitOnBlur) forceSubmit();
                                                }}
                                            />
                                            {showError ? <ValidationError>{errors ? errors[name] : []}</ValidationError> : <></>}
                                        </>
                                    ) : values[name] ? (
                                        formatDate(values[name], 'MM/DD/YYYY')
                                    ) : (
                                        ''
                                    )}
                                </HandleUnmount>
                            </div>
                        );
                    }}
                </FormConsumer>
            </div>
        );
    }
}
export class FormDateRangePicker extends Component {
    state = {
        focusedSearchDates: null
    };

    render() {
        const { startName, endName, label, readOnly, required, showLabel, showError, submitOnBlur, startDateId, endDateId, onChange } = this.props;

        return (
            <div>
                <FormConsumer>
                    {({ values, setValue, removeValue, forceSubmit, errors }) => (
                        <HandleUnmount handleUnmount={() => removeValue(startName, () => removeValue(endName))}>
                            {showLabel ? (
                                <Label>
                                    {label}
                                    {required && !readOnly ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label>
                            ) : (
                                <></>
                            )}
                            <DateRangePicker
                                readOnly={readOnly}
                                startDate={values[startName] ? moment(values[startName]) : null}
                                startDateId={startDateId || shortid.generate()}
                                endDate={values[endName] ? moment(values[endName]) : null}
                                endDateId={endDateId || shortid.generate()}
                                onDatesChange={({ startDate, endDate }) => {
                                    setValue(startName, startDate, () => {
                                        if (onChange) onChange(startName, startDate);
                                        setValue(endName, endDate, () => {
                                            if (onChange) onChange(endName, endDate);
                                        });
                                    });
                                }}
                                focusedInput={this.state.focusedSearchDates || null}
                                onFocusChange={focusedInput => {
                                    this.setState(
                                        {
                                            focusedSearchDates: focusedInput
                                        },
                                        () => {
                                            if (!focusedInput && submitOnBlur) {
                                                setTimeout(() => forceSubmit(), 100);
                                            }
                                        }
                                    );
                                }}
                                enableOutsideDays={true}
                                isOutsideRange={() => false}
                                withPortal={true}
                            />
                            {showError ? (
                                <ValidationError>{errors ? [...(errors[startName] || []), ...(errors[endName] || [])] : []}</ValidationError>
                            ) : (
                                <></>
                            )}
                        </HandleUnmount>
                    )}
                </FormConsumer>
            </div>
        );
    }
}

export class Checkbox extends Component {
    render = () => {
        let { name, label, type, className, readOnly, submitOnBlur, ...rest } = this.props;
        return (
            <div className={`flex flex-grow items-center h-full ${className || ''}`}>
                <FormConsumer>
                    {({ values, setValue, removeValue, forceSubmit }) => (
                        <HandleUnmount handleUnmount={() => removeValue(name)}>
                            <label className="md:w-2/3 flex items-center">
                                <CustomCheckbox
                                    {...rest}
                                    className="mr-2 leading-tight"
                                    placeholder={label}
                                    disabled={readOnly}
                                    checked={values[name] || false}
                                    defaultChecked={values[name] || false}
                                    onChange={event => {
                                        setValue(name, event.target.checked);
                                        if (submitOnBlur) forceSubmit();
                                    }}
                                />
                                <span className="text-primary text-sm">{label}</span>
                            </label>
                        </HandleUnmount>
                    )}
                </FormConsumer>
            </div>
        );
    };
}
