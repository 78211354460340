import logo from './images/IDS-Logo_Icon.png';
import logoWord from './images/IDS-Logo_Word.png';

//const baseUrl = 'https://testcaas-api.informationdiscovery.net/';
//const baseUrl = 'https://caasnew.azurewebsites.net/';
//const baseUrl = 'http://localhost/caas/';
const baseUrl = 'https://caas-api.informationdiscovery.net/';
//const baseUrl = '//case-api.onstaging.it';

const disclaimer = 'Access to this page is restricted to Information Discovery Services authorized personnel only. Do not attempt to log into this site without prior authentication.';
const version = '1.0.0hf-hub';
export { baseUrl, logo, logoWord, disclaimer, version };
