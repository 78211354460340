import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import numeral from 'numeral';
import moment from 'moment';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import ItemExpenseModal from './ItemExpenseModal';

class ItemExpensesList extends Component {
    state = {
        showModal: false,
        expense: undefined,
        isView: false
    };

    componentDidMount() {
        const { item } = this.props;
        this.props.getExpenses(item.Case.Id, item.Id);
    }

    openModal = (expense = undefined, isView = false) => {
        this.setState({
            ...this.state,
            showModal: true,
            expense,
            isView
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showModal: false,
            expense: undefined,
            isView: false
        });
    };

    deleteExpense = expense => {
        this.setState({
            expenseToDelete: expense
        });
    };

    confirmDeleteExpense = () => {
        const { item } = this.props;
        const expense = this.state.expenseToDelete;
        this.setState(
            {
                expenseToDelete: undefined
            },
            () => this.props.deleteExpense(item.Case.Id, item.Id, expense)
        );
    };

    cancelDeleteExpense = () => {
        this.setState({
            expenseToDelete: undefined
        });
    };

    render() {
        const { gettingExpenses, expenses, item, deletingExpense, disabled } = this.props;
        const { showModal, expense, isView, expenseToDelete } = this.state;
        const columns = [
            {
                id: 'ExpenseType',
                Header: 'Expense Type',
                accessor: d => d.ExpenseType.Description,
                Cell: ({ value }) => <div className="flex justify-center items-center h-full">{value}</div>
            },
            {
                id: 'IncurredBy',
                Header: 'Incurred By',
                accessor: d => (d.IncurredBy ? `${d.IncurredBy.FirstName} ${d.IncurredBy.LastName}` : ''),
                Cell: ({ value }) => <div className="flex justify-center items-center h-full">{value}</div>
            },
            {
                Header: 'Total Amount',
                accessor: 'TotalAmount',
                minWidth: 75,
                Cell: ({ value, row }) => (
                    <div className="flex flex-col justify-center items-center font-mono">
                        {row._original.Override ? (
                            <>
                                <span className="text-grey-dark line-through text-sm">{numeral(value).format('$0,0.00')}</span>
                                <span>{numeral(row._original.Override).format('$0,0.00')}</span>
                            </>
                        ) : (
                            numeral(value).format('$0,0.00')
                        )}
                    </div>
                )
            },
            {
                Header: 'Reimbursable',
                accessor: 'Reimbursable',
                Cell: ({ value }) => (
                    <div className="flex justify-center items-center h-full">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                Header: 'Created On',
                accessor: 'CreatedDate',
                minWidth: 75,
                Cell: ({ value }) => <div className="flex justify-center items-center h-full">{moment.utc(value).format('MM/DD/YYYY')}</div>
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <span
                            className={`mr-4 ${disabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : 'cursor-pointer'}`}
                            onClick={e => {
                                e.preventDefault();
                                this.openModal(row._original);
                            }}
                        >
                            <i className="fas fa-pencil-alt" />
                        </span>
                        <span
                            className="cursor-pointer mr-4 cursor-pointer"
                            onClick={e => {
                                e.preventDefault();
                                this.openModal(row._original, true);
                            }}
                        >
                            <i className="fas fa-eye" />
                        </span>
                        <span
                            className={`text-red-light ${
                                deletingExpense[row._original.Id] || disabled ? 'cursor-not-allowed opacity-50 pointer-events-none' : 'cursor-pointer'
                            }`}
                            onClick={e => {
                                e.preventDefault();
                                this.deleteExpense(row._original);
                            }}
                        >
                            <i className={`fas ${deletingExpense[row._original.Id] ? 'fa-spinner fa-pulse' : 'fa-trash-alt'}`} />
                        </span>
                    </div>
                )
            }
        ];

        return (
            <>
                <div className="mt-8 mb-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="font-bold text-primary">Expenses</div>
                        <Button inverse onClick={e => this.openModal()} disabled={disabled}>
                            Add Expense
                        </Button>
                    </div>
                    {!gettingExpenses ? (
                        expenses.length > 0 ? (
                            <ReactTable
                                pageSize={expenses.length}
                                data={expenses}
                                columns={columns}
                                showPagination={false}
                                showPageSizeOptions={false}
                            />
                        ) : (
                            <div className="text-primary">This item has no expenses.</div>
                        )
                    ) : (
                        <span>
                            Loading expenses... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                </div>
                <Modal show={showModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showModal ? <ItemExpenseModal item={item} expense={expense} isView={isView} closeModal={this.closeModal} /> : <></>}
                    </div>
                </Modal>

                <Modal show={expenseToDelete} onClose={this.cancelDeleteExpense} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>Delete this expense? This action cannot be undone.</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={this.cancelDeleteExpense}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.confirmDeleteExpense}>
                            Delete Section
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    gettingExpenses: state.item._updating._gettingExpenses,
    deletingExpense: state.item._updating._deletingExpense,
    expenses: state.item.expenses
});

const mapDispatchToProps = dispatch => ({
    getExpenses: (caseId, itemId) => dispatch.item.getExpenses({ caseId, itemId }),
    deleteExpense: (caseId, itemId, expense) => dispatch.item.deleteExpense({ caseId, itemId, expense })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemExpensesList);
