import React, { Component } from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux';

import { typesSelectors, typesOperations } from '../types/duck';
import { Label } from './form';
import { optional } from '../../utils';

class TypeSelector extends Component {
    state = {};

    componentDidMount() {
        if (this.props.shouldUpdate && !this.props.isLoading) this.props.updateType();
    }

    shouldComponentUpdate(props, state) {
        return true;
    }

    render() {
        const {
            customKey,
            label,
            labelOptions,
            name,
            errors = {},
            onChange,
            value,
            options,
            disabled,
            readOnly,
            required,
            className,
            hideLabel,
            allowBlank,
            instructions,
            showHidden,
            valueField,
            showShort
        } = this.props;
        const option = options.find(o => o && +o.Id === value);

        return (
            <div className={className}>
                {label && !hideLabel ? (
                    <Label instructions={instructions} {...labelOptions}>
                        {label}
                        {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                ) : (
                        ''
                    )}
                <div className="relative">
                    {!readOnly ? (
                        <>
                            <select
                                key={customKey || shortid.generate()}
                                className={`${
                                    !readOnly
                                        ? 'input-field'
                                        : hideLabel
                                            ? 'text-primary appearance-none'
                                            : 'font-medium text-primary appearance-none py-3 px-2'
                                    } ${this.state.error || (errors && errors[name]) ? 'border-red-light' : ''}`}
                                name={name}
                                placeholder=""
                                value={value || ''}
                                onChange={onChange}
                                disabled={disabled || readOnly}
                                required={required}
                                readOnly={readOnly}
                            >
                                {!options ? (
                                    <option value={value} disabled>
                                        Loading...
                                    </option>
                                ) : (
                                        options
                                            .filter(option => option && (!!showHidden || !option.Hidden))
                                            .sort((a, b) => {
                                                var x = optional(a, s => s[showShort ? 'Short' : 'Description'].toLowerCase(), '');
                                                var y = optional(b, s => s[showShort ? 'Short' : 'Description'].toLowerCase(), '');
                                                if (x < y) {
                                                    return -1;
                                                }
                                                if (x > y) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .reduce(
                                                (options, option = {}) => [
                                                    ...options,
                                                    <option
                                                        key={`${shortid.generate()}-${option.Id}-${option.Short}`}
                                                        value={valueField ? option[valueField] : +option.Id}
                                                    >
                                                        {showShort && option.Short != option.Description
                                                            ? `${option.Short} (${option.Description})`
                                                            : option.Description}
                                                        {option.Hidden ? ` (inactive)` : ``}
                                                    </option>
                                                ],
                                                [
                                                    allowBlank ? (
                                                        <option key={`${shortid.generate()}`} value={0}>
                                                            Select a {label.toLowerCase()}
                                                            ...
                                                    </option>
                                                    ) : (
                                                            <option key={`${shortid.generate()}`} value="" disabled>
                                                                Select a {label.toLowerCase()}
                                                                ...
                                                    </option>
                                                        )
                                                ]
                                            )
                                    )}
                            </select>
                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </>
                    ) : (
                            <span className="leading-tight text-primary appearance-none py-3 px-2">{option ? option.Description : 'Not found...'}</span>
                        )}
                </div>
                {errors[name] ? <p className="text-red text-xs italic">{errors[name]}</p> : null}
            </div>
        );
    }
}

const mapStateToProps = (state, { type }) => ({
    shouldUpdate: typesSelectors.shouldUpdateType(state.types, type),
    isLoading: typesSelectors.typeIsLoading(state.types, type),
    options: (state.types[type] && state.types[type].Types) || []
});

const mapDispatchToProps = (dispatch, { type }) => ({
    updateType: () => dispatch(typesOperations.updateType(type))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeSelector);
