import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { Input, ValidationError } from '../common/form';
import Button from '../common/Button';
import { Form } from '../common/form/Form';
import { baseUrl } from '../../config';

export class ResetPassword extends Component {
    state = {
        passwordChanged: false,
        loading: false,
        errors: {},
        message: []
    };

    onSubmit = async values => {
        const parsed = qs.parse(this.props.location.search);
        let code = '';
        if (Array.isArray(parsed.code)) {
            code = parsed.code.pop();
        } else {
            code = parsed.code.split(',').pop();
        }

        this.setState(
            {
                loading: true
            },
            async () => {
                try {
                    await axios.post(`${baseUrl}/api/user/reset-password`, { ...values, Code: code });
                    toast.success('Your password has been changed.');
                    this.setState({
                        passwordChanged: true,
                        loading: false
                    });
                } catch (error) {
                    if (error.response && error.response.data) {
                        this.setState({
                            ...this.state,
                            loading: false,
                            errors: error.response.data.ModelState,
                            message: [error.response.data.Message]
                        });
                    } else {
                        this.setState({ ...this.state, loading: false, errors: {}, message: ['Unable to reset password.'] });
                    }
                }
            }
        );
    };

    render() {
        const { loading, passwordChanged, errors, message } = this.state;

        if (passwordChanged) {
            return (
                <Redirect
                    to={{
                        pathname: '/login'
                    }}
                />
            );
        }
        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="mb-2 text-base text-primary">Reset your password.</h1>
                        <Form onSubmit={this.onSubmit} errors={errors}>
                            <div className="mb-4">
                                <Input name="Username" label="Username" required={true} />
                            </div>
                            <div className="mb-4">
                                <Input name="Password" label="New Password" required={true} type="password" />
                            </div>
                            <div className="mb-4">
                                <Input name="ConfirmPassword" label="Confirm Password" required={true} type="password" />
                            </div>
                            <div className="flex items-center justify-between">
                                <Button type="submit" loading={loading}>
                                    Submit
                                </Button>

                                <Link
                                    to="/login"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </Form>
                        <ValidationError>{message}</ValidationError>
                    </div>

                    <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
