import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import SortableTree from 'react-sortable-tree';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { roles } from '../../../constants';

import { orgOperations } from '../duck';
import { buildSortableOrgTree, expandTreeToChild } from '../../../utils';

// https://stackoverflow.com/a/4819886/1601953
const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);

class SortableOrgTree extends Component {
    state = {
        orgTree: [],
        previousOrgs: undefined
    };

    static getDerivedStateFromProps({ gettingOrgs, orgs, currentOrgId }, prevState) {
        if (!_.isEqual(prevState.previousOrgs, orgs)) {
            let orgTree = gettingOrgs ? [] : [buildSortableOrgTree(orgs, orgs[0])];
            if (orgTree.length > 0) orgTree[0].expanded = true;
            if (currentOrgId) orgTree = expandTreeToChild(orgTree, currentOrgId);
            return { ...prevState, orgTree, previousOrgs: orgs };
        }

        return {
            ...prevState, previousOrgs: orgs
        };
    }

    componentDidMount() {
        this.props.getOrgs();
    }

    orgTreeUpdated = treeData => {
        this.setState({
            orgTree: treeData
        });
    };

    onOrgMoved = ({ node, nextParentNode }) => {
        this.props.updateOrgParent(node, nextParentNode);
    };

    getOrgName = () => {
        const {
            location: { pathname },
            orgs
        } = this.props;
        const splitPathname = pathname.split('/');

        if (splitPathname.length < 3) return null;
        const org = orgs.find(o => o.Id === +splitPathname[2]);
        return org ? org.Name : null;
    };

    render() {
        const { gettingOrgs, userRoles } = this.props;
        const { orgTree } = this.state;

        return (
            <>
                <div id="org-list-tree" className="p-2" style={{ height: '100%' }}>
                    {!gettingOrgs ? (
                        <SortableTree
                            treeData={orgTree}
                            onChange={this.orgTreeUpdated}
                            getNodeKey={({ node }) => node.id}
                            onMoveNode={this.onOrgMoved}
                            canDrag={
                                userRoles.includes(roles.Orgs.CHANGE_PARENT_ORGANIZATION) || userRoles.includes(roles.General.SYSTEM_ADMINISTRATOR)
                            }
                            canDrop={({ nextParent }) => !!nextParent}
                            theme={FileExplorerTheme}
                            innerStyle={{ padding: '0px 0.5rem', overflow: 'auto' }}
                            generateNodeProps={({ node }) => ({
                                title: (
                                    <NavLink
                                        className="no-underline text-grey-darker flex items-center"
                                        activeClassName="font-bold selected-org"
                                        to={`/orgs/${node.id}`}
                                    >
                                        {node.title}
                                        {node.hidden ? (
                                            <span className="text-xxs bg-grey text-white px-2 py-1 rounded uppercase ml-4">Inactive</span>
                                        ) : (
                                                <></>
                                            )}
                                    </NavLink>
                                )
                            })}
                            scaffoldBlockPxWidth={20}
                            searchQuery={this.getOrgName()}
                            onlyExpandSearchedNodes={false}
                        />
                    ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    orgs: state.orgs.orgs,
    gettingOrgs: state.orgs._updating._gettingOrgs,
    userRoles: state.account.permissions.roles
});

const mapDispatchToProps = dispatch => ({
    getOrgs: () => dispatch(orgOperations.getOrgs()),
    updateOrgParent: (org, parentOrg) => dispatch(orgOperations.updateOrgParent(org, parentOrg))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DragDropContext(isTouchDevice ? TouchBackend : HTML5Backend)(SortableOrgTree))
);
