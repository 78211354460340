import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Types from './Types';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

export default () => (
    <Route key="lists-router" path="/lists">
        <Switch key="lists-router-switch">
            <Guard key='lists-route' exact path='/lists' notFound={NotFound} permissions={[roles.Types.VIEW_TYPE]}>
                <AuthRoute exact path='/lists' component={Types} />
            </Guard>
        </Switch>
    </Route>
);