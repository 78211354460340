import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { init } from '@rematch/core';
import { persistStore } from 'redux-persist';
import createRematchPersist from '@rematch/persist';
import { authInterceptor } from './middleware';

import rootReducer from './reducers';
import models from './models';

const persistConfig = createRematchPersist({
    key: 'root',
    storage,
    whitelist: ['security', 'workflowState', 'reports', 'filters', 'cases'],
    version: 2
});

export const store = init({
    models,
    redux: {
        reducers: { ...rootReducer },
        middlewares: [thunk, authInterceptor]
    },
    plugins: [persistConfig]
});
// export const store = createStore(
//     persistReducer(persistConfig, rootReducer),
//     compose(
//         applyMiddleware(thunk, authInterceptor),
//         window.navigator.userAgent.includes('Chrome') && !window.navigator.userAgent.includes('Android')
//             ? window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : a => a
//             : a => a
//     )
// );
export const persistor = persistStore(store);
