import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const expenseTypesPersistConfig = {
    key: 'expenseTypes',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    expenseTypes: [],
    _errors: {},
    _updating: {
        _creatingExpenseType: false,
        _gettingExpenseTypes: false,
        _expenseTypes: {}
    }
};

const expenseTypes = createReducer(initialState, {
    [types.GET_EXPENSE_TYPES_REQUEST]: (state) => ({
        ...state,
        _errors: {},
        _updating: { ...state._updating, _gettingExpenseTypes: true }
    }),
    [types.GET_EXPENSE_TYPES_SUCCESS]: (state, { payload: expenseTypes }) => ({
        ...state,
        expenseTypes,
        _errors: {},
        _updating: { ...state._updating, _gettingExpenseTypes: false }
    }),
    [types.GET_EXPENSE_TYPES_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _gettingExpenseTypes: false }
    }),

    [types.CREATE_EXPENSE_TYPE_REQUEST]: (state) => ({
        ...state,
        _errors: {},
        _updating: { ...state._updating, _creatingExpenseType: true }
    }),
    [types.CREATE_EXPENSE_TYPE_SUCCESS]: (state, { payload: expenseType }) => ({
        ...state,
        expenseTypes: [
            ...state.expenseTypes,
            expenseType
        ],
        _errors: {},
        _updating: { ...state._updating, _creatingExpenseType: false }
    }),
    [types.CREATE_EXPENSE_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingExpenseType: false }
    }),

    [types.UPDATE_EXPENSE_TYPE_REQUEST]: (state, { payload: expenseTypeId }) => ({
        ...state,
        _errors: {},
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseTypeId]: true
            }
        }
    }),
    [types.UPDATE_EXPENSE_TYPE_SUCCESS]: (state, { payload: expenseType }) => ({
        ...state,
        expenseTypes: state.expenseTypes.map(et => et.Id !== expenseType.Id ? et : expenseType),
        _errors: {},
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseType.Id]: false
            }
        }
    }),
    [types.UPDATE_EXPENSE_TYPE_FAILURE]: (state, { payload: { expenseTypeId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseTypeId]: false
            }
        }
    }),

    [types.DELETE_EXPENSE_TYPE_REQUEST]: (state, { payload: expenseTypeId }) => ({
        ...state,
        _errors: {},
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseTypeId]: true
            }
        }
    }),
    [types.DELETE_EXPENSE_TYPE_SUCCESS]: (state, { payload: expenseTypeId }) => ({
        ...state,
        expenseTypes: state.expenseTypes.map(et => et.Id !== expenseTypeId ? et : {
            ...et,
            Hidden: !et.Hidden
        }),
        _errors: {},
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseTypeId]: false
            }
        }
    }),
    [types.DELETE_EXPENSE_TYPE_FAILURE]: (state, { payload: { expenseTypeId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating, _expenseTypes: {
                ...state._updating._expenseTypes,
                [expenseTypeId]: false
            }
        }
    }),
});

export default expenseTypes;