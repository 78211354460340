import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import AuthRoute from './common/AuthRoute';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/all.css';
import 'react-resizable/css/styles.css';

import Layout from './general/Layout';
import NotFound from './general/NotFound';

import FileUpload from './files/Upload';
import FileDownload from './files/Download';

import FileUploadNCSC from './files/UploadNCSC';
import FileDownloadNCSC from './files/DownloadNCSC';

import Login from './account/Login';
import Dashboard from './dashboard/Dashboard';

import routes from './router';
import ForgotPassword from './account/ForgotPassword';
import ForgotUsername from './account/ForgotUsername';
import ChangePassword from './account/ChangePassword';
import ResetPassword from './account/ResetPassword';
import ConfirmEmail from './account/ConfirmEmail';
import HandleQboComponent from './common/HandleQboComponent';

import AutoLogout from './account/AutoLogout';

Sentry.init({ dsn: 'https://a2b3d01121aa4d8e898fad27396f7274@o407928.ingest.sentry.io/5277888' });

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                <Route path="/fileU" component={FileUpload} />
                <Route path="/fileD" component={FileDownload} />
                <Route path="/fileUNCSC" component={FileUploadNCSC} />
                <Route path="/fileDNCSC" component={FileDownloadNCSC} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/forgot-username" component={ForgotUsername} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/confirm-email" component={ConfirmEmail} />
                    <Route>
                        <Layout>
                            <AutoLogout />
                            <Switch>
                                <AuthRoute exact path="/" component={Dashboard} />
                                {routes}
                                <Route exact path="/qbo/auth" component={HandleQboComponent} />
                                <Route component={NotFound} />
                            </Switch>
                        </Layout>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;
