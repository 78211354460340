import axios from 'axios';
import { baseUrl } from '../../../config';

export const workflowState = {
    state: {
        CaseStates: [],
        ItemStates: [],
        errors: {}
    },
    reducers: {
        setCaseStates: (state, payload) => ({ ...state, ...{ CaseStates: payload } }),
        setItemStates: (state, payload) => ({ ...state, ...{ ItemStates: payload } }),
        setErrors: (state, payload) => ({ ...state, ...{ _errors: payload } })
    },
    effects: dispatch => ({
        async getCaseStates(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/workflows/case-states`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.workflowState.setCaseStates(response.data);
            } catch (error) {
                if (error && error.response) dispatch.workflowState.setErrors(error.response);
            }
        },
        async getItemStates(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/workflows/item-states`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                dispatch.workflowState.setItemStates(response.data);
            } catch (error) {
                if (error && error.response) dispatch.workflowState.setErrors(error.response);
            }
        }
    })
};
