import React from 'react';

import { Header, Input, EnumTypeSelector, MultiSelect, FetchSelectorConsumer, Divider, Section } from '../../common/form';
import selectorStyles from './styles';
import { Label } from '../../common/form';

const ContractsGroups = ({ errors = {}, user = {}, handleInputChange, contractTypes, updateContractTypes, updateGroups, disableFields = {} }) => (
    <Section>
        <Header>IDS Settings</Header>
        <Divider />
        <div className="p-4">
            <div className="flex flex-col sm:flex-row mt-2">
                <div className={`mb-4 flex-grow sm:w-1/2 ${disableFields['EmploymentStatusType_Id'] ? '' : 'sm:mr-4'}`}>
                    <EnumTypeSelector label="Work Status" name="WorkStatusType_Id" errors={errors} type="WorkStatusType" />
                </div>
                {disableFields['EmploymentStatusType_Id'] ? (
                    <></>
                ) : (
                    <div className="mb-4 flex-grow sm:w-1/2">
                        <EnumTypeSelector label="Status" name="EmploymentStatusType_Id" errors={errors} type="EmploymentStatusType" />
                    </div>
                )}
            </div>
            <div className="flex flex-col sm:flex-row mt-2">
                <div className="mb-4 flex-grow sm:mr-4 sm:w-1/2">
                    {disableFields['Manager_Id'] ? (
                        <div>
                            <Label htmlFor="">Manager</Label>
                            <p className="py-2 font-medium text-primary">
                                {user.Manager ? `${user.Manager.FirstName} ${user.Manager.LastName}` : 'No manager'}
                            </p>
                        </div>
                    ) : (
                        <FetchSelectorConsumer
                            label="Manager"
                            name="Manager_Id"
                            errors={errors}
                            url="identity/managers"
                            valueKey="Id"
                            textKey="Name"
                            disabled={disableFields['Manager_Id']}
                        />
                    )}
                </div>
                <div className="mb-4 flex-grow sm:w-1/2">
                    <EnumTypeSelector
                        label="Region"
                        name="GeoRegionType_Id"
                        errors={errors}
                        type="GeoRegionType"
                        disabled={disableFields['RegionType']}
                    />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-2">
                <div className="mb-4 w-full sm:mr-4 sm:w-1/2">
                    {disableFields['ContractType'] ? (
                        <div>
                            <Label htmlFor="">Contract Types</Label>
                            <p className="py-2 font-medium text-primary">{user.ContractTypes.map(ct => ct.Short).join(', ') || 'None'}</p>
                        </div>
                    ) : (
                        <MultiSelect
                            name="ContractTypes"
                            label="Contract Types"
                            styles={selectorStyles}
                            getOptionLabel={option => option.Description}
                            getOptionValue={option => option.Id}
                            options={contractTypes.Types}
                            disabled={disableFields['ContractType']}
                        />
                    )}
                </div>
                <div className="mb-4 flex-grow sm:w-1/2">
                    {disableFields['Title'] ? (
                        <div>
                            <Label htmlFor="">Title</Label>
                            <p className="py-2 font-medium text-primary">{user.Title || 'No title'}</p>
                        </div>
                    ) : (
                        <Input name="Title" label="Title" type="text" disabled={disableFields['Title']} />
                    )}
                </div>
            </div>
        </div>
    </Section>
);

export default ContractsGroups;
