import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';

import { Header, TableCheckbox, Divider } from '../../../common/form';

import Button from '../../../common/Button';
import { USER_PROFILE_PAGE_SIZE } from '../../../../constants';

class AddressesEditor extends Component {
    state = {
        columns: []
    };

    componentDidMount = () => {
        let locations = this.props.locations || [{}];

        this.setState({
            ...this.state,
            columns: [
                {
                    Header: 'Address',
                    accessor: 'Line1',
                    Cell: row => {
                        return (
                            <span
                                className="flex flex-grow items-center h-full hover:underline cursor-pointer"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.openModal(row.index);
                                }}
                            >
                                {`${row.original.Line1 || ''} ${row.original.Line2 || ''} ${row.original.City || ''}, ${row.original.State ||
                                    ''} ${row.original.PostalCode || ''}`}
                            </span>
                        );
                    }
                },
                {
                    Header: 'Type',
                    accessor: 'AddressType_Id',
                    Cell: row => {
                        let location = locations.find(location => location.Id == row.original.AddressType_Id);
                        return <div className="flex flex-grow items-center h-full justify-center">{location ? location.Short : ''}</div>;
                    }
                },
                {
                    Header: 'Primary',
                    accessor: 'IsPrimary',
                    Cell: row => <TableCheckbox name={`Addresses.${row.original.Id}.IsPrimary`} label="" type="checkbox" />
                },
                {
                    Header: '',
                    accessor: 'Actions',
                    filterable: false,
                    sortable: false,
                    Cell: row => (
                        <div className="flex justify-center items-center h-full">
                            <span
                                className="cursor-pointer mr-4 text-grey"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.openModal(row.index);
                                }}
                            >
                                <i className="fas fa-pencil-alt" />
                            </span>
                            <span
                                className="text-red-light cursor-pointer"
                                onClick={this.props.onDelete.bind(null, this.props.form, 'Addresses', row.original.Id)}
                            >
                                <i className="fas fa-trash-alt" />
                            </span>
                        </div>
                    )
                }
            ]
        });
    };

    render() {
        let { user } = this.props;
        let { columns } = this.state;
        const data = user && user.Addresses ? [...user.Addresses] : [];

        return (
            <>
                <div className="w-full">
                    <div className="flex align-center justify-between">
                        <Header>Addresses</Header>
                        <Button icon="fas fa-address-book" onClick={() => this.props.openModal(-1)}>
                            Add
                        </Button>
                    </div>
                    <Divider />
                    <div className="p-4">
                        {data.length > 0 ? (
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPagination={false}
                                pageSize={data.length}
                                defaultPageSize={USER_PROFILE_PAGE_SIZE}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        ) : (
                                <div className="mb-8 text-primary">No addresses.</div>
                            )}
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    locations: state.types.AddressType ? state.types.AddressType.Types : []
});

export default connect(mapStateToProps)(AddressesEditor);
