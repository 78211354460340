import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { roles } from '../../constants';
import { accountSelectors } from '../account/duck';

const Dashboard = ({ hasPermission }) =>
    <Redirect to={hasPermission([roles.Cases.VIEW_CASES]) ? '/cases' : '/account'} />;

const mapStateToProps = (state) => ({
    hasPermission: (permissions) => accountSelectors.hasPermission(state.account, permissions)
});

export default connect(mapStateToProps)(Dashboard);