import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Modal extends Component {
    componentDidMount() {
        if (!this.props.disableEscape) {
            document.addEventListener('keyup', this.handleEscapeKeyPress);
        }
    }

    componentWillUnmount() {
        if (!this.props.disableEscape) {
            document.removeEventListener('keyup', this.handleEscapeKeyPress);
        }
    }

    handleEscapeKeyPress = e => {
        if (e.code === 'Escape') {
            this.props.onClose && this.props.onClose();
        }
    };

    render() {
        const { show, onClose, children, className, hideClose, style, zIndex } = this.props;
        const modalStyle = { display: show ? 'flex' : 'none' };

        if (zIndex) modalStyle.zIndex = zIndex;

        return ReactDOM.createPortal(
            <div className="flex justify-center items-center pin select-none fixed z-50" style={modalStyle}>
                <div className="absolute pin bg-black opacity-75" />
                <div
                    style={style}
                    className={`z-20 bg-white rounded shadow-lg px-6 py-4 w-4/5 lg:w-2/3 overflow-y-auto max-h-screen-90 ${className || ''}`}
                >
                    {!hideClose ? (
                        <button type="button" className="appearance-none absolute pin-r pin-t text-red px-4 py-3" onClick={onClose}>
                            <i className="fas fa-times" />
                        </button>
                    ) : (
                        <></>
                    )}
                    {children}
                </div>
            </div>,
            document.getElementById('modal-root')
        );
    }
}

export default Modal;
