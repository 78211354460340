import { roles } from '../../constants';
import { buildDesktopNavigation } from '../../utils/navigation';

export const identityNavigation = {
    linkText: 'IAM',
    props: { to: '/identity' },
    permissions: [roles.Users.VIEW_USERS, roles.Roles.VIEW_ROLE, roles.Groups.VIEW_GROUP],
    children: [
        {
            linkText: 'Users',
            props: { to: '/identity/users' },
            permissions: [roles.Users.VIEW_USERS]
        },
        {
            linkText: 'Roles',
            props: { to: '/identity/roles' },
            permissions: [roles.Roles.VIEW_ROLE]
        },
        {
            linkText: 'Groups',
            props: { to: '/identity/groups' },
            permissions: [roles.Groups.VIEW_GROUP]
        }
    ]
};

export const identityDesktopNavigation = buildDesktopNavigation(identityNavigation);