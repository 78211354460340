const GET_ALL_TYPES_REQUEST = 'app/types/GET_ALL_TYPES_REQUEST';
const GET_ALL_TYPES_SUCCESS = 'app/types/GET_ALL_TYPES_SUCCESS';
const GET_ALL_TYPES_FAILURE = 'app/types/GET_ALL_TYPES_FAILURE';

const UPDATE_TYPE_REQUEST = 'app/types/UPDATE_TYPE_REQUEST';
const UPDATE_TYPE_SUCCESS = 'app/types/UPDATE_TYPE_SUCCESS';
const UPDATE_TYPE_FAILURE = 'app/types/UPDATE_TYPE_FAILURE';

const ADD_TYPE_REQUEST = 'app/types/ADD_TYPE_REQUEST';
const ADD_TYPE_SUCCESS = 'app/types/ADD_TYPE_SUCCESS';
const ADD_TYPE_FAILURE = 'app/types/ADD_TYPE_FAILURE';

const EDIT_TYPE_REQUEST = 'app/types/EDIT_TYPE_REQUEST';
const EDIT_TYPE_SUCCESS = 'app/types/EDIT_TYPE_SUCCESS';
const EDIT_TYPE_FAILURE = 'app/types/EDIT_TYPE_FAILURE';

const DELETE_TYPE_REQUEST = 'app/types/DELETE_TYPE_REQUEST';
const DELETE_TYPE_SUCCESS = 'app/types/DELETE_TYPE_SUCCESS';
const DELETE_TYPE_FAILURE = 'app/types/DELETE_TYPE_FAILURE';

const ADD_ITEM_TYPE_REQUEST = 'app/types/ADD_ITEM_TYPE_REQUEST';
const ADD_ITEM_TYPE_SUCCESS = 'app/types/ADD_ITEM_TYPE_SUCCESS';
const ADD_ITEM_TYPE_FAILURE = 'app/types/ADD_ITEM_TYPE_FAILURE';

const EDIT_ITEM_TYPE_REQUEST = 'app/types/EDIT_ITEM_TYPE_REQUEST';
const EDIT_ITEM_TYPE_SUCCESS = 'app/types/EDIT_ITEM_TYPE_SUCCESS';
const EDIT_ITEM_TYPE_FAILURE = 'app/types/EDIT_ITEM_TYPE_FAILURE';

const DELETE_ITEM_TYPE_REQUEST = 'app/types/DELETE_ITEM_TYPE_REQUEST';
const DELETE_ITEM_TYPE_SUCCESS = 'app/types/DELETE_ITEM_TYPE_SUCCESS';
const DELETE_ITEM_TYPE_FAILURE = 'app/types/DELETE_ITEM_TYPE_FAILURE';

export default {
    GET_ALL_TYPES_REQUEST,
    GET_ALL_TYPES_SUCCESS,
    GET_ALL_TYPES_FAILURE,

    UPDATE_TYPE_REQUEST,
    UPDATE_TYPE_SUCCESS,
    UPDATE_TYPE_FAILURE,

    ADD_TYPE_REQUEST,
    ADD_TYPE_SUCCESS,
    ADD_TYPE_FAILURE,

    EDIT_TYPE_REQUEST,
    EDIT_TYPE_SUCCESS,
    EDIT_TYPE_FAILURE,

    DELETE_TYPE_REQUEST,
    DELETE_TYPE_SUCCESS,
    DELETE_TYPE_FAILURE,

    ADD_ITEM_TYPE_REQUEST,
    ADD_ITEM_TYPE_SUCCESS,
    ADD_ITEM_TYPE_FAILURE,

    EDIT_ITEM_TYPE_REQUEST,
    EDIT_ITEM_TYPE_SUCCESS,
    EDIT_ITEM_TYPE_FAILURE,

    DELETE_ITEM_TYPE_REQUEST,
    DELETE_ITEM_TYPE_SUCCESS,
    DELETE_ITEM_TYPE_FAILURE
};