import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const itemTypeAssets = {
    state: { assets: [], errors: {}, _updating: { _gettingAssets: false } },
    reducers: {
        getItemTypeAssetRequest: state => ({ ...state, _errors: {}, _updating: { ...state._updating, _gettingAssets: true } }),
        getItemTypeAssetSuccess: (state, assets) => ({ ...state, assets, _errors: {}, _updating: { ...state._updating, _gettingAssets: false } }),
        getItemTypeAssetFailure: (state, payload) => ({ ...state, ...{ _errors: payload } })
    },
    effects: dispatch => ({
        async getAssets({ itemTypeId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            dispatch.itemTypeAssets.getItemTypeAssetRequest();

            try {
                const response = await axios.get(`${baseUrl}/api/item-types/${itemTypeId}/assets`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.itemTypeAssets.getItemTypeAssetSuccess(response.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.item.getItemFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async addAsset({ data, itemTypeId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.post(`${baseUrl}/api/item-types/${itemTypeId}/assets`, data, {
                    headers: { Authorization: `Bearer ${access_token}`, 'content-type': 'multipart/form-data' }
                });

                toast.success(`Files were uploaded.`);
                dispatch.itemTypeAssets.getItemTypeAssetSuccess(response.data);

                return response;
            } catch (error) {
                toast.error('Unable to get attachments.');

                if (error && error.response) dispatch.itemTypeAssets.setErrors(error.response);

                throw error;
            }
        },
        async deleteAsset({ itemTypeId, assetId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const attachments = rootState.itemTypeAssets.assets.filter(attachment => attachment.Id !== assetId);
                dispatch.itemTypeAssets.getItemTypeAssetSuccess(attachments);

                await axios.delete(`${baseUrl}/api/item-types/${itemTypeId}/assets/${assetId}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
            } catch (error) {
                toast.error('Unable to remove attachment.');
            }
        },
        async downloadAsset({ itemTypeId, assetId }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await fetch(`${baseUrl}/api/item-types/${itemTypeId}/assets/${assetId}`, {
                    headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
                });

                return response;
            } catch (error) {
                toast.error('Unable to retrieve file.');

                throw error;
            }
        },
        async changeName({ itemTypeId, assetId, name }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                await axios.put(`${baseUrl}/api/item-types/${itemTypeId}/assets/${assetId}/${name}`, null, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('Filename updated.');
            } catch (error) {
                toast.error('Unable to remove attachment.');
            }
        }
    })
};
