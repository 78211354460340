export const filters = {
    state: {
        caseFilters: {
            page: 0,
            pageSize: 20,
            additional: {
                CaseStateIDs: [],
                OrgIds: [],
                ItemStateIDS: [],
                StartDate: '',
                EndDate: '',
                IncludePeopleWhoWorkForMe: false
            },
            sorted: [],
            filtered: []
        },
        itemFilters: {
            page: 0,
            pageSize: 20,
            additional: {
                ItemStateIDS: []
            },
            sorted: [],
            filtered: []
        }
    },
    reducers: {
        updateCaseFilters: (state, newState) => ({
            ...state,
            caseFilters: newState
        }),
        updateItemFilters: (state, newState) => ({
            ...state,
            itemFilters: newState
        })
    }
};