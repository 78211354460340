import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import ChangePassword from './components/ChangePassword';
import { typesSelectors } from '../types/duck';

import General from '../identity/components/General';
import Settings from '../identity/components/Settings';
import { Form } from '../common/form/Form';
import Modal from '../common/Modal';
import AddressForm from '../identity/components/AddressForm';
import PhoneNumbersEditor from '../identity/components/FormDataTable/PhoneNumbersEditor';
import EmailsEditor from '../identity/components/FormDataTable/EmailsEditor';
import CredentialsEditor from '../identity/components/FormDataTable/CredentialsEditor';
import ClearancesEditor from '../identity/components/FormDataTable/ClearancesEditor';
import AddressesEditor from '../identity/components/FormDataTable/AddressesEditor';
import Button from '../common/Button';

import { accountOperations } from './duck';
import {
    handleInputChange,
    orgSelected,
    updateContractTypes,
    showOrgSelector,
    closeOrgSelector,
    updateGroups,
    addRow,
    removeRow,
    openModal,
    closeModal,
    submitAddressForm
} from '../identity/components/methods';

const FORM_NAME = 'EditUser';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formName: FORM_NAME,
            user: {},
            values: {}
        };
        this.handleInputChange = handleInputChange.bind(this);
        this.orgSelected = orgSelected.bind(this);
        this.updateContractTypes = updateContractTypes.bind(this);
        this.showOrgSelector = showOrgSelector.bind(this);
        this.closeOrgSelector = closeOrgSelector.bind(this);
        this.updateGroups = updateGroups.bind(this);
        this.addRow = addRow.bind(this);
        this.removeRow = removeRow.bind(this);
        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
        this.submitAddressForm = submitAddressForm.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (Object.keys(this.state.user).length !== 0) return;

        const user = newProps.user || {};
        const values = {};
        Object.keys(user).forEach(key => {
            if (Array.isArray(user[key]) && key !== 'ContractTypes') {
                let array = user[key];
                array.forEach(el => {
                    Object.keys(el).forEach(elKey => {
                        if (elKey === 'Id') return;
                        let value = el[elKey];

                        if (moment(el[elKey], moment.ISO_8601, true).isValid()) {
                            value = moment(el[elKey]).format('YYYY-MM-DD');
                        }

                        values[`${key}.${el.Id}.${elKey}`] = value;
                    });
                });
            } else {
                values[key] = user[key];
            }
        });

        this.setState({
            user: user,
            values
        });
    }
    submitUpdateForm = values => {
        let { user } = this.state;
        let email = user.Email;
        let Emails = [];
        let Addresses = [];
        let PhoneNumbers = [];
        let Clearances = [];
        let Credentials = [];

        if (user.Emails && user.Emails.length > 0) {
            Emails = user.Emails.map(email => values.Emails[email.Id]).filter(email => !!email.Address);
            const primaryEmail = user.Emails.find(email => email.IsPrimary) || {};
            email = primaryEmail.Address || Emails[0].Address;
        }

        if (user.Addresses && user.Addresses.length > 0) {
            Addresses = user.Addresses.map(address => {
                const { Id, ...rest } = address;
                return { ...rest, ...(values.Addresses ? values.Addresses[Id] : {}) };
            });
        }

        if (values.PhoneNumbers && user.PhoneNumbers && user.PhoneNumbers.length > 0) {
            PhoneNumbers = user.PhoneNumbers.map(({ Id }) => values.PhoneNumbers[Id]);
        }

        if (values.Clearances && user.Clearances && user.Clearances.length > 0) {
            Clearances = user.Clearances.map(({ Id }) => values.Clearances[Id]);
        }

        if (values.Credentials && user.Credentials && user.Credentials.length > 0) {
            Credentials = user.Credentials.map(({ Id }) => values.Credentials[Id]);
        }

        user = {
            ...user,
            ...{
                FirstName: values.FirstName,
                MiddleName: values.MiddleName,
                LastName: values.LastName,
                UserName: values.UserName,
                WorkStatusType_Id: values.WorkStatusType_Id ? +values.WorkStatusType_Id : null,
                GeoRegionType_Id: values.GeoRegionType_Id,
                Emails,
                PhoneNumbers,
                Addresses,
                Clearances,
                Credentials
            }
        };
        user.Email = email;
        this.props.updateUser(user);
    };
    componentDidMount() {
        this.props.loadUser();
    }
    render = () => {
        const { user, values, currentAddress } = this.state;
        const { contractTypes, groups, updatingUser } = this.props;
        const isEmployee = user.Groups && user.Groups.some(group => group.Tags.some(t => t.Name === 'Employee'));

        const valuesArray = JSON.parse(localStorage.getItem("persist:account"));
        const valuesArray1 = JSON.parse(valuesArray.permissions);
        var isPalmetto = false;
        var isNCSC = false;
        if (valuesArray1 != null) {
            for (let i = 0; i < valuesArray1.groups.length; i++) {
                if (valuesArray1.groups[i] == "Palmetto") {
                    isPalmetto = true;
                }
				if (valuesArray1.groups[i] == "NCSC") {
                    isNCSC = true;
                }
            }
        }
        else {
            console.log(valuesArray);
            console.log(valuesArray1);
        }

        return (
            <div className="container ">
                <div className="bg-white mb-4 p-4 mb-8 overflow-x-auto">
                    {(() => {
                        if (isPalmetto) {
                            return (
                                <span> <a href="/fileu">Upload Palmetto</a>
                                    <br />
                                    <a href="/filed">Download Palmetto</a>
                                </span>
                            )
                        }
                        if (isNCSC) {
                            return (
                                <span> <a href="/fileuNCSC">Upload NCSC</a>
                                    <br />
                                    <a href="/filedNCSC">Download NCSC</a>
                                </span>
                            )
                        }

                        return null;
                    })()}
                    <Form onSubmit={values => this.submitUpdateForm(values, `/api/identity/users/${user.Id}`, 'patch')} defaultValues={values}>
                        <General user={user} groups={groups} handleInputChange={this.handleInputChange} disableFields={{ UserName: true }} />
                        {isEmployee ? (
                            <Settings
                                user={user}
                                groups={groups}
                                contractTypes={contractTypes}
                                handleInputChange={this.handleInputChange}
                                updateContractTypes={this.updateContractTypes}
                                updateGroups={this.updateGroups}
                                disableFields={{ ContractType: true, Manager_Id: true, EmploymentStatusType_Id: true, RegionType: true, Title: true }}
                            />
                        ) : (
                            <></>
                        )}

                        <PhoneNumbersEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                        <EmailsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                        <AddressesEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} openModal={this.openModal} />
                        {isEmployee ? (
                            <>
                                <CredentialsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                                <ClearancesEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="flex items-end w-full">
                            {!updatingUser ? (
                                <Button className="ml-auto" type="submit">
                                    Save Changes
                                </Button>
                            ) : (
                                <Button className="ml-auto">
                                    Saving changes... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                        </div>
                    </Form>
                </div>
                <ChangePassword />
                <Modal show={this.state.showModal} onClose={this.closeModal}>
                    {this.state.showModal ? (
                        <AddressForm onSubmit={this.submitAddressForm} values={user ? user.Addresses[currentAddress] : {}}>
                            <div className="flex justify-end items-end">
                                <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                    Cancel
                                </Button>
                                <Button type="submit">Ok</Button>
                            </div>
                        </AddressForm>
                    ) : (
                        <></>
                    )}
                </Modal>
            </div>
        );
    };
}
const mapStateToProps = state => ({
    user: state.account ? state.account.user : null,
    errors: state.account ? state.account.errors : null,
    updatingUser: state.account ? state.account._updating._updatingUser : false,
    groups: state.identity.groups,
    regions: state.types.RegionType,
    contractTypes: state.types['ContractType'],
    access_token: state.account.auth.access_token,
    loadingGroups: state.identity._updating._gettingGroups,
    loadingContractTypes: typesSelectors.typeIsLoading(state.types, 'ContractType'),
    shouldUpdateContractTypes: typesSelectors.shouldUpdateType(state.types, 'ContractType')
});

const mapDispatchToProps = dispatch => ({
    loadUserPhoneNumbers: () => dispatch(accountOperations.loadUserPhoneNumbers()),
    updateUser: user => dispatch(accountOperations.updateUser(user)),
    loadUser: () => dispatch(accountOperations.loadUser()),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);
