import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Button from '../../common/Button';
import InvoiceCaseList from './InvoiceCaseList';
import { DatePicker } from '../../common/form';
import { Form } from '../../common/form/Form';
import Modal from '../../common/Modal';

class InvoiceManager extends Component {
    state = {
        currentTabIndex: 0,
        checkedCases: []
    };

    changeIndex = index => {
        const { currentTabIndex } = this.state;
        if (currentTabIndex === index) return;

        this.setState({
            ...this.state,
            currentTabIndex: index,
            checkedCases: []
        });
    };

    updateChecked = event => {
        const { invoices } = this.props;
        const { startDate, endDate } = this.state;
        const minCompletionDate = moment.min(invoices.map(i => moment(i.CompletionDate)));

        if (event.target.name === 'CHECK_ALL') {
            this.setState({
                ...this.state,
                checkedCases: event.target.checked
                    ? invoices.filter(i => moment(i.CompletionDate).isBetween(startDate || minCompletionDate, endDate, null, '[]')).map(c => c.Id)
                    : []
            });
        } else {
            this.setState({
                ...this.state,
                checkedCases: event.target.checked
                    ? [...this.state.checkedCases, +event.target.name]
                    : this.state.checkedCases.filter(c => c !== +event.target.name)
            });
        }
    };

    createInvoice = () => {
        const { companyName } = this.props;
        const { checkedCases, invoiceDate } = this.state;
        this.props
            .createInvoice(checkedCases, companyName, invoiceDate)
            .then(() => {
                this.setState({
                    currentTabIndex: 0,
                    showInvoiceModal: false,
                    checkedCases: []
                });
            })
            .catch(() => { });
    };

    render() {
        const { startDate, endDate, checkedCases, showInvoiceModal } = this.state;
        const { invoices, creatingInvoice } = this.props;

        const hasQboId = invoices !== null ? !!invoices[0].QboId : false;
        const minCompletionDate = moment.min(invoices.map(i => moment(i.CompletionDate)));

        if (invoices.length === 0) return <div />;
        return (
            <>
                <div className="flex justify-end mb-4">
                    {hasQboId ? (
                        <Guard permissions={[roles.Invoices.CREATE_INVOICES]}>
                            <Button
                                inverse
                                disabled={checkedCases.length === 0 || creatingInvoice}
                                onClick={() => this.setState({ showInvoiceModal: true })}
                            >
                                {!creatingInvoice ? (
                                    'Create Invoice'
                                ) : (
                                        <>
                                            Creating Invoice... <i className="fas fa-spinner fa-pulse" />
                                        </>
                                    )}
                            </Button>
                        </Guard>
                    ) : (
                            <span className="font-bold text-sm text-primary">You must assign a Quickbooks company to this org to create an invoice.</span>
                        )}
                </div>
                <div className="flex -mx-4">
                    <div className="w-1/5 px-4">
                        <strong className="mb-4">Filter by completion date</strong>
                        <br />
                        <Form className="mt-4" onSubmit={() => { }}>
                            <div className="mb-4 flex-grow DayPickerModal">
                                <DatePicker
                                    name="startDate"
                                    label="Start Date"
                                    showLabel={true}
                                    onChange={(_, day) => {
                                        this.setState({
                                            startDate: moment.utc(day).startOf('day')
                                        });
                                    }}
                                />
                            </div>
                            <div className="mb-4 flex-grow DayPickerModal">
                                <DatePicker
                                    name="endDate"
                                    label="End Date"
                                    showLabel={true}
                                    onChange={(_, day) => {
                                        this.setState({
                                            endDate: moment.utc(day).endOf('day')
                                        });
                                    }}
                                />
                            </div>
                        </Form>
                    </div>
                    <div className="w-4/5 px-4">
                        <InvoiceCaseList
                            checkedCases={checkedCases}
                            cases={invoices.filter(i => moment(i.CompletionDate).isBetween(startDate || minCompletionDate, endDate, null, '[]'))}
                            updateChecked={this.updateChecked}
                        />
                    </div>
                </div>

                <Modal show={showInvoiceModal} onClose={() => this.setState({ showInvoiceModal: false })} className="max-w-md">
                    <h2 className="pb-4">Create Invoice?</h2>
                    <Form onSubmit={() => { }}>
                        <div className="mb-4 flex-grow DayPickerModal">
                            <DatePicker
                                name="invoiceDate"
                                label="Invoice Date"
                                showLabel={true}
                                onChange={(_, day) => {
                                    this.setState({
                                        invoiceDate: day
                                    });
                                }}
                            />
                        </div>
                        <em>Invoice date will default to today if left empty.</em>
                    </Form>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={() => this.setState({ showInvoiceModal: false })}>
                            Cancel
                        </Button>
                        <Button className="text-green border-green" onClick={this.createInvoice} loading={creatingInvoice}>
                            Confirm
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    creatingInvoice: state.invoices._updating._creatingInvoice
});

const mapDispatchToProps = dispatch => ({
    createInvoice: (caseIds, orgName, invoiceDate) => dispatch.invoices.createInvoice({ caseIds, orgName, invoiceDate })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceManager);
