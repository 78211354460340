import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';
import { makeCancelable } from '../../../utils';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Header, Divider } from '../../common/form';
import Button from '../../common/Button';

class AccountLock extends Component {
    state = {
        lockUntil: '',
        lockForever: false,
        locking: false,
        errors: null
    };

    componentWillUnmount() {
        if (this.lockUserPromise) this.lockUserPromise.cancel();
        if (this.unlockUserPromise) this.unlockUserPromise.cancel();
    }

    handleSelectChange = event => {
        this.setState({
            ...this.state,
            lockForever: event.target.value === 'true'
        });
    };

    updateLockUntil = (day, modifiers) => {
        this.setState({
            ...this.state,
            lockUntil: day
        });
    };

    disableAccount = () => {
        const { userId, access_token, user } = this.props;
        const { lockUntil, lockForever, locking } = this.state;

        if (locking) return;

        this.lockUserPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        locking: true
                    },
                    async () => {
                        try {
                            const lockResult = await axios.put(
                                `${baseUrl}/api/identity/users/${userId}/lock`,
                                {
                                    LockUntil: lockUntil ? moment(lockUntil).format() : null,
                                    LockForever: lockForever
                                },
                                { headers: { Authorization: `Bearer ${access_token}` } }
                            );

                            this.setState({
                                ...this.state,
                                locking: false
                            });
                            this.props.lockStatusChanged(lockResult.data);
                            toast.success(`${user.FirstName} ${user.LastName}'s account was deactivated.`);
                        } catch (error) {
                            if (error.response === undefined) toast.error('There was an error connecting to the server.');
                            this.setState({
                                ...this.state,
                                errors:
                                    error.response !== undefined
                                        ? error.response.data
                                        : { error_description: 'There was an error connecting to the server.' },
                                locking: false
                            });
                        }
                    }
                );
            })
        );
    };


  SendVerificationEmail = () => {
        const { userId, access_token, user } = this.props;
        const { lockUntil, lockForever, locking } = this.state;

        if (locking) return;

        this.lockUserPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        locking: true
                    },
                    async () => {
                        try {
							  await axios.post(`${baseUrl}/api/user/resendmail`, {
                UserId: userId
            });

                            const lockResult = await axios.post(
                                `${baseUrl}/api/identity/users/${userId}/resendmail`,
                                {
                                    LockUntil: lockUntil ? moment(lockUntil).format() : null,
                                    LockForever: lockForever
                                },
                                { headers: { Authorization: `Bearer ${access_token}` } }
                            );

                            this.setState({
                                ...this.state,
                                locking: false
                            });
                            this.props.lockStatusChanged(lockResult.data);
                            toast.success(`${user.FirstName} ${user.LastName}'s account was deactivated.`);
                        } catch (error) {
                            if (error.response === undefined) toast.error('There was an error connecting to the server.');
                            this.setState({
                                ...this.state,
                                errors:
                                    error.response !== undefined
                                        ? error.response.data
                                        : { error_description: 'There was an error connecting to the server.' },
                                locking: false
                            });
                        }
                    }
                );
            })
        );
    };

    enableAccount = event => {
        event.preventDefault();
        const { userId, access_token, user } = this.props;

        this.setState({
            ...this.state,
            locking: true
        });

        this.unlockUserPromise = makeCancelable(
            new Promise(async r => {
                try {
                    const lockResult = await axios.delete(`${baseUrl}/api/identity/users/${userId}/lock`, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    });

                    this.setState({
                        lockUntil: '',
                        lockForever: false,
                        locking: false,
                        errors: null
                    });
                    this.props.lockStatusChanged(lockResult.data);
                    toast.success(`${user.FirstName} ${user.LastName}'s account was reactivated.`);
                } catch (error) {
                    if (error.response === undefined) toast.error('There was an error connecting to the server.');
                    this.setState({
                        ...this.state,
                        errors:
                            error.response !== undefined
                                ? error.response.data
                                : { error_description: 'There was an error connecting to the server.' },
                        locking: false
                    });
                }
            })
        );
    };

    render() {
        const { user } = this.props;
        const { lockUntil, lockForever, locking, errors } = this.state;
        const endDate = user.LockoutEndDateUtc && moment(user.LockoutEndDateUtc);

        return (
            <div className="mb-6">
                <div className="flex items-center uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                    <Header>Account Status</Header>     <Button onClick={this.SendVerificationEmail} className="text-red-light bg-white border border-grey shadow-button hover:bg-grey-lighter ml-2">
                                            Resend Verification Email
                                    </Button>
                                   
                    {endDate && moment().isBefore(endDate) ? (
                        <span className="flex rounded-full normal-case bg-red-light px-2 py-1 text-xxs ml-3 font-normal text-white">Deactivated</span>
                    ) : (
                            <span className="flex rounded-full normal-case bg-green-light px-2 py-1 text-xxs ml-3 font-normal text-white">Active</span>
                        )}
                </div>
                <Divider />
                <div className="p-4">
                    {endDate && moment().isBefore(endDate) ? (
                        <div className="flex justify-between items-center">
                            <span>
                                Deactivated{' '}
                                {endDate.diff(moment(), 'years') > 50 ? (
                                    <strong>Forever</strong>
                                ) : (
                                        <>
                                            {'until '} <strong>{moment(user.LockoutEndDateUtc).format('MMMM DD, YYYY')}</strong>
                                        </>
                                    )}
                            </span>

                            {!locking ? (
                                <Button onClick={this.enableAccount} className="text-green-light bg-white border border-grey shadow-button hover:bg-grey-lighter">
                                    Reactivate
                                </Button>
                            ) : (
                                    <Button className="cursor-not-allowed text-green-light bg-white border border-grey shadow-button hover:bg-grey-lighter ml-2">
                                        <i className="fas fa-spinner fa-pulse" />
                                    </Button>
                                )}
                        </div>
                    ) : (
                            <>
                                <div className="flex">
                                    <DayPickerInput
                                        dayPickerProps={{
                                            disabledDays: [{ before: new Date() }]
                                        }}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder={`${formatDate(new Date(), 'MMMM DD, YYYY')}`}
                                        format="MMMM DD, YYYY"
                                        inputProps={
                                            lockForever
                                                ? {
                                                    disabled: lockForever
                                                }
                                                : {}
                                        }
                                        value={lockUntil}
                                        onDayChange={this.updateLockUntil}
                                    />
                                    {!locking ? (
                                        <Button onClick={this.disableAccount} className="text-red-light bg-white border border-grey shadow-button hover:bg-grey-lighter ml-2">
                                            Deactivate
                                    </Button>
                                    ) : (
                                            <Button className="cursor-not-allowed text-red-light bg-white border border-grey shadow-button hover:bg-grey-lighter ml-2">
                                                <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                </div>
                                <p className="text-xs italic mt-2">Leave blank to lockout indefinitely.</p>
                                {errors ? <p className="text-red text-xs italic mt-2">{errors['Message']}</p> : <></>}
                            </>
                        )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(AccountLock);
