import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '../common/form/Form';
import { Input } from '../common/form';
import Button from '../common/Button';
import { baseUrl } from '../../config';
import Select from 'react-select';
import ReactTable from 'react-table';


class FileDownload extends Component {

    state = {
        users: [],
        loading: true,
        fileLocation: "PalmettoRequestsFolder",
        uploadingfile: false,
        errors: {}
    };
    locationChange = event => {
        this.setState({ fileLocation: event.target.value });
    };
    /*  async getUsersData() {
          const res = await axios.get('https://jsonplaceholder.typicode.com/users')
          console.log(res.data)
          this.setState({ loading: false, users: res.data })
      }
      componentDidMount() {
          //      this.getUsersData()
      }*/

    async DeleteFileAPI(data) {
        //    const res = await axios.post(`${baseUrl}/api/user/GetFileList`, formData);
        const formData = new FormData();

        // Update the formData object 

        formData.append(
            "FilePath",
            data
        );
        try {
            await axios.post(`${baseUrl}/api/user/DeleteFile`, formData);
            toast.success('Files Deleted Successfully.');
        } catch (error) {
            toast.success('ERROR DELETING FILE.');
            console.log(error);
            this.setState({
                uploadingfile: false,
                errors: { ...error }
            });
        }
        //     axios.post(`http://localhost:55910/api/user/DeleteFile`, formData);

    }

    DeleteFile = (data) => (e) => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            this.DeleteFileAPI(data);
        }
        else {

        }
    }



    onSubmit = async values => {

        const formData = new FormData();

        // Update the formData object 

        formData.append(
            "Folder",
            this.state.fileLocation
        );
        console.log(values);
        try {
            this.setState({
                uploadingfile: true
            });

            const res = await axios.post(`${baseUrl}/api/user/GetFileList`, formData);

            //const res = await axios.post(`http://localhost:55910/api/user/GetFileList`, formData);
            console.log(res.data)
            //               const res1 = await axios.get('https://jsonplaceholder.typicode.com/users')
            //         console.log(res1.data)
            this.setState({ loading: false, users: res.data })
            toast.success('Files List Loaded.');
        } catch (error) {
            toast.success('ERROR LOADING FILES LIST.');
            console.log(error);
            this.setState({
                uploadingfile: false,
                errors: { ...error }
            });
        }

        this.setState({
            uploadingfile: false
        });
    };
    render() {

        let { uploadingfile } = this.state;
        const valuesArray = JSON.parse(localStorage.getItem("persist:account"));
        const valuesArray1 = JSON.parse(valuesArray.permissions);
        var isPalmetto = false;
        if (valuesArray1 != null) {
            for (let i = 0; i < valuesArray1.groups.length; i++) {
                if (valuesArray1.groups[i] == "Palmetto") {
                    isPalmetto = true;
                }
            }
        }
        else {
            console.log(valuesArray);
            console.log(valuesArray1);
        }
        if (!isPalmetto) {
            return (<span>You Are Not Authorized</span>);
        }
        const columns = [

            {
                Header: 'Name',
                accessor: 'Name',
            },
            {
                Header: 'Timestamp',
                accessor: 'CreationTime',
            },
            {
                Header: 'Download',
                accessor: 'FullName',
                //Cell: e =><a href={e.FullName}> {e.value} </a>
                Cell: ({ row }) => (<Link target="_blank" to={{ pathname: `${baseUrl}` + `${row.FullName}`, state: { data: row } }}>Download</Link>),
            },
            {
                Header: 'Delete',
                accessor: 'FullName',
                Cell: ({ row }) => (<span onClick={this.DeleteFile(`${row.FullName}`)}>Delete</span>)
                //   Cell: ({ row }) =>(<span onClick={() => { window.confirm('Are you sure you wish to delete this item?') ?this.changeValue(`${row.FullName}`):this.changeValue(null) } } >Delete</span>)

            }


        ]
        return (
            <div>
                <span> <a href="/fileu">Upload Palmetto</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="/filed">Download Palmetto</a>
                </span>
                <Form onSubmit={this.onSubmit}>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                Select Location*

                                <select value={this.state.fileLocation} onChange={this.locationChange} >
                                    <option value="PalmettoRequestsFolder">Palmetto Requests</option>
                                    <option value="PalmettoAcknowledgmentsFolder">Palmetto Acknowledgments</option>
                                    <option value="PalmettoProcessingFolder">Palmetto Processing</option>
                                    <option value="Palmetto202102RequestsFolder">Palmetto 2021 Requests</option>
                                    <option value="Palmetto202102AcknowledgmentsFolder">Palmetto 2021 Acknowledgments</option>
                                    <option value="Palmetto202102ProcessingFolder">Palmetto 2021 Processing</option>


                                </select>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>


                    <div className="flex items-center justify-between">
                        <Button type="submit" loading={uploadingfile}>
                            List Files
                        </Button>

                    </div>
                </Form>
                <br></br>
                <ReactTable
                    data={this.state.users}
                    columns={columns}
                />
            </div>
        )
    }
}
export default FileDownload;
