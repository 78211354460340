import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { baseUrl } from '../../../../config';
import { makeCancelable } from '../../../../utils';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import OrgExpenseRulesList from './OrgExpenseRules/OrgExpenseRulesList';
import CreateOrgExpenseRule from './OrgExpenseRules/CreateOrgExpenseRule';

class OrgExpenses extends Component {
    state = {
        loadingExpenseRules: false,
        showCreateModal: false,
        expenseRules: []
    };

    componentDidMount() {
        this.loadExpenseRules();
    }

    componentWillUnmount() {
        if (this.orgExpenseRulesPromise) this.orgExpenseRulesPromise.cancel();
    }

    showCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    loadExpenseRules = (refresh = false) => {
        const { access_token, match: { params: { orgId } } } = this.props;
        this.orgExpenseRulesPromise = makeCancelable(
            new Promise(r => {
                this.setState({
                    ...this.state,
                    loadingExpenseRules: true
                }, async () => {
                    try {
                        const expenseRules = await axios.get(`${baseUrl}/api/orgs/${orgId}/expense-rules`, {
                            headers: { Authorization: `Bearer ${access_token}` }
                        });

                        this.setState({
                            ...this.state,
                            loadingExpenseRules: false,
                            expenseRules: expenseRules.data
                        });
                    } catch (e) {
                        toast.error(`There was an error ${refresh ? 'refreshing' : 'loading'} the expense rules for this org.`);
                        this.setState({
                            ...this.state,
                            expenseRules: refresh ? this.state.expenseRules : [],
                            loadingExpenseRules: false
                        });
                    }
                });
            })
        );
    };

    expenseRuleOverrode = (expenseRule, oldId) => {
        this.setState({
            ...this.state,
            expenseRules: this.state.expenseRules.map(er => er.Id === oldId ? expenseRule : er)
        })
    };

    expenseRuleUpdated = (expenseRule) => {
        this.setState({
            ...this.state,
            expenseRules: this.state.expenseRules.map(er => er.Id !== expenseRule.Id ? er : expenseRule)
        });
    };

    expenseRuleDeleted = (expenseRule) => {
        this.setState({
            ...this.state,
            expenseRules: this.state.expenseRules.filter(er => er.Id !== expenseRule.Id)
        });
    };

    expenseRuleCreated = (expenseRule) => {
        this.setState({
            ...this.state,
            expenseRules: [
                ...this.state.expenseRules,
                expenseRule
            ]
        }, () => {
            this.closeCreateModal();
        });
    };

    render() {
        const { loadingExpenseRules, expenseRules, showCreateModal } = this.state;
        const { match: { params: { orgId } } } = this.props;

        return (<>
            <div className="flex justify-end items-center">
                <Button inverse onClick={this.showCreateModal}>Add Expense Rule</Button>
            </div>
            {!loadingExpenseRules ?
                expenseRules.length ?
                    <OrgExpenseRulesList
                        expenseRules={expenseRules}
                        orgId={orgId}
                        expenseRuleUpdated={this.expenseRuleUpdated}
                        expenseRuleDeleted={this.expenseRuleDeleted} 
                        expenseRuleOverrode={this.expenseRuleOverrode}/> :
                    <div className="text-primary">There are no expense rules for this org.</div> :
                <span>
                    Loading... <i className="fas fa-spinner fa-pulse" />
                </span>
            }
            <Modal show={showCreateModal} onClose={this.closeCreateModal}>
                <div className="p-4">
                    {showCreateModal ?
                        <CreateOrgExpenseRule
                            expenseRuleCreated={this.expenseRuleCreated}
                            closeModal={this.closeCreateModal}
                            orgId={orgId} />
                        : <></>
                    }
                </div>
            </Modal>
        </>);
    }
};

const mapStateToProps = (state) => ({
    access_token: state.account.auth.access_token
});

export default withRouter(connect(mapStateToProps)(OrgExpenses));