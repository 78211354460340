import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ExpenseTypes from './ExpenseTypes';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const expenseTypesRouter = () => (
    <Route key="expense-types-router" path="/expense-types">
        <Switch key="expense-types-router-switch">
            <Guard key='expense-types-route' exact path='/expense-types' notFound={NotFound} permissions={[roles.ExpenseTypes.VIEW_EXPENSE_TYPE]}>
                <AuthRoute exact path='/expense-types' component={ExpenseTypes} />
            </Guard>
        </Switch>
    </Route>
);

export default expenseTypesRouter;