import types from './types';

const getRolesRequest = () => ({
    type: types.GET_ROLES_REQUEST
});
const getRolesSuccess = roles => ({
    type: types.GET_ROLES_SUCCESS,
    payload: roles
});
const getRolesFailure = errors => ({
    type: types.GET_ROLES_FAILURE,
    payload: errors
});

const createRoleRequest = () => ({
    type: types.CREATE_ROLE_REQUEST
});
const createRoleSuccess = role => ({
    type: types.CREATE_ROLE_SUCCESS,
    payload: role
});
const createRoleFailure = errors => ({
    type: types.CREATE_ROLE_FAILURE,
    payload: errors
});

const updateRoleRequest = roleId => ({
    type: types.UPDATE_ROLE_REQUEST,
    payload: roleId
});
const updateRoleSuccess = role => ({
    type: types.UPDATE_ROLE_SUCCESS,
    payload: role
});
const updateRoleFailure = (roleId, errors) => ({
    type: types.UPDATE_ROLE_FAILURE,
    payload: { roleId, errors }
});

const deleteRoleRequest = roleId => ({
    type: types.DELETE_ROLE_REQUEST,
    payload: roleId
});
const deleteRoleSuccess = roleId => ({
    type: types.DELETE_ROLE_SUCCESS,
    payload: roleId
});
const deleteRoleFailure = (roleId, errors) => ({
    type: types.DELETE_ROLE_FAILURE,
    payload: { roleId, errors }
});

const getGroupsRequest = () => ({
    type: types.GET_GROUPS_REQUEST
});
const getGroupsSuccess = groups => ({
    type: types.GET_GROUPS_SUCCESS,
    payload: groups
});
const getGroupsFailure = errors => ({
    type: types.GET_GROUPS_FAILURE,
    payload: errors
});

const updateGroupRequest = () => ({
    type: types.UPDATE_GROUP_REQUEST
});
const updateGroupSuccess = group => ({
    type: types.UPDATE_GROUP_SUCCESS,
    payload: group
});
const updateGroupFailure = errors => ({
    type: types.UPDATE_GROUP_FAILURE,
    payload: errors
});

const deleteGroupRequest = () => ({
    type: types.DELETE_GROUP_REQUEST
});
const deleteGroupSuccess = deletedId => ({
    type: types.DELETE_GROUP_SUCCESS,
    payload: deletedId
});
const deleteGroupFailure = errors => ({
    type: types.DELETE_GROUP_FAILURE,
    payload: errors
});

const getCategoriesRequest = () => ({
    type: types.GET_CATEGORIES_REQUEST
});
const getCategoriesSuccess = categories => ({
    type: types.GET_CATEGORIES_SUCCESS,
    payload: categories
});
const getCategoriesFailure = errors => ({
    type: types.GET_CATEGORIES_FAILURE,
    payload: errors
});

const addRoleToGroupRequest = () => ({
    type: types.ADD_ROLE_TO_GROUP_REQUEST
});
const addRoleToGroupSuccess = group => ({
    type: types.ADD_ROLE_TO_GROUP_SUCCESS,
    payload: group
});
const addRoleToGroupFailure = errors => ({
    type: types.ADD_ROLE_TO_GROUP_FAILURE,
    payload: errors
});

const removeRoleFromGroupRequest = () => ({
    type: types.REMOVE_ROLE_FROM_GROUP_REQUEST
});
const removeRoleFromGroupSuccess = group => ({
    type: types.REMOVE_ROLE_FROM_GROUP_SUCCESS,
    payload: group
});
const removeRoleFromGroupFailure = errors => ({
    type: types.REMOVE_ROLE_FROM_GROUP_FAILURE,
    payload: errors
});

const getUsersRequest = () => ({
    type: types.GET_USERS_REQUEST
});
const getUsersSuccess = users => ({
    type: types.GET_USERS_SUCCESS,
    payload: users
});
const getUsersFailure = errors => ({
    type: types.GET_USERS_FAILURE,
    payload: errors
});
const getUserFromUsers = user_id => ({
    type: types.GET_USERS_FAILURE,
    payload: user_id
});

const getTagsSuccess = tags => ({
    type: types.GET_TAGS_SUCCESS,
    payload: tags
});

export default {
    getRolesRequest,
    getRolesSuccess,
    getRolesFailure,
    createRoleRequest,
    createRoleSuccess,
    createRoleFailure,
    updateRoleRequest,
    updateRoleSuccess,
    updateRoleFailure,
    deleteRoleRequest,
    deleteRoleSuccess,
    deleteRoleFailure,
    getGroupsRequest,
    getGroupsSuccess,
    getGroupsFailure,
    updateGroupRequest,
    updateGroupSuccess,
    updateGroupFailure,
    deleteGroupRequest,
    deleteGroupSuccess,
    deleteGroupFailure,
    getCategoriesRequest,
    getCategoriesSuccess,
    getCategoriesFailure,
    addRoleToGroupRequest,
    addRoleToGroupSuccess,
    addRoleToGroupFailure,
    removeRoleFromGroupRequest,
    removeRoleFromGroupSuccess,
    removeRoleFromGroupFailure,
    getUsersRequest,
    getUsersSuccess,
    getUsersFailure,
    getUserFromUsers,
    getTagsSuccess
};
