import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CaseTemplates from './CaseTemplates';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const caseTemplatesRouter = () => (
    <Route key="case-templates-router" path="/case-templates">
        <Switch key="case-templates-router-switch">
            <Guard key='case-templates-route' exact path='/case-templates' notFound={NotFound} permissions={[roles.Cases.CREATE_CASE_TEMPLATE]}>
                <AuthRoute exact path='/case-templates' component={CaseTemplates} />
            </Guard>
        </Switch>
    </Route>
);

export default caseTemplatesRouter;