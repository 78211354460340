const LOG_IN_REQUEST = 'app/account/LOG_IN_REQUEST';
const LOG_IN_SUCCESS = 'app/account/LOG_IN_SUCCESS';
const LOG_IN_FAILURE = 'app/account/LOG_IN_FAILURE';
const LOG_IN_COMPLETE = 'app/account/LOG_IN_COMPLETE';

const LOG_OUT = 'app/account/LOG_OUT'

const LOAD_USER_REQUEST = 'app/account/LOAD_USER_REQUEST';
const LOAD_USER_SUCCESS = 'app/account/LOAD_USER_SUCCESS';
const LOAD_USER_FAILURE = 'app/account/LOAD_USER_FAILURE';

const LOAD_USER_PERMISSIONS_REQUEST = 'app/account/LOAD_USER_PERMISSIONS_REQUEST';
const LOAD_USER_PERMISSIONS_SUCCESS = 'app/account/LOAD_USER_PERMISSIONS_SUCCESS';
const LOAD_USER_PERMISSIONS_FAILURE = 'app/account/LOAD_USER_PERMISSIONS_FAILURE';

const LOAD_USER_ADDRESS_REQUEST = 'app/account/LOAD_USER_ADDRESS_REQUEST';
const LOAD_USER_ADDRESS_SUCCESS = 'app/account/LOAD_USER_ADDRESS_SUCCESS';
const LOAD_USER_ADDRESS_FAILURE = 'app/account/LOAD_USER_ADDRESS_FAILURE';

const LOAD_USER_PHONE_NUMBERS_REQUEST = 'app/account/LOAD_USER_PHONE_NUMBERS_REQUEST';
const LOAD_USER_PHONE_NUMBERS_SUCCESS = 'app/account/LOAD_USER_PHONE_NUMBERS_SUCCESS';
const LOAD_USER_PHONE_NUMBERS_FAILURE = 'app/account/LOAD_USER_PHONE_NUMBERS_FAILURE';

const UPDATE_USER_REQUEST = 'app/account/UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'app/account/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'app/account/UPDATE_USER_FAILURE';

const UPDATE_USER_ADDRESS_REQUEST = 'app/account/UPDATE_USER_ADDRESS_REQUEST';
const UPDATE_USER_ADDRESS_SUCCESS = 'app/account/UPDATE_USER_ADDRESS_SUCCESS';
const UPDATE_USER_ADDRESS_FAILURE = 'app/account/UPDATE_USER_ADDRESS_FAILURE';

const UPDATE_USER_PHONE_REQUEST = 'app/account/UPDATE_USER_PHONE_REQUEST';
const UPDATE_USER_PHONE_SUCCESS = 'app/account/UPDATE_USER_PHONE_SUCCESS';
const UPDATE_USER_PHONE_FAILURE = 'app/account/UPDATE_USER_PHONE_FAILURE';

const CREATE_USER_PHONE_REQUEST = 'app/account/CREATE_USER_PHONE_REQUEST';
const CREATE_USER_PHONE_SUCCESS = 'app/account/CREATE_USER_PHONE_SUCCESS';
const CREATE_USER_PHONE_FAILURE = 'app/account/CREATE_USER_PHONE_FAILURE';

const DELETE_USER_PHONE_REQUEST = 'app/account/DELETE_USER_PHONE_REQUEST';
const DELETE_USER_PHONE_SUCCESS = 'app/account/DELETE_USER_PHONE_SUCCESS';
const DELETE_USER_PHONE_FAILURE = 'app/account/DELETE_USER_PHONE_FAILURE';

const UPDATE_PASSWORD_REQUEST = 'app/account/UPDATE_PASSWORD_REQUEST';
const UPDATE_PASSWORD_SUCCESS = 'app/account/UPDATE_PASSWORD_SUCCESS';
const UPDATE_PASSWORD_FAILURE = 'app/account/UPDATE_PASSWORD_FAILURE';

export default {
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_COMPLETE,

    LOG_OUT,

    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILURE,

    LOAD_USER_PERMISSIONS_REQUEST,
    LOAD_USER_PERMISSIONS_SUCCESS,
    LOAD_USER_PERMISSIONS_FAILURE,

    LOAD_USER_ADDRESS_REQUEST,
    LOAD_USER_ADDRESS_SUCCESS,
    LOAD_USER_ADDRESS_FAILURE,

    LOAD_USER_PHONE_NUMBERS_REQUEST,
    LOAD_USER_PHONE_NUMBERS_SUCCESS,
    LOAD_USER_PHONE_NUMBERS_FAILURE,

    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,

    UPDATE_USER_ADDRESS_REQUEST,
    UPDATE_USER_ADDRESS_SUCCESS,
    UPDATE_USER_ADDRESS_FAILURE,

    UPDATE_USER_PHONE_REQUEST,
    UPDATE_USER_PHONE_SUCCESS,
    UPDATE_USER_PHONE_FAILURE,

    CREATE_USER_PHONE_REQUEST,
    CREATE_USER_PHONE_SUCCESS,
    CREATE_USER_PHONE_FAILURE,

    DELETE_USER_PHONE_REQUEST,
    DELETE_USER_PHONE_SUCCESS,
    DELETE_USER_PHONE_FAILURE,

    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE
};