import axios from 'axios';
import 'whatwg-fetch';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../config';

export const reports = {
    state: {
        reports: [],
        errors: {}
    },
    reducers: {
        setReports: (state, payload) => ({ ...state, reports: payload }),
        setErrors: (state, payload) => ({ ...state, errors: payload })
    },
    effects: dispatch => ({
        async getReports(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/reports`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
              //  console.log("asd");
              //  console.log(response.data);
                dispatch.reports.setReports(response.data);
                return response;
            } catch (error) {
                toast.error('Unable to get reports');
                if (error && error.response) dispatch.reports.setErrors(error.response);

                throw error;
            }
        },
        async getReport({ group, name }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.get(`${baseUrl}/api/reports/${group}/${name}/parameters`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
                return response;
            } catch (error) {
                toast.error('Unable to get report');
                if (error && error.response) dispatch.reports.setErrors(error.response);

                throw error;
            }
        },
        async generateReport({ group, name, params, format }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                let response = await axios.post(`${baseUrl}/api/reports/${group}/${name}`, params, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });
/*
                 const test = await axios.get(`${baseUrl}/api/reports/instance/${response.data.Id}/${format}`, {
                     headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
                 });
                 console.log(test);
  */
                //alert("asd");
                const file = await fetch(`${baseUrl}/api/reports/instance/${response.data.Id}/${format}`, {
                    headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
                });

                if (!file.ok) {
                    let text = await file.text();
                    throw text;
                }

                return file;
            } catch (error) {
                if (typeof error === 'string') {
                    toast.error(error);
                } else {
                    toast.error('Unable to generate report');
                }
                if (error && error.response) dispatch.reports.setErrors(error.response);

                throw error;
            }
        }
    })
};
