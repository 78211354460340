import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import Roles from './Roles';
import Users from './Users';
import Groups from './Groups';
import Identity from './Identity';

import { roles } from '../../constants';

const identityRouter = () => (
    <Route key="identity-router" path="/identity">
        <Switch key="identity-router-switch">
            <Guard key='identity-route' exact path='/identity' notFound={NotFound} permissions={[roles.Users.VIEW_USERS, roles.Roles.VIEW_ROLE, roles.Groups.VIEW_GROUP]}>
                <Identity />
            </Guard>
            <Guard key='identity-roles-route' exact path='/identity/roles' notFound={NotFound} permissions={[roles.Roles.VIEW_ROLE]}>
                <AuthRoute exact path='/identity/roles' component={Roles} />
            </Guard>
            <Guard key='identity-groups-route' exact path='/identity/groups' notFound={NotFound} permissions={[roles.Groups.VIEW_GROUP]}>
                <AuthRoute exact path='/identity/groups' component={Groups} />
            </Guard>
            <Guard key='identity-users-route' exact path='/identity/users' notFound={NotFound} permissions={[roles.Users.VIEW_USERS]}>
                <AuthRoute exact path='/identity/users' component={Users} />
            </Guard>
        </Switch>
    </Route>
);

export default identityRouter;