import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { FetchSelectorConsumer, Input, Checkbox, TextArea, Label } from '../../common/form';
import { Form } from '../../common/form/Form';

const DEFAULT_CREATE_VALUES = {
    ExpenseType_Id: 0,
    Units: 0,
    IncurredBy_Id: '',
    Note: '',
    Reimbursable: false
};

class ItemExpenseModal extends Component {
    handleFormSubmit = values => {
        const { item } = this.props;

        if (values['Id']) {
            this.props
                .updateExpense(item.Case.Id, item.Id, {
                    Id: +values['Id'],
                    ExpenseType_Id: +values['ExpenseType_Id'],
                    Units: +values['Units'],
                    IncurredBy_Id: values['IncurredBy_Id'],
                    Note: values['Note'],
                    Reimbursable: !!values['Reimbursable'],
                    Override: +values['Override']
                })
                .then(() => this.props.closeModal())
                .catch(() => {});
        } else {
            this.props
                .createExpense(item.Case.Id, item.Id, {
                    ExpenseType_Id: +values['ExpenseType_Id'],
                    Units: +values['Units'],
                    IncurredBy_Id: values['IncurredBy_Id'],
                    Note: values['Note'],
                    Reimbursable: !!values['Reimbursable'],
                    Override: +values['Override']
                })
                .then(() => this.props.closeModal())
                .catch(() => {});
        }
    };

    render() {
        const { expense, isView, closeModal, updatingExpense, creatingExpense, errors, item } = this.props;
        const transformedExpense = expense
            ? {
                  Id: expense.Id,
                  ExpenseType_Id: expense.ExpenseType.Id,
                  Units: expense.Units,
                  IncurredBy_Id: expense.IncurredBy ? expense.IncurredBy.Id : '',
                  Note: expense.Note,
                  Reimbursable: expense.Reimbursable,
                  Override: expense.Override || 0
              }
            : {};

        return (
            <>
                <Form
                    defaultValues={{
                        ...DEFAULT_CREATE_VALUES,
                        ...transformedExpense
                    }}
                    errors={errors}
                    onSubmit={this.handleFormSubmit}
                >
                    <div className="flex flex-wrap md:-mx-4">
                        <div className="w-full md:w-1/2  md:px-4">
                            <div className="mb-4">
                                {!isView ? (
                                    <FetchSelectorConsumer
                                        label="Expense Type"
                                        name="ExpenseType_Id"
                                        url={`orgs/${item.Case.Client_Id}/expense-rules`}
                                        valueKey="ExpenseType.Id"
                                        textKey={option => `${option.ExpenseType.Short} (${option.ExpenseType.Description})`}
                                        readOnly={isView}
                                        required
                                    />
                                ) : (
                                    <>
                                        <Label>Expense Type</Label>
                                        <div className="font-medium text-primary">{`${expense.ExpenseType.Description}${
                                            expense.ExpenseType.UOM ? ` (${expense.ExpenseType.UOM.Description})` : ''
                                        }`}</div>
                                    </>
                                )}
                            </div>
                            <div className="mb-4">
                                <Input type="number" name="Units" label="Units" readOnly={isView} />
                            </div>
                            <div className="mb-4">
                                {!isView ? (
                                    <FetchSelectorConsumer
                                        label="Incurred By"
                                        name="IncurredBy_Id"
                                        url={`cases/${item.Case.Id}/recipients`}
                                        valueKey="Id"
                                        textKey={option => `${option.FirstName} ${option.LastName}`}
                                        customPlaceholder={'Select who incurred this expense...'}
                                        readOnly={isView}
                                        required
                                    />
                                ) : (
                                    <>
                                        <Label>Incurred By</Label>
                                        <div className="font-medium text-primary">
                                            {expense.IncurredBy ? (
                                                `${expense.IncurredBy.FirstName} ${expense.IncurredBy.FirstName}`
                                            ) : (
                                                <span className="italic text-grey">Nobody</span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="mb-4">
                                <Input step="0.01" type="number" name="Override" label="Override" readOnly={isView} />
                                <p className="text-sm italic pl-2 text-grey-dark">Enter 0 or a blank value for no override.</p>
                            </div>
                            <div className="mb-4">
                                {!isView ? (
                                    <Checkbox type="checkbox" name="Reimbursable" label="Reimbursable?" />
                                ) : (
                                    <div className="block text-primary text-sm">
                                        Reimbursable?
                                        <i
                                            className={`ml-4 fas ${expense.Reimbursable ? `fa-check text-green-light` : `fa-times text-grey-light`}`}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full md:w-1/2  md:px-4">
                            {!isView ? (
                                <TextArea label="Note" name="Note" rows="10" readOnly={isView} />
                            ) : (
                                <>
                                    <Label>Note</Label>
                                    <div className="font-medium text-primary">{expense.Note}</div>
                                </>
                            )}
                        </div>
                    </div>
                    {!isView ? (
                        <div className="flex justify-end items-end mt-4">
                            <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                Cancel
                            </Button>
                            {!(updatingExpense || creatingExpense) ? (
                                <Button type="submit">{expense ? 'Update' : 'Add'} Expense</Button>
                            ) : (
                                <Button disabled>
                                    {expense ? 'Updating' : 'Adding'} Expense... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    errors: (state.item._errors && state.item._errors.ModelState) || {},
    creatingExpense: state.item._updating._creatingExpense,
    updatingExpense: state.item._updating._updatingExpense
});

const mapDispatchToProps = dispatch => ({
    createExpense: (caseId, itemId, expense) => dispatch.item.createExpense({ caseId, itemId, expense }),
    updateExpense: (caseId, itemId, expense) => dispatch.item.updateExpense({ caseId, itemId, expense })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemExpenseModal);
