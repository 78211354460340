import React, { Component } from 'react';
import { connect } from 'react-redux';

import { accountSelectors } from '../../../../../account/duck';
import Guard from '../../../../../common/Guard';
import { roles } from '../../../../../../constants';

import Button from '../../../../../common/Button';
import CaseFeeForm from './CaseFeeForm';
import Modal from '../../../../../common/Modal';

class CaseFeeManager extends Component {
    state = {
        showCreateModal: false
    };

    openModal = caseType => {
        this.setState({
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    render() {
        const { caseFees, isDiscontinued, readOnly, orgId, caseType, hasPermission } = this.props;
        const { showCreateModal } = this.state;

        return (
            <>
                <div className="flex justify-between items-center my-4">
                    <h1 className="text-base text-primary">{isDiscontinued ? 'Discontinued' : 'Completed'} Case Fees</h1>

                    {readOnly ? (
                        <></>
                    ) : (
                        <Guard permissions={[roles.Orgs.ADD_FEE_TO_ORG_CASE_TYPE]}>
                            <Button inverse onClick={this.openModal}>
                                Add {isDiscontinued ? 'Discontinued' : 'Completed'} Case Fee
                            </Button>
                        </Guard>
                    )}
                </div>
                <div className="mb-8">
                    {caseFees.length !== 0 ? (
                        caseFees.map(fee => (
                            <CaseFeeForm
                                key={`case-fee-${fee.Id}`}
                                caseFee={fee}
                                forDiscontinued={isDiscontinued}
                                readOnly={readOnly || !hasPermission([roles.Orgs.UPDATE_FEE_ON_ORG_CASE_TYPE])}
                                caseType={caseType}
                                orgId={orgId}
                                onFeeCreate={this.props.onFeeCreate}
                                closeModal={this.closeModal}
                                onFeeEdit={this.props.onFeeEdit}
                                onFeeDelete={this.props.onFeeDelete}
                                onCreated={this.props.onCreated}
                                onOverride={this.props.onOverride}
                            />
                        ))
                    ) : (
                        <div className="text-primary">There are no {isDiscontinued ? 'discontinued' : 'completed'} case fees.</div>
                    )}
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showCreateModal ? (
                            <CaseFeeForm
                                forDiscontinued={isDiscontinued}
                                caseType={caseType}
                                orgId={orgId}
                                onFeeCreate={this.props.onFeeCreate}
                                onFeeEdit={this.props.onFeeEdit}
                                closeModal={this.closeModal}
                                onFeeDelete={this.props.onFeeDelete}
                                onCreated={this.props.onCreated}
                                onOverride={this.props.onOverride}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

export default connect(mapStateToProps)(CaseFeeManager);
