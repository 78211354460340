import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import numeral from 'numeral';

import { roles } from '../../../../../../constants';
import { baseUrl } from '../../../../../../config';
import { makeCancelable } from '../../../../../../utils';
import { accountSelectors } from '../../../../../account/duck';

import { Currency, Input, DatePicker, FormDateRangePicker } from '../../../../../common/form';
import { Form, FormConsumer } from '../../../../../common/form/Form';
import CaseFeeManager from './CaseFeeManager';

class OrgCaseTypeDetails extends Component {
    state = {
        errors: {}
    };

    componentWillUnmount() {
        if (this.updateOrgBasePricePromise) this.updateOrgBasePricePromise.cancel();
    }

    updateBasePrice = values => {
        const { access_token, orgCaseType, orgId } = this.props;
        const formValues = {
            BasePrice: numeral(values['BasePrice']).value(),
            ActiveFrom: values['ActiveFrom'],
            ActiveTo: values['ActiveTo']
        };

        if (
            formValues.BasePrice === orgCaseType.BaseCost &&
            formValues.ActiveFrom === orgCaseType.ActiveFrom &&
            formValues.ActiveTo === orgCaseType.ActiveTo
        ) return;

        this.updateOrgBasePricePromise = makeCancelable(
            new Promise(async r => {
                try {
                    const response = orgCaseType.Inherited
                        ? await this.props.createOrgCaseType({
                            ...{
                                Org_Id: orgId,
                                CaseType_Id: orgCaseType.CaseType.Id,
                                CaseTemplate_Id: orgCaseType.CaseTemplate ? orgCaseType.CaseTemplate.Id : null,
                                BasePrice: orgCaseType.BaseCost,
                                ActiveFrom: orgCaseType.ActiveFrom,
                                ActiveTo: orgCaseType.ActiveTo
                            },
                            ...formValues
                        })
                        : await axios.put(
                            `${baseUrl}/api/orgs/${orgId}/case-types/${orgCaseType.Id}`, formValues,
                            {
                                headers: { Authorization: `Bearer ${access_token}` }
                            }
                        );

                    if (orgCaseType.Inherited) {
                        this.props.onOverride(response.data, orgCaseType.Id);
                    } else {
                        this.props.onPriceUpdated(orgCaseType.Id, formValues);
                    }

                    toast.success(
                        `The pricing rules were updated successfully.`
                    );
                    this.setState({
                        ...this.state,
                        errors: {}
                    });
                } catch (e) {
                    toast.error('There was an error updating the pricing rules.');
                    this.setState({
                        ...this.state,
                        errors: e.response.data.ModelState || {}
                    });
                }
            })
        );
    };

    render() {
        const { orgCaseType, orgId, hasPermission } = this.props;
        const { errors } = this.state;
        const readOnly = !hasPermission([roles.Orgs.UPDATE_ORG_CASE_TYPE]);

        return (<>
            <Form defaultValues={{ BasePrice: orgCaseType.BaseCost, ActiveFrom: orgCaseType.ActiveFrom, ActiveTo: orgCaseType.ActiveTo }} errors={errors} onSubmit={this.updateBasePrice}>
                <div className="flex -mx-2">
                    <div className="w-1/3 px-2">
                        <Currency type="text" name="BasePrice" label="Base Cost" required readOnly={readOnly} submitOnBlur />
                    </div>
                    <div className="w-1/3 px-2">
                        <DatePicker name="ActiveFrom" label="Active From" showLabel={true} showError={true} readOnly={readOnly} submitOnBlur />
                    </div>
                    <div className="w-1/3 px-2">
                        <DatePicker name="ActiveTo" label="Active To" showLabel={true} showError={true} readOnly={readOnly} submitOnBlur />
                    </div>
                </div>
            </Form>
            <CaseFeeManager
                caseFees={orgCaseType.CompletedCaseFees}
                isDiscontinued={false}
                readOnly={readOnly}
                orgId={orgId}
                caseType={orgCaseType}
                onFeeCreate={this.props.onFeeCreate}
                onFeeEdit={this.props.onFeeEdit}
                onFeeDelete={this.props.onFeeDelete}
                onCreated={this.props.onCreated}
                onOverride={this.props.onOverride}
            />
            <CaseFeeManager
                caseFees={orgCaseType.DiscontinuedCaseFees}
                isDiscontinued={true}
                readOnly={readOnly}
                orgId={orgId}
                caseType={orgCaseType}
                onFeeCreate={this.props.onFeeCreate}
                onFeeEdit={this.props.onFeeEdit}
                onFeeDelete={this.props.onFeeDelete}
                onCreated={this.props.onCreated}
                onOverride={this.props.onOverride}
            />
        </>);
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

const mapDispatchToProps = dispatch => ({
    createOrgCaseType: dispatch.orgCaseType.createOrgCaseType
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgCaseTypeDetails);
