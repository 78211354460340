import React from 'react';
import _ from 'lodash';

import Guard from '../../../../common/Guard';
import { roles } from '../../../../../constants';

import Button from '../../../../common/Button';
import { Checkbox } from '../../../../common/form';
import { Form, FormConsumer } from '../../../../common/form/Form';
import QboCompanySelector from '../../../../common/QboCompanySelector';

const OrgInvoicesForm = ({ defaultValues, errors, onSubmit, updating }) => (
    <Form defaultValues={defaultValues} errors={errors} onSubmit={onSubmit}>
        <>
            <div className="flex flex-wrap -mx-2">
                <div className="w-1/2 px-2">
                    <QboCompanySelector
                        name="QboId"
                        label="QuickBooks Company"
                        required />
                </div>
                <div className="w-1/2 px-2 pt-4">
                    <Checkbox type="checkbox" name="IncludeLast4SSN" label="Include Last 4 SSN in Details" />
                </div>
            </div>
            <div className="flex justify-end">
                <Guard permissions={[roles.Orgs.UPDATE_ORG_INVOICE_SETTINGS]}>
                    <FormConsumer>
                        {({ values }) => <Button disabled={_.isEqual(values, defaultValues) || updating} type="submit">
                            {!updating ?
                                'Save Changes' :
                                <>Saving Changes... <i className="fas fa-spinner fa-pulse" /></>
                            }
                        </Button>}
                    </FormConsumer>
                </Guard>
            </div>
        </>
    </Form>
);

export default OrgInvoicesForm;