import React from 'react';

const CustomSearchBox = React.forwardRef((props, ref) => (
    <div className="relative flex items-center">
        <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight"
            {...props}
            ref={ref}
        />
        <i className="fa fa-search absolute pin-r mr-2 text-accent" />
    </div>
));

export default CustomSearchBox;
