import types from './types';

const getOrgsRequest = () => ({
    type: types.GET_ORGS_REQUEST
});
const getOrgsSuccess = (orgs) => ({
    type: types.GET_ORGS_SUCCESS,
    payload: orgs
});
const getOrgsFailure = (errors) => ({
    type: types.GET_ORGS_FAILURE,
    payload: errors
});

const updateOrgParentRequest = orgId => ({
    type: types.UPDATE_ORG_PARENT_REQUEST,
    payload: orgId
});
const updateOrgParentSuccess = (orgId, parentOrgId) => ({
    type: types.UPDATE_ORG_PARENT_SUCCESS,
    payload: { orgId, parentOrgId }
});
const updateOrgParentFailure = (orgId, errors) => ({
    type: types.UPDATE_ORG_PARENT_FAILURE,
    payload: { orgId, errors }
});

const createOrgRequest = () => ({
    type: types.CREATE_ORG_REQUEST
});
const createOrgSuccess = (org) => ({
    type: types.CREATE_ORG_SUCCESS,
    payload: org
});
const createOrgFailure = (errors) => ({
    type: types.CREATE_ORG_FAILURE,
    payload: errors
});

const deleteOrgRequest = orgId => ({
    type: types.DELETE_ORG_REQUEST,
    payload: orgId
});
const deleteOrgSuccess = orgId => ({
    type: types.DELETE_ORG_SUCCESS,
    payload: orgId
});
const deleteOrgFailure = (orgId, errors) => ({
    type: types.DELETE_ORG_FAILURE,
    payload: { orgId, errors }
});

export default {
    getOrgsRequest,
    getOrgsSuccess,
    getOrgsFailure,
    updateOrgParentRequest,
    updateOrgParentSuccess,
    updateOrgParentFailure,
    createOrgRequest,
    createOrgSuccess,
    createOrgFailure,
    deleteOrgRequest,
    deleteOrgSuccess,
    deleteOrgFailure
}