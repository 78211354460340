const GET_CASE_TEMPLATES_REQUEST = 'app/case-templates/GET_CASE_TEMPLATES_REQUEST';
const GET_CASE_TEMPLATES_SUCCESS = 'app/case-templates/GET_CASE_TEMPLATES_SUCCESS';
const GET_CASE_TEMPLATES_FAILURE = 'app/case-templates/GET_CASE_TEMPLATES_FAILURE';

const CREATE_CASE_TEMPLATE_REQUEST = 'app/case-templates/CREATE_CASE_TEMPLATE_REQUEST';
const CREATE_CASE_TEMPLATE_SUCCESS = 'app/case-templates/CREATE_CASE_TEMPLATE_SUCCESS';
const CREATE_CASE_TEMPLATE_FAILURE = 'app/case-templates/CREATE_CASE_TEMPLATE_FAILURE';

const EDIT_CASE_TEMPLATE_REQUEST = 'app/case-templates/EDIT_CASE_TEMPLATE_REQUEST';
const EDIT_CASE_TEMPLATE_SUCCESS = 'app/case-templates/EDIT_CASE_TEMPLATE_SUCCESS';
const EDIT_CASE_TEMPLATE_FAILURE = 'app/case-templates/EDIT_CASE_TEMPLATE_FAILURE';

const DELETE_CASE_TEMPLATE_REQUEST = 'app/case-templates/DELETE_CASE_TEMPLATE_REQUEST';
const DELETE_CASE_TEMPLATE_SUCCESS = 'app/case-templates/DELETE_CASE_TEMPLATE_SUCCESS';
const DELETE_CASE_TEMPLATE_FAILURE = 'app/case-templates/DELETE_CASE_TEMPLATE_FAILURE';

const ADD_ITEM_TO_CASE_TEMPLATE_REQUEST = 'app/case-templates/ADD_ITEM_TO_CASE_TEMPLATE_REQUEST';
const ADD_ITEM_TO_CASE_TEMPLATE_SUCCESS = 'app/case-templates/ADD_ITEM_TO_CASE_TEMPLATE_SUCCESS';
const ADD_ITEM_TO_CASE_TEMPLATE_FAILURE = 'app/case-templates/ADD_ITEM_TO_CASE_TEMPLATE_FAILURE';

const REMOVE_ITEM_FROM_CASE_TEMPLATE_REQUEST = 'app/case-templates/REMOVE_ITEM_FROM_CASE_TEMPLATE_REQUEST';
const REMOVE_ITEM_FROM_CASE_TEMPLATE_SUCCESS = 'app/case-templates/REMOVE_ITEM_FROM_CASE_TEMPLATE_SUCCESS';
const REMOVE_ITEM_FROM_CASE_TEMPLATE_FAILURE = 'app/case-templates/REMOVE_ITEM_FROM_CASE_TEMPLATE_FAILURE';

const SWAP_CASE_TEMPLATE_ITEM_REQUEST = 'app/case-templates/SWAP_CASE_TEMPLATE_ITEM_REQUEST';
const SWAP_CASE_TEMPLATE_ITEM_SUCCESS = 'app/case-templates/SWAP_CASE_TEMPLATE_ITEM_SUCCESS';
const SWAP_CASE_TEMPLATE_ITEM_FAILURE = 'app/case-templates/SWAP_CASE_TEMPLATE_ITEM_FAILURE';

export default {
    GET_CASE_TEMPLATES_REQUEST,
    GET_CASE_TEMPLATES_SUCCESS,
    GET_CASE_TEMPLATES_FAILURE,
    CREATE_CASE_TEMPLATE_REQUEST,
    CREATE_CASE_TEMPLATE_SUCCESS,
    CREATE_CASE_TEMPLATE_FAILURE,
    EDIT_CASE_TEMPLATE_REQUEST,
    EDIT_CASE_TEMPLATE_SUCCESS,
    EDIT_CASE_TEMPLATE_FAILURE,
    DELETE_CASE_TEMPLATE_REQUEST,
    DELETE_CASE_TEMPLATE_SUCCESS,
    DELETE_CASE_TEMPLATE_FAILURE,
    ADD_ITEM_TO_CASE_TEMPLATE_REQUEST,
    ADD_ITEM_TO_CASE_TEMPLATE_SUCCESS,
    ADD_ITEM_TO_CASE_TEMPLATE_FAILURE,
    REMOVE_ITEM_FROM_CASE_TEMPLATE_REQUEST,
    REMOVE_ITEM_FROM_CASE_TEMPLATE_SUCCESS,
    REMOVE_ITEM_FROM_CASE_TEMPLATE_FAILURE,
    SWAP_CASE_TEMPLATE_ITEM_REQUEST,
    SWAP_CASE_TEMPLATE_ITEM_SUCCESS,
    SWAP_CASE_TEMPLATE_ITEM_FAILURE
};