import { toast } from 'react-toastify';
import _ from 'lodash';

let messages = {
    success: [],
    error: [],
    warn: [],
    info: [],
    default: []
};

function batchMessages() {
    Object.keys(messages).forEach(key => {
        _.uniq(messages[key]).forEach(message => {
            if (toast[key]) {
                toast[key](message, { autoClose: 8000 });
            } else {
                toast(message);
            }
        });
        messages[key] = [];
    });
}
let batchInterval = setInterval(batchMessages, 1000);

export default (message, type) => {
    if (messages[type]) {
        messages[type].push(message);
    } else {
        messages.default.push(message);
    }

    clearInterval(batchInterval);
    batchInterval = setInterval(batchMessages, 1000);
};
