import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Component from './Security';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

export default () => (
    <Route key="security-router" path="/security">
        <Switch key="security-router-switch">
            <Guard key="security-route" exact path="/security" notFound={NotFound} permissions={[roles.Admin.ADMIN_SECURITY]}>
                <AuthRoute exact path="/security" component={Component} />
            </Guard>
        </Switch>
    </Route>
);
