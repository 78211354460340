import types from './types';

const logInRequest = () => ({
    type: types.LOG_IN_REQUEST
});
const logInSuccess = (auth) => ({
    type: types.LOG_IN_SUCCESS,
    payload: auth
});
const logInFailure = (errors) => ({
    type: types.LOG_IN_FAILURE,
    payload: errors
});
const logInComplete = () => ({
    type: types.LOG_IN_COMPLETE
});

const logOut = () => ({
    type: types.LOG_OUT
});

const loadUserRequest = () => ({
    type: types.LOAD_USER_REQUEST
});
const loadUserSuccess = (user) => ({
    type: types.LOAD_USER_SUCCESS,
    payload: user
});
const loadUserFailure = (errors) => ({
    type: types.LOAD_USER_FAILURE,
    payload: errors
});

const loadUserPermissionsRequest = () => ({
    type: types.LOAD_USER_PERMISSIONS_REQUEST
});
const loadUserPermissionsSuccess = (permissions) => ({
    type: types.LOAD_USER_PERMISSIONS_SUCCESS,
    payload: permissions
});
const loadUserPermissionsFailure = (errors) => ({
    type: types.LOAD_USER_PERMISSIONS_FAILURE,
    payload: errors
});

const loadUserAddressRequest = () => ({
    type: types.LOAD_USER_ADDRESS_REQUEST
});
const loadUserAddressSuccess = (address) => ({
    type: types.LOAD_USER_ADDRESS_SUCCESS,
    payload: address
});
const loadUserAddressFailure = (errors) => ({
    type: types.LOAD_USER_ADDRESS_FAILURE,
    payload: errors
});

const loadUserPhoneNumbersRequest = () => ({
    type: types.LOAD_USER_PHONE_NUMBERS_REQUEST
});
const loadUserPhoneNumbersSuccess = (phone_numbers) => ({
    type: types.LOAD_USER_PHONE_NUMBERS_SUCCESS,
    payload: phone_numbers
});
const loadUserPhoneNumbersFailure = (errors) => ({
    type: types.LOAD_USER_PHONE_NUMBERS_FAILURE,
    payload: errors
});

const updateUserRequest = () => ({
    type: types.UPDATE_USER_REQUEST
});
const updateUserSuccess = (user) => ({
    type: types.UPDATE_USER_SUCCESS,
    payload: user
});
const updateUserFailure = (errors) => ({
    type: types.UPDATE_USER_FAILURE,
    payload: errors
});

const updateUserAddressRequest = () => ({
    type: types.UPDATE_USER_ADDRESS_REQUEST
});
const updateUserAddressSuccess = (address) => ({
    type: types.UPDATE_USER_ADDRESS_SUCCESS,
    payload: address
});
const updateUserAddressFailure = (errors) => ({
    type: types.UPDATE_USER_ADDRESS_FAILURE,
    payload: errors
});

const updateUserPhoneRequest = (phoneId) => ({
    type: types.UPDATE_USER_PHONE_REQUEST,
    payload: phoneId
});
const updateUserPhoneSuccess = (phoneId, phoneNumbers) => ({
    type: types.UPDATE_USER_PHONE_SUCCESS,
    payload: { phoneId, phoneNumbers }
});
const updateUserPhoneFailure = (phoneId, errors) => ({
    type: types.UPDATE_USER_PHONE_FAILURE,
    payload: { phoneId, errors }
});

const createUserPhoneRequest = () => ({
    type: types.CREATE_USER_PHONE_REQUEST
});
const createUserPhoneSuccess = (phoneNumbers) => ({
    type: types.CREATE_USER_PHONE_SUCCESS,
    payload: phoneNumbers
});
const createUserPhoneFailure = (errors) => ({
    type: types.CREATE_USER_PHONE_FAILURE,
    payload: errors
});

const deleteUserPhoneRequest = (phoneId) => ({
    type: types.DELETE_USER_PHONE_REQUEST,
    payload: phoneId
});
const deleteUserPhoneSuccess = (phoneId, phoneNumbers) => ({
    type: types.DELETE_USER_PHONE_SUCCESS,
    payload: { phoneId, phoneNumbers }
});
const deleteUserPhoneFailure = (phoneId, errors) => ({
    type: types.DELETE_USER_PHONE_FAILURE,
    payload: { phoneId, errors }
});

const updatePasswordRequest = () => ({
    type: types.UPDATE_PASSWORD_REQUEST
});
const updatePasswordSuccess = () => ({
    type: types.UPDATE_PASSWORD_SUCCESS
});
const updatePasswordFailure = (errors) => ({
    type: types.UPDATE_PASSWORD_FAILURE,
    payload: errors
});

export default {
    logInRequest,
    logInSuccess,
    logInFailure,
    logInComplete,
    logOut,
    loadUserRequest,
    loadUserSuccess,
    loadUserFailure,
    loadUserPermissionsRequest,
    loadUserPermissionsSuccess,
    loadUserPermissionsFailure,
    loadUserAddressRequest,
    loadUserAddressSuccess,
    loadUserAddressFailure,
    loadUserPhoneNumbersRequest,
    loadUserPhoneNumbersSuccess,
    loadUserPhoneNumbersFailure,
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
    updateUserAddressRequest,
    updateUserAddressSuccess,
    updateUserAddressFailure,
    updateUserPhoneRequest,
    updateUserPhoneSuccess,
    updateUserPhoneFailure,
    createUserPhoneRequest,
    createUserPhoneSuccess,
    createUserPhoneFailure,
    deleteUserPhoneRequest,
    deleteUserPhoneSuccess,
    deleteUserPhoneFailure,
    updatePasswordRequest,
    updatePasswordSuccess,
    updatePasswordFailure,
};