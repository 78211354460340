import adminRouter from './admin/router';
import { casesRouter, casesEditRouter } from './cases/router';
import accountRouter from './account/router';
import identityRouter from './identity/router';
import invoicesRouter from './invoices/router';
import typesRouter from './types/router';
import sectionsRouter from './sections/router';
import securityRouter from './security/router';
import itemTypesRouter from './item-types/router';
import caseTemplatesRouter from './case-templates/router';
import orgsRouter from './orgs/router';
import expenseTypesRouter from './expense-types/router';
import { itemsRouter, itemsListRouter } from './items/router';
import reportsRouter from './reports/router';

const routes = [
    adminRouter(),
    casesRouter(),
    casesEditRouter(),
    itemsRouter(),
    itemsListRouter(),
    typesRouter(),
    accountRouter(),
    identityRouter(),
    invoicesRouter(),
    sectionsRouter(),
    securityRouter(),
    itemTypesRouter(),
    caseTemplatesRouter(),
    orgsRouter(),
    expenseTypesRouter(),
    reportsRouter()
];

export default routes;
