import Creators from './actions';
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { optional } from '../../../utils';

import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

import { typesOperations } from '../../types/duck';

//const logOut = Creators.logOut;

const logOut = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();

    axios.post(`${baseUrl}/api/user/logout`, null, {
        headers: { Authorization: `Bearer ${access_token}` }
    });

    dispatch(Creators.logOut());
    Object.keys(localStorage).forEach(key => localStorage.removeItem(key));
};

const loadUserPhoneNumbers = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.loadUserPhoneNumbersRequest());

    try {
        const phoneNumbers = await axios.get(`${baseUrl}/api/user/phones`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.loadUserPhoneNumbersSuccess(phoneNumbers.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.loadUserPhoneNumbersFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const loadUserAddress = () => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.loadUserAddressRequest());

    try {
        const address = await axios.get(`${baseUrl}/api/user/address`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.loadUserAddressSuccess(address.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.loadUserAddressFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const loadUserPermissions = (isLogin = false) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token },
            user
        }
    } = getState();
    dispatch(Creators.loadUserPermissionsRequest());

    try {
        const permissions = await axios.get(`${baseUrl}/api/user/permissions`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.loadUserPermissionsSuccess(permissions.data));
        if (isLogin) {
            dispatch(Creators.logInComplete());
            toast(`Welcome back, ${user.FirstName} ${user.LastName}!`);
        }
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.loadUserPermissionsFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const loadUser = (isLogin = false) => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.loadUserRequest());

    dispatch(typesOperations.getTypes());

    try {
        const user = await axios.get(`${baseUrl}/api/user`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.loadUserSuccess(user.data));
        dispatch(loadUserPermissions(isLogin));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.loadUserFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const logIn = (username, password) => async dispatch => {
    dispatch(Creators.logInRequest());

    try {
        const auth = await axios.post(
            `${baseUrl}/oauth/token`,
            qs.stringify({
                username,
                password,
                grant_type: 'password'
            }),
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }
        );

        dispatch(Creators.logInSuccess(auth.data));
        dispatch(loadUser(true));
        return auth;
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.logInFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );

        throw error;
    }
};

const updateUser = user => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateUserRequest());

    try {
        const newUser = await axios.put(
            `${baseUrl}/api/user`,
            {
                ...user
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        dispatch(Creators.updateUserSuccess(newUser.data));
        toast.success('Your account was successfully updated.');
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        else if (optional(error, target => target.response.data.ModelState, false)) {
            let errorMessages = [];
            Object.keys(error.response.data.ModelState).forEach(key => {
                errorMessages.push(error.response.data.ModelState[key].join());
            });
            _.uniq(errorMessages).forEach(message => toast.error(message));
        } else if (error.response.data) {
            toast.error(error.response.data.Message);
        }
        dispatch(Creators.updateUserFailure(error.response));
    }
};

const updateAddress = address => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateUserAddressRequest());

    try {
        const newAddress = await axios.patch(
            `${baseUrl}/api/user/address`,
            {
                ...address,
                RegionType_Id: +address.RegionType_Id
            },
            {
                headers: { Authorization: `Bearer ${access_token}` }
            }
        );

        dispatch(Creators.updateUserAddressSuccess(newAddress.data));
        toast.success('Your address was successfully updated.');
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.updateUserAddressFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const updatePhoneNumber = phoneNumber => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updateUserPhoneRequest(phoneNumber.Id));

    try {
        const phoneNumbers = await axios.patch(`${baseUrl}/api/user/phones/${phoneNumber.Id}`, phoneNumber, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.updateUserPhoneSuccess(phoneNumber.Id, phoneNumbers.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.updateUserPhoneFailure(
                phoneNumber.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const createPhoneNumber = phoneNumber => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.createUserPhoneRequest());

    try {
        const phoneNumbers = await axios.post(`${baseUrl}/api/user/phones`, phoneNumber, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.createUserPhoneSuccess(phoneNumbers.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.createUserPhoneFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const deletePhoneNumber = phoneNumber => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.deleteUserPhoneRequest(phoneNumber.Id));

    try {
        const phoneNumbers = await axios.delete(`${baseUrl}/api/user/phones/${phoneNumber.Id}`, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.deleteUserPhoneSuccess(phoneNumber.Id, phoneNumbers.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.deleteUserPhoneFailure(
                phoneNumber.Id,
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

const updatePassword = passwordObject => async (dispatch, getState) => {
    const {
        account: {
            auth: { access_token }
        }
    } = getState();
    dispatch(Creators.updatePasswordRequest());

    try {
        await axios.post(`${baseUrl}/api/user/password`, passwordObject, {
            headers: { Authorization: `Bearer ${access_token}` }
        });

        dispatch(Creators.updatePasswordSuccess());
        toast.success('Your password was successfully updated.');
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(
            Creators.updatePasswordFailure(
                error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
            )
        );
    }
};

export default {
    logIn,
    logOut,
    updateUser,
    loadUserAddress,
    updateAddress,
    loadUserPhoneNumbers,
    updatePhoneNumber,
    createPhoneNumber,
    deletePhoneNumber,
    updatePassword,
    loadUser
};
