import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { baseUrl } from '../../../../config';
import { orgOperations } from '../../duck';

import { makeCancelable } from '../../../../utils';
import OrgGeneralForm from './OrgGeneral/OrgGeneralForm';

class OrgGeneral extends Component {
    state = {
        updatingGeneral: false,
        loadingGeneral: false,
        orgGeneral: undefined,
        errors: {}
    };

    componentDidMount() {
        const {
            access_token,
            match: {
                params: { orgId }
            }
        } = this.props;

        this.orgGeneralPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        loadingGeneral: true
                    },
                    async () => {
                        try {
                            const orgGeneral = await axios.get(`${baseUrl}/api/orgs/${orgId}/general`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.setState({
                                ...this.state,
                                loadingGeneral: false,
                                orgGeneral: orgGeneral.data
                            });
                        } catch (e) {
                            toast.error('There was an error loading the general settings for this org.');
                            this.setState({
                                ...this.state,
                                orgGeneral: undefined,
                                loadingGeneral: false
                            });
                        }
                    }
                );
            })
        );
    }

    componentWillUnmount() {
        if (this.orgGeneralPromise) this.orgGeneralPromise.cancel();
        if (this.orgGeneralUpdatePromise) this.orgGeneralUpdatePromise.cancel();
    }

    updateOrgGeneral = values => {
        const {
            access_token,
            match: {
                params: { orgId }
            }
        } = this.props;

        values.PrependAttachmentsToROI = !!values.PrependAttachmentsToROI;

        this.orgGeneralUpdatePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        updatingGeneral: true
                    },
                    async () => {
                        try {
                            await axios.put(`${baseUrl}/api/orgs/${orgId}/general`, values, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            toast.success(`${values.Name} was updated successfully.`);
                            this.setState({
                                ...this.state,
                                errors: {},
                                updatingGeneral: false,
                                orgGeneral: values
                            });
                            this.props.refreshOrgs();
                        } catch (error) {
                            toast.error('There was an error when trying to update the org.');
                            this.setState({
                                ...this.state,
                                errors: error.response.data,
                                updatingGeneral: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { updatingGeneral, loadingGeneral, orgGeneral, errors } = this.state;
        return (
            <>
                {!loadingGeneral ? (
                    orgGeneral !== undefined ? (
                        <OrgGeneralForm
                            defaultValues={orgGeneral}
                            errors={errors.ModelState || {}}
                            onSubmit={this.updateOrgGeneral}
                            updating={updatingGeneral}
                        />
                    ) : (
                            <span>There was an error loading the general settings for this org. Please try again.</span>
                        )
                ) : (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    refreshOrgs: () => dispatch(orgOperations.getOrgs())
});

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgGeneral));
