import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import { Form, FormConsumer } from '../../common/form/Form';
import Guard from '../../common/Guard';
import OrgSelect from '../../common/form/OrgSelect';
import { FetchSelectorConsumer, Input, DatePicker, Label, TextArea, Section, Header, Divider } from '../../common/form/';
import { tags, roles } from '../../../constants';
import Button from '../../common/Button';
import { accountSelectors } from '../../account/duck';
import Attachments from './Attachments';
import Messages from './Messages';
import AdditionalInformations from './AdditionalInformations';
import Modal from '../../common/Modal';
import { TrackingInfoForm } from './TrackingInfoForm';
import ItemsList from '../../items/ItemsList';

class CaseForm extends Component {
    state = {
        showTrackingModal: false
    };

    submitTrackingInfo = async values => {
        try {
            await this.props.addTrackingInfo(values);
            this.props.onTrackingInfoAdded && this.props.onTrackingInfoAdded({ Id: shortid.generate(), ...values });

            this.setState({
                showTrackingModal: false
            });
        } catch (error) {
            this.setState({
                errors: error
            });
        }
    };
    
    render() {
        const {
            onSubmit,
            user,
            defaultValues = {
                RequestingOfficial_Id: user.Id,
                Client_Id: user.Org_Id
            },
            values,
            requiredFields,
            disableFields = {},
            hideFields = {},
            isSaving,
            orgs,
            showDates,
            showMessages,
            showItems,
            hasPermission,
            isAddingTackingInfo,
            errors,
            hasDuplicate,
            onChange = () => { },
            onAttachmentAdded,
            onAttachmentDeleted,
            canChangeCaseType
        } = this.props;
        const org = orgs.find(org => org.Id === user.Org_Id);

        return (
            <>
                <Form
                    onSubmit={onSubmit}
                    defaultValues={{
                        ...defaultValues,
                        ContractType_Id: org ? org.ContractType_Id : 0
                    }}
                    values={values}
                    errors={errors}
                >
                    <Section>
                        <div className="flex flex-wrap -mx-3 mt-2">
                            <div className={`mb-4 flex-grow md:w-1/2 px-3`}>
                                {hideFields['ContractType_Id'] && (
                                    <>
                                        <Label htmlFor="">Contract Type</Label>
                                        <FormConsumer>{({ values }) => <p>{values.ContractType}</p>}</FormConsumer>
                                    </>
                                )}
                            </div>
                            <div className={`mb-4 flex-grow px-3 ${hideFields['ContractType_Id'] ? 'md:w-1/2' : 'w-full'}`}>
                                <FormConsumer>
                                    {({ values }) => (
                                        <FetchSelectorConsumer
                                            label="Requesting Official"
                                            name="RequestingOfficial_Id"
                                            url={`cases/requesting-official/${(values && values.Client_Id) || ''}`}
                                            valueKey="Id"
                                            textKey="Name"
                                            disabled={disableFields['RequestingOfficial_Id']}
                                            required={true}
                                        />
                                    )}
                                </FormConsumer>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className="px-3 w-full">
                                <FormConsumer>
                                    {() => {
                                        if (hideFields['Client_Id'])
                                            return (
                                                <div className="mb-4">
                                                    <Label htmlFor="">Client/Facility</Label>
                                                    <FormConsumer>{({ values }) => <p>{values.Org}</p>}</FormConsumer>
                                                </div>
                                            );
                                        return (
                                            <OrgSelect
                                                name="Client_Id"
                                                label="Client/Facility"
                                                required={true}
                                                onChange={org => {
                                                    onChange('Client_Id', org.Id);
                                                }}
                                            />
                                        );
                                    }}
                                </FormConsumer>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className={`mb-4 flex-grow px-3  ${hideFields['CaseNumber'] ? '' : 'md:w-1/2 px-3'}`}>
                                {hideFields['CaseTemplate_Id'] ? (
                                    <>
                                        <Label htmlFor="">Case Type</Label>
                                        <FormConsumer>{({ values }) => <p>{values.CaseType}</p>}</FormConsumer>
                                    </>
                                ) : (
                                    <FormConsumer>
                                        {({ values }) => (
                                            <div className="w-full">
                                                <FetchSelectorConsumer
                                                    label="Case Type"
                                                    name="CaseType_Id"
                                                    url={`orgs/${values.Client_Id}/case-types?onlyActive=true`}
                                                    valueKey="CaseType.Id"
                                                    textKey={option => `${option.CaseType.Short} (${option.CaseType.Description})`}
                                                    required={true}
                                                    onChange={onChange}
                                                    disabled={!canChangeCaseType}
                                                />
                                            </div>
                                        )}
                                    </FormConsumer>
                                )}
                            </div>
                            {hideFields['CaseNumber'] ? (
                                <></>
                            ) : (
                                <div className="mb-4 flex-grow md:w-1/2 px-3">
                                    <Input name="CaseNumber" label="Case Number" type="text" required={true} disabled={disableFields['CaseNumber']} />
                                </div>
                            )}
                        </div>

                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className="mb-4 flex-grow md:w-1/2 px-3">
                                <FormConsumer>
                                    {({ values }) => (
                                        <FetchSelectorConsumer
                                            label="Data Entry"
                                            name="DataEntryClerk_Id"
                                            url={`cases/assignees/${(values && values.Client_Id) || ''}/${tags.DATA_ENTRY}`}
                                            valueKey="Id"
                                            textKey="Name"
                                            disabled={disableFields['DataEntryClerk_Id']}
                                            hidden={hideFields['DataEntryClerk_Id']}
                                        />
                                    )}
                                </FormConsumer>
                            </div>
                            <div className="mb-4 flex-grow md:w-1/2 px-3">
                                <FormConsumer>
                                    {({ values }) => (
                                        <FetchSelectorConsumer
                                            label="Reviewer"
                                            name="Reviewer_Id"
                                            url={`cases/${defaultValues.Id}/org/${values && values.Client_Id}/reviewers`}
                                            valueKey="Id"
                                            textKey="Name"
                                            disabled={disableFields['Reviewer_Id']}
                                            hidden={hideFields['Reviewer_Id']}
                                        />
                                    )}
                                </FormConsumer>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className="mb-4 flex-grow px-3">
                                <TextArea name="Notes" label="Case Notes" className="h-32" disabled={disableFields['Notes']} />
                            </div>
                        </div>
                        {showDates ? (
                            <>
                                {' '}
                                <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                                    <div className="mb-4 flex-grow md:w-1/2 px-3">
                                        <Label>
                                            Received Date <strong className="text-red">&nbsp;*</strong>
                                        </Label>
                                        <DatePicker name="ReceivedDate" showError={true} required={true} disabled={disableFields['ReceivedDate']} />
                                    </div>
                                    <div className="mb-4 flex-grow md:w-1/2 px-3">
                                        <Label>
                                            Scheduled Date <strong className="text-red">&nbsp;*</strong>
                                        </Label>
                                        <DatePicker
                                            name="ScheduledDate"
                                            showError={true}
                                            required={true}
                                            disabled={disableFields['ScheduledDate']}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mt-2">
                                    <div className="mb-4 flex-grow sm:w-1/2 px-3">
                                        <Guard exact permissions={[roles.Cases.VIEW_CASE_CRITICAL_DATE]}>
                                            <Label>Critical Date</Label>
                                            <DatePicker
                                                name="DueDate"
                                                placeholder="Awaiting Case Papers"
                                                readonly={!hasPermission([roles.Cases.EDIT_CASE_CRITICAL_DATE])}
                                                showError={true}
                                                disabled={disableFields['DueDate']}
                                            />
                                        </Guard>
                                    </div>
                                    <div className="mb-4 flex-grow sm:w-1/2 px-3">
                                        <Label>
                                            Completion Date {requiredFields['CompletionDate'] ? <strong className="text-red">&nbsp;*</strong> : ''}
                                        </Label>
                                        <DatePicker
                                            name="CompletionDate"
                                            readonly={!hasPermission([roles.Cases.EDIT_CASE_COMPLETION_DATE])}
                                            showError={true}
                                            required={requiredFields['CompletionDate']}
                                            disabled={disableFields['CompletionDate']}
                                        />
                                    </div>
                                </div>{' '}
                            </>
                        ) : (
                            <></>
                        )}
                    </Section>
                    <Section>
                        <Header>Subject</Header>
                        <Divider />
                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className="mb-4 flex-grow px-3">
                                <Input
                                    name="FirstName"
                                    label="First Name"
                                    required={true}
                                    disabled={disableFields['FirstName']}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="mb-4 flex-grow px-3">
                                <Input name="MiddleName" label="Middle Name" disabled={disableFields['MiddleName']} />
                            </div>
                            <div className="mb-4 flex-grow px-3">
                                <Input name="LastName" label="Last Name" required={true} disabled={disableFields['LastName']} onChange={onChange} />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-2 mt-2">
                            <div className="mb-4 flex-grow px-3">
                                <Input
                                    name="SSN"
                                    label="SSN (Last 9)"
                                    required={true}
                                    disabled={disableFields['SSN']}
                                    maxLength="9"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        {hasDuplicate && (
                            <p className="my-2 w-full text-right">
                                <em>Saving this case will create a duplicate! Click continue to create a duplicate or change the case info.</em>
                            </p>
                        )}
                        {disableFields['save'] ? (
                            <></>
                        ) : (
                            <div className="flex w-full items-center justify-end">
                                {this.props.onClose && (
                                    <Button className="mr-2 bg-grey" onClick={this.props.onClose} disabled={isSaving}>
                                        Cancel
                                    </Button>
                                )}
                                {!hasDuplicate && (
                                    <Button type="submit" loading={isSaving}>
                                        Save
                                    </Button>
                                )}

                                {hasDuplicate && (
                                    <Button className="bg-yellow-dark" type="submit" loading={isSaving}>
                                        Continue
                                    </Button>
                                )}
                            </div>
                        )}
                    </Section>
                    <Section>{showItems && <ItemsList _case={defaultValues} onChange={onChange} />}</Section>
                    <Section>
                        <Attachments
                            caseId={defaultValues.Id}
                            showTrackingModal={() => this.setState({ showTrackingModal: true })}
                            onAttachmentAdded={onAttachmentAdded}
                            onAttachmentDeleted={onAttachmentDeleted}
                        />
                    </Section>
                    <Section>
                        {showMessages && hasPermission([roles.CaseMessages.VIEW_MESSAGES]) ? <Messages caseId={defaultValues.Id} /> : <></>}
                    </Section>
                  


                </Form>
                <Section>
                        <AdditionalInformations caseId={defaultValues.Id} /> 
                    </Section>
                <Modal className="max-w-md" show={this.state.showTrackingModal} onClose={() => this.setState({ showTrackingModal: false })}>
                    {this.state.showTrackingModal ? (
                        <TrackingInfoForm
                            submitTrackingInfo={this.submitTrackingInfo}
                            defaultValues={defaultValues}
                            onClose={() => this.setState({ showTrackingModal: false })}
                            isAddingTackingInfo={isAddingTackingInfo}
                        />
                    ) : (
                        <></>
                    )}
                </Modal>
            </>
        );
    }
}

const mapState = state => ({
    ContractTypes: (state.types.ContractType && state.types.ContractType.Types) || [],
    orgs: state.orgs.orgs,
    isAddingTackingInfo: state.cases._updating._addingTackingInfo,
    errors: state.cases._errors.ModelState,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});

const mapDispatch = dispatch => ({
    addTrackingInfo: dispatch.cases.addTrackingInfo
});
const CaseFormRef = React.forwardRef((props, ref) => <CaseForm {...props} ref={ref} />);
export default connect(
    mapState,
    mapDispatch,
    null,
    { withRef: true }
)(CaseForm);
