import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { identityOperations } from './duck';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class Roles extends Component {
    componentDidMount() {
        this.props.getRoles();
    }

    render() {
        const { gettingRoles, roles } = this.props;

        const columns = [
            {
                Header: 'Role Name',
                accessor: 'Name',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            }
        ];

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Roles</PageHeader>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingRoles ? (
                            <ReactTable
                                filterable
                                data={roles}
                                columns={columns}
                                defaultSorted={[{ id: 'Name', desc: false }]}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })} />
                        ) : (
                                <span>
                                    Loading... <i className="fas fa-spinner fa-pulse" />
                                </span>
                            )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    roles: state.identity.roles,
    gettingRoles: state.identity._updating._gettingRoles
});

const mapDispatchToProps = dispatch => ({
    getRoles: () => dispatch(identityOperations.getRoles())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Roles);
