import { security } from './app/security/model';
import { cases } from './app/cases/models/cases';
import { messages } from './app/cases/models/messages';
import { additionalinformations } from './app/cases/models/additionalinformations';
import { workflowState } from './app/cases/models/workflowstate';
import { items } from './app/items/models/items';
import { item } from './app/items/models/item';
import { reports } from './app/reports/models/reports';
import { stockPhrases } from './app/items/models/stockPhrases';
import { invoices } from './app/invoices/models/invoices';
import { navigation } from './app/general/models/navigation';
import { filters } from './app/general/models/filters';
import { itemTypeAssets } from './app/item-types/models/itemTypeAssets';
import { orgCaseType } from './app/orgs/components/OrgDetails/OrgCaseTypes/models/OrgCaseType';
import { orgDeadline } from './app/orgs/components/OrgDetails/OrgDeadlines/models/OrgDeadline';
import { orgExpenseRule } from './app/orgs/components/OrgDetails/OrgExpenseRules/models/OrgExpenseRule';

export default {
    security,
    cases,
    messages,
    additionalinformations,
    items,
    workflowState,
    item,
    filters,
    reports,
    stockPhrases,
    invoices,
    navigation,
    itemTypeAssets,
    orgCaseType,
    orgDeadline,
    orgExpenseRule
};
