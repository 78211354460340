import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import numeral from 'numeral';

import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';

import Button from '../../../../common/Button';
import { EnumTypeSelector, Currency, DatePicker } from '../../../../common/form';
import { Form, FormConsumer } from '../../../../common/form/Form';

import FilteredTemplateSelector from './CreateOrgCaseType/FilteredTemplateSelector';

const DEFAULT_CREATE_VALUES = {
    CaseType_Id: 0,
    CaseTemplate_Id: 0,
    BasePrice: 0,
    ActiveFrom: null,
    ActiveTo: null
};

class CreateOrgCaseType extends Component {
    state = {
        errors: {},
        isCreating: false
    };

    componentWillUnmount() {
        if (this.createOrgCaseTypePromise) this.createOrgCaseTypePromise.cancel();
    }

    createOrgCaseType = values => {
        const { access_token, orgId } = this.props;

        this.createOrgCaseTypePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isCreating: true
                    },
                    async () => {
                        try {
                            const result = await axios.post(
                                `${baseUrl}/api/orgs/${orgId}/case-types`,
                                {
                                    CaseType_Id: +values['CaseType_Id'] || null,
                                    CaseTemplate_Id: +values['CaseTemplate_Id'] || null,
                                    BasePrice: numeral(values['BasePrice']).value(),
                                    ActiveFrom: values['ActiveFrom'],
                                    ActiveTo: values['ActiveTo']
                                },
                                {
                                    headers: { Authorization: `Bearer ${access_token}` }
                                }
                            );

                            this.setState({
                                ...this.state,
                                isCreating: false
                            });

                            this.props.onCreated(result.data);
                            toast.success(`The case type was added to the org successfully.`);
                            this.props.closeModal();
                        } catch (error) {
                            toast.error(`There was an error adding the case type to the org.`);
                            this.setState({
                                ...this.state,
                                errors: error.response.data.ModelState || {},
                                isCreating: false
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { errors, isCreating } = this.state;
        const { closeModal, caseTypeId, caseTemplateId } = this.props;

        const mergeObject = {
            ...(caseTypeId ? { CaseType_Id: caseTypeId } : {}),
            ...(caseTemplateId ? { CaseTemplate_Id: caseTemplateId } : {})
        };

        return (
            <>
                <Form defaultValues={{ ...DEFAULT_CREATE_VALUES, ...mergeObject }} errors={errors} onSubmit={this.createOrgCaseType}>
                    {(!!!caseTypeId && !!!caseTemplateId) &&
                        <div className="flex -mx-2">
                            <div className="w-1/2 px-2">
                                <EnumTypeSelector showShort={true} type="CaseType" name="CaseType_Id" label="Case Type" required />
                            </div>
                            <div className="w-1/2 px-2">
                                <FormConsumer>
                                    {({ values, setValue, errors }) => <FilteredTemplateSelector values={values} setValue={setValue} errors={errors} />}
                                </FormConsumer>
                            </div>
                        </div>
                    }
                    <div className="flex -mx-2">
                        <div className="w-1/3 px-2">
                            <Currency type="text" name="BasePrice" label="Base Cost" required />
                        </div>
                        <div className="w-1/3 px-2">
                            <DatePicker name="ActiveFrom" label="Active From" showLabel={true} showError={true} />
                        </div>
                        <div className="w-1/3 px-2">
                            <DatePicker name="ActiveTo" label="Active To" showLabel={true} showError={true} />
                        </div>
                    </div>
                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!isCreating ? (
                            <Button type="submit">Add Case Type</Button>
                        ) : (
                                <Button disabled>
                                    Adding Case Type... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default connect(mapStateToProps)(CreateOrgCaseType);
