import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import shortid from 'shortid';
import Guard from '../app/common/Guard';
import { optional } from './index';

export const buildDesktopNavigation = navigation => {
    return (
        <Guard key={`${shortid.generate()}-${navigation.linkText}`} permissions={navigation.permissions}>
            <div className="group relative mx-2 text-white">
                <NavLink
                    activeClassName="opacity-100 border-b-4 border-accent nav-active"
                    className="no-underline opacity-75 hover:opacity-100 block cursor-pointer py-4 px-4"
                    {...navigation.props}
                >
                    <i className={`fas mr-2 ${navigation.icon} text-white`} />
                    <span className={`text-white ${optional(navigation, target => target.children.length, 0) > 0 ? 'mr-2' : ''}`}>
                        {navigation.linkText}
                    </span>
                    {optional(navigation, target => target.children.length, 0) > 0 ? (
                        <i className="fas fa-angle-down fa-xs opacity-50 group-hover:opacity-75" />
                    ) : (
                        <></>
                    )}
                </NavLink>
                <ChildLink navigation={navigation} />
            </div>
            <div className="border-r-2 m-3 border-accent " />
        </Guard>
    );
};

function ChildLink({ navigation }) {
    const hasChildren = optional(navigation, target => target.children.length, 0) > 0;
    const childPermissions = !hasChildren
        ? []
        : navigation.children.reduce((permissions, child) => {
              return [...permissions, ...child.permissions];
          }, []);

    if (!hasChildren) return <> </>;

    return (
        <Guard permissions={childPermissions}>
            <div
                className="hidden group-hover:block absolute shadow rounded-br rounded-bl opacity-100 bg-white w-48 pin-r -mr-4 text-right"
                style={{ top: '100%' }}
            >
                {optional(navigation, target => target.children, []).map(child => (
                    <Guard key={`${shortid.generate()}-${child.linkText}`} permissions={child.permissions}>
                        {optional(child, target => target.children.length, 0) === 0 ? (
                            <NavLink
                                activeClassName="bg-grey-lightest"
                                className="block no-underline tracking p-4 pr-6 text-primary hover:bg-grey-lightest relative nested-nav"
                                {...child.props}
                            >
                                <span>{child.linkText}</span>
                            </NavLink>
                        ) : (
                            <span className="block no-underline tracking p-4 pr-6 text-primary hover:bg-grey-lightest relative nested-nav">
                                <div style={{ display: 'flex' }} className="justify-between items-center">
                                    <i className="fas fa-angle-left fa-xs opacity-50 group-hover:opacity-75" />
                                    <span className="">{child.linkText}</span>
                                </div>
                                <SubLink navigation={child} />
                            </span>
                        )}
                    </Guard>
                ))}
            </div>
        </Guard>
    );
}

function SubLink({ navigation }) {
    return (
        <div className="hidden absolute shadow rounded-br rounded-bl opacity-100 bg-white w-48 text-right pin-t -mr-48" style={{ left: '-12rem' }}>
            {optional(navigation, target => target.children, []).map(child => (
                <Guard key={`${shortid.generate()}-${child.linkText}`} permissions={child.permissions} notFound="wtf">
                    <NavLink
                        activeClassName="bg-grey-lightest"
                        className="block no-underline tracking p-4 pr-6 text-primary hover:bg-grey-lightest"
                        {...child.props}
                    >
                        {child.linkText}
                    </NavLink>
                </Guard>
            ))}
        </div>
    );
}

export class MobileNavigationItemC extends Component {
    state = {
        isExpanded: false
    };

    toggleExpansion = () => {
        this.setState({ ...this.state, isExpanded: !this.state.isExpanded });
    };

    render() {
        const { menuLevel, navigation, toggleMenu } = this.props;
        const { isExpanded } = this.state;

        const menuBgColor = ['white', 'grey-lighter', 'grey-light'];
        const hoverMenuBgColor = ['grey-lightest', 'grey-light', 'grey'];

        return (
            <Guard key={`${shortid.generate()}-${navigation.linkText}`} permissions={navigation.permissions}>
                <div className={`group w-full relative bg-${menuBgColor[menuLevel]} md:hidden`}>
                    <NavLink
                        activeClassName="border-primary border-l-4 opacity-100"
                        className={`${isExpanded ? 'shadow' : ''} flex hover:bg-${
                            hoverMenuBgColor[menuLevel]
                        } border-l-4 justify-between no-underline text-primary block cursor-pointer py-4 px-8`}
                        {...navigation.props}
                        onClick={e => {
                            if (optional(navigation, target => target.children.length, 0) > 0) {
                                e.preventDefault();
                                e.stopPropagation();
                                this.toggleExpansion();
                            } else toggleMenu();
                        }}
                    >
                        <span className={optional(navigation, target => target.children.length, 0) > 0 ? 'mr-2' : ''}>{navigation.linkText}</span>
                        {optional(navigation, target => target.children.length, 0) > 0 ? (
                            <i className={`fas ${isExpanded ? 'fa-angle-up' : 'fa-angle-down'} fa-xs opacity-50 group-hover:opacity-75`} />
                        ) : (
                            <></>
                        )}
                    </NavLink>
                    {isExpanded ? navigation.children.map(child => <MobileNavigationItem navigation={child} menuLevel={menuLevel + 1} />) : <></>}
                </div>
            </Guard>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    toggleMenu: dispatch.navigation.toggleMenu
});

export const MobileNavigationItem = connect(
    undefined,
    mapDispatchToProps
)(MobileNavigationItemC);
