import types from './types';

const getAllTypesRequest = () => ({
    type: types.GET_ALL_TYPES_REQUEST
});
const getAllTypesSuccess = (_types) => ({
    type: types.GET_ALL_TYPES_SUCCESS,
    payload: _types
});
const getAllTypesFailure = (errors) => ({
    type: types.GET_ALL_TYPES_FAILURE,
    payload: errors
});

const updateTypeRequest = (_type) => ({
    type: types.UPDATE_TYPE_REQUEST,
    payload: _type
});
const updateTypeSuccess = (result) => ({
    type: types.UPDATE_TYPE_SUCCESS,
    payload: result
});
const updateTypeFailure = (errors) => ({
    type: types.UPDATE_TYPE_FAILURE,
    payload: errors
});

const addTypeRequest = () => ({
    type: types.ADD_TYPE_REQUEST
});
const addTypeSuccess = (result) => ({
    type: types.ADD_TYPE_SUCCESS,
    payload: result
});
const addTypeFailure = (errors) => ({
    type: types.ADD_TYPE_FAILURE,
    payload: errors
});

const editTypeRequest = (_type) => ({
    type: types.EDIT_TYPE_REQUEST,
    payload: _type
});
const editTypeSuccess = (typeName, _type) => ({
    type: types.EDIT_TYPE_SUCCESS,
    payload: { typeName, _type }
});
const editTypeFailure = (typeName, errors) => ({
    type: types.EDIT_TYPE_FAILURE,
    payload: { typeName, errors }
});

const deleteTypeRequest = (_type) => ({
    type: types.DELETE_TYPE_REQUEST,
    payload: _type
});
const deleteTypeSuccess = (_type) => ({
    type: types.DELETE_TYPE_SUCCESS,
    payload: _type
});
const deleteTypeFailure = (_type, errors) => ({
    type: types.DELETE_TYPE_FAILURE,
    payload: { _type, errors }
});

const addItemTypeRequest = () => ({
    type: types.ADD_ITEM_TYPE_REQUEST
});
const addItemTypeSuccess = (_type, item) => ({
    type: types.ADD_ITEM_TYPE_SUCCESS,
    payload: { _type, item }
});
const addItemTypeFailure = (errors) => ({
    type: types.ADD_ITEM_TYPE_FAILURE,
    payload: errors
});

const editItemTypeRequest = () => ({
    type: types.EDIT_ITEM_TYPE_REQUEST
});
const editItemTypeSuccess = (_type, item) => ({
    type: types.EDIT_ITEM_TYPE_SUCCESS,
    payload: { _type, item }
});
const editItemTypeFailure = (errors) => ({
    type: types.EDIT_ITEM_TYPE_FAILURE,
    payload: errors
});

const deleteItemTypeRequest = () => ({
    type: types.DELETE_ITEM_TYPE_REQUEST
});
const deleteItemTypeSuccess = (_type, item) => ({
    type: types.DELETE_ITEM_TYPE_SUCCESS,
    payload: { _type, item }
});
const deleteItemTypeFailure = (errors) => ({
    type: types.DELETE_ITEM_TYPE_FAILURE,
    payload: errors
});

export default {
    getAllTypesRequest,
    getAllTypesSuccess,
    getAllTypesFailure,
    updateTypeRequest,
    updateTypeSuccess,
    updateTypeFailure,
    addTypeRequest,
    addTypeSuccess,
    addTypeFailure,
    editTypeRequest,
    editTypeSuccess,
    editTypeFailure,
    deleteTypeRequest,
    deleteTypeSuccess,
    deleteTypeFailure,
    addItemTypeRequest,
    addItemTypeSuccess,
    addItemTypeFailure,
    editItemTypeRequest,
    editItemTypeSuccess,
    editItemTypeFailure,
    deleteItemTypeRequest,
    deleteItemTypeSuccess,
    deleteItemTypeFailure
};