import React from 'react';
import { roles } from '../../constants';
import { buildDesktopNavigation, MobileNavigationItem } from '../../utils/navigation';

const itemsNavigation = {
    linkText: 'Items',
    icon: 'fa-paperclip',
    props: { to: '/items' },
    permissions: [roles.Items.SEARCH_ITEMS],
    children: []
};

export const itemsDesktopNavigation = buildDesktopNavigation(itemsNavigation);
export const itemsMobileNavigation = <MobileNavigationItem key="items-mobile-navigation" navigation={itemsNavigation} menuLevel={0} />;
