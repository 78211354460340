import React, { Component } from 'react';
import ReactTable from 'react-table';
import shortid from 'shortid';

import { TableCheckbox, EnumTypeSelector, TableField, Header, Divider } from '../../../common/form';

import { optional } from '../../../../utils';
import Button from '../../../common/Button';

import { USER_PROFILE_PAGE_SIZE } from '../../../../constants';
import { FormConsumer } from '../../../common/form/Form';

class EmailsEditor extends Component {
    state = {
        columns: []
    };

    render() {
        const { user, onAdd, onDelete } = this.props;
        const data = optional(user, target => target.Emails, []);
        const columns = [
            {
                Header: 'Email Address',
                accessor: 'Address',
                Cell: row => {
                    return <TableField name={`Emails.${row.original.Id}.Address`} label="Email Address" type="email" required={true} />;
                }
            },
            {
                Header: 'Type',
                accessor: 'EmailType_Id',
                Cell: row => <EnumTypeSelector name={`Emails.${row.original.Id}.EmailType_Id`} label="" type="EmailType" />
            },
            {
                Header: 'Primary',
                accessor: 'IsPrimary',
                Cell: row => <TableCheckbox name={`Emails.${row.original.Id}.IsPrimary`} label="" type="checkbox" />
            },
            {
                Header: '',
                accessor: 'Actions',
                filterable: false,
                sortable: false,
                Cell: row => (
                    <div className="flex justify-center items-center h-full">
                        <FormConsumer>
                            {({ removeValue }) => (
                                <span
                                    className="text-red-light cursor-pointer"
                                    onClick={() => {
                                        onDelete(this.props.form, 'Emails', row.original.Id, () => {
                                            removeValue(`Emails.${row.original.Id}.Address`);
                                        });
                                    }}
                                >
                                    <i className="fas fa-trash-alt" />
                                </span>
                            )}
                        </FormConsumer>
                    </div>
                )
            }
        ];
        return (
            <>
                <div className="w-full">
                    <div className="flex align-center justify-between">
                        <Header>
                            <>
                                Emails
                                <span className="text-sm pt-2 pl-4 normal-case">
                                    <span className="text-red font-bold">*</span> At least 1 is required
                                </span>
                            </>
                        </Header>
                        <Button
                            icon="fas fa-envelope"
                            onClick={onAdd.bind(null, 'Emails', {
                                Id: shortid.generate(),
                                Address: '',
                                EmailType_Id: 1,
                                IsPrimary: false
                            })}
                        >
                            Add
                        </Button>
                    </div>
                    <Divider />
                    <div className="p-4">
                        {data.length > 0 ? (
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPagination={false}
                                pageSize={data.length}
                                defaultPageSize={USER_PROFILE_PAGE_SIZE}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        ) : (
                            <div className="mb-8 text-primary">No emails.</div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default EmailsEditor;
