import React, { Component } from 'react';
import { connect } from 'react-redux';

import Guard from '../common/Guard';
import { roles } from '../../constants';

import Modal from '../common/Modal';
import Button from '../common/Button';
import { itemTypesOperations } from './duck';
import { sectionsOperations } from '../sections/duck';

import ItemTypesList from './components/ItemTypesList';
import NewItemTypeForm from './components/NewItemTypeForm';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class ItemTypes extends Component {
    state = {
        itemType: undefined,
        showCreateModal: false
    };

    componentDidMount() {
        this.props.getItemTypes();
        this.props.getSections();
    }

    openCreateModal = (event, section = undefined) => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    render() {
        const { showCreateModal } = this.state;
        const { errors, itemTypes, createItemType, deleteItemType, gettingItemTypes, updatingItemTypes, creatingItemType } = this.props;

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Item Templates</PageHeader>
                        <Guard permissions={[roles.ItemTypes.CREATE_ITEM_TYPES]}>
                            <Button inverse icon="far fa-list-alt" onClick={this.openCreateModal}>
                                Add Item Template
                            </Button>
                        </Guard>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingItemTypes ? (
                            <ItemTypesList
                                itemTypes={itemTypes}
                                createItemType={createItemType}
                                deleteItemType={deleteItemType}
                                updatingItemTypes={updatingItemTypes}
                            />
                        ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? (
                            <NewItemTypeForm
                                errors={errors}
                                createItemType={createItemType}
                                creatingItemType={creatingItemType}
                                closeModal={this.closeModal}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    itemTypes: state.itemTypes.itemTypes,
    gettingItemTypes: state.itemTypes._updating._gettingItemTypes,
    updatingItemTypes: state.itemTypes._updating._itemTypes,
    creatingItemType: state.itemTypes._updating._creatingItemType,
    errors: state.itemTypes._errors ? state.itemTypes._errors.ModelState : {}
});

const mapDispatchToProps = dispatch => ({
    getItemTypes: () => dispatch(itemTypesOperations.getItemTypes()),
    createItemType: itemType => dispatch(itemTypesOperations.createItemType(itemType)),
    deleteItemType: itemType => dispatch(itemTypesOperations.deleteItemType(itemType)),
    getSections: () => dispatch(sectionsOperations.getSections())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemTypes);
