import moment from 'moment';
import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const typesPersistConfig = {
    key: 'types',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    _errors: null,
    types: {},
    _updating: {
        _creatingItemType: false,
        _deletingItemType: false,
        _editingItemType: false,
        _gettingTypes: false,
        _addingType: false
    }
};

const _types = createReducer(initialState, {
    [types.GET_ALL_TYPES_REQUEST]: state => ({ ...state, _updating: { ...state._updating, _gettingTypes: true } }),
    [types.GET_ALL_TYPES_SUCCESS]: (state, { payload: types }) => ({
        ...types,
        _lastRetrieved: moment().unix(),
        _updating: { ...state._updating, _gettingTypes: false },
        _errors: null
    }),
    [types.GET_ALL_TYPES_ERROR]: (state, { payload: errors }) => ({
        ...types,
        _updating: { ...state._updating, _gettingTypes: false },
        _errors: errors
    }),

    [types.UPDATE_TYPE_REQUEST]: (state, { payload: _type }) => ({
        ...state,
        _updating: { ...state._updating, [_type]: true },
        _errors: null
    }),
    [types.UPDATE_TYPE_SUCCESS]: (state, { payload: { _type, values } }) => ({
        ...state,
        [_type]: { ..._type, Types: values, _lastRetrieved: moment().unix() },
        _updating: { ...state._updating, [_type]: false },
        _errors: null
    }),
    [types.UPDATE_TYPE_FAILURE]: (state, { payload: { _type, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, [_type]: false }
    }),

    [types.ADD_TYPE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _addingType: true }
    }),
    [types.ADD_TYPE_SUCCESS]: (state, { payload: _type }) => ({
        ...state,
        [_type.TableName]: { ..._type, _lastRetrieved: moment().unix() },
        _errors: null,
        _updating: { ...state._updating, _addingType: false }
    }),
    [types.ADD_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _addingType: false }
    }),

    [types.EDIT_TYPE_REQUEST]: (state, { payload: _type }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, [_type]: true }
    }),
    [types.EDIT_TYPE_SUCCESS]: (state, { payload: { typeName, _type } }) => {
        const { [typeName]: old, ...rest } = state;

        return {
            ...rest,
            [_type.TableName]: { ..._type, _lastRetrieved: moment().unix() },
            _errors: null,
            _updating: { ...rest._updating, [typeName]: false }
        };
    },
    [types.EDIT_TYPE_FAILURE]: (state, { payload: { typeName, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, [typeName]: false }
    }),

    [types.DELETE_TYPE_REQUEST]: (state, { payload: _type }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, [_type]: true }
    }),
    [types.DELETE_TYPE_SUCCESS]: (state, { payload: _type }) => {
        const { [_type]: old, ...rest } = state;

        return {
            ...rest,
            _errors: null,
            _updating: { ...state._updating, [_type]: false }
        };
    },
    [types.DELETE_TYPE_FAILURE]: (state, { payload: { _type, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, [_type]: false }
    }),

    [types.ADD_ITEM_TYPE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _creatingItemType: true }
    }),
    [types.ADD_ITEM_TYPE_SUCCESS]: (state, { payload: { _type, item } }) => ({
        ...state,
        [_type]: {
            ...state[_type],
            Types: [...state[_type].Types, item]
        },
        _errors: null,
        _updating: { ...state._updating, _creatingItemType: false }
    }),
    [types.ADD_ITEM_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingItemType: false }
    }),

    [types.EDIT_ITEM_TYPE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _editingItemType: true }
    }),
    [types.EDIT_ITEM_TYPE_SUCCESS]: (state, { payload: { _type, item } }) => ({
        ...state,
        [_type]: {
            ...state[_type],
            Types: [...state[_type].Types.filter(t => t && t.Id !== item.Id), item]
        },
        _errors: null,
        _updating: { ...state._updating, _editingItemType: false }
    }),
    [types.EDIT_ITEM_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _editingItemType: false }
    }),

    [types.DELETE_ITEM_TYPE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _deletingItemType: true }
    }),
    [types.DELETE_ITEM_TYPE_SUCCESS]: (state, { payload: { _type, item } }) => ({
        ...state,
        [_type]: {
            ...state[_type],
            Types: [...state[_type].Types.filter(t => t.Id !== item.Id), { ...item, Hidden: !item.Hidden }]
        },
        _errors: null,
        _updating: { ...state._updating, _deletingItemType: false }
    }),
    [types.DELETE_ITEM_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _deletingItemType: false }
    })
});

export default _types;
