const loggedIn = (account) => account.auth !== null && account.auth !== undefined && !account.isLoggingIn;
const userName = (account) => account.user !== null ? `${account.user.FirstName} ${account.user.LastName}` : '';

const hasPermission = (account, permissions) => {
    if (!loggedIn(account) || account.permissions === null) return false;
    const roles = account.permissions.roles;

    if (roles.includes('System Administrator')) return true;
    return permissions.some(role => roles.includes(role));
};

const hasRole = (account, roles) => {
    if (!loggedIn(account) || account.permissions === null) return false;
    const userRoles = account.permissions.roles;

    return roles.some(role => userRoles.includes(role));
};

const phoneNumberIsUpdating = (account, phoneNumberId) => account._updating[`phone-${phoneNumberId}`];
const phoneNumberIsDeleting = (account, phoneNumberId) => account._updating[`deleting-phone-${phoneNumberId}`];

export default {
    hasRole,
    loggedIn,
    userName,
    hasPermission,
    phoneNumberIsUpdating,
    phoneNumberIsDeleting
};