import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import TypeItemsList from './TypeItemsList';

import { typesOperations } from '../duck';
import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Button from '../../common/Button';
import Modal from '../../common/Modal';
import { Input } from '../../common/form';
import { Form } from '../../common/form/Form';

class TypeList extends Component {
    state = {
        expanded: {},
        editing: null,
        deleting: null,
        showEditModal: false,
        showDeleteModal: false
    };

    expandRow = rowIndex => {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            editing: null,
            deleting: null,
            showEditModal: false,
            showDeleteModal: false
        });
    };

    deleteType = _type => {
        this.setState({
            ...this.state,
            editing: null,
            deleting: _type,
            showEditModal: false,
            showDeleteModal: true
        });
    };

    updateType = _type => {
        this.setState({
            ...this.state,
            editing: _type,
            deleting: null,
            showEditModal: true,
            showDeleteModal: false
        });
    };

    onDelete = () => {
        this.props
            .deleteType(this.state.deleting.TableName)
            .then(() => this.closeModal())
            .catch(() => {});
    };

    onSubmit = values => {
        this.props
            .editType(this.state.editing, values.TableName)
            .then(() => this.closeModal())
            .catch(() => {});
    };

    render() {
        const { types, editErrors } = this.props;
        const { expanded, editing, deleting, showEditModal, showDeleteModal } = this.state;

        const editingType = this.props.editingType((editing && editing.TableName) || undefined || ((deleting && deleting.TableName) || undefined));

        const columns = [
            {
                Header: 'List Name',
                accessor: 'FormattedTableName',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'List Type',
                accessor: 'IsDynamic',
                sortable: false,
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        <span
                            className={`rounded-full normal-case bg-${value ? 'green' : 'blue'}-light px-2 py-1 text-xs ml-3 font-normal text-white`}
                        >
                            {value ? 'Dynamic' : 'Static'}
                        </span>
                    </div>
                ),
                filterMethod: (filter, row) => {
                    if (filter.value === 'ALL') return true;
                    if (filter.value === 'STATIC') return row[filter.id] === false;
                    if (filter.value === 'DYNAMIC') return row[filter.id] === true;
                },
                Filter: ({ filter, onChange }) => (
                    <div className="relative w-full">
                        <select
                            className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 rounded"
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter ? filter.value : 'ALL'}
                        >
                            <option value="ALL">Show All</option>
                            <option value="STATIC">Static</option>
                            <option value="DYNAMIC">Dynamic</option>
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) =>
                    row.IsDynamic ? (
                        <div className="flex justify-center items-center h-full">
                            <Guard permissions={[roles.Types.EDIT_TYPE]}>
                                <span
                                    className="cursor-pointer mr-4 cursor-pointer text-grey"
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.updateType(row._original);
                                    }}
                                >
                                    <i className="fas fa-pencil-alt" />
                                </span>
                            </Guard>
                            <Guard permissions={[roles.Types.DELETE_TYPE]}>
                                <span
                                    className="text-red-light cursor-pointer"
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.deleteType(row._original);
                                    }}
                                >
                                    <i className="fas fa-trash-alt" />
                                </span>
                            </Guard>
                        </div>
                    ) : (
                        <></>
                    )
            }
        ];

        return (
            <>
                <ReactTable
                    filterable
                    data={types}
                    columns={columns}
                    expanded={expanded}
                    defaultSorted={[
                        {
                            id: 'FormattedTableName',
                            desc: false
                        }
                    ]}
                    SubComponent={({ row }) => {
                        return <TypeItemsList parent={row._original} items={row._original.Types} />;
                    }}
                    getTrProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            this.expandRow(rowInfo.viewIndex);
                        }
                    })}
                    getTdProps={(state, rowInfo) => ({
                        className: `${
                            typeof Object.keys(expanded).find(k => !!expanded[k]) === 'string' && !!!expanded[rowInfo && rowInfo.viewIndex]
                                ? `opacity-25`
                                : ''
                        }`
                    })}
                    getTheadProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTheadFilterProps={() => ({
                        style: { background: 'white' }
                    })}
                />
                <Modal show={showEditModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showEditModal ? (
                            <Form defaultValues={editing} errors={editErrors} onSubmit={this.onSubmit}>
                                <Input type="text" name="TableName" label="List Name" required />
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!editingType ? (
                                        <Button type="submit">Update List</Button>
                                    ) : (
                                        <Button disabled>
                                            Updating List... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
                <Modal show={showDeleteModal} onClose={this.closeModal} className="max-w-md">
                    <div className="p-4">
                        <h2 className="pb-4">
                            <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                        </h2>
                        {showDeleteModal ? (
                            <Form onSubmit={this.onDelete}>
                                <p>{`Are you sure you would like to delete ${deleting.FormattedTableName}? This cannot be undone.`}</p>
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!editingType ? (
                                        <Button type="submit" className="text-red bg-white hover:bg-grey-lighter">
                                            Yes, delete {deleting.FormattedTableName}
                                        </Button>
                                    ) : (
                                        <Button className="text-red border-red bg-white hover:bg-grey-lighter" disabled>
                                            Deleting list... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    types: Object.keys(state.types)
        .filter(key => key.includes('Type'))
        .map(typeKey => ({
            IsDynamic: false,
            ...state.types[typeKey],
            FormattedTableName: typeKey.toTitleCase()
        })),
    editingType: typeName => !!state.types._updating[typeName],
    editErrors: (state.types._errors && state.types._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    editType: (_type, newName) => dispatch(typesOperations.editType(_type, newName)),
    deleteType: _type => dispatch(typesOperations.deleteType(_type))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeList);
