import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import _ from 'lodash';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import { itemTypesOperations } from '../duck';
import { transformSections, optional } from '../../../utils';
import { throws } from 'assert';
import ItemTypeAssets from './ItemTypeAssets';
import PageHeader from '../../common/PageHeader';
import CustomCheckbox from '../../common/CustomCheckbox';
import { Form, FormConsumer } from '../../common/form/Form';
import { Input } from '../../common/form';

class ItemTypeSectionsList extends Component {
    state = {
        selectedSection: -1,
        showCreateModal: false
    };
    sectionTable = undefined;

    onChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : +event.target.value
        });
    };

    openCreateModal = event => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            selectedSection: -1,
            showCreateModal: false
        });
    };

    addSection = event => {
        event.preventDefault();
        const { selectedSection, sectionRequired } = this.state;

        const section = this.props.sections.find(s => s.Id === selectedSection);
        this.props
            .addSectionToItemType(section, sectionRequired, this.props.itemType)
            .then(() => this.closeModal())
            .catch(() => { });
    };

    updateRequiredOnSection = (section, sectionRequired) => {
        this.props.updateSectionOnItemType(section, sectionRequired, this.props.itemType);
    };

    removeSection = section => {
        this.props.removeSectionFromItemType(section, this.props.itemType);
    };

    getAvailableSections = () => {
        const { sections, itemType } = this.props;
        const SectionIds = itemType.Sections.map(s => s.Id);

        return transformSections(sections)
            .filter(s => !s.Sections.some(ss => SectionIds.includes(ss.Id)) && s.Sections[0].Enabled)
            .sort((s1, s2) => s1.SectionName > s2.SectionName);
    };

    submitForm = values => {
        const { itemType } = this.props;

        values.Sections = [
            ...itemType.Sections.map(section => ({
                ...section,
                SectionId: +section.Id,
                Required: !!section.Required
            }))
        ];
        this.props.updateItemType(values);
    };

    render() {
        const { selectedSection, sectionRequired, showCreateModal } = this.state;
        const { itemType, gettingSections, updatingItemTypes, swappingSections, errors } = this.props;
        const itemSections = itemType.Sections;

        return (
            <>
                <div className="px-8 py-4 my-4 bg-table-search rounded shadow-inner">
                    <Guard permissions={[roles.ItemTypes.EDIT_ITEM_TYPES]}>
                        <div className="flex justify-between items-center mb-4">
                            <Form className="w-full" errors={errors && errors.ModelState} defaultValues={itemType} onSubmit={this.submitForm}>
                                <div className="flex flex-col lg:flex-row w-full">
                                    <div className="flex flex-col w-full lg:w-1/2 lg:mr-4">
                                        <Input name="Name" required={true} label="Item Template Name" />
                                    </div>
                                    <div className="flex flex-col w-full lg:w-1/2">
                                        <Input name="Description" required={true} label="Item Template Header" />
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <FormConsumer>
                                        {({ values }) => (
                                            <Button disabled={_.isEqual(itemType, values)} type="submit" loading={updatingItemTypes[itemType.Id]}>
                                                Save
                                            </Button>
                                        )}
                                    </FormConsumer>
                                </div>
                            </Form>
                        </div>
                    </Guard>
                    <div className="flex justify-between items-center mb-4">
                        <PageHeader>Sections</PageHeader>
                        <Guard permissions={[roles.ItemTypes.ADD_SECTION_TO_ITEM_TYPE]}>
                            <Button inverse icon="far fa-list-alt" onClick={this.openCreateModal}>
                                Add Section
                            </Button>
                        </Guard>
                    </div>
                    <div className="bg-white shadow rounded p-4">
                        {itemSections.length > 0 ? (
                            <ReactTable
                                data={itemSections}
                                columns={[
                                    {
                                        Header: 'Section Template Name',
                                        accessor: 'SectionName',
                                        filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
                                    },
                                    {
                                        Header: 'Required',
                                        accessor: 'Required',
                                        filterable: false,
                                        sortable: false,
                                        Cell: ({ value, row }) => (
                                            <div className="flex justify-center items-center h-full">
                                                <Guard permissions={[roles.ItemTypes.EDIT_ITEM_TYPES]}>
                                                    <CustomCheckbox
                                                        checked={!!value}
                                                        onChange={e => this.updateRequiredOnSection(row._original, !!e.target.checked)}
                                                    />
                                                </Guard>
                                            </div>
                                        )
                                    },
                                    {
                                        filterable: false,
                                        sortable: false,
                                        Cell: ({ row }) => (
                                            <div className="flex justify-center items-center h-full">
                                                <Guard permissions={[roles.ItemTypes.REMOVE_SECTION_FROM_ITEM_TYPE]}>
                                                    <span
                                                        className={`text-red-light ${
                                                            !updatingItemTypes[`${itemType.Id}-${row._original.Id}`]
                                                                ? 'cursor-pointer'
                                                                : 'cursor-not-allowed opacity-50'
                                                            }`}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            this.removeSection(row._original);
                                                        }}
                                                    >
                                                        <i
                                                            className={
                                                                !updatingItemTypes[`${itemType.Id}-${row._original.Id}`]
                                                                    ? `fas fa-trash-alt`
                                                                    : `fas fa-spinner fa-pulse`
                                                            }
                                                        />
                                                    </span>
                                                </Guard>
                                            </div>
                                        )
                                    },
                                    {
                                        accessor: 'DisplayOrder',
                                        id: 'DisplayOrder',
                                        sortable: false,
                                        filterable: false,
                                        minWidth: 65,
                                        Cell: ({ row, index, viewIndex }) => (
                                            <Guard permissions={[roles.ItemTypes.REORDER_ITEM_TYPE_SECTIONS]}>
                                                {viewIndex !== 0 && itemSections.length > 1 ? (
                                                    !swappingSections ? (
                                                        <span
                                                            className="text-grey cursor-pointer mr-4"
                                                            onClick={e => {
                                                                this.props.swapSections({
                                                                    SwapFromSection_Id: this.sectionTable.state.resolvedData[viewIndex]._original.Id,
                                                                    SwapToSection_Id: this.sectionTable.state.resolvedData[viewIndex - 1]._original.Id
                                                                });
                                                            }}
                                                        >
                                                            <i className="fas fa-chevron-up" />
                                                        </span>
                                                    ) : (
                                                            <span className="text-grey-light cursor-not-allowed mr-4">
                                                                <i className="fas fa-spinner fa-pulse" />
                                                            </span>
                                                        )
                                                ) : (
                                                        <></>
                                                    )}
                                                {viewIndex !== itemSections.length - 1 && itemSections.length > 1 ? (
                                                    !swappingSections ? (
                                                        <span
                                                            className="text-grey cursor-pointer mr-4"
                                                            onClick={e => {
                                                                this.props.swapSections({
                                                                    SwapFromSection_Id: this.sectionTable.state.resolvedData[viewIndex]._original.Id,
                                                                    SwapToSection_Id: this.sectionTable.state.resolvedData[viewIndex + 1]._original.Id
                                                                });
                                                            }}
                                                        >
                                                            <i className="fas fa-chevron-down" />
                                                        </span>
                                                    ) : (
                                                            <span className="text-grey-light cursor-not-allowed mr-4">
                                                                <i className="fas fa-spinner fa-pulse" />
                                                            </span>
                                                        )
                                                ) : (
                                                        <></>
                                                    )}
                                            </Guard>
                                        )
                                    }
                                ]}
                                defaultSorted={[{ id: 'DisplayOrder', desc: false }]}
                                pageSize={itemSections.length}
                                showPagination={false}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            >
                                {(state, makeTable, instance) => {
                                    this.sectionTable = instance;
                                    return makeTable();
                                }}
                            </ReactTable>
                        ) : (
                                <div className="text-primary">This item template has no possible sections yet.</div>
                            )}
                    </div>
                    <div className="flex justify-between items-center my-4">
                        <PageHeader>Assets</PageHeader>
                    </div>
                    <div className="bg-white shadow rounded p-4 mt-4">
                        <ItemTypeAssets itemType={itemType} />
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? (
                            <form onSubmit={this.addSection}>
                                <div className="w-full mt-2">
                                    <div className="flex justify-between items-center">
                                        <div className="relative flex-grow">
                                            <select
                                                className="input-field"
                                                name="selectedSection"
                                                placeholder=""
                                                value={selectedSection}
                                                onChange={this.onChange}
                                            >
                                                {gettingSections ? (
                                                    <option value={selectedSection} disabled>
                                                        Loading...
                                                    </option>
                                                ) : (
                                                        [
                                                            <option key="disabled-default-section" disabled value={-1}>
                                                                Select a section...
                                                        </option>,
                                                            ...this.getAvailableSections()
                                                                .sort((a, b) => {
                                                                    var x = optional(a, s => s.SectionName.toLowerCase(), '');
                                                                    var y = optional(b, s => s.SectionName.toLowerCase(), '');
                                                                    if (x < y) {
                                                                        return -1;
                                                                    }
                                                                    if (x > y) {
                                                                        return 1;
                                                                    }
                                                                    return 0;
                                                                })
                                                                .map(s => (
                                                                    <option key={s.Sections[0].Id} value={s.Sections[0].Id}>
                                                                        {s.SectionName}
                                                                    </option>
                                                                ))
                                                        ]
                                                    )}
                                            </select>
                                            <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex mt-4 ml-2 items-center">
                                        <label className="md:w-2/3 flex items-center">
                                            <CustomCheckbox
                                                className="mr-2 leading-tight"
                                                name="sectionRequired"
                                                checked={!!sectionRequired}
                                                onChange={this.onChange}
                                            />
                                            <span className="text-primary text-sm">This section is required.</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!updatingItemTypes[itemType.Id] ? (
                                        <Button type="submit" disabled={selectedSection === -1}>
                                            Add Section
                                        </Button>
                                    ) : (
                                            <Button disabled>
                                                Adding Section... <i className="fas fa-spinner fa-pulse" />
                                            </Button>
                                        )}
                                </div>
                            </form>
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    updatingItemTypes: state.itemTypes._updating._itemTypes,
    swappingSections: state.itemTypes._updating._swappingSections,
    gettingSections: state.sections._updating._gettingSections,
    sections: state.sections.sections,
    errors: state.itemTypes._errors
});

const mapDispatchToProps = (dispatch, props) => ({
    addSectionToItemType: (section, sectionRequired, itemType) =>
        dispatch(itemTypesOperations.addSectionToItemType(section, sectionRequired, itemType)),
    updateSectionOnItemType: (section, sectionRequired, itemType) =>
        dispatch(itemTypesOperations.updateSectionOnItemType(section, sectionRequired, itemType)),
    removeSectionFromItemType: (section, itemType) => dispatch(itemTypesOperations.removeSectionFromItemType(section, itemType)),
    swapSections: swapObject => dispatch(itemTypesOperations.swapItemTypeSections({ itemType: props.itemType, swapObject })),
    updateItemType: itemType => dispatch(itemTypesOperations.updateItemType(itemType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemTypeSectionsList);
