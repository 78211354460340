import React from 'react';

import Button from '../../common/Button';
import { Checkbox, Input, EnumTypeSelector } from '../../common/form';
import { Form } from '../../common/form/Form';

const DEFAULT_CREATE_VALUES = {
    Short: '',
    Description: '',
    UOMType_Id: 0,
    MultipleUnitsAllowed: false,
    Reimbursable: false
};

const ExpenseTypeForm = ({ defaultValues, errors, onSubmit, isProcessing, closeModal }) => (
    <>
        <Form
            onSubmit={onSubmit}
            errors={errors}
            defaultValues={{
                ...DEFAULT_CREATE_VALUES,
                ...defaultValues
            }}
        >
            <div className="flex -mx-2">
                <div className="w-1/2 px-2">
                    <Input type="text" name="Short" label="Name" required />
                </div>
                <div className="w-1/2 px-2">
                    <EnumTypeSelector type="UOMType" name="UOMType_Id" label="UOM" required showShort={true} />
                </div>
            </div>
            <div className="flex -mx-2 mt-4">
                <div className="w-1/3 px-2">
                    <Checkbox type="checkbox" name="MultipleUnitsAllowed" label="Multiple Units Allowed?" />
                </div>
                <div className="w-1/3 px-2">
                    <Checkbox type="checkbox" name="Reimbursable" label="Reimbursable?" />
                </div>
            </div>
            <div className="flex justify-end items-end mt-4">
                <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                    Cancel
                </Button>
                {!isProcessing ? (
                    <Button type="submit">{defaultValues ? 'Update' : 'Create'} Expense Type</Button>
                ) : (
                    <Button disabled>
                        {defaultValues ? 'Updating' : 'Creating'} Expense Type... <i className="fas fa-spinner fa-pulse" />
                    </Button>
                )}
            </div>
        </Form>
    </>
);

export default ExpenseTypeForm;
