import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import { Form } from '../../common/form/Form';
import FetchSearchSelector from '../../common/form/FetchSearchSelector';

class ItemIssueCodeList extends Component {
    state = {
        showModal: false
    };

    componentDidMount() {
        const { item } = this.props;
        this.props.getItemIssueCodes(item.Case.Id, item.Id);
    }

    openModal = () => {
        this.setState({ ...this.state, showModal: true });
    };

    closeModal = () => {
        this.setState({ ...this.state, showModal: false });
    };

    onSubmit = values => {
        const { item } = this.props;
        this.props
            .addItemIssueCode(item.Case.Id, item.Id, +values['IssueCodeType_Id'])
            .then(() => this.closeModal())
            .catch(() => {});
    };

    deleteItemIssueCode = issueCodeId => {
        const { item } = this.props;
        this.props.deleteItemIssueCode(item.Case.Id, item.Id, issueCodeId);
    };

    render() {
        const { gettingIssueCodes, issueCodes, addingIssueCode, errors, deletingIssueCode, disabled } = this.props;
        const { showModal } = this.state;
        const columns = [
            {
                id: 'Code',
                Header: 'Code',
                accessor: d => (d.Code ? d.Code.Short : ''),
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                id: 'EnteredBy',
                Header: 'Entered By',
                accessor: d => (d.EnteredBy ? `${d.EnteredBy.FirstName} ${d.EnteredBy.LastName}` : ''),
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                Header: 'Entered Date',
                accessor: 'EnteredDate',
                Cell: ({ value }) => <div className="text-center">{moment.utc(value).format('MM/DD/YYYY')}</div>
            },
            {
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center">
                        <span
                            className={`text-red ${
                                deletingIssueCode[row._original.Id] || disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer'
                            }`}
                            onClick={event => {
                                event.preventDefault();
                                if (deletingIssueCode[row._original.Id]) return;
                                this.deleteItemIssueCode(row._original.Id);
                            }}
                        >
                            <i className={`fas ${!deletingIssueCode[row._original.Id] ? 'fa-trash-alt' : 'fa-spinner fa-pulse'}`} />
                        </span>
                    </div>
                )
            }
        ];

        return (
            <>
                <div className="mt-8 mb-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="font-bold text-primary">Issue Codes</div>
                        <Button inverse onClick={this.openModal} disabled={disabled}>
                            Add Issue Code
                        </Button>
                    </div>
                    {!gettingIssueCodes ? (
                        issueCodes.length > 0 ? (
                            <ReactTable
                                pageSize={issueCodes.length}
                                data={issueCodes}
                                columns={columns}
                                showPagination={false}
                                showPageSizeOptions={false}
                            />
                        ) : (
                            <div className="text-primary">This item has no issue codes.</div>
                        )
                    ) : (
                        <span>
                            Loading issue codes... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                </div>
                <Modal className="h-64" show={showModal} onClose={this.closeModal} style={{ overflow: 'visible' }}>
                    <div className="p-4 h-full">
                        {showModal ? (
                            <Form
                                className="flex flex-col justify-between h-full"
                                defaultValues={{ IssueCodeType_Id: 0 }}
                                errors={errors}
                                onSubmit={this.onSubmit}
                            >
                                <FetchSearchSelector
                                    label="Issue Code Type"
                                    name="IssueCodeType_Id"
                                    url={`types/IssueCodeType`}
                                    valueKey="Id"
                                    textKey={ic => `${ic.Short} (${ic.Description})`}
                                    required={true}
                                />
                                <div className="flex justify-end items-end mt-4">
                                    <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!addingIssueCode ? (
                                        <Button type="submit">Add Issue Code</Button>
                                    ) : (
                                        <Button disabled>
                                            Adding Issue Code... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    deletingIssueCode: state.item._updating._deletingIssueCode,
    gettingIssueCodes: state.item._updating._gettingIssueCodes,
    addingIssueCode: state.item._updating._addingIssueCode,
    issueCodes: state.item.issueCodes,
    errors: (state.item._errors && state.item._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    getItemIssueCodes: (caseId, itemId) => dispatch.item.getItemIssueCodes({ caseId, itemId }),
    addItemIssueCode: (caseId, itemId, IssueCodeType_Id) => dispatch.item.addItemIssueCode({ caseId, itemId, IssueCodeType_Id }),
    deleteItemIssueCode: (caseId, itemId, issueCodeId) => dispatch.item.deleteItemIssueCode({ caseId, itemId, issueCodeId })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemIssueCodeList);
