import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';
import { Form, FormConsumer } from '../../../../common/form/Form';
import Button from '../../../../common/Button';
import { EnumTypeSelector, Input, Checkbox, FetchSelectorConsumer, DatePicker } from '../../../../common/form';

const DEFAULT_CREATE_VALUES = {
    CaseType_Id: null,
    ItemType_Id: null,
    DaysFromPending: 0,
    IsBusinessDays: false,
    StartOnFull: false,
    IsACD: false
};

class CreateOrgDeadline extends Component {
    state = {
        creatingDeadline: false,
        errors: {}
    };

    componentWillUnmount() {
        if (this.deadlineCreatePromise) this.deadlineCreatePromise.cancel();
    }

    createDeadline = values => {
        const { access_token, orgId, isACD } = this.props;

        const createObject = {
            CaseType_Id: this.props.caseTypeId || null,
            ItemType_Id: isACD ? +values['ItemType_Id'] : null,
            DaysFromPending: +values['DaysFromPending'],
            IsBusinessDays: !!values['IsBusinessDays'],
            StartOnFull: !!values['StartOnFull'],
            IsACD: !!isACD,
            ActiveFrom: values['ActiveFrom'],
            ActiveTo: values['ActiveTo']
        };

        this.deadlineCreatePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        creatingDeadline: true
                    },
                    async () => {
                        try {
                            createObject.Org_Id = orgId;
                            const result = await this.props.createOrgDeadline(createObject);

                            toast.success(`The ${isACD ? 'ACD' : 'CD'} was created successfully.`);

                            this.props.deadlineCreated(result.data);
                            this.setState({
                                ...this.state,
                                errors: {},
                                creatingDeadline: false
                            });
                        } catch (e) {
                            toast.error(`There was an error creating the ${isACD ? 'ACD' : 'CD'}.`);
                            this.setState({
                                ...this.state,
                                creatingDeadline: false,
                                errors: e.response.data.ModelState || {}
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { isACD, closeModal, orgId } = this.props;
        const { errors, creatingDeadline } = this.state;

        return (
            <Form
                defaultValues={{
                    ...DEFAULT_CREATE_VALUES,
                    IsACD: isACD
                }}
                onSubmit={this.createDeadline}
                errors={errors}
            >
                <>
                    <div className="flex flex-wrap -mx-2">
                        {isACD ? (
                            <div className="w-1/2 px-2">
                                <EnumTypeSelector type="GoalType" name="ItemType_Id" label="Item Template" required showShort={true} />
                            </div>
                        ) : (
                                <></>
                            )}
                        <div className={`${isACD ? 'w-1/2' : 'w-full'} px-2`}>
                            <Input type="number" name="DaysFromPending" label="# Days" required />
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-2">
                        <div className="w-1/2 px-2">
                            <DatePicker name="ActiveFrom" label="Active From" showLabel={true} showError={true} />
                        </div>
                        <div className="w-1/2 px-2">
                            <DatePicker name="ActiveTo" label="Active To" showLabel={true} showError={true} />
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-2">
                        <div className="w-1/3 px-2">
                            <Checkbox type="checkbox" name="IsBusinessDays" label="Business Days" />
                        </div>
                        <div className="w-1/3 px-2">
                            <Checkbox type="checkbox" name="StartOnFull" label="Start on Full" />
                        </div>
                    </div>
                    <div className="flex items-end mt-4">
                        <Button onClick={closeModal} className="ml-auto mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!creatingDeadline ? (
                            <Button type="submit">Add {isACD ? 'ACD' : 'CD'}</Button>
                        ) : (
                                <Button disabled>
                                    Adding {isACD ? 'ACD' : 'CD'}
                                    ... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                    </div>
                </>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

const mapDispatchToProps = dispatch => ({
    createOrgDeadline: dispatch.orgDeadline.createOrgDeadline
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrgDeadline);
