import axios from 'axios';
import 'whatwg-fetch';
import { baseUrl } from '../../../../../../config';

export const orgDeadline = {
    state: {},
    reducers: {},
    effects: () => ({
        async createOrgDeadline(payload, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;

            try {
                const response = await axios.post(`${baseUrl}/api/orgs/${payload.Org_Id}/deadlines`, payload, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                return response;
            } catch (error) {
                throw error;
            }
        }
    })
};
