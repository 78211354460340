import { tags } from '../constants';
import { debug } from 'util';

export const createReducer = (initialState, handlers) => (state = initialState, action) =>
    handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;

export const extractGroupTag = groupName => {
    const tagArray = Object.keys(tags).map(tag => tags[tag]);
    const groupRegEx = new RegExp(`([^\\(]*)\\((${tagArray.join('|')})\\)`);
    const matches = groupName.match(groupRegEx);

    if (matches !== null)
        return {
            Name: matches[1].trim(),
            Group: matches[2]
        };
    else if (tagArray.includes(groupName))
        return {
            Name: '',
            Group: groupName
        };
    else
        return {
            Name: groupName,
            Group: ''
        };
};

export const buildGroupTree = (tags, groups) => {
    const tagTree = [];
    tags.forEach(tag => {
        tagTree.push({
            id: tag.Id,
            name: tag.Name,
            children: groups
                .filter(group => group.Tags.some(groupTag => groupTag.Name === tag.Name))
                .map(group => ({ id: group.Id, name: group.Name }))
        });
    });

    return {
        id: 'All',
        name: 'All',
        children: tagTree
    };
};

export const buildOrgTree = (orgs, currentOrg, includeHidden = false) => {
    if (currentOrg === undefined || (!includeHidden && currentOrg.Hidden)) return {};

    // Inactive orgs are just being placed under an "INACTIVE ORGS" parent rather than
    // actually deactiating them. Exclude "INACTIVE ORGS" and all of its children.
    if (!includeHidden && currentOrg.Name == 'INACTIVE ORGS') return {};

    const children = orgs.filter(org => org.ParentOrg_Id === currentOrg.Id && (!includeHidden ? !org.Hidden : true));
    let returnObject = {
        id: currentOrg.Id,
        name: currentOrg.Name,
        ContractType_Id: currentOrg.ContractType_Id,
        lastChecked: Date.now()
    };

    if (children.length > 0) returnObject.children = children.map(org => buildOrgTree(orgs, org));

    return returnObject;
};

export const buildSortableOrgTree = (orgs, currentOrg) => {
    if (currentOrg === undefined) return {};

    const children = orgs.filter(org => org.ParentOrg_Id === currentOrg.Id);
    let returnObject = {
        id: currentOrg.Id,
        title: currentOrg.Name,
        hidden: currentOrg.Hidden
    };

    if (children.length > 0) returnObject.children = children.map(org => buildSortableOrgTree(orgs, org));

    return returnObject;
};

export const getCheckedOrgs = data => {
    let checkedIds = [];

    data.forEach(org => {
        if (org.isChecked) checkedIds = [...checkedIds, org.id];
        if (org.children && org.children.length > 0) checkedIds = [...checkedIds, ...getCheckedOrgs(org.children)];
    });

    return checkedIds;
};

// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export const makeCancelable = promise => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        }
    };
};

// Access the path of an object safely
// If any part of the path is undefined, return def
export function optional(obj, evalFunc, def) {
    try {
        if (typeof evalFunc === 'string') {
            return obj[evalFunc] || def;
        }

        return evalFunc(obj) || def;
    } catch (error) {
        return def;
    }
}

export const transformSections = (sections = []) => {
    const mappedSections = sections.reduce(
        (sectionMap, section) => ({
            ...sectionMap,
            [section.SectionName]: {
                ...(sectionMap[section.SectionName] || {}),
                ...{
                    LatestVersion:
                        optional(sectionMap[section.SectionName], t => t.LatestVersion, -1) > section.Version
                            ? sectionMap[section.SectionName].LatestVersion
                            : section.Version,
                    Sections: [...(sectionMap[section.SectionName] ? sectionMap[section.SectionName].Sections || [] : []), section]
                }
            }
        }),
        {}
    );

    return Object.keys(mappedSections).reduce(
        (result, key) => [
            ...result,
            {
                ...mappedSections[key],
                SectionName: key,
                Sections: mappedSections[key].Sections.sort((l, r) => l.Version < r.Version)
            }
        ],
        []
    );
};

export const getScrollBarWidth = () => {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = 'scroll';

    // add innerdiv
    var inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
};

export const setCheckedInTree = (tree, checkedIds) =>
    tree &&
    tree.map(node => ({
        ...node,
        isChecked: checkedIds.includes(node.id),
        children: node.children ? setCheckedInTree(node.children, checkedIds) : undefined
    }));

export const getCheckedInTree = data => {
    let checkedIds = [];

    data.forEach(node => {
        if (node.isChecked) checkedIds = [...checkedIds, node.id];
        if (node.children && node.children.length > 0) checkedIds = [...checkedIds, ...getCheckedInTree(node.children)];
    });

    return checkedIds;
};

export const expandTreeToChild = (data, childId) => {
    return data.map(node => ({
        ...node,
        children: node.children && expandTreeToChild(node.children, childId),
        expanded: node.children ? node.children.some(child => child.id == childId) : false
    }));
};

export const buildCaseToItemMap = items =>
    items.reduce(
        (caseItemMap, item) => ({
            ...caseItemMap,
            [item.Case_Id]: {
                CaseNumber: item.CaseNumber,
                ...caseItemMap[item.Case_Id],
                items: [...(caseItemMap[item.Case_Id] ? caseItemMap[item.Case_Id].items : []), item]
            }
        }),
        {}
    );

export const getFileName = xhr => {
    var filename = '';
    var disposition = xhr;
    if (disposition) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    return filename;
};
