import React from 'react';
import { Field } from 'redux-form';
import Select from 'react-select';

import { Header, Divider, Label, Section } from '../../common/form';
import { Input } from '../../common/form';
import OrgManager from './OrgManager';
import selectorStyles from './styles';
import OrgSelect from '../../common/form/OrgSelect';

const General = ({ errors = {}, user = {}, groups = [], defaultGroups, showOrgSelector, updateGroups, disableFields = {} }) => {
    return (
        <Section>
            <Header>General</Header>
            <Divider />
            <div className="p-4">
                <div className="flex flex-col sm:flex-row mt-2">
                    <div className="mb-4 sm:mr-4 flex-grow">
                        {disableFields['UserName'] ? (
                            <div>
                                <Label htmlFor="">Username</Label>
                                <p className="py-2 font-medium text-primary">{user.UserName}</p>
                            </div>
                        ) : (
                                <Input name="UserName" label="Username" type="text" required={true} />
                            )}
                    </div>
                    <OrgSelect name="Org_Id" label="Client/Facility" required={true} />
                </div>
                <div className="flex flex-col sm:flex-row mt-2">
                    <div className="mb-4 sm:mr-4 flex-grow">
                        <Input name="FirstName" label="First Name" type="text" required={true} />
                    </div>
                    <div className="mb-4 sm:mr-4 flex-grow">
                        <Input name="MiddleName" label="Middle Name" type="text" />
                    </div>
                    <div className="mb-4 flex-grow">
                        <Input name="LastName" label="Last Name" type="text" required={true} />
                    </div>
                </div>
                {updateGroups ? (
                    <div className="flex flex-col sm:flex-row">
                        <div className="mb-4 w-full">
                            <Label>Groups</Label>
                            <Select
                                isMulti
                                styles={selectorStyles}
                                defaultValue={defaultGroups}
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.Id}
                                options={groups.sort((a, b) => {
                                    if (a.Name.toUpperCase() > b.Name.toUpperCase()) return 1;
                                    if (a.Name.toUpperCase() < b.Name.toUpperCase()) return -1;
                                    return 0;
                                })}
                                onChange={updateGroups}
                            />
                        </div>
                    </div>
                ) : (
                        <></>
                    )}
            </div>
        </Section>
    );
};

export default General;
