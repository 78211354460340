import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { caseTemplatesOperations } from '../../../../../case-templates/duck';
import { Label } from '../../../../../common/form';

class FilteredTemplateSelector extends Component {
    state = {};

    componentDidMount() {
        this.props.getCaseTemplates();
    }

    onChange = event => {
        const { setValue } = this.props;

        setValue('CaseTemplate_Id', +event.target.value);
    };

    render() {
        const { caseTemplates, gettingCaseTemplates, values, errors } = this.props;

        const availableCaseTemplates = caseTemplates
            .filter(option => option)
            .sort((a, b) => {
                return a.Label.toUpperCase() > b.Label.toUpperCase() ? 1 : -1;
            });

        return (
            <div>
                <Label>
                    Template <strong className="text-red">&nbsp;*</strong>
                </Label>
                <div className="relative">
                    <select
                        className={`input-field ${errors['CaseTemplate_Id'] ? 'border-red' : ''}`}
                        name="CaseTemplate_Id"
                        placeholder=""
                        value={values['CaseTemplate_Id']}
                        onChange={this.onChange}
                        required
                    >
                        {gettingCaseTemplates ? (
                            <option value={values['CaseTemplate_Id']} disabled>
                                Loading templates...
                            </option>
                        ) : availableCaseTemplates.length > 0 ? (
                            availableCaseTemplates.reduce(
                                (options, option = {}) => [
                                    ...options,
                                    <option key={`${shortid.generate()}-${option.Id}`} value={+option.Id}>
                                        {option.Label}
                                    </option>
                                ],
                                [
                                    <option key={`${shortid.generate()}`} value="0" disabled>
                                        Select a template...
                                    </option>
                                ]
                            )
                        ) : (
                                    <option value={values['CaseTemplate_Id']} disabled>
                                        No templates available for the selected type.
                            </option>
                                )}
                    </select>
                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                {errors['CaseTemplate_Id'] ? <p className="text-red text-xs italic">{errors['CaseTemplate_Id']}</p> : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    caseTemplates: state.caseTemplates.caseTemplates,
    gettingCaseTemplates: state.caseTemplates._updating._gettingCaseTemplates
});

const mapDispatchToProps = dispatch => ({
    getCaseTemplates: () => dispatch(caseTemplatesOperations.getCaseTemplates())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilteredTemplateSelector);
