import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import CaseForm from './CaseForm';
import { optional } from '../../../utils';

class CaseCreator extends Component {
    state = {
        FirstName: '',
        MiddleName: '',
        LastName: '',
        SSN: '',
        CreateDuplicate: false
    };

    attachmentData = new FormData();
    trackingAttachments = [];

    createCase = async values => {
        let { user } = this.props.user;
        let { CreateDuplicate } = this.state;
        this.setState(
            {
                FirstName: values.FirstName,
                MiddleName: values.MiddleName,
                LastName: values.LastName,
                SSN: values.SSN
            },
            async () => {
                try {
                    const response = await this.props.createCase({
                        RequestingOfficial_Id: values.RequestingOfficial_Id || user.Id,
                        Client_Id: values.Client_Id || user.Org_Id,
                        DataEntryClerk_Id: values.DataEntryClerk_Id,
                        Reviewer_Id: values.Reviewer_Id,
                        Notes: values.Notes,
                        CaseType_Id: values.CaseType_Id,
                        CaseSubject: {
                            FirstName: values.FirstName,
                            MiddleName: values.MiddleName,
                            LastName: values.LastName,
                            SSN: values.SSN
                        },
                        CreateDuplicate
                    });

                    this.trackingAttachments.forEach(trackingInfo => {
                        trackingInfo.Case_Id = response.data.Id;
                        console.log(trackingInfo);
                        this.props.addTrackingInfo(trackingInfo);
                    });

                    if (this.props.attachments && this.props.attachments.length) {
                        await this.props.addAttachments({ data: this.attachmentData, caseId: response.data.Id });
                        this.props.setAttachments([]);
                    }

                    this.props.onSave && this.props.onSave();

                    this.setState({ ...this.state, CreateDuplicate: false });
                } catch (error) {
                    console.log(error);
                    if (optional(error, t => t.response.data.ModelState.Duplicate, false)) {
                        this.setState({ ...this.state, CreateDuplicate: true });
                    }
                }
            }
        );
    };

    shouldCreateDuplicate = () => {
        this.setState(() => ({
            ...this.state,
            showDuplicateModal: false,
            CreateDuplicate: true
        }));
    };

    checkForDuplicate = _.debounce((name, value) => {
        if (this.state[name] !== value) {
            this.setState({ ...this.state, CreateDuplicate: false });
        }
    }, 300);

    onAttachmentAdded = (name, file, fileName) => {
        this.attachmentData.append(name, file, fileName);
        this.props.addAttachments({
            data: {
                Id: name,
                Name: fileName
            }
        });
    };

    onAttachmentDeleted = attachment => {
        this.attachmentData.delete(attachment.Id);
        this.trackingAttachments = this.trackingAttachments.filter(t => t.FedExTracking !== attachment.FedExTracking);
        console.log(this.trackingAttachments);
    };

    onTrackingInfoAdded = attachment => {
        this.trackingAttachments.push(attachment);
    };

    render() {
        const { CreateDuplicate } = this.state;

        return (
            <CaseForm
                {...this.props}
                hideFields={{ CaseNumber: true, DataEntryClerk_Id: true, Reviewer_Id: true }}
                onSubmit={this.createCase}
                onClose={() => {
                    this.setState({ CreateDuplicate: false }, this.props.onClose);
                }}
                hasDuplicate={CreateDuplicate}
                onChange={this.checkForDuplicate.bind(this)}
                onAttachmentAdded={this.onAttachmentAdded}
                onAttachmentDeleted={this.onAttachmentDeleted}
                onTrackingInfoAdded={this.onTrackingInfoAdded}
                canChangeCaseType={true}
            />
        );
    }
}

const mapState = state => ({
    attachments: state.cases.attachments
});

const mapDispatch = dispatch => ({
    createCase: payload => dispatch.cases.createCase(payload),
    addAttachments: payload => dispatch.cases.addAttachments(payload),
    setAttachments: payload => dispatch.cases.setAttachments(payload),
    addTrackingInfo: dispatch.cases.addTrackingInfo
});

export default connect(
    mapState,
    mapDispatch
)(CaseCreator);
