import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RadioGroup, Radio } from 'react-radio-group';

import Button from '../../../common/Button';
import { Input } from '../../../common/form';
import { FormConsumer, GhostForm } from '../../../common/form/Form';
import { roles } from '../../../../constants';
import Guard from '../../../common/Guard';

const DEFAULT_FORM_VALUES = {
    SelectedPhrase: '',
    Phrase: ''
};

class StockPhraseManager extends Component {
    componentDidMount() {
        const { fieldKey } = this.props;
        this.props.getStockPhrases(fieldKey);
    }

    processForm = values => {
        const { onPhraseSelected, fieldKey } = this.props;

        if (values['SelectedPhrase'] !== 'NEW_PHRASE') onPhraseSelected(values['SelectedPhrase']);
        else {
            this.props
                .addStockPhrase(fieldKey, values['Phrase'])
                .then(() => onPhraseSelected(values['Phrase']))
                .catch(() => {});
        }
    };

    deletePhrase = phrase => {
        const { fieldKey } = this.props;
        this.props.deleteStockPhrase(fieldKey, phrase);
    };

    render() {
        const { stockPhrases, gettingStockPhrases, creatingStockPhrase, errors, closeModal, fieldKey, deletingStockPhrase, disabled } = this.props;
        const phrases = stockPhrases[fieldKey] || [];

        return (
            <div className="pr-4 z-50">
                <GhostForm defaultValues={{ ...DEFAULT_FORM_VALUES }} errors={errors} onSubmit={this.processForm}>
                    {!gettingStockPhrases ? (
                        <FormConsumer>
                            {({ values, setValue }) => (
                                <RadioGroup
                                    name="SelectedPhrase"
                                    selectedValue={values['SelectedPhrase']}
                                    onChange={value => setValue('SelectedPhrase', value)}
                                >
                                    {phrases.map(phrase => (
                                        <label className="flex justify-between items-center text-primary mb-4" key={`phrase-${phrase.Id}`}>
                                            <div>
                                                <Radio value={phrase.Phrase} className="mr-4" disabled={disabled} /> {phrase.Phrase}
                                            </div>
                                            <Guard permissions={[roles.StockPhrases.DELETE_STOCK_PHRASES]}>
                                                <span
                                                    className={`text-red-light ${
                                                        !deletingStockPhrase[phrase.Id] ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                                                    }`}
                                                    onClick={() => this.deletePhrase(phrase)}
                                                >
                                                    <i
                                                        className={`fas ${!deletingStockPhrase[phrase.Id] ? 'fa-trash-alt' : 'fa-spinner fa-pulse'}`}
                                                    />
                                                </span>
                                            </Guard>
                                        </label>
                                    ))}

                                    <Guard permissions={[roles.StockPhrases.ADD_STOCK_PHRASES]}>
                                        <label className="flex items-center">
                                            <Radio value="NEW_PHRASE" className="mr-4" />
                                            <Input
                                                className="w-full"
                                                name="Phrase"
                                                label="New Phrase"
                                                disabled={values['SelectedPhrase'] !== 'NEW_PHRASE' || disabled}
                                                hideLabel
                                            />
                                        </label>
                                    </Guard>
                                </RadioGroup>
                            )}
                        </FormConsumer>
                    ) : (
                        <span>
                            Loading stock phrases... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                    <div className="flex justify-end items-end mt-4">
                        <FormConsumer>
                            {({ forceSubmit }) => (
                                <>
                                    <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                        Cancel
                                    </Button>
                                    {!creatingStockPhrase ? (
                                        <Button onClick={forceSubmit}>Add Selected Phrase</Button>
                                    ) : (
                                        <Button disabled>
                                            Adding Selected Phrase... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                                </>
                            )}
                        </FormConsumer>
                    </div>
                </GhostForm>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    gettingStockPhrases: state.stockPhrases._updating._gettingStockPhrases,
    creatingStockPhrase: state.stockPhrases._updating._creatingStockPhrase,
    deletingStockPhrase: state.stockPhrases._updating._deletingStockPhrase,
    errors: (state.stockPhrases._errors && state.stockPhrases._errors.ModelState) || {},
    stockPhrases: state.stockPhrases.stockPhrases
});

const mapDispatchToProps = dispatch => ({
    getStockPhrases: fieldKey => dispatch.stockPhrases.getStockPhrases(fieldKey),
    addStockPhrase: (fieldKey, phrase) => dispatch.stockPhrases.addStockPhrase({ fieldKey, phrase }),
    deleteStockPhrase: (fieldKey, phrase) => dispatch.stockPhrases.deleteStockPhrase({ fieldKey, phrase })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StockPhraseManager);
