import React, { Component } from 'react';
import shortid from 'shortid';

import Button from '../../../common/Button';
import { SECTION_EDITOR_TYPES } from '../../../../constants';

class SBFieldAdd extends Component {
    state = {
        selectedField: undefined
    };

    componentDidMount() {
        if (!this.props.disableShortcuts) document.addEventListener('keyup', this.handleNewKeyPress);
    }

    componentWillUnmount() {
        if (!this.props.disableShortcuts) document.removeEventListener('keyup', this.handleNewKeyPress);
    }

    handleNewKeyPress = e => {
        if (e.code === 'KeyN' && e.target.tagName !== 'INPUT' && e.target.tagName !== 'SELECT') {
            e.stopPropagation();
            e.preventDefault();
            if (this.state.selectedField) this.addField();
        }
    };

    selectedFieldUpdated = event => {
        this.setState({
            ...this.state,
            selectedField: event.target.value
        });
    };

    addField = event => {
        event && event.preventDefault();
        const field = SECTION_EDITOR_TYPES.find(field => field['@type'] === this.state.selectedField);

        if (field !== undefined)
            this.props.onAdd({
                ...field,
                '@id': shortid.generate()
            });
    };

    render() {
        const { selectedField } = this.state;
        const { disabledTypes } = this.props;
        const filteredTypes = (!SECTION_EDITOR_TYPES
            ? undefined
            : disabledTypes
                ? SECTION_EDITOR_TYPES.filter(t => !disabledTypes.includes(t['@type']))
                : SECTION_EDITOR_TYPES
        ).sort((a, b) => (a['@type'] > b['@type'] ? 1 : -1));

        return (
            <div className="flex justify-between items-center">
                <div className="relative flex-grow">
                    <select
                        className="input-field"
                        name="selectedField"
                        placeholder=""
                        value={selectedField || ''}
                        onKeyPress={e => {
                            if (e.which === 13) {
                                e.preventDefault();
                                this.addField();
                            }
                        }}
                        onChange={this.selectedFieldUpdated}
                    >
                        {!filteredTypes ? (
                            <option value={selectedField} disabled>
                                Loading...
                            </option>
                        ) : (
                            filteredTypes.reduce(
                                (options, option) => [
                                    ...options,
                                    <option key={`${shortid.generate()}-${option['@type']}`} value={option['@type']}>
                                        {option['@type'].toTitleCase()}
                                    </option>
                                ],
                                [
                                    <option key={`${shortid.generate()}`} value="" disabled>
                                        Select a field...
                                    </option>
                                ]
                            )
                        )}
                    </select>
                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
                <Button disabled={selectedField === undefined} icon="fas fa-plus" onClick={this.addField} className="py-3 ml-2">
                    Add Field
                </Button>
            </div>
        );
    }
}

export default SBFieldAdd;
