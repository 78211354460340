import React, { Component } from 'react';
import { connect } from 'react-redux';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/xml';
import 'brace/theme/xcode';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Button from '../../common/Button';
import { Input, EnumTypeSelector, Checkbox } from '../../common/form';
import { Form, FormConsumer } from '../../common/form/Form';
import { caseTemplatesOperations } from '../duck';

import CCTItems from './CreateCaseTemplate/CCTItems';

class CreateCaseTemplate extends Component {
    state = {
        showXsltEditor: false
    };

    toggleXsltEditor = () => {
        this.setState({
            ...this.state,
            showXsltEditor: !this.state.showXsltEditor
        });
    };

    createCase = values => {
        const { createValues } = this.props;
        const caseTemplate = {
            Label: values['Label'],
            CaseType_Id: +values['CaseType_Id'],
            ContractType_Id: +values['ContractType_Id'],
            ROICoversheetTemplate: values['ROICoversheetTemplate'],
            ROIStylesheet: values['ROIStylesheet'],
            ROIHeader: values['ROIHeader'],
            ROIFooter: values['ROIFooter'],
            ShowCoverPage: values['ShowCoverPage'],
            ItemTypeIds: values['ItemTypeIds']
                ? values['ItemTypeIds'].map(it => ({
                    ItemType_Id: it.Id,
                    AutoAdd: it.AutoAdd || false,
                    ShowInReport: it.ShowInReport || false,
                    ShowTitle: it.ShowTitle || false,
                    DisplayOrder: it.DisplayOrder
                }))
                : []
        };

        (createValues.Id !== undefined
            ? this.props.editCaseTemplate({ ...caseTemplate, Id: createValues.Id })
            : this.props.createCaseTemplate(caseTemplate)
        )
            .then(() => this.props.closeModal())
            .catch(() => { });
    };

    render() {
        const { showXsltEditor } = this.state;
        const { closeModal, createValues, errors, creatingCaseTemplate, caseTemplateUpates } = this.props;
        const isEdit = createValues['Id'] !== undefined;
        const isProcessing = creatingCaseTemplate || (isEdit ? caseTemplateUpates[createValues['Id']] : false);

        return (
            <Form defaultValues={createValues} onSubmit={this.createCase} errors={errors}>
                <>
                    <div className="flex flex-wrap -mx-2">
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Label" label="Label" required />
                        </div>
                        <div className="w-1/2 px-2">
                            <EnumTypeSelector type="ContractType" name="ContractType_Id" label="Contract Type" required showShort={true} />
                        </div>
                        <div className="w-full px-2 mb-4">
                            <Checkbox name="ShowCoverPage" label="Show Cover Page On Report?" />
                        </div>
                        <Guard permissions={[roles.CaseTemplates.ADD_ITEM_TO_CASE_TEMPLATE]}>
                            <div className="w-full px-2">
                                <FormConsumer>
                                    {({ values, setValue, removeValue }) =>
                                        !showXsltEditor ? (
                                            <CCTItems values={values} setValue={setValue} removeValue={removeValue} />
                                        ) : (
                                                <div className="flex flex-col">
                                                    <div className="flex flex-col lg:flex-row">
                                                        <div className="flex flex-col w-full lg:w-1/2 lg:mr-4">
                                                            <strong className="block mt-4 mb-2">Cover Sheet XSLT</strong>
                                                            <AceEditor
                                                                editorProps={{
                                                                    $blockScrolling: Infinity
                                                                }}
                                                                width="100%"
                                                                mode="xml"
                                                                theme="xcode"
                                                                onChange={newValue => setValue('ROICoversheetTemplate', newValue)}
                                                                fontSize={12}
                                                                showPrintMargin={true}
                                                                showGutter={true}
                                                                highlightActiveLine={true}
                                                                value={values['ROICoversheetTemplate'] || ''}
                                                                setOptions={{
                                                                    enableBasicAutocompletion: true,
                                                                    enableLiveAutocompletion: true,
                                                                    showLineNumbers: true,
                                                                    tabSize: 4
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col w-full lg:w-1/2">
                                                            <strong className="block mt-4 mb-2">Stylesheet XSLT</strong>
                                                            <AceEditor
                                                                editorProps={{
                                                                    $blockScrolling: Infinity
                                                                }}
                                                                width="100%"
                                                                mode="xml"
                                                                theme="xcode"
                                                                onChange={newValue => setValue('ROIStylesheet', newValue)}
                                                                fontSize={12}
                                                                showPrintMargin={true}
                                                                showGutter={true}
                                                                highlightActiveLine={true}
                                                                value={values['ROIStylesheet'] || ''}
                                                                setOptions={{
                                                                    enableBasicAutocompletion: true,
                                                                    enableLiveAutocompletion: true,
                                                                    showLineNumbers: true,
                                                                    tabSize: 4
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col lg:flex-row lg:mb-4">
                                                        <div className="flex flex-col w-full lg:w-1/2 lg:mr-4">
                                                            <strong className="block mt-4 mb-2">Header XSLT</strong>
                                                            <AceEditor
                                                                editorProps={{
                                                                    $blockScrolling: Infinity
                                                                }}
                                                                width="100%"
                                                                mode="xml"
                                                                theme="xcode"
                                                                onChange={newValue => setValue('ROIHeader', newValue)}
                                                                fontSize={12}
                                                                showPrintMargin={true}
                                                                showGutter={true}
                                                                highlightActiveLine={true}
                                                                value={values['ROIHeader'] || ''}
                                                                setOptions={{
                                                                    enableBasicAutocompletion: true,
                                                                    enableLiveAutocompletion: true,
                                                                    showLineNumbers: true,
                                                                    tabSize: 4
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col w-full lg:w-1/2">
                                                            <strong className="block mt-4 mb-2">Footer XSLT</strong>
                                                            <AceEditor
                                                                editorProps={{
                                                                    $blockScrolling: Infinity
                                                                }}
                                                                width="100%"
                                                                mode="xml"
                                                                theme="xcode"
                                                                onChange={newValue => setValue('ROIFooter', newValue)}
                                                                fontSize={12}
                                                                showPrintMargin={true}
                                                                showGutter={true}
                                                                highlightActiveLine={true}
                                                                value={values['ROIFooter'] || ''}
                                                                setOptions={{
                                                                    enableBasicAutocompletion: true,
                                                                    enableLiveAutocompletion: true,
                                                                    showLineNumbers: true,
                                                                    tabSize: 4
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </FormConsumer>
                            </div>
                        </Guard>
                    </div>
                    <div className="flex justify-between items-end mt-4">
                        <Guard permissions={[roles.CaseTemplates.CREATE_CASE_TEMPLATE]}>
                            <Button onClick={this.toggleXsltEditor} inverse icon={`fas ${!showXsltEditor ? 'fa-i-cursor' : 'file-invoice'}`}>
                                Switch to {!showXsltEditor ? 'XSLT Editor' : 'Items'}
                            </Button>
                        </Guard>
                        <div className="flex">
                            <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                Cancel
                            </Button>
                            {!isProcessing ? (
                                <Button type="submit">{isEdit ? 'Edit' : 'Add'} Case Template</Button>
                            ) : (
                                    <Button disabled>
                                        {isEdit ? 'Editing' : 'Adding'} Case Template... <i className="fas fa-spinner fa-pulse" />
                                    </Button>
                                )}
                        </div>
                    </div>
                </>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    errors: state.caseTemplates._errors && state.caseTemplates._errors.ModelState,
    creatingCaseTemplate: state.caseTemplates._updating._creatingCaseTemplate,
    caseTemplateUpates: state.caseTemplates._updating._caseTemplates
});

const mapDispatchToProps = dispatch => ({
    createCaseTemplate: caseTemplate => dispatch(caseTemplatesOperations.createCaseTemplate(caseTemplate)),
    editCaseTemplate: caseTemplate => dispatch(caseTemplatesOperations.editCaseTemplate(caseTemplate))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCaseTemplate);
