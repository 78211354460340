import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ItemsList from './ItemsList';
import _ from 'lodash';

import Button from '../common/Button';
import { optional, setCheckedInTree, getCheckedInTree } from '../../utils';
import SideNav from '../general/SideNav';
import SideNavMenuItem from '../general/SideNavItem';
import CustomTreeView from '../common/CustomTreeView';

class Items extends Component {
    state = {
        itemStatusTree: []
    };
    itemListHandle = undefined;

    componentDidMount() {
        this.props.getItemStates();
    }

    static getDerivedStateFromProps({ ItemStates, itemFilters }, prevState) {
        // Skip if we already generated the trees
        if (prevState.itemStatusTree.length) {
            // We will want to update for any saved filters, however.
            return {
                ...prevState,
                casesStatusTree: setCheckedInTree(prevState.casesStatusTree, itemFilters.additional.CaseStateIDs || []),
                itemStatusTree: setCheckedInTree(prevState.itemStatusTree, itemFilters.additional.ItemStateIDS || []),
                orgs: setCheckedInTree(prevState.orgs, itemFilters.additional.OrgIds || [])
            };
        }

        const itemStatusTree = ItemStates.map(({ Id, Short }) => ({ id: Id, name: _.startCase(_.toLower(Short)) }));

        return { ...prevState, itemStatusTree };
    }

    clearFilters = () => {
        function unCheck(node) {
            node.isChecked = false;

            if (node.children) {
                node.children.forEach(child => unCheck(child));
            }
        }

        let { itemStatusTree } = this.state;
        itemStatusTree.forEach(node => unCheck(node));

        this.itemListHandle && this.itemListHandle.clearFilters()

        this.setState(
            {
                itemStatusTree: []
            },
            () =>
                this.setState(
                    {
                        itemStatusTree
                    },
                    () => this.itemListHandle && this.itemListHandle.fetchData()
                )
        );
    };

    render() {
        const { itemFilters } = this.props;
        const { itemStatusTree } = this.state;

        return (
            <>
                <SideNav icons={['fa-paperclip']}>
                    <div className="w-100">
                        <div className="mb-4">
                            <Button className="m-auto" inverse onClick={this.clearFilters}>
                                Clear Filters
                            </Button>
                        </div>
                        <SideNavMenuItem icon="fa-paperclip" title="Item Status">
                            <CustomTreeView
                                header="Item Status"
                                data={itemStatusTree}
                                onUpdateCb={(updatedData) => {
                                    const oldIds = itemFilters.additional ? itemFilters.additional.ItemStateIDS : [];
                                    const newValues = getCheckedInTree(updatedData);
                                    this.props.updateItemFilters({
                                        ...itemFilters,
                                        additional: {
                                            ...itemFilters.additional,
                                            ItemStateIDS: newValues
                                        }
                                    });
                                    this.setState(
                                        {
                                            itemStatusTree: updatedData
                                        },
                                        () => {
                                            if (!_.isEqual(newValues, oldIds)) this.itemListHandle && this.itemListHandle.fetchData();
                                        }
                                    );
                                }}
                                onCheckToggleCb={nodes => {
                                    const checkState = nodes[0].isChecked;
                                    const ItemStateIDS = [];

                                    const applyCheckStateTo = nodes => {
                                        nodes.forEach(node => {
                                            node.isChecked = checkState;
                                            if (checkState) ItemStateIDS.push(node.id);
                                            if (node.children) applyCheckStateTo(node.children);
                                        });
                                    };

                                    applyCheckStateTo(nodes);
                                    this.props.updateItemFilters({
                                        ...itemFilters,
                                        additional: {
                                            ...itemFilters.additional,
                                            ItemStateIDS: checkState ?
                                                [...(itemFilters.additional.ItemStateIDS || []), ...ItemStateIDS] :
                                                (itemFilters.additional.ItemStateIDS || []).filter(id => !ItemStateIDS.includes(id))
                                        }
                                    });
                                }}
                                node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                            />
                        </SideNavMenuItem>
                    </div>
                </SideNav>
                <div className="flex">
                    <div className="w-full ml-12">
                        <div className="bg-white rounded pl-4 shadow mb-8 overflow-x-auto pb-4">
                            <ItemsList
                                all
                                ItemStateIDS={[]}
                                provideConnection={itemListHandle => { this.itemListHandle = itemListHandle }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapState = state => ({
    access_token: optional(state, t => t.account.auth.access_token, ''),
    ItemStates: state.workflowState.ItemStates || [],
    itemFilters: state.filters.itemFilters
});

const mapDispatch = dispatch => ({
    getItemStates: dispatch.workflowState.getItemStates,
    updateItemFilters: filters => dispatch.filters.updateItemFilters(filters)
});

export default withRouter(
    connect(
        mapState,
        mapDispatch
    )(Items)
);
