import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import AddSectionForm from './AddSectionForm';
import SectionListItem from './SectionListItem';

class ItemSectionsManager extends Component {
    state = {
        showAddModal: false
    };

    componentWillUnmount() {
        this.props.setCurrentSectionForItemSuccess(undefined);
    }

    openAddModal = () => {
        this.setState({
            ...this.state,
            showAddModal: true
        });
    };
    closeAddModal = () => {
        this.setState({
            ...this.state,
            showAddModal: false
        });
    };

    render() {
        const { showAddModal } = this.state;
        const { item, sectionErrors, currentSection, disabled } = this.props;

        return (
            <>
                <div className="mt-8 mb-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="font-bold text-primary">Sections</div>
                        <Button inverse onClick={this.openAddModal} disabled={disabled}>
                            Add Section
                        </Button>
                    </div>
                    <div className="py-4 mt-4 bg-grey-lighter rounded shadow-inner">
                        {item.Sections.length > 0 ? (
                            <div className="mx-4 bg-white rounded py-4 shadow">
                                {item.Sections.map(section => (
                                    <SectionListItem
                                        hasErrors={!!sectionErrors[section.Id]}
                                        key={`section-${section.Id}`}
                                        section={section}
                                        item={item}
                                        showModal={section.Id == currentSection}
                                        disabled={disabled}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center text-primary h-full">This item has no sections, please add some.</div>
                        )}
                    </div>
                </div>
                <Modal show={showAddModal} onClose={this.closeAddModal} style={{ overflow: 'visible' }}>
                    <div className="p-4">{showAddModal ? <AddSectionForm closeModal={this.closeAddModal} /> : <></>}</div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    item: state.item.current,
    currentSection: state.item.currentSection
});
const mapDispatchToProps = dispatch => ({
    setCurrentSectionForItemSuccess: dispatch.item.setCurrentSectionForItemSuccess
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemSectionsManager);
