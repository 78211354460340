import React from 'react';
import CustomCheckbox from '../../../../common/CustomCheckbox';

const TextareaConfigurer = ({ field, onChange }) => (
    <div className="flex justify-between items-center flex-wrap -mx-4">
        <div className="w-1/2 px-4">
            <label className="block text-primary text-sm mt-4 mb-2">
                <CustomCheckbox
                    name="@allowStockPhrases"
                    checked={field['@allowStockPhrases']}
                    onChange={event => onChange(field, event.target.name, event.target.checked)}
                />
                &nbsp;&nbsp;&nbsp;Allow Stock Phrases
            </label>
        </div>
    </div>
);

export default TextareaConfigurer;
