import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'whatwg-fetch';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import shortid from 'shortid';
import { USER_PROFILE_PAGE_SIZE } from '../../../constants';
import { baseUrl } from '../../../config';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import { accountSelectors } from '../../account/duck';
import { roles } from '../../../constants';
import { Header, Divider } from '../../common/form/';
import Guard from '../../common/Guard';

class Attachments extends Component {
    state = {
        errors: {}
    };
    columns = [
        {
            Header: 'Name',
            accessor: 'Name',
            Cell: row => (
                <span
                    className="flex flex-grow items-center h-full hover:underline cursor-pointer"
                    onClick={e => {
                        e.preventDefault();
                        this.downloadFile(row.original);
                    }}
                >
                    {row.original.Name}
                </span>
            )
        },
        {
            Header: 'Tracking',
            accessor: 'FedExTracking',
            Cell: row => (
                <div className="w-full text-center">
                    <a href={`https://www.fedex.com/apps/fedextrack/?tracknumbers=${row.original.FedExTracking}`} target="_blank">
                        {row.original.FedExTracking}
                    </a>
                </div>
            )
        },
        {
            Header: 'Added On',
            accessor: 'CreatedDate',
            Cell: row => (
                <div className="w-full text-center">
                    {moment(row.original.CreatedDate)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            Header: '',
            id: 'Actions',
            filterable: false,
            sortable: false,
            accessor: d => d,
            Cell: row => (
                <div className="flex justify-center items-center h-full">
                    <span
                        className="text-grey cursor-pointer mr-4"
                        onClick={e => {
                            e.preventDefault();
                            this.downloadFile(row.original);
                        }}
                    >
                        <i className="fas fa-download" />
                    </span>

                    <Guard permissions={[roles.CaseAttachments.DELETE_ATTACHMENTS]}>
                        <span className="text-red-light cursor-pointer" onClick={this.confirmDelete.bind(this, row.original)}>
                            <i className="fas fa-trash-alt" />
                        </span>
                    </Guard>
                </div>
            )
        }
    ];

    confirmDelete = attachment => {
        this.setState({
            showModal: true,
            attachmentToDelete: { ...attachment }
        });
    };

    async downloadFile(attachment) {
        let { access_token } = this.props;
        try {
            const response = await fetch(`${baseUrl}/api/cases/${attachment.Case_Id}/attachments/${attachment.Id}/file`, {
                headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
            });

            let blob = await response.blob();

            FileSaver.saveAs(blob, attachment.Name);
        } catch (error) {
            toast.error('Unable to retrieve file.');
        }
    }

    uploadFile = async acceptedFiles => {
        let data = new FormData();

        acceptedFiles.forEach(file => {
            const name = shortid.generate();
            data.append(name, file, file.name);
            this.props.onAttachmentAdded && this.props.onAttachmentAdded(name, file, file.name);
        });

        if (this.props.caseId) {
            await this.props.addAttachments({ data, caseId: this.props.caseId });
        }
    };

    deleteAttachment = async attachment => {
        await this.props.deleteAttachment(attachment);

        this.setState({
            showModal: false,
            attachmentToDelete: undefined
        });

        this.props.onAttachmentDeleted && this.props.onAttachmentDeleted(attachment);
    };

    render() {
        const { uploading, showTrackingModal, deleting } = this.props;
        const { showModal, attachmentToDelete } = this.state;

        return (
            <Guard permissions={[roles.CaseAttachments.VIEW_ATTACHMENTS]}>
                <div className="flex align-center justify-between">
                    <Header>Papers</Header>
                    <Guard permissions={[roles.CaseAttachments.CREATE_ATTACHMENTS]}>
                        <Button onClick={showTrackingModal}>Add Tracking Info</Button>
                    </Guard>
                </div>
                <Divider />
                <div className="mt-2" />
                <ReactTable
                    columns={this.columns}
                    data={this.props.attachments}
                    getNoDataProps={() => ({
                        style: { display: 'none' }
                    })}
                    showPagination={false}
                    defaultPageSize={USER_PROFILE_PAGE_SIZE}
                    pageSize={this.props.attachments.length}
                />
                <div className="flex w-full mb-4">
                    <Guard permissions={[roles.CaseAttachments.CREATE_ATTACHMENTS]}>
                        <Dropzone multiple className="w-full h-64 border-dashed border-4" onDrop={this.uploadFile}>
                            <div className="flex w-full justify-center items-center h-full">
                                {uploading ? (
                                    <i className="fas fa-spinner fa-pulse fa-3x" />
                                ) : (
                                    <h3 className="text-primary text-base">Click or drop files here (zip files will be extracted)</h3>
                                )}
                            </div>
                        </Dropzone>
                    </Guard>
                </div>
                <Modal show={showModal} className="max-w-md" onClose={() => this.setState({ showModal: false })}>
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>{`Remove ${attachmentToDelete && attachmentToDelete.Name}?`} This action cannot be undone</p>
                    <div className="w-full flex mt-4">
                        <Button className="bg-grey ml-auto mr-4" onClick={() => this.setState({ showModal: false })}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.deleteAttachment.bind(this, attachmentToDelete)} loading={deleting}>
                            Remove
                        </Button>
                    </div>
                </Modal>
            </Guard>
        );
    }
}
const mapState = state => ({
    attachments: state.cases.attachments,
    access_token: state.account.auth.access_token,
    uploading: state.cases._updating._uploadingFiles,
    deleting: state.cases._updating._deletingFiles,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});
const mapDispatch = dispatch => ({
    addAttachments: payload => dispatch.cases.addAttachments(payload),
    deleteAttachment: payload => dispatch.cases.deleteAttachment(payload)
});
export default connect(
    mapState,
    mapDispatch
)(Attachments);
