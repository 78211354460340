import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';

class ItemHistoryList extends Component {
    componentDidMount() {
        const { item } = this.props;
        this.props.getItemHistory(item.Case.Id, item.Id);
    }

    render() {
        const { gettingItemHistory, history } = this.props;
        const columns = [{
            id: 'Status',
            Header: 'Status',
            maxWidth: 100,
            accessor: d => d.Status ? d.Status.Description : '',
            Cell: ({ value }) => <div className="text-center">{value}</div>
        }, {
            id: 'TriggeredBy',
            Header: 'Triggered By',
            maxWidth: 200,
            accessor: d => d.TriggeredBy ? `${d.TriggeredBy.FirstName} ${d.TriggeredBy.LastName}` : '',
            Cell: ({ value }) => <div className="text-center">{value}</div>
        }, {
            Header: 'Note',
            accessor: 'Note',
        }, {
            Header: 'Date',
            accessor: 'CreatedDate',
            maxWidth: 100,
            Cell: ({ value }) => <div className="text-center">{moment.utc(value).format('MM/DD/YYYY')}</div>
        }];

        return (<div className="mt-8 mb-4">
            <div className="font-bold text-primary mb-4">History</div>
            {!gettingItemHistory ?
                history.length > 0 ?
                    <ReactTable
                        pageSize={history.length}
                        data={history}
                        columns={columns}
                        showPagination={false}
                        showPageSizeOptions={false} /> :
                    <div className="text-primary">This item has no history.</div> :
                <span>Loading history... <i className="fas fa-spinner fa-pulse" /></span>
            }
        </div>);
    }
};

const mapStateToProps = (state) => ({
    gettingItemHistory: state.item._updating._gettingItemHistory,
    history: state.item.history
});

const mapDispatchToProps = (dispatch) => ({
    getItemHistory: (caseId, itemId) => dispatch.item.getItemHistory({ caseId, itemId })
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemHistoryList);