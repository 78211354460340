import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { orgOperations } from '../duck';
import Button from '../../common/Button';
import OrgSelect from '../../common/form/OrgSelect';
import { Form } from '../../common/form/Form';
import { Header, Divider, Input, EnumTypeSelector, PhoneNumber } from '../../common/form';

const DEFAULT_CREATE_VALUES = {
    ParentOrg_Id: 0,
    Name: null,
    Code: null,
    ContractType_Id: null,
    Address1: null,
    Address2: null,
    City: null,
    RegionType_Id: 0,
    PostalCode: null,
    Phone1: null,
    Phone2: null,
    Fax: null
};

class CreateOrgForm extends Component {
    state = {
        createdOrg: undefined
    };

    createOrg = values => {
        this.props
            .createOrg({
                ParentOrg_Id: +values['ParentOrg_Id'],
                Name: values['Name'],
                Code: values['Code'],
                ContractType_Id: +values['ContractType_Id'],
                Address1: values['Address1'],
                Address2: values['Address2'],
                City: values['City'],
                RegionType_Id: +values['RegionType_Id'],
                PostalCode: values['PostalCode'],
                Phone1: values['Phone1'],
                Phone2: values['Phone2'],
                Fax: values['Fax']
            })
            .then(org => {
                this.setState(
                    {
                        createdOrg: org
                    },
                    () => this.props.closeModal()
                );
            })
            .catch(() => {});
    };

    render() {
        const { creatingOrg, errors, closeModal } = this.props;
        const { createdOrg } = this.state;

        return (
            <>
                {createdOrg !== undefined ? <Redirect to={`/orgs/${createdOrg.Id}`} /> : <></>}
                <Form defaultValues={DEFAULT_CREATE_VALUES} errors={errors} onSubmit={this.createOrg}>
                    <div className="flex -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <OrgSelect label="Parent Org" name="ParentOrg_Id" showError required />
                        </div>
                        <div className="w-1/2 px-2">
                            <EnumTypeSelector label="Contract Type" type="ContractType" name="ContractType_Id" />
                        </div>
                    </div>
                    <div className="flex -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Name" label="Name" required />
                        </div>
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Code" label="Code" required />
                        </div>
                    </div>

                    <div className="mt-8 mb-4">
                        <Header>Address</Header>
                        <Divider />
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Address1" label="Street" required />
                        </div>
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Address2" label="&nbsp;" />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/3 px-2">
                            <Input type="text" name="City" label="City" required />
                        </div>
                        <div className="w-1/3 px-2">
                            <EnumTypeSelector type="RegionType" name="RegionType_Id" label="State" />
                        </div>
                        <div className="w-1/3 px-2">
                            <Input type="text" name="PostalCode" label="PostalCode" required />
                        </div>
                    </div>

                    <div className="mt-8 mb-4">
                        <Header>Phones</Header>
                        <Divider />
                    </div>
                    <div className="flex flex-wrap -mx-2 mb-4">
                        <div className="w-1/3 px-2">
                            <PhoneNumber type="text" name="Phone1" label="Phone 1" required />
                        </div>
                        <div className="w-1/3 px-2">
                            <PhoneNumber type="text" name="Phone2" label="Phone 2" />
                        </div>
                        <div className="w-1/3 px-2">
                            <PhoneNumber type="text" name="Fax" label="Fax" />
                        </div>
                    </div>

                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!creatingOrg ? (
                            <Button type="submit">Create Org</Button>
                        ) : (
                            <Button disabled>
                                Creating Org... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    creatingOrg: state.orgs._updating._creatingOrg,
    errors: state.orgs._errors ? state.orgs._errors.ModelState || {} : {}
});

const mapDispatchToProps = dispatch => ({
    createOrg: org => dispatch(orgOperations.createOrg(org))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrgForm);
