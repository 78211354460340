import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const invoices = {
    state: {
        orgs: {},
        _errors: {},
        _updating: {
            _gettingInvoices: false,
            _creatingInvoice: false
        }
    },
    reducers: {
        getInvoicesRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _gettingInvoices: true }
        }),
        getInvoicesSuccess: (state, orgs) => ({
            ...state,
            orgs,
            _errors: {},
            _updating: { ...state._updating, _gettingInvoices: false }
        }),
        getInvoicesFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingInvoices: false }
        }),

        createInvoiceRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _creatingInvoice: true }
        }),
        createInvoiceSuccess: (state, { orgName, updatedOrg }) => ({
            ...state,
            orgs: {
                ...state.orgs,
                [orgName]: updatedOrg
            },
            _errors: {},
            _updating: { ...state._updating, _creatingInvoice: false }
        }),
        createInvoiceFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _creatingInvoice: false }
        })
    },
    effects: dispatch => ({
        async getInvoices(orgIds, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.invoices.getInvoicesRequest();

            try {
                const invoices = await axios.post(
                    `${baseUrl}/api/invoices/get-cases`,
                    {
                        Org_Ids: orgIds
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                dispatch.invoices.getInvoicesSuccess(invoices.data);
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.invoices.getInvoicesFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async createInvoice({ caseIds, orgName, invoiceDate }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.invoices.createInvoiceRequest();

            try {
                const result = await axios.post(
                    `${baseUrl}/api/invoices/create-invoice`,
                    {
                        Case_Ids: caseIds,
                        OrgName: orgName,
                        InvoiceDate: invoiceDate
                    },
                    {
                        headers: { Authorization: `Bearer ${access_token}` }
                    }
                );

                toast.success('The cases were invoiced successfully.');
                dispatch.invoices.createInvoiceSuccess({
                    orgName,
                    updatedOrg: result.data === '' ? null : result.data
                });
                return Promise.resolve(result.data === '' ? null : result.data);
            } catch (error) {
                toast.error('There was an error creating the invoices for the case.');
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.invoices.createInvoiceFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        }
    })
};
