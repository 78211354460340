import React from 'react';
import { roles } from '../../constants';
import { buildDesktopNavigation, MobileNavigationItem } from '../../utils/navigation';

const reportsNavigation = {
    linkText: 'Reports',
    icon: 'fa-folder',
    props: { to: '/reports' },
    permissions: [roles.ReportData.VIEW_REPORT],
    children: []
};

export const reportsDesktopNavigation = buildDesktopNavigation(reportsNavigation);
export const reportsMobileNavigation = <MobileNavigationItem key="reports-mobile-nav" navigation={reportsNavigation} menuLevel={0} />;
