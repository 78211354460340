export const roles = {
    Cases: {
        CREATE_CASE_TEMPLATE: 'Admin Case Templates',
        CREATE_CASE: 'Create Case',
        CREATE_INVOICES: 'Create Invoices',
        DELETE_CASES: 'Delete Cases',
        DISCONTINUE_CASE: 'Discontinue Case',
        RESUME_CASE: 'Resume A Case',
        DOWNLOAD_REPORT: 'Download Report',
        DOWNLOAD_INCOMPLETE_REPORT: 'Download Incomplete Report',
        EDIT_CASE_DEADLINE: 'Edit Case Deadline',
        EDIT_CASES: 'Edit Cases',
        MARK_DATA_ENTRY_COMPLETE: 'Mark Data Entry Complete',
        PUT_CASE_ON_HOLD: 'Put Case On Hold',
        VIEW_CASE_TEMPLATE: 'Admin Case Templates',
        VIEW_CASE_XML: 'Case XML',
        VIEW_CASE_XSLT: 'Case XSLT',
        VIEW_CASES: 'View Cases',
        VIEW_CASE_CRITICAL_DATE: 'View Case Critical Date',
        EDIT_CASE_CRITICAL_DATE: 'Edit Case Critical Date',
        EDIT_CASE_COMPLETION_DATE: 'Edit Case Completion Date',
        ASSIGN_REVIEWER: 'Assign Reviewer',
        ASSIGN_DATA_ENTRY: 'Assign Data Entry',
        CHANGE_REQUESTING_OFFICIAL: 'Change Requesting Official',
        CHANGE_CASE_TYPE: 'Change Case Type',
        EDIT_INACTIVE_CASES: 'Edit Inactive Cases'
    },
    CaseAttachments: {
        CREATE_ATTACHMENTS: 'Create Attachments',
        DELETE_ATTACHMENTS: 'Delete Attachments',
        EDIT_ATTACHMENTS: 'Edit Attachments',
        VIEW_ATTACHMENTS: 'View Attachments'
    },
    CaseMessages: {
        VIEW_MESSAGES: 'View Messages',
        CREATE_MESSAGE: 'Create Message'
    },
    CaseItems: {
        CREATE_CASE_ITEMS: 'Create Case Items',
        DELETE_CASE_ITEMS: 'Delete Case Items',
        EDIT_CASE_ITEMS: 'Edit Case Items',
        MANAGE_CASE_ITEM_DEADLINES: 'Manage Item Deadlines',
        REVIEW_CASE_ITEMS: 'Review Items',
        UPDATE_CASE_ITEM_STATUS: 'Update Case Item Status',
        VIEW_CASE_ITEMS: 'View Case Items',
        VIEW_ITEM_HISTORY: 'View Item History',
        ADD_ITEM_ISSUE_CODES: 'Add Item Issue Codes',
        DELETE_ITEM_ISSUE_CODES: 'Delete Item Issue Codes',
        REORDER_CASE_ITEMS: 'Reorder Case Items',
        EDIT_ITEMS_IN_REVIEW: 'Edit Items In Review'
    },
    CaseSubjects: {
        CREATE_SUBJECT: 'Create Subject',
        DELETE_SUBJECT: 'Delete Subject',
        EDIT_SUBJECT: 'Edit Subject',
        VIEW_SUBJECT: 'View Subject'
    },
    CaseTemplates: {
        GET_CASE_TEMPLATES: 'Admin Case Templates',
        CREATE_CASE_TEMPLATE: 'Admin Case Templates',
        UPDATE_CASE_TEMPLATE: 'Admin Case Templates',
        DELETE_CASE_TEMPLATE: 'Admin Case Templates',
        ADD_ITEM_TO_CASE_TEMPLATE: 'Admin Case Templates',
        REMOVE_ITEM_FROM_CASE_TEMPLATE: 'Admin Case Templates',
        REORDER_CASE_TEMPLATE_ITEMS: 'Admin Case Templates'
    },
    CaseTypes: {
        CREATE_CASE_TYPE: 'Create Case Type',
        DELETE_CASE_TYPE: 'Delete Case Type',
        EDIT_CASE_TYPE: 'Edit Case Type',
        VIEW_CASE_TYPE: 'View Case Type'
    },
    ExpenseRules: {
        CREATE_EXPENSE_RULE: 'Admin Expense Units',
        DELETE_EXPENSE_RULE: 'Admin Expense Units',
        EDIT_EXPENSE_RULE: 'Admin Expense Units',
        VIEW_EXPENSE_RULE: 'Admin Expense Units'
    },
    ExpenseTypes: {
        CREATE_EXPENSE_TYPE: 'Create Expense Type',
        DELETE_EXPENSE_TYPE: 'Delete Expense Type',
        EDIT_EXPENSE_TYPE: 'Edit Expense Type',
        VIEW_EXPENSE_TYPE: 'View Expense Type'
    },
    General: {
        DASHBOARD: 'Dashboard',
        SYSTEM_ADMINISTRATOR: 'System Administrator',
        VIEW_JSON_EDITOR: 'View JSON Editor'
    },
    Groups: {
        CREATE_GROUP: 'Admin Groups',
        DELETE_GROUP: 'Admin Groups',
        EDIT_GROUP: 'Admin Groups',
        VIEW_GROUP: 'Admin Groups'
    },
    Invoices: {
        CREATE_INVOICES: 'Create Invoices',
        VIEW_INVOICE_DETAILS: 'View Invoice Details'
    },
    Items: {
        CREATE_ITEMS: 'Create Case Items',
        DELETE_ITEMS: 'Delete Items',
        EDIT_ITEMS: 'Edit Items',
        VIEW_ITEMS: 'View Items',
        REORDER_ITEMS: 'Reorder Case Items',
        SEARCH_ITEMS: 'Search Items'
    },
    ItemAssignments: {
        ADD_ITEM_ASSIGNMENTS: 'Add Assignments'
    },
    ItemAttachments: {
        ADD_ITEM_ATTACHMENTS: 'Add Item Attachments',
        DELETE_ITEM_ATTACHMENTS: 'Delete Item Attachments',
        VIEW_ITEM_ATTACHMENTS: 'View Item Attachments'
    },
    ItemExpenses: {
        ADD_EXPENSE: 'Add Expense',
        DELETE_EXPENSE: 'Delete Expense',
        EDIT_EXPENSES: 'Edit Expenses',
        VIEW_EXPENSES: 'View Expenses'
    },
    ItemTypes: {
        CREATE_ITEM_TYPES: 'Create Item Types',
        DELETE_ITEM_TYPES: 'Delete Item Types',
        EDIT_ITEM_TYPES: 'Edit Item Types',
        VIEW_ITEM_TYPES: 'View Item Types',
        ADD_SECTION_TO_ITEM_TYPE: 'Add Section to Item Type',
        REMOVE_SECTION_FROM_ITEM_TYPE: 'Remove Section from Item Type',
        REORDER_ITEM_TYPE_SECTIONS: 'Reorder Sections in Item Type'
    },
    ItemTemplateAsset: {
        VIEW_ITEM_TEMPLATES: 'Admin Item Templates',
        VIEW_ITEM_ASSETS: 'View Item Assets'
    },
    Orgs: {
        CHANGE_PARENT_ORGANIZATION: 'Change Parent Organization',
        CREATE_ORGANIZATION: 'Create Organization',
        DELETE_ORGANIZATION: 'Delete Organization',
        EDIT_ORGANIZATION: 'Edit Organization',
        VIEW_ORGANIZATIONS: 'View Organizations',
        VIEW_ORGS_AS_JSON: 'Admin Orgs',

        GET_ORG_CASE_TYPES: 'Admin Organization Case Types',
        ADD_CASE_TYPE_TO_ORG: 'Admin Organization Case Types',
        UPDATE_ORG_CASE_TYPE: 'Admin Organization Case Types',
        REMOVE_CASE_TYPE_FROM_ORG: 'Admin Organization Case Types',
        ADD_FEE_TO_ORG_CASE_TYPE: 'Admin Organization Case Types',
        UPDATE_FEE_ON_ORG_CASE_TYPE: 'Admin Organization Case Types',
        DELETE_FEE_FROM_ORG_CASE_TYPE: 'Admin Organization Case Types',

        GET_ORG_USERS: 'Admin Organization Users',
        UPDATE_USER_ORG_SETTINGS: 'Admin Organization Users',

        GET_ORG_DEADLINES: 'Admin Organization Deadlines',
        ADD_ORG_DEADLINE: 'Admin Organization Deadlines',
        UPDATE_ORG_DEADLINE: 'Admin Organization Deadlines',
        DELETE_ORG_DEADLINE: 'Admin Organization Deadlines',

        GET_ORG_EXPENSE_RULES: 'Admin Organization Expense Rules',
        ADD_ORG_EXPENSE_RULE: 'Admin Organization Expense Rules',
        UPDATE_ORG_EXPENSE_RULE: 'Admin Organization Expense Rules',
        DELETE_ORG_EXPENSE_RULE: 'Admin Organization Expense Rules',

        GET_ORG_INVOICE_SETTINGS: 'View Organizations',
        UPDATE_ORG_INVOICE_SETTINGS: 'Edit Organization'
    },
    OrgDeadlines: {
        CREATE_ORGANIZATION_DEADLINE: 'Create Organization Deadline',
        DELETE_ORGANIZATION_DEADLINE: 'Delete Organization Deadline',
        EDIT_ORGANIZATION_DEADLINE: 'Edit Organization Deadline',
        VIEW_ORGANIZATION_DEADLINES: 'View Organization Deadlines'
    },
    Reports: {
        CREATE_REPORT: 'View Admin Report',
        DELETE_REPORT: 'View Admin Report',
        EDIT_REPORT: 'View Admin Report',
        VIEW_REPORT: 'View Admin Report'
    },
    ReportData: {
        VIEW_REPORT: 'View Report'
    },
    Roles: {
        CREATE_ROLE: 'Admin Roles',
        DELETE_ROLE: 'Admin Roles',
        EDIT_ROLE: 'Admin Roles',
        VIEW_ROLE: 'Admin Roles'
    },
    Sections: {
        ADMIN_SECTION_TEMPLATES: 'Admin Section Templates',
        CREATE_SECTION_TEMPLATES: 'Create Section Templates',
        EDIT_SECTION_TEMPLATES: 'Edit Section Templates',
        VIEW_SECTION_TEMPLATES: 'View Section Templates',
        DELETE_SECTION_TEMPLATES: 'Delete Section Templates'
    },
    Types: {
        CREATE_TYPE: 'Create Type',
        DELETE_TYPE: 'Delete Type',
        EDIT_TYPE: 'Edit Type',
        VIEW_TYPE: 'View Type'
    },
    Users: {
        CREATE_USER: 'Admin Users',
        DELETE_USERS: 'Admin Users',
        EDIT_USERS: 'Admin Users',
        VIEW_USERS: 'Admin Users'
    },
    Admin: {
        ADMIN_SECURITY: 'Admin Security'
    },
    CaseEngine: {
        ENGINE_XSL: 'Engine XSL'
    },
    CaseColumns: {
        ORG: 'Org',
        COMPLETION_DATE: 'Completion Date',
        RECEIVED_DATE: 'Received Date',
        REVIEWER: 'Reviewer'
    },
    StockPhrases: {
        VIEW_STOCK_PHRASES: 'View Stock Phrases',
        ADD_STOCK_PHRASES: 'Add Stock Phrase',
        DELETE_STOCK_PHRASES: 'Delete Stock Phrase'
    }
};

export const tags = {
    CASE_ADMIN: 'Case Admin',
    CLIENT: 'Client',
    DATA_ENTRY: 'Data Entry',
    INVESTIGATOR: 'Investigator',
    MANAGER: 'Manager',
    REVIEWER: 'Reviewer',
    SYS_ADMIN: 'System Administrator'
};

export const WorkflowState = {
    CHECKING_DUPLICATION: 'CHECKING_DUPLICATION',
    LINKING_CASE: 'LINKING_CASE',
    AWAITING_CASE_PAPERS: 'AWAITING_CASE_PAPERS',
    SETTING_DEADLINES: 'SETTING_DEADLINES',
    AWAITING_DATA_ENTRY: 'AWAITING_DATA_ENTRY',
    AWAITING_ASSIGNMENT: 'AWAITING_ASSIGNMENT',
    IN_PROCESS: 'IN_PROCESS',
    IN_REVIEW: 'IN_REVIEW',
    COMPLETE: 'COMPLETE',
    ON_HOLD: 'ON_HOLD',
    DISCONTINUED: 'DISCONTINUED',
    DATA_ENTRY_IN_PROCESS: 'DATA_ENTRY_IN_PROCESS'
};

export const GoalStatusType = {
    AWAITING_ASSIGNMENT: 'AWAITING_ASSIGNMENT',
    ASSIGNED: 'ASSIGNED',
    IN_REVIEW: 'IN_REVIEW',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED'
};

export const USER_PROFILE_PAGE_SIZE = 5;

const GENERIC_ATTRIBUTES = {
    '@name': '',
    '@label': '',
    '@instructions': '',
    '@required': false,
    '@showInReport': true
};
export const SECTION_EDITOR_TYPES = [
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'text'
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'textarea',
        '@allowStockPhrases': false
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'address',
        '@line1': '',
        '@line2': '',
        '@city': '',
        '@region': '',
        '@postalCode': ''
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'phoneNumber'
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'date',
        '@disableDatesBefore': undefined,
        '@disableDatesBeforeToday': false,
        '@disableDatesAfter': undefined,
        '@disableDatesAfterToday': false,
        '@allowCurrent': false
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'checkbox'
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'list',
        '@listType': '',
        '@allowMultiple': false
    },
    {
        ...GENERIC_ATTRIBUTES,
        '@type': 'template',
        Template: { fields: { field: [] } },
        Entries: []
    }
].sort((l, r) => l['@type'] > r['@type']);
