import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import JsonEditor from '../../common/JsonEditor';
import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/xml';
import 'brace/theme/xcode';

import { FormConsumer } from '../../common/form/Form';
import { Label, HandleUnmount, ValidationError } from '../../common/form';
import SBFieldAdd from './SectionBuilder/SBFieldAdd';
import SBFieldConfigurer from './SectionBuilder/SBFieldConfigurer';

class SectionBuilder extends Component {
    state = {};
    addField = field => {
        const { values, setValue, name, parentField } = this.props;
        const originalField = parentField ? values[name].fields.field.find(f => f['@id'] === parentField['@id']) : undefined;

        setValue(name, {
            fields: {
                field: parentField
                    ? values[name].fields.field.map(f =>
                          f['@id'] !== parentField['@id']
                              ? f
                              : {
                                    ...originalField,
                                    Template: {
                                        fields: {
                                            field: [...originalField.Template.fields.field, field]
                                        }
                                    }
                                }
                      )
                    : [...values[name].fields.field, field]
            }
        });
    };

    deleteField = field => {
        const { values, setValue, name, parentField } = this.props;
        const originalField = parentField ? values[name].fields.field.find(f => f['@id'] === parentField['@id']) : undefined;

        setValue(name, {
            fields: {
                field: parentField
                    ? values[name].fields.field.map(f =>
                          f['@id'] !== parentField['@id']
                              ? f
                              : {
                                    ...originalField,
                                    Template: {
                                        fields: {
                                            field: originalField.Template.fields.field.filter(f => f['@id'] !== field['@id'])
                                        }
                                    }
                                }
                      )
                    : values[name].fields.field.filter(f => f['@id'] !== field['@id'])
            }
        });
    };

    changeConfiguration = (field, fieldName, value, cb = () => {}) => {
        const { values, setValue, name, parentField } = this.props;
        const originalField = parentField ? values[name].fields.field.find(f => f['@id'] === parentField['@id']) : undefined;

        setValue(
            name,
            {
                fields: {
                    field: parentField
                        ? values[name].fields.field.map(f =>
                              f['@id'] !== parentField['@id']
                                  ? f
                                  : {
                                        ...originalField,
                                        Template: {
                                            fields: {
                                                field: originalField.Template.fields.field.map(f =>
                                                    f['@id'] !== field['@id']
                                                        ? f
                                                        : {
                                                              ...f,
                                                              [fieldName]: value
                                                          }
                                                )
                                            }
                                        }
                                    }
                          )
                        : values[name].fields.field.map(f =>
                              f['@id'] !== field['@id']
                                  ? f
                                  : {
                                        ...f,
                                        [fieldName]: value
                                    }
                          )
                }
            },
            cb
        );
    };

    JSONUpdated = result => {
        const { setValue, name } = this.props;

        setValue(name, {
            fields: {
                field: result
            }
        });
    };

    XMLUpdated = result => {
        const { setValue } = this.props;
        setValue('ROITemplate', result);
    };

    render() {
        const { label, existingFieldIds, required, values, errors, disabledTypes, name, parentField, showJSON, editing, showXsltEditor } = this.props;

        const fields =
            parentField === undefined
                ? values[name].fields.field
                : values[name].fields.field.find(f => f['@id'] === parentField['@id']).Template.fields.field;

        return (
            <div className="mt-4">
                <Label>
                    {label}
                    {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                </Label>
                <div className="w-full">
                    <SBFieldAdd onAdd={this.addField} disabledTypes={disabledTypes} disableShortcuts={!!parentField} />
                    {!showJSON && !showXsltEditor ? (
                        fields.length ? (
                            <div className="bg-grey-lighter rounded py-4 mt-4 shadow-inner">
                                <Scrollbars autoHeight autoHeightMax="50vh">
                                    <div className="px-8">
                                        {fields.map((field, index) => {
                                            const lookupId = parentField ? parentField['@id'] : field['@id'];
                                            const newField = !(
                                                existingFieldIds.general.includes(field['@id']) ||
                                                (existingFieldIds.templates[lookupId] && existingFieldIds.templates[lookupId].includes(field['@id']))
                                            );

                                            return (
                                                <div
                                                    key={field['@id']}
                                                    className={
                                                        index === fields.length - 1 ? 'bg-white shadow rounded m-1' : 'bg-white shadow rounded mb-4'
                                                    }
                                                >
                                                    <SBFieldConfigurer
                                                        existingFieldIds={existingFieldIds || []}
                                                        errors={errors[field['@id']] || {}}
                                                        field={field}
                                                        onDelete={this.deleteField}
                                                        editing={editing}
                                                        newField={newField}
                                                        onConfigurationChanged={this.changeConfiguration}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Scrollbars>
                            </div>
                        ) : (
                            <></>
                        )
                    ) : showJSON ? (
                        <JsonEditor
                            className="w-full mt-4 max-h-screen-50 overflow-auto"
                            options={{
                                modes: ['tree', 'view', 'code']
                            }}
                            initialValue={fields}
                            onChange={this.JSONUpdated}
                        />
                    ) : (
                        <AceEditor
                            editorProps={{
                                $blockScrolling: Infinity
                            }}
                            width="100%"
                            mode="xml"
                            theme="xcode"
                            onChange={this.XMLUpdated}
                            fontSize={12}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={values['ROITemplate'] || ''}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                showLineNumbers: true,
                                tabSize: 4
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default props => (
    <FormConsumer>
        {({ errors = {}, values, setValue, removeValue, name }) => (
            <HandleUnmount handleUnmount={() => removeValue(name)}>
                <SectionBuilder {...props} values={values} setValue={setValue} />
                <ValidationError>{errors[name]}</ValidationError>
            </HandleUnmount>
        )}
    </FormConsumer>
);
