import React, { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';

import { roles } from '../../../constants';
import toolTips from '../../../tooltips';
import Guard from '../../common/Guard';
import { Divider, Label, Header, Section } from '../../common/form';
import Button from '../../common/Button';
import ControlledInput from '../../common/ControlledInput';
import selectorStyles from './styles';
import CustomCheckbox from '../../common/CustomCheckbox';
import { optional } from '../../../utils';

class GroupEditor extends Component {
    state = {
        Name: '',
        Group: '',
        Description: '',
        _original: {
            Name: '',
            Group: '',
            Description: ''
        },
        Tags: []
    };

    static getDerivedStateFromProps({ currentGroup, updatingGroup }, prevState) {
        if (!prevState._original.Name && currentGroup) {
            const { Name, Description } = currentGroup;
            const Tags = _.merge(prevState.Tags || [], currentGroup.Tags || []);
            if (Name === undefined || updatingGroup) return null;

            const newState = {
                Name,
                Description: Description || '',
                Tags
            };

            return {
                ...newState,
                _original: newState
            };
        }
    }

    handleInputChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    updateGroupTags = tags => {
        this.setState({
            Tags: tags
        });
    };

    handleCheckboxClick = (group, role, event) => {
        this.props[event.target.checked ? 'addRoleToGroup' : 'removeRoleFromGroup'](group, role);
    };

    submitUpdateForm = event => {
        event.preventDefault();
        const { Name, Description, Tags } = this.state;
        const Id = this.props.currentGroup && this.props.currentGroup.Id;
        const group = { Name, Description };

        if (Id) {
            group.Id = Id;
        }

        if (!this.props.updatingGroup) {
            this.props.updateGroup(group, Tags);
        }
    };

    render() {
        const { currentGroup, updatingGroup, getRoleWithName, tags = [], categories = {} } = this.props;
        const { Name, Description, Tags } = this.state;

        const sysAdmin = getRoleWithName(roles.General.SYSTEM_ADMINISTRATOR);
        const hasAdmin = optional(currentGroup, g => g.Roles.includes(sysAdmin.Id), false);
        const errors = this.props.errors ? this.props.errors.ModelState : {};

        return (
            <form className="flex flex-col my-4" onSubmit={this.submitUpdateForm}>
                <div className="flex flex-col sm:flex-row">
                    <div className="mb-4 flex-grow">
                        <ControlledInput label="Name" name="Name" errors={errors} type="text" onChange={this.handleInputChange} value={Name || ''} />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="mb-4 flex-grow">
                        <Label>Tags</Label>
                        <Select
                            isMulti
                            styles={selectorStyles}
                            defaultValue={Tags}
                            getOptionLabel={option => option.Name}
                            getOptionValue={option => option.Id}
                            options={tags.sort((a, b) => {
                                if (a.Name.toUpperCase() > b.Name.toUpperCase()) return 1;
                                if (a.Name.toUpperCase() < b.Name.toUpperCase()) return -1;
                                return 0;
                            })}
                            onChange={this.updateGroupTags}
                        />
                    </div>
                </div>
                <div className="mb-4 flex-grow">
                    <ControlledInput
                        label="Description"
                        name="Description"
                        errors={errors}
                        type="text"
                        onChange={this.handleInputChange}
                        value={Description}
                    />
                </div>
                {currentGroup ? (
                    <Section>
                        <Header>Roles</Header>
                        <Divider />
                        <div className="overflow-x-auto h-128 my-4">
                            <Scrollbars autoHeight autoHeightMin="32rem">
                                <div className="bg-table-search rounded p-4 mx-4">
                                    {Object.keys(categories).map(key => (
                                        <div
                                            className={`bg-white rounded shadow ${hasAdmin && key !== 'General' ? 'hidden' : ''}`}
                                            key={`category-${key}`}
                                        >
                                            <h2 className="text-primary font-bold text-sm tracking-wide font-small py-3 px-4">
                                                {key.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')}
                                            </h2>
                                            <div className="mb-8 overflow-x-auto flex flex-wrap">
                                                {categories[key].map(role => {
                                                    const roleObj = getRoleWithName(role);
                                                    const hasRole = roleObj && currentGroup.Roles.includes(roleObj.Id);
                                                    return (
                                                        (roleObj && (
                                                            <div key={`${key}-label-${role}`} className="w-1/3 mb-5">
                                                                <div className="text-center text-primary py-2 whitespace-no-wrap cursor-pointer">
                                                                    {role}
                                                                </div>
                                                                <div className="px-2 text-center">
                                                                    <em className="text-xs">{toolTips[role]}</em>
                                                                </div>
                                                                <div className="text-center pb-4" key={`${key}-${currentGroup.Id}-${role}`}>
                                                                    <Guard
                                                                        permissions={[roles.Groups.EDIT_GROUP]}
                                                                        notFound={() => (
                                                                            <i
                                                                                className={`fas ${
                                                                                    hasRole ? 'fa-check text-green' : 'fa-times text-grey-lighter'
                                                                                    }`}
                                                                            />
                                                                        )}
                                                                    >
                                                                        <CustomCheckbox
                                                                            onChange={this.handleCheckboxClick.bind(this, currentGroup, roleObj)}
                                                                            name={`${currentGroup.Id}---${roleObj && roleObj.Id}`}
                                                                            checked={hasRole}
                                                                        />
                                                                    </Guard>
                                                                </div>
                                                            </div>
                                                        )) || <span key={`${key}-label-${role}`} />
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Scrollbars>
                        </div>
                    </Section>
                ) : (
                        <> </>
                    )}
                <div className="flex justify-end items-center">
                    <Button type="submit" loading={updatingGroup}>
                        {currentGroup !== '' ? 'Save Changes' : 'Create Group'}
                    </Button>
                </div>
            </form>
        );
    }
}

export default GroupEditor;
