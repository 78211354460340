import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import numeral from 'numeral';

import { baseUrl } from '../../../../../config';
import { makeCancelable } from '../../../../../utils';
import { Form } from '../../../../common/form/Form';
import Button from '../../../../common/Button';
import { EnumTypeSelector, DatePicker, Checkbox, Currency } from '../../../../common/form';

const DEFAULT_CREATE_VALUES = {
    CPU: 0,
    IsPassthrough: false,
    ActiveFrom: null,
    ActiveTo: null,
    ExpenseType_Id: 0
};

class CreateOrgExpenseRule extends Component {
    state = {
        creatingExpenseRule: false,
        errors: {}
    };

    componentWillUnmount() {
        if (this.expenseRuleCreatePromise) this.expenseRuleCreatePromise.cancel();
    }

    createExpenseRule = values => {
        const { access_token, orgId } = this.props;

        const createObject = {
            CPU: numeral(values['CPU']).value(),
            IsPassthrough: !!values['IsPassthrough'],
            ActiveFrom: values['ActiveFrom'],
            ActiveTo: values['ActiveTo'],
            ExpenseType_Id: +values['ExpenseType_Id']
        };

        this.expenseRuleCreatePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        creatingExpenseRule: true
                    },
                    async () => {
                        try {
                            createObject.Org_Id = orgId;
                            const result = await this.props.createOrgExpenseRule(createObject);

                            toast.success(`The expense rule was created successfully.`);

                            this.setState(
                                {
                                    ...this.state,
                                    errors: {},
                                    creatingExpenseRule: false
                                },
                                () => {
                                    this.props.expenseRuleCreated(result.data);
                                }
                            );
                        } catch (e) {
                            toast.error(`There was an error creating the expense rule.`);
                            this.setState({
                                ...this.state,
                                creatingExpenseRule: false,
                                errors: e.response.data.ModelState || {}
                            });
                        }
                    }
                );
            })
        );
    };

    render() {
        const { closeModal } = this.props;
        const { errors, creatingExpenseRule } = this.state;

        return (
            <Form defaultValues={{ ...DEFAULT_CREATE_VALUES }} errors={errors} onSubmit={this.createExpenseRule}>
                <>
                    <div className="flex flex-wrap -mx-2">
                        <div className="w-1/2 px-2">
                            <EnumTypeSelector type="ExpenseType" name="ExpenseType_Id" label="Expense Type" required showShort={true} />
                        </div>
                        <div className="w-1/2 px-2">
                            <Currency type="text" name="CPU" label="CPU" required />
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-2">
                        <div className="w-1/2 px-2 DayPickerModal">
                            <DatePicker name="ActiveFrom" label="Active From" type="date" showLabel showError />
                        </div>
                        <div className="w-1/2 px-2 DayPickerModal">
                            <DatePicker name="ActiveTo" label="Active To" type="date" showLabel showError />
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-4 -mx-2">
                        <div className="w-1/2 px-2">
                            <Checkbox type="checkbox" name="IsPassthrough" label="Passthrough?" />
                        </div>
                    </div>
                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!creatingExpenseRule ? (
                            <Button type="submit">Add Expense Rule</Button>
                        ) : (
                            <Button disabled>
                                Adding Expense Rule... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                    </div>
                </>
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});
const mapDispatchToProps = dispatch => ({
    createOrgExpenseRule: dispatch.orgExpenseRule.createOrgExpenseRule
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrgExpenseRule);
