import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { accountOperations, accountSelectors } from './account/duck';

import { casesDesktopNavigation, casesMobileNavigation } from './cases/navigation';
import { itemsDesktopNavigation, itemsMobileNavigation } from './items/navigation';
import { invoicesDesktopNavigation, invoicesMobileNavigation } from './invoices/navigation';
import { reportsDesktopNavigation, reportsMobileNavigation } from './reports/navigation';
import { adminDesktopNavigation, adminMobileNavigation } from './admin/navigation';

export const desktopNav = [
    casesDesktopNavigation,
    itemsDesktopNavigation,
    invoicesDesktopNavigation,
    reportsDesktopNavigation,
    adminDesktopNavigation,
    reportsMobileNavigation,
    adminMobileNavigation
];

export const mobileNav = [casesMobileNavigation, itemsMobileNavigation, invoicesMobileNavigation, reportsMobileNavigation, adminMobileNavigation];

class MobileNavigationC extends Component {
    componentDidMount() {
        window.addEventListener('resize', this.processResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.processResize);
    }

    processResize = () => {
        if (window.innerWidth >= 768) this.props.toggleMenu(false);
    };

    render() {
        const { isOpen, toggleMenu, userName } = this.props;

        return (
            <div className="block md:hidden">
                <i className={`text-white cursor-pointer text-xl fas ${isOpen ? 'fa-times' : 'fa-bars'}`} onClick={() => toggleMenu()} />
                {isOpen ? (
                    <div className="absolute bg-white shadow pin-l pin-r md:hidden" style={{ top: '55px', paddingLeft: '5rem' }}>
                        <div className="group w-full relative bg-white text-center text-primary">
                            <div className="py-4 font-bold">{userName}</div>
                        </div>
                        {mobileNav}
                        <div className="group w-full relative bg-white">
                            <NavLink
                                activeClassName="border-primary border-l-4 opacity-100"
                                className="flex hover:bg-grey-lightest border-l-4 justify-between no-underline text-primary block cursor-pointer py-4 px-8"
                                to="/account"
                                onClick={e => toggleMenu()}
                            >
                                <span>Manage Account</span>
                            </NavLink>
                        </div>
                        <button
                            className="appearance-none flex w-full hover:bg-grey-lightest border-l-4 justify-between no-underline text-primary block cursor-pointer py-4 px-8"
                            type="button"
                            onClick={this.props.logOut}
                        >
                            Log Out
                        </button>
                    </div>
                ) : (
                        <></>
                    )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.navigation.menuOpen,
    userName: accountSelectors.userName(state.account)
});

const mapDispatchToProps = dispatch => ({
    toggleMenu: dispatch.navigation.toggleMenu,
    logOut: () => dispatch(accountOperations.logOut())
});

export const MobileNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileNavigationC);
