import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const sectionsPersistConfig = {
    key: 'sections',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    sections: [],
    _errors: null,
    _updating: {
        _gettingSections: false,
        _creatingSection: false,
        _sections: {}
    }
};

const sections = createReducer(initialState, {
    [types.GET_SECTIONS_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _gettingSections: true }
    }),
    [types.GET_SECTIONS_SUCCESS]: (state, { payload: sections }) => ({
        ...state,
        sections: sections.map(s => ({
            ...s,
            Template: JSON.parse(s.Template)
        })),
        _errors: null,
        _updating: { ...state._updating, _gettingSections: false }
    }),
    [types.GET_SECTIONS_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _gettingSections: false }
    }),

    [types.CREATE_SECTION_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _creatingSection: true }
    }),
    [types.CREATE_SECTION_SUCCESS]: (state, { payload: section }) => ({
        ...state,
        sections: [
            ...state.sections,
            {
                ...section,
                Template: JSON.parse(section.Template),
                ROITemplate: section.ROITemplate
            }
        ],
        _errors: null,
        _updating: { ...state._updating, _creatingSection: false }
    }),
    [types.CREATE_SECTION_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingSection: false }
    }),
    [types.UPDATE_XSLT_SUCCESS]: (state, { payload: section }) => {
        let index = state.sections.findIndex(s => s.Id == section.Id);

        state.sections[index] = {
            ...state.sections[index],
            ROITemplate: section.ROITemplate
        };

        return {
            ...state,
            sections: [...state.sections],
            _updating: { ...state._updating, _creatingSection: false }
        };
    },
    [types.DELETE_SECTION_REQUEST]: (state, { payload: sectionId }) => ({
        ...state,
        _errors: null,
        _updating: {
            ...state._updating,
            _sections: {
                ...state._updating._sections,
                [sectionId]: true
            }
        }
    }),
    [types.DELETE_SECTION_SUCCESS]: (state, { payload: sectionId }) => ({
        ...state,
        sections: state.sections.map(section =>
            section.Id !== sectionId
                ? section
                : {
                      ...section,
                      Enabled: !section.Enabled
                  }
        ),
        _errors: null,
        _updating: {
            ...state._updating,
            _sections: {
                ...state._updating._sections,
                [sectionId]: false
            }
        }
    }),
    [types.DELETE_SECTION_FAILURE]: (state, { payload: { sectionId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: {
            ...state._updating,
            _sections: {
                ...state._updating._sections,
                [sectionId]: false
            }
        }
    })
});

export default sections;
