import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';

import Guard from '../common/Guard';
import { roles } from '../../constants';

import Button from '../common/Button';

import SortableOrgTree from './components/SortableOrgTree';
import OrgDetails from './OrgDetails';
import Modal from '../common/Modal';
import CreateOrgForm from './components/CreateOrgForm';
import PageHeader from '../common/PageHeader';

class Orgs extends Component {
    state = {
        showCreateModal: false
    };

    openCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    getOrgId = () => {
        const {
            location: { pathname }
        } = this.props;
        const splitPathname = pathname.split('/');

        if (splitPathname.length < 3) return null;
        return splitPathname[2];
    };

    render() {
        const { showCreateModal } = this.state;
        const minResizableWidth = Math.max(window.innerWidth / 5, 300);

        return (
            <>
                <div className="flex mx-8">
                    <div className="mr-4">
                        <div className="flex justify-between items-center">
                            <PageHeader>Orgs</PageHeader>
                            <Guard permissions={[roles.Orgs.CREATE_ORGANIZATION]}>
                                <Button inverse icon="far fa-building" onClick={this.openCreateModal}>
                                    Add Org
                                </Button>
                            </Guard>
                        </div>
                        <div className="rounded bg-white shadow my-4 pt-2 pb-2 px-2">
                            <ResizableBox
                                width={minResizableWidth}
                                height={400}
                                minConstraints={[minResizableWidth, 400]}
                                maxConstraints={[Infinity, Infinity]}
                            >
                                <SortableOrgTree currentOrgId={this.getOrgId()} />
                            </ResizableBox>
                        </div>
                        <Guard permissions={[roles.Orgs.CHANGE_PARENT_ORGANIZATION]}>
                            <p className="text-grey-darker font-sm italic px-4">Drag and drop Orgs to change parenting.</p>
                        </Guard>
                    </div>
                    <div className="w-4/5">
                        <Route
                            exact
                            path="/orgs/:orgId"
                            render={({ match }) => {
                                return <Redirect to={`/orgs/${match.params.orgId}/general`} />;
                            }}
                        />
                        <Route
                            exact
                            path="/orgs/:orgId/:section"
                            render={({ match }) => {
                                return <OrgDetails match={match} />;
                            }}
                        />
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeCreateModal} className="max-h-screen-90">
                    <div className="p-4">{showCreateModal ? <CreateOrgForm closeModal={this.closeCreateModal} /> : <></>}</div>
                </Modal>
            </>
        );
    }
}

export default Orgs;
