import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormConsumer } from './Form';
import { Label, ValidationError } from './index';
import OrgSelector from '../OrgSelector';

class OrgSelect extends Component {
    state = {
        orgName: '',
        showOrgSelector: false,
        error: false
    };
    componentDidUpdate(prevProps) {
        if (prevProps.user && prevProps.user.Org && prevProps.user.Org.Id !== this.props.user.Org.Id) this.input.blur();
    }

    showOrgSelector = () => {
        if (!this.props.disabled) {
            this.setState({
                ...this.state,
                showOrgSelector: true
            });
        }
    };

    closeOrgSelector = () => {
        this.setState({
            ...this.state,
            showOrgSelector: false
        });
    };

    render = () => {
        let { name, label, disabled, required = true, orgs = [], onChange, showError, ...rest } = this.props;
        let { showOrgSelector } = this.state;
        return (
            <FormConsumer>
                {({ values, setValue, errors }) => {
                    const currentOrg = orgs.find(org => org && org.Id === values[name]);
                    return (
                        <>
                            <div className="mb-4 flex-grow">
                                <Label>
                                    {label}
                                    {required ? <strong className="text-red">&nbsp;*</strong> : <></>}
                                </Label>
                                <div className="relative" onClick={this.showOrgSelector}>
                                    <input
                                        ref={input => (this.input = input)}
                                        name={name}
                                        value={currentOrg ? currentOrg.Name : ''}
                                        placeholder={label}
                                        onBlur={e => {
                                            e.preventDefault();
                                            this.setState({ error: !e.target.checkValidity() });
                                        }}
                                        onChange={() => {}}
                                        onFocus={this.showOrgSelector}
                                        disabled={disabled}
                                        className={`input-field ${this.state.error ? 'border-red-light' : ''}`}
                                        autoComplete="nope"
                                    />
                                    <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                                {showError ? <ValidationError>{errors ? errors[name] : []}</ValidationError> : <></>}
                            </div>
                            {showOrgSelector ? (
                                <OrgSelector
                                    close={this.closeOrgSelector}
                                    orgSelected={org => {
                                        setValue(name, org ? org.Id : '', () => {
                                            onChange && onChange(org || {});
                                        });
                                        this.setState({ orgName: org.Name, showOrgSelector: false });
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    );
                }}
            </FormConsumer>
        );
    };
}

const mapStateToProps = state => ({
    orgs: state.orgs.orgs
});

export default connect(mapStateToProps)(OrgSelect);
