import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import { Label } from '../common/form';
import Button from '../common/Button';
import { accountSelectors, accountOperations } from './duck';
import { disclaimer, version } from '../../config';

class Login extends Component {
    state = {
        username: '',
        password: ''
    };

    handleInputChange = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    submitLoginForm = async event => {
        event.preventDefault();

        if (!this.props.loggingIn) {
            try {
                await this.props.logIn(this.state.username, this.state.password);
                localStorage.AutoLogoutTime = moment()
                    .add(this.props.AutoLogoutTime, 'minutes')
                    .unix();
            } catch (error) {
                if (error.response) {
                    this.setState({
                        errors: error.response.data
                    });
                }
            }
        }
    };

    render() {
        const { loggedIn, loggingIn, errors } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };

        if (loggedIn && !loggingIn) return <Redirect to={'/'} />;

        if (this.state.errors && this.state.errors.error === 'password_expired') {
            return <Redirect to="/change-password" />;
        }

        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={this.submitLoginForm}>
                        <div className="mb-4">
                            <Label>Username</Label>
                            <input className="input-field" name="username" type="text" placeholder="" onChange={this.handleInputChange} />
                        </div>
                        <div className="mb-4">
                            <Label>Password</Label>
                            <input className="input-field" name="password" type="password" placeholder="" onChange={this.handleInputChange} />
                        </div>
                        {errors === null ? null : (
                            <p className="mb-4 text-red">
                                &bull;&nbsp;
                                {errors.error_description}
                            </p>
                        )}
                        <div className="flex items-center justify-between">
                            {!loggingIn ? (
                                <Button type="submit">Log In</Button>
                            ) : (
                                <Button disabled>
                                    Logging in... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                            <div className="flex justify-between">
                                <Link
                                    to="/forgot-username"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Forgot Username?
                                </Link>
                                <Link
                                    to="/forgot-password"
                                    className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>
                    </form>
                    <p className="text-center text-grey text-xs mb-2">{disclaimer}</p>
                    <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                    <p className="text-center text-grey text-xs">{version}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn: accountSelectors.loggedIn(state.account),
    loggingIn: state.account._updating._loggingIn,
    errors: state.account.errors,
    AutoLogoutTime: state.security.settings.AutoLogoutTime || 30
});

const mapDispatchToProps = dispatch => ({
    logIn: (email, password) => dispatch(accountOperations.logIn(email, password))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
