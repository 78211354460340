import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const itemTypesPersistConfig = {
    key: 'itemTypes',
    storage: storage,
    blacklist: ['_errors', '_updating']
};

const initialState = {
    itemTypes: [],
    _errors: null,
    _updating: {
        _gettingItemTypes: false,
        _creatingItemType: false,
        _swappingSections: false,
        _itemTypes: {}
    }
};

const itemTypes = createReducer(initialState, {
    [types.GET_ITEM_TYPES_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _gettingItemTypes: true }
    }),
    [types.GET_ITEM_TYPES_SUCCESS]: (state, { payload: itemTypes }) => ({
        ...state,
        itemTypes,
        _errors: null,
        _updating: { ...state._updating, _gettingItemTypes: false }
    }),
    [types.GET_ITEM_TYPES_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _gettingItemTypes: false }
    }),

    [types.CREATE_ITEM_TYPE_REQUEST]: state => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _creatingItemType: true }
    }),
    [types.CREATE_ITEM_TYPE_SUCCESS]: (state, { payload: itemType }) => ({
        ...state,
        itemTypes: [
            ...state.itemTypes,
            itemType
        ],
        _errors: null,
        _updating: { ...state._updating, _creatingItemType: false }
    }),
    [types.CREATE_ITEM_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _creatingItemType: false }
    }),

    [types.UPDATE_ITEM_TYPE_REQUEST]: (state, { payload: itemTypeId }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: true } }
    }),
    [types.UPDATE_ITEM_TYPE_SUCCESS]: (state, { payload: itemType }) => ({
        ...state,
        _errors: null,
        itemTypes: state.itemTypes.map(it => it.Id !== itemType.Id ? it : itemType),
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemType.Id]: false } }
    }),
    [types.UPDATE_ITEM_TYPE_FAILURE]: (state, { payload: { itemTypeId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: false } }
    }),



    [types.DELETE_ITEM_TYPE_REQUEST]: (state, { payload: itemTypeId }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: true } }
    }),
    [types.DELETE_ITEM_TYPE_SUCCESS]: (state, { payload: itemTypeId }) => ({
        ...state,
        _errors: null,
        itemTypes: state.itemTypes.map(itemType => itemType.Id !== itemTypeId ? itemType : {
            ...itemType,
            Hidden: !itemType.Hidden
        }),
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: false } }
    }),
    [types.DELETE_ITEM_TYPE_FAILURE]: (state, { payload: { itemTypeId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: false } }
    }),

    [types.ADD_SECTION_TO_ITEM_TYPE_REQUEST]: (state, { payload: itemTypeId }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: true } }
    }),
    [types.ADD_SECTION_TO_ITEM_TYPE_SUCCESS]: (state, { payload: itemType }) => ({
        ...state,
        _errors: null,
        itemTypes: state.itemTypes.map(it => it.Id !== itemType.Id ? it : itemType),
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemType.Id]: false } }
    }),
    [types.ADD_SECTION_TO_ITEM_TYPE_FAILURE]: (state, { payload: { itemTypeId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [itemTypeId]: false } }
    }),

    [types.UPDATE_SECTION_ON_ITEM_TYPE_REQUEST]: (state) => ({
        ...state,
        _errors: null
    }),
    [types.UPDATE_SECTION_ON_ITEM_TYPE_SUCCESS]: (state, { payload: itemType }) => ({
        ...state,
        _errors: null,
        itemTypes: state.itemTypes.map(it => it.Id !== itemType.Id ? it : itemType)
    }),
    [types.UPDATE_SECTION_ON_ITEM_TYPE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        _errors: errors
    }),

    [types.REMOVE_SECTION_FROM_ITEM_TYPE_REQUEST]: (state, { payload: { itemTypeId, sectionId } }) => ({
        ...state,
        _errors: null,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [`${itemTypeId}-${sectionId}`]: true } }
    }),
    [types.REMOVE_SECTION_FROM_ITEM_TYPE_SUCCESS]: (state, { payload: { itemType, sectionId } }) => ({
        ...state,
        _errors: null,
        itemTypes: state.itemTypes.map(it => it.Id !== itemType.Id ? it : itemType),
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [`${itemType.Id}-${sectionId}`]: false } }
    }),
    [types.REMOVE_SECTION_FROM_ITEM_TYPE_FAILURE]: (state, { payload: { itemTypeId, sectionId, errors } }) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _itemTypes: { ...state._updating._itemTypes, [`${itemTypeId}-${sectionId}`]: false } }
    }),

    [types.SWAP_ITEM_TYPE_SECTION_REQUEST]: state => ({
        ...state,
        _errors: {},
        _updating: { ...state._updating, _swappingSections: true }
    }),
    [types.SWAP_ITEM_TYPE_SECTION_SUCCESS]: (state, { payload: { itemTypeId, swapResult } }) => ({
        ...state,
        itemTypes: state.itemTypes.map(itemType => itemType.Id !== itemTypeId ? itemType : ({
            ...itemType,
            Sections: (itemType.Sections || []).map(
                section => swapResult[section.Id] !== undefined
                    ? {
                        ...section,
                        DisplayOrder: +swapResult[section.Id]
                    } :
                    section
            )
        })),
        _errors: {},
        _updating: { ...state._updating, _swappingSections: false }
    }),
    [types.SWAP_ITEM_TYPE_SECTION_FAILURE]: (state, errors) => ({
        ...state,
        _errors: errors,
        _updating: { ...state._updating, _swappingSections: false }
    })
});

export default itemTypes;