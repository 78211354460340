const GET_ITEM_TYPES_REQUEST = 'app/item-types/GET_ITEM_TYPES_REQUEST';
const GET_ITEM_TYPES_SUCCESS = 'app/item-types/GET_ITEM_TYPES_SUCCESS';
const GET_ITEM_TYPES_FAILURE = 'app/item-types/GET_ITEM_TYPES_FAILURE';

const CREATE_ITEM_TYPE_REQUEST = 'app/item-types/CREATE_ITEM_TYPE_REQUEST';
const CREATE_ITEM_TYPE_SUCCESS = 'app/item-types/CREATE_ITEM_TYPE_SUCCESS';
const CREATE_ITEM_TYPE_FAILURE = 'app/item-types/CREATE_ITEM_TYPE_FAILURE';

const UPDATE_ITEM_TYPE_REQUEST = 'app/item-types/UPDATE_ITEM_TYPE_REQUEST';
const UPDATE_ITEM_TYPE_SUCCESS = 'app/item-types/UPDATE_ITEM_TYPE_SUCCESS';
const UPDATE_ITEM_TYPE_FAILURE = 'app/item-types/UPDATE_ITEM_TYPE_FAILURE';

const DELETE_ITEM_TYPE_REQUEST = 'app/item-types/DELETE_ITEM_TYPE_REQUEST';
const DELETE_ITEM_TYPE_SUCCESS = 'app/item-types/DELETE_ITEM_TYPE_SUCCESS';
const DELETE_ITEM_TYPE_FAILURE = 'app/item-types/DELETE_ITEM_TYPE_FAILURE';

const ADD_SECTION_TO_ITEM_TYPE_REQUEST = 'app/item-types/ADD_SECTION_TO_ITEM_TYPE_REQUEST';
const ADD_SECTION_TO_ITEM_TYPE_SUCCESS = 'app/item-types/ADD_SECTION_TO_ITEM_TYPE_SUCCESS';
const ADD_SECTION_TO_ITEM_TYPE_FAILURE = 'app/item-types/ADD_SECTION_TO_ITEM_TYPE_FAILURE';

const UPDATE_SECTION_ON_ITEM_TYPE_REQUEST = 'app/item-types/UPDATE_SECTION_ON_ITEM_TYPE_REQUEST';
const UPDATE_SECTION_ON_ITEM_TYPE_SUCCESS = 'app/item-types/UPDATE_SECTION_ON_ITEM_TYPE_SUCCESS';
const UPDATE_SECTION_ON_ITEM_TYPE_FAILURE = 'app/item-types/UPDATE_SECTION_ON_ITEM_TYPE_FAILURE';

const REMOVE_SECTION_FROM_ITEM_TYPE_REQUEST = 'app/item-types/REMOVE_SECTION_FROM_ITEM_TYPE_REQUEST';
const REMOVE_SECTION_FROM_ITEM_TYPE_SUCCESS = 'app/item-types/REMOVE_SECTION_FROM_ITEM_TYPE_SUCCESS';
const REMOVE_SECTION_FROM_ITEM_TYPE_FAILURE = 'app/item-types/REMOVE_SECTION_FROM_ITEM_TYPE_FAILURE';

const SWAP_ITEM_TYPE_SECTION_REQUEST = 'app/item-types/SWAP_ITEM_TYPE_SECTION_REQUEST';
const SWAP_ITEM_TYPE_SECTION_SUCCESS = 'app/item-types/SWAP_ITEM_TYPE_SECTION_SUCCESS';
const SWAP_ITEM_TYPE_SECTION_FAILURE = 'app/item-types/SWAP_ITEM_TYPE_SECTION_FAILURE';

export default {
    GET_ITEM_TYPES_REQUEST,
    GET_ITEM_TYPES_SUCCESS,
    GET_ITEM_TYPES_FAILURE,
    CREATE_ITEM_TYPE_REQUEST,
    CREATE_ITEM_TYPE_SUCCESS,
    CREATE_ITEM_TYPE_FAILURE,
    UPDATE_ITEM_TYPE_REQUEST,
    UPDATE_ITEM_TYPE_SUCCESS,
    UPDATE_ITEM_TYPE_FAILURE,
    DELETE_ITEM_TYPE_REQUEST,
    DELETE_ITEM_TYPE_SUCCESS,
    DELETE_ITEM_TYPE_FAILURE,
    ADD_SECTION_TO_ITEM_TYPE_REQUEST,
    ADD_SECTION_TO_ITEM_TYPE_SUCCESS,
    ADD_SECTION_TO_ITEM_TYPE_FAILURE,
    UPDATE_SECTION_ON_ITEM_TYPE_REQUEST,
    UPDATE_SECTION_ON_ITEM_TYPE_SUCCESS,
    UPDATE_SECTION_ON_ITEM_TYPE_FAILURE,
    REMOVE_SECTION_FROM_ITEM_TYPE_REQUEST,
    REMOVE_SECTION_FROM_ITEM_TYPE_SUCCESS,
    REMOVE_SECTION_FROM_ITEM_TYPE_FAILURE,
    SWAP_ITEM_TYPE_SECTION_REQUEST,
    SWAP_ITEM_TYPE_SECTION_SUCCESS,
    SWAP_ITEM_TYPE_SECTION_FAILURE
};