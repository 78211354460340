import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import Guard from '../../../common/Guard';
import { baseUrl } from '../../../../config';
import { roles } from '../../../../constants';

import { makeCancelable } from '../../../../utils';
import Button from '../../../common/Button';
import OrgCaseTypesList from './OrgCaseTypes/OrgCaseTypesList';
import Modal from '../../../common/Modal';
import CreateOrgCaseType from './OrgCaseTypes/CreateOrgCaseType';

class OrgCaseTypes extends Component {
    state = {
        loadingCaseTypes: false,
        showCreateModal: false,
        caseTypes: [],
        filtered: [{ id: 'Inherited', value: 'ALL' }]
    };

    openCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    componentDidMount() {
        this.loadOrgCaseTypes();
    }

    componentWillUnmount() {
        if (this.orgCaseTypesPromise) this.orgCaseTypesPromise.cancel();
    }

    loadOrgCaseTypes = (refresh = false) => {
        const {
            access_token,
            match: {
                params: { orgId }
            }
        } = this.props;
        this.orgCaseTypesPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        loadingCaseTypes: true
                    },
                    async () => {
                        try {
                            const caseTypes = await axios.get(`${baseUrl}/api/orgs/${orgId}/case-types`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.setState({
                                ...this.state,
                                loadingCaseTypes: false,
                                caseTypes: caseTypes.data
                            });
                        } catch (e) {
                            toast.error(`There was an error ${refresh ? 'refreshing' : 'loading'} the case types for this org.`);
                            this.setState({
                                ...this.state,
                                caseTypes: refresh ? this.state.caseTypes : [],
                                loadingCaseTypes: false
                            });
                        }
                    }
                );
            })
        );
    };

    caseTypeDeleted = (caseTypeId = null) => {
        if (caseTypeId === null) {
            this.loadOrgCaseTypes(true);
        } else {
            this.setState({
                ...this.state,
                caseTypes: this.state.caseTypes.filter(caseType => caseType.Id !== caseTypeId)
            });
        }
    };

    caseTypeCreated = (caseType = null) => {
        if (caseType === null) {
            this.loadOrgCaseTypes(true);
        } else {
            this.setState({
                ...this.state,
                caseTypes: [
                    ...this.state.caseTypes,
                    caseType
                ]
            })
        }
        this.closeModal();
    };

    caseTypeOverrode = (caseType = null) => {
        if (caseType === null) {
            this.loadOrgCaseTypes(true);
            return;
        }

        this.setState({
            ...this.state,
            caseTypes: [
                ...this.state.caseTypes,
                caseType
            ]
        });
    };
    caseTypePriceUpdated = (orgCaseTypeId, newValues) => {
        this.setState({
            ...this.state,
            caseTypes: this.state.caseTypes.map(
                ct =>
                    ct.Id !== orgCaseTypeId
                        ? ct
                        : {
                            ...ct,
                            BaseCost: newValues.BasePrice,
                            ActiveFrom: newValues.ActiveFrom,
                            ActiveTo: newValues.ActiveTo
                        }
            )
        });
    };

    onFeeCreate = (newFee, caseTypeId, forDiscontinued) => {
        const feeArrayKey = forDiscontinued ? 'DiscontinuedCaseFees' : 'CompletedCaseFees';
        this.setState({
            ...this.state,
            caseTypes: this.state.caseTypes.map(
                ct =>
                    ct.Id !== caseTypeId
                        ? ct
                        : {
                            ...ct,
                            [feeArrayKey]: [...ct[feeArrayKey], newFee]
                        }
            )
        });
    };

    onFeeEdit = (newFee, caseTypeId, forDiscontinued) => {
        const feeArrayKey = forDiscontinued ? 'DiscontinuedCaseFees' : 'CompletedCaseFees';
        this.setState({
            ...this.state,
            caseTypes: this.state.caseTypes.map(
                ct =>
                    ct.Id !== caseTypeId
                        ? ct
                        : {
                            ...ct,
                            [feeArrayKey]: ct[feeArrayKey].map(fee => (fee.Id !== newFee.Id ? fee : newFee))
                        }
            )
        });
    };

    onFeeDelete = (newFee, caseTypeId, forDiscontinued) => {
        const feeArrayKey = forDiscontinued ? 'DiscontinuedCaseFees' : 'CompletedCaseFees';
        this.setState({
            ...this.state,
            caseTypes: this.state.caseTypes.map(
                ct =>
                    ct.Id !== caseTypeId
                        ? ct
                        : {
                            ...ct,
                            [feeArrayKey]: ct[feeArrayKey].filter(fee => fee.Id !== newFee.Id)
                        }
            )
        });
    };

    render() {
        const { loadingCaseTypes, caseTypes, showCreateModal } = this.state;
        const {
            match: {
                params: { orgId }
            }
        } = this.props;
        return (
            <>
                <div className="flex justify-end">
                    <Guard permissions={[roles.Orgs.ADD_CASE_TYPE_TO_ORG]}>
                        <Button inverse onClick={this.openCreateModal}>
                            Add Case Type
                        </Button>
                    </Guard>
                </div>
                {!loadingCaseTypes ? (
                    caseTypes.length > 0 ? (
                        <OrgCaseTypesList
                            caseTypes={caseTypes}
                            orgId={orgId}
                            onDelete={this.caseTypeDeleted}
                            onPriceUpdated={this.caseTypePriceUpdated}
                            onOverride={this.caseTypeOverrode}
                            onFeeCreate={this.onFeeCreate}
                            onFeeEdit={this.onFeeEdit}
                            onFeeDelete={this.onFeeDelete}
                            onCreated={this.caseTypeCreated}
                            filtered={this.state.filtered}
                            onFilteredChanged={(filtered) => this.setState({ ...this.state, filtered })}
                        />
                    ) : (
                            <div className="text-primary">There are no case types for this org.</div>
                        )
                ) : (
                        <span>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </span>
                    )}
                <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showCreateModal ? <CreateOrgCaseType orgId={orgId} closeModal={this.closeModal} onCreated={this.caseTypeCreated} /> : <></>}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

export default withRouter(connect(mapStateToProps)(OrgCaseTypes));
