import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { Label } from '../../../common/form';
import TypeSelector from '../../../common/TypeSelector';
import { optional } from '../../../../utils';

const ListSectionField = ({ field, onChange, Types, disabled }) => {
    const availableTypes = (Types[optional(field, '@listType', '')] && Types[field['@listType']].Types) || [];
    const typeNames = optional(field, '@allowMultiple', '') ? (optional(field, '#text', '') && optional(field, '#text', '').split(', ')) || [] : [];
    const Type = !optional(field, '@allowMultiple', '')
        ? availableTypes.find(t => t.Description === optional(field, '#text', ''))
        : availableTypes.filter(t => typeNames.includes(t.Description));
    const options = availableTypes
        .map(t => ({
            value: t.Id,
            label: t.Description
        }))
        .sort((a, b) => {
            if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
            if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
            return 0;
        });

    return (
        <>
            {!optional(field, '@allowMultiple', '') ? (
                <div className="mb-4">
                    <TypeSelector
                        customKey={`${field['@id']}-${optional(field, '@listType', '')}`}
                        label={optional(field, '@label', '')}
                        labelOptions={{
                            muted: !!!field['@showInReport']
                        }}
                        type={optional(field, '@listType', '')}
                        name="#text"
                        value={Type ? Type.Id : 0}
                        required={optional(field, '@required', '')}
                        onChange={event => onChange(field, event.target.name, availableTypes.find(t => t.Id === +event.target.value).Description)}
                        instructions={optional(field, '@instructions', '')}
                        disabled={disabled}
                    />
                </div>
            ) : (
                <>
                    <Label instructions={optional(field, '@instructions', '')} muted={!!!field['@showInReport']}>
                        {optional(field, '@name', '')}
                        {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
                    </Label>
                    <Select
                        isMulti
                        defaultValue={Type.map(t => ({
                            value: t.Id,
                            label: t.Description
                        }))}
                        options={options}
                        onChange={value => {
                            onChange(field, '#text', value.map(v => v.label).join(', '));
                        }}
                        isDisabled={disabled}
                    />
                </>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    Types: state.types
});

export default connect(mapStateToProps)(ListSectionField);
