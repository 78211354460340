import React, { Component } from 'react';
import ReactTable from 'react-table';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import { transformSections } from '../../../utils';
import SectionVersionList from './SectionVersionList';

class SectionList extends Component {
    state = {
        expanded: {}
    };

    expandRow = rowIndex => {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    };

    render() {
        const { sections, updatingSections, deleteSection, openCreateModal } = this.props;
        const { expanded } = this.state;

        const tableData = transformSections(sections);

        const columns = [
            {
                Header: 'Section Template Name',
                accessor: 'SectionName',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                Header: 'Latest Version',
                accessor: 'LatestVersion',
                filterable: false,
                Cell: ({ value }) => <div className="flex justify-center">{value}</div>
            },
            {
                Header: 'Enabled',
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center">
                        {row._original.Sections[0].Enabled ? (
                            <i className="fas fa-check text-green-light" />
                        ) : (
                                <i className="fas fa-times text-grey-light" />
                            )}
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <Guard permissions={[roles.Sections.CREATE_SECTION_TEMPLATES]}>
                            <span
                                className="cursor-pointer mr-4 cursor-pointer text-grey"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    openCreateModal(undefined, row._original.Sections.sort((a, b) => b.Version - a.Version)[0]);
                                }}
                            >
                                <i className="fas fa-pencil-alt" />
                            </span>
                        </Guard>
                        <Guard permissions={[roles.Sections.DELETE_SECTION_TEMPLATES]}>
                            <span
                                className={`${row._original.Sections[0].Enabled ? 'text-red-light' : 'text-green-light'} ${
                                    !updatingSections[row._original.Sections[0].Id] ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                                    }`}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (!updatingSections[row._original.Sections[0].Id]) deleteSection(row._original.Sections[0]);
                                }}
                            >
                                <i
                                    className={`fas ${
                                        !updatingSections[row._original.Sections[0].Id]
                                            ? row._original.Sections[0].Enabled
                                                ? 'fa-trash-alt'
                                                : 'fa-undo'
                                            : 'fa-spinner fa-pulse'
                                        }`}
                                />
                            </span>
                        </Guard>
                    </div>
                )
            }
        ];

        return sections.length > 0 ? (
            <ReactTable
                filterable
                expanded={expanded}
                data={tableData}
                columns={columns}
                defaultSorted={[
                    {
                        id: 'SectionName',
                        desc: false
                    }
                ]}
                SubComponent={({ row }) => (
                    <SectionVersionList
                        versions={row._original.Sections}
                        deleteSection={deleteSection}
                        updatingSections={updatingSections}
                        openCreateModal={openCreateModal}
                    />
                )}
                getTdProps={(_, rowInfo, column) => ({
                    className: `${
                        typeof Object.keys(expanded).find(k => !!expanded[k]) === 'string' && !!!expanded[rowInfo && rowInfo.viewIndex]
                            ? `opacity-25`
                            : ''
                        }`,
                    onClick: e => {
                        if (column.Header !== undefined || column.expander) this.expandRow(rowInfo.viewIndex);
                    }
                })}
                getTheadProps={() => ({
                    style: { background: 'white' }
                })}
                getTheadFilterProps={() => ({
                    style: { background: 'white' }
                })}
            />
        ) : (
                <div className="text-primary">There are no sections.</div>
            );
    }
}

export default SectionList;
