import axios from 'axios';
import { baseUrl } from '../../../config';
import { toast } from 'react-toastify';

export const stockPhrases = {
    state: {
        stockPhrases: {},
        _errors: {},
        _updating: {
            _gettingStockPhrases: false,
            _creatingStockPhrase: false,
            _deletingStockPhrase: {}
        }
    },
    reducers: {
        getStockPhraseRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _gettingStockPhrases: true }
        }),
        getStockPhraseSuccess: (state, { fieldKey, phrases }) => ({
            ...state,
            stockPhrases: {
                ...state.stockPhrases,
                [fieldKey]: phrases
            },
            _errors: {},
            _updating: { ...state._updating, _gettingStockPhrases: false }
        }),
        getStockPhraseFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _gettingStockPhrases: false }
        }),

        addStockPhraseRequest: state => ({
            ...state,
            _errors: {},
            _updating: { ...state._updating, _creatingStockPhrase: true }
        }),
        addStockPhraseSuccess: (state, { fieldKey, phrase }) => ({
            ...state,
            stockPhrases: {
                ...state.stockPhrases,
                [fieldKey]: [
                    ...state.stockPhrases[fieldKey],
                    phrase
                ]
            },
            _errors: {},
            _updating: { ...state._updating, _creatingStockPhrase: false }
        }),
        addStockPhraseFailure: (state, errors) => ({
            ...state,
            _errors: errors,
            _updating: { ...state._updating, _creatingStockPhrase: false }
        }),

        deleteStockPhraseRequest: (state, phraseId) => ({
            ...state,
            _errors: {},
            _updating: {
                ...state._updating, _deletingStockPhrase: {
                    ...state._updating._deletingStockPhrase,
                    [phraseId]: true
                }
            }
        }),
        deleteStockPhraseSuccess: (state, { fieldKey, phraseId }) => ({
            ...state,
            stockPhrases: {
                ...state.stockPhrases,
                [fieldKey]: state.stockPhrases[fieldKey].filter(sp => sp.Id !== phraseId)
            },
            _errors: {},
            _updating: {
                ...state._updating, _deletingStockPhrase: {
                    ...state._updating._deletingStockPhrase,
                    [phraseId]: false
                }
            }
        }),
        deleteStockPhraseFailure: (state, { phraseId, errors }) => ({
            ...state,
            _errors: errors,
            _updating: {
                ...state._updating, _deletingStockPhrase: {
                    ...state._updating._deletingStockPhrase,
                    [phraseId]: false
                }
            }
        }),
    },
    effects: dispatch => ({
        async getStockPhrases(fieldKey, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.stockPhrases.getStockPhraseRequest();

            try {
                const result = await axios.get(`${baseUrl}/api/stock-phrases/${fieldKey}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                dispatch.stockPhrases.getStockPhraseSuccess({ fieldKey, phrases: result.data });
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.stockPhrases.getStockPhraseFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
            }
        },
        async addStockPhrase({ fieldKey, phrase }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.stockPhrases.addStockPhraseRequest();

            try {
                const result = await axios.post(`${baseUrl}/api/stock-phrases/${fieldKey}`, {
                    Phrase: phrase
                }, {
                        headers: { Authorization: `Bearer ${access_token}` }
                    });

                toast.success('The stock phrase was added successfully.');
                dispatch.stockPhrases.addStockPhraseSuccess({ fieldKey, phrase: result.data });
                return Promise.resolve();
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.stockPhrases.addStockPhraseFailure(
                    error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                );
                return Promise.reject();
            }
        },
        async deleteStockPhrase({ fieldKey, phrase }, rootState) {
            const {
                account: {
                    auth: { access_token }
                }
            } = rootState;
            dispatch.stockPhrases.deleteStockPhraseRequest(phrase.Id);

            try {
                await axios.delete(`${baseUrl}/api/stock-phrases/${fieldKey}/${phrase.Id}`, {
                    headers: { Authorization: `Bearer ${access_token}` }
                });

                toast.success('The stock phrase was removed successfully.');
                dispatch.stockPhrases.deleteStockPhraseSuccess({ fieldKey, phraseId: phrase.Id });
            } catch (error) {
                if (error.response === undefined) toast.error('There was an error connecting to the server.');
                dispatch.stockPhrases.deleteStockPhraseFailure({
                    phraseId: phrase.Id,
                    errors: error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' }
                });
            }
        }
    })
};