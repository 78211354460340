import React from 'react';
import { Label } from './form';

const ControlledInput = React.forwardRef(({ top, errors = {}, className, ...props }, ref) => {
    const actualClassName = className === undefined ? (props.type !== 'checkbox' && `input-field`) || `` : className;

    const input = (
        <input
            id={props.name}
            ref={ref || null}
            className={`${actualClassName} ${errors[props.name] ? 'border-red' : ''}`}
            checked={props.type === 'checkbox' && props.value}
            {...props}
        />
    );

    return (
        <>
            {props.label && props.type !== 'checkbox' && <Label>{props.label}</Label>}

            {top !== undefined ? errors[props.name] ? <p className="text-red text-xs italic mb-2">{errors[props.name]}</p> : null : <></>}

            {(props.type === 'checkbox' && (
                <div className="flex flex-grow -mt-3 items-center">
                    {input}
                    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold ml-4" htmlFor={props.name}>
                        {props.label}
                    </label>
                </div>
            )) ||
                input}

            {top === undefined ? errors[props.name] ? <p className="text-red text-xs italic">{errors[props.name]}</p> : null : <></>}
        </>
    );
});

export default ControlledInput;
