import React, { Component } from 'react';
import { connect } from 'react-redux';

import { expenseTypesOperations } from './duck';
import Guard from '../common/Guard';
import { roles } from '../../constants';

import Modal from '../common/Modal';
import Button from '../common/Button';
import ExpenseTypesList from './components/ExpenseTypesList';
import ExpenseTypeForm from './components/ExpenseTypeForm';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class ExpenseTypes extends Component {
    state = {
        showCreateModal: false
    };

    componentDidMount() {
        this.props.getExpenseTypes();
    }

    openModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    createExpenseType = values => {
        this.props
            .createExpenseType({
                Short: values['Short'],
                Description: values['Short'],
                UOMType_Id: +values['UOMType_Id'],
                MultipleUnitsAllowed: !!values['MultipleUnitsAllowed'],
                Reimbursable: !!values['Reimbursable']
            })
            .then(() => this.closeModal())
            .catch(() => {});
    };

    render() {
        const { showCreateModal } = this.state;
        const { gettingExpenseTypes, creatingExpenseType, errors } = this.props;

        return (
            <>
                <div className="container">
                    <div className="flex justify-between items-center">
                        <PageHeader>Expense Types</PageHeader>
                        <Guard permissions={[roles.ExpenseTypes.CREATE_EXPENSE_TYPE]}>
                            <Button inverse icon="fas fa-dollar-sign" onClick={this.openModal}>
                                Add Expense Type
                            </Button>
                        </Guard>
                    </div>
                    <Divider />
                    <div className="bg-white mb-4 p-4 mb-8">
                        {!gettingExpenseTypes ? (
                            <ExpenseTypesList />
                        ) : (
                            <span>
                                Loading... <i className="fas fa-spinner fa-pulse" />
                            </span>
                        )}
                    </div>
                </div>
                <Modal show={showCreateModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showCreateModal ? (
                            <ExpenseTypeForm
                                errors={errors}
                                closeModal={this.closeModal}
                                onSubmit={this.createExpenseType}
                                isProcessing={creatingExpenseType}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    creatingExpenseType: state.expenseTypes._updating._creatingExpenseType,
    gettingExpenseTypes: state.expenseTypes._updating._gettingExpenseTypes,
    errors: (state.expenseTypes._errors && state.expenseTypes._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    getExpenseTypes: () => dispatch(expenseTypesOperations.getExpenseTypes()),
    createExpenseType: expenseType => dispatch(expenseTypesOperations.createExpenseType(expenseType)),
    updateExpenseType: expenseType => dispatch(expenseTypesOperations.updateExpenseType(expenseType)),
    deleteExpenseType: expenseType => dispatch(expenseTypesOperations.deleteExpenseType(expenseType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpenseTypes);
