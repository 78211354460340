import React from 'react';

const Step = ({ index, text, activeIndex, first, last }) => (
    <div
        className={`flex items-center justify-center border-b border-t lg:border-t-0 border-solid border-grey h-16 step relative text-right ${
            index === activeIndex ? 'text-white bg-accent active' : ''
        } ${last ? 'border-r' : ''} ${first ? 'border-l' : ''}`}
    >
        {!first && <div className={`chevron border-grey bg-transparent ${index - 1 === activeIndex ? 'active' : ''}`} />}
        <div className={`text-sm ml-8`}>{text}</div>
    </div>
);
const Stepper = ({ steps = [], activeIndex }) => (
    <div className="flex items-center w-full">
        {steps.map((step, index) => (
            <div style={{ width: 100 / steps.length + '%' }} key={index}>
                <Step
                    index={index}
                    text={step}
                    first={index === 0}
                    last={index === steps.length - 1}
                    activeIndex={activeIndex}
                    completed={index < activeIndex}
                />
            </div>
        ))}
    </div>
);

export default Stepper;
