import React from 'react';
import { Route } from 'react-router-dom';

import Component from './Invoices';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

export default () => (
    <Route key="invoices-router" path="/invoices">
        <Guard key='invoices-route' notFound={NotFound} permissions={[roles.Invoices.VIEW_INVOICE_DETAILS]}>
            <AuthRoute exact path='/invoices' component={Component} />
        </Guard>
    </Route>
);