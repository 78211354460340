import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { Form } from '../../common/form/Form';
import { CustomSelect } from '../../common/form';

class AddSectionForm extends Component {
    addSectionToItem = values => {
        const { item } = this.props;

        if (values['Section_Id'] === undefined) return;

        this.props
            .addSectionToItem(item.Case.Id, item.Id, +values['Section_Id'].value)
            .then(() => this.props.closeModal())
            .catch(() => { });
    };

    render() {
        const { addingSection, closeModal, errors, item } = this.props;
        const options = item.AvailableSections.map(s => ({
            value: s.Id,
            label: s.SectionName
        }));

        return (
            <>
                <Form errors={errors} onSubmit={this.addSectionToItem}>
                    <CustomSelect
                        name="Section_Id"
                        label="Section"
                        options={options}
                        required={true}
                        submitOnEnter />
                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!addingSection ? (
                            <Button type="submit">Add Section</Button>
                        ) : (
                                <Button disabled>
                                    Adding Section... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    addingSection: state.item._updating._addingSection,
    item: state.item.current,
    errors: (state.item._errors && state.item._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    addSectionToItem: (caseId, itemId, Section_Id) => dispatch.item.addSectionToItem({ caseId, itemId, Section_Id })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSectionForm);
