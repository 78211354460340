import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Link } from 'react-router-dom';
import 'whatwg-fetch';
import { toast } from 'react-toastify';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';
import { baseUrl } from '../../../config';
import CustomCheckbox from '../../common/CustomCheckbox';
import StatusPill from '../../common/StatusPill';

async function downloadCaseXml(_case, access_token) {
    try {
        const response = await fetch(`${baseUrl}/api/cases/${_case.Id}/xml`, {
            headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
        });

        let blob = await response.blob();

        FileSaver.saveAs(blob, `${_case.CaseNumber}.xml`);
    } catch (error) {
        toast.error('Unable to retrieve case XML.');
    }
}

async function downloadCaseXslt(_case, access_token) {
    try {
        const response = await fetch(`${baseUrl}/api/cases/${_case.Id}/xslt`, {
            headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
        });

        let blob = await response.blob();

        FileSaver.saveAs(blob, `${_case.CaseNumber}.xslt`);
    } catch (error) {
        toast.error('Unable to retrieve case XML.');
    }
}

export default function getCaseColumns(userGroups = []) {
    const showACD = userGroups.includes('Investigator');

    return [
        {
            filterable: false,
            sortable: false,
            minWidth: 30,
            className: 'no-click-event',
            Cell: row => (
                <div className="flex justify-center items-center">
                    <CustomCheckbox
                        name={row.original.Id}
                        checked={this.state.checkedCases[row.original.Id] || false}
                        onChange={e => {
                            this.updateChecked(row.original.Id, row.index, e.target.checked);
                        }}
                        onClick={e => {
                            if (e.shiftKey) this.multiChecked(row.index, e.target.checked);
                        }}
                    />
                </div>
            ),
            Header: () => (
                <div className="flex justify-center items-center">
                    <CustomCheckbox
                        name="CHECK_ALL"
                        checked={this.state.allChecked || false}
                        onChange={e => {
                            this.checkAll(e.target.checked);
                        }}
                    />
                </div>
            )
        },
        {
            Header: 'Case',
            accessor: 'CaseNumber',
            filterable: false,
            Cell: row => (
                <div className="group">
                    <span className="hidden group-hover:block">{row.original.CaseNumber}</span>
                    <span className="block group-hover:hidden">
                        {row.original.CaseNumber
                            ? (row.original.CaseNumber.length - 6 > 0 ? '...' : '') +
                            row.original.CaseNumber.substring(Math.max(row.original.CaseNumber.length - 6, 0))
                            : ''}
                    </span>
                </div>
            )
        },
        {
            Header: 'Case Name',
            accessor: 'CaseName',
            filterable: false
        },
        {
            Header: 'Org',
            accessor: 'Code',
            maxWidth: 70,
            filterable: false,
            sortable: true
        },
        {
            Header: 'RD',
            accessor: 'ReceivedDate',
            maxWidth: 105,
            filterable: false,
            sortable: true,
            Cell: row => (
                <div className="w-full text-center text-sm">
                    {row.original.ReceivedDate &&
                        moment.utc(row.original.ReceivedDate)
                            .local()
                            .format('MM/DD/YY')}
                </div>
            )
        },
        {
            Header: 'SD',
            accessor: 'ScheduledDate',
            maxWidth: 105,
            filterable: false,
            sortable: true,
            Cell: row => (
                <div className="w-full text-center text-sm text-sm">
                    {row.original.ScheduledDate &&
                        moment.utc(row.original.ScheduledDate)
                            .local()
                            .format('MM/DD/YY')}
                </div>
            )
        },
        {
            Header: (showACD ? 'ACD' : 'CD'),
            accessor: 'DueDate',
            maxWidth: 105,
            filterable: false,
            sortable: true,
            Cell: row => (
                <div className="w-full text-center text-sm">
                    {row.original.DueDate &&
                        moment.utc(row.original.DueDate)
                            .local()
                            .format('MM/DD/YY')}
                </div>
            )
        },
        {
            Header: 'CM',
            accessor: 'CompletionDate',
            maxWidth: 105,
            filterable: false,
            sortable: true,
            Cell: ({ original }) => (
                <div className="w-full text-center text-sm">
                    {original.CompletionDate &&
                        moment.utc(original.CompletionDate)
                            .local()
                            .format('MM/DD/YY')}
                </div>
            )
        },
        {
            Header: 'Reviewer',
            accessor: 'Reviewer',
            filterable: false,
            sortable: true,
            Cell: ({ original }) => <span className="text-sm">{_.get(original, 'Reviewer')}</span>
        },
        {
            Header: 'Case Type',
            accessor: 'CaseType',
            minWidth: 80,
            filterable: false,
            sortable: true
        },
        {
            Header: 'Status',
            accessor: 'WorkflowState',
            filterable: false,
            sortable: true,
            minWidth: 150,
            Cell: ({ value }) => (value ? <StatusPill>{_.startCase(_.toLower(value))}</StatusPill> : <></>)
        },
        {
            Header: undefined,
            id: 'Actions',
            className: 'no-click-event',
            filterable: false,
            sortable: false,
            filterable: false,
            sortable: false,
            accessor: d => d,
            Cell: ({ original }) => {
                return (
                    <div className="flex justify-end items-center h-full px-2 pr-4">
                        {this.state.casesProcessing[original.Id] ? (
                            <i className="fas fa-spinner fa-pulse" />
                        ) : (
                                <>
                                    {original.CompletionDate !== null ||
                                        (original.WorkflowState && original.WorkflowState.Name === 'COMPLETE') ||
                                        this.props.hasPermission([roles.Cases.DOWNLOAD_INCOMPLETE_REPORT]) ? (
                                            <Guard permissions={[roles.Cases.DOWNLOAD_REPORT]}>
                                                {!this.state.downloading[original.Id] ? (
                                                    <span
                                                        className="cursor-pointer mr-4"
                                                        onClick={e => {
                                                            this.downloadReport(original);
                                                        }}
                                                    >
                                                        <i className="fas fa-file-download text-grey" />
                                                    </span>
                                                ) : (
                                                        <span className="text-grey-light cursor-not-allowed mr-4">
                                                            <i className="fas fa-spinner fa-pulse" />
                                                        </span>
                                                    )}
                                            </Guard>
                                        ) : (
                                            <></>
                                        )}
                                    <Guard permissions={[roles.Cases.VIEW_CASE_XML]}>
                                        <span
                                            className="cursor-pointer mr-4"
                                            onClick={e => {
                                                downloadCaseXml(original, this.props.access_token);
                                            }}
                                        >
                                            <i className="fas fa-code text-grey" />
                                        </span>
                                    </Guard>
                                    <Guard permissions={[roles.Cases.VIEW_CASE_XSLT]}>
                                        <span
                                            className="cursor-pointer mr-4"
                                            onClick={e => {
                                                downloadCaseXslt(original, this.props.access_token);
                                            }}
                                        >
                                            <i className="far fa-file-code text-grey" />
                                        </span>
                                    </Guard>
                                    <Guard permissions={[roles.Cases.VIEW_CASES]}>
                                        <Link to={`/cases/${original.Id}`} className="text-grey mr-4">
                                            <span className="cursor-pointer">
                                                <i className="fas fa-pencil-alt" />
                                            </span>
                                        </Link>
                                    </Guard>
                                    <Guard permissions={[roles.Cases.DELETE_CASES]}>
                                        <span className="text-red-light cursor-pointer" onClick={this.shouldDeleteCase.bind(this, original)}>
                                            <i className="fas fa-trash-alt" />
                                        </span>
                                    </Guard>
                                </>
                            )}
                    </div>
                );
            }
        }
    ];
}
