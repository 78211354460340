import React, { Component } from 'react';

import Button from '../../../common/Button';
import {
    DateConfigurer,
    CheckboxConfigurer,
    TextareaConfigurer,
    TemplateConfigurer,
    ListFieldConfigurer,
    GeneralFieldConfigurer
} from './Configurers';

const TYPES_TO_COMPONENT = {
    'date': DateConfigurer,
    'list': ListFieldConfigurer,
    'checkbox': CheckboxConfigurer,
    'textarea': TextareaConfigurer,
    'template': TemplateConfigurer
};

class SBFieldConfigurer extends Component {
    render() {
        const { field, existingFieldIds, errors, onConfigurationChanged, onDelete, editing, newField } = this.props;

        const SpecialConfigurationComponent = TYPES_TO_COMPONENT[field['@type']];

        return (
            <div className="p-4">
                <div className="flex justify-between items-center">
                    <span>{field['@name'] ? field['@name'] : 'No name'} <em>({field['@type']})</em></span>
                    <div className="flex items-center">
                        <Button className="text-red bg-white border border-grey rounded shadow-button hover:bg-grey-lighter" onClick={() => onDelete(field)}><i className="fas fa-trash-alt" /></Button>
                    </div>
                </div>
                <div className="mt-4">
                    <>
                        <GeneralFieldConfigurer newField={newField} field={field} errors={errors} onChange={onConfigurationChanged} editing={editing} />
                        {SpecialConfigurationComponent ?
                            <>
                                <div className="w-full border my-4"></div>
                                <SpecialConfigurationComponent existingFieldIds={existingFieldIds} editing={editing} field={field} errors={errors} onChange={onConfigurationChanged} />
                            </> : <></>
                        }
                    </>
                </div>
            </div>
        );
    }
};

export default SBFieldConfigurer;