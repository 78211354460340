const GET_ORGS_REQUEST = 'app/orgs/GET_ORGS_REQUEST';
const GET_ORGS_SUCCESS = 'app/orgs/GET_ORGS_SUCCESS';
const GET_ORGS_FAILURE = 'app/orgs/GET_ORGS_FAILURE';

const UPDATE_ORG_PARENT_REQUEST = 'app/orgs/UPDATE_ORG_PARENT_REQUEST';
const UPDATE_ORG_PARENT_SUCCESS = 'app/orgs/UPDATE_ORG_PARENT_SUCCESS';
const UPDATE_ORG_PARENT_FAILURE = 'app/orgs/UPDATE_ORG_PARENT_FAILURE';

const CREATE_ORG_REQUEST = 'app/orgs/CREATE_ORG_REQUEST';
const CREATE_ORG_SUCCESS = 'app/orgs/CREATE_ORG_SUCCESS';
const CREATE_ORG_FAILURE = 'app/orgs/CREATE_ORG_FAILURE';

const DELETE_ORG_REQUEST = 'app/orgs/DELETE_ORG_REQUEST';
const DELETE_ORG_SUCCESS = 'app/orgs/DELETE_ORG_SUCCESS';
const DELETE_ORG_FAILURE = 'app/orgs/DELETE_ORG_FAILURE';

export default {
    GET_ORGS_REQUEST,
    GET_ORGS_SUCCESS,
    GET_ORGS_FAILURE,
    UPDATE_ORG_PARENT_REQUEST,
    UPDATE_ORG_PARENT_SUCCESS,
    UPDATE_ORG_PARENT_FAILURE,
    CREATE_ORG_REQUEST,
    CREATE_ORG_SUCCESS,
    CREATE_ORG_FAILURE,
    DELETE_ORG_REQUEST,
    DELETE_ORG_SUCCESS,
    DELETE_ORG_FAILURE
}