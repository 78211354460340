import React, { Component } from 'react';
import ReactTable from 'react-table';
import shortid from 'shortid';

import { EnumTypeSelector, TableField, Header, Divider, DatePicker } from '../../../common/form';

import { optional } from '../../../../utils';
import Button from '../../../common/Button';
import { USER_PROFILE_PAGE_SIZE } from '../../../../constants';

class ClearancesEditor extends Component {
    state = {
        columns: []
    };

    componentDidMount = () => {
        this.setState({
            ...this.state,
            columns: [
                {
                    Header: 'Type',
                    accessor: 'ClearanceType_Id',
                    Cell: row => (
                        <EnumTypeSelector
                            name={`Clearances.${row.original.Id}.ClearanceType_Id`}
                            label=""
                            type="ClearanceType"
                            required={true}
                            showShort={true}
                        />
                    )
                },
                {
                    Header: 'Last Investigation',
                    accessor: 'LastInvestigation',
                    className: 'overflow-auto',
                    Cell: row => {
                        return (
                            <DatePicker
                                dayPickerProps={{
                                    disabledDays: [{ after: new Date() }]
                                }}
                                name={`Clearances.${row.original.Id}.LastInvestigation`}
                                label="Last Investigation"
                                type="date"
                                required={true}
                            />
                        );
                    }
                },
                {
                    Header: 'Organization',
                    accessor: 'OrgCode',
                    Cell: row => <TableField name={`Clearances.${row.original.Id}.OrgCode`} label="Organization" type="text" required={true} />
                },
                {
                    Header: 'Actions',
                    accessor: 'Actions',
                    filterable: false,
                    sortable: false,
                    Cell: row => (
                        <div className="flex justify-center items-center h-full">
                            <span
                                className="text-red-light cursor-pointer"
                                onClick={this.props.onDelete.bind(null, this.props.form, 'Clearances', row.original.Id)}
                            >
                                <i className="fas fa-trash-alt" />
                            </span>
                        </div>
                    )
                }
            ]
        });
    };

    render() {
        let { user, onAdd } = this.props;
        let { columns } = this.state;
        const data = optional(user, target => target.Clearances, []);

        return (
            <>
                <div className="w-full">
                    <div className="flex align-center justify-between">
                        <Header>Clearances</Header>
                        <Button
                            icon="fas fa-id-badge"
                            onClick={onAdd.bind(null, 'Clearances', {
                                Id: shortid.generate(),
                                CredentialType_Id: 1,
                                License: '',
                                RegionType_Id: 1,
                                LastInvestigation: ''
                            })}
                        >
                            Add
                        </Button>
                    </div>
                    <Divider />
                    <div className="p-4">
                        {data.length > 0 ? (
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPagination={false}
                                pageSize={data.length}
                                defaultPageSize={USER_PROFILE_PAGE_SIZE}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        ) : (
                            <div className="mb-8 text-primary">No clearances.</div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ClearancesEditor;
