import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import axios from 'axios';
import { ResizableBox } from 'react-resizable';

import { buildOrgTree, getCheckedOrgs, optional } from '../../utils';
import { orgSelectors, orgOperations } from '../orgs/duck';
import InvoiceCompany from './components/InvoiceCompany';
import { orgTreeToggled } from '../common/methods';
import CustomTreeView from '../common/CustomTreeView';
import { baseUrl } from '../../config';

class Invoices extends Component {
    state = {
        additional: {
            OrgIds: []
        },
        orgs: [],
        expandedOrg: '',
        orgsWithBillable: []
    };
    constructor(props) {
        super(props);
        this.orgTreeToggled = orgTreeToggled.bind(this);
    }
    static getDerivedStateFromProps({ orgs }, prevState) {
        const orgTree = buildOrgTree(orgs, orgs[0]);
        orgTree.isExpanded = true;

        return prevState.orgs.length === 0
            ? {
                  additional: {
                      OrgIds: []
                  },
                  orgs: [orgTree]
              }
            : null;
    }

    orgTreeUpdated = updatedData => {
        const {
            additional: { OrgIds }
        } = this.state;

        if (!_.isEqual(OrgIds, getCheckedOrgs(updatedData))) {
            this.refreshData(getCheckedOrgs(updatedData));
        }

        this.setState({
            ...this.state,
            orgs: updatedData,
            additional: {
                ...this.state.additional,
                OrgIds: getCheckedOrgs(updatedData)
            },
            expandedOrg: ''
        });
    };

    refreshData = _.debounce(OrgIds => {
        this.props.getInvoices(OrgIds);
    }, 500);

    expandCompany = key => {
        this.setState({
            ...this.state,
            expandedOrg: key === this.state.expandedOrg ? '' : key
        });
    };

    async componentDidMount() {
        if (this.props.shouldUpdate && !this.props.loadingOrgs) this.props.updateOrgs();

        try {
            const response = await axios.get(`${baseUrl}/api/orgs/invoiced`, { headers: { Authorization: `Bearer ${this.props.access_token}` } });
            this.setState({
                orgsWithBillable: response.data
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { loadingOrgs, loadingInvoices, invoicableOrgs } = this.props;
        const { orgs, expandedOrg, orgsWithBillable } = this.state;
        const minResizableWidth = Math.max(window.innerWidth / 5, 300);

        return (
            <>
                <div className="flex mx-8">
                    <div className="mr-4">
                        <div>
                            <div className="rounded bg-white shadow pb-2 pr-2 overflow-hidden">
                                <ResizableBox
                                    width={minResizableWidth}
                                    minConstraints={[minResizableWidth, 600]}
                                    maxConstraints={[Infinity, Infinity]}
                                    onResize={(_, data) => {
                                        this.setState({
                                            height: data.size.height
                                        });
                                    }}
                                >
                                    <div className="p-4 overflow-hidden">
                                        <h3 className="text-primary tracking-wide font-medium mb-4">Orgs</h3>
                                        <Scrollbars autoHeight autoHeightMin={this.state.height || 600}>
                                            {loadingOrgs ? (
                                                <span>
                                                    Loading orgs...
                                                    {'\u00A0'}
                                                    {'\u00A0'}
                                                    <i className="fas fa-spinner fa-pulse" />
                                                </span>
                                            ) : (
                                                <>
                                                    <CustomTreeView
                                                        data={orgs}
                                                        node={({ children, classNames, data }) => (
                                                            <li
                                                                className={`cursor-pointer pt-2 pb-2 ${
                                                                    hasBillableCases(data, orgsWithBillable)
                                                                        ? 'font-bold text-grey-darkest'
                                                                        : 'text-grey'
                                                                }`}
                                                            >
                                                                {children}
                                                            </li>
                                                        )}
                                                        onCheckToggleCb={this.orgTreeToggled}
                                                        onUpdateCb={this.orgTreeUpdated}
                                                    />
                                                </>
                                            )}
                                        </Scrollbars>
                                    </div>
                                </ResizableBox>
                            </div>
                        </div>
                    </div>
                    <div className="w-4/5">
                        {loadingInvoices ? (
                            <div className="bg-white rounded shadow p-4 mb-4 overflow-x-auto">
                                Loading invoices for the selected orgs... <i className="fas fa-spinner fa-pulse" />
                            </div>
                        ) : Object.keys(invoicableOrgs).length === 0 ? (
                            <div className="bg-white rounded shadow p-4 mb-4 overflow-x-auto">
                                <p>Please select which orgs you would like to retrieve invoiceable cases for.</p>
                            </div>
                        ) : (
                            Object.keys(invoicableOrgs).map(key => (
                                <div
                                    key={key}
                                    onClick={() => {
                                        if (invoicableOrgs[key]) this.expandCompany(key);
                                    }}
                                >
                                    <InvoiceCompany
                                        companyName={key}
                                        invoices={invoicableOrgs[key]}
                                        isExpanded={expandedOrg === key && invoicableOrgs[key]}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const hasBillableCases = (org, billable) => {
    if (Array.isArray(org.children)) {
        return org.children.some(child => hasBillableCases(child, billable));
    } else {
        return billable.includes(org.id);
    }
};

const mapStateToProps = state => ({
    loadingInvoices: state.invoices._updating._gettingInvoices,
    shouldUpdate: orgSelectors.shouldUpdateOrgs(state.orgs),
    loadingOrgs: state.orgs._updating._gettingOrgs,
    invoicableOrgs: state.invoices.orgs,
    orgs: state.orgs.orgs,
    access_token: state.account.auth.access_token
});

const mapDispatchToProps = dispatch => ({
    updateOrgs: () => dispatch(orgOperations.getOrgs()),
    getInvoices: orgIds => dispatch.invoices.getInvoices(orgIds)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Invoices);
