import React, { Component } from 'react';
import { connect } from 'react-redux';
import { XmlEntities } from 'html-entities';
import ReactTooltip from 'react-tooltip';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import SectionEditModal from './SectionEditModal';
import { optional } from '../../../utils';

class SectionListItem extends Component {
    state = {
        showModal: this.props.showModal || false
    };

    openModal = () => {
        this.setState({
            ...this.state,
            showModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showModal: false
        });
    };

    validateFields = fields => {
        return fields.field.some(field => {
            if (field['@required'] && field['@type'] !== 'checkbox') {
                if (field['@type'] === 'address' && ['@line1', '@city', '@region', '@postalCode'].some(key => !!!field[key])) {
                    return true;
                } else if (field['@type'] === 'template') {
                    return field.Entries.some(e => this.validateFields(e.fields));
                } else if (field['@type'] !== 'address' && !!!field['#text']) {
                    return true;
                }
            }

            return false;
        });
    };

    removeSection = () => {
        this.setState({
            showDeleteModal: true
        });
    };

    confirmRemoveSection = () => {
        const { item, section } = this.props;

        this.setState(
            {
                showDeleteModal: false
            },
            () => this.props.removeSectionFromItem(item.Case.Id, item.Id, section.Id)
        );
    };

    cancelRemoveSection = () => {
        this.setState({
            showDeleteModal: false
        });
    };

    render() {
        const { item, section, deletingSection, disabled } = this.props;
        const { showModal, showDeleteModal } = this.state;
        const isDeleting = deletingSection && deletingSection[section.Id];
        const definition = JSON.parse(section.Definition);
        const hasErrors = this.props.hasErrors || optional(definition, d => this.validateFields(d.fields), false);

        return (
            <>
                <div
                    className={`relative bg-white py-2 px-8 cursor-pointer group ${isDeleting ? 'opacity-50' : ''} ${
                        hasErrors ? 'bg-red-light' : ''
                    }`}
                    onClick={this.openModal}
                >
                    <div
                        className={`hidden ${disabled ? '' : 'group-hover:block '}absolute pin-t pin-r mt-3 mr-4 ${
                            hasErrors ? 'text-white' : 'text-red-light'
                        }`}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.removeSection();
                        }}
                    >
                        <i className={`fas ${!isDeleting ? 'fa-trash-alt' : 'fa-spinner fa-pulse'}`} />
                    </div>
                    <div
                        className={`font-bold text-sm ${hasErrors ? 'text-white' : 'text-grey-dark'} group-hover:text-primary mb-2`}
                        data-tip={`${hasErrors ? 'Required fields are empty!' : section.Section.SectionName}`}
                    >
                        {hasErrors && <i className="fa fa-exclamation-triangle mr-2" />} {section.Section.SectionName}
                    </div>
                    <div className="text-primary group-hover:text-primary-dark">
                        <div className="section-preview" dangerouslySetInnerHTML={{ __html: new XmlEntities().decode(section.Preview) }} />
                    </div>

                    {hasErrors && <ReactTooltip />}
                </div>
                <Modal show={showModal} onClose={this.closeModal} disableEscape>
                    <div className="p-4">
                        {showModal ? <SectionEditModal section={section} item={item} closeModal={this.closeModal} disabled={disabled} /> : <></>}
                    </div>
                </Modal>

                <Modal show={showDeleteModal} onClose={this.cancelRemoveSection} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>{`Delete section ${section.Section.SectionName}?`} All data for this section will be lost. This action cannot be undone.</p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={this.cancelRemoveSection}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.confirmRemoveSection}>
                            Delete Section
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    deletingSection: state.item._updating._deletingSection
});

const mapDispatchToProps = dispatch => ({
    removeSectionFromItem: (caseId, itemId, sectionId) => dispatch.item.removeSectionFromItem({ caseId, itemId, sectionId })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionListItem);
