import React from 'react';
import ReactTable from 'react-table';
import numeral from 'numeral';

const InvoiceExpenseList = ({ expenses }) => {
    const columns = [{
        Header: 'ID',
        accessor: 'Id',
        Cell: ({ value }) => (
            <div className="flex justify-center items-center h-full">
                {value}
            </div>
        )
    }, {
        id: 'ExpenseType',
        Header: 'Expense Type',
        accessor: d => d.ExpenseType ? d.ExpenseType.Description : '',
        Cell: ({ value }) => (
            <div className="flex justify-center items-center h-full">
                {value}
            </div>
        )
    }, {
        id: 'Cost',
        Header: 'Cost',
        Cell: ({ row }) => (
            <div className="flex flex-col justify-center items-center font-mono">
                {row._original.Override ?
                    <>
                        <span className="text-grey-dark line-through text-sm">{numeral(row._original.Cost).format('$0,0.00')} x {row._original.Units}</span>
                        <span>{numeral(row._original.Override).format('$0,0.00')}</span>
                    </> :
                    <>{numeral(row._original.Cost).format('$0,0.00')} x {row._original.Units}</>
                }
            </div>
        )
    }, {
        id: 'Units',
        Header: 'Units',
        accessor: d => d.ExpenseType && d.ExpenseType.UOM ? d.ExpenseType.UOM.Description : '',
        Cell: ({ value }) => (
            <div className="flex justify-center items-center h-full">
                {value}
            </div>
        )
    }, {
        id: 'Passthrough',
        Header: 'Passthrough',
        accessor: 'Passthrough',
        Cell: ({ value }) => <div className="flex justify-center items-center h-full">
            {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
        </div>
    }, {
        id: 'IncurredBy',
        Header: 'Incurred By',
        accessor: d => d.Subject ? `${d.Subject.FirstName} ${d.Subject.LastName}` : '',
        Cell: ({ value }) => (
            <div className="flex justify-center items-center h-full">
                {value}
            </div>
        )
    }];

    return (
        <div className="px-8 py-4 my-4 bg-table-search rounded shadow-inner">
            <div className="bg-white shadow rounded p-4">
                {expenses.length ?
                    <ReactTable
                        data={expenses}
                        columns={columns}
                        sortable={false}
                        filterable={false}
                        pageSize={expenses.length}
                        showPagination={false}
                        defaultSorted={[{ id: 'Id', desc: false }]}
                    /> :
                    <span className="text-grey-darker">This case has no expenses.</span>
                }
            </div>
        </div>);
};

export default InvoiceExpenseList;