const sortedRoles = identity => [...identity.roles.sort((role1, role2) => (role1.Name.toUpperCase() < role2.Name.toUpperCase() ? -1 : 1))];

const getRoleWithName = (identity, name) => identity.roles.find(r => r.Name === name);

const roleIsUpdating = (identity, roleId) => identity._updating[`role-${roleId}`];
const roleIsDeleting = (identity, roleId) => identity._updating[`deleting-role-${roleId}`];
const getUserFromUsers = (identity, user_id) => identity.users.find(u => u.Id === user_id) || {};

export default {
    sortedRoles,
    getRoleWithName,
    roleIsUpdating,
    roleIsDeleting,
    getUserFromUsers
};
