import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { baseUrl } from '../../../config';
import { makeCancelable } from '../../../utils';
import { typesSelectors, typesOperations } from '../../types/duck';
import { identityOperations } from '../../identity/duck';
import { TextArea } from '../../common/form';
import OrgSelector from '../../common/OrgSelector';
import AccountLock from './AccountLock';
import General from './General';
import Modal from '../../common/Modal';
import AddressForm from './AddressForm';
import { Form } from '../../common/form/Form';
import Settings from './Settings';
import PhoneNumbersEditor from './FormDataTable/PhoneNumbersEditor';
import EmailsEditor from './FormDataTable/EmailsEditor';
import AddressesEditor from './FormDataTable/AddressesEditor';
import CredentialsEditor from './FormDataTable/CredentialsEditor';
import ClearancesEditor from './FormDataTable/ClearancesEditor';

import {
    handleInputChange,
    orgSelected,
    updateContractTypes,
    showOrgSelector,
    closeOrgSelector,
    updateGroups,
    addRow,
    removeRow,
    openModal,
    closeModal,
    submitAddressForm,
    submitUpdateForm
} from './methods';
import Button from '../../common/Button';
import { toast } from 'react-toastify';

const FORM_NAME = 'EditUser';

class UserEditor extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = handleInputChange.bind(this);
        this.orgSelected = orgSelected.bind(this);
        this.updateContractTypes = updateContractTypes.bind(this);
        this.showOrgSelector = showOrgSelector.bind(this);
        this.closeOrgSelector = closeOrgSelector.bind(this);
        this.updateGroups = updateGroups.bind(this);
        this.addRow = addRow.bind(this);
        this.removeRow = removeRow.bind(this);
        this.openModal = openModal.bind(this);
        this.closeModal = closeModal.bind(this);
        this.submitAddressForm = submitAddressForm.bind(this);
        this.submitUpdateForm = submitUpdateForm.bind(this);

        this.state = {
            formName: FORM_NAME,
            user: {},
            errors: null,
            loading: false,
            updatingUser: false,
            deletingUser: false,
            showOrgSelector: false,
            values: {},
            errors: {}
        };
    }

    async componentDidMount() {
        const { userId, access_token, shouldUpdateContractTypes } = this.props;
        //this.props.dispatch(clearFields(FORM_NAME));
        this.userPromise = makeCancelable(
            new Promise(r => {
                if (shouldUpdateContractTypes) this.props.updateContractTypes();
                this.props.getGroups();

                this.setState(
                    {
                        ...this.state,
                        errors: null,
                        loading: true
                    },
                    async () => {
                        try {
                            const response = await axios.get(`${baseUrl}/api/identity/users/${userId}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });
                            const values = {};
                            const user = response.data;
                            if (user && Array.isArray(user.Emails)) {
                                const primaryEmail = user.Emails.find(email => email.Address === user.Email);
                                if (!primaryEmail) {
                                    user.Emails.push({
                                        Id: 0,
                                        Address: user.Email,
                                        IsPrimary: true
                                    });
                                }
                            }

                            Object.keys(user).forEach(key => {
                                if (Array.isArray(user[key]) && key !== 'ContractTypes') {
                                    let array = user[key];
                                    array.forEach((el, index) => {
                                        Object.keys(el).forEach(elKey => {
                                            if (elKey === 'Id') return;
                                            let value = el[elKey];

                                            if (moment(el[elKey], moment.ISO_8601, true).isValid()) {
                                                value = moment(el[elKey]).format('YYYY-MM-DD');
                                            }

                                            values[`${key}.${el.Id}.${elKey}`] = value;
                                        });
                                    });
                                } else {
                                    values[key] = user[key];
                                }
                            });
                            this.setState({
                                ...this.state,
                                user: user,
                                _original: user,
                                loading: false,
                                values
                            });
                        } catch (e) {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                        }
                    }
                );
            })
        );
    }

    componentWillUnmount() {
        if (this.userPromise) this.userPromise.cancel();
        if (this.userUpdatePromise) this.userUpdatePromise.cancel();
        if (this.deleteUserPromise) this.deleteUserPromise.cancel();
    }

    lockStatusChanged = ({ LockoutEndDateUtc }) => {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                LockoutEndDateUtc
            }
        });
        this.props.onUpdated(false);
    };

    render() {
        const { loading, updatingUser, user, values, currentAddress } = this.state;
        const { contractTypes, groups, loadingGroups, loadingContractTypes, userId } = this.props;
        const errors = this.state.errors ? this.state.errors.ModelState : {};
        const isEmployee = user.Groups && user.Groups.some(group => group.Tags.some(t => t.Name === 'Employee'));

        return (
            <div className="px-2">
                {loading || !user || loadingGroups || loadingContractTypes ? (
                    <span>
                        Loading... <i className="fas fa-spinner fa-pulse" />
                    </span>
                ) : (
                    <Form
                        onSubmit={values => {
                            this.submitUpdateForm({ ...values }, `/api/identity/users/${userId}`, 'patch')
                                .then(() => this.props.onUpdated(false))
                                .catch(e => toast.error(e));
                        }}
                        defaultValues={values}
                        errors={errors}
                    >
                        <AccountLock user={user} userId={this.props.userId} lockStatusChanged={this.lockStatusChanged} />
                        <General
                            errors={errors}
                            user={user}
                            defaultGroups={user.Groups || []}
                            groups={groups}
                            handleInputChange={this.handleInputChange}
                            showOrgSelector={this.showOrgSelector}
                            updateGroups={this.updateGroups}
                        />
                        {isEmployee ? (
                            <Settings
                                errors={errors}
                                user={user}
                                groups={groups}
                                contractTypes={contractTypes}
                                handleInputChange={this.handleInputChange}
                                updateContractTypes={this.updateContractTypes}
                                updateGroups={this.updateGroups}
                            />
                        ) : (
                            <></>
                        )}

                        <PhoneNumbersEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                        <EmailsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                        <AddressesEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} openModal={this.openModal} />
                        {isEmployee ? (
                            <>
                                <CredentialsEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                                <ClearancesEditor user={user} onAdd={this.addRow} onDelete={this.removeRow} form={FORM_NAME} />
                            </>
                        ) : (
                            <></>
                        )}

                        <TextArea name="Notes" label="Notes" type="textarea" />
                        <div className="flex items-end w-full justify-end">
                            {this.props.close && (
                                <Button className="mr-2 bg-grey" onClick={this.props.close}>
                                    Cancel
                                </Button>
                            )}
                            {!updatingUser ? (
                                <Button type="submit">Save Changes</Button>
                            ) : (
                                <Button className="ml-auto">
                                    Saving changes... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
                {this.state.showOrgSelector ? <OrgSelector close={this.closeOrgSelector} orgSelected={this.orgSelected} /> : <></>}
                <Modal show={this.state.showModal} onClose={this.closeModal}>
                    {this.state.showModal ? (
                        <AddressForm onSubmit={this.submitAddressForm} values={user ? user.Addresses[currentAddress] : {}}>
                            <div className="flex justify-end items-end">
                                <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                    Cancel
                                </Button>
                                <Button type="submit">Ok</Button>
                            </div>
                        </AddressForm>
                    ) : (
                        <></>
                    )}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    groups: state.identity.groups,
    contractTypes: state.types['ContractType'],
    access_token: state.account.auth.access_token,
    loadingGroups: state.identity._updating._gettingGroups,
    loadingContractTypes: typesSelectors.typeIsLoading(state.types, 'ContractType'),
    shouldUpdateContractTypes: typesSelectors.shouldUpdateType(state.types, 'ContractType'),
    regions: state.types.RegionType
});

const mapDispatchToProps = dispatch => ({
    getGroups: () => dispatch(identityOperations.getGroups()),
    updateContractTypes: () => dispatch(typesOperations.updateType('ContractType')),
    dispatch
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditor);
