import React from 'react';
import { Label } from '../../../common/form';
import { optional } from '../../../../utils';

const TextSectionField = ({ field, onChange, disabled }) => (
    <div className="flex justify-between items-center flex-wrap -mx-4 mb-4">
        <div className="w-full px-4">
            <Label instructions={optional(field, '@instructions', '')} muted={!!!field['@showInReport']}>
                {optional(field, '@label', '')}
                {optional(field, '@required', '') ? <strong className="text-red">&nbsp;*</strong> : <></>}
            </Label>
            <input
                className="input-field"
                type="text"
                name="#text"
                value={field['#text']}
                onChange={event => onChange(field, event.target.name, event.target.value)}
                disabled={disabled}
            />
        </div>
    </div>
);

export default TextSectionField;
