import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { expenseTypesOperations } from '../duck';
import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Modal from '../../common/Modal';
import ExpenseTypeForm from './ExpenseTypeForm';

class ExpenseTypesList extends Component {
    state = {
        showEditModal: false,
        editing: undefined
    };

    openModal = expenseType => {
        this.setState({
            ...this.state,
            showEditModal: true,
            editing: expenseType
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showEditModal: false,
            editing: undefined
        });
    };

    updateExpenseType = values => {
        this.props
            .updateExpenseType({
                Id: values['Id'],
                Short: values['Short'],
                Description: values['Short'],
                UOMType_Id: +values['UOMType_Id'],
                MultipleUnitsAllowed: !!values['MultipleUnitsAllowed'],
                Reimbursable: !!values['Reimbursable']
            })
            .then(() => this.closeModal())
            .catch(() => { });
    };

    render() {
        const { showEditModal, editing } = this.state;
        const { expenseTypes, updatingExpenseTypes, errors } = this.props;
        const isUpdating = editing ? updatingExpenseTypes[editing.Id] : false;

        const columns = [
            {
                id: 'Name',
                Header: 'Name',
                accessor: 'Short',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                id: 'UOM',
                Header: 'UOM',
                accessor: d => (d.UOM ? d.UOM.Short : ''),
                minWidth: 50,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                Header: 'Allow Multiple?',
                filterable: false,
                sortable: false,
                accessor: 'MultipleUnitsAllowed',
                minWidth: 50,
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                Header: 'Reimbursable?',
                filterable: false,
                sortable: false,
                accessor: 'Reimbursable',
                minWidth: 50,
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        {value ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                    </div>
                )
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <Guard permissions={[roles.ExpenseTypes.EDIT_EXPENSE_TYPE]}>
                            <span
                                className="cursor-pointer mr-4 cursor-pointer text-grey"
                                onClick={e => {
                                    e.preventDefault();
                                    this.openModal(row._original);
                                }}
                            >
                                <i className="fas fa-pencil-alt" />
                            </span>
                        </Guard>
                        <Guard permissions={[roles.ExpenseTypes.DELETE_EXPENSE_TYPE]}>
                            <span
                                className={`text-${row._original.Hidden ? 'green' : 'red'}-light ${
                                    !updatingExpenseTypes[row._original.Id] ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                                    }`}
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.deleteExpenseType(row._original);
                                }}
                            >
                                <i
                                    className={`fas ${
                                        updatingExpenseTypes[row._original.Id] ? 'fa-spinner fa-pulse' : row._original.Hidden ? 'fa-undo' : 'fa-trash-alt'
                                        }`}
                                />
                            </span>
                        </Guard>
                    </div>
                )
            }
        ];

        return (
            <>
                {expenseTypes.length > 0 ? (
                    <ReactTable
                        filterable
                        pageSize={Math.min(expenseTypes.length, 10)}
                        data={expenseTypes}
                        defaultSorted={[
                            {
                                id: 'Name',
                                desc: false
                            }
                        ]}
                        columns={columns}
                        showPagination={expenseTypes.length > 10}
                        showPageSizeOptions={false}
                        getTheadProps={() => ({
                            style: { background: 'white' }
                        })}
                        getTheadFilterProps={() => ({
                            style: { background: 'white' }
                        })}
                    />
                ) : (
                        <div className="text-primary">There are no expense types.</div>
                    )}
                <Modal show={showEditModal} onClose={this.closeModal}>
                    <div className="p-4">
                        {showEditModal ? (
                            <ExpenseTypeForm
                                defaultValues={{
                                    ...editing,
                                    UOMType_Id: editing.UOM ? editing.UOM.Id : 0
                                }}
                                errors={errors}
                                closeModal={this.closeModal}
                                onSubmit={this.updateExpenseType}
                                isProcessing={isUpdating}
                            />
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    expenseTypes: state.expenseTypes.expenseTypes,
    updatingExpenseTypes: state.expenseTypes._updating._expenseTypes,
    errors: (state.expenseTypes._errors && state.expenseTypes._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    updateExpenseType: expenseType => dispatch(expenseTypesOperations.updateExpenseType(expenseType)),
    deleteExpenseType: expenseType => dispatch(expenseTypesOperations.deleteExpenseType(expenseType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpenseTypesList);
