export default {
    clearIndicator: styles => ({
        ...styles,
        color: '#606f7b',
        ':hover': {
            color: '#606f7b'
        }
    }),
    control: styles => ({
        ...styles,
        appearance: 'none',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        borderColor: '#dae1e7',
        borderWidth: '1px',
        borderRadius: '0.25rem',
        color: '#606f7b',
        cursor: 'text',
        width: '100%',
        ':hover': {
            borderColor: '#dae1e7'
        }
    }),
    dropdownIndicator: styles => ({
        ...styles,
        color: '#606f7b',
        ':hover': {
            color: '#606f7b'
        }
    }),
    input: styles => ({
        ...styles,
        color: '#606f7b'
    }),
    multiValue: styles => ({
        ...styles,
        backgroundColor: 'white',
        marginRight: '1em'
    }),
    multiValueLabel: styles => ({
        ...styles,
        backgroundColor: 'white',
        color: '#606f7b',
        paddingRight: '0.25rem'
    }),
    multiValueRemove: styles => ({
        ...styles,
        backgroundColor: 'white',
        color: '#e3342f',
        cursor: 'pointer',
        marginTop: '2px'
    })
};
