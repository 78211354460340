import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { accountOperations, accountSelectors } from '../account/duck';
import { desktopNav, MobileNavigation } from '../navigation';
import { logo, logoWord } from '../../config';

class Navigation extends Component {
    render() {
        const { userName, showSideNavButton, toggleSideNav, sideNavOpen } = this.props;

        return (
            <div id="nav" className="bg-primary border-b z-50 fixed w-full">
                <div className="px-6">
                    <div className="flex justify-between items-baseline">
                        <div className="-ml-4 py-4 relative">
                            <Link to="/" className="no-underline text-white text-xl w-16">
                                <img src={logo} alt="" className="absolute" style={{ top: '0.5rem' }} />
                                {sideNavOpen && <img src={logoWord} alt="" className="absolute" style={{ top: '0.5rem', left: '3rem' }} />}
                            </Link>
                            <i
                                className={`side-nav-menu text-white cursor-pointer text-xl fas ${sideNavOpen ? 'fa-times' : 'fa-bars'} ${
                                    showSideNavButton ? '' : 'opacity-0'
                                }`}
                                onClick={toggleSideNav}
                                style={{ marginLeft: sideNavOpen ? '330px' : '5rem' }}
                            />
                        </div>
                        <div className="block md:hidden">
                            <MobileNavigation />
                        </div>
                        <div className="hidden md:flex">
                            {desktopNav}
                            {/* User Menu */}
                            <div className="group relative py-4 ml-4">
                                <div className="no-underline cursor-pointer text-white opacity-75 group-hover:opacity-100">
                                    <span className="mr-2">{userName}</span>
                                    <i className="fas fa-angle-down fa-xs opacity-50 group-hover:opacity-75" />
                                </div>
                                <div
                                    className="hidden group-hover:block absolute shadow rounded-br rounded-bl opacity-100 bg-white w-48 pin-r text-right"
                                    style={{ top: '100%' }}
                                >
                                    <NavLink to="/account" className="block no-underline tracking p-4 pr-6 text-primary hover:bg-grey-lightest">
                                        Manage
                                    </NavLink>
                                    <button
                                        className="appearance-none text-right no-underline w-full p-4 pr-6 text-primary hover:bg-grey-lightest"
                                        type="button"
                                        onClick={this.props.logOut}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userName: accountSelectors.userName(state.account),
    sideNavOpen: state.navigation.sideNavOpen,
    showSideNavButton: state.navigation.showSideNavButton
});

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(accountOperations.logOut()),
    toggleSideNav: dispatch.navigation.toggleSideNav
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Navigation)
);
