import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

class AssetList extends Component {
    downloadFile = async asset => {
        const itemType = this.props.item.ItemType;
        try {
            const response = await this.props.downloadAsset({ itemTypeId: itemType.Id, assetId: asset.Id });

            let blob = await response.blob();

            FileSaver.saveAs(blob, asset.Name);
        } catch (error) {
            console.log(error);
            toast.error('Unable to retrieve file.');
        }
    };
    render() {
        const assets = this.props.item.ItemType.GoalTypeAssets || [];
        return (
            <div className="mt-8 mb-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="font-bold text-primary">Downloadable Assets</div>
                </div>
                {assets.length ? (
                    <ul>
                        {assets.map(a => (
                            <li key={a.Id} className="cursor-pointer underline text-primary" onClick={this.downloadFile.bind(this, a)}>
                                {a.Name}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="text-primary">This item has no assets.</div>
                )}
            </div>
        );
    }
}

const mapDispatch = dispatch => ({
    downloadAsset: payload => dispatch.itemTypeAssets.downloadAsset(payload)
});
export default connect(
    null,
    mapDispatch
)(AssetList);
