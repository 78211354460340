import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import ReactTable from 'react-table';
import { roles } from '../../../constants';
import { Header, Divider, Label } from '../../common/form/';
import Guard from '../../common/Guard';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import NewMessageForm from './NewMessageForm';

class Messages extends Component {
    state = {
        hasRenderError: false,
        expanded: {},
        showNewMessageModal: false
    };
    columns = [
        {
            Header: 'Message',
            accessor: 'Message',
            Cell: row => (
                <div
                    className="flex w-full items-center h-full hover:underline cursor-pointer whitespace-no-wrap"
                    onClick={e => {
                        e.preventDefault();
                        this.openMessage(row.original);
                    }}
                >
                    {_.truncate(row.original.Message, { length: 28, omission: '...' })}
                </div>
            )
        },
        {
            Header: 'Recipients',
            accessor: 'Recipients',
            Cell: row => <div className="w-full whitespace-no-wrap">{row.original.Recipients.map(r => r.Name).join(', ')}</div>
        },
        {
            Header: 'Sent On',
            accessor: 'CreatedDate',
            Cell: row => (
                <div className="w-full text-center">
                    {moment
                        .utc(row.original.CreatedDate)
                        .local()
                        .format('MMMM DD YYYY, h:mm A')}
                </div>
            )
        },
        {
            Header: 'Sent By',
            accessor: 'SentBy'
        },
        {
            Header: '',
            id: 'Actions',
            filterable: false,
            sortable: false,
            accessor: d => d,
            Cell: row => (
                <Guard permissions={[roles.CaseMessages.VIEW_MESSAGES]}>
                    <div className="flex justify-center items-center h-full">
                        <span className="text-grey cursor-pointer" onClick={this.openMessage.bind(this, row.viewIndex)}>
                            <i className="fas fa-eye" />
                        </span>
                    </div>
                </Guard>
            )
        }
    ];

    componentDidMount() {
        const { caseId } = this.props;
        this.props.getMessages(caseId);
        this.props.getRecipients(caseId);
    }

    componentDidCatch() {
        this.setState({
            hasRenderError: true
        });
    }

    openMessage(rowIndex) {
        this.setState({
            ...this.state,
            expanded: {
                [rowIndex]: this.state.expanded[rowIndex] ? false : true
            }
        });
    }

    submitMessage = async values => {
        const { addMessage, caseId } = this.props;

        try {
            await addMessage({
                caseId: caseId,
                Message: values.Message,
                Recipients: values.Recipients && values.Recipients.map(r => r.Id)
            });

            this.setState({
                showNewMessageModal: false
            });
        } catch (error) {
            this.setState({
                showNewMessageModal: true,
                errors: error
            });
        }
    };
    render() {
        const { messages } = this.props;
        const { hasRenderError, expanded, showNewMessageModal } = this.state;

        if (hasRenderError) return <h1 className="text-primary text-base">Unable to show messages</h1>;

        return (
            <Guard permissions={[roles.CaseMessages.VIEW_MESSAGES]}>
                <div className="flex align-center justify-between">
                    <Header>Messages</Header>
                    <Guard permissions={[roles.CaseMessages.CREATE_MESSAGE]}>
                        <Button onClick={() => this.setState({ showNewMessageModal: true })}>Add Message</Button>
                    </Guard>
                </div>
                <Divider />
                <div className="mt-2" />
                <ReactTable
                    columns={this.columns}
                    data={messages}
                    getNoDataProps={() => ({ style: { display: 'none' } })}
                    showPagination={messages.length > 10}
                    expanded={expanded}
                    pageSize={messages.length < 10 ? messages.length : 10}
                    getTrProps={(state, rowInfo, column) => ({
                        onClick: () => {
                            this.openMessage(rowInfo.viewIndex);
                        }
                    })}
                    SubComponent={row => {
                        return (
                            <div className="sm:p-8 p-2">
                                <div className="flex flex-wrap -mx-3 mt-2">
                                    <div className="mb-4 flex-grow sm:w-1/2 px-3">
                                        <label className="text-primary font-semibold">Recipients</label>
                                        <p className="text-sm mt-1">{row.original.Recipients.map(r => r.Name).join(', ')}</p>
                                    </div>
                                    <div className="mb-4 flex-grow sm:w-1/2 px-3">
                                        <label className="text-primary font-semibold">Sent By</label>
                                        <p className="text-sm mt-1">{row.original.SentBy}</p>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-2">
                                    <label className="text-primary font-semibold w-full">Message</label>
                                    <p className="mt-1 w-full">{row.original.Message}</p>
                                </div>
                            </div>
                        );
                    }}
                />
                <Modal className="max-w-md" show={this.state.showNewMessageModal} onClose={() => this.setState({ showNewMessageModal: false })}>
                    {this.state.showNewMessageModal ? (
                        <NewMessageForm
                            onSubmit={this.submitMessage}
                            defaultValues={{}}
                            onClose={() => this.setState({ showNewMessageModal: false })}
                        />
                    ) : (
                            <></>
                        )}
                </Modal>
            </Guard>
        );
    }
}

const mapState = state => ({
    recipients: state.messages.recipients,
    messages: state.messages.messages
});

const mapDispatchToProps = dispatch => ({
    addMessage: dispatch.messages.addMessage,
    getMessages: caseId => dispatch.messages.getMessages(caseId),
    getRecipients: caseId => dispatch.messages.getRecipients(caseId)
});
export default connect(
    mapState,
    mapDispatchToProps
)(Messages);
