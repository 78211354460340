import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import { Form } from '../../common/form/Form';
import { EnumTypeSelector, DatePicker, Input, FetchSelectorConsumer } from '../../common/form';

const DEFAULT_CREATE_VALUES = {
    ItemType_Id: 0,
    ACD: null,
    Label: '',
    Address1: null,
    Address2: null,
    City: null,
    RegionType_Id: 0,
    PostalCode: null
};

class NewItemForm extends Component {
    createItem = values => {
        const { _case } = this.props;

        this.props
            .createItem(_case, {
                ItemType_Id: +values['ItemType_Id'],
                ACD: values['ACD'],
                Label: values['Label'],
                Address1: values['Address1'],
                Address2: values['Address2'],
                City: values['City'],
                RegionType_Id: +values['RegionType_Id'],
                PostalCode: values['PostalCode']
            })
            .then(() => this.props.closeModal(true))
            .catch(() => {});
    };

    render() {
        const { _case, creatingItem, errors, closeModal } = this.props;

        return (
            <>
                <Form defaultValues={{ ...DEFAULT_CREATE_VALUES }} errors={errors} onSubmit={this.createItem}>
                    <div className="flex -mx-2">
                        <div className="w-full px-2">
                            <FetchSelectorConsumer
                                url={`cases/${_case.Id}/items/available`}
                                name="ItemType_Id"
                                label="Item Template"
                                valueKey="Id"
                                textKey="Description"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-4">
                        <div className="w-full px-2">
                            <Input type="text" name="Label" label="Location" />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-8">
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Address1" label="Street" />
                        </div>
                        <div className="w-1/2 px-2">
                            <Input type="text" name="Address2" label="&nbsp;" />
                        </div>
                    </div>
                    <div className="flex -mx-2 mt-4">
                        <div className="w-1/3 px-2">
                            <Input type="text" name="City" label="City" />
                        </div>
                        <div className="w-1/3 px-2">
                            <EnumTypeSelector type="RegionType" name="RegionType_Id" label="State" allowBlank />
                        </div>
                        <div className="w-1/3 px-2">
                            <Input type="text" name="PostalCode" label="Postal Code" />
                        </div>
                    </div>
                    <div className="flex justify-end items-end mt-4">
                        <Button onClick={closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                            Cancel
                        </Button>
                        {!creatingItem ? (
                            <Button type="submit">Add Item</Button>
                        ) : (
                            <Button disabled>
                                Adding Item... <i className="fas fa-spinner fa-pulse" />
                            </Button>
                        )}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    creatingItem: state.items._updating._creatingItem,
    errors: (state.items._errors && state.items._errors.ModelState) || {}
});

const mapDispatchToProps = dispatch => ({
    createItem: (_case, item) => dispatch.items.createItem({ _case, item })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewItemForm);
