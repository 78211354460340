import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Orgs from './Orgs';
import Guard from '../common/Guard';
import NotFound from '../general/NotFound';
import AuthRoute from '../common/AuthRoute';

import { roles } from '../../constants';

const orgsRouter = () => (
    <Route key="orgs-router" path="/orgs">
        <Switch key="orgs-router-switch">
            <Guard key='orgs-route' path='/orgs' notFound={NotFound} permissions={[roles.Orgs.VIEW_ORGANIZATIONS]}>
                <AuthRoute path='/orgs' component={Orgs} />
            </Guard>
        </Switch>
    </Route>
);

export default orgsRouter;