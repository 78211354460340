import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getTypes = () => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.getAllTypesRequest());

    try {
        const types = await axios.get(`${baseUrl}/api/types`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        dispatch(Creators.getAllTypesSuccess(types.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getAllTypesFailure(
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
    }
};

const updateType = (type) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.updateTypeRequest(type));

    try {
        const _type = await axios.get(`${baseUrl}/api/types/${type}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        dispatch(Creators.updateTypeSuccess({ _type: type, values: _type.data }));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.updateTypeFailure({
            type,
            errors: error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        }));
    }
};

const addType = (_type) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.addTypeRequest());

    try {
        const newType = await axios.post(`${baseUrl}/api/types`, {
            TypeName: _type
        }, {
                headers: { 'Authorization': `Bearer ${access_token}` }
            });

        toast.success(`The list ${_type} was successfully created.`);
        dispatch(Creators.addTypeSuccess(newType.data));
        return Promise.resolve(newType.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.addTypeFailure(error.response.data));
        return Promise.reject(error.response.data);
    }
};

const editType = (_type, newName) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.editTypeRequest(_type.TableName));

    try {
        const updatedType = await axios.patch(`${baseUrl}/api/types/${_type.TableName}`, {
            TypeName: newName
        }, {
                headers: { 'Authorization': `Bearer ${access_token}` }
            });

        toast.success(`The list ${_type.TableName} was successfully renamed to ${newName}.`);
        dispatch(Creators.editTypeSuccess(_type.TableName, updatedType.data));
        return Promise.resolve(updatedType.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.editTypeFailure(_type.TableName, error.response.data));
        return Promise.reject(error.response.data);
    }
};

const deleteType = (_type) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.deleteTypeRequest(_type));

    try {
        await axios.delete(`${baseUrl}/api/types/${_type}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`The list ${_type} was successfully delete.`);
        dispatch(Creators.deleteTypeSuccess(_type));
        return Promise.resolve();
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.deleteTypeFailure(_type, error.response.data));
        return Promise.reject(error.response.data);
    }
};

const addItemType = (_type, item) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.addItemTypeRequest());

    try {
        const newItem = await axios.post(`${baseUrl}/api/types/${_type.TableName}/items`, item, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`${item.Short} was successfully added as a ${_type.FormattedTableName}.`);
        dispatch(Creators.addItemTypeSuccess(_type.TableName, newItem.data));
        return Promise.resolve(newItem.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.addItemTypeFailure(error.response.data));
        return Promise.reject(error.response.data);
    }
};

const editItemType = (_type, item) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.editItemTypeRequest());

    try {
        const updatedItem = await axios.patch(`${baseUrl}/api/types/${_type.TableName}/items/${item.Id}`, item, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`${item.Short} was successfully updated.`);
        dispatch(Creators.editItemTypeSuccess(_type.TableName, updatedItem.data));
        return Promise.resolve(updatedItem.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.editItemTypeFailure(error.response.data));
        return Promise.reject(error.response.data);
    }
};

const deleteItemType = (_type, item) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.deleteItemTypeRequest());

    try {
        await axios.delete(`${baseUrl}/api/types/${_type.TableName}/items/${item.Id}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`${item.Short} was successfully ${item.Hidden ? 'enabled' : 'disabled'}.`);
        dispatch(Creators.deleteItemTypeSuccess(_type.TableName, item));
        return Promise.resolve();
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.deleteItemTypeFailure(error.response.data));
        return Promise.reject(error.response.data);
    }
};

export default {
    addType,
    editType,
    getTypes,
    deleteType,
    updateType,
    addItemType,
    editItemType,
    deleteItemType
};