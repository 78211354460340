import React, { Component } from 'react';
import shortid from 'shortid';
import CustomCheckbox from './CustomCheckbox';
import { getScrollBarWidth } from '../../utils';

const TreeViewNode = ({ data, node, root, onCheckToggleCb, onUpdateCb }) => {
    const Node = node;
    const scrollBarWidth = getScrollBarWidth() + 10;
    if (data.children && data.children.length) {
        return (
            <Node data={data}>
                <div className="flex justify-between">
                    <label>
                        <CustomCheckbox
                            checked={data.isChecked || false}
                            onChange={e => {
                                data.isChecked = e.target.checked;
                                onCheckToggleCb && onCheckToggleCb([data]);
                                onUpdateCb && onUpdateCb(root, getCheckedValues([data]));
                            }}
                        />{' '}
                        {data.name}
                    </label>
                    {data.isExpanded ? (
                        <i
                            className="fa fa-angle-down text-2xl"
                            style={{ marginRight: scrollBarWidth }}
                            onClick={() => {
                                data.isExpanded = false;
                                onUpdateCb && onUpdateCb(root, getCheckedValues([data]));
                            }}
                        />
                    ) : (
                            <i
                                className="fa fa-angle-right text-2xl"
                                style={{ marginRight: scrollBarWidth }}
                                onClick={() => {
                                    data.isExpanded = true;
                                    onUpdateCb && onUpdateCb(root, getCheckedValues([data]));
                                }}
                            />
                        )}
                </div>

                <ul className="list-reset pl-8" style={{ display: data.isExpanded ? 'block' : 'none' }}>
                    {data.children.map((child, index) => (
                        <TreeViewNode key={`${index}-${shortid.generate()}`} data={child} node={node} onCheckToggleCb={onCheckToggleCb} onUpdateCb={onUpdateCb} root={root} />
                    ))}
                </ul>
            </Node>
        );
    }

    if (!!!data.name) return <></>;

    return (
        <Node data={data}>
            {' '}
            <label>
                <CustomCheckbox
                    checked={data.isChecked || false}
                    onChange={e => {
                        data.isChecked = e.target.checked;
                        onCheckToggleCb && onCheckToggleCb([data]);
                        onUpdateCb && onUpdateCb(root, getCheckedValues([data]));
                    }}
                />{' '}
                {data.name}
            </label>
        </Node>
    );
};

class CustomTreeView extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { data, node, onCheckToggleCb, onUpdateCb } = this.props;
        return (
            <ul className="list-reset">
                {data.map(v => (
                    <TreeViewNode key={`${v.id}-${shortid.generate()}`} data={v} node={node} onCheckToggleCb={onCheckToggleCb} onUpdateCb={onUpdateCb} root={data} />
                ))}
            </ul>
        );
    }
}

function getCheckedValues(data) {
    let checkedIds = [];

    data.forEach(values => {
        if (values.isChecked) checkedIds = [...checkedIds, values.id];
        if (values.children && values.children.length > 0) checkedIds = [...checkedIds, ...getCheckedValues(values.children)];
    });

    return checkedIds;
}

export default CustomTreeView;
