import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import qs from 'query-string';
import { baseUrl } from '../../config';

class ConfirmEmail extends Component {
    state = {
        loading: true,
        emailConfirmed: false
    };

    async componentDidMount() {
        const parsed = qs.parse(this.props.location.search);
        const code = decodeURI(parsed.code)
            .split(',')
            .pop();
        let userId = '';

        if (Array.isArray(parsed.userId)) {
            userId = parsed.userId.pop();
        } else {
            userId = parsed.userId;
        }
        try {
            this.setState({
                loading: true
            });

            await axios.post(`${baseUrl}/api/user/confirm`, {
                UserId: userId,
                Code: code
            });

            this.setState({
                loading: false,
                emailConfirmed: true
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: 'Unable to confirm email'
            });
        }
    }

    render() {
        if (this.state.emailConfirmed)
            return (
                <Redirect
                    to={{
                        pathname: '/change-password'
                    }}
                />
            );

        return (
            <div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
                <div className="w-2/3 md:w-1/2 lg:w-1/3">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        {this.state.loading ? (
                            <div className="flex items-center justify-center">
                                <i className="fas fa-spinner fa-pulse" />
                            </div>
                        ) : (
                            <>
                                {this.state.error ? (
                                    <p className="text-red">{this.state.error}</p>
                                ) : (
                                    <p>
                                        Thank you for confirming your email. Please{' '}
                                        <Link
                                            to="/login"
                                            className="appearance-none inline-block no-underline align-baseline font-bold text-sm text-primary hover:text-primary-dark mr-4"
                                        >
                                            Click here to log in
                                        </Link>
                                    </p>
                                )}
                            </>
                        )}
                    </div>

                    <p className="text-center text-grey text-xs">&copy;2018 Information Discovery Services.</p>
                </div>
            </div>
        );
    }
}

export default ConfirmEmail;
