import { change, arrayRemove } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import shortid from 'shortid';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../config';
import { makeCancelable, optional } from '../../../utils';
import { debug } from 'util';

export function handleInputChange(event) {
    this.setState({
        ...this.state,
        user: {
            ...this.state.user,
            [event.target.name]: event.target.value
        }
    });
}

export function orgSelected(org) {
    this.setState({
        ...this.state,
        user: {
            ...this.state.user,
            Org_Id: org.Id,
            Org: org
        },
        showOrgSelector: false
    });
}

export function updateContractTypes(contractTypes) {
    this.setState({
        ...this.state,
        user: {
            ...this.state.user,
            ContractTypes: contractTypes
        }
    });
}
export function showOrgSelector(event) {
    event.preventDefault();
    this.setState({ ...this.state, showOrgSelector: true });
}

export function closeOrgSelector(event) {
    event.preventDefault();
    this.setState({ ...this.state, showOrgSelector: false });
}

export function updateGroups(groups) {
    this.setState({
        ...this.state,
        user: {
            ...this.state.user,
            Groups: groups
        }
    });
}

export function addRow(key, obj) {
    this.setState({
        ...this.state,
        user: {
            ...this.state.user,
            [key]: [...(this.state.user[key] || []), obj]
        },
        currentAddress: -1
    });
}

export function removeRow(_, key, id, cb) {
    let rows = this.state.user[key] || [];
    let index = this.state.user[key].findIndex(el => el.Id == id);
    rows.splice(index, 1);

    this.setState(
        {
            ...this.state,
            user: {
                ...this.state.user,
                [key]: rows
            }
        },
        typeof cb === 'function' ? cb : () => {}
    );
}

export function openModal(index) {
    this.setState({
        showModal: true,
        currentAddress: index
    });
}

export function closeModal(e) {
    e && e.preventDefault();
    this.setState({
        showModal: false
    });
}

export function submitAddressForm(values) {
    let index = this.state.currentAddress;
    const addresses = this.state.user.Addresses || [];
    const address = {
        ...values,
        Id: shortid.generate(),
        IsPrimary: false
    };
    const State = (this.props.regions.Types || []).find(region => +region.Id === +address.RegionType_Id) || {};
    address.State = State.Short;

    if (index === -1) addresses.push(address);
    else addresses[index] = address;

    this.setState({
        ...this.state,
        showModal: false,
        user: {
            ...this.state.user,
            Addresses: addresses
        },
        currentAddress: -1
    });
}

export function submitUpdateForm(values, url, method) {
    this.createUserPromise = makeCancelable(
        new Promise(async (resolve, reject) => {
            try {
                const { access_token } = this.props;
                const { user } = this.state;
                let email = user.Email;
                let Emails = Object.keys(values.Emails || {})
                    .map(key => values.Emails[key])
                    .filter(email => email.Address);
                let Addresses = [];
                let PhoneNumbers = [];
                let Clearances = [];
                let Credentials = [];

                if (!user.Org_Id) {
                    throw 'You must choose an organization.';
                }

                if (Emails.length > 0) {
                    const primaryEmail = Emails.find(email => email.IsPrimary) || Emails[0];
                    email = primaryEmail.Address;
                    primaryEmail.IsPrimary = true;
                } else {
                    throw 'User needs at least one email.';
                }

                if (user.Addresses && user.Addresses.length > 0) {
                    Addresses = user.Addresses.map((address, index) => {
                        const { Id, ...rest } = address;
                        return { ...rest, ...(values.Addresses ? values.Addresses[Id] : {}) };
                    });
                }

                if (values.PhoneNumbers && user.PhoneNumbers && user.PhoneNumbers.length > 0) {
                    PhoneNumbers = user.PhoneNumbers.map(({ Id }) => values.PhoneNumbers[Id]);
                }

                if (values.Clearances && user.Clearances && user.Clearances.length > 0) {
                    Clearances = user.Clearances.map(({ Id }) => values.Clearances[Id]);
                }

                if (values.Credentials && user.Credentials && user.Credentials.length > 0) {
                    Credentials = user.Credentials.map(({ Id }) => values.Credentials[Id]);
                }

                this.setState({
                    ...this.state,
                    errors: null,
                    updatingUser: true
                });

                await axios({
                    method,
                    url: `${baseUrl}${url}`,
                    headers: { Authorization: `Bearer ${access_token}` },
                    data: {
                        Id: this.props.userId || null,
                        FirstName: values.FirstName,
                        MiddleName: values.MiddleName,
                        LastName: values.LastName,
                        UserName: values.UserName,
                        Title: values.Title,
                        Email: email,
                        Org_Id: values.Org_Id,
                        WorkStatusType_Id: values.WorkStatusType_Id ? +values.WorkStatusType_Id : null,
                        EmploymentStatusType_Id: values.EmploymentStatusType_Id ? +values.EmploymentStatusType_Id : null,
                        PINumber: user.PINumber,
                        CredNumber: user.CredNumber,
                        Manager_Id: values.Manager_Id,
                        ContractTypeIds: optional(values, target => target.ContractTypes, []).map(ct => ct.Id),
                        GroupIds: optional(user, target => target.Groups, []).map(g => g.Id),
                        PhoneNumbers,
                        Emails,
                        Addresses,
                        Credentials,
                        Clearances,
                        Notes: values.Notes,
                        GeoRegionType_Id: values.GeoRegionType_Id
                    }
                });

                this.props.onUpdated && this.props.onUpdated();
                toast.success(`${values.FirstName} ${values.LastName} was saved successfully.`);
                resolve();
            } catch (error) {
                console.log(error);
                if (error.response === undefined) toast.error(error || 'There was an error connecting to the server.');
                else if (optional(error, target => target.response.data.ModelState, false)) {
                    let errorMessages = [];
                    Object.keys(error.response.data.ModelState).forEach(key => {
                        errorMessages.push(error.response.data.ModelState[key].join());
                    });
                    _.uniq(errorMessages).forEach(message => toast.error(message));
                } else if (error.response.data) {
                    toast.error(error.response.data.Message);
                }
                this.setState({
                    ...this.state,
                    errors:
                        error.response !== undefined ? error.response.data : { error_description: 'There was an error connecting to the server.' },
                    updatingUser: false
                });
                reject();
            }
        })
    );

    return this.createUserPromise.promise;
}

export function expandRow(rowIndex) {
    this.setState({
        ...this.state,
        expanded: {
            [rowIndex]: this.state.expanded[rowIndex] ? false : true
        }
    });
}
