import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { XmlEntities } from 'html-entities';
import _ from 'lodash';

import { accountSelectors } from '../../account/duck';
import SectionForm from './SectionForm';
import Button from '../../common/Button';
import { Form, FormConsumer } from '../../common/form/Form';
import SectionEditModalClose from './SectionEditModalClose';
import { roles, GoalStatusType } from '../../../constants';

class SectionEditModal extends Component {
    state = {
        Definition: { fields: { field: [] } },
        unsavedChanges: false
    };
    formHandle = undefined;
    autoSaveTimeout = undefined;
    static getDerivedStateFromProps({ section }) {
        return {
            Definition: JSON.parse(section.Definition)
        };
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleEscapeKeyPress);
        clearTimeout(this.autoSaveTimeout);
    }

    updateSection = (values, { forced }) => {
        const { item, section } = this.props;

        this.prevValues = { ...values };

        this.props
            .updateSection(item.Case.Id, item.Id, section.Id, JSON.stringify(values['Definition']))
            .then(() => {
                if (!forced) {
                    this.setState({
                        ...this.state,
                        unsavedChanges: false
                    });
                    clearTimeout(this.autoSaveTimeout);
                } else {
                    this.props.closeModal();
                }
            })
            .catch(() => {
                clearTimeout(this.autoSaveTimeout);
            });
    };

    onUpdate() {
        clearTimeout(this.autoSaveTimeout);

        this.autoSaveTimeout = setTimeout(() => {
            this.formHandle && this.formHandle.onSubmit({ preventDefault: () => { } });
        }, 30000);
    }

    attemptClose = values => {
        const { Definition, unsavedChanges } = this.state;
        if (!_.isEqual(values['Definition'], Definition) && !unsavedChanges) {
            this.setState({
                ...this.state,
                unsavedChanges: true
            });
        } else {
            if (unsavedChanges) toast.info('Your changes have been discarded.');
            this.props.closeModal();
        }
    };

    handleEscapeKeyPress = (e, values) => {
        if (e.code === 'Escape') {
            const { Definition, unsavedChanges } = this.state;
            if (!_.isEqual(values['Definition'], Definition) && !unsavedChanges) {
                this.setState(
                    {
                        ...this.state,
                        unsavedChanges: true
                    },
                    () => {
                        toast.warning('You have unsaved changes, hit escape again to discard them.');
                    }
                );
            } else {
                if (unsavedChanges) toast.info('Your changes have been discarded.');
                this.props.closeModal();
            }
        }
    };

    render() {
        const { item, section, updatingSection, disabled } = this.props;
        const { Definition, unsavedChanges } = this.state;
        const inReview =
            item && item.ItemStatus.Short == GoalStatusType.IN_REVIEW && !this.props.hasPermission([roles.CaseItems.EDIT_ITEMS_IN_REVIEW]);

        return (
            <>
                <Form
                    provideConnection={formHandle => {
                        this.formHandle = formHandle;
                    }}
                    className="h-full"
                    defaultValues={{ Definition }}
                    onSubmit={this.updateSection}
                    onUpdate={this.onUpdate.bind(this)}
                    noValidate={true}
                >
                    <div className="flex h-screen-75 -mx-4">
                        <div className="w-1/2 px-4 overflow-y-auto">
                            <h1 className="text-lg text-primary mb-4">
                                {item.ItemType.Description}: {section.Section.SectionName} Form
                            </h1>
                            <SectionForm section={section} disabled={disabled} />
                        </div>
                        <div className="w-1/2 px-4 overflow-y-auto">
                            <h1 className="text-lg text-primary mb-4">
                                {item.ItemType.Description}: {section.Section.SectionName} Preview
                            </h1>
                            <div className="section-preview" dangerouslySetInnerHTML={{ __html: new XmlEntities().decode(section.Preview) }} />
                        </div>
                    </div>
                    <div className="flex justify-end items-end mt-4">
                        <FormConsumer>
                            {({ values }) => (
                                <SectionEditModalClose
                                    values={values}
                                    unsavedChanges={unsavedChanges}
                                    handleEscapeKeyPress={this.handleEscapeKeyPress}
                                    attemptClose={this.attemptClose}
                                />
                            )}
                        </FormConsumer>
                        {!disabled && (!updatingSection ? (
                            <>
                                <Button type="submit" className="mr-2" disabled={inReview}>
                                    Save and Update
                                </Button>
                                <FormConsumer>
                                    {({ forceSubmit }) => (
                                        <Button onClick={() => forceSubmit()} disabled={inReview}>
                                            Save and Close
                                        </Button>
                                    )}
                                </FormConsumer>
                            </>
                        ) : (
                                <Button disabled>
                                    Saving Changes... <i className="fas fa-spinner fa-pulse" />
                                </Button>
                            ))}
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    updatingSection: state.item._updating._updatingSection,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});
const mapDispatchToProps = dispatch => ({
    updateSection: (caseId, itemId, sectionId, Definition) => dispatch.item.updateSection({ caseId, itemId, sectionId, Definition })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SectionEditModal);
