import types from './types';
import { createReducer } from '../../../utils';
import storage from 'redux-persist/lib/storage';

export const accountPersistConfig = {
    key: 'account',
    storage: storage,
    blacklist: ['errors', 'address', 'phoneNumbers', '_updating']
}

const initialState = {
    auth: null,
    user: null,
    address: null,
    phoneNumbers: [],
    errors: null,
    permissions: null,
    _updating: {
        _loggingIn: false,
        _updatingUser: false,
        _updatingPassword: false,
        _updatingAddress: false,
        _updatingPhoneNumbers: false,
        _creatingPhoneNumber: false
    }
};

const account = createReducer(initialState, {
    [types.LOG_IN_REQUEST]: (state) => ({ ...state, _updating: { ...state._updating, _loggingIn: true } }),
    [types.LOG_IN_SUCCESS]: (state, { payload: auth }) => ({ ...state, auth, errors: null }),
    [types.LOG_IN_FAILURE]: (_, { payload: errors }) => ({ ...initialState, errors }),
    [types.LOG_IN_COMPLETE]: (state) => ({ ...state, _updating: { ...state._updating, _loggingIn: false } }),
    [types.LOG_OUT]: (_) => initialState,

    [types.LOAD_USER_REQUEST]: (state) => ({ ...state, user: null }),
    [types.LOAD_USER_SUCCESS]: (state, { payload: user }) => ({ ...state, user, errors: null }),
    [types.LOAD_USER_FAILURE]: (_, { payload: errors }) => ({ ...initialState, errors }),

    [types.LOAD_USER_PERMISSIONS_REQUEST]: (state) => ({ ...state, permissions: null }),
    [types.LOAD_USER_PERMISSIONS_SUCCESS]: (state, { payload: permissions }) => ({ ...state, permissions, errors: null }),
    [types.LOAD_USER_PERMISSIONS_FAILURE]: (_, { payload: errors }) => ({ ...initialState, errors }),

    [types.LOAD_USER_ADDRESS_REQUEST]: (state) => ({ ...state, address: null, _updating: { ...state._updating, _updatingAddress: true } }),
    [types.LOAD_USER_ADDRESS_SUCCESS]: (state, { payload: address }) => ({
        ...state,
        address,
        errors: null,
        _updating: { ...state._updating, _updatingAddress: false }
    }),
    [types.LOAD_USER_ADDRESS_FAILURE]: (state, { payload: errors }) => ({
        ...initialState,
        errors,
        _updating: { ...state._updating, _updatingAddress: false }
    }),

    [types.LOAD_USER_PHONE_NUMBERS_REQUEST]: (state) => ({ ...state, phoneNumbers: [], _updating: { ...state._updating, _updatingPhoneNumbers: true } }),
    [types.LOAD_USER_PHONE_NUMBERS_SUCCESS]: (state, { payload: phoneNumbers }) => ({
        ...state,
        phoneNumbers,
        errors: null,
        _updating: { ...state._updating, _updatingPhoneNumbers: false }
    }),
    [types.LOAD_USER_PHONE_NUMBERS_FAILURE]: (state, { payload: errors }) => ({
        ...initialState,
        errors,
        _updating: { ...state._updating, _updatingPhoneNumbers: false }
    }),

    [types.UPDATE_USER_REQUEST]: (state) => ({ ...state, _updating: { ...state._updating, _updatingUser: true } }),
    [types.UPDATE_USER_SUCCESS]: (state, { payload: user }) => ({
        ...state,
        user,
        errors: null,
        _updating: { ...state._updating, _updatingUser: false }
    }),
    [types.UPDATE_USER_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _updatingUser: false }
    }),

    [types.UPDATE_USER_ADDRESS_REQUEST]: (state) => ({ ...state, _updating: { ...state._updating, _updatingAddress: true } }),
    [types.UPDATE_USER_ADDRESS_SUCCESS]: (state, { payload: address }) => ({
        ...state,
        address,
        errors: null,
        _updating: { ...state._updating, _updatingAddress: false }
    }),
    [types.UPDATE_USER_ADDRESS_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _updatingAddress: false }
    }),

    [types.UPDATE_USER_PHONE_REQUEST]: (state, { payload: phoneId }) => ({
        ...state,
        _updating: { ...state._updating, [`phone-${phoneId}`]: true, _updatingPhoneNumbers: true }
    }),
    [types.UPDATE_USER_PHONE_SUCCESS]: (state, { payload: { phoneId, phoneNumbers } }) => ({
        ...state,
        phoneNumbers,
        errors: null,
        _updating: { ...state._updating, [`phone-${phoneId}`]: false, _updatingPhoneNumbers: false }
    }),
    [types.UPDATE_USER_PHONE_FAILURE]: (state, { payload: { phoneId, errors } }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, [`phone-${phoneId}`]: false, _updatingPhoneNumbers: false }
    }),

    [types.CREATE_USER_PHONE_REQUEST]: (state) => ({ ...state, _updating: { ...state._updating, _creatingPhoneNumber: true } }),
    [types.CREATE_USER_PHONE_SUCCESS]: (state, { payload: phoneNumbers }) => ({
        ...state,
        phoneNumbers,
        errors: null,
        _updating: { ...state._updating, _creatingPhoneNumber: false }
    }),
    [types.CREATE_USER_PHONE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _creatingPhoneNumber: false }
    }),

    [types.DELETE_USER_PHONE_REQUEST]: (state, { payload: phoneId }) => ({
        ...state,
        _updating: { ...state._updating, [`deleting-phone-${phoneId}`]: true, _updatingPhoneNumbers: true }
    }),
    [types.DELETE_USER_PHONE_SUCCESS]: (state, { payload: { phoneId, phoneNumbers } }) => ({
        ...state,
        phoneNumbers,
        errors: null,
        _updating: { ...state._updating, [`deleting-phone-${phoneId}`]: false, _updatingPhoneNumbers: false }
    }),
    [types.DELETE_USER_PHONE_FAILURE]: (state, { payload: { phoneId, errors } }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, [`deleting-phone-${phoneId}`]: false, _updatingPhoneNumbers: false }
    }),

    [types.UPDATE_PASSWORD_REQUEST]: (state) => ({ ...state, _updating: { ...state._updating, _updatingPassword: true } }),
    [types.UPDATE_PASSWORD_SUCCESS]: (state) => ({ ...state, errors: null, _updating: { ...state._updating, _updatingPassword: false } }),
    [types.UPDATE_PASSWORD_FAILURE]: (state, { payload: errors }) => ({ ...state, errors, _updating: { ...state._updating, _updatingPassword: false } })
});

export default account;