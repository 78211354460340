const GET_ROLES_REQUEST = 'app/identity/GET_ROLES_REQUEST';
const GET_ROLES_SUCCESS = 'app/identity/GET_ROLES_SUCCESS';
const GET_ROLES_FAILURE = 'app/identity/GET_ROLES_FAILURE';

const CREATE_ROLE_REQUEST = 'app/identity/CREATE_ROLE_REQUEST';
const CREATE_ROLE_SUCCESS = 'app/identity/CREATE_ROLE_SUCCESS';
const CREATE_ROLE_FAILURE = 'app/identity/CREATE_ROLE_FAILURE';

const UPDATE_ROLE_REQUEST = 'app/identity/UPDATE_ROLE_REQUEST';
const UPDATE_ROLE_SUCCESS = 'app/identity/UPDATE_ROLE_SUCCESS';
const UPDATE_ROLE_FAILURE = 'app/identity/UPDATE_ROLE_FAILURE';

const DELETE_ROLE_REQUEST = 'app/identity/DELETE_ROLE_REQUEST';
const DELETE_ROLE_SUCCESS = 'app/identity/DELETE_ROLE_SUCCESS';
const DELETE_ROLE_FAILURE = 'app/identity/DELETE_ROLE_FAILURE';

const GET_GROUPS_REQUEST = 'app/identity/GET_GROUPS_REQUEST';
const GET_GROUPS_SUCCESS = 'app/identity/GET_GROUPS_SUCCESS';
const GET_GROUPS_FAILURE = 'app/identity/GET_GROUPS_FAILURE';

const UPDATE_GROUP_REQUEST = 'app/identity/UPDATE_GROUP_REQUEST';
const UPDATE_GROUP_SUCCESS = 'app/identity/UPDATE_GROUP_SUCCESS';
const UPDATE_GROUP_FAILURE = 'app/identity/UPDATE_GROUP_FAILURE';

const DELETE_GROUP_REQUEST = 'app/identity/DELETE_GROUP_REQUEST';
const DELETE_GROUP_SUCCESS = 'app/identity/DELETE_GROUP_SUCCESS';
const DELETE_GROUP_FAILURE = 'app/identity/DELETE_GROUP_FAILURE';

const GET_CATEGORIES_REQUEST = 'app/identity/GET_CATEGORIES_REQUEST';
const GET_CATEGORIES_SUCCESS = 'app/identity/GET_CATEGORIES_SUCCESS';
const GET_CATEGORIES_FAILURE = 'app/identity/GET_CATEGORIES_FAILURE';

const ADD_ROLE_TO_GROUP_REQUEST = 'app/identity/ADD_ROLE_TO_GROUP_REQUEST';
const ADD_ROLE_TO_GROUP_SUCCESS = 'app/identity/ADD_ROLE_TO_GROUP_SUCCESS';
const ADD_ROLE_TO_GROUP_FAILURE = 'app/identity/ADD_ROLE_TO_GROUP_FAILURE';

const REMOVE_ROLE_FROM_GROUP_REQUEST = 'app/identity/REMOVE_ROLE_FROM_GROUP_REQUEST';
const REMOVE_ROLE_FROM_GROUP_SUCCESS = 'app/identity/REMOVE_ROLE_FROM_GROUP_SUCCESS';
const REMOVE_ROLE_FROM_GROUP_FAILURE = 'app/identity/REMOVE_ROLE_FROM_GROUP_FAILURE';

const GET_USERS_REQUEST = 'app/identity/GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'app/identity/GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'app/identity/GET_USERS_FAILURE';

const GET_USER_FROM_USERS = 'app/identity/GET_USER_FROM_USERS';

const GET_TAGS_SUCCESS = 'app/identity/GET_TAGS_SUCCESS';

export default {
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,

    CREATE_ROLE_REQUEST,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,

    UPDATE_ROLE_REQUEST,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAILURE,

    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,

    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILURE,

    UPDATE_GROUP_REQUEST,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILURE,

    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,

    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILURE,

    ADD_ROLE_TO_GROUP_REQUEST,
    ADD_ROLE_TO_GROUP_SUCCESS,
    ADD_ROLE_TO_GROUP_FAILURE,

    REMOVE_ROLE_FROM_GROUP_REQUEST,
    REMOVE_ROLE_FROM_GROUP_SUCCESS,
    REMOVE_ROLE_FROM_GROUP_FAILURE,

    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    GET_USER_FROM_USERS,

    GET_TAGS_SUCCESS
};
