import React, { Component } from 'react';
import shortid from 'shortid';
import _ from 'lodash';

import { FormConsumer } from '../../common/form/Form';
import { HandleUnmount, ValidationError } from '../../common/form';
import {
    CheckboxSectionField,
    AddressSectionField,
    TextSectionField,
    PhoneNumberSectionField,
    DateSectionField,
    TextareaSectionField,
    ListSectionField
} from './SectionFields';
import Button from '../../common/Button';

const TYPES_TO_COMPONENT = {
    checkbox: CheckboxSectionField,
    address: AddressSectionField,
    text: TextSectionField,
    phoneNumber: PhoneNumberSectionField,
    date: DateSectionField,
    textarea: TextareaSectionField,
    list: ListSectionField
};

class SectionForm extends Component {
    state = {
        renderError: false
    };

    componentDidCatch() {
        this.setState({
            renderError: true
        });
    }

    onChange = (field, name, value, cb = () => { }) => {
        const { values, setValue } = this.props;
        setValue(
            'Definition',
            {
                fields: {
                    field: values['Definition'].fields.field.map(f =>
                        f['@id'] !== field['@id']
                            ? f
                            : {
                                ...f,
                                [name]: value.toString()
                            }
                    )
                }
            },
            cb
        );
    };

    addTemplate = (field, cb = () => { }) => {
        const { values, setValue } = this.props;

        setValue(
            'Definition',
            {
                fields: {
                    field: values['Definition'].fields.field.map(f =>
                        f['@id'] !== field['@id']
                            ? f
                            : {
                                ...f,
                                Entries: [
                                    ...(f.Entries || []),
                                    {
                                        '@id': shortid.generate(),
                                        ...f.Template
                                    }
                                ]
                            }
                    )
                }
            },
            cb
        );
    };

    removeTemplate = (field, entry, cb = () => { }) => {
        const { values, setValue } = this.props;

        setValue(
            'Definition',
            {
                fields: {
                    field: values['Definition'].fields.field.map(f =>
                        f['@id'] !== field['@id']
                            ? f
                            : {
                                ...f,
                                Entries: f.Entries.filter(e => e['@id'] !== entry['@id'])
                            }
                    )
                }
            },
            cb
        );
    };

    onSubChange = (parentField, entry, field, name, value, cb = () => { }) => {
        const { values, setValue } = this.props;
        setValue(
            'Definition',
            {
                fields: {
                    field: values['Definition'].fields.field.map(f =>
                        f['@id'] !== parentField['@id']
                            ? f
                            : {
                                ...f,
                                Entries: f.Entries.map(e =>
                                    e['@id'] !== entry['@id']
                                        ? e
                                        : {
                                            ...e,
                                            fields: {
                                                field: e.fields.field.map(ef =>
                                                    ef['@id'] !== field['@id']
                                                        ? ef
                                                        : {
                                                            ...ef,
                                                            [name]: value.toString()
                                                        }
                                                )
                                            }
                                        }
                                )
                            }
                    )
                }
            },
            cb
        );
    };

    render() {
        const { values, section, disabled } = this.props;
        const fields = values['Definition'].fields.field;

        if (this.state.renderError) return 'Unable to display section.';

        return (
            <>
                {fields.map(field => {
                    const Component = TYPES_TO_COMPONENT[field['@type']] || undefined;

                    return field['@type'] === 'template' ? (
                        this.renderTemplate(field)
                    ) : Component ? (
                        <Component section={section} key={`field-${field['@id']}`} field={field} onChange={this.onChange} disabled={disabled} />
                    ) : (
                                `Missing field for type ${field['@type']}`
                            );
                })}
            </>
        );
    }

    renderTemplate = templateField => {
        const entries = templateField.Entries || [];
        const { section, disabled } = this.props;

        return (
            <div key={templateField['@id']} className="mb-4">
                <div className="block text-sm font-bold text-primary mb-2">
                    <div className="flex items-center">
                        <div className="mr-4">{templateField['@label']}</div>
                        <Button inverse onClick={e => this.addTemplate(templateField)} disabled={disabled}>
                            Add
                        </Button>
                    </div>
                    {templateField['@instructions'] ? (
                        <>
                            <br />
                            <span className="text-sm font-normal text-grey-dark italic block">{templateField['@instructions']}</span>
                        </>
                    ) : (
                            <></>
                        )}
                </div>
                {entries.map(e => (
                    <div key={e['@id']}>
                        {e.fields.field.map(field => {
                            const Component = TYPES_TO_COMPONENT[field['@type']] || undefined;
                            const updateFunction = _.curry(this.onSubChange)(templateField, e);
                            return Component ? (
                                <Component
                                    section={section}
                                    key={`field-${field['@id']}`}
                                    field={field}
                                    onChange={updateFunction}
                                    disabled={disabled}
                                />
                            ) : (
                                    `Missing field for type ${field['@type']}`
                                );
                        })}
                        <div className="flex justify-end items-center">
                            <Button disabled={disabled} inverse className="text-red hover:text-red-darker" onClick={_ => this.removeTemplate(templateField, e)}>
                                Remove
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
}

export default props => (
    <FormConsumer>
        {({ errors = {}, values, setValue, removeValue, name }) => (
            <HandleUnmount handleUnmount={() => removeValue(name)}>
                <SectionForm {...props} values={values} setValue={setValue} />
                <ValidationError>{errors[name]}</ValidationError>
            </HandleUnmount>
        )}
    </FormConsumer>
);
