import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { toast } from 'react-toastify';

import Modal from '../common/Modal';
import { baseUrl } from '../../config';
import { buildOrgTree, getCheckedOrgs, makeCancelable, buildGroupTree } from '../../utils';
import { identityOperations } from './duck';
import { orgOperations, orgSelectors } from '../orgs/duck';
import UserEditor from './components/UserEditor';
import UserCreator from './components/UserCreator';
import { expandRow } from './components/methods';
import { orgTreeUpdated, orgTreeToggled } from '../common/methods';
import Button from '../common/Button';
import { optional } from '../../utils/index';
import SideNav from '../general/SideNav';
import PageHeader from '../common/PageHeader';
import SideNavMenuItem from '../general/SideNavItem';
import CustomTreeView from '../common/CustomTreeView';
import CustomCheckbox from '../common/CustomCheckbox';

class Users extends Component {
    state = {
        additional: {
            OrgIds: [],
            HideIDSUsers: false,
            GroupIds: []
        },
        expanded: {},
        orgs: [],
        groups: [],
        pages: null,
        showUserCreator: false,
        defaultGroups: [],
        usersProcessing: {}
    };

    constructor(props) {
        super(props);
        this.expandRow = expandRow.bind(this);
        this.orgTreeUpdated = orgTreeUpdated.bind(this);
        this.orgTreeToggled = orgTreeToggled.bind(this);
    }

    static getDerivedStateFromProps({ loadingOrgs, orgs, groups, tags }, prevState) {
        // Skip if we already generated the trees
        if (!optional(prevState.orgs, o => o[0].isExpanded) || !optional(prevState.groups, g => g[0].isExpanded)) {
            const orgTree = buildOrgTree(orgs, orgs[0]);
            orgTree.isExpanded = true;
            let groupTree = [];

            if (Array.isArray(groups) && Array.isArray(tags)) {
                groupTree = buildGroupTree(tags, groups);
                groupTree.isExpanded = true;
            }

            return loadingOrgs
                ? null
                : {
                      ...prevState,
                      orgs: [orgTree],
                      groups: [groupTree]
                  };
        }

        return null;
    }

    componentDidMount() {
        this.props.getTags();
        this.props.getGroups();
        if (this.props.shouldUpdate && !this.props.loadingOrgs) this.props.updateOrgs();
    }

    refreshData = (closeRows = true) => {
        this.setState(
            {
                ...this.state,
                showUserCreator: false,
                expanded: closeRows ? {} : this.state.expanded
            },
            () => {
                this.fetchData(this.state, null);
            }
        );
    };

    fetchData = _.debounce(async state => {
        const { pageSize, page, sorted, filtered } = state || this.state;
        try {
            const pages = await this.props.getUsers({ pageSize, page, sorted, filtered, additional: this.state.additional });
            this.setState({ ...state, pages });
        } catch (e) {}
    }, 500);

    groupTreeUpdated = updatedData => {
        const {
            additional: { GroupIds }
        } = this.state;

        this.setState(
            {
                ...this.state,
                groups: updatedData,
                additional: {
                    ...this.state.additional,
                    GroupIds: getCheckedOrgs(updatedData).filter(id => typeof id === 'string')
                }
            },
            () => {
                if (!_.isEqual(GroupIds, this.state.additional.GroupIds)) this.refreshData();
            }
        );
    };

    handleAdditionalCheckbox = event => {
        this.setState(
            {
                ...this.state,
                additional: {
                    ...this.state.additional,
                    [event.target.name]: event.target.checked
                }
            },
            () => {
                this.refreshData();
            }
        );
    };

    openUserCreator = (showSettings = false, groups = []) => {
        const defaultGroups = this.props.groups.filter(group => groups.includes(group.Name));
        this.setState({
            ...this.state,
            showUserCreator: true,
            showSettings,
            defaultGroups
        });
    };

    closeUserCreator = () => {
        this.setState({
            ...this.state,
            showUserCreator: false
        });
    };

    deleteUser = (e, user) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        this.setState({
            userToDelete: user
        });
    };
    cancelDeleteUser = () => {
        this.setState({
            userToDelete: undefined
        });
    };
    confirmDeleteUser = () => {
        const { access_token } = this.props;
        const user = this.state.userToDelete;

        this.deleteUserPromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        errors: null,
                        deletingUser: true,
                        userToDelete: undefined,
                        ...{ usersProcessing: { [user.Id]: true } }
                    },
                    async () => {
                        try {
                            await axios.delete(`${baseUrl}/api/identity/users/${user.Id}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            toast.success(`${user.FirstName} ${user.LastName} was deleted successfully.`);

                            this.setState(
                                {
                                    ...this.state,
                                    deletingUser: false,
                                    ...{ usersProcessing: { [user.Id]: false } }
                                },
                                this.refreshData
                            );
                        } catch (error) {
                            if (error.response) toast.error(optional(error, e => e.response.data.Message, '') || 'Unable to delete user.');
                            this.setState({
                                ...this.state,
                                errors:
                                    error.response !== undefined
                                        ? error.response.data
                                        : { error_description: 'There was an error connecting to the server.' },
                                deletingUser: false,
                                ...{ usersProcessing: { [user.Id]: false } }
                            });
                        }
                    }
                );
            })
        );
    };

    clearFilters = () => {
        function unCheck(node) {
            node.isChecked = false;

            if (node.children) {
                node.children.forEach(child => unCheck(child));
            }
        }

        let { groups, orgs } = this.state;
        groups.forEach(node => unCheck(node));
        orgs.forEach(node => unCheck(node));

        this.setState(
            {
                ...this.state,
                additional: {
                    OrgIds: [],
                    HideIDSUsers: false,
                    Active: false,
                    Inactive: false,
                    GroupIds: []
                },
                groups: [],
                orgs: []
            },
            () =>
                this.setState(
                    {
                        groups,
                        orgs
                    },
                    () => this.fetchData(this.state)
                )
        );
    };

    render() {
        const { users, loading, loadingOrgs } = this.props;
        const { additional, orgs, groups, expanded, pages, showUserCreator, defaultGroups, userToDelete } = this.state;
        const expandedIndex = Object.keys(expanded).find(k => !!expanded[k]);

        return (
            <>
                <SideNav icons={['fa-address-book', 'fa-users', 'fa-user']}>
                    <div>
                        <div className="mb-4">
                            <Button className="m-auto" inverse onClick={this.clearFilters}>
                                Clear Filters
                            </Button>
                        </div>
                        <SideNavMenuItem icon="fa-address-book" title="Orgs">
                            <Scrollbars autoHeight autoHeightMin={300}>
                                {loadingOrgs ? (
                                    <span>
                                        Loading orgs...
                                        {'\u00A0'}
                                        {'\u00A0'}
                                        <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                ) : (
                                    <CustomTreeView
                                        data={orgs}
                                        onUpdateCb={(updatedData, checkedValues) => {
                                            const oldIds = this.state.additional.OrgIds;
                                            this.setState(
                                                {
                                                    ...this.state,
                                                    orgs: updatedData,
                                                    additional: {
                                                        ...this.state.additional,
                                                        OrgIds: checkedValues
                                                    }
                                                },
                                                () => {
                                                    if (!_.isEqual(checkedValues, oldIds)) this.refreshData();
                                                }
                                            );
                                        }}
                                        onCheckToggleCb={nodes => {
                                            const timeDiff = Date.now() - nodes[0].lastChecked;
                                            const checkState = timeDiff <= 300 || nodes[0].isChecked;
                                            nodes[0].lastChecked = Date.now();
                                            const OrgIds = [];

                                            const applyCheckStateTo = nodes => {
                                                nodes.forEach(node => {
                                                    node.isChecked = checkState;
                                                    if (checkState) OrgIds.push(node.id);
                                                    if (node.children && (timeDiff <= 300 || checkState === false)) applyCheckStateTo(node.children);
                                                });
                                            };

                                            applyCheckStateTo(nodes);
                                        }}
                                        node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                                    />
                                )}
                            </Scrollbars>
                        </SideNavMenuItem>
                        <SideNavMenuItem icon="fa-users" title="Groups">
                            <CustomTreeView
                                header="Groups"
                                data={groups}
                                onUpdateCb={(updatedData, checkedValues) => {
                                    const oldIds = this.state.additional.GroupIds;
                                    this.setState(
                                        {
                                            ...this.state,
                                            groups: updatedData,
                                            additional: {
                                                ...this.state.additional,
                                                GroupIds: checkedValues
                                            }
                                        },
                                        () => {
                                            if (!_.isEqual(checkedValues, oldIds)) this.refreshData();
                                        }
                                    );
                                }}
                                onCheckToggleCb={nodes => {
                                    const checkState = nodes[0].isChecked;

                                    const applyCheckStateTo = nodes => {
                                        nodes.forEach(node => {
                                            node.isChecked = checkState;
                                            if (node.children) applyCheckStateTo(node.children);
                                        });
                                    };

                                    applyCheckStateTo(nodes);
                                }}
                                node={({ children }) => <li className={`cursor-pointer pt-2 pb-2 text-white`}>{children}</li>}
                            />
                        </SideNavMenuItem>
                        <SideNavMenuItem icon="fa-user" title="Additional">
                            <div>
                                <label className="block text-primary ml-4 py-2 text-white">
                                    <CustomCheckbox
                                        className="mr-2"
                                        name="HideIDSUsers"
                                        checked={additional.HideIDSUsers}
                                        onChange={this.handleAdditionalCheckbox}
                                    />
                                    Hide IDS Users
                                </label>
                                <label className="block text-primary ml-4 py-2 text-white">
                                    <CustomCheckbox
                                        className="mr-2"
                                        name="Active"
                                        checked={!!additional.Active}
                                        onChange={this.handleAdditionalCheckbox}
                                    />
                                    Active
                                </label>
                                <label className="block text-primary ml-4 py-2 text-white">
                                    <CustomCheckbox
                                        className="mr-2"
                                        name="Inactive"
                                        checked={!!additional.Inactive}
                                        onChange={this.handleAdditionalCheckbox}
                                    />
                                    Inactive
                                </label>
                            </div>
                        </SideNavMenuItem>
                    </div>
                </SideNav>
                <div className="flex">
                    <div className="w-full ml-12">
                        <div className="flex justify-between items-center w-full">
                            <PageHeader>Users</PageHeader>
                            <div className="flex sm:mr-4">
                                <Button onClick={this.openUserCreator.bind(this, true, ['Investigator'])} inverse>
                                    <i className="fas fa-user-plus" />
                                    <span className="ml-2">New Employee</span>
                                </Button>
                                <Button onClick={this.openUserCreator.bind(this, true, ['Independent Contractor'])} inverse className="ml-2">
                                    <i className="fas fa-user-plus" />
                                    <span className="ml-2">New Contractor</span>
                                </Button>
                                <Button onClick={this.openUserCreator.bind(this, false, ['Client'])} inverse className="ml-2">
                                    <i className="fas fa-user-plus" />
                                    <span className="ml-2">New Client</span>
                                </Button>
                            </div>
                        </div>
                        <div className="bg-white rounded shadow pl-4 pb-4 mb-8 overflow-x-auto">
                            <ReactTable
                                columns={[
                                    { Header: 'ID', accessor: 'UserName' },
                                    {
                                        Header: 'First Name',
                                        accessor: 'FirstName',
                                        Cell: row => (
                                            <div className="flex items-center">
                                                {row.original.WorkStatusType ? (
                                                    <div
                                                        className={`bg-${row.original.WorkStatusType.Short.toLowerCase()} inline-block h-2 w-2 rounded-full mr-3`}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                {row.value}
                                            </div>
                                        )
                                    },
                                    { Header: 'Last Name', accessor: 'LastName' },
                                    { Header: 'Email', accessor: 'Email' },
                                    {
                                        Header: 'Organization',
                                        id: 'Org.Name',
                                        accessor: d => d.Org,
                                        Cell: row => (
                                            <div>
                                                {row.value ? row.value.Name : <span className="text-small text-grey italic">No organization</span>}
                                            </div>
                                        )
                                    },
                                    {
                                        Header: '',
                                        id: 'Actions',
                                        filterable: false,
                                        sortable: false,
                                        accessor: d => d,
                                        Cell: row => (
                                            <div className="flex justify-center items-center h-full">
                                                {this.state.usersProcessing[row.original.Id] ? (
                                                    <i className="fas fa-spinner fa-pulse" />
                                                ) : (
                                                    <>
                                                        <span className="cursor-pointer mr-4 text-grey">
                                                            <i className="fas fa-pencil-alt" />
                                                        </span>
                                                        <span className="text-red-light cursor-pointer" onClick={e => this.deleteUser(e, row.value)}>
                                                            <i className="fas fa-trash-alt" />
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    }
                                ]}
                                manual
                                filterable
                                data={users}
                                pages={pages}
                                loading={loading}
                                onFetchData={this.fetchData}
                                filterable
                                defaultPageSize={20}
                                defaultSorted={[{ id: 'FirstName', desc: false }, { id: 'LastName', desc: false }]}
                                expanded={expanded}
                                SubComponent={row => {
                                    return (
                                        <div className="px-4 py-4">
                                            <UserEditor onUpdated={this.refreshData} userId={row.original.Id} />
                                        </div>
                                    );
                                }}
                                getTrProps={(state, rowInfo, column) => ({
                                    onClick: e => {
                                        this.expandRow(rowInfo.viewIndex);
                                    }
                                })}
                                getTdProps={(state, rowInfo) => ({
                                    className: `${typeof expandedIndex === 'string' && !!!expanded[rowInfo && rowInfo.index] ? `opacity-25` : ''}`
                                })}
                                getTrGroupProps={(state, rowInfo, column) => ({
                                    className:
                                        (rowInfo && expanded[rowInfo.index] ? 'shadow-lg my-2 bg-white ' : '') +
                                        (rowInfo &&
                                        rowInfo.original.LockoutEndDateUtc &&
                                        moment().isBefore(moment(rowInfo.original.LockoutEndDateUtc))
                                            ? 'border-l-2 border-red-light '
                                            : rowInfo && rowInfo.original
                                            ? 'border-l-2 border-green-light'
                                            : 'border-l-2 border-white'),
                                    style: rowInfo && expanded[rowInfo.index] ? { transform: 'translayeY(-5px)' } : { transition: 'all 0.25s' }
                                })}
                                getTheadProps={() => ({
                                    style: { background: 'white' }
                                })}
                                getTheadFilterProps={() => ({
                                    style: { background: 'white' }
                                })}
                            />
                        </div>
                    </div>
                </div>
                {showUserCreator ? (
                    <UserCreator
                        onUpdated={this.refreshData}
                        close={this.closeUserCreator}
                        showSettings={this.state.showSettings}
                        defaultGroups={defaultGroups}
                    />
                ) : (
                    <></>
                )}

                <Modal show={userToDelete} onClose={this.cancelDeleteUser} className="max-w-md">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                    </h2>
                    <p>
                        {`Delete ${userToDelete && userToDelete.FirstName} ${userToDelete && userToDelete.LastName}?`} This action cannot be undone.
                    </p>
                    <div className="flex w-full">
                        <Button className="bg-grey ml-auto mr-2" onClick={this.cancelDeleteUser}>
                            Cancel
                        </Button>
                        <Button className="text-red border-red" onClick={this.confirmDeleteUser}>
                            Delete User
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.identity._updating._gettingUsers,
    shouldUpdate: orgSelectors.shouldUpdateOrgs(state.orgs),
    loadingOrgs: state.orgs._updating._gettingOrgs,
    orgs: state.orgs.orgs,
    users: state.identity.users,
    groups: state.identity.groups,
    tags: state.identity.tags,
    access_token: state.account.auth.access_token
});

const mapDispatchToProps = dispatch => ({
    getUsers: search => dispatch(identityOperations.getUsers(search)),
    updateOrgs: () => dispatch(orgOperations.getOrgs()),
    getGroups: () => dispatch(identityOperations.getGroups()),
    getTags: () => dispatch(identityOperations.getTags())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
