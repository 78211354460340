import types from './types';
import { createReducer } from '../../../utils';

const initialState = {
    categories: {},
    groups: [],
    roles: [],
    users: [],
    errors: null,
    _updating: {
        _gettingCategories: false,
        _gettingGroups: false,
        _gettingRoles: false,
        _creatingRole: false,
        _updatingGroup: false,
        _gettingUsers: false
    }
};

const identity = createReducer(initialState, {
    [types.GET_ROLES_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _gettingRoles: true } }),
    [types.GET_ROLES_SUCCESS]: (state, { payload: roles }) => ({
        ...state,
        roles,
        errors: null,
        _updating: { ...state._updating, _gettingRoles: false }
    }),
    [types.GET_ROLES_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _gettingRoles: false }
    }),

    [types.CREATE_ROLE_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _creatingRole: true } }),
    [types.CREATE_ROLE_SUCCESS]: (state, { payload: role }) => ({
        ...state,
        errors: null,
        roles: [...state.roles, role],
        _updating: { ...state._updating, _creatingRole: false }
    }),
    [types.CREATE_ROLE_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _creatingRole: false }
    }),

    [types.UPDATE_ROLE_REQUEST]: (state, { payload: roleId }) => ({
        ...state,
        _updating: { ...state._updating, [`role-${roleId}`]: true }
    }),
    [types.UPDATE_ROLE_SUCCESS]: (state, { payload: role }) => ({
        ...state,
        errors: null,
        roles: [...state.roles.map(r => (r.Id !== role.Id ? r : role))],
        _updating: { ...state._updating, [`role-${role.Id}`]: false }
    }),
    [types.UPDATE_ROLE_FAILURE]: (state, { payload: { roleId, errors } }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, [`role-${roleId}`]: false }
    }),

    [types.DELETE_ROLE_REQUEST]: (state, { payload: roleId }) => ({
        ...state,
        _updating: { ...state._updating, [`deleting-role-${roleId}`]: true }
    }),
    [types.DELETE_ROLE_SUCCESS]: (state, { payload: roleId }) => ({
        ...state,
        errors: null,
        roles: [...state.roles.filter(role => role.Id !== roleId)],
        _updating: { ...state._updating, [`deleting-role-${roleId}`]: false }
    }),
    [types.DELETE_ROLE_FAILURE]: (state, { payload: { roleId, errors } }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, [`deleting-role-${roleId}`]: false }
    }),

    [types.GET_GROUPS_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _gettingGroups: true } }),
    [types.GET_GROUPS_SUCCESS]: (state, { payload: groups }) => ({
        ...state,
        groups,
        errors: null,
        _updating: { ...state._updating, _gettingGroups: false }
    }),
    [types.GET_GROUPS_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _gettingGroups: false }
    }),

    [types.UPDATE_GROUP_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _updatingGroup: true } }),
    [types.UPDATE_GROUP_SUCCESS]: (state, { payload: group }) => ({
        ...state,
        groups: state.groups.reduce((gAcc, oldG) => (oldG.Id !== group.Id ? [...gAcc, oldG] : gAcc), [group]),
        errors: null,
        _updating: { ...state._updating, _updatingGroup: false }
    }),
    [types.UPDATE_GROUP_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _updatingGroup: false }
    }),

    [types.DELETE_GROUP_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _updatingGroup: true } }),
    [types.DELETE_GROUP_SUCCESS]: (state, { payload: deletedId }) => ({
        ...state,
        groups: state.groups.filter(g => g.Id !== deletedId),
        errors: null,
        _updating: { ...state._updating, _updatingGroup: false }
    }),
    [types.DELETE_GROUP_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _updatingGroup: false }
    }),

    [types.GET_CATEGORIES_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _gettingCategories: true } }),
    [types.GET_CATEGORIES_SUCCESS]: (state, { payload: categories }) => ({
        ...state,
        categories,
        errors: null,
        _updating: { ...state._updating, _gettingCategories: false }
    }),
    [types.GET_CATEGORIES_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _gettingCategories: false }
    }),

    [types.ADD_ROLE_TO_GROUP_REQUEST]: state => ({ ...state, errors: null }),
    [types.ADD_ROLE_TO_GROUP_SUCCESS]: (state, { payload: group }) => ({
        ...state,
        errors: null,
        groups: state.groups.map(g => (g.Id === group.Id ? group : g))
    }),
    [types.ADD_ROLE_TO_GROUP_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors
    }),

    [types.REMOVE_ROLE_FROM_GROUP_REQUEST]: state => ({ ...state, errors: null }),
    [types.REMOVE_ROLE_FROM_GROUP_SUCCESS]: (state, { payload: group }) => ({
        ...state,
        errors: null,
        groups: state.groups.map(g => (g.Id === group.Id ? group : g))
    }),
    [types.REMOVE_ROLE_FROM_GROUP_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors
    }),

    [types.GET_USERS_REQUEST]: state => ({ ...state, errors: null, _updating: { ...state._updating, _gettingUsers: true } }),
    [types.GET_USERS_SUCCESS]: (state, { payload: users }) => ({
        ...state,
        users,
        errors: null,
        _updating: { ...state._updating, _gettingUsers: false }
    }),
    [types.GET_USERS_FAILURE]: (state, { payload: errors }) => ({
        ...state,
        errors,
        _updating: { ...state._updating, _gettingUsers: false }
    }),
    [types.GET_TAGS_SUCCESS]: (state, { payload: tags }) => ({
        ...state,
        tags,
        errors: null
    })
});

export default identity;
