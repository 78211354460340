import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import shortid from 'shortid';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

import Modal from '../../common/Modal';
import Button from '../../common/Button';

import { caseTemplatesOperations } from '../duck';

class CaseTemplateList extends Component {
    state = {
        showDeleteModal: false,
        caseTemplateToDelete: undefined,
        deleteErrors: undefined
    };

    openDeleteModal = caseTemplate => {
        this.setState({
            ...this.state,
            showDeleteModal: true,
            caseTemplateToDelete: caseTemplate
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            caseTemplateToDelete: undefined
        });
    };

    editCaseTemplate = caseTemplate => {
        const { openCreateModal, itemTypes } = this.props;
        openCreateModal(null, {
            Id: caseTemplate.Id,
            Label: caseTemplate.Label,
            ContractType_Id: caseTemplate.ContractType.Id,
            ROICoversheetTemplate: caseTemplate.ROICoversheetTemplate,
            ROIStylesheet: caseTemplate.ROIStylesheet,
            ROIHeader: caseTemplate.ROIHeader,
            ROIFooter: caseTemplate.ROIFooter,
            ShowCoverPage: caseTemplate.ShowCoverPage,
            ItemTypeIds: caseTemplate.Items.map(i => ({
                ...itemTypes.find(it => it.Id === i.ItemType.Id),
                CaseTemplateItemId: i.Id,
                AutoAdd: i.AutoAdd,
                ShowInReport: i.ShowInReport,
                ShowTitle: i.ShowTitle,
                TempId: shortid.generate(),
                DisplayOrder: i.DisplayOrder
            }))
        });
    };

    deleteCaseTemplate = event => {
        event.preventDefault();
        this.props
            .deleteCaseTemplate(this.state.caseTemplateToDelete)
            .then(() => this.closeModal())
            .catch(e => {
                if (e.Message) {
                    this.setState({
                        ...this.state,
                        deleteErrors: JSON.parse(e.Message)
                    });
                }
            });
    };

    render() {
        const { caseTemplates, caseTemplateUpates } = this.props;
        const { expanded, showDeleteModal, caseTemplateToDelete, deleteErrors } = this.state;
        const isDeleting = caseTemplateToDelete !== undefined ? caseTemplateUpates[caseTemplateToDelete.Id] : false;

        const deleteErrorsCases = deleteErrors && deleteErrors['Cases'];
        const deleteErrorsOrgs = deleteErrors && deleteErrors['OrgCaseTypes'];

        const columns = [
            {
                Header: 'Label',
                accessor: 'Label',
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
            },
            {
                id: 'ContractType',
                Header: 'Contract Type',
                accessor: d => d.ContractType.Description,
                filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                Cell: ({ value }) => <div className="text-center">{value}</div>
            },
            {
                filterable: false,
                sortable: false,
                Cell: ({ row }) => (
                    <div className="flex justify-center items-center h-full">
                        <Guard permissions={[roles.CaseTemplates.UPDATE_CASE_TEMPLATE]}>
                            <span
                                className="cursor-pointer mr-4 cursor-pointer text-grey"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.editCaseTemplate(row._original);
                                }}
                            >
                                <i className="fas fa-pencil-alt" />
                            </span>
                        </Guard>
                        <Guard permissions={[roles.CaseTemplates.DELETE_CASE_TEMPLATE]}>
                            <span
                                className="cursor-pointer mr-4 cursor-pointer text-red-light"
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.openDeleteModal(row._original);
                                }}
                            >
                                <i className="fas fa-trash-alt" />
                            </span>
                        </Guard>
                    </div>
                )
            }
        ];

        return (
            <>
                <ReactTable
                    filterable
                    data={caseTemplates}
                    columns={columns}
                    expanded={expanded}
                    defaultSorted={[{ id: 'Label', desc: false }]}
                    getTrProps={(state, rowInfo, column) => ({
                        onClick: e => {
                            this.editCaseTemplate(rowInfo.row._original);
                        }
                    })}
                    getTheadProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTheadFilterProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTdProps={(state, rowInfo) => ({
                        className: `${
                            typeof Object.keys(expanded || {}).find(k => !!expanded[k]) === 'string' && !!!expanded[rowInfo && rowInfo.index]
                                ? `opacity-25`
                                : ''
                            }`
                    })}
                />
                <Modal show={showDeleteModal} onClose={this.closeModal} className="max-h-screen-90">
                    <div className="p-4">
                        {showDeleteModal ? (
                            <>
                                <h2 className="pb-4">
                                    <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                                </h2>
                                <p>
                                    Are you sure you wish to delete <strong>{caseTemplateToDelete.Label}</strong>? This action cannot be undone.
                                </p>
                                {deleteErrorsCases && deleteErrorsCases.length && (
                                    <div className="mt-4">
                                        <p>You must remove the following cases:</p>
                                        <ul>
                                            {deleteErrorsCases.map(dec => (
                                                <li key={`case-${dec.Id}`}>
                                                    <a href={`/cases/${dec.Id}`}>#{dec.CaseNumber}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {deleteErrorsOrgs && deleteErrorsOrgs.length && (
                                    <div className="mt-4">
                                        <p>You must remove the case template from the following orgs:</p>
                                        <ul>
                                            {deleteErrorsOrgs.map(deo => (
                                                <li key={`org-${deo.Org_Id}`}>
                                                    <a href={`/orgs/${deo.Org_Id}/case-types`}>#{deo.Name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <div className="flex justify-between items-end mt-4">
                                    <div className="flex items-end">
                                        <Button onClick={this.closeModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                            Cancel
                                        </Button>
                                        {!isDeleting ? (
                                            <Button className="text-red bg-white hover:bg-grey-lighter" onClick={this.deleteCaseTemplate}>
                                                Delete {caseTemplateToDelete.Label}
                                            </Button>
                                        ) : (
                                                <Button className="text-red bg-white hover:bg-grey-lighter" disabled>
                                                    Deleting {caseTemplateToDelete.Label}
                                                    ... <i className="fas fa-spinner fa-pulse" />
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </>
                        ) : (
                                <></>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    caseTemplates: state.caseTemplates.caseTemplates,
    itemTypes: state.types['GoalType'] ? state.types['GoalType'].Types : [],
    caseTemplateUpates: state.caseTemplates._updating._caseTemplates
});

const mapDispatchToProps = dispatch => ({
    deleteCaseTemplate: caseTemplate => dispatch(caseTemplatesOperations.deleteCaseTemplate(caseTemplate))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseTemplateList);
