import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';

import { typesSelectors, typesOperations } from '../../../../types/duck';
import CustomCheckbox from '../../../../common/CustomCheckbox';

class ListFieldConfigurer extends Component {
    componentDidMount() {
        if (this.props.shouldUpdateTypes) this.props.getTypes();
    }

    render() {
        const { field, availableTypes, onChange, isLoadingTypes } = this.props;

        return (
            <div className="flex justify-between items-center flex-wrap -mx-4">
                <div className="w-1/2 px-4">
                    <div className="relative flex-grow">
                        <select
                            className="input-field"
                            name="@listType"
                            placeholder=""
                            value={field['@listType'] || ''}
                            onChange={event => onChange(field, event.target.name, event.target.value)}
                        >
                            {!isLoadingTypes ? (
                                availableTypes.reduce(
                                    (options, option) => [
                                        ...options,
                                        <option key={shortid.generate()} value={option}>
                                            {option.toTitleCase()}
                                        </option>
                                    ],
                                    [
                                        <option key={shortid.generate()} value="" disabled>
                                            Select a list...
                                        </option>
                                    ]
                                )
                            ) : (
                                <option key={shortid.generate()} value={field['@listType'] || ''} disabled>
                                    Loading types...
                                </option>
                            )}
                        </select>
                        <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 px-4">
                    <label className="block text-primary text-sm mt-4 mb-2">
                        <CustomCheckbox
                            name="@allowMultiple"
                            checked={field['@allowMultiple']}
                            onChange={event => onChange(field, event.target.name, event.target.checked)}
                        />
                        &nbsp;&nbsp;&nbsp;Allow Multiple Selections
                    </label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    availableTypes: Object.keys(state.types).filter(key => key[0] !== '_' && key !== 'types'),
    shouldUpdateTypes: typesSelectors.shouldUpdateType(state.types, ''),
    isLoadingTypes: state.types._updating._gettingTypes
});

const mapDispatchToProps = dispatch => ({
    getTypes: () => dispatch(typesOperations.getTypes())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListFieldConfigurer);
