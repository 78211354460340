import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment';

import Guard from '../../common/Guard';
import { roles } from '../../../constants';

const SectionVersionList = ({ versions, deleteSection, updatingSections, openCreateModal }) => {
    const columns = [
        {
            Header: 'Version',
            accessor: 'Version',
            filterable: false,
            Cell: ({ value }) => <div className="flex justify-center">{value}</div>
        },
        {
            Header: 'Enabled',
            filterable: false,
            sortable: false,
            Cell: ({ row }) => (
                <div className="flex justify-center">
                    {row._original.Enabled ? <i className="fas fa-check text-green-light" /> : <i className="fas fa-times text-grey-light" />}
                </div>
            )
        },
        {
            Header: 'Created On',
            filterable: false,
            sortable: false,
            Cell: ({ row }) => <div className="flex justify-center">{moment(row._original.CreatedDate).format('MMMM DD, YYYY')}</div>
        },
        {
            filterable: false,
            sortable: false,
            Cell: ({ row }) => (
                <div className="flex justify-center items-center h-full">
                    <Guard permissions={[roles.Sections.CREATE_SECTION_TEMPLATES]}>
                        <span
                            className="cursor-pointer mr-4 cursor-pointer text-grey"
                            onClick={e => {
                                e.preventDefault();
                                openCreateModal(undefined, row._original);
                            }}
                        >
                            <i className="fas fa-pencil-alt" />
                        </span>
                    </Guard>
                    <Guard permissions={[roles.Sections.DELETE_SECTION_TEMPLATES]}>
                        <span
                            className={`${row._original.Enabled ? 'text-red-light' : 'text-green-light'} ${
                                !updatingSections[row._original.Id] ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                            }`}
                            onClick={e => {
                                e.preventDefault();

                                if (!updatingSections[row._original.Id]) deleteSection(row._original);
                            }}
                        >
                            <i
                                className={`fas ${
                                    !updatingSections[row._original.Id] ? (row._original.Enabled ? 'fa-trash-alt' : 'fa-undo') : 'fa-spinner fa-pulse'
                                }`}
                            />
                        </span>
                    </Guard>
                </div>
            )
        }
    ];

    return (
        <div className="px-8 py-4 my-4 bg-table-search rounded shadow-inner">
            <div className="bg-white shadow rounded p-4">
                <ReactTable
                    data={versions}
                    pageSize={versions.length}
                    showPagination={false}
                    columns={columns}
                    defaultSorted={[
                        {
                            id: 'Version',
                            desc: true
                        }
                    ]}
                    getTheadProps={() => ({
                        style: { background: 'white' }
                    })}
                    getTheadFilterProps={() => ({
                        style: { background: 'white' }
                    })}
                />
            </div>
        </div>
    );
};

export default SectionVersionList;
