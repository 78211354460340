import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '../common/form/Form';
import { Input, Checkbox } from '../common/form';
import Button from '../common/Button';
import PageHeader from '../common/PageHeader';
import { Divider } from '../common/form/index';

class SecuritySettings extends Component {
    componentDidMount() {
        this.props.getSecuritySettings();
    }

    onSubmit = values => {
        this.props.saveSecuritySettings(values);
    };

    render() {
        let { security } = this.props;
        return (
            <div className="container">
                <div className="flex justify-between items-center">
                    <PageHeader>Security</PageHeader>
                </div>
                <Divider />
                <div className="bg-white mb-4 p-4 mb-8 overflow-x-auto">
                    {security.isLoading ? (
                        <>
                            Loading... <i className="fas fa-spinner fa-pulse" />
                        </>
                    ) : (
                        <Form defaultValues={security.settings} onSubmit={this.onSubmit}>
                            <div className="flex flex-col sm:flex-row mt-2">
                                <div className="mb-4 flex-grow">
                                    <Input name="AutoLogoutTime" label="Auto-Logout (minutes)" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-col sm:flex-row mt-2">
                                <div className="mb-4 sm:mr-4 flex-grow">
                                    <Checkbox name="EnablePasswordExpiration" label="Enable Password Expiration" />
                                </div>
                                <div className="mb-4 sm:mr-4 flex-grow">
                                    <Input name="PasswordExpirationTime" label="Expire Password (days)" type="number" />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row mt-2">
                                {security.isSaving ? (
                                    <Button className="ml-auto" disabled={true}>
                                        Saving... <i className="fas fa-spinner fa-pulse" />
                                    </Button>
                                ) : (
                                    <Button className="ml-auto" type="submit">
                                        Save
                                    </Button>
                                )}
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        );
    }
}

const mapState = state => ({
    security: state.security
});

const mapDispatch = ({ security: { getSecuritySettings, saveSecuritySettings } }) => ({
    getSecuritySettings,
    saveSecuritySettings
});

export default connect(
    mapState,
    mapDispatch
)(SecuritySettings);
