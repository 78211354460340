import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import Guard from '../../../../../common/Guard';
import { baseUrl } from '../../../../../../config';
import { roles } from '../../../../../../constants';
import OrgCaseTypeDetails from './OrgCaseTypeDetails';
import { makeCancelable } from '../../../../../../utils';

import Button from '../../../../../common/Button';
import Modal from '../../../../../common/Modal';
import { Form } from '../../../../../common/form/Form';
import CreateOrgCaseType from '../CreateOrgCaseType';
import OrgDeadlines from '../../OrgDeadlines';

class OrgCaseTypeManager extends Component {
    state = {
        expandedFee: null,
        showDeleteModal: false,
        isDeleting: false,
        caseTypeToDelete: undefined,
        showCreateModal: false,
        isUpdating: false
    };

    componentWillUnmount() {
        if (this.caseOverridePromise) this.caseOverridePromise.cancel();
    }

    openCreateModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: true
        });
    };

    closeModal = () => {
        this.setState({
            ...this.state,
            showCreateModal: false
        });
    };

    openDeleteModal = caseType => {
        this.setState({
            showDeleteModal: true,
            caseTypeToDelete: caseType
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            showDeleteModal: false,
            isDeleting: false,
            caseTypeToDelete: undefined
        });
    };

    onDelete = () => {
        const { access_token, orgId, caseTypes } = this.props;
        const { caseTypeToDelete } = this.state;

        const lastOfTypes = caseTypes.length === 1;

        this.deleteOrgCaseTypePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isDeleting: true
                    },
                    async () => {
                        try {
                            await axios.delete(`${baseUrl}/api/orgs/${orgId}/case-types/${caseTypeToDelete.Id}`, {
                                headers: { Authorization: `Bearer ${access_token}` }
                            });

                            this.props.onDelete(!lastOfTypes ? caseTypeToDelete.Id : null);
                            toast.success('The case type was deleted successfully.');
                            this.closeDeleteModal();
                        } catch (e) {
                            toast.error('There was an error deleting the case type.');
                            this.setState({
                                ...this.state,
                                isDeleting: false
                            });
                        }
                    }
                );
            })
        );
    };

    overrideInheritedTypes = () => {
        if (this.state.isDeleting) return;
        const { caseTypes, orgId } = this.props;

        if (caseTypes.length <= 0) return;

        const caseType = caseTypes[0];

        this.caseOverridePromise = makeCancelable(
            new Promise(r => {
                this.setState(
                    {
                        ...this.state,
                        isUpdating: true
                    },
                    async () => {
                        try {
                            const response = await this.props.createOrgCaseType({
                                Org_Id: orgId,
                                CaseType_Id: caseType.CaseType.Id,
                                CaseTemplate_Id: caseType.CaseTemplate ? caseType.CaseTemplate.Id : null,
                                BasePrice: caseType.BaseCost,
                                ActiveFrom: caseType.ActiveFrom,
                                ActiveTo: caseType.ActiveTo,
                                Override: true
                            });

                            caseType.Id = response.data.Id;
                            this.props.onOverride();

                            this.setState({
                                ...this.state,
                                isUpdating: false
                            });
                            toast.success(`The case fees were successfully overridden.`);
                        } catch (e) {
                            toast.error(`There was an error overriding the case fees.`);
                            this.setState({
                                ...this.state,
                                errors: e.response.data.ModelState || {},
                                isUpdating: false
                            });
                        }
                    }
                );
            })
        );
    };

    getFormattedDateRange = (caseType) => {
        const from = caseType.ActiveFrom ?
            moment(caseType.ActiveFrom).format('MMMM DD, YYYY') :
            'Any time';
        const until = caseType.ActiveTo ?
            moment(caseType.ActiveTo).format('MMMM DD, YYYY') :
            'Any time';

        return `${from} - ${until}`;
    };

    getIsActive = (caseType) => {
        if (caseType.ActiveFrom === null && caseType.ActiveTo === null) return true;
        if (!!!caseType.ActiveFrom && moment().isBefore(moment(caseType.ActiveTo))) return true;
        if (!!!caseType.ActiveTo && moment().isAfter(moment(caseType.ActiveFrom))) return true;

        return moment().isBetween(moment(caseType.ActiveFrom), moment(caseType.ActiveTo));
    };

    render() {
        const { expandedFee, showDeleteModal, caseTypeToDelete, isDeleting, showCreateModal } = this.state;
        const { caseTypes, orgId, inherited } = this.props;

        const nameToDelete =
            caseTypeToDelete !== undefined
                ? `${caseTypeToDelete.CaseType.Description} ${caseTypeToDelete.CaseTemplate ? `(${caseTypeToDelete.CaseTemplate.Label})` : ``}`
                : ``;

        if (caseTypes.length === 0) return null;

        const lastIndex = caseTypes.length - 1;
        const CaseType_Id = caseTypes[0].CaseType.Id;
        const CaseTemplate_Id = caseTypes[0].CaseTemplate && caseTypes[0].CaseTemplate.Id;

        return (<>
            <div className="px-8 py-4 my-4 bg-grey-lighter rounded shadow-inner">
                <div className="flex justify-between items-center py-4">
                    <h1 className="mb-2 text-xl text-black">Fees</h1>
                    <Guard permissions={[roles.Orgs.ADD_CASE_TYPE_TO_ORG]}>
                        {caseTypes[0].Inherited ?
                            <Button inverse onClick={this.overrideInheritedTypes} loading={this.state.isUpdating}>
                                Override
                            </Button> :
                            <Button inverse onClick={this.openCreateModal}>
                                Add Date Range
                            </Button>
                        }
                    </Guard>
                </div>
                <div className="bg-white shadow rounded p-4">
                    {caseTypes.map((caseType, index) => {
                        const isActive = this.getIsActive(caseType);
                        const isExpanded = expandedFee === caseType.Id;

                        return (<div key={caseType.Id} className={index !== lastIndex ? 'border-b' : ''}>
                            <div className="flex justify-between items-center cursor-pointer py-4" onClick={() => this.setState({
                                ...this.state,
                                expandedFee: isExpanded ? null : caseType.Id
                            })}>
                                <span className="w-1/3 font-bold text-grey-darkest">{this.getFormattedDateRange(caseType)}</span>
                                <span className={`font-bold px-2 py-1 rounded text-white text-xxs ${isActive ? 'bg-green-light' : 'bg-grey-dark'}`}>{isActive ? 'ACTIVE' : 'INACTIVE'}</span>
                                <div>
                                    <Guard permissions={[roles.Orgs.REMOVE_CASE_TYPE_FROM_ORG]}>
                                        <span
                                            className="mr-4 text-red-light cursor-pointer"
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.openDeleteModal(caseType);
                                            }}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </span>
                                    </Guard>
                                    <span className="mr-4">{
                                        isExpanded ? <i className="fas fa-chevron-down" /> :
                                            <i className="fas fa-chevron-up" />
                                    }</span>
                                </div>
                            </div>
                            {isExpanded &&
                                <div className="py-4 px-4">
                                    <OrgCaseTypeDetails
                                        onPriceUpdated={this.props.onPriceUpdated}
                                        onOverride={this.props.onOverride}
                                        orgCaseType={caseType}
                                        orgId={orgId}
                                        onFeeCreate={this.props.onFeeCreate}
                                        onFeeEdit={this.props.onFeeEdit}
                                        onFeeDelete={this.props.onFeeDelete}
                                        onCreated={this.props.onCreated}
                                    />
                                </div>
                            }
                        </div>);
                    })}
                </div>
                <Guard permissions={[roles.Orgs.GET_ORG_DEADLINES]}>
                    <>
                        <div className="flex justify-between items-center py-4">
                            <h1 className="mb-2 text-xl text-black">Deadlines</h1>
                        </div>
                        <div className="bg-white shadow rounded p-4">
                            <OrgDeadlines filtered={this.props.filtered} inherited={inherited} caseTypeId={CaseType_Id} />
                        </div>
                    </>
                </Guard>
            </div>
            <Modal show={showDeleteModal} onClose={this.closeDeleteModal} className="max-w-md">
                <div className="p-4">
                    <h2 className="pb-4">
                        <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                        </h2>
                    {showDeleteModal ? (
                        <Form onSubmit={this.onDelete}>
                            <p>
                                Are you sure you would like to remove <strong>{nameToDelete} - [{this.getFormattedDateRange(caseTypeToDelete)}]</strong>? This action cannot be undone.
                                </p>
                            <div className="flex justify-end items-end mt-4">
                                <Button onClick={this.closeDeleteModal} className="mr-4 bg-grey hover:bg-grey-dark">
                                    Cancel
                                    </Button>
                                {!isDeleting ? (
                                    <Button type="submit" className="text-red border-red hover:bg-grey-dark">
                                        Remove
                                        </Button>
                                ) : (
                                        <Button className="text-red border-red hover:bg-grey-dark" disabled>
                                            Removing... <i className="fas fa-spinner fa-pulse" />
                                        </Button>
                                    )}
                            </div>
                        </Form>
                    ) : (
                            <></>
                        )}
                </div>
            </Modal>
            <Modal show={showCreateModal} onClose={this.closeModal} className="max-h-screen-90">
                <div className="p-4">
                    {showCreateModal ? <CreateOrgCaseType caseTypeId={CaseType_Id} caseTemplateId={CaseTemplate_Id} orgId={orgId} closeModal={this.closeModal} onCreated={this.props.onCreated} /> : <></>}
                </div>
            </Modal>
        </>);
    }
}

const mapStateToProps = state => ({
    access_token: state.account.auth.access_token
});

const mapDispatchToProps = dispatch => ({
    createOrgCaseType: dispatch.orgCaseType.createOrgCaseType
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCaseTypeManager);