import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

import { accountSelectors } from '../account/duck';

const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props => rest.loggedIn ? (
            <Component {...props} />
        ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }} />
            )} />
);

const mapStateToProps = state => ({
    loggedIn: accountSelectors.loggedIn(state.account)
});

export default withRouter(connect(mapStateToProps)(AuthRoute));