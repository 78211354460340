import Creators from './actions';
import axios from 'axios';
import { toast } from 'react-toastify';

import { baseUrl } from '../../../config';

const getOrgs = () => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.getOrgsRequest());

    try {
        const orgs = await axios.get(`${baseUrl}/api/orgs?includeHidden=true`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        dispatch(Creators.getOrgsSuccess(orgs.data));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.getOrgsFailure(
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
    }
};

const updateOrgParent = (org, parentOrg) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.updateOrgParentRequest(org.id));

    try {
        await axios.put(`${baseUrl}/api/orgs/${org.id}/parent`, {
            ParentOrg_Id: parentOrg.id
        }, {
                headers: { 'Authorization': `Bearer ${access_token}` }
            });

        toast.success(`Change the parent of ${org.title} to ${parentOrg.title} successfully.`);
        dispatch(Creators.updateOrgParentSuccess(org.id, parentOrg.id));
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.updateOrgParentFailure(org.id,
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
    }
};

const createOrg = (org) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.createOrgRequest());

    try {
        const newOrg = await axios.post(`${baseUrl}/api/orgs`, org, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`${org.Name} was created successfully.`);
        dispatch(Creators.createOrgSuccess(newOrg.data));
        return Promise.resolve(newOrg.data);
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.createOrgFailure(
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
        return Promise.reject();
    }
};

const deleteOrg = (org) => async (dispatch, getState) => {
    const { account: { auth: { access_token } } } = getState();
    dispatch(Creators.deleteOrgRequest(org.Id));

    try {
        await axios.delete(`${baseUrl}/api/orgs/${org.Id}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });

        toast.success(`${org.Name} was ${org.Hidden ? 'activated' : 'deactivated'} successfully.`);
        dispatch(Creators.deleteOrgSuccess(org.Id));
        return Promise.resolve();
    } catch (error) {
        if (error.response === undefined) toast.error('There was an error connecting to the server.');
        dispatch(Creators.deleteOrgFailure(org.Id,
            error.response !== undefined ? error.response.data :
                { error_description: 'There was an error connecting to the server.' }
        ));
        return Promise.reject();
    }
};

export default {
    getOrgs,
    updateOrgParent,
    createOrg,
    deleteOrg
};