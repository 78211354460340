import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'whatwg-fetch';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { USER_PROFILE_PAGE_SIZE } from '../../../constants';
import { baseUrl } from '../../../config';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import { accountSelectors } from '../../account/duck';
import { roles } from '../../../constants';
import { Header, Divider } from '../../common/form/';
import Guard from '../../common/Guard';

class ItemAttachments extends Component {
    state = {
        attachments: [],
        errors: {},
        uploading: false
    };
    columns = [
        {
            Header: 'Name',
            accessor: 'Name',
            Cell: row => (
                <span
                    className="flex flex-grow items-center h-full hover:underline cursor-pointer"
                    onClick={e => {
                        e.preventDefault();
                        this.downloadFile(row.original);
                    }}
                >
                    {row.original.Name}
                </span>
            )
        },
        {
            Header: 'Added On',
            accessor: 'CreatedDate',
            Cell: row => (
                <div className="w-full text-center">
                    {moment(row.original.CreatedDate)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            Header: 'Actions',
            id: 'Actions',
            filterable: false,
            sortable: false,
            accessor: d => d,
            Cell: ({ row, index, viewIndex }) => (
                <div className="flex justify-center items-center h-full">
                    <span
                        className="text-black cursor-pointer mr-4"
                        onClick={e => {
                            e.preventDefault();
                            this.downloadFile(row._original);
                        }}
                    >
                        <i className="fas fa-download" />
                    </span>

                    <Guard permissions={[roles.ItemAttachments.DELETE_ITEM_ATTACHMENTS]}>
                        <span
                            className={`text-red-light ${this.props.disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer'}`}
                            onClick={this.confirmDelete.bind(this, row._original)}
                        >
                            <i className="fas fa-trash-alt" />
                        </span>
                    </Guard>
                    <Guard permissions={[roles.ItemAttachments.ADD_ITEM_ATTACHMENTS]}>
                        <div className="mx-4">
                            {viewIndex !== 0 && this.props.attachments.length > 1 ? (
                                !this.props.swappingAttachments ? (
                                    <span
                                        className="text-grey cursor-pointer mr-4"
                                        onClick={() => {
                                            this.props.swapAttachments(this.props.caseId, this.props.itemId, {
                                                SwapFrom_Id: this.props.attachments[viewIndex].Id,
                                                SwapTo_Id: this.props.attachments[viewIndex - 1].Id
                                            });
                                        }}
                                    >
                                        <i className="fas fa-chevron-up" />
                                    </span>
                                ) : (
                                    <span className="text-grey-light cursor-not-allowed mr-4">
                                        <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                )
                            ) : (
                                <span className="mr-4 opacity-0">
                                    <i className="fas fa-chevron-up" />
                                </span>
                            )}
                            {viewIndex !== this.props.attachments.length - 1 && this.props.attachments.length > 1 ? (
                                !this.props.swappingAttachments ? (
                                    <span
                                        className="text-grey cursor-pointer mr-4"
                                        onClick={() => {
                                            this.props.swapAttachments(this.props.caseId, this.props.itemId, {
                                                SwapFrom_Id: this.props.attachments[viewIndex].Id,
                                                SwapTo_Id: this.props.attachments[viewIndex + 1].Id
                                            });
                                        }}
                                    >
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                ) : (
                                    <span className="text-grey-light cursor-not-allowed mr-4">
                                        <i className="fas fa-spinner fa-pulse" />
                                    </span>
                                )
                            ) : (
                                <span className="mr-4 opacity-0">
                                    <i className="fas fa-chevron-up" />
                                </span>
                            )}
                        </div>
                    </Guard>
                </div>
            )
        }
    ];

    confirmDelete = attachment => {
        this.setState({
            showModal: true,
            attachmentToDelete: { ...attachment }
        });
    };

    async downloadFile(attachment) {
        let { access_token, caseId } = this.props;
        try {
            const response = await fetch(`${baseUrl}/api/cases/${caseId}/items/${attachment.Goal_Id}/attachments/${attachment.Id}/file`, {
                headers: { Authorization: `Bearer ${access_token}`, responseType: 'blob' }
            });

            let blob = await response.blob();

            FileSaver.saveAs(blob, attachment.Name);
        } catch (error) {
            toast.error('Unable to retrieve file.');
        }
    }

    uploadFile = async acceptedFiles => {
        let data = new FormData();
        const { caseId, itemId } = this.props;
        acceptedFiles.forEach((file, index) => {
            data.append(index, file, file.name);
        });

        try {
            this.setState({
                uploading: true
            });
            await this.props.addAttachments({ data, caseId, itemId });
            this.setState({
                uploading: false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                uploading: false
            });
        }
    };

    deleteAttachment = async attachment => {
        attachment.Case_Id = this.props.caseId;

        await this.props.deleteAttachment(attachment);

        this.setState({
            showModal: false,
            attachmentToDelete: undefined
        });
    };

    render() {
        const { disabled, deletingFiles } = this.props;
        const { showModal, attachmentToDelete, uploading } = this.state;

        return (
            <div className="mt-8 mb-4">
                <Guard permissions={[roles.ItemAttachments.VIEW_ITEM_ATTACHMENTS]}>
                    <div className="flex align-center justify-between">
                        <div className="font-bold text-primary">Attachments</div>
                    </div>
                    <div className="mt-2" />
                    <ReactTable
                        columns={this.columns}
                        data={this.props.attachments}
                        getNoDataProps={() => ({
                            style: { display: 'none' }
                        })}
                        showPagination={false}
                        defaultPageSize={USER_PROFILE_PAGE_SIZE}
                        pageSize={this.props.attachments.length}
                        getTrProps={(_, rowInfo) => ({
                            className: rowInfo.original.CorruptPDF ? 'bg-red-lightest border-l-4 border-red' : 'border-l-4 border-white'
                        })}
                    />
                    <div className={`flex w-full mb-4 ${disabled ? 'pointer-events-none opacity-50' : ''}`}>
                        <Guard permissions={[roles.ItemAttachments.ADD_ITEM_ATTACHMENTS]}>
                            <Dropzone multiple className="w-full h-64 border-dashed border-4" onDrop={this.uploadFile}>
                                <div className="flex w-full justify-center items-center h-full">
                                    {uploading ? (
                                        <i className="fas fa-spinner fa-pulse fa-3x" />
                                    ) : (
                                        <h3 className="text-primary text-base">Click or drop files here (zip files will be extracted)</h3>
                                    )}
                                </div>
                            </Dropzone>
                        </Guard>
                    </div>
                    <Modal show={showModal} className="max-w-md" onClose={() => this.setState({ showModal: false })}>
                        <h2 className="pb-4">
                            <i className="fa fa-exclamation-triangle mr-2 text-yellow-dark" /> Do you really want to do this?
                        </h2>
                        <p>{`Delete ${attachmentToDelete && attachmentToDelete.Name}?`}</p>
                        <div className="w-full flex mt-4">
                            <Button className="bg-grey ml-auto mr-4" onClick={() => this.setState({ showModal: false })}>
                                Cancel
                            </Button>
                            <Button
                                className="text-red border-red"
                                onClick={this.deleteAttachment.bind(this, attachmentToDelete)}
                                loading={deletingFiles}
                            >
                                Delete
                            </Button>
                        </div>
                    </Modal>
                </Guard>
            </div>
        );
    }
}
const mapState = state => ({
    attachments: state.item.attachments,
    access_token: state.account.auth.access_token,
    uploading: state.item._updating._uploadingFiles,
    deletingFiles: state.item._updating._deletingFiles,
    swappingAttachments: state.item._updating._swappingAttachments,
    hasPermission: permissions => accountSelectors.hasPermission(state.account, permissions)
});
const mapDispatch = dispatch => ({
    addAttachments: payload => dispatch.item.addAttachments(payload),
    deleteAttachment: payload => dispatch.item.deleteAttachment(payload),
    swapAttachments: (Case_Id, Goal_Id, swapObject) => dispatch.item.swapAttachments({ Case_Id, Goal_Id, swapObject })
});
export default connect(
    mapState,
    mapDispatch
)(ItemAttachments);
