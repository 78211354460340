import React, { Component } from 'react';

import { Label, ValidationError } from '../../../../common/form';
import CustomCheckbox from '../../../../common/CustomCheckbox';

class GeneralFieldConfigurer extends Component {
    state = {
        errors: {}
    };

    updateError = event => {
        this.setState({
            ...this.state,
            errors: {
                ...this.state.errors,
                [event.target.name]: !event.target.checkValidity()
            }
        });
    };

    onChange = event => {
        const { field, onChange } = this.props;
        onChange(field, event.target.name, event.target.type !== 'checkbox' ? event.target.value : event.target.checked);
    };

    onLabelChange = event => {
        const { field, onChange, editing, newField } = this.props;
        const computedOldName = this.generateFieldName(field['@label']);
        const newName = this.generateFieldName(event.target.value);

        onChange(field, event.target.name, event.target.value, () => {
            if (field['@name'] === computedOldName && (newField || !editing)) {
                onChange(field, '@name', newName);
            }
        });
    };

    generateFieldName = label => {
        return label.replace(/[^a-zA-Z0-9]/gi, '');
    };

    render() {
        const { field, errors, editing, newField } = this.props;

        return (
            <div className="flex justify-between items-center flex-wrap -mx-4">
                <div className="w-1/2 px-4">
                    <Label>
                        Label <strong className="text-red">&nbsp;*</strong>
                    </Label>
                    <input
                        type="text"
                        name="@label"
                        required
                        value={field['@label']}
                        autoFocus={true}
                        onChange={this.onLabelChange}
                        onBlur={this.updateError}
                        className={`input-field ${this.state.errors['@label'] || errors['@label'] ? 'border-red-light' : ''}`}
                    />
                    <ValidationError>{errors['@label']}</ValidationError>
                </div>
                <div className="w-1/2 px-4">
                    <Label>
                        Name <strong className="text-red">&nbsp;*</strong>
                    </Label>
                    <input
                        type="text"
                        name="@name"
                        required
                        value={field['@name']}
                        onChange={this.onChange}
                        onBlur={this.updateError}
                        className={!editing || newField ? `input-field ${this.state.errors['@name'] || errors['@name'] ? 'border-red-light' : ''}` : 'font-medium text-primary'}
                        readOnly={editing && !newField}
                    />
                    <ValidationError>{errors['@name']}</ValidationError>
                </div>
                <div className="w-full px-4">
                    <Label>Instructions</Label>
                    <input type="text" name="@instructions" value={field['@instructions']} onChange={this.onChange} className="input-field" />
                </div>
                <div className="w-1/2 px-4">
                    <label className="block text-primary text-sm mt-4 mb-2">
                        <CustomCheckbox name="@required" checked={field['@required']} onChange={this.onChange} />
                        &nbsp;&nbsp;&nbsp;Required
                    </label>
                </div>
                <div className="w-1/2 px-4">
                    <label className="block text-primary text-sm mt-4 mb-2">
                        <CustomCheckbox name="@showInReport" checked={field['@showInReport']} onChange={this.onChange} />
                        &nbsp;&nbsp;&nbsp;Show in Report
                    </label>
                </div>
            </div>
        );
    }
}

export default GeneralFieldConfigurer;
